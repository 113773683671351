import { testCovidConstants } from "../actions/constants";

const initState = {
  listData: {
    packages: [],
  },
  areaListData: {
    areaLayanan: [],
  },
  detailData: {
    package: {},
    partner: {},
  },
  loading: false,
  error: null,
  accessToken: null,
  user: {
    chatrooms: {},
  },
  userId: null,
  message: "",
  phone: "",
  email: "",
  initData: null,
  customerData: null,
  createdData: null,
  updatedData: null,
  addressData: null,
  paymentMethodData: null,
  paymentData: null,
  authenticate: false,
  authenticating: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case testCovidConstants.GET_LIST_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case testCovidConstants.GET_LIST_SUCCESS:
      state = {
        ...state,
        listData: action.payload.listData,
        loading: false,
      };
      break;
    case testCovidConstants.GET_LIST_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case testCovidConstants.GET_AREA_LIST_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case testCovidConstants.GET_AREA_LIST_SUCCESS:
      state = {
        ...state,
        areaListData: action.payload.areaListData,
        loading: false,
      };
      break;
    case testCovidConstants.GET_AREA_LIST_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case testCovidConstants.GET_DETAIL_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case testCovidConstants.GET_DETAIL_SUCCESS:
      state = {
        ...state,
        detailData: action.payload.detailData,
        loading: false,
      };
      break;
    case testCovidConstants.GET_DETAIL_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case testCovidConstants.REGISTER_OTP_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case testCovidConstants.REGISTER_OTP_SUCCESS:
      state = {
        ...state,
        userId: action.payload.userId,
        message: action.payload.message,
        email: action.payload.email,
        phone: action.payload.phone,
        loading: false,
      };
      break;
    case testCovidConstants.REGISTER_OTP_FAILURE:
      state = {
        ...state,
        userId: null,
        message: "",
        phone: "",
        email: "",
        error: action.payload.error,
        loading: false,
      };
      break;
    case testCovidConstants.CREATE_OTP_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case testCovidConstants.CREATE_OTP_SUCCESS:
      state = {
        ...state,
        userId: action.payload.userId,
        message: action.payload.message,
        email: action.payload.email,
        phone: action.payload.phone,
        loading: false,
      };
      break;
    case testCovidConstants.CREATE_OTP_FAILURE:
      state = {
        ...state,
        userId: null,
        message: "",
        phone: "",
        email: "",
        error: action.payload.error,
        loading: false,
      };
      break;
    case testCovidConstants.VERIFY_OTP_REQUEST:
      state = {
        ...state,
        authenticate: false,
        authenticating: true,
        loading: true,
      };
      break;
    case testCovidConstants.VERIFY_OTP_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        user: action.payload.user,
        accessToken: action.payload.accessToken,
        authenticate: true,
        authenticating: false,
        loading: false,
      };
      break;
    case testCovidConstants.VERIFY_OTP_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case testCovidConstants.INITIALIZE_CUSTOMER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case testCovidConstants.INITIALIZE_CUSTOMER_SUCCESS:
      state = {
        ...state,
        initData: action.payload.initData,
        loading: false,
      };
      break;
    case testCovidConstants.INITIALIZE_CUSTOMER_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case testCovidConstants.GET_CUSTOMER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case testCovidConstants.GET_CUSTOMER_SUCCESS:
      state = {
        ...state,
        customerData: action.payload.customerData,
        loading: false,
      };
      break;
    case testCovidConstants.GET_CUSTOMER_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case testCovidConstants.UPDATE_CUSTOMER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case testCovidConstants.UPDATE_CUSTOMER_SUCCESS:
      state = {
        ...state,
        updatedData: action.payload.updatedData,
        loading: false,
      };
      break;
    case testCovidConstants.UPDATE_CUSTOMER_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case testCovidConstants.CREATE_CUSTOMER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case testCovidConstants.CREATE_CUSTOMER_SUCCESS:
      state = {
        ...state,
        createdData: action.payload.createdData,
        loading: false,
      };
      break;
    case testCovidConstants.CREATE_CUSTOMER_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case testCovidConstants.CREATE_ADDRESS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case testCovidConstants.CREATE_ADDRESS_SUCCESS:
      state = {
        ...state,
        addressData: action.payload.addressData,
        loading: false,
      };
      break;
    case testCovidConstants.CREATE_ADDRESS_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case testCovidConstants.GET_PAYMENT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case testCovidConstants.GET_PAYMENT_SUCCESS:
      state = {
        ...state,
        paymentMethodData: action.payload.paymentMethodData,
        loading: false,
      };
      break;
    case testCovidConstants.GET_PAYMENT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case testCovidConstants.CREATE_PAYMENT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case testCovidConstants.CREATE_PAYMENT_SUCCESS:
      state = {
        ...state,
        paymentData: action.payload.paymentData,
        loading: false,
      };
      break;
    case testCovidConstants.CREATE_PAYMENT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
  }
  return state;
};
