import React, {Fragment, useEffect, useState } from "react";
import DashboardLayout from "../../Layout";
import { useDispatch, useSelector } from "react-redux";
import { getAllTeleOrder, getTeleStatus, getOrderTeleDetail, changeStatusOrderTele  } from "../../../../actions";
import { useParams } from "react-router";
import moment from "moment";
import { Link } from "react-router-dom";
import Loading from "../../../../components/Loading";
import Swal from 'sweetalert2';

const Breadcrumb = (props) => {
    return (
        <nav key={props.label} aria-label="breadcrumb" id="breadcrumb-id">
            <ol className="breadcrumb pl-0">
              <li className="breadcrumb-item">
                <a>Dashboard</a>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to={props.path}>{props.menu}</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {props.label}
              </li>
            </ol>
        </nav>
    )
}

const TeleDetail = () => {
  let { orderId } = useParams();
  const [ statusCode, setStatusCode ] = useState();

  const orderTele = useSelector((state) => state.orderTele);
  const dispatch = useDispatch();

  const {loading, error} = orderTele;
  
  useEffect(() => {
    dispatch(getOrderTeleDetail(orderId)); 
    dispatch(getTeleStatus());  
    }, [dispatch, orderId]
  );
  
  const changeStatus = () => {
    const form = new FormData();
    form.append("status", statusCode)
    if(orderId !== "" && form.get("status") !== null){
        Swal.fire({
            title: "Reconfirmation",
            text: `Apakah anda yakin ingin mengubah status?`,
            icon: "warning",
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#7cb342",
            cancelButtonText: "No",
        }).then((result) => {
            if (result.value) {
                console.log("statuscode", statusCode);
                dispatch(changeStatusOrderTele(orderId, form)).then((res) => {
                  if (res) {
                    dispatch(getAllTeleOrder());
                  }
                }).then(
                    setTimeout(() => 
                        Swal.fire({
                            icon: 'success',
                            text: 'Update Teleconsultation Success',
                        }), 1000
                    )
                );
            } 
          });
    }
  }
  let data = orderTele.order; 
  let teleStatus = orderTele.status; 

  return (
    
    <DashboardLayout>
        <Breadcrumb 
            path={"/teleconsultation-all-data"} 
            menu={"List Teleconsultation"} 
            label={"Detail - List Teleconsultation"}
            />
        <div className="form-wrapper" id="detail-complete-call-teleconsul-order">
        { loading ? <Loading/> : error ? error.message : (
        <Fragment>
           <div className="wrapper-form-title">
               <span className="form-title">Completed Call Teleconsultation Detail</span>
           </div>
   
           <div className="border-bottom-section">
               
               <div className="table-responsive">
                   <table id="tableScheduler" className="table table-striped table-bordered">
                           
                        <tbody className="text-left">
                            <tr>
                               <td>Order Id</td>
                               <td colSpan="2">{ data.order_id }</td>
                           </tr>
                           <tr>
                               <td>Customer</td>
                               <td>{ data.customer_name }</td>
                           </tr>
                           <tr>
                               <td>Patient</td>
                               <td>{ data.patient_name }</td>
                           </tr>
                           <tr>
                               <td>Patien DOB</td>
                               <td>{moment(data.patient_dob).format("DD MMM Y")}</td>
                           </tr>
                           <tr>
                               <td>Patient Phone</td>
                               <td>{data.patient_phone}</td>
                           </tr>
                           <tr>
                               <td>Doctor</td>
                               <td>{data.doctor_name}</td>
                           </tr>
                           {
                            localStorage.getItem("parentId") == 3295 ? (
                                <>
                                    <tr>
                                        <td>Type</td>
                                        <td>
                                            {!data.isHealthScreening && !data.isPremium ? "Free Tele" : !data.eventId && data.isPremium ? "Tanya Dokter Premium" : data.isHealthScreening ? "Health Screening" : ""}
                                        </td>
                                    </tr>
                                    {
                                        data.isHealthScreening ? 
                                        <tr>
                                            <td>Event Name</td>
                                            <td>{data.event_name} {data.corporate_name ? ` - ${data.corporate_name}` : ""}</td>
                                        </tr> : <></>
                                    }
                                </>
                            ) : <></>
                            }
                           <tr>
                               <td>Schedule</td>
                               {/* <td>{moment(data.scheduleDate).format("D MMMM Y")}</td> */}
                               <td>{moment(data.scheduleDate).utc().format("D MMMM Y HH:mm:ss")}</td>
                           </tr>
                           <tr>
                               <td>Start</td>
                               <td>{data.startTime}</td>
                           </tr>
                           <tr>
                               <td>End</td>
                               <td>{data.endTime}</td>
                           </tr>
                           <tr>
                               <td>Gender</td>
                               <td>{data.gender}</td>
                           </tr>
                           <tr>
                               <td>Email</td>
                               <td>{data.email}</td>
                           </tr>
                           <tr>
                                <td>Status</td>
                                <td>
                                    <div className="form-group">
                                        <select 
                                            className="form-control" 
                                            name="status" 
                                            onChange={(e) => setStatusCode(e.target.value)} 
                                            onFocus={(e) => setStatusCode(e.target.value)} 
                                            value={statusCode}
                                            >
                                                { teleStatus.map((statusData) => (
                                                    <option 
                                                        key={statusData.code}
                                                        value={statusData.code}
                                                        selected={ data.status === statusData.code ? (true) : false}
                                                        >{ statusData.description }</option>
                                                ))}
                                        </select>
                                    </div>
                                </td>
                           </tr>
                        </tbody>
                           
                       
                   </table>
                </div>
              
               
            </div>

            <div className="form-btn-group text-right">
                <button type="button" className="btn btn-primary" onClick={changeStatus}>Save Status</button>
            </div>

    </Fragment>
        )}
       </div>
    </DashboardLayout>
  );
};

export default TeleDetail;
