import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addProcedures,
  deleteProcedures,
  getAllProcedures,
  getProceduresById,
  updateProcedures,
} from "../../../actions";
import Input from "../Components/Input";
import Modal from "../Components/Modal";
import Pagination from "../../../components/Pagination";
import DashboardLayout from "../Layout";
import moment from "moment";
import Swal from "sweetalert2";

const MyProcedures = () => {
  const [doctorPrice, setDoctorPrice] = useState("");
  const [nursePrice, setNursePrice] = useState("");
  const [therapistPrice, setTherapistPrice] = useState("");
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [addProceduresModal, setaddProceduresModal] = useState(false);
  const [updateProceduresModal, setupdateProceduresModal] = useState(false);
  const [key, setKey] = useState("");
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const MyProcedures = useSelector((state) => state.myProcedures);
  const dispatch = useDispatch();

  const initState = () => {
    setName("");
    setDoctorPrice("");
    setNursePrice("");
    setTherapistPrice("");
  };

  useEffect(() => {
    dispatch(getAllProcedures(search));
    if (id !== "") {
      getData(MyProcedures.proceduresData);
    }
  }, [search, MyProcedures.proceduresData]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: MyProcedures.totalData,
    });
  }, [MyProcedures.totalData]);

  const handleCloseModal = () => {
    initState();
    setaddProceduresModal(false);
    setupdateProceduresModal(false);
  };

  const handleAutoComplete = (e) => {
    setKey(e.target.value);
    setPagination({
      ...pagination,
      page: 1,
      limit: 10,
    });
    setSearch(
      `page=${pagination.page}&limit=${pagination.limit}&search=${e.target.value}`
    );
  };

  const handlePagination = (page, limit) => {
    setSearch(`page=${page}&limit=${limit}&search=${key}`);
  };

  const handleaddProcedures = () => {
    setaddProceduresModal(true);
  };

  const actionaddProcedures = () => {
    const form = new FormData();
    let professionProducts = [];    
    let profession_doctor;
    let profession_nurse;
    let profession_fisio;
    if(doctorPrice !== 0 ||doctorPrice !== null||doctorPrice !== ""){
      profession_doctor = {
        "price": doctorPrice,
        "profession_id": 1
      }

      if(profession_doctor.price !== ""){
        professionProducts.push(profession_doctor);
      }
    }
    if(nursePrice !== 0 ||nursePrice !== null ||nursePrice !== ""){
      profession_nurse = {
        "price": nursePrice,
        "profession_id": 2
      }
      
      if(profession_nurse.price !== ""){
        professionProducts.push(profession_nurse);
      }
    }
    if(therapistPrice !== 0 ||therapistPrice !== null||therapistPrice !== ""){
      profession_fisio = {
        "price": therapistPrice,
        "profession_id": 3
      }

      if(profession_fisio.price !== ""){
        professionProducts.push(profession_fisio);
      }
    }
    form.append("productName", name);
    form.append("professionProducts", JSON.stringify(professionProducts));

    dispatch(addProcedures(form, search))
      .then((result) => {
        if (result) {
          dispatch(getAllProcedures(search));
        }
      })
      .then(() => {
        initState();
        setaddProceduresModal(false);
      })
      .then(
        setTimeout(() => Swal.fire("Success!", "Data Created", "success"), 3000)
      );
  };

  const renderaddProcedures = () => {
    return (
      <Modal
        modalTitle="Add New Procedures"
        show={addProceduresModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        onSubmit={actionaddProcedures}
      >
        <Row>
          <Col md>
            <Input
              label="Name"
              placeholder="Input Procedure Name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
          <Col md>
            <Input
              label="Procedure Fee for Doctor"
              placeholder="Input Procedure Fee for Doctor"
              type="text"
              value={doctorPrice}
              onChange={(e) => setDoctorPrice(e.target.value)}
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              label="Procedure Fee for Nurse"
              placeholder="Input Procedure Fee for Nurse"
              type="text"
              value={nursePrice}
              onChange={(e) => setNursePrice(e.target.value)}
            />
          </Col>
          <Col md>
            <Input
              label="Procedure Fee for Physiotherapy"
              placeholder="Input Procedure Fee for Physiotherapy"
              type="text"
              value={therapistPrice}
              onChange={(e) => setTherapistPrice(e.target.value)}
            />
          </Col>
        </Row>
      </Modal>
    );
  };

  const handleupdateProcedures = (proceduresId) => {
    setId(proceduresId);
    dispatch(getProceduresById(proceduresId)).then(
      setTimeout(() => setupdateProceduresModal(true), 200)
    );
  };

  const getData = (data) => {
    setName(data.productName);
    if(data.price.length > 0 ){
      data.price.map((procData) => {
        if(procData.profession_id === 1){
          setDoctorPrice(procData.price);
        } else if (procData.profession_id === 2){
          setNursePrice(procData.price);
        }else{
          setTherapistPrice(procData.price);
        }
      })
    }    
  };

  const actionUpdateProcedures = () => {
    const form = new FormData();
    let professionProducts = [];    
    let profession_doctor;
    let profession_nurse;
    let profession_fisio;
    if(doctorPrice !== 0 ||doctorPrice !== null||doctorPrice !== ""){
      profession_doctor = {
        "price": doctorPrice,
        "profession_id": 1
      }

      if(profession_doctor.price !== ""){
        professionProducts.push(profession_doctor);
      }
    }
    if(nursePrice !== 0 ||nursePrice !== null ||nursePrice !== ""){
      profession_nurse = {
        "price": nursePrice,
        "profession_id": 2
      }
      
      if(profession_nurse.price !== ""){
        professionProducts.push(profession_nurse);
      }
    }
    if(therapistPrice !== 0 ||therapistPrice !== null||therapistPrice !== ""){
      profession_fisio = {
        "price": therapistPrice,
        "profession_id": 3
      }

      if(profession_fisio.price !== ""){
        professionProducts.push(profession_fisio);
      }
    }
    form.append("productName", name);
    form.append("professionProducts", JSON.stringify(professionProducts));
    dispatch(updateProcedures(id, form, search))
      .then((result) => {
        if (result) {
          dispatch(getAllProcedures(search));
        }
      })
      .then(() => {
        initState();
        setupdateProceduresModal(false);
      })
      .then(
        setTimeout(() => Swal.fire("Success!", "Data Updated", "success"), 1000)
      );
  };

  const renderupdateProcedures = () => {
    return (
      <Modal
        modalTitle="Edit Procedures"
        show={updateProceduresModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        buttons={[
          {
            label: "Update",
            color: "warning",
            onClick: actionUpdateProcedures,
          },
        ]}
      >
        <Row>
          <Col md>
            <Input
              label="Name"
              placeholder="Input Procedure Name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
          <Col md>
            <Input
              label="Procedure Fee for Doctor"
              placeholder="Input Procedure Fee for Doctor"
              type="text"
              value={doctorPrice}
              onChange={(e) => setDoctorPrice(e.target.value)}
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              label="Procedure Fee for Nurse"
              placeholder="Input Procedure Fee for Nurse"
              type="text"
              value={nursePrice}
              onChange={(e) => setNursePrice(e.target.value)}
            />
          </Col>
          <Col md>
            <Input
              label="Procedure Fee for Physiotherapy"
              placeholder="Input Procedure Fee for Physiotherapy"
              type="text"
              value={therapistPrice}
              onChange={(e) => setTherapistPrice(e.target.value)}
            />
          </Col>
        </Row>
      </Modal>
    );
  };

  const handledeleteProcedures = (proceduresId) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "grey",
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteProcedures(proceduresId, search))
          .then((result) => {
            if (result) {
              dispatch(getAllProcedures(search));
            }
          })
          .then(() => {
            initState();
            setTimeout(
              () => Swal.fire("Success!", "Data Deleted", "success"),
              200
            );
          });
      }
    });
  };

  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const renderProcedures = () => {
    return MyProcedures.loading ? (
      <td colSpan="6">
        <Loading />
      </td>
    ) : (
      <>
        {MyProcedures.procedures.length > 0 ? (
          MyProcedures.procedures.map((procedures) => (
            <tr key={procedures.id}>
              <td className="num"></td>
              <td>{procedures.productName}</td>
              <td>{formatter.format(procedures.doctorPrice)}</td>
              <td>{formatter.format(procedures.nursePrice)}</td>
              <td>{formatter.format(procedures.therapistPrice)}</td>
              <td>
                <div style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    className="btn"
                    onClick={() => handleupdateProcedures(procedures.id)}
                  >
                    <img
                      src="/img/icon/ic_edit.png"
                      alt="edit"
                      className="container-img"
                    />
                  </button>

                  <button
                    type="button"
                    className="btn"
                    onClick={() => handledeleteProcedures(procedures.id)}
                  >
                    <img
                      src="/img/icon/ic_delete.png"
                      alt="delete"
                      className="container-img"
                    />
                  </button>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="6">Empty data</td>
          </tr>
        )}
      </>
    );
  };

  return (
    <DashboardLayout>
      {/* <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>My Procedures</div>

        <div className="col">
          <hr />
        </div>
      </div> */}

      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        {/* <div className="row">
          <div className="col-md-6">
            <span id="finance-title" className="mb10">
              Procedures
            </span>
          </div>
        </div> */}

        <div className="row">
          <div className="col-md-8" style={{ paddingBottom: "1em" }}>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => handleaddProcedures()}
            >
              Add New Procedures
            </button>
          </div>

          <div className="col-md-4" id="search-form">
            <div className="input-group" style={{ paddingBottom: "1em" }}>
              <input
                className="form-control table-search-field"
                type="text"
                value={key}
                onChange={handleAutoComplete}
                placeholder="Search Name"
              />
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "45px" }}
                >
                  No
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "250px" }}
                >
                  Procedures
                </th>

                <th
                  colSpan={3}
                  className="table-header-middle"
                >
                  Price
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "180px" }}
                >
                  Action
                </th>
              </tr>

              <tr>
                <th>Doctor</th>
                <th>Nurse</th>
                <th>Rehabilitation Therapist</th>
              </tr>
            </thead>
            <tbody>{renderProcedures()}</tbody>
          </table>
        </div>

        {MyProcedures.totalData > 10 ? (
          <Pagination
            props={pagination}
            setProps={setPagination}
            onChange={(page, limit) => {
              handlePagination(page, limit);
            }}
          />
        ) : null}
      </div>
      {renderaddProcedures()}
      {renderupdateProcedures()}
    </DashboardLayout>
  );
};

export default MyProcedures;
