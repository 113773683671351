import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashboardLayout from "../../../../Layout";

// Style
import "../../../orderHistory.css";

export default function OrderHistoryDetailConsentForm() {
    const { state } = useLocation();

    const consentPhoto = state.consentPhoto != undefined ? state.consentPhoto : "";

    const prevPageUrl = "/order-history/detail/lihatriwayat";
    const stateVal = { "id" : state.id, "statusCode": state.statusCode, "detailData": state.detailData, "orderId": state.orderId };
    
    return (
        <DashboardLayout>        
            <div className="col-12" >
                <div className="card-detail" style={{background:"#38a8ff", margin:"30px 0px 20px 0px", borderRadius: "10px 10px 0px 0px"}}>
                    <Link to={{ pathname: prevPageUrl, state: stateVal}}>
                        <i className="fa fa-arrow-left" style={{fontSize: "18px", color: "white"}} aria-hidden="true" /> 
                    </Link>
                    <span style={{margin: "0px 30px 0px 30px", color: "white", fontSize: "16px"}}> 
                        Lihat Consent Form
                    </span>
                </div>
                <div className="card-detail">
                    <div>
                        <i className="fa fa-file-text-o" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                        <span style={{margin: "0px 0px 10px 10px"}}> Consent Photo : </span>
                    </div>
                    <div>
                        <img src={consentPhoto} style={{minHeight:"250px", width: "250px", margin: "0px 10px 0px 10px"}} />
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
};