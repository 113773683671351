import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import DashboardLayout from "../../Layout";
import Loading from "../../../../components/Loading";
import Pagination from "../../../../components/Pagination";
import Swal from "sweetalert2";
import queryString from "query-string";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getAllVaccinationEvent, deleteEvent } from "../../../../actions";

const MyVaccinationEvent = () => {
  let history = useHistory();
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const searchKey = useRef("");
  const myVaccinationEvent = useSelector((state) => state.myVaccinationEvent);
  const dispatch = useDispatch();
  const { loading, error, events, totalData } = myVaccinationEvent;

  useEffect(() => {
    dispatch(getAllVaccinationEvent(search));
  }, [search]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: totalData,
    });
  }, [totalData]);

  const handleSearch = (page, limit) => {
    let values = {
      searchKey: searchKey.current.value,
    };
    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }
    if (limit) {
      values.limit = limit;
    }
    if (searchKey.current.value !== "") {
      values.searchKey = searchKey.current.value;
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const removeEvent = (eventId) => {
    if (eventId !== null || eventId !== "") {
      Swal.fire({
        title: "Reconfirmation",
        text: "Are you sure want to delete this event?",
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#58A8F8",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteEvent(eventId, search)).then((res) => {
            if (res) {
              dispatch(getAllVaccinationEvent(search));
            }
            Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: false,
            }).fire({
              icon: "success",
              title: "deleted successfully.",
            });
            history.replace("/my-vaccination-event");
          });
        }
      });
    }
  };

  const dataTables = () => {
    return loading ? (
      <tr>
        <td colSpan="6">
          <Loading />
        </td>
      </tr>
    ) : error ? (
      error.message
    ) : (
      <Fragment>
        {events.length > 0 ? (
          Object.values(JSON.parse(JSON.stringify(events))).map(
            (item, index) => (
              <tr key={item.eventId}>
                <td>{(pagination.page - 1) * pagination.limit + index + 1}</td>
                <td>{item.eventId ? item.eventId : "-"}</td>
                <td>{item.eventName ? item.eventName : "-"}</td>
                <td>{item.companyName ? item.companyName : "-"}</td>
                <td>
                  {item.startDate ? moment(item.startDate).format("LL") : "-"}
                </td>
                <td>
                  <Link
                    to={{
                      pathname: "/my-vaccination-event/detail",
                      state: item.eventId,
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-block"
                    >
                      Detail
                    </button>
                  </Link>

                  <button
                    type="button"
                    onClick={() => removeEvent(item.eventId)}
                    className="btn btn-outline-secondary btn-block mt-2 removeEvent"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            )
          )
        ) : (
          <tr>
            <td colSpan="6">Empty data, Please add your data...</td>
          </tr>
        )}
      </Fragment>
    );
  };

  return (
    <DashboardLayout>
      {loading ? (
        <div className="table-wrapper">
          <Loading />
        </div>
      ) : (
        <Fragment>
          <div className="row revenue-title">
            <div className="col">
              <hr />
            </div>
            <div>My Vaccination Event</div>
            <div className="col">
              <hr />
            </div>
          </div>

          <div className="table-wrapper">
            <div className="alert alert-info" style={{ textAlign: "center" }}>
              <p style={{ fontSize: "15px", marginBottom: "10px" }}>
                Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
                aido, silahkan hubungi aido support dengan click chat aido
                support.
                <br />
                <a
                  href="https://wa.me/628118481436"
                  style={{ color: "#51BED3" }}
                  target="blank"
                >
                  Chat Aido Support
                </a>
              </p>
            </div>

            {events && events.length > 0 ? (
              <>
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="input-group"
                      style={{ marginBottom: "1em" }}
                    >
                      <input
                        type="text"
                        id="searchKey"
                        className="form-control"
                        placeholder="Search event name"
                        ref={searchKey}
                      />
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => handleSearch()}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <Link to="/my-vaccination-event/nasional">
                      <button
                        className="btn btn-primary btn-block"
                        type="button"
                        style={{ marginRight: "5px", marginBottom: "1em" }}
                      >
                        Create Vaksinasi Nasional
                      </button>
                    </Link>
                  </div>

                  <div className="col-md-3">
                    <Link to="/my-vaccination-event/gotong-royong">
                      <button
                        className="btn btn-primary btn-block"
                        type="button"
                        style={{ marginBottom: "1em" }}
                      >
                        Create Vaksinasi Gotong Royong
                      </button>
                    </Link>
                  </div>
                </div>

                <div className="table-responsive mt-3 mt-md-4">
                  <table
                    id="tableScheduler"
                    className="table table-striped table-bordered"
                  >
                    <thead className="table-header">
                      <tr>
                        <th
                          rowSpan={2}
                          className="table-header-middle"
                          style={{ width: "45px" }}
                        >
                          No
                        </th>
                        <th rowSpan={2} className="table-header-middle">
                          Event ID
                        </th>
                        <th rowSpan={2} className="table-header-middle">
                          Event Name
                        </th>
                        <th rowSpan={2} className="table-header-middle">
                          Company Name
                        </th>
                        <th rowSpan={2} className="table-header-middle">
                          Start Date
                        </th>
                        <th
                          rowSpan={2}
                          className="header-action table-header-middle"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>

                    <tbody>{dataTables()}</tbody>
                  </table>
                </div>

                {totalData > 10 ? (
                  <Pagination
                    props={pagination}
                    setProps={setPagination}
                    onChange={(page, limit) => {
                      handleSearch(page, limit);
                    }}
                  />
                ) : null}
              </>
            ) : (
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center mt-5">
                    <img
                      src="/img/illustration/illustrate-vaccination-event.svg"
                      alt="illustrate-vaccination-event"
                    />
                  </div>
                  <div className="col-12 text-center mt-4">
                    <div> There are no registered events yet </div>
                  </div>

                  <div className="col-12 text-center mt-4">
                    <Link to="/my-vaccination-event/nasional">
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ marginRight: "5px", marginBottom: "1em" }}
                      >
                        Create Vaksinasi Nasional
                      </button>
                    </Link>
                    <Link to="/my-vaccination-event/gotong-royong">
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ marginBottom: "1em" }}
                      >
                        Create Vaksinasi Gotong Royong
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Fragment>
      )}
    </DashboardLayout>
  );
};

export default MyVaccinationEvent;
