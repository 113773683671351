import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import DashboardLayout from "../../../Layout";

import { useDispatch, useSelector } from "react-redux";

// Style
import "../../orderHistory.css";

// Helpers
import { formatDate } from "../../../../../helpers/getFormattedDate";

export default function OrderHistoryDetailProfile() {
    const { state } = useLocation();

    useEffect(() => {
        document.getElementById("btnUmum").click();
    }, []);

    const prevPageUrl = "/order-history/detail";
    const stateVal = {"id": state.id, "statusCode": state.statusCode, "detailData": state.detailData};   

    const patientName = state.detailData != undefined ? 
                            state.detailData.patient != undefined ?
                                state.detailData.patient.name : ""
                        : "";
    const relationName = state.detailData != undefined ? 
                            state.detailData.patient != undefined ?
                                state.detailData.patient.relations != undefined ? 
                                    state.detailData.patient.relations.name : "" 
                                : ""
                            : "";
    const birthDate = state.detailData != undefined ? 
                        state.detailData.patient != undefined ?
                            formatDate(state.detailData.patient.birthday) : ""
                    : "";
    const gender = state.detailData != undefined ? 
                        state.detailData.patient != undefined ?
                            state.detailData.patient.genderDesc : ""
                    : "";
    const bloodType = state.detailData != undefined ? 
                        state.detailData.patient != undefined ?
                            state.detailData.patient.bloods != undefined ? 
                                state.detailData.patient.bloods.name : "" 
                            : ""
                    : "";
    const hobby = state.detailData != undefined ? 
                    state.detailData.patient != undefined ?
                        state.detailData.patient.hobby : ""
                : "";
    const disease = state.detailData != undefined ? 
                        state.detailData.patient != undefined ?
                            state.detailData.patient.diseases.join(", ") : ""
                    : "";
    const medicineAllergy = state.detailData != undefined ? 
                                state.detailData.patient != undefined ?
                                    state.detailData.patient.medicineAllergy : ""
                            : "";
    const othersAllergy = state.detailData != undefined ? 
                                state.detailData.patient != undefined ?
                                    state.detailData.patient.otherAllergy : ""
                           : "";
    
    const openPage = (pageName) => {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent1");
        for (i = 0; i < tabcontent.length; i++) {
          tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablink1");
        for (i = 0; i < tablinks.length; i++) {
          tablinks[i].style.backgroundColor = "#38a8ff";
          tablinks[i].style.borderBottom = "4px solid #38a8ff";
        }
        document.getElementById(pageName).style.display = "block";
        var el = "btn"+pageName;
        document.getElementById(el).style.borderBottom = "4px solid rgba(255, 255, 255, 0.377)";
    };

    return (
        <DashboardLayout>        
            <div className="col-12" >
                <div className="card-detail" style={{background:"#38a8ff", margin:"30px 0px 0px 0px", borderRadius: "10px 10px 0px 0px"}}>
                    <Link to={{ pathname: prevPageUrl, state: stateVal }}>
                        <i className="fa fa-arrow-left" style={{fontSize: "18px", color: "white"}} aria-hidden="true" /> 
                    </Link>
                    <span style={{margin: "0px 30px 0px 30px", color: "white", fontSize: "16px"}}> 
                        {patientName}
                    </span>
                </div>
                <button className="tablink1" id="btnUmum" onClick={() => {openPage('Umum');}}>Umum</button>
                <button className="tablink1" id="btnMedis" onClick={() => {openPage('Medis');}}>Medis</button>
                    <div id="Umum" className="tabcontent1">
                        <div className="card-detail">
                            <div>
                                <i className="fa fa-user" style={{fontSize: "20px", color: "#38a8ff"}} aria-hidden="true" /> 
                                <span style={{margin: "0px 30px 0px 10px"}}> 
                                    Nama Profil
                                </span>
                            </div>
                            <div style={{fontSize: '12px', margin: '10px 30px 10px 28px'}}>
                                {patientName}
                            </div>
                        </div>
                        
                        <div className="card-detail">
                            <div>
                                <i className="fa fa-group" style={{fontSize: "20px", color: "#38a8ff"}} aria-hidden="true" /> 
                                <span style={{margin: "0px 30px 0px 10px"}}> 
                                    Hubungan
                                </span>
                            </div>
                            <div style={{fontSize: '12px', margin: '10px 30px 10px 28px'}}>
                                {relationName}
                            </div>
                        </div>
                        <div className="card-detail">
                            <div>
                                <i className="fa fa-calendar-o" style={{fontSize: "20px", color: "#38a8ff"}} aria-hidden="true" /> 
                                <span style={{margin: "0px 30px 0px 10px"}}> 
                                    Tanggal Lahir
                                </span>
                            </div>
                            <div style={{fontSize: '12px', margin: '10px 30px 10px 28px'}}>
                                {birthDate}
                            </div>
                        </div>
                        <div className="card-detail">
                            <div>
                                <i className="fa fa-venus-mars" style={{fontSize: "20px", color: "#38a8ff"}} aria-hidden="true" /> 
                                <span style={{margin: "0px 30px 0px 10px"}}> 
                                    Jenis Kelamin
                                </span>
                            </div>
                            <div style={{fontSize: '12px', margin: '10px 30px 10px 28px'}}>
                                {gender}
                            </div>
                        </div>
                        <div className="card-detail">
                            <div>
                                <i className="fa fa-tint" style={{fontSize: "20px", color: "#38a8ff"}} aria-hidden="true" /> 
                                <span style={{margin: "0px 30px 0px 10px"}}> 
                                    Golongan Darah
                                </span>
                            </div>
                            <div style={{fontSize: '12px', margin: '10px 30px 10px 28px'}}>
                                {bloodType}
                            </div>
                        </div>
                        <div className="card-detail">
                            <div>
                                <i className="fa fa-gamepad" style={{fontSize: "20px", color: "#38a8ff"}} aria-hidden="true" /> 
                                <span style={{margin: "0px 30px 0px 10px"}}> 
                                    Hobi
                                </span>
                            </div>
                            <div style={{fontSize: '12px', margin: '10px 30px 10px 28px'}}>
                                {hobby}
                            </div>
                        </div>
                    </div>

                    <div id="Medis" className="tabcontent1">
                        <div className="card-detail">
                            <div>
                                <span style={{margin: "0px 30px 0px 10px"}}> 
                                    Riwayat Penyakit
                                </span>
                            </div>
                            <div style={{fontSize: '12px', margin: '10px 30px 10px 28px'}}>
                                {disease}
                            </div>
                        </div>
                        <div className="card-detail">
                            <div>
                                <i className="fa fa-medkit" style={{fontSize: "20px", color: "#38a8ff"}} aria-hidden="true" /> 
                                <span style={{margin: "0px 30px 0px 10px"}}> 
                                    Alergi Obat
                                </span>
                            </div>
                            <div style={{fontSize: '12px', margin: '10px 30px 10px 28px'}}>
                                {medicineAllergy}
                            </div>
                        </div>
                        <div className="card-detail">
                            <div>
                                <i className="fa fa-medkit" style={{fontSize: "20px", color: "#38a8ff"}} aria-hidden="true" /> 
                                <span style={{margin: "0px 30px 0px 10px"}}> 
                                    Alergi Lain-lain
                                </span>
                            </div>
                            <div style={{fontSize: '12px', margin: '10px 30px 10px 28px'}}>
                                {othersAllergy}
                            </div>
                        </div>
                    </div>
            </div>
        </DashboardLayout>
    );
};