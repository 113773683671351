import axios from "axios";
import Swal from "sweetalert2";
import axiosPartnerApi from "../helpers/axiosPartnerApi";
import { partnerApi } from "../urlConfig";
import { testCovidConstants } from "./constants";

export const getCovidList = (query) => {
  return async (dispatch) => {
    dispatch({ type: testCovidConstants.GET_LIST_REQUEST });
    const res = await axiosPartnerApi.get(`/Package/1/List?${query}`);
    if (res.status === 200) {
      dispatch({
        type: testCovidConstants.GET_LIST_SUCCESS,
        payload: { listData: res.data },
      });
    } else {
      dispatch({
        type: testCovidConstants.GET_LIST_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getCovidAreaList = () => {
  return async (dispatch) => {
    dispatch({ type: testCovidConstants.GET_AREA_LIST_REQUEST });
    const res = await axiosPartnerApi.get("/PackageGroup/1/Area");
    if (res.status === 200) {
      dispatch({
        type: testCovidConstants.GET_AREA_LIST_SUCCESS,
        payload: { areaListData: res.data },
      });
    } else {
      dispatch({
        type: testCovidConstants.GET_AREA_LIST_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getCovidDetail = (id) => {
  return async (dispatch) => {
    dispatch({ type: testCovidConstants.GET_DETAIL_REQUEST });
    const res = await axiosPartnerApi.get(`/Package/Service/${id}`);
    if (res.status === 200) {
      dispatch({
        type: testCovidConstants.GET_DETAIL_SUCCESS,
        payload: { detailData: res.data },
      });
    } else {
      dispatch({
        type: testCovidConstants.GET_DETAIL_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const createOtp = (body) => {
  return async (dispatch) => {
    dispatch({ type: testCovidConstants.CREATE_OTP_REQUEST });
    const res = await axiosPartnerApi.post("/CreateOtp/V2", {
      ...body,
    });

    if (res.status === 200) {
      const { message, userId, phone, email } = res.data;
      if (message == "Success.") {
        if (body.phone) {
          dispatch({
            type: testCovidConstants.CREATE_OTP_SUCCESS,
            payload: {
              message,
              userId,
              phone,
            },
          });
        } else {
          dispatch({
            type: testCovidConstants.CREATE_OTP_SUCCESS,
            payload: {
              message,
              userId,
              email,
            },
          });
        }
      } else {
        let customerEmailOrPhone = body.phone ? body.phone : body.email;
        dispatch({
          type: testCovidConstants.CREATE_OTP_FAILURE,
          payload: { error: { message, customerEmailOrPhone } },
        });
        Swal.fire("Error", message, "error");
      }
    } else {
      dispatch({
        type: testCovidConstants.CREATE_OTP_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
export const createOtpRegister = (form) => {
  return async (dispatch) => {
    dispatch({ type: testCovidConstants.REGISTER_OTP_REQUEST });
    const res = await axiosPartnerApi.post(`/CreateOtp/Register`, form);
    if (res.status === 200) {
      const { message, userId, phone, email } = res.data;

      if (message == "Success") {
        dispatch({
          type: testCovidConstants.REGISTER_OTP_SUCCESS,
          payload: {
            message,
            userId,
            phone,
            email,
          },
        });
      } else {
        dispatch({
          type: testCovidConstants.REGISTER_OTP_SUCCESS,
          payload: { error: message },
        });
        Swal.fire({
          icon: "error",
          text: message,
        });
      }
    } else {
      dispatch({
        type: testCovidConstants.REGISTER_OTP_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const verifyOtp = (customer) => {
  return async (dispatch) => {
    dispatch({ type: testCovidConstants.VERIFY_OTP_REQUEST });
    const res = await axiosPartnerApi.post("/App/Customer/VerifyOtp/Auth", {
      ...customer,
    });

    if (res.status === 200) {
      console.log("res=", res.data);
      const { message, accessToken, user } = res.data;

      if (message == "Success") {
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("userCustomer", JSON.stringify(user));
        dispatch({
          type: testCovidConstants.VERIFY_OTP_SUCCESS,
          payload: {
            message,
            accessToken,
            user,
          },
        });
      } else {
        let customerOtp = customer.otp;
        dispatch({
          type: testCovidConstants.VERIFY_OTP_FAILURE,
          payload: { error: { message, customerOtp } },
        });
      }
    } else {
      if (res.status === 400) {
        dispatch({
          type: testCovidConstants.VERIFY_OTP_FAILURE,
          payload: { error: res.data.error },
        });
      }
    }
  };
};

export const verifyOtpEmail = (customer) => {
  return async (dispatch) => {
    dispatch({ type: testCovidConstants.VERIFY_OTP_REQUEST });
    const res = await axiosPartnerApi.post(
      "/App/Customer/VerifyOtpEmail/Auth",
      {
        ...customer,
      }
    );

    if (res.status === 200) {
      console.log("res=", res.data);
      const { message, accessToken, user } = res.data;

      if (message == "Success") {
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("userCustomer", JSON.stringify(user));
        dispatch({
          type: testCovidConstants.VERIFY_OTP_SUCCESS,
          payload: {
            message,
            accessToken,
            user,
          },
        });
      } else {
        let customerOtp = customer.otp;
        dispatch({
          type: testCovidConstants.VERIFY_OTP_FAILURE,
          payload: { error: { message, customerOtp } },
        });
      }
    } else {
      if (res.status === 400) {
        dispatch({
          type: testCovidConstants.VERIFY_OTP_FAILURE,
          payload: { error: res.data.error },
        });
      }
    }
  };
};

export const initializeCustomer = (form) => {
  return async (dispatch) => {
    dispatch({ type: testCovidConstants.INITIALIZE_CUSTOMER_REQUEST });
    const accessToken = window.localStorage.getItem("accessToken");
    const res = await axios
      .create({
        baseURL: partnerApi,
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : "",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .post("/App/Customer/Me/Initialize", form);
    if (res.status === 200) {
      dispatch({
        type: testCovidConstants.INITIALIZE_CUSTOMER_SUCCESS,
        payload: { initData: res.data },
      });
    } else {
      dispatch({
        type: testCovidConstants.INITIALIZE_CUSTOMER_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getCustomerSnorlax = () => {
  return async (dispatch) => {
    dispatch({ type: testCovidConstants.GET_CUSTOMER_REQUEST });
    const accessToken = window.localStorage.getItem("accessToken");
    const res = await axios
      .create({
        baseURL: partnerApi,
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : "",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .get("/App/Snorlax/Me");
    if (res.status === 200) {
      dispatch({
        type: testCovidConstants.GET_CUSTOMER_SUCCESS,
        payload: { customerData: res.data },
      });
    } else {
      dispatch({
        type: testCovidConstants.GET_CUSTOMER_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const createCustomerSnorlax = (form) => {
  return async (dispatch) => {
    dispatch({ type: testCovidConstants.CREATE_CUSTOMER_REQUEST });
    const accessToken = window.localStorage.getItem("accessToken");
    const res = await axios
      .create({
        baseURL: partnerApi,
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : "",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .post("/App/Snorlax/Me", form);
    if (res.status === 200) {
      dispatch({
        type: testCovidConstants.CREATE_CUSTOMER_SUCCESS,
        payload: { createdData: res.data },
      });
    } else {
      dispatch({
        type: testCovidConstants.CREATE_CUSTOMER_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const updateCustomerSnorlax = (form) => {
  return async (dispatch) => {
    dispatch({ type: testCovidConstants.UPDATE_CUSTOMER_REQUEST });
    const accessToken = window.localStorage.getItem("accessToken");
    const res = await axios
      .create({
        baseURL: partnerApi,
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : "",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .put("/App/Snorlax/Me", form);
    if (res.status === 200) {
      dispatch({
        type: testCovidConstants.UPDATE_CUSTOMER_SUCCESS,
        payload: { updatedData: res.data },
      });
    } else {
      dispatch({
        type: testCovidConstants.UPDATE_CUSTOMER_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const createAddressSnorlax = (form) => {
  return async (dispatch) => {
    dispatch({ type: testCovidConstants.CREATE_ADDRESS_REQUEST });
    const accessToken = window.localStorage.getItem("accessToken");
    const res = await axios
      .create({
        baseURL: partnerApi,
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : "",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .post("/App/Customer/Me/Address", form);
    if (res.status === 200) {
      dispatch({
        type: testCovidConstants.CREATE_ADDRESS_SUCCESS,
        payload: { addressData: res.data },
      });
    } else {
      dispatch({
        type: testCovidConstants.CREATE_ADDRESS_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getPaymentMethod = (query) => {
  return async (dispatch) => {
    dispatch({ type: testCovidConstants.GET_PAYMENT_REQUEST });
    const accessToken = window.localStorage.getItem("accessToken");
    const res = await axios
      .create({
        baseURL: partnerApi,
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : "",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .get(`/App/Snorlax/PaymentGroup?${query}`);
    if (res.status === 200) {
      dispatch({
        type: testCovidConstants.GET_PAYMENT_SUCCESS,
        payload: { paymentMethodData: res.data },
      });
    } else {
      dispatch({
        type: testCovidConstants.GET_PAYMENT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const createPayment = (form) => {
  return async (dispatch) => {
    dispatch({ type: testCovidConstants.CREATE_PAYMENT_REQUEST });
    const accessToken = window.localStorage.getItem("accessToken");
    const res = await axios
      .create({
        baseURL: partnerApi,
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : "",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .post("/Package/Order/confirmOrder", form);
    if (res.status === 200) {
      dispatch({
        type: testCovidConstants.CREATE_PAYMENT_SUCCESS,
        payload: { paymentData: res.data },
      });
    } else {
      dispatch({
        type: testCovidConstants.CREATE_PAYMENT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
