import { myProceduresConstants } from "../actions/constants";

const initState = {
  procedures: [],
  totalData: "",
  proceduresData: {},
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case myProceduresConstants.GET_ALL_PROCEDURES_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myProceduresConstants.GET_ALL_PROCEDURES_SUCCESS:
      state = {
        ...state,
        procedures: action.payload.procedures,
        totalData: action.payload.count,
        loading: false,
      };
      break;
    case myProceduresConstants.GET_ALL_PROCEDURES_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myProceduresConstants.GET_PROCEDURES_BY_ID_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myProceduresConstants.GET_PROCEDURES_BY_ID_SUCCESS:
      state = {
        ...state,
        proceduresData: action.payload.proceduresData,
        loading: false,
      };
      break;
    case myProceduresConstants.GET_PROCEDURES_BY_ID_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myProceduresConstants.ADD_NEW_PROCEDURES_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myProceduresConstants.ADD_NEW_PROCEDURES_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case myProceduresConstants.ADD_NEW_PROCEDURES_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myProceduresConstants.UPDATE_PROCEDURES_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myProceduresConstants.UPDATE_PROCEDURES_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case myProceduresConstants.UPDATE_PROCEDURES_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myProceduresConstants.DELETE_PROCEDURES_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myProceduresConstants.DELETE_PROCEDURES_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case myProceduresConstants.DELETE_PROCEDURES_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
  }
  return state;
};
