import { monthlyReportConstants } from "../actions/constants";

const initState = {
  teleOrders: [],
  pharmacyOrders: [],
  homevisit: [],
  loading: false,
  error: null,
};

export default (state = initState, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case monthlyReportConstants.GET_MONTHLY_REPORT_SUCCESS:
        state = {
          ...state,
          teleOrders: action.payload.teleOrders,
          pharmacyOrders: action.payload.pharmacyOrders,
          homevisit: action.payload.homevisit,
          loading: false,
        };
        break;
      case monthlyReportConstants.GET_MONTHLY_REPORT_REQUEST:
        state = {
          ...state,
          loading: true,
        };
        break;
      case monthlyReportConstants.GET_MONTHLY_REPORT_FAILURE:
        state = {
          ...state,
          error: action.payload.error,
          loading: false,
        };
        break;
    }
    return state;        
  };
  