import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReportLink } from "../../../../actions";
import DashboardLayout from "../../Layout";
import queryString from "query-string";

const ReportingPharmacyDelivery = () => {

    const pharmacyLink = useSelector((state) => state.pharmacyDelivery);
    const dispatch = useDispatch();

    useEffect(() => {
        let values = {
            partnerId: localStorage.getItem("parentId"),
        },
        query = queryString.stringify(values);
        dispatch(getReportLink(query));
    }, []);

    useEffect(() => {
      if ( pharmacyLink.link != "" && pharmacyLink.totalData > 0 ) {
        window.open(pharmacyLink.link);
      }
    }, [pharmacyLink.totalData]);

    return (
      <DashboardLayout>
        {pharmacyLink.link != "" && pharmacyLink.totalData > 0 ? (
          <div
            style={{
              minHeight: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "35px",
              textAlign: "center",
              color:"#6c757d"
            }}
          >
            <div>
              Anda akan diarahkan ke report eksternal, <br/>jika tidak terbuka silahkan klik link berikut :
              <br/><a href={pharmacyLink.link} 
              target="_blank" className="btn btn-primary" style={{marginTop:"30px"}}>Report Pharmacy Delivery</a>
            </div>
          </div>
        ): (
          <div
            style={{
              minHeight: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "35px",
              textAlign: "center",
              color:"#6c757d"
            }}
          >
            <div>
              Anda tidak memiliki akses ke report eksternal
            </div>
          </div>
        )}
      </DashboardLayout>
    );

};

export default ReportingPharmacyDelivery;
