import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../Layout";
import { createOtp } from "../../../actions";
import { Form, Alert, Button } from "react-bootstrap";
import Swal from "sweetalert2";

const Login = () => {
  const history = useHistory();
  const { state } = useLocation();
  const [credential, setCredential] = useState("");
  const [errors, setErrors] = useState({
    credential: false,
  });
  const testCovid = useSelector((state) => state.testCovid);
  const dispatch = useDispatch();

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (testCovid.userId) {
      setIsValid(true);
    }
  }, [testCovid.userId, isValid]);

  const sendOtp = () => {
    if (credential !== "") {
      const patternEmail =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      const patternPhone =
        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
      const emailFlag = patternEmail.test(credential);
      const phoneFlag = patternPhone.test(credential);

      if (phoneFlag) {
        dispatch(createOtp({ phone: credential }))
          .then(() => {
            history.push({
              pathname: "/test-covid/verifyOtp",
              state: state,
            });
          })
          .catch((err) => {
            if (err.response) {
              Swal.fire({
                icon: "error",
                text: err.response.data.message,
              });
            } else {
              Swal.fire({
                icon: "error",
                text: err.message,
              });
            }
          });
      } else if (emailFlag) {
        dispatch(createOtp({ email: credential }))
          .then(() => {
            history.push({
              pathname: "/test-covid/verifyOtp",
              state: state,
            });
          })
          .catch((err) => {
            if (err.response) {
              Swal.fire({
                icon: "error",
                text: err.response.data.message,
              });
            } else {
              Swal.fire({
                icon: "error",
                text: err.message,
              });
            }
          });
      } else {
        Swal.fire("Error", "Please enter an email address or phone", "error");
      }
    } else {
      Swal.fire("Error", "cannot empty", "error");
    }
  };

  return (
    <Layout link="/test-covid/detail" state={state}>
      <div className="row justify-content-center">
        <div className="login-logo-tc">
          <img
            data-cfsrc="/img/logo/main-logo.png"
            src="/img/logo/main-logo.png"
            alt="aido-logo"
          />
        </div>
      </div>

        <h4>Masuk</h4>
        <Form.Group className="mb-3" controlId="formGroupEmail">
          <Form.Label style={{ fontWeight: 600 }}>
            Nomor Telepon atau Email
          </Form.Label>

          <Form.Control
            type="text"
            placeholder=""
            value={credential}
            onChange={(e) => setCredential(e.target.value)}
            readOnly={testCovid.authenticate}
          />

          <Form.Text id="exampleLogin" muted>
            Contoh: 08123456789 atau nama@email.com
          </Form.Text>
        </Form.Group>

        {errors.credential && (
          <div style={{ color: "red", marginTop: "20px", textAlign: "center" }}>
            <label>
              {testCovid.error ? testCovid.error.message : "Not found"}
            </label>
          </div>
        )}

        {!testCovid.authenticate && (
          <Alert
            variant={"danger"}
            style={{ color: "#000000", marginBottom: "40px" }}
          >
            Karena adanya gangguan, jika kamu pengguna 3 (Tri) silahkan masuk
            via email.
          </Alert>
        )}

        <div>
          {!testCovid.authenticate && (
            <Button className="btn-block" variant="primary" onClick={sendOtp}>
              Masuk
            </Button>
          )}
        </div>
    </Layout>
  );
};

export default Login;
