import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import DashboardLayout from "../../../../Layout";

import { useDispatch, useSelector } from "react-redux";

import {
    getAdditionalService,
} from "../../../../../../actions";

// Style
import "../../../orderHistory.css";

export default function OrderHistoryDetailListProsedur() {
    const result = useSelector((state) => state.orderHistory);
    const dispatch = useDispatch(); 
    const { state } = useLocation();

    const parentId = state.detailData != undefined ? 
                        state.detailData.partner != undefined ? state.detailData.partner.parentId : 0
                        : 0;
    const professionId = state.detailData != undefined ? 
                            state.detailData.partner != undefined ? 
                                state.detailData.partner.profession != undefined ? state.detailData.partner.profession.id : 0
                                : 0
                            : 0;

    const formData = new FormData();
    formData.append("parentId", parentId);
    formData.append("professionId", professionId);

    useEffect(() => {
        dispatch(getAdditionalService(formData));
    }, []);

    const layananData = result.additionalService != undefined ? result.additionalService : [];
    const allProducts = layananData.length != 0 ? layananData.filter(item => item.productType == 2) : [];

    const nf = new Intl.NumberFormat();

    const prevPageUrl = "/order-history/detail/lihatriwayat";
    const stateVal = { "id" : state.id, "statusCode": state.statusCode, "detailData": state.detailData, "orderId": state.orderId };
    
    return (
        <DashboardLayout>        
            <div className="col-12" >
                <div className="card-detail" style={{background:"#38a8ff", margin:"30px 0px 20px 0px", borderRadius: "10px 10px 0px 0px"}}>
                    <Link to={{ pathname: prevPageUrl, state: stateVal}}>
                        <i className="fa fa-arrow-left" style={{fontSize: "18px", color: "white"}} aria-hidden="true" /> 
                    </Link>
                    <span style={{margin: "0px 30px 0px 30px", color: "white", fontSize: "16px"}}> 
                        Lihat Prosedur
                    </span>
                </div>
                <div>
                    {allProducts.length > 0 ? 
                        (
                            allProducts.map((data, index) => (
                                <div className="card-detail" key={data.id}>
                                    <div style={{margin: "10px 0px 10px 0px"}}>
                                        <span style={{margin: "0px 10px 0px 0px", fontWeight: "bold"}}> 
                                            {data.productName}
                                        </span>
                                    </div>
                                    <div style={{margin: "0px 0px 10px 0px"}}>
                                        <i className="fa fa-money" style={{fontSize: "16px", color: "#38a8ff"}} aria-hidden="true" /> 
                                        <span style={{margin: "0px 10px 0px 10px"}}> 
                                            Rp. {nf.format(data.price)},-
                                        </span>
                                    </div>
                                </div>
                            ))
                        ) 
                        : 
                        (
                            <h5 style={{fontSize:"18px", color: "grey", margin:"20px 20px 20px 20px"}}>Data prosedur tidak tersedia..</h5>
                        )
                    }
                </div>
            </div>
        </DashboardLayout>
    );
};