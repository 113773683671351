import React from "react";

const Breadcrumb = ({ listMenu }) => {
  const pathUrl = window.location.pathname;

  return (
    <>
      {listMenu.map((menu) => {
        return pathUrl === menu.path ? (
          <nav key={menu.label} aria-label="breadcrumb" id="breadcrumb-id">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href={menu.path}>Dashboard</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {menu.label}
              </li>
            </ol>
          </nav>
        ) : pathUrl === menu.childPath ? (
          <nav key={menu.childLabel} aria-label="breadcrumb" id="breadcrumb-id">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href={menu.path}>Dashboard</a>
              </li>
              <li className="breadcrumb-item">
                <a href={menu.path}>{menu.label}</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {menu.childLabel}
              </li>
            </ol>
          </nav>
        ) : pathUrl === menu.childPath2 ? (
          <nav
            key={menu.childLabel2}
            aria-label="breadcrumb"
            id="breadcrumb-id"
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href={menu.path}>Dashboard</a>
              </li>
              <li className="breadcrumb-item">
                <a href={menu.path}>{menu.label}</a>
              </li>
              <li className="breadcrumb-item active">{menu.childLabel}</li>
              <li className="breadcrumb-item active" aria-current="page">
                {menu.childLabel2}
              </li>
            </ol>
          </nav>
        ) : null;
      })}
    </>
  );
};

export default Breadcrumb;
