import React from "react";
import { Link } from "react-router-dom";
import "./sideMenu.scss";

const SideMenu = ({ listMenu }) => {
  return (
    <>
      <div className="sidebar-header text-center">
        <img
          className="sidebar-logo"
          data-cfsrc="/img/logo/textonly-horizontal-nobg-whitetext.png"
          src="/img/logo/textonly-horizontal-nobg-whitetext.png"
          alt="aido-logo"
        />
      </div>
      <ul className="list-unstyled components">
        {listMenu &&
          listMenu.map((menu) => (
            <li
              key={menu.label}
              id={`${menu.path}`}
              className="list-menu"
              style={
                window.location.pathname === menu.path ||
                window.location.pathname === menu.childPath ||
                window.location.pathname === menu.childPath2
                  ? {
                      fontWeight: "bold",
                      backgroundColor: "blueviolet",
                    }
                  : null
              }
            >
              <Link to={menu.path}>
                <i className="sidebar-icon">
                  <img
                    className="sidebar-icon"
                    data-cfsrc={menu.icon}
                    src={menu.icon}
                    alt="icon"
                  />
                </i>
                <span className="sidebar-title">{menu.label}</span>
              </Link>
            </li>
          ))}
      </ul>
    </>
  );
};

export default SideMenu;
