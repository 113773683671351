import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPharmacyDeliveryAll } from "../../../actions";
import Pagination from "../../../components/Pagination";
import DashboardLayout from "../Layout";
import queryString from "query-string";
import moment from "moment";
import "./index.scss";

const PharmacyDeliveryAll = () => {
  const partnerId = localStorage.getItem("parentId");
  const [columnCancel, setColumnCancel] = useState(false);
  const [search, setSearch] = useState(`partnerId=${partnerId}&orderStatus=21`);
  const [status, setStatus] = useState([
    {
      id: 21,
      title: "Belum Dibayar",
      desc: "totalOrdersNotPaid",
      active: true,
    },
    {
      id: 9,
      title: "Pending Bayar",
      desc: "totalOrdersPending",
      active: false,
    },
    {
      id: 22,
      title: "Sudah Dibayar",
      desc: "totalOrdersPaid",
      active: false,
    },
    {
      id: 23,
      title: "Dalam Pengiriman",
      desc: "totalOrdersProcess",
      active: false,
    },
    {
      id: 99,
      title: "Selesai",
      desc: "totalOrdersDone",
      active: false,
    },
    {
      id: 89,
      title: "Dibatalkan",
      desc: "totalOrdersCancel",
      active: false,
    },
  ]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const searchKey = useRef("");
  const pharmacyDeliveryAll = useSelector((state) => state.pharmacyDeliveryAll);
  const dispatch = useDispatch();

  const role = JSON.parse(localStorage.getItem("user"));
  const roleUser = role.user_role_management_id.id;

  useEffect(() => {
    dispatch(getPharmacyDeliveryAll(search));
  }, [search]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: pharmacyDeliveryAll.totalData,
    });
  }, [pharmacyDeliveryAll.totalData]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const handleReset = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let orderStatus = status.filter((data) => data.active === true)[0].id;

    let values = {
      partnerId: partnerId,
      orderStatus: orderStatus,
      page: pagination.page,
      limit: pagination.limit,
    };

    if (searchKey.current.value !== "") {
      searchKey.current.value = "";
    }

    let query = queryString.stringify(values);

    setSearch(query);
  };

  const handleSearch = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let orderStatus = status.filter((data) => data.active === true)[0].id;

    let values = {
      partnerId: partnerId,
      orderStatus: orderStatus,
      searchKey: searchKey.current.value,
      page: pagination.page,
      limit: pagination.limit,
    };

    let query = queryString.stringify(values);

    setSearch(query);
  };

  const handlePage = (page, limit) => {
    let orderStatus = status.filter((data) => data.active === true)[0].id;

    let values = {
      partnerId: partnerId,
      orderStatus: orderStatus,
      searchKey: searchKey.current.value,
    };

    if (page) {
      values.page = page;
    } else {
      values.page = 1;

      setPagination({
        ...pagination,
        page: 1,
      });
    }

    if (limit) {
      values.limit = limit;
    }

    let query = queryString.stringify(values);

    setSearch(query);
  };

  const handleStatus = (index) => {
    if (index == 5) {
      setColumnCancel(true);
    } else {
      setColumnCancel(false);
    }

    setPagination({
      ...pagination,
      page: 1,
    });

    let data = [
      {
        id: 21,
        title: "Belum Dibayar",
        desc: "totalOrdersNotPaid",
        active: false,
      },
      {
        id: 9,
        title: "Pending Bayar",
        desc: "totalOrdersPending",
        active: false,
      },
      {
        id: 22,
        title: "Sudah Dibayar",
        desc: "totalOrdersPaid",
        active: false,
      },
      {
        id: 23,
        title: "Dalam Pengiriman",
        desc: "totalOrdersProcess",
        active: false,
      },
      {
        id: 99,
        title: "Selesai",
        desc: "totalOrdersDone",
        active: false,
      },
      {
        id: 89,
        title: "Dibatalkan",
        desc: "totalOrdersCancel",
        active: false,
      },
    ];

    data[index].active = true;

    setStatus(data);

    let orderStatus = data.filter((data) => data.active === true)[0].id;

    let values = {
      partnerId: partnerId,
      orderStatus: orderStatus,
      searchKey: searchKey.current.value,
      page: pagination.page,
      limit: pagination.limit,
    };

    let query = queryString.stringify(values);

    setSearch(query);
  };

  const renderStatus = () => {
    return status.map((data, index) => (
      <div
        className="col-md mb10"
        style={{ display: "flex", justifyContent: "center" }}
        key={data.id}
      >
        <button
          type="button"
          className={`btn ${
            data.active ? "btn-primary" : "btn-outline-primary"
          }`}
          style={{ width: "100%" }}
          onClick={() => handleStatus(index)}
        >
          {`${
            pharmacyDeliveryAll.totalOrders
              ? !pharmacyDeliveryAll.payFromAido && data.id === 22 && roleUser != 43 && roleUser != 44
                ? "Bayar ke RS" : (roleUser == 43 || roleUser == 44) && data.id == 22 ? "Sudah Dibayar/Bayar ke RS"
                : data.title
              : "Memuat..."
          } (${
            pharmacyDeliveryAll.totalOrders
              ? pharmacyDeliveryAll.totalOrders[data.desc]
              : 0
          })`}
        </button>
      </div>
    ));
  };

  const renderPharmacyAll = () => {
    return pharmacyDeliveryAll.loading ? (
      <tr>
        <td colSpan={(roleUser == 43 || roleUser == 44) ? "10" : "8"}>
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        {pharmacyDeliveryAll.orders && pharmacyDeliveryAll.orders.length > 0 ? (
          pharmacyDeliveryAll.orders.map((data, index) => (
            <tr key={index}>
              <td>{(pagination.page - 1) * 10 + index + 1}</td>
              <td>{data.orderId ? data.orderId : "-"}</td>
              <td>
                {data.createdAt ? moment(data.createdAt).format("lll") : "-"}
              </td>
              <td>{data.patientName ? data.patientName : "-"}</td>
              <td>
                {data.patientDOB ? moment(data.patientDOB).format("ll") : "-"}
              </td>
              <td>{data.patientPhone ? data.patientPhone : "-"}</td>
              <td>{data.patientAddress ? data.patientAddress : data.patientSiloAddress ? data.patientSiloAddress : "-"}</td>
              <td>{data.deliveryName ? data.deliveryName : "-"}</td>
              {columnCancel ? <td>{data.notes ? data.notes : "-"}</td> : ""}
              {(roleUser == 43 || roleUser == 44) ?
              <td>
                {data.orderType == 2
                  ? "Tele"
                  : data.orderType == 13
                  ? "MySiloam"
                  : "Unit"}
              </td> : ""}
              {(roleUser == 43 || roleUser == 44) ? <td>{data.pharmacyName ? data.pharmacyName : "-"}</td> : ""}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={(roleUser == 43 || roleUser == 44) ? "10" : "8"}>Empty data</td>
          </tr>
        )}
      </>
    );
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>{(roleUser == 43 || roleUser == 44) ? "All Pharmacy Delivery (Today)" : "All Pharmacy Delivery Offline (Today)"}</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        <div className="row">
          <div className="col-md">
            <span id="finance-title" className="mb10">
              Status
            </span>
          </div>
        </div>

        <div className="row pb-4">{renderStatus()}</div>

        <div className="row d-flex justify-content-end">
          <div className="col-md-4 pb-2">
            <input
              type="text"
              className="form-control table-search-field"
              placeholder={(roleUser == 43 || roleUser == 44) ? "Search Patient Name/Phone/Order ID/Unit Name" : "Search Patient Name/Phone/Order ID"}
              ref={searchKey}
            />
          </div>
        </div>

        <div className="row d-flex justify-content-end">
          <div className="col-md-3 pb-4 text-right">
            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100px", marginRight: "5px" }}
              onClick={() => handleSearch()}
            >
              Search
            </button>

            <button
              type="button"
              className="btn btn-outline-primary"
              style={{ width: "100px" }}
              onClick={() => handleReset()}
            >
              Reset
            </button>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "45px" }}
                >
                  No
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "100px" }}
                >
                  Order Id Pharmacy
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "100px" }}
                >
                  Created At
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Patient Name
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Patient DOB
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Patient Phone
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Patient Address
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Delivery Method
                </th>

                {columnCancel ? (
                  <th rowSpan={2} className="table-header-middle">
                    Cancel Reason
                  </th>
                ) : (
                  ""
                )}

                {(roleUser == 43 || roleUser == 44) && (
                  <th rowSpan={2} className="table-header-middle">
                    Channel
                  </th>
                )}

                {(roleUser == 43 || roleUser == 44) && (
                  <th rowSpan={2} className="table-header-middle">
                    Unit Name
                  </th>
                )}
              </tr>
            </thead>
            <tbody>{renderPharmacyAll()}</tbody>
          </table>
        </div>

        {pharmacyDeliveryAll.totalData > 10 ? (
          <Pagination
            props={pagination}
            setProps={setPagination}
            onChange={(page, limit) => {
              handlePage(page, limit);
            }}
          />
        ) : null}
      </div>
    </DashboardLayout>
  );
};

export default PharmacyDeliveryAll;
