import axios from "../helpers/axios";
import { orderDailyReportConstants } from "./constants";

export const findReport = (form) => {
  return async (dispatch) => {
    dispatch({
      type: orderDailyReportConstants.FIND_ORDER_DAILY_REPORT_REQUEST,
    });
    const res = await axios.post("/Order/Report/Daily", form);
    if (res.status === 200) {
      dispatch({
        type: orderDailyReportConstants.FIND_ORDER_DAILY_REPORT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: orderDailyReportConstants.FIND_ORDER_DAILY_REPORT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const findReportV2 = (form) => {
  return async (dispatch) => {
    dispatch({
      type: orderDailyReportConstants.FIND_ORDER_DAILY_REPORT_REQUEST,
    });
    const res = await axios.post("/Order/Report/DailyV2", form);
    if (res.status === 200) {
      dispatch({
        type: orderDailyReportConstants.FIND_ORDER_DAILY_REPORT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: orderDailyReportConstants.FIND_ORDER_DAILY_REPORT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
