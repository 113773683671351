import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import {
  findPatients,
  getCountry,
  getProvince,
  getCity,
  getDistrict,
  getSubDistrict,
  getPostalCode,
  findAddress,
  submitPayment,
} from "../../../../actions";
import GoogleMap from "../../Components/GoogleMap";
import Input from "../../Components/Input";
import Modal from "../../Components/Modal";
import DashboardLayout from "../../Layout";
import queryString from "query-string";
import moment from "moment";
import Swal from "sweetalert2";

const PaymentOfflineAdd = () => {
  const [patientData, setPatientData] = useState({
    customerId: "",
    patientId: "",
    patientName: "",
    patientEmail: "",
    patientDob: "",
    patientGender: "",
    patientAddress: "",
    patientNational: "",
    patientNationalOption: "",
    patientPhone: "",
    patientCountry: "",
    patientProvince: "",
    patientCity: "",
    patientDistrict: "",
    patientSubDistrict: "",
    patientPostalCode: "",
  });
  const [newPatientData, setNewPatientData] = useState({
    patientName: "",
    patientEmail: "",
    patientDob: "",
    patientGender: "",
    patientAddress: "",
    patientNational: "",
    patientNationalOption: "",
    patientPhone: "",
    patientCountry: "",
    patientProvince: "",
    patientCity: "",
    patientDistrict: "",
    patientSubDistrict: "",
    patientPostalCode: "",
  });
  const [totalNewPatientData, setTotalNewPatientData] = useState([]);
  const [teleconsultation, setTeleconsultation] = useState({
    teleServiceFee: "",
  });
  const [addressData, setAddressData] = useState([]);
  const [validation, setValidation] = useState({
    patientForm: false,
  });
  const [addPatientModal, setAddPatientModal] = useState(false);
  const [addNewButton, setAddNewButton] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [customerId, setCustomerId] = useState("");
  const name = useRef("");
  const birthday = useRef("");
  const auth = useSelector((state) => state.auth);
  const paymentOffline = useSelector((state) => state.paymentOffline);
  const dispatch = useDispatch();

  const initState = () => {
    setNewPatientData({
      patientName: "",
      patientEmail: "",
      patientDob: "",
      patientGender: "",
      patientAddress: "",
      patientNational: "",
      patientNationalOption: "",
      patientPhone: "",
      patientCountry: "",
      patientProvince: "",
      patientCity: "",
      patientDistrict: "",
      patientSubDistrict: "",
      patientPostalCode: "",
    });
    setAddNewButton(0);
  };

  useEffect(() => {
    if (customerId) {
      let values = {
          customerId: customerId,
        },
        query = queryString.stringify(values);

      dispatch(findAddress(query));
    }
  }, [customerId]);

  useEffect(() => {
    dispatch(getCountry());
  }, []);

  const handleGetProvince = (id) => {
    dispatch(getProvince(id));
    setNewPatientData({
      ...newPatientData,
      patientCountry: id,
    });
  };

  const handleGetCity = (id) => {
    dispatch(getCity(id));
    setNewPatientData({
      ...newPatientData,
      patientProvince: id,
    });
  };

  const handleGetDistrict = (id) => {
    dispatch(getDistrict(id));
    setNewPatientData({
      ...newPatientData,
      patientCity: id,
    });
  };

  const handleGetSubDistrict = (id) => {
    dispatch(getSubDistrict(id));
    setNewPatientData({
      ...newPatientData,
      patientDistrict: id,
    });
  };

  const handleGetPostalCode = (id) => {
    dispatch(getPostalCode(id));
    setNewPatientData({
      ...newPatientData,
      patientSubDistrict: id,
    });
  };

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    showCloseButton: true,
    timer: 3000,
  });

  const handleCloseModal = () => {
    initState();
    setAddPatientModal(false);
  };

  const handleNext = (nextStep) => {
    if(nextStep == 1 && patientData.patientId == ""){
      return Swal.fire({
        icon: "warning",
        title: "Choose patient!",
        showConfirmButton: false,
      })    
    }
    
    if(nextStep == 2 && teleconsultation.teleServiceFee == "" )
    {
      return Swal.fire({
        icon: "warning",
        title: "Fill all mandatory fields!",
        showConfirmButton: false,
      }) 
    }
    setActiveStep((step) => step + 1);
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const actionSubmitPayment = () => {
    const form = new FormData();
    if (patientData.customerId && patientData.patientId) {
      form.append("customerId", patientData.customerId);
      form.append("patientId", patientData.patientId);
    }
    form.append("patientName", patientData.patientName);
    form.append("patientEmail", patientData.patientEmail);
    form.append("patientDob", patientData.patientDob);
    form.append("patientGender", patientData.patientGender);
    form.append("patientAddress", patientData.patientAddress);
    form.append("patientNational", patientData.patientNational);
    form.append("patientNationalOption", patientData.patientNationalOption);
    form.append("patientPhone", patientData.patientPhone);
    form.append("patientCountry", patientData.patientCountry);
    form.append("patientProvince", patientData.patientProvince);
    form.append("patientCity", patientData.patientCity);
    form.append("patientDistrict", patientData.patientDistrict);
    form.append("patientSubDistrict", patientData.patientSubDistrict);
    form.append("patientPostalCode", patientData.patientPostalCode);
    form.append("partnerId", localStorage.getItem("parentId"));
    form.append("teleServiceFee", teleconsultation.teleServiceFee);

    if (auth.user) {
      form.append("createdBy", auth.user.id);
    }

    dispatch(submitPayment(form))
      .then(() => {
        setTimeout(
          () =>
            Swal.fire({
              icon: "success",
              title: "Data Created",
              showConfirmButton: false,
            }),
          1000
        );
        setTimeout(() => (window.location = "/payment-offline"), 2000);
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  };

  const renderPreviewData = () => {
    return (
      <>
        <div className="wrapper-form-title">
          <span className="form-title">Patient Data</span>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "250px" }}
                >
                  Name
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Gender
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Date of Birth
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Phone Number
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Identity Number
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Full Address
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {patientData.patientName ? patientData.patientName : "-"}
                </td>
                <td>
                  {patientData.patientGender == "1"
                    ? "Male"
                    : patientData.patientGender == "2"
                    ? "Female"
                    : "-"}
                </td>
                <td>
                  {patientData.patientDob
                    ? moment(patientData.patientDob).format("LL")
                    : "-"}
                </td>
                <td>
                  {patientData.patientPhone
                    ? "+62" + patientData.patientPhone
                    : "-"}
                </td>
                <td>
                  {patientData.patientNational &&
                  patientData.patientNationalOption == "1"
                    ? `KTP ${patientData.patientNational}`
                    : patientData.patientNational &&
                      patientData.patientNationalOption == "2"
                    ? `SIM ${patientData.patientNational}`
                    : patientData.patientNational &&
                      patientData.patientNationalOption == "3"
                    ? `Passport ${patientData.patientNational}`
                    : "-"}
                </td>
                <td>
                  {patientData.patientAddress
                    ? patientData.patientAddress
                    : "-"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="wrapper-form-title">
          <span className="form-title">Teleconsultation</span>
        </div>

        <div className="form-group">
          <div style={{ display: "flex" }}>
            <span className="col-4">Total Payment</span>
            <span className="col">{`: ${
              teleconsultation.teleServiceFee
                ? formatter.format(teleconsultation.teleServiceFee)
                : "-"
            }`}</span>
          </div>

        </div>

        <button
          type="button"
          className="btn btn-primary"
          style={{ width: "100%" }}
          onClick={actionSubmitPayment}
        >
          Submit Data
        </button>
      </>
    )
  };

  const handleCheckAddressData = (data) => {
    setValidation({
      ...validation,
      teleAddressForm: data.fullAddress,
    });
    setTeleconsultation({
      ...teleconsultation,
      teleAddressId: data.address_id,
      teleLatitude: data.latitude,
      teleLongitude: data.longitude,
      teleFullAddress: data.fullAddress,
      telePostalCode: data.postalCode,
      teleDetailAddress: data.detailAddress != undefined ? data.detailAddress : data.note != undefined ?  data.note : "",
      teleAddressName: data.addressName != undefined ? data.addressName : data.name != undefined ?  data.name : "",
    });
    Toast.fire({
      icon: "success",
      title: "Data Selected",
    });
    initState();
  };

  const handlePatientAddress = (addressData) => {
    setNewPatientData({
      ...newPatientData,
      patientAddress: addressData.fullAddress,
    });
  };

  const renderPaymentOffline = () => {
    return (
      <>
        <div className="wrapper-form-title">
          <span className="form-title">Teleconsultation</span>
        </div>

        <div className="row">
          <div className="form-group col-md-12">
            <div className="mb10">Total Payment <span style={{color:"red"}}>*</span></div>
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">IDR</div>
              </div>
              <input
                type="number"
                className="form-control"
                placeholder="Input Total Payment"
                min="0"
                style={{ height: "38px" }}
                value={teleconsultation.teleServiceFee}
                onChange={(e) =>
                  setTeleconsultation({
                    ...teleconsultation,
                    teleServiceFee: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-12">
            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100%" }}
              onClick={() => handleNext(2)}
            >
              Preview Data
            </button>
          </div>
        </div>
      </>
    );
  };

  const handleAddPatient = () => {
    setAddPatientModal(true);
  };

  const actionAddPatient = () => {
    let obj = {
      patientName: newPatientData.patientName,
      patientEmail: newPatientData.patientEmail,
      patientDob: newPatientData.patientDob,
      patientGender: newPatientData.patientGender,
      patientAddress: newPatientData.patientAddress,
      patientNational: newPatientData.patientNational,
      patientNationalOption: newPatientData.patientNationalOption,
      patientPhone: newPatientData.patientPhone,
      patientCountry: newPatientData.patientCountry,
      patientProvince: newPatientData.patientProvince,
      patientCity: newPatientData.patientCity,
      patientDistrict: newPatientData.patientDistrict,
      patientSubDistrict: newPatientData.patientSubDistrict,
      patientPostalCode: newPatientData.patientPostalCode,
    };

    setTotalNewPatientData([...totalNewPatientData, obj]);
    initState();
    setAddPatientModal(false);
    Swal.fire("Success!", "Data Created", "success");
  };

  const renderAddPatient = () => {
    return (
      <Modal
        modalTitle="Add New Patient Data"
        show={addPatientModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        onSubmit={actionAddPatient}
      >
        <Row>
          <Col md>
            <Input
              type="text"
              label="Patient Name"
              placeholder="Input Patient Name"
              value={newPatientData.patientName}
              onChange={(e) =>
                setNewPatientData({
                  ...newPatientData,
                  patientName: e.target.value,
                })
              }
            />
          </Col>
          <Col md>
            <Input
              type="text"
              label="Patient Email"
              placeholder="Input Patient Email"
              value={newPatientData.patientEmail}
              onChange={(e) =>
                setNewPatientData({
                  ...newPatientData,
                  patientEmail: e.target.value,
                })
              }
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="date"
              label="Date of Birth"
              value={newPatientData.patientDob}
              onChange={(e) =>
                setNewPatientData({
                  ...newPatientData,
                  patientDob: e.target.value,
                })
              }
            />
          </Col>
          <Col md>
            <div className="form-group">
              <label>Gender</label>
              <select
                className="form-control"
                value={newPatientData.patientGender}
                onChange={(e) =>
                  setNewPatientData({
                    ...newPatientData,
                    patientGender: e.target.value,
                  })
                }
              >
                <option value="">Select Gender</option>
                <option value="1">Male</option>
                <option value="2">Female</option>
              </select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Phone Number</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">+62</div>
                </div>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Input Phone Number"
                  style={{ height: "38px" }}
                  value={newPatientData.patientPhone}
                  onChange={(e) =>
                    setNewPatientData({
                      ...newPatientData,
                      patientPhone: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </Col>
          <Col md>
            <div className="form-group">
              <label>Identity Number</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <select
                    className="form-control"
                    value={newPatientData.patientNationalOption}
                    onChange={(e) =>
                      setNewPatientData({
                        ...newPatientData,
                        patientNationalOption: e.target.value,
                      })
                    }
                  >
                    <option value="">Select ID</option>
                    <option value="1">KTP</option>
                    <option value="2">SIM</option>
                    <option value="3">Passport</option>
                  </select>
                </div>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Input Identity Number"
                  style={{ height: "38px" }}
                  value={newPatientData.patientNational}
                  onChange={(e) =>
                    setNewPatientData({
                      ...newPatientData,
                      patientNational: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md style={{ height: "350px", marginTop: "20px" }}>
            <GoogleMap
              addressData={(addressData) => handlePatientAddress(addressData)}
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Full Address</label>
              <textarea
                rows="3"
                className="form-control"
                placeholder="Input Full Address"
                value={newPatientData.patientAddress}
                onChange={(e) =>
                  setNewPatientData({
                    ...newPatientData,
                    patientAddress: e.target.value,
                  })
                }
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Country</label>
              <div className="input-group">
                <select
                  className="form-control"
                  value={newPatientData.patientCountry}
                  onChange={(e) => handleGetProvince(e.target.value)}
                >
                  <option value="">Select Country</option>
                  {paymentOffline.country.length > 0 ? (
                    paymentOffline.country.map((data, index) => (
                      <option value={data.id} key={index}>
                        {data.name}
                      </option>
                    ))
                  ) : (
                    <option value=""></option>
                  )}
                </select>
              </div>
            </div>
          </Col>
          <Col md>
            <div className="form-group">
              <label>Province</label>
              <div className="input-group">
                <select
                  className="form-control"
                  value={newPatientData.patientProvince}
                  onChange={(e) => handleGetCity(e.target.value)}
                >
                  <option value="">Select Province</option>
                  {paymentOffline.province.length > 0 ? (
                    paymentOffline.province.map((data, index) => (
                      <option value={data.id} key={index}>
                        {data.name}
                      </option>
                    ))
                  ) : (
                    <option value=""></option>
                  )}
                </select>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>City</label>
              <div className="input-group">
                <select
                  className="form-control"
                  value={newPatientData.patientCity}
                  onChange={(e) => handleGetDistrict(e.target.value)}
                >
                  <option value="">Select City</option>
                  {paymentOffline.city.length > 0 ? (
                    paymentOffline.city.map((data, index) => (
                      <option value={data.id} key={index}>
                        {data.name}
                      </option>
                    ))
                  ) : (
                    <option value=""></option>
                  )}
                </select>
              </div>
            </div>
          </Col>
          <Col md>
            <div className="form-group">
              <label>District</label>
              <div className="input-group">
                <select
                  className="form-control"
                  value={newPatientData.patientDistrict}
                  onChange={(e) => handleGetSubDistrict(e.target.value)}
                >
                  <option value="">Select District</option>
                  {paymentOffline.district.length > 0 ? (
                    paymentOffline.district.map((data, index) => (
                      <option value={data.id} key={index}>
                        {data.name}
                      </option>
                    ))
                  ) : (
                    <option value=""></option>
                  )}
                </select>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Subdistrict</label>
              <div className="input-group">
                <select
                  className="form-control"
                  value={newPatientData.patientSubDistrict}
                  onChange={(e) => handleGetPostalCode(e.target.value)}
                >
                  <option value="">Select Subdistrict</option>
                  {paymentOffline.subdistrict.length > 0 ? (
                    paymentOffline.subdistrict.map((data, index) => (
                      <option value={data.id} key={index}>
                        {data.name}
                      </option>
                    ))
                  ) : (
                    <option value=""></option>
                  )}
                </select>
              </div>
            </div>
          </Col>
          <Col md>
            <div className="form-group">
              <label>Postal Code</label>
              <div className="input-group">
                <select
                  className="form-control"
                  value={newPatientData.patientPostalCode}
                  onChange={(e) =>
                    setNewPatientData({
                      ...newPatientData,
                      patientPostalCode: e.target.value,
                    })
                  }
                >
                  <option value="">Select Postal Code</option>
                  {paymentOffline.postalcode.length > 0 ? (
                    paymentOffline.postalcode.map((data, index) => (
                      <option value={data.id} key={index}>
                        {data.code}
                      </option>
                    ))
                  ) : (
                    <option value=""></option>
                  )}
                </select>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  };

  const handleCheckPatientData = (data) => {
    if (data.patientId && data.customerId) {
      setValidation({
        ...validation,
        patientForm: data.patientId,
      });
      setPatientData({
        ...patientData,
        customerId: data.customerId,
        patientId: data.patientId,
        patientName: data.patientName,
        patientEmail: data.email,
        patientDob: data.birthday,
        patientGender: data.gender,
        patientAddress: data.address,
        patientNational: data.national_id_no,
        patientNationalOption: data.national_id_type,
        patientPhone: data.phone,
      });

      if (data.customerId) {
        setCustomerId(data.customerId);
      }

      Toast.fire({
        icon: "success",
        title: "Data Selected",
      });
    } else {
      setValidation({
        ...validation,
        patientForm: data.patientName,
      });
      setPatientData({
        ...patientData,
        patientName: data.patientName,
        patientEmail: data.patientEmail,
        patientDob: data.patientDob,
        patientGender: data.patientGender,
        patientAddress: data.patientAddress,
        patientNational: data.patientNational,
        patientNationalOption: data.patientNationalOption,
        patientPhone: data.patientPhone,
        patientCountry: data.patientCountry,
        patientProvince: data.patientProvince,
        patientCity: data.patientCity,
        patientDistrict: data.patientDistrict,
        patientSubDistrict: data.patientSubDistrict,
        patientPostalCode: data.patientPostalCode,
      });

      Toast.fire({
        icon: "success",
        title: "Data Selected",
      });
    }
  };

  const renderDetailPatientData = () => {
    return paymentOffline.loading ? (
      <tr>
        <td colSpan="7">
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        {paymentOffline.patientDetail.length > 0 &&
          paymentOffline.patientDetail.map((data) => (
            <tr key={data.patientId}>
              <td>
                <input
                  type="radio"
                  name="patientData"
                  checked={validation.patientForm === data.patientId}
                  value={data.patientId}
                  onChange={() => handleCheckPatientData(data)}
                />
              </td>
              <td>{data.patientName ? data.patientName : "-"}</td>
              <td>
                {data.gender == "1"
                  ? "Male"
                  : data.gender == "2"
                  ? "Female"
                  : "-"}
              </td>
              <td>
                {data.birthday ? moment(data.birthday).format("LL") : "-"}
              </td>
              <td>{data.phone ? "+62" + data.phone : "-"}</td>
              <td>
                {data.national_id_no && data.national_id_type == "1"
                  ? `KTP ${data.national_id_no}`
                  : data.national_id_no && data.national_id_type == "2"
                  ? `SIM ${data.national_id_no}`
                  : data.national_id_no && data.national_id_type == "3"
                  ? `Passport ${data.national_id_no}`
                  : "-"}
              </td>
              <td>{data.address ? data.address : "-"}</td>
            </tr>
          ))}
        {totalNewPatientData.length > 0 &&
          totalNewPatientData.map((data, index) => (
            <tr key={index}>
              <td>
                <input
                  type="radio"
                  name="patientData"
                  checked={validation.patientForm === data.patientName}
                  value={data.patientName}
                  onChange={() => handleCheckPatientData(data)}
                />
              </td>
              <td>{data.patientName ? data.patientName : "-"}</td>
              <td>
                {data.patientGender == "1"
                  ? "Male"
                  : data.patientGender == "2"
                  ? "Female"
                  : "-"}
              </td>
              <td>
                {data.patientDob ? moment(data.patientDob).format("LL") : "-"}
              </td>
              <td>{data.patientPhone ? "+62" + data.patientPhone : "-"}</td>
              <td>
                {data.patientNational && data.patientNationalOption == "1"
                  ? `KTP ${data.patientNational}`
                  : data.patientNational && data.patientNationalOption == "2"
                  ? `SIM ${data.patientNational}`
                  : data.patientNational && data.patientNationalOption == "3"
                  ? `Passport ${data.patientNational}`
                  : "-"}
              </td>
              <td>{data.patientAddress ? data.patientAddress : "-"}</td>
            </tr>
          ))}
        {paymentOffline.patientDetail.length === 0 &&
          totalNewPatientData.length === 0 && (
            <tr>
              <td colSpan="7">Empty data</td>
            </tr>
          )}
      </>
    );
  };

  const handleReset = () => {
    const form = new FormData();
    form.append("name", (name.current.value = ""));
    form.append("birthday", (birthday.current.value = ""));

    dispatch(findPatients(form)).then(() => setTotalNewPatientData([]));
  };

  const handleSearch = () => {
    const form = new FormData();
    form.append("name", name.current.value);
    form.append("birthday", birthday.current.value);

    dispatch(findPatients(form));
  };

  const renderPatientData = () => {
    return (
      <>
        <div className="wrapper-form-title">
          <span className="form-title">Patient Data</span>
        </div>

        <div className="row">
          <div className="form-group col-md-3">
            <div className="mb10">Name</div>
            <input
              type="text"
              className="form-control"
              placeholder="Search Patient Name"
              ref={name}
            />
          </div>

          <div className="form-group col-md-3">
            <div className="mb10">Date of Birth</div>
            <input type="date" className="form-control" ref={birthday} />
          </div>

          <div className="form-group col-md-3 mt-auto">
            <button
              type="button"
              className="btn btn-secondary"
              style={{
                width: "100px",
                marginRight: "5px",
              }}
              onClick={() => handleReset()}
            >
              Reset
            </button>

            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100px" }}
              onClick={() => handleSearch()}
            >
              Apply
            </button>
          </div>

          <div
            className="form-group col-md-3"
            style={{ borderLeft: "1px solid grey" }}
          >
            <div className="mb10">User is not registered yet?</div>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={handleAddPatient}
            >
              Add New Patient Data
            </button>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "45px" }}
                >
                  +
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "200px" }}
                >
                  Name
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Gender
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Date of Birth
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Phone Number
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Identity Number
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Full Address
                </th>
              </tr>
            </thead>
            <tbody>{renderDetailPatientData()}</tbody>
          </table>
        </div>

        <div className="row mt-4">
          <div className="col-md-12">
            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100%" }}
              onClick={() => handleNext(1)}
            >
              Next Step
            </button>
          </div>
        </div>
      </>
    );
  };

  const style = createMuiTheme({
    overrides: {
      MuiStepIcon: {
        root: {
          "&$active": {
            color: "#38a8ff",
          },
          "&$completed": {
            color: "#38a8ff",
          },
        },
      },
      MuiStepLabel: {
        label: {
          "&$active": {
            color: "#38a8ff",
          },
          "&$completed": {
            color: "#38a8ff",
          },
        },
      },
    },
  });

  const getLabel = () => {
    return ["Patient Data", "Teleconsultation", "Submit"];
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Payment Offline</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        <div className="row">
          <div className="col-md-6">
            <span id="finance-title" className="mb10">
              Registration Steps
            </span>
          </div>
        </div>

        <ThemeProvider theme={style}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {getLabel().map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </ThemeProvider>

        {activeStep === 0 && renderPatientData()}
        {activeStep === 1 && renderPaymentOffline()}
        {activeStep === 2 && renderPreviewData()}
      </div>
      {renderAddPatient()}
    </DashboardLayout>
  );
};

export default PaymentOfflineAdd;
