import React from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import {
    updateOrderProducts,
    createOrderProducts,
    updateStatusOrderProducts,
} from "../../../../actions";

// Style
import "../orderHistory.css";

import Swal from "sweetalert2";

// Helpers
import { formatDate } from "../../../../helpers/getFormattedDate";

export default function ViewDetailHistoryOther({state, detailDataVal, latitude, longitude, result}) {
    const dispatch = useDispatch(); 

    // Patient
    const patientData = detailDataVal.patient != undefined ?  detailDataVal.patient : [];
    const patientName = patientData.length != 0 ? patientData.name : "";

    const addressData = detailDataVal.address != undefined ?  detailDataVal.address : [];
    const patientAddress = addressData.length != 0 ? addressData.fullAddress : "";    

    //Schedule
    const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
    const scheduleData = detailDataVal.schedule != undefined ?  detailDataVal.schedule : [];
    const scheduleDay = scheduleData.length != 0 ? new Date(scheduleData.scheduleDate).getDay() : -1;
    const scheduleDate = scheduleData.length != 0 ? formatDate(scheduleData.scheduleDate) : "";
    const startTime = scheduleData.length != 0 ? scheduleData.startTime : "";
    const endTime = scheduleData.length != 0 ? scheduleData.endTime : "";

    //Paket
    const packageServiceName = [];
    const orderPackageData = detailDataVal.orderPackage != undefined ?  detailDataVal.orderPackage : [];
    for(var i = 0; i < orderPackageData.length; i++){
        packageServiceName.push(orderPackageData[i].packageService.name);
    }
    const packageService = packageServiceName.join(", ");    

    //Keluhan
    const complaints = detailDataVal.complaints != undefined ? detailDataVal.complaints : "";
    
    //Next Status
    const totalFee = detailDataVal.totalFee != undefined ?  detailDataVal.totalFee : null;
    const orderStatusData = detailDataVal.orderStatus != undefined ?  detailDataVal.orderStatus : [];
    const statusVal = orderStatusData.length != 0 ?  orderStatusData.code : null;
    const nextStatusData = orderStatusData.length != 0 ?  orderStatusData.nextStatus : [];
    const nextStatusDesc = orderStatusData.code == 14 ? 
                                totalFee > 0 ? nextStatusData.find(code => code.code == "98") : 
                                nextStatusData.find(code => code.code == "99") 
                            : null;
    const nextStatusVal = statusVal != null ? 
                            statusVal == 99 ? "Pesanan Selesai" : 
                                statusVal == 98 ? "Menunggu Pembayaran" :
                                    statusVal == 14 ? nextStatusDesc.description : nextStatusData[0].description
                            : "";
    
    //Chat
    const partnerData = detailDataVal.partner != undefined ? detailDataVal.partner : [];
    const custUserData = partnerData.length != 0 ? partnerData.user : [];
    const userId = custUserData.length != 0 ? custUserData.id : 0;
    const sendbirdToken = custUserData.length != 0 ? custUserData.sendbirdToken : 0;
    const channelURL = detailDataVal.channelUrl != undefined ? detailDataVal.channelUrl : " ";
    const urlChat =  `${process.env.REACT_APP_CHAT_URL}chat.html?userid=${userId}&userToken=${sendbirdToken}&chatUrl=${channelURL}`;

    //Maps
    const mapLong = latitude;
    const mapLat = longitude;
    const longAddress = addressData.length != 0 ? addressData.longitude : 0;
    const latAddress = addressData.length != 0 ? addressData.latitude : 0;
    const urlGmaps = "https://www.google.com/maps/dir/"+mapLong+","+mapLat+"/"+latAddress+","+longAddress+"/data=!3m1!1e3";

    const prevPageUrl = "/order-history";
    const nextPageProfileUrl = "/order-history/detail/profile";
    const nextPageRiwayatUrl = "/order-history/detail/riwayat";
    const nextPageLayananUrl = "/order-history/detail/layanan";
    const stateVal = {"id": state.id, "statusCode": statusVal, "detailData": detailDataVal};    

    const selectedProcedureDataVal = JSON.parse(localStorage.getItem("selectedProcedureData"));
    const selectedMedicalDataVal = JSON.parse(localStorage.getItem("selectedMedicalData"));

    const onSubmitData = () => { 
        if(statusVal == 99){
            return Swal.fire({
                title: "Pesanan Selesai!",
                icon: "success",
                confirmButtonText: "OK",
                confirmButtonColor: "#7cb342",
            }).then((result) => {
                if (result.value) {
                    // document.getElementById("redirectPage").click();
                    window.location.replace("/order-history");
                } 
            });
        }
        else if(statusVal == 98){
            return Swal.fire({
                title: "Menunggu Pembayaran!",
                icon: "success",
                confirmButtonText: "OK",
                confirmButtonColor: "#7cb342",
            }).then((result) => {
                if (result.value) {
                    localStorage.removeItem("selectedProcedureData");
                    localStorage.removeItem("selectedMedicalData");
                    // document.getElementById("redirectPage").click();
                    window.location.replace("/order-history");
                } 
            });
        }

        const selectedProcedureData = selectedProcedureDataVal != undefined ? selectedProcedureDataVal : [];
        const selectedMedicalData = selectedMedicalDataVal != undefined ? selectedMedicalDataVal : [];

        const statusCodes = statusVal != null ? statusVal == 14 ? 
                                nextStatusDesc.code == 99 && 
                                    (selectedProcedureData.length != 0 || selectedMedicalData.length != 0) ? 98 : 99
                                : nextStatusData[0].code
                            : 0;

        var productData = [...selectedProcedureData, ...selectedMedicalData];

        if(selectedProcedureData.length != 0 || selectedMedicalData.length != 0){
            dispatch(updateOrderProducts(state.id, JSON.stringify(productData)));
        
            for(var i = 0; i < productData.length; i++){
                var productName = productData[i].productName;
                dispatch(createOrderProducts(productName));
            }
        }

        dispatch(updateStatusOrderProducts(state.id, statusCodes)).then(
            Swal.fire({
                title: nextStatusVal+"!",
                icon: "success",
                confirmButtonText: "OK",
                confirmButtonColor: "#7cb342",
            }).then((result) => {
                if (result.value) {
                    // document.getElementById("redirectPage").click();
                    window.location.replace("/order-history");
                } 
            })
        );      
    };

    return (
            <div className="col-12" >
                <div className="card-detail" style={{background:"#38a8ff", borderRadius: "10px 10px 0px 0px"}}>
                    <a href={prevPageUrl}>
                        <i className="fa fa-arrow-left" style={{fontSize: "18px", color: "white"}} aria-hidden="true" /> 
                    </a>
                    <span style={{margin: "0px 30px 0px 30px", color: "white", fontSize: "16px"}}> 
                        {patientName}
                    </span>
                    <i className="material-icons" aria-hidden="true" onClick={() => window.open(urlChat)}
                    style={{fontSize :"20px", fontWeight: 'bolder', color:'white', right: 33, position: 'absolute', cursor: 'pointer'}}> 
                    &#xe0b7;</i> 
                </div>
                <Link to = {{ pathname: nextPageProfileUrl, state: stateVal }}>
                    <div className="card-detail">
                        <i className="fa fa-user" style={{fontSize: "20px", color: "grey"}} aria-hidden="true" /> 
                        <span style={{margin: "0px 30px 0px 10px"}}> 
                            {patientName}
                        </span>                    
                        <i className="fa fa-angle-right" aria-hidden="true" 
                            style={{fontSize :"22px", fontWeight: 'bolder', right: 33, position: 'absolute'}} /> 
                    </div>
                </Link>
                <div className="card-detail">
                    <div>
                        <i className="fa fa-home" style={{fontSize: "20px", color: "grey"}} aria-hidden="true" /> 
                        <span style={{margin: "0px 30px 0px 10px"}}> 
                            {patientName}
                        </span>
                        <button className="btnNearMe" onClick={() => window.open(urlGmaps)}>
                            <i className="fa fa-location-arrow" aria-hidden="true" 
                                style={{fontSize :"17px", color:'#38a8ff', fontWeight: 'bolder'}} />
                        </button>
                    </div>
                    <div style={{fontSize: '12px', margin: '0px 30px 15px 28px'}}>
                        {patientAddress}
                    </div>
                    <div>
                        <i className="fa fa-calendar" style={{ color: "grey"}} aria-hidden="true" /> 
                        <span style={{margin: "0px 10px 10px 10px"}}> {scheduleDay >= 0 ? days[scheduleDay] + "," : ""} {scheduleDate}</span>
                    
                    </div>
                    <div>
                        <i className="fa fa-clock-o" style={{ fontSize: '18px', color: "grey"}} aria-hidden="true" /> 
                        <span style={{margin: "0px 10px 0px 10px"}}> {startTime} - {endTime}</span>
                    </div>
                </div>
                <div className="card-detail">
                    <div>
                        <i className="fa fa-file-text-o" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                        <span style={{margin: "0px 10px 0px 10px"}}> Layanan </span>
                    </div>
                    <div style={{fontSize: '16px', margin: '10px 10px 15px 28px'}}>
                        {packageService}
                    </div>
                </div>
                <div className="card-detail">
                    <i className="fa fa-file-text-o" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                    <span style={{margin: "0px 30px 0px 10px"}}> Order ID : {state.id} </span>
                    
                </div>
                <div className="card-detail">
                    <div>
                        <i className="fa fa-file-text-o" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                        <span style={{margin: "0px 10px 0px 10px"}}> Keluhan</span>
                    </div>
                    <div style={{fontSize: '16px', margin: '10px 10px 15px 28px'}}>
                        {complaints}
                    </div>
                </div>
                <Link to = {{ pathname: nextPageRiwayatUrl, state: stateVal }}>
                    <div className="card-detail">
                        <i className="fa fa-file-text-o" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                        <span style={{margin: "0px 30px 0px 10px"}}> Lihat Riwayat Kesehatan</span>
                        <i className="fa fa-angle-right" aria-hidden="true" 
                            style={{fontSize :"22px", fontWeight: 'bolder', right: 33, position: 'absolute'}} /> 
                    </div>
                </Link>
                <Link to = {{ pathname: nextPageLayananUrl, state: stateVal }}>
                    <div className="card-detail">
                        <i className="fa fa-file-text-o" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                        <span style={{margin: "0px 30px 0px 10px"}}> Layanan Tambahan</span>
                        <i className="fa fa-angle-right" aria-hidden="true" 
                            style={{fontSize :"22px", fontWeight: 'bolder', right: 33, position: 'absolute'}} /> 
                    </div>
                </Link>
                <button className="btnNextAction" onClick={() => onSubmitData()}> {nextStatusVal} </button>
                {/* <Link to={{ pathname: "/order-history/detail", state: {"id": state.id} }} id="redirectPage" /> */}
            </div>
    );
};