import axios from "../helpers/axios";
import { pharmacyWaitingDeliveryConstants } from "./constants";

export const getAllPharmacyWaitingDelivery = (partnerId, search) => {
  return async (dispatch) => {
    dispatch({
      type: pharmacyWaitingDeliveryConstants.GET_ALL_PHARMACY_WAITING_REQUEST,
    });
    const res = await axios.get(
      `/Order/getPharmacyWaitingDelivery?${partnerId}&${search}`
    );
    if (res.status === 200) {
      dispatch({
        type: pharmacyWaitingDeliveryConstants.GET_ALL_PHARMACY_WAITING_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: pharmacyWaitingDeliveryConstants.GET_ALL_PHARMACY_WAITING_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getDetailPharmacyWaiting = (id, partnerId) => {
  return async (dispatch) => {
    dispatch({
      type: pharmacyWaitingDeliveryConstants.GET_PHARMACY_WAITING_DETAIL_REQUEST,
    });
    const res = await axios.get(
      `/Order/getDetailPharmacyWaitingDelivery/${id}?${partnerId}`
    );
    if (res.status === 200) {
      dispatch({
        type: pharmacyWaitingDeliveryConstants.GET_PHARMACY_WAITING_DETAIL_SUCCESS,
        payload: { details: res.data },
      });
    } else {
      dispatch({
        type: pharmacyWaitingDeliveryConstants.GET_PHARMACY_WAITING_DETAIL_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const submitPharmacyNoteWaiting = (form) => {
  return async (dispatch) => {
    dispatch({
      type: pharmacyWaitingDeliveryConstants.SUBMIT_PHARMACY_NOTE_REQUEST,
    });
    const res = await axios.post("/Order/submitPharmacyNote", form);
    if (res.status === 200) {
      dispatch({
        type: pharmacyWaitingDeliveryConstants.SUBMIT_PHARMACY_NOTE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: pharmacyWaitingDeliveryConstants.SUBMIT_PHARMACY_NOTE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
