import { myScheduleConstants } from "../actions/constants";

const initState = {
  schedules: [],
  totalData: "",
  loading: false,
  error: null,
  message: "",
  dataError: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case myScheduleConstants.GET_ALL_SCHEDULES_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myScheduleConstants.GET_ALL_SCHEDULES_SUCCESS:
      state = {
        ...state,
        schedules: action.payload.schedules,
        totalData: action.payload.totalData,
        loading: false,
      };
      break;
    case myScheduleConstants.GET_ALL_SCHEDULES_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myScheduleConstants.ADD_NEW_SCHEDULES_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myScheduleConstants.ADD_NEW_SCHEDULES_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case myScheduleConstants.ADD_NEW_SCHEDULES_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myScheduleConstants.IMPORT_SCHEDULES_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myScheduleConstants.IMPORT_SCHEDULES_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.msg,
        dataError: action.payload.dataError
      };
      break;
    case myScheduleConstants.IMPORT_SCHEDULES_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myScheduleConstants.CLEAR_IMPORT_SCHEDULE_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        dataError: action.payload.dataError,
        loading: false,
      };
      break;
    case myScheduleConstants.UPDATE_SCHEDULES_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myScheduleConstants.UPDATE_SCHEDULES_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case myScheduleConstants.UPDATE_SCHEDULES_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myScheduleConstants.DELETE_SCHEDULES_REQUEST:
      state = {
        ...state,
        loading: false,
      };
      break;
    case myScheduleConstants.DELETE_SCHEDULES_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case myScheduleConstants.DELETE_SCHEDULES_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myScheduleConstants.MULTIPLE_DELETE_SCHEDULES_REQUEST:
      state = {
        ...state,
        loading: false,
      };
      break;
    case myScheduleConstants.MULTIPLE_DELETE_SCHEDULES_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case myScheduleConstants.MULTIPLE_DELETE_SCHEDULES_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
  }
  return state;
};
