import React, { useEffect} from "react";
import { Link, useLocation } from "react-router-dom";
import DashboardLayout from "../../../../Layout";

// Style
import "../../../orderHistory.css";

export default function OrderHistoryDetailLampiranFoto() {

    const { state } = useLocation();

    var slideIndex = 1;

    useEffect(() => {
        showSlides(slideIndex);
    }, []);

    const type = state.type != undefined ? state.type : "";
    const orderGallery = state.orderGallery != undefined ? state.orderGallery : [];
    const prevPageUrl = type == "riwayat" ? "/order-history/detail/lihatriwayat" : "/order-history/detail";
    const stateVal = { "id" : state.id, "statusCode": state.statusCode, "detailData": state.detailData, "orderId": state.orderId, "orderGallery": orderGallery };
    
    const showSlides = (n) => {
        var i;
        var slides = document.getElementsByClassName("mySlides");
        var dots = document.getElementsByClassName("dot");
        
        if (n > slides.length) {slideIndex = 1}    
        if (n < 1) {slideIndex = slides.length}
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";  
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" actives", "");
        }
        
        if(slides[slideIndex-1] != undefined){
            slides[slideIndex-1].style.display = "block";
        }

        if(dots[slideIndex-1] != undefined){
            dots[slideIndex-1].className += " active";
        }
    }

    showSlides(slideIndex);

    var plusSlides = (n) => {
        showSlides(slideIndex += n);
    }

    var currentSlide = (n) => {
        showSlides(slideIndex = n);
    }
    
    return (
        <DashboardLayout>        
            <div className="col-12" >
                <div className="card-detail" style={{background:"#38a8ff", margin:"30px 0px 20px 0px", borderRadius: "10px 10px 0px 0px"}}>
                    <Link to={{ pathname: prevPageUrl, state: stateVal}}>
                        <i className="fa fa-arrow-left" style={{fontSize: "18px", color: "white"}} aria-hidden="true" /> 
                    </Link>
                    <span style={{margin: "0px 30px 0px 30px", color: "white", fontSize: "16px"}}> 
                        Lampiran Foto
                    </span>
                </div>
                <div className="card-detail">
                    <div>
                        <i className="fa fa-file-text-o" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                        <span style={{margin: "0px 0px 10px 10px"}}> Lampiran Foto : </span>
                    </div>

                    <div className="slideshow-container">
                        { orderGallery.length > 0 ? 
                            (
                                orderGallery.map((data, index) => (
                                    <div className="mySlides" key = {index}>
                                        <div className="numbertext">{parseInt(index)+1 + " / " + orderGallery.length}</div>
                                            <img src={data.imgUrl} style={{minHeight:"250px", width: "250px", margin: "0px 10px 0px 10px"}} />
                                    </div>
                                ))
                            ) 
                            : 
                            (<p></p>)
                        }
                        <a className="prev" onClick={() => {plusSlides(-1);}} >&#10094;</a>
                        <a className="next" onClick={() => {plusSlides(1);}}>&#10095;</a>
                    </div>
                    <br />
                    <div style={{textAlign: "center"}}>
                        {
                            orderGallery.length > 0 ?
                            (
                                orderGallery.map((data, index) => (
                                    <span className="dot" key={index} onClick={() => {currentSlide(parseInt(index)+1);}} ></span> 
                                ))
                            ) 
                            : 
                            (
                                <p> </p>
                            )
                        }
                    </div>
                    
                </div>
            </div>
        </DashboardLayout>
    );
};