import axiosParent from "../helpers/axiosParent";
import { healthScreeningConstants } from "./constants";

export const getAllEventCheckInHS = () => {
  return async (dispatch) => {
    dispatch({ type: healthScreeningConstants.GET_ALL_EVENTHS_REQUEST });
    
    const res = await axiosParent.get(`/Cms/HC/getAllEventCheckInHS`);
    if (res.status === 200) {
      const { eventLists } = res.data;
      dispatch({
        type: healthScreeningConstants.GET_ALL_EVENTHS_SUCCESS,
        payload: {  eventLists },
      });
    } else {
      dispatch({
        type: healthScreeningConstants.GET_ALL_EVENTHS_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getDataCheckInHS = (eventId, q) => {
    return async (dispatch) => {
      dispatch({ type: healthScreeningConstants.GET_ALL_HS_REQUEST });
      
      const res = await axiosParent.get(`/Cms/HC/getCheckInOrder?eventId=${eventId}&${q}`);
      if (res.status === 200) {
        const { data, totalData } = res.data;
        dispatch({
          type: healthScreeningConstants.GET_ALL_HS_SUCCESS,
          payload: {  data, totalData },
        });
      } else {
        dispatch({
          type: healthScreeningConstants.GET_ALL_HS_FAILURE,
          payload: { error: res.data.error },
        });
      }
    };
  };

export const checkInHS = (form) => {
    return async (dispatch) => {
      dispatch({ type: healthScreeningConstants.CHECKIN_HS_REQUEST });
      const res = await axiosParent.post("/Cms/HC/Order/CheckIn", form);
      if (res.status === 200) {
        dispatch({
          type: healthScreeningConstants.CHECKIN_HS_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: healthScreeningConstants.CHECKIN_HS_FAILURE,
          payload: { error: res.data.error },
        });
      }
    };
};