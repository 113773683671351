import React, { Fragment, useEffect } from "react";
import DashboardLayout from "../../Layout";
import { useDispatch, useSelector } from "react-redux";
import { cancelOrderTele, getTeleDetail, getTeleReschedule, approveClaimOrderTele, rejectClaimOrderTele  } from "../../../../actions";
import { useParams } from "react-router";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import Loading from "../../../../components/Loading";
import Swal from 'sweetalert2';

const Breadcrumb = (props) => {
    return (
        <nav key={props.label} aria-label="breadcrumb" id="breadcrumb-id">
            <ol className="breadcrumb pl-0">
              <li className="breadcrumb-item">
                <a>Dashboard</a>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to={props.path}>{props.menu}</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {props.label}
              </li>
            </ol>
        </nav>
    )
}

const TeleRescheduleDetail = () => {
  let { orderId } = useParams();
  let history = useHistory();

  const role = JSON.parse(localStorage.getItem("user"));
  const roleUser = role.user_role_management_id.id
  const teleReschedule = useSelector((state) => state.teleReschedule);
  const dispatch = useDispatch();
  const {loading, error} = teleReschedule;
  
  useEffect(() => {
    dispatch(getTeleDetail(orderId))  
    }, [dispatch, orderId]
  );

  const cancelOrder = () => {
      console.log(orderId)
      if (orderId !== "") {
        Swal.fire({
            title: "Reconfirmation",
            text: `Apa anda yakin ingin cancel order #${orderId}`,
            icon: "warning",
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#7cb342",
            cancelButtonText: "No",
        }).then((result) => {
                if (result.value) {
                    dispatch(cancelOrderTele(orderId)).then((res) => {
                        if (res) {
                          dispatch(getTeleReschedule());
                        }
                        Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: false,
                        }).fire({
                            icon: 'success',
                            title: 'Successfully canceled an order'
                        })
                        history.replace("/re-schedule")
                    });
                }
        });
     }
  }

  const approveClaim = () => {
    console.log(orderId)
    if (orderId !== "") {
        var formData = new FormData();
        formData.append("orderId", orderId);
        dispatch(approveClaimOrderTele(formData)).then((res) => {
            Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: false,
            }).fire({
                icon: 'success',
                title: 'Successfully Approve Claim'
            })
            history.replace("/re-schedule")
        });
   }
}

const rejectClaim = () => {
      console.log(orderId)
      if (orderId !== "") {
        Swal.fire({
            text: 'Please type the reason of claim rejection here:',
            input: 'textarea',
            confirmButtonText: 'Submit Rejection',
            showLoaderOnConfirm: true,
            preConfirm: (value) => {
                if (!value) {
                    Swal.showValidationMessage('this is a mandatory field')   
                }
            }
          }).then((result) => {
              console.log(result)
            if (result.isConfirmed) {
                var formData = new FormData();
                formData.append("orderId", orderId);
                formData.append("reasonRejected", result.value);
                dispatch(rejectClaimOrderTele(formData)).then((res) => {
                    Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: false,
                    }).fire({
                        icon: 'success',
                        title: 'Successfully Reject Claim'
                    })
                    history.replace("/re-schedule")
                });
            }
          })
     }
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

    let data = teleReschedule.order;

  return (
    
    <DashboardLayout>
        <Breadcrumb 
            path={"/re-schedule"} 
            menu={"Reschedule Teleconsultation"} 
            label={"Detail - Order Teleconsultation"}
            />
        <div className="form-wrapper" id="detail-complete-call-teleconsul-order">
        { loading ? <Loading/> : error ? error.message : (
        <Fragment>
           <div className="wrapper-form-title">
               <span className="form-title">Teleconsultation Detail</span>
           </div>
   
           <div className="border-bottom-section">
               
               <div className="table-responsive">
                   <table id="tableScheduler" className="table table-striped table-bordered">
                           
                        <tbody className="text-left">
                            <tr>
                               <td>Order Id</td>
                               <td colSpan="2">{ data.order_id }</td>
                           </tr>
                           <tr>
                               <td>Customer</td>
                               <td>{ data.customer_name }</td>
                           </tr>
                           <tr>
                               <td>Patient</td>
                               <td>{ data.patient_name }</td>
                           </tr>
                           <tr>
                               <td>Patien DOB</td>
                               <td>{moment(data.patient_dob).format("DD MMM Y")}</td>
                           </tr>
                           <tr>
                               <td>Patient Phone</td>
                               <td>{data.patient_phone}</td>
                           </tr>
                           <tr>
                               <td>Doctor</td>
                               <td>{data.doctor_name}</td>
                           </tr>
                            {
                                localStorage.getItem("parentId") == 3295 ? (
                                    <>
                                    <tr>
                                        <td>Type</td>
                                        <td>
                                        {!data.isHealthScreening && !data.isPremium ? "Free Tele" : !data.eventId && data.isPremium ? "Tanya Dokter Premium" : data.isHealthScreening ? "Health Screening" : ""}
                                        </td>
                                    </tr>
                                    {
                                        data.isHealthScreening ? 
                                        <tr>
                                            <td>Event Name</td>
                                            <td>{data.event_name} {data.corporate_name ? ` - ${data.corporate_name}` : ""}</td>
                                        </tr> : <></>
                                    }
                                    </>
                                ) : <></>
                            }
                           <tr>
                               <td>Schedule</td>
                               <td>{moment(data.scheduleDate).utc().format("D MMMM Y HH:mm:ss")}</td>
                           </tr>
                           <tr>
                               <td>Start</td>
                               <td>{data.startTime}</td>
                           </tr>
                           <tr>
                               <td>End</td>
                               <td>{data.endTime}</td>
                           </tr>
                           <tr>
                               <td>Gender</td>
                               <td>{data.gender}</td>
                           </tr>
                           <tr>
                               <td>Email</td>
                               <td>{data.email}</td>
                           </tr>
                           <tr>
                               <td>{ data.voucherTitle == null ? 'Promo' : 'Voucher' } Code</td>
                               <td>{data.promoCode}</td>
                           </tr>
                           <tr>
                               <td>{ data.voucherTitle == null ? 'Promo' : 'Voucher' } Title</td>
                               <td>{data.voucherTitle == null ? data.promoTitle : data.voucherTitle}</td>
                           </tr>
                           <tr>
                               <td>{ data.voucherTitle == null ? 'Promo' : 'Voucher' } Amount</td>
                               <td>{ data.discountByPromo == null ? formatter.format(0) : formatter.format(data.discountByPromo) }</td>
                           </tr>
                           
                           <tr>
                               <td>National ID</td>
                               <td>{data.nitName}</td>
                           </tr>
                           
                           <tr>
                               <td>No National ID</td>
                               <td>{data.national_id_no}</td>
                           </tr>
                           <tr>
                               <td>Jaminan</td>
                               <td>
                                {data.insurance_img_url != null ? 
                                <a href={data.insurance_img_url} target="_blank" download><span className="label label-info">{data.insurance_img_url}</span><span className="label label-info" style={{float: 'right'}}>
                                    <i className="fa fa-download" style={{color: 'white', fontSize: '20px'}}></i></span></a>
                                :
                                "-"
                                    }
                            </td>
                           </tr>
                           <tr>
                               <td>KTP</td>
                               <td>
                                {data.identity_img != null ? 
                                <a href={data.identity_img} target="_blank" download><span className="label label-info">{data.identity_img}</span><span className="label label-info" style={{float: 'right'}}>
                                    <i className="fa fa-download" style={{color: 'white', fontSize: '20px'}}></i></span></a>
                                :
                                "-"
                                }
                                </td>
                           </tr>
                        </tbody>
                           
                       
                   </table>
                </div>
              
               
            </div>
            { data.status == 94 && (roleUser === 25 || roleUser === 23 ) && (
                <div className="form-btn-group text-right">
                    <button type="button" style={{marginRight: '10px'}} className="btn btn-primary" onClick={approveClaim} >Approve Claim</button>
                    <button type="button" className="btn btn-danger" onClick={rejectClaim} >Reject Claim</button>
                </div>  
            )}   
        </Fragment>
        )}
       </div>
    </DashboardLayout>
  );
};

export default TeleRescheduleDetail;
