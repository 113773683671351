import { orderByIdConstants } from "../actions/constants";

const initState = {
  data: [],
  loading: false,
  error: null,
};

export default (state = initState, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case orderByIdConstants.GET_ORDER_BY_ID_SUCCESS:
        state = {
          ...state,
          data: action.payload.data,
          loading: false,
        };
        break;
      case orderByIdConstants.GET_ORDER_BY_ID_REQUEST:
        state = {
          ...state,
          loading: true,
        };
        break;
      case orderByIdConstants.GET_ORDER_BY_ID_FAILURE:
        state = {
          ...state,
          error: action.payload.error,
          loading: false,
        };
        break;
    }
    return state;        
  };
  