import axios from "../helpers/axios";
import { configConstants } from "./constants"

export const findConfig = (params) => {
  return async (dispatch) => {
    dispatch({
      type: configConstants.FIND_CONFIG_REQUEST,
    });
    const res = await axios.get(`/configs?${params}`);
    if (res.status === 200) {
      dispatch({
        type: configConstants.FIND_CONFIG_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: configConstants.FIND_CONFIG_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};