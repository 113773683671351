import { configConstants } from "../actions/constants"

const initState = {
  data: [],
  totalData: 0,
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case configConstants.FIND_CONFIG_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case configConstants.FIND_CONFIG_SUCCESS:
      state = {
        ...state,
        data: action.payload.configs[0],
        totalData: action.payload.totalData,
        loading: false
      };
      break;
    case configConstants.FIND_CONFIG_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false
      };
      break;
  }

  return state;
};