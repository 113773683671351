import React, { useState } from "react";
import "./login.scss";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../actions";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({
    email: false,
    password: false,
  });
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const role = JSON.parse(localStorage.getItem("user"));
  const routeManagement = role
    ? Object.keys(JSON.parse(role.user_role_management_id.route_group))
    : [];

  const userLogin = (e) => {
    e.preventDefault();

    setError({
      email: email !== "" ? false : true,
      password: password !== "" ? false : true,
    });

    if (email && password) {
      const user = {
        email,
        password,
      };
      dispatch(login(user)).catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
    }
  };

  if (auth.authenticate) {
    if (routeManagement.includes("team_member_management_tele")) {
      window.location = "/my-team-tele";
    } else if (routeManagement.includes("team_member_management")) {
      window.location = "/my-team-homecare";
    } else if (routeManagement.includes("vaccine_management")) {
      window.location = "/my-vaccination-event";
    } else if (routeManagement.includes("order_partner")) {
      window.location = "/dokter-worklist";
    } else if (routeManagement.includes("completed_call_teleconsultation")) {
      window.location = "/completed-call-teleconsultation";
    } else if (routeManagement.includes("waiting_invoice_teleconsultation")) {
      window.location = "/waiting-invoice-teleconsultation";
    } else if (routeManagement.includes("promo_teleconsultation")) {
      window.location = "/my-promo-tele";
    } else if (routeManagement.includes("partner_management")) {
      window.location = "/order-history";
    } else if (routeManagement.includes("register_teleconsultation")) {
      window.location = "/register-teleconsultation";
    } else if (routeManagement.includes("pharmacy_delivery_teleconsultation") || routeManagement.includes("pharmacy_delivery_monitoring")) {
      window.location = "/pharmacy-delivery";
    } else if (routeManagement.includes("order_by_id")) {
      window.location = "/order-by-id";
    } else if (routeManagement.includes("payment_report")) {
      window.location = "/payment-report";
    } else if (routeManagement.includes("gp_onsite")) {
      window.location = "/gp-onsite";
    } else if (routeManagement.includes("health_screening")) {
      window.location = "/CheckIn-HS";
    } else if (routeManagement.includes("pharmacy_offline")) {
      window.location = "/report-pharmacy-offline";
    }
    else {
      Swal.fire({
        icon: "error",
        text: "Something went wrong",
      });
    }
  }

  return (
    <>
      <section className="sign-page">
        <div className="sign-container">
          <div className="form-content">
            <div
              className="form-right"
              style={{
                background: "url(/banner1.jpg)",
                backgroundSize: "cover",
              }}
            >
              <div className="overlay">
                <div className="grid-info-form">
                  <h3 className="title-text">Healthcare Comes To Your Home</h3>
                </div>
              </div>
            </div>

            <div className="form-left">
              <div className="login-logo">
                <img
                  data-cfsrc="/img/logo/main-logo.png"
                  src="/img/logo/main-logo.png"
                  alt="aido-logo"
                />
              </div>

              <div className="middle">
                <h4>Sign in</h4>
                <p>Please sign in with your ID</p>
              </div>

              <form onSubmit={userLogin} className="signin-form">
                <div className="form-input">
                  <label>Email</label>
                  <input
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {error.email && (
                    <div className="error">
                      <label style={{ color: "red" }}>Email is required!</label>
                    </div>
                  )}
                </div>

                <div className="form-input">
                  <label>Password</label>
                  <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {error.password && (
                    <div className="error">
                      <label style={{ color: "red" }}>
                        Password is required!
                      </label>
                    </div>
                  )}
                </div>

                <button type="submit" className="btn">
                  SIGN IN
                </button>
              </form>

              <div className="form-group text-right mt-3">
                <Link to="/forgot-password">
                  Forgot password? <i className="mdi mdi-36px mdi-account"> </i>
                </Link>
              </div>

              <div className="copy-right text-center">
                <p>
                  © 2021{" "}
                  <a href="http://aido.id/" target="_blank">
                    Aido Health
                  </a>
                  . All rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
