import React, { useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  createEventGotongRoyong,
  getAllVaccinationEvent,
} from "../../../../../actions";
import DashboardLayout from "../../../Layout";
import Loading from "../../../../../components/Loading";
import MaterialTable from "material-table";
import Modal from "../../../Components/Modal";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import moment from "moment";
import Swal from "sweetalert2";
import ExcelJS from "exceljs";
import saveAs from "file-saver";

const AddGotongRoyong = () => {
  let history = useHistory();
  const [vaccinationData, setVaccinationData] = useState({
    fullName: "",
    vaccineType: "",
    fulladdress: "",
    companyName: "",
    startDate: "",
    endDate: "",
    logo: [],
    banner: [],
  });
  const [employeeData, setEmployeeData] = useState([]);
  const [excel, setExcel] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState({
    excelName: "Upload Excel",
    logoName: "Upload Image",
    bannerName: "Upload Image",
  });
  const [errors, setErrors] = useState({
    fullName: false,
    vaccineType: false,
    fulladdress: false,
    companyName: false,
    startDate: false,
    endDate: false,
    logo: false,
    banner: false,
  });
  const [viewLogoModal, setViewLogoModal] = useState(false);
  const [viewBannerModal, setViewBannerModal] = useState(false);
  const [addEmployeeModal, setAddEmployeeModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const vaccinationEvent = useSelector((state) => state.myVaccinationEvent);
  const dispatch = useDispatch();
  const { loading, error } = vaccinationEvent;

  const handleCloseModal = () => {
    setViewLogoModal(false);
    setViewBannerModal(false);
    setAddEmployeeModal(false);
  };

  const handleNext = () => {
    setActiveStep((step) => step + 1);
  };

  const actionSubmitVaccination = () => {
    const form = new FormData();

    form.append("parentId", localStorage.getItem("parentId"));
    form.append("fullName", vaccinationData.fullName);
    form.append("fulladdress", vaccinationData.fulladdress);
    form.append("companyName", vaccinationData.companyName);
    form.append("vaccineType", vaccinationData.vaccineType);
    form.append("startDate", vaccinationData.startDate);
    form.append("endDate", vaccinationData.endDate);
    form.append("employeeData", JSON.stringify(employeeData));

    form.append("avatar", vaccinationData.banner);
    form.append("logo", vaccinationData.logo);

    dispatch(createEventGotongRoyong(form))
      .then((res) => {
        if (res) {
          dispatch(getAllVaccinationEvent());
        }

        Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: false,
        }).fire({
          icon: "success",
          title: "Data Created",
        });
        history.replace("/my-vaccination-event");
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  };

  const renderPreviewData = () => {
    return (
      <>
        <div className="wrapper-form-title">
          <span className="form-title">Vaccination Information</span>
        </div>

        <div className="form-group">
          <div style={{ display: "flex" }}>
            <span className="col-4">Event Name</span>
            <span className="col">{`: ${
              vaccinationData.fullName ? vaccinationData.fullName : "-"
            }`}</span>
          </div>

          <div style={{ display: "flex" }}>
            <span className="col-4">Vaccine Type</span>
            <span className="col">{`: ${
              vaccinationData.vaccineType ? vaccinationData.vaccineType : "-"
            }`}</span>
          </div>

          <div style={{ display: "flex" }}>
            <span className="col-4">Location</span>
            <span className="col">{`: ${
              vaccinationData.fulladdress ? vaccinationData.fulladdress : "-"
            }`}</span>
          </div>

          <div style={{ display: "flex" }}>
            <span className="col-4">Company Name</span>
            <span className="col">{`: ${
              vaccinationData.companyName ? vaccinationData.companyName : "-"
            }`}</span>
          </div>

          <div style={{ display: "flex" }}>
            <span className="col-4">Start Date</span>
            <span className="col">{`: ${
              vaccinationData.startDate
                ? moment(vaccinationData.startDate).format("LL")
                : "-"
            }`}</span>
          </div>

          <div style={{ display: "flex" }}>
            <span className="col-4">End Date</span>
            <span className="col">{`: ${
              vaccinationData.endDate
                ? moment(vaccinationData.endDate).format("LL")
                : "-"
            }`}</span>
          </div>
        </div>

        <div className="wrapper-form-title mb-3 d-flex justify-content-between">
          <span className="form-title">
            Employee Data (<span id="employeeTotal">{employeeData.length}</span>
            )
          </span>
        </div>

        {dataTables()}

        <div className="row justify-content-end mt-3">
          <div className="col-md-4 col-lg-3 col-xl-2">
            <button
              className="btn btn-block btn-primary"
              id="form3--final-submit"
              onClick={actionSubmitVaccination}
            >
              Submit
            </button>
          </div>
        </div>
      </>
    );
  };

  const handleExcelFile = (e) => {
    e.preventDefault();
    const workbook = new ExcelJS.Workbook();
    let files = e.target.files[0];
    let dataSet = [];
    setFiles(files);

    workbook.xlsx.load(files).then(() => {
      const worksheet = workbook.getWorksheet(1);

      worksheet.eachRow((row) => {
        const object = Object.assign(
          {},
          row.values.filter((e) => e !== null)
        );

        const renameKeys = (obj, newKeys) => {
          const keyValues = Object.keys(obj).map((key) => {
            const newKey = newKeys[key] || key;
            return { [newKey]: obj[key] };
          });
          return Object.assign({}, ...keyValues);
        };

        const newKeys = {
          0: "fullName",
          1: "dob",
          2: "nik",
          3: "email",
        };

        const renamedObj = renameKeys(object, newKeys);
        dataSet.push(renamedObj);
      });
    });
    setExcel(dataSet);
  };

  const actionImportExcel = () => {
    if (fileName.excelName === "Upload Excel") {
      Swal.fire({
        icon: "error",
        text: "Please choose document first",
      });
    } else {
      setEmployeeData(excel.slice(1, excel.length));
      setAddEmployeeModal(false);
    }
  };

  const renderAddEmployee = () => {
    return (
      <Modal
        modalTitle="Upload Employee Data"
        show={addEmployeeModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        buttons={[
          {
            label: "Import",
            color: "primary",
            onClick: actionImportExcel,
          },
        ]}
      >
        <Row>
          <Col md>
            <div className="form-group">
              <label>Excel File (.xlsx)</label>
              <Form.File
                custom
                label={fileName.excelName}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                data-browse="Drag & Drop or Browse"
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    setFileName({
                      ...fileName,
                      excelName: e.target.files[0].name,
                    });
                    handleExcelFile(e);
                  } else {
                    setFileName({ ...fileName, excelName: "Upload Excel" });
                    setExcel([]);
                  }
                }}
              />
            </div>
          </Col>
        </Row>
      </Modal>
    );
  };

  const dataTables = () => {
    return (
      <MaterialTable
        minRows={10}
        columns={[
          {
            title: "No",
            field: "index",
            width: null,
            cellStyle: { width: 45 },
            headerStyle: { tableLayout: "auto" },
          },
          { title: "Full Name", field: "fullName" },
          { title: "DOB", field: "dob" },
          { title: "NIK", field: "nik" },
          { title: "Email", field: "email" },
        ]}
        data={employeeData.map((item, index) => ({
          index: index + 1,
          fullName: item.fullName,
          dob: moment(item.dob).format("YYYY-MM-DD"),
          nik: item.nik,
          email: item.email,
        }))}
        options={{
          search: true,
          headerStyle: {
            backgroundColor: "rgba(57,169,255,.18)",
            color: "#39a9ff",
            boxSizing: "border-box",
            border: "1px solid #cfd4d9",
            fontFamily: "source sans pro",
            fontWeight: 700,
            width: 45,
            padding: ".75rem",
          },
          rowStyle: {
            border: "1px solid #cfd4d9",
            height: 43,
            padding: ".75rem",
          },
          cellStyle: {
            border: "1px solid #cfd4d9",
            padding: ".75rem",
          },
          searchFieldStyle: {
            fontFamily: "source sans pro",
          },
          showTitle: false,
          tableLayout: "auto",
          pageSize: employeeData.length < 10 ? employeeData.length : 10,
          pageSizeOptions:
            employeeData.length < 10
              ? [employeeData.length, 10, 25, 50, 100]
              : [10, 25, 50, 100],
        }}
        style={{
          color: "#212529",
          boxShadow: "none",
        }}
      />
    );
  };

  const handleDownload = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("template");
    let row;

    row = worksheet.addRow(["fullName", "dob", "nik", "email"]);
    row.font = { bold: true };
    worksheet.addRow([
      "Nama Lengkap",
      "1998-12-30",
      1234567890,
      "email@email.xyz",
    ]);

    let date = new Date().getTime();
    workbook.xlsx.writeBuffer().then((buff) => {
      saveAs(new Blob([buff]), `template-${date}.xlsx`);
    });
  };

  const renderEmployeeData = () => {
    return (
      <>
        {employeeData.length > 0 ? (
          <>
            <div className="wrapper-form-title mb-3 d-flex justify-content-between">
              <span className="form-title">
                Employee Data (<span>{employeeData.length}</span>)
              </span>
              <button
                className="btn btn-outline-primary px-5"
                onClick={() => saveAs(files)}
              >
                Download
              </button>
            </div>

            {dataTables()}

            <div className="row justify-content-end mt-3">
              <div className="col-md-4 col-lg-3 col-xl-2">
                <button
                  type="button"
                  className="btn btn-block btn-primary"
                  style={{ width: "100%" }}
                  onClick={handleNext}
                >
                  Next
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="row">
            <div className="col-12 text-center mt-5">
              <img src="/img/illustration/illustrate-upload-employee-data.svg" />
            </div>

            <div className="col-12 text-center mt-4">
              <div> There are no employee data yet </div>
            </div>

            <div className="col-12 text-center mt-4">
              <button
                className="btn btn-primary"
                style={{ marginRight: "5px", marginBottom: "1em" }}
                onClick={() => handleDownload()}
              >
                Download Template
              </button>
              <button
                className="btn btn-primary"
                style={{ marginBottom: "1em" }}
                onClick={() => setAddEmployeeModal(true)}
              >
                Upload File
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  const actionValidation = () => {
    setErrors({
      fullName: vaccinationData.fullName === "" ? true : false,
      vaccineType: vaccinationData.vaccineType === "" ? true : false,
      fulladdress: vaccinationData.fulladdress === "" ? true : false,
      companyName: vaccinationData.companyName === "" ? true : false,
      startDate: vaccinationData.startDate === "" ? true : false,
      endDate: vaccinationData.endDate === "" ? true : false,
      logo: vaccinationData.logo.length === 0 ? true : false,
      banner: vaccinationData.banner.length === 0 ? true : false,
    });

    const readyForSubmit = () => {
      if (vaccinationData.fullName === "") {
        return false;
      }
      if (vaccinationData.vaccineType === "") {
        return false;
      }
      if (vaccinationData.fulladdress === "") {
        return false;
      }
      if (vaccinationData.companyName === "") {
        return false;
      }
      if (vaccinationData.startDate === "") {
        return false;
      }
      if (vaccinationData.endDate === "") {
        return false;
      }
      if (vaccinationData.logo.length === 0) {
        return false;
      }
      if (vaccinationData.banner.length === 0) {
        return false;
      }
      return true;
    };

    if (readyForSubmit()) {
      handleNext();
    }
  };

  const handleView = (e) => {
    let type = e.target.name;

    if (type === "logo") {
      setViewLogoModal(true);
    } else if (type === "banner") {
      setViewBannerModal(true);
    }
  };

  const renderViewLogo = () => {
    return (
      <Modal
        modalTitle="Company Logo Image"
        show={viewLogoModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        buttons={[]}
        close
      >
        <Row>
          <Col md>
            <img
              className="text-center"
              src={
                vaccinationData.logo.length !== 0
                  ? URL.createObjectURL(vaccinationData.logo)
                  : "/img/icon/ic_camera.png"
              }
              style={{
                borderRadius: "10px",
                width: "100%",
              }}
            />
          </Col>
        </Row>
      </Modal>
    );
  };

  const renderViewBanner = () => {
    return (
      <Modal
        modalTitle="Company Banner Image"
        show={viewBannerModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        buttons={[]}
        close
      >
        <Row>
          <Col md>
            <img
              className="text-center"
              src={
                vaccinationData.banner.length !== 0
                  ? URL.createObjectURL(vaccinationData.banner)
                  : "/img/icon/ic_camera.png"
              }
              style={{
                borderRadius: "10px",
                width: "100%",
              }}
            />
          </Col>
        </Row>
      </Modal>
    );
  };

  const renderVaccinationData = () => {
    return (
      <>
        <div className="row">
          <div className="form-group col-md-6">
            <div className="mb10">Event Name</div>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Input Event Name"
                value={vaccinationData.fullName}
                onChange={(e) =>
                  setVaccinationData({
                    ...vaccinationData,
                    fullName: e.target.value,
                  })
                }
              />
            </div>
            {errors.fullName && (
              <div className="error">
                <label className="is-invalid">
                  Please input event name first
                </label>
              </div>
            )}
          </div>

          <div className="form-group col-md-6">
            <div className="mb10">Vaccine Type</div>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Input Vaccine Type"
                value={vaccinationData.vaccineType}
                onChange={(e) =>
                  setVaccinationData({
                    ...vaccinationData,
                    vaccineType: e.target.value,
                  })
                }
              />
            </div>
            {errors.vaccineType && (
              <div className="error">
                <label className="is-invalid">
                  Please input vaccine type first
                </label>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <div className="mb10">Vaccination Location</div>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Input Venue Name"
                value={vaccinationData.fulladdress}
                onChange={(e) =>
                  setVaccinationData({
                    ...vaccinationData,
                    fulladdress: e.target.value,
                  })
                }
              />
            </div>
            {errors.fulladdress && (
              <div className="error">
                <label className="is-invalid">
                  Please input venue name first
                </label>
              </div>
            )}
          </div>

          <div className="form-group col-md-6">
            <div className="mb10">Company Name</div>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Input Company Name"
                value={vaccinationData.companyName}
                onChange={(e) =>
                  setVaccinationData({
                    ...vaccinationData,
                    companyName: e.target.value,
                  })
                }
              />
            </div>
            {errors.companyName && (
              <div className="error">
                <label className="is-invalid">
                  Please input company name first
                </label>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <div className="mb10">Start Date</div>
            <div className="input-group">
              <input
                type="text"
                className="form-control dateInput"
                placeholder="Select Date"
                min={moment().format("YYYY-MM-DD")}
                value={vaccinationData.startDate}
                onFocus={(e) => {
                  e.currentTarget.type = "date";
                  e.currentTarget.focus();
                }}
                onChange={(e) =>
                  setVaccinationData({
                    ...vaccinationData,
                    startDate: e.target.value,
                  })
                }
              />
            </div>
            {errors.startDate && (
              <div className="error">
                <label className="is-invalid">Please select date first</label>
              </div>
            )}
          </div>

          <div className="form-group col-md-6">
            <div className="mb10">End Date</div>
            <div className="input-group">
              <input
                type="text"
                className="form-control dateInput"
                placeholder="Select Date"
                min={moment().format("YYYY-MM-DD")}
                value={vaccinationData.endDate}
                onFocus={(e) => {
                  e.currentTarget.type = "date";
                  e.currentTarget.focus();
                }}
                onChange={(e) =>
                  setVaccinationData({
                    ...vaccinationData,
                    endDate: e.target.value,
                  })
                }
              />
            </div>
            {errors.endDate && (
              <div className="error">
                <label className="is-invalid">Please select date first</label>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <div className="mb10">Company Logo</div>
            <div className="input-group">
              <div className="input-group-prepend">
                <img
                  name="logo"
                  src={
                    vaccinationData.logo.length !== 0
                      ? URL.createObjectURL(vaccinationData.logo)
                      : "/img/icon/ic_camera.png"
                  }
                  style={{
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleView(e)}
                />
              </div>
              <Form.File
                custom
                label={fileName.logoName}
                accept="image/*"
                data-browse="Drag & Drop or Browse"
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    setFileName({
                      ...fileName,
                      logoName: e.target.files[0].name,
                    });
                    setVaccinationData({
                      ...vaccinationData,
                      logo: e.target.files[0],
                    });
                  } else {
                    setFileName({
                      ...fileName,
                      logoName: "Upload Image",
                    });
                    setVaccinationData({
                      ...vaccinationData,
                      logo: [],
                    });
                  }
                }}
              />
            </div>
            <div className="mt10">
              <label>
                Please upload 1080x1080px resolution photo (JPG or PNG) and less
                than 1MB
              </label>
            </div>
            {errors.logo && (
              <div className="error">
                <label className="is-invalid">Please choose image first</label>
              </div>
            )}
          </div>

          <div className="form-group col-md-6">
            <div className="mb10">Company Banner</div>
            <div className="input-group">
              <div className="input-group-prepend">
                <img
                  name="banner"
                  src={
                    vaccinationData.banner.length !== 0
                      ? URL.createObjectURL(vaccinationData.banner)
                      : "/img/icon/ic_camera.png"
                  }
                  style={{
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleView(e)}
                />
              </div>
              <Form.File
                custom
                label={fileName.bannerName}
                accept="image/*"
                data-browse="Drag & Drop or Browse"
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    setFileName({
                      ...fileName,
                      bannerName: e.target.files[0].name,
                    });
                    setVaccinationData({
                      ...vaccinationData,
                      banner: e.target.files[0],
                    });
                  } else {
                    setFileName({
                      ...fileName,
                      bannerName: "Upload Image",
                    });
                    setVaccinationData({
                      ...vaccinationData,
                      banner: [],
                    });
                  }
                }}
              />
            </div>
            <div className="mt10">
              <label>
                Please upload 1080x576px resolution photo (JPG or PNG) and less
                than 1MB
              </label>
            </div>
            {errors.banner && (
              <div className="error">
                <label className="is-invalid">Please choose image first</label>
              </div>
            )}
          </div>
        </div>

        <div className="row justify-content-end mt-5">
          <div className="col col-md-2">
            <Link to="/my-vaccination-event">
              <button
                type="button"
                className="btn btn-block btn-outline-primary"
              >
                Cancel
              </button>
            </Link>
          </div>
          <div className="col col-md-2">
            <button
              type="button"
              className="btn btn-block btn-primary"
              style={{ width: "100%" }}
              onClick={actionValidation}
            >
              Next Step
            </button>
          </div>
        </div>
      </>
    );
  };

  const style = createMuiTheme({
    overrides: {
      MuiStepIcon: {
        root: {
          "&$active": {
            color: "#38a8ff",
          },
          "&$completed": {
            color: "#38a8ff",
          },
        },
      },
      MuiStepLabel: {
        label: {
          "&$active": {
            color: "#38a8ff",
          },
          "&$completed": {
            color: "#38a8ff",
          },
        },
      },
    },
  });

  const getLabel = () => {
    return ["Vaccination Information", "Upload Employee Data", "Submit"];
  };

  return (
    <DashboardLayout>
      {loading ? (
        <div className="table-wrapper">
          <Loading />
        </div>
      ) : error ? (
        error.message
      ) : (
        <>
          <div className="row revenue-title">
            <div className="col">
              <hr />
            </div>

            <div>Create Vaksinasi Gotong Royong</div>

            <div className="col">
              <hr />
            </div>
          </div>

          <div className="table-wrapper">
            <div className="alert alert-info" style={{ textAlign: "center" }}>
              <p style={{ fontSize: "15px", marginBottom: "10px" }}>
                Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
                aido, silahkan hubungi aido support dengan click chat aido
                support.
                <br />
                <a
                  href="https://wa.me/628118481436"
                  style={{ color: "#51BED3" }}
                  target="blank"
                >
                  Chat Aido Support
                </a>
              </p>
            </div>

            <ThemeProvider theme={style}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {getLabel().map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </ThemeProvider>

            {activeStep === 0 && renderVaccinationData()}
            {activeStep === 1 && renderEmployeeData()}
            {activeStep === 2 && renderPreviewData()}
          </div>
          {renderViewLogo()}
          {renderViewBanner()}
          {renderAddEmployee()}
        </>
      )}
    </DashboardLayout>
  );
};

export default AddGotongRoyong;
