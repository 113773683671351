import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllWaitingInvoice } from "../../../actions";
import Pagination from "../../../components/Pagination";
import DashboardLayout from "../Layout";
import queryString from "query-string";
import moment from "moment";
import { Link } from "react-router-dom";

const WaitingInvoice = () => {
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const searchKey = useRef("");
  const startDate = useRef("");
  const endDate = useRef("");
  const waitingInvoice = useSelector((state) => state.waitingInvoice);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllWaitingInvoice(search));
  }, [search]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: waitingInvoice.totalData,
    });
  }, [waitingInvoice.totalData]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const handleAutoComplete = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      searchKey: searchKey.current.value,
      page: pagination.page,
      limit: pagination.limit,
    };
    if (startDate.current.value !== "") {
      values.startDate = startDate.current.value;
    }
    if (endDate.current.value !== "") {
      values.endDate = endDate.current.value;
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleReset = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      searchKey: searchKey.current.value,
      page: pagination.page,
      limit: pagination.limit,
    };
    if (startDate.current.value !== "") {
      startDate.current.value = "";
    }
    if (endDate.current.value !== "") {
      endDate.current.value = "";
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleSearch = (page, limit) => {
    let values = {
      searchKey: searchKey.current.value,
    };
    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }
    if (limit) {
      values.limit = limit;
    }
    if (startDate.current.value !== "") {
      values.startDate = startDate.current.value;
    }
    if (endDate.current.value !== "") {
      values.endDate = endDate.current.value;
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const renderInvoices = () => {
    return waitingInvoice.loading ? (
      <tr>
        <td colSpan="12">
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        {waitingInvoice.orders.length > 0 ? (
          waitingInvoice.orders.map((invoice, index) => (
            <tr key={invoice.order_id}>
              <td>{(pagination.page - 1) * 10 + index + 1}</td>
              <td>{invoice.order_id ? invoice.order_id : "-"}</td>
              <td>{invoice.patient_name ? invoice.patient_name : "-"}</td>
              <td>
                {invoice.patient_dob
                  ? moment(invoice.patient_dob).format("ll")
                  : "-"}
              </td>
              <td>{invoice.customer_phone ? invoice.customer_phone : "-"}</td>
              <td>{invoice.patient_phone ? invoice.patient_phone : "-"}</td>
              <td>
                {invoice.scheduleDate
                  ? moment(invoice.scheduleDate).format("ll")
                  : "-"}
              </td>
              <td>
                {invoice.startTime
                  ? moment(invoice.startTime, "HH:mm:ss").format("HH:mm")
                  : "-"}
              </td>
              <td>
                {invoice.endTime
                  ? moment(invoice.endTime, "HH:mm:ss").format("HH:mm")
                  : "-"}
              </td>
              <td>{invoice.doctor_name ? invoice.doctor_name : "-"}</td>
              {
                localStorage.getItem("parentId") == 3295 ? (
                  <td>{!invoice.isHealthScreening && !invoice.isPremium ? "Free Tele" : !invoice.eventId && invoice.isPremium ? "Tanya Dokter Premium" : invoice.isHealthScreening ? "Health Screening" : ""}</td>
                ) : <></>
              }
              <td>{invoice.statusDesc ? invoice.statusDesc : "-"}</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <Link
                    to={{
                      pathname: "/waiting-invoice-teleconsultation/detail",
                      state: invoice.order_id,
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-block btn-outline-primary"
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      Detail
                    </button>
                  </Link>

                  {invoice.channel_url === null ? (
                    <a>
                      <button
                        type="button"
                        className="btn btn-block btn-outline-secondary"
                        disabled
                      >
                        Chat
                      </button>
                    </a>
                  ) : (
                    <a
                      href={`${process.env.REACT_APP_CHAT_URL}chat.html?userid=${waitingInvoice.parentId}&userToken=${waitingInvoice.sendBird}&chatUrl=${invoice.channel_url}`}
                      target="_blank"
                    >
                      <button
                        type="button"
                        className="btn btn-block btn-outline-primary"
                      >
                        Chat
                      </button>
                    </a>
                  )}
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="12">Empty data</td>
          </tr>
        )}
      </>
    );
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Waiting Invoice Teleconsultation</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        <div className="row">
          <div className="col-md-8">
            <span id="finance-title" className="mb10">
              Waiting Invoice Teleconsultation Order
            </span>
          </div>

          <div style={{ marginBottom: "1em" }} className="col-md-4">
            <div className="input-group">
              <input
                type="text"
                className="form-control table-search-field"
                placeholder="Search Patient Name"
                onChange={handleAutoComplete}
                ref={searchKey}
              />
            </div>
          </div>
        </div>

        <div className="row mb15">
          <div className="link-btn">
            <button
              type="button"
              className="btn btn-link no-padding"
              style={{ textDecoration: "none", boxShadow: "none" }}
              data-toggle="collapse"
              data-target="#collapseDetailSchedule"
            >
              Filter By
              <i className="fa fa-angle-down" style={{ marginLeft: "10px" }} />
            </button>
          </div>

          <div className="col">
            <hr />
          </div>
        </div>

        <div className="collapse" id="collapseDetailSchedule">
          <div id="filterForm" className="row filter-by">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <div className="mb10">Date From</div>
                  <input type="date" className="form-control" ref={startDate} />
                </div>

                <div className="col-md-4">
                  <div className="mb10">Date To</div>
                  <input type="date" className="form-control" ref={endDate} />
                </div>
              </div>
            </div>
          </div>

          <div className="text-right" style={{ marginTop: "25px" }}>
            <button
              type="button"
              className="btn btn-secondary"
              style={{ width: "100px", marginRight: "5px" }}
              onClick={() => handleReset()}
            >
              Reset
            </button>

            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100px" }}
              onClick={() => handleSearch()}
            >
              Apply
            </button>
          </div>

          <div style={{ width: "100%" }}>
            <hr />
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "45px" }}
                >
                  No
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Order ID
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "150px" }}
                >
                  Patient Name
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Patient Date of Birth
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Customer Phone
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Patient Phone
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Schedule Date
                </th>

                <th colSpan={2} className="table-header-middle">
                  Time
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "150px" }}
                >
                  Doctor Name
                </th>
                {
                  localStorage.getItem("parentId") == 3295 ? (
                    <th rowSpan={2} className="table-header-middle">
                      Type
                    </th>) : <></>
                }
                <th rowSpan={2} className="table-header-middle">
                  Status
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Action
                </th>
              </tr>

              <tr>
                <th>Start</th>
                <th>End</th>
              </tr>
            </thead>
            <tbody>{renderInvoices()}</tbody>
          </table>
        </div>

        {waitingInvoice.totalData > 10 ? (
          <Pagination
            props={pagination}
            setProps={setPagination}
            onChange={(page, limit) => {
              handleSearch(page, limit);
            }}
          />
        ) : null}
      </div>
    </DashboardLayout>
  );
};

export default WaitingInvoice;
