import { orderVaccineConstants } from "../actions/constants";

const initState = {
  orders: [],
  totalData: null,
  loading: false,
  error: null,
  order: {},
  questions: [],
  skrining_detail: [],
  skrining_result: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case orderVaccineConstants.GET_ALL_ORDER_VACCINE_REQUEST:
      return {
          ...state,
          loading: true,
      };
    case orderVaccineConstants.GET_ALL_ORDER_VACCINE_SUCCESS:
      return {
        ...state,
        orders: action.payload.orders,
        totalData: action.payload.totalData,
        loading: false,
      };
    case orderVaccineConstants.GET_ALL_ORDER_VACCINE_FAILURE:
      return {
          ...state,
          error: action.payload.error,
          loading: false,
      }
    case orderVaccineConstants.ORDER_VACCINE_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderVaccineConstants.ORDER_VACCINE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        order: action.payload.order,
      }
    case orderVaccineConstants.ORDER_VACCINE_DETAIL_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case orderVaccineConstants.GET_SKRINING_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderVaccineConstants.GET_SKRINING_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        skrining_detail: action.payload.skrining_detail[0],
      }
    case orderVaccineConstants.GET_SKRINING_DETAIL_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case orderVaccineConstants.ORDER_VACCINE_RESULT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderVaccineConstants.ORDER_VACCINE_RESULT_SUCCESS:
      return {
        ...state,
        skrining_result: action.payload.skrining_result,
        loading: false,
      }
    case orderVaccineConstants.ORDER_VACCINE_RESULT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case orderVaccineConstants.UPDATE_VACCINE_NOTE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderVaccineConstants.UPDATE_VACCINE_NOTE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case orderVaccineConstants.UPDATE_VACCINE_NOTE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case orderVaccineConstants.UPDATE_ORDER_VACCINE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderVaccineConstants.UPDATE_ORDER_VACCINE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case orderVaccineConstants.UPDATE_ORDER_VACCINE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    
    case orderVaccineConstants.GET_QUESTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderVaccineConstants.GET_QUESTION_SUCCESS:
      return {
        ...state,
        questions: action.payload.questions,
        loading: false,
      }
    case orderVaccineConstants.GET_QUESTION_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      } 
    case orderVaccineConstants.GET_ORDER_FORM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderVaccineConstants.GET_ORDER_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        skrining_detail: action.payload.skrining_detail[0],
      }
    case orderVaccineConstants.GET_ORDER_FORM_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case orderVaccineConstants.UPDATE_SKRINING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderVaccineConstants.UPDATE_SKRINING_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case orderVaccineConstants.UPDATE_SKRINING_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case orderVaccineConstants.GET_VACCINE_DAILY_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderVaccineConstants.GET_VACCINE_DAILY_REPORT_SUCCESS:
      return {
        ...state,
        orders: action.payload.orders,
        loading: false,
      }
    case orderVaccineConstants.GET_VACCINE_DAILY_REPORT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    default: 
      return state;
  }
};
