import React, { useState } from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../../Dashboard/Layout";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/Loading";
import { InputGroup, FormControl } from "react-bootstrap";
import { changePassword } from "../../../actions/user.actions";
import Swal from 'sweetalert2';

const Breadcrumb = (props) => {
    return (
        <nav key={props.label} aria-label="breadcrumb" id="breadcrumb-id">
            <ol className="breadcrumb pl-0 mt-1">
              <li className="breadcrumb-item" aria-current="page">
                <Link to={props.path}>{props.menu}</Link>
              </li>
            </ol>
        </nav>
    )
}

const ChangePassword = () => {
    const [ inputOldPassword, setInputOldPassword ] = useState("");
    const [ inputNewPassword, setInputNewPassword ] = useState(""); 
    const [ retypeNewPassword, setRetypeNewPassword ] = useState(""); 
    const [ passwordShown, setPasswordShown ] = useState(false);
    const [errors, setErrors] = useState(
        {
            blankOldPassword: false,
            blankNewPassword: false,
            blankRetypePassword: false,
            oldPasswordUnder5: false,
            newPasswordUnder5: false,
            retypePasswordNotMatch: false,
        }
    );

    const user = useSelector((state) => state.userProfile);
    const dispatch = useDispatch();

    const {loading, error} = user;
    
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const handleSubmit = () => {
        const submitValue = {
            inputOldPassword: inputOldPassword,
            inputNewPassword: inputNewPassword,
            retypeNewPassword: retypeNewPassword,
        };

        setErrors((prev) => {
            return {
            ...prev,
            blankOldPassword: submitValue.inputOldPassword === "" ? true : false,
            blankNewPassword: submitValue.inputNewPassword === "" ? true : false,
            blankRetypePassword: submitValue.retypeNewPassword === "" ? true : false,
            oldPasswordUnder5: submitValue.inputOldPassword.length < 5 ? true : false,
            newPasswordUnder5: submitValue.inputNewPassword.length < 5 ? true : false,
            retypePasswordNotMatch: submitValue.retypeNewPassword === submitValue.inputNewPassword ? false : true,
            };
        });

        const readyForSubmit = () => {
            if (submitValue.inputOldPassword === "") {
                return false;
            }      
            if (submitValue.inputNewPassword === "") {
                return false;
            }
            if (submitValue.retypeNewPassword === "") {
                return false;
            }
            if (submitValue.inputOldPassword.length < 5) {
                return false;
            }
            if (submitValue.inputNewPassword.length < 5) {
                return false;
            }
            if (submitValue.retypeNewPassword !== submitValue.inputNewPassword) {
            return false;
            }
            return true;
        };

        if (readyForSubmit()) {
            const formData = new FormData();
            formData.append("oldPassword", inputOldPassword);
            formData.append("password", inputNewPassword);

            dispatch(changePassword(formData))
            .then((res) => {        
                Swal.fire({
                    icon: 'success',
                    text: 'Change Password Success',
                    timer: 1000
                })
                
            }).catch((error) => {
                if (error.response) {
                    setTimeout(() => 
                    Swal.fire({
                        icon: 'error',
                        text: error.response.data.message,
                    }).then((isConfirm) =>{
                        if (isConfirm) {
                          window.location.reload();
                        } 
                      }), 1000
                )

                } else {
                    // Something happened in setting up the request that triggered an Error
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.message,
                    })
                }
            })
            
            setInputOldPassword("");
            setInputNewPassword("");
            setRetypeNewPassword("");

        }
    }

    return (
        <DashboardLayout>
            <Breadcrumb path={"/change-password"}
                menu={"Change Password"}
                />
            { loading ? 
                <div className="table-wrapper">
                    <Loading/>
                </div> : error ? error.message : (
            <div className="table-wrapper">
                <form id="formChangePassword" action="{{ route('submit-change-password') }}">
    
                    <div className="col-md-13" style={{ marginBottom: '80px' }}>
                        
                        <div className="form-group">
                            <label htmlFor="oldPassword">Current Password</label>
                            <input 
                                type={passwordShown ? "text" : "password"}
                                className={ (errors.blankOldPassword || errors.oldPasswordUnder5) ? "form-control is-invalid" : "form-control"}
                                name="oldPassword" 
                                placeholder="Input Current Password"
                                onChange={(e) =>{                   
                                    setInputOldPassword(e.target.value)
                                    }
                                }
                                required    
                                />
                        </div>
                        
                        <div className="error mb-3">
                            {errors.blankOldPassword ? (
                                <label className="is-invalid">Please fill the password.</label>
                            ) : errors.oldPasswordUnder5 ? (
                                <label className="is-invalid">The password must be at least 5 characters.</label>
                            ) : null
                            }
                        </div>
                     
                        
                        <div className="form-group">
                            <label htmlFor="password">New Password</label>
                            <InputGroup className="mb-3">
                                <FormControl
                                    type={passwordShown ? "text" : "password"}
                                    name="password" 
                                    onChange={(e) =>{                   
                                        setInputNewPassword(e.target.value)
                                        }
                                    }
                                    placeholder="Input New Password"
                                    aria-label="Input New Password"
                                    aria-describedby="basic-addon2"
                                    isInvalid={ !!(errors.blankNewPassword || errors.newPasswordUnder5) }
                                    required
                                    />
                                <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">
                                        <i className={passwordShown ? "fa fa-eye" : "fa fa-eye-slash"} 
                                            aria-hidden="true" 
                                            onClick={togglePasswordVisiblity}>    
                                        </i>
                                        </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>

                        <div className="error mb-3">
                            {errors.blankNewPassword ? (
                                <label className="is-invalid">Please fill the new password.</label>
                            ) : errors.newPasswordUnder5 ? (
                                <label className="is-invalid">The password must be at least 5 characters.</label>
                            ) : null
                            }
                        </div>
                        
            
                        <div className="form-group">
                            <label htmlFor="confirm_password">Re-type Password</label>
                            <input 
                                type={passwordShown ? "text" : "password"}
                                className={ (errors.blankRetypePassword || errors.retypePasswordNotMatch) ? "form-control is-invalid" : "form-control"}
                                name="confirm_password" 
                                placeholder="Input New Password"
                                onChange={(e) =>{                   
                                    setRetypeNewPassword(e.target.value)
                                    }
                                } 
                                required
                                />
                        </div>

                        <div className="error mb-3">
                            {errors.blankRetypePassword ? (
                                <label className="is-invalid">Please fill the new password again.</label>
                            ) : errors.retypePasswordNotMatch ? (
                                <label className="is-invalid">Entered passwords do not match.</label>
                            ) : null
                            }
                        </div>
                    </div>


                    <div className="form-btn-group text-right">
                        <button 
                            type="reset" 
                            className="btn btn-secondary mr-1"
                            onClick={
                                () => {
                                    setInputOldPassword("")
                                    setInputNewPassword("")
                                    setRetypeNewPassword("")
                                    setErrors(
                                        {
                                            blankOldPassword: false,
                                            blankNewPassword: false,
                                            blankRetypePassword: false,
                                            oldPasswordUnder5: false,
                                            newPasswordUnder5: false,
                                            retypePasswordNotMatch: false,
                                        }
                                    )}
                                }
                            >
                            Cancel
                        </button>
                        <button 
                            type="button" 
                            className="btn btn-primary"
                            onClick={handleSubmit}>Save</button>
                    </div>
                </form>
            </div>
            )
        }
        </DashboardLayout>
    )
}

export default ChangePassword;