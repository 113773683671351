import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Layout from "../Layout";

const Promo = () => {
  const { state } = useLocation();
  const testCovid = useSelector((state) => state.testCovid);
  const dispatch = useDispatch();
  const { loading, error } = testCovid;

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  return (
    <Layout title="Kode Promo / Voucher" link="/test-covid/order" state={state}>
      {loading ? (
        <Loading />
      ) : (
        <div style={{ display: "flex" }}>
          <input
            type="text"
            className="form-control"
            placeholder="Kode Promo"
          />

          <button
            type="button"
            className="btn btn-primary"
            style={{ marginLeft: "10px" }}
            // onClick={actionSubmit}
          >
            Pakai
          </button>
        </div>
      )}
    </Layout>
  );
};

export default Promo;
