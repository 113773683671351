import axios from "../helpers/axios";
import { teleRescheduleConstants } from "./constants";
import axiosPartnerApi from "../helpers/axiosPartnerApi";
import Swal from 'sweetalert2';

export const getTeleReschedule = (q) => {
  return async (dispatch) => {
    dispatch({ type: teleRescheduleConstants.GET_TELE_RESCHEDULE_REQUEST });
    const res = await axios.get(
      `/Order/newAndRescheduleTeleconsultation?${q}`, 
    );
    if (res.status === 200) {
      const { orders, totalData, sendBird, parentId } = res.data;
      dispatch({
        type: teleRescheduleConstants.GET_TELE_RESCHEDULE_SUCCESS,
        payload: { orders, totalData, sendBird, parentId },
      });
    } else {
      dispatch({
        type: teleRescheduleConstants.GET_TELE_RESCHEDULE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getTeleDetail = (id) => {
  return async (dispatch) => {
    dispatch({ type: teleRescheduleConstants.GET_TELE_DETAIL_REQUEST });
    const res = await axios.get(
      `Order/completedCallTeleconsultation/${id}`, 
    );
    if (res.status === 200) {
      const { order } = res.data;
      dispatch({
        type: teleRescheduleConstants.GET_TELE_DETAIL_SUCCESS,
        payload: { order },
      });
    } else {
      dispatch({
        type: teleRescheduleConstants.GET_TELE_DETAIL_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getScheduleData = (dataSch) => {
  return async (dispatch) => {
    dispatch({ type: teleRescheduleConstants.GET_SCHEDULE_DATA_REQUEST });
    const res = await axios.post(
      `/Siloam/Schedule`, dataSch 
    );
    if (res.status === 200) {
      var json  = res.data;
      var schedule_data = JSON.parse(json.body)
      dispatch({
        type: teleRescheduleConstants.GET_SCHEDULE_DATA_SUCCESS,
        payload: { schedule_data },
      });
    } else {
      dispatch({
        type: teleRescheduleConstants.GET_SCHEDULE_DATA_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getScheduleNonSiloam = (partnerId) => {
  return async (dispatch) => {
    dispatch({ type: teleRescheduleConstants.GET_SCHEDULE_NONSILOAM_REQUEST });
    const res = await axios.get(
      `Order/Schedule/${partnerId}`, 
    );
    if (res.status === 200) {
      var data = res.data;
      var schedule_data = Object.values(JSON.parse(JSON.stringify(data)))
      dispatch({
        type: teleRescheduleConstants.GET_SCHEDULE_NONSILOAM_SUCCESS,
        payload: { schedule_data },
      });
    } else {
      dispatch({
        type: teleRescheduleConstants.GET_SCHEDULE_NONSILOAM_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const rescheduleTele = (data) => {
  return async (dispatch) => {
    dispatch({ type: teleRescheduleConstants.RESCHEDULE_TELE_REQUEST });
    const res = await axios.post(
      "/Order/Reschedule", data 
    );
    if (res.status === 200) {
      // const { orders } = res.data;
      let data;
      res.data.orders ? (data = res.data.orders) : (data = res.data);
      dispatch({
        type: teleRescheduleConstants.RESCHEDULE_TELE_SUCCESS,
        payload: { data },
      });
      if (!res.data.orders && JSON.parse(data.body).message == "Schedule used in other user.") {
        // var res_data = JSON.parse(data.body)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "Schedule used in other user.",
        })
      } else {
        Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
        }).fire({
            icon: 'success',
            title: 'Reschedule Success'
        })
        setTimeout(
          () => (window.location = "/re-schedule"),
          4000
        )
      }
      dispatch(getTeleReschedule())
    } else {
      dispatch({
        type: teleRescheduleConstants.RESCHEDULE_TELE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const cancelOrderTele = (id) => {
  return async (dispatch) => {
    dispatch({ type: teleRescheduleConstants.CANCEL_ORDER_TELE_REQUEST });
    const res = await axios.get(
      `Order/Cancel/${id}`, 
    );
    if (res.status === 200) {
      dispatch({
        type: teleRescheduleConstants.CANCEL_ORDER_TELE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: teleRescheduleConstants.CANCEL_ORDER_TELE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const approveClaimOrderTele = (form) => {
  return async (dispatch) => {
    dispatch({ type: teleRescheduleConstants.APPROVECLAIM_TELE_REQUEST });
    const res = await axiosPartnerApi.post(`/App/Snorlax/OrderTele/ApproveInsurance`, form);
    if (res.status === 200) {
      var data  = res.data;
      dispatch({
        type: teleRescheduleConstants.APPROVECLAIM_TELE_SUCCESS,
        payload: { data },
      });
    } else {
      dispatch({
        type: teleRescheduleConstants.APPROVECLAIM_TELE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const rejectClaimOrderTele = (form) => {
  return async (dispatch) => {
    dispatch({ type: teleRescheduleConstants.REJECTCLAIM_TELE_REQUEST });
    const res = await axiosPartnerApi.post(`/App/Snorlax/OrderTele/RejectInsurance`, form);
    if (res.status === 200) {
      var data  = res.data;
      dispatch({
        type: teleRescheduleConstants.REJECTCLAIM_TELE_SUCCESS,
        payload: { data },
      });
    } else {
      dispatch({
        type: teleRescheduleConstants.REJECTCLAIM_TELE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};