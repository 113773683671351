import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { createPayment, getCovidDetail } from "../../../actions";
import Swal from "sweetalert2";
import moment from "moment";
import Layout from "../Layout";
import "./index.scss";

const Order = () => {
  const { state } = useLocation();
  const history = useHistory();
  const testCovid = useSelector((state) => state.testCovid);
  const dispatch = useDispatch();
  const { loading, error, detailData, addressData } = testCovid;

  useEffect(() => {
    dispatch(getCovidDetail(state.packageServiceId));
  }, []);

  useEffect(() => {
    if (addressData) {
      history.push({
        state: {
          ...state,
          addressId: addressData.id,
        },
      });
    }
  }, []);

  const paymentData = {
    serviceType: state.serviceType ? state.serviceType : "",
    xenditToken: state.xenditToken ? state.xenditToken : "",
    paymentChannel: state.paymentType ? state.paymentType : "",
    partnerId: state.partnerId ? state.partnerId : "",
    cartList: state.id
      ? [
          {
            packageId: state.id,
            packageServiceId: state.packageServiceId,
            price: state.price,
            quantity: 1,
          },
        ]
      : "",
    address: state.addressId ? state.addressId : "",
    patient: state.patientId ? state.patientId : "",
    orderRequestTimes: state.requestDate
      ? JSON.stringify([
          {
            requestDate: moment(state.requestDate).format("YYYY-MM-DD"),
            requestTime: null,
          },
        ])
      : "",
    promoCode: state.promoCode ? state.promoCode : "",
  };

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const actionPayment = () => {
    if (!paymentData.xenditToken) {
      return Swal.fire({
        icon: "error",
        text: "Pilih metode pembayaran!",
        showConfirmButton: false,
        timer: 2000,
      });
    }

    dispatch(createPayment(paymentData))
      .then(() => {
        history.push({
          pathname: "/test-covid/success",
          state: state,
        });
      })
      .catch((err) => {
        if (err.response) {
          return Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          return Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  };

  return (
    <Layout title="Konfirmasi Pembayaran" link="/test-covid/form" state={state}>
      {loading ? (
        <Loading />
      ) : (
        <div className="detail-res">
          <div className="list-res">
            <span>
              Penyadia Layanan:{" "}
              <span style={{ fontWeight: "bold" }}>
                {detailData.partner.name ? detailData.partner.name : "-"}
              </span>
            </span>
          </div>

          <div className="card-col-res-grey">
            <span style={{ fontWeight: "bold" }}>
              Area Layanan: <span>{detailData.areaLayanan}</span>
            </span>
            <span>
              *) Mohon pastikan area layanan sesuai dengan tempat tinggal kamu.
              Bila tidak sesuai, maka akan dikenakan biaya tambahan atau
              pembatalan atas layanan yang sudah dibeli.
            </span>
          </div>

          <div className="card-res">
            <div className="img-res">
              <img
                src={detailData.imgUrl}
                style={{
                  height: "100%",
                  width: "100px",
                  borderRadius: "5px 0 0 5px",
                }}
              />
            </div>
            <div className="content-res" style={{ justifyContent: "center" }}>
              <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                {detailData.package.name ? detailData.package.name : "-"}
              </span>

              <span style={{ fontSize: "18px", color: "#0077ff" }}>
                {state.price ? formatter.format(state.price) : "-"}
              </span>
            </div>
          </div>

          <div className="card-res">
            <div
              className="row"
              style={{
                width: "100%",
                marginRight: "15px",
                marginLeft: "15px",
              }}
            >
              <div
                className="col-6"
                style={{
                  fontWeight: "bold",
                  padding: "15px",
                  borderRight: "1px solid #a6bbd6",
                }}
              >
                <Link
                  to={{
                    pathname: "/test-covid/payment-method",
                    state: state,
                  }}
                >
                  {state.paymentName ? (
                    <div>
                      <img
                        src={state.paymentImg}
                        style={{
                          height: "auto",
                          width: "30px",
                          marginRight: "20px",
                        }}
                      />
                      <span>{state.paymentName}</span>
                    </div>
                  ) : (
                    <span>Opsi Pembayaran</span>
                  )}
                </Link>
              </div>
              <div
                className="col-6"
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                  padding: "15px",
                  borderLeft: "1px solid #a6bbd6",
                }}
              >
                <Link
                  to={{
                    pathname: "/test-covid/promo",
                    state: state,
                  }}
                >
                  <span>Kode Promo / Voucher</span>
                </Link>
              </div>
            </div>
          </div>

          <div className="card-res" style={{ paddingBottom: "20px" }}>
            <div
              className="content-res"
              style={{ justifyContent: "center", width: "100%" }}
            >
              <span>Detail Pembayaran</span>

              <div className="order-res">
                <span>Subtotal</span>
                <span style={{ color: "#0077ff" }}>
                  {state.price ? formatter.format(state.price) : "-"}
                </span>
              </div>

              <div className="order-res">
                <span style={{ fontWeight: "bold" }}>Total Biaya</span>
                <span style={{ fontWeight: "bold" }}>
                  {state.price ? formatter.format(state.price) : "-"}
                </span>
              </div>
            </div>
          </div>

          <div className="list-res">
            <button
              type="button"
              className="btn btn-primary"
              onClick={actionPayment}
            >
              Konfirmasi Pembayaran
            </button>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Order;
