import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  findOneTimeAddress,
  getDeliveryPrice,
  getPharmacyName,
  submitOneTimeAddress,
  submitPharmacyByUnit,
  updatePharmacyAddress,
  findDetailKlinik,
  validatePromo,
} from "../../../actions";
import Swal from "sweetalert2";
import GrabMap from "../Components/GrabMap";
import GoogleMap from "../../Dashboard/Components/GoogleMap";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import queryString from "query-string";
import "./index.scss";
import AsyncSelect from 'react-select/async';

import {formatCurrency} from '../../../helpers/currency'

import { useTranslation } from "react-i18next";
import LanguageSelect from "../../../components/SelectLanguage";


const RegisterPharmacyByUnit = () => {
  const { t,i18n } = useTranslation();
  const currentLang = i18n.language;
  const getPathLocation = window.location.pathname.split("/");
  const getPharmacyId = getPathLocation[3] ? getPathLocation[3] : 0;
  const getChannelCode = getPathLocation[4] ? getPathLocation[4] : 0;
  const getPasienUuid = getPathLocation[5] ? getPathLocation[5] : 0;
  const [patientNameReadOnly, setPatientNameReadOnly] = useState(false);
  const [patientPhoneReadOnly, setPatientPhoneReadOnly] = useState(false);
  const [isScheduled,setIsScheduled] = useState(null);
  const [patientDobReadOnly, setPatientDobReadOnly] = useState(false);
  const [doctorNameReadOnly, setDoctorNameReadOnly] = useState(false);
  const [prescriptionDateReadOnly, setPrescriptionDateReadOnly] = useState(false);
  const [queueNumberReadOnly, setQueueNumberReadOnly] = useState(false);
  const [drugPriceReadOnly, setdrugPriceReadOnly] = useState(false);
  const [customFields, setCustomFields] = useState({});
  const [data, setData] = useState({
    patientName: "",
    patientPhone: "",
    patientDob: "",
    patientGender: "",
    fullAddress: "",
    detailAddress: "",
    latitude: "",
    longitude: "",
    postalCode: "",
    prescriptionDate: "",
    doctorName: "",
    partnerTrxId: "",
    partnerCallback: "",
    queueNumber: "",
    drugPrice:"",
    pharmacyAmount: 0,
    pharmacyItems: [],
    remarks:"",
    orderDate:"",
    drugType: ""
  });
  const [tempAddressData, setTempAddressData] = useState({
    fullAddress: "",
    detailAddress: "",
    latitude: -6.175400791146999,
    longitude: 106.82716145352265,
    postalCode: "",
    subDistId: "",
    distId: "",
    cityId: "",
    stateId: "",
  });
  const [submitData, setSubmitData] = useState({
    patientId: "",
    customerId: "",
    patientPhone: "",
    patientName: "",
    pharmacyId: getPharmacyId,
    channelCode: getChannelCode,
    addressId: "",
    deliveryMethodId: "",
    totalPrice: 100000,
    originalPrice: "",
    transportFee: "",
    totalPriceBeforePromo: "",
    discount: "",
    platformFee: "",
    distance: "",
    scheduleTime: "",
    promoCode: "",
    promoCodeDiscount: "",
    drugPrice:"",
    prescriptionDate:""
  });
  const [searchField, setSearchField] = useState({
    phone: "",
    dob: null,
  });
  const [toggle, setToggle] = useState({
    prevData: false,
    address: false,
    deliveryTime: false,
    success: false,
  });

  const [deliveryMap, setDeliveryMap] = useState({});
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [WA, setWA] = useState("");
  const [isTikiActive, setIsTikiActive] = useState(null);
  const [changeTikiToAnteraja, setChangeTikiToAnteraja] = useState(false)
  const [tikiTariffCode, setTikiTariffCode] = useState(null);
  const [isPromoActive, setIsPromoActive] = useState(null);
  const [deliveryMethodId, setDeliveryMethodId] = useState("");
  const [addressId, setAddressId] = useState(null);
  const [promoCode, setPromoCode] = useState(null);
  const [totalAfterPromo, setTotalAfterPromo] = useState(null);
  const [isExist, setIsExist] = useState(false);
  const [previousDataModal, setPreviousDataModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [map, setMap] = useState([]);
  const register = useSelector((state) => state.register);
  const address = useSelector((state) => state.address);
  const dispatch = useDispatch();
  const [submitDataLoading, setSubmitDataLoading] = useState(false);
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-block btn-submit',
    },
    buttonsStyling: false
  })
  const styleConfig = {
    control: styles => ({ ...styles, borderRadius: "20px", height: "58px"}),
    input: styles => ({...styles, height:"50px", paddingTop: "10px"})
  };
 

  useEffect(() => {
    //setConfirmationModal(true)
    if (!getPharmacyId) {
      return swalWithBootstrapButtons.fire({
        // icon: "error",
        iconHtml: '<img src="/img/icon/error.png">',
        text: "Hospital not listed",
      });
    }

    let values = {
      pharmacyId: getPharmacyId,
    };

    let query = queryString.stringify(values);

    dispatch(getPharmacyName(query)).catch((err) => {
      if (err.response) {
        swalWithBootstrapButtons.fire({
          // icon: "error",
          iconHtml: '<img src="/img/icon/error.png">',
          text: err.response.data.message,
        });
      } else {
        swalWithBootstrapButtons.fire({
          // icon: "error",
          iconHtml: '<img src="/img/icon/error.png">',
          text: err.message,
        });
      }
    });
    if(getChannelCode == 34){
      dispatch(findDetailKlinik(getPharmacyId, getPasienUuid))
      .then((response) => {
        if(response.data.request_data){
          setPatientNameReadOnly(true)
          setPatientDobReadOnly(true)
          setPatientPhoneReadOnly(true)
          setIsExist(true)
          setPrescriptionDateReadOnly(true)
          setDoctorNameReadOnly(true)
          setQueueNumberReadOnly(true)
          setdrugPriceReadOnly(true)

          let values = {
            phone: response.data.request_data.patientPhone,
            dob: moment(response.data.request_data.patientDob).format(
              "YYYY-MM-DD"
            )
          };
      
          let query = queryString.stringify(values);
      
          dispatch(findOneTimeAddress(query))
          .then((addressResult) => {
            if(addressResult.data){
              setData({
                patientName: response.data.request_data.patientName,
                patientPhone: response.data.request_data.patientPhone,
                patientDob: moment(response.data.request_data.patientDob).format(
                  "YYYY-MM-DD"
                ),
                patientGender: response.data.request_data.patientGender,
                patientMrNo: response.data.request_data.patientMrNo,
                fullAddress: addressResult.data.fullAddress,
                detailAddress:  addressResult.data.note,
                latitude:  addressResult.data.latitude,
                longitude:  addressResult.data.longitude,
                postalCode:  addressResult.data.postalCode,
                prescriptionDate: moment(response.data.request_data.prescriptionDate).format(
                  "YYYY-MM-DD"
                ),
                queueNumber: response.data?.request_data?.queueNumber,
                drugPrice: response.data?.request_data?.drugPrice,

                doctorName: response.data.request_data.doctorName,
                partnerTrxId: response.data.partner_trx_id,
                partnerCallback: response.data.callback_url,
                pharmacyAmount: response.data.request_data.pharmacyAmount,
                pharmacyItems: response.data.request_data.pharmacyItems
              });
            }else{
              setData({
                patientName: response.data.request_data.patientName,
                patientPhone: response.data.request_data.patientPhone,
                patientDob: moment(response.data.request_data.patientDob).format(
                  "YYYY-MM-DD"
                ),
                patientGender: response.data.request_data.patientGender,
                patientMrNo: response.data.request_data.patientMrNo,
                fullAddress: "",
                detailAddress:  "",
                latitude:  "",
                longitude:  "",
                postalCode:  "",
                prescriptionDate: moment(response.data.request_data.prescriptionDate).format(
                  "YYYY-MM-DD"
                ),
                queueNumber: response.data?.request_data?.queueNumber,
                drugPrice: response.data?.request_data?.drugPrice,
                doctorName: response.data.request_data.doctorName,
                partnerTrxId: response.data.partner_trx_id,
                partnerCallback: response.data.callback_url,
                pharmacyAmount: response.data.request_data.pharmacyAmount,
                pharmacyItems: response.data.request_data.pharmacyItems
              });
            }
          })
          .catch(err => {
            setData({
              patientName: response.data.request_data.patientName,
              patientPhone: response.data.request_data.patientPhone,
              patientDob: moment(response.data.request_data.patientDob).format(
                "YYYY-MM-DD"
              ),
              patientGender: response.data.request_data.patientGender,
              patientMrNo: response.data.request_data.patientMrNo,
              fullAddress: "",
              detailAddress:  "",
              latitude:  "",
              longitude:  "",
              postalCode:  "",
              prescriptionDate: moment(response.data.request_data.prescriptionDate).format(
                "YYYY-MM-DD"
              ),
              queueNumber: response.data?.request_data?.queueNumber,
              // drugPrice: response.data?.request_data?.drugPrice,
              doctorName: response.data.request_data.doctorName,
              partnerTrxId: response.data.partner_trx_id,
              partnerCallback: response.data.callback_url,
              pharmacyAmount: response.data.request_data.pharmacyAmount,
              pharmacyItems: response.data.request_data.pharmacyItems
            });
          })
        }
        
      }).catch((err) => {
        if (err.response) {
          swalWithBootstrapButtons.fire({
            // icon: "error",
            iconHtml: '<img src="/img/icon/error.png">',
            text: err.response.data.message,
          });
        } else {
          swalWithBootstrapButtons.fire({
            // icon: "error",
            iconHtml: '<img src="/img/icon/error.png">',
            text: err.message,
          });
        }
      });
    }

  }, []);

  useEffect(() => {
    if (register.pharmacyName) {
      let hospitalName = register.pharmacyName.name.replace(/ /g, "%20");
      let url = `https://wa.me/628118481436?text=Hai%20aido%20support.%0aSaya%20mengalami%20kendala%20pemesanan%20pharmacy%20delivery%20di%20${hospitalName}%20tolong%20dibantu%20ya`;

      setWA(url);
      setIsTikiActive(register.pharmacyName.isTikiActive);
      setMap(register.pharmacyName.map);

      if(register.pharmacyName.insuranceValue !== null){
        let tempSubmitData = submitData;
        tempSubmitData.totalPrice = register.pharmacyName.insuranceValue;
        setSubmitData(tempSubmitData);
      }
      setIsPromoActive(register.pharmacyName.isPromoActive)
      setCustomFields(JSON.parse(register.pharmacyName.customFields))

      if(register.pharmacyName.deliveryMethod && register.pharmacyName.deliveryMethod.length > 0){
        setDeliveryOptions(register.pharmacyName.deliveryMethod)
      }
    }

  }, [register.pharmacyName]);

  useEffect(() => {
    if(deliveryOptions && deliveryOptions.length > 0){
      const mappedDeliveryMethod = deliveryOptions.reduce((result, item) => {
        result[item.deliveryMethodId] = {
          display: item.display,
          type: item.type
        };
        return result;
      }, {});

      setDeliveryMap(mappedDeliveryMethod)
    }
  }, [deliveryOptions])

  useEffect(() => {
    if(addressId && deliveryMethodId != ""){
      handleGetPrice(deliveryMethodId)
    }
  }, [addressId, deliveryMethodId]);

  useEffect(() => {
    if(register?.pharmacyName?.deliveryMethod && register.pharmacyName.deliveryMethod.length > 0){
      let newDelivOptions = []
      for (const item of register.pharmacyName.deliveryMethod) {
        if(item.deliveryMethodId == 28 && changeTikiToAnteraja === true) {
          newDelivOptions.push({
            deliveryMethodId: 17,
            type: 'ND',
            display: item.display
          })
        } else if (item.deliveryMethodId == 29 && changeTikiToAnteraja === true) {
          newDelivOptions.push({
            deliveryMethodId: 16,
            type: 'REG',
            display: item.display
          })
        } else {
          newDelivOptions.push({
            deliveryMethodId: item.deliveryMethodId,
            type: item.type,
            display: item.display
          })
        }
      }
      setDeliveryOptions(newDelivOptions)
    }
  }, [changeTikiToAnteraja]);

  useEffect(() => { //Ini yang pas gunakan alamat sebelumnya
    setAddressId(null)

    if (register.addressData) {
      if(register?.addressData?.tikiTariffCode === null || register?.addressData?.tikiTariffCode === ""){
        if(deliveryMethodId == 28){
          setDeliveryMethodId(17)
          setChangeTikiToAnteraja(true)
        } else if(deliveryMethodId == 29){
          setDeliveryMethodId(16)
          setChangeTikiToAnteraja(true)
        }
      } else {
        if(isTikiActive){
          if(deliveryMethodId == 17){
            setDeliveryMethodId(28)
          } else if(deliveryMethodId == 16){
            setDeliveryMethodId(29)
          }
        }
        setChangeTikiToAnteraja(false)
      }

      setTikiTariffCode(register.addressData.tikiTariffCode);
      if(getChannelCode != 34){
        setData({
          ...data,
          patientName: data.patientName || register.addressData.patientName,
          patientPhone: register.addressData.patientPhone,
          patientDob: data.patientDob ?
            moment(data.patientDob).format("YYYY-MM-DD")
            : moment(register.addressData.patientDob).format("YYYY-MM-DD")
          ,
          patientGender: register.addressData.patientGender,
          fullAddress: register.addressData.fullAddress,
          detailAddress: register.addressData.note,
          latitude: register.addressData.latitude,
          longitude: register.addressData.longitude,
          postalCode: register.addressData.postalCode
        });
      }
      

      setTempAddressData({
        fullAddress: register.addressData.fullAddress,
        detailAddress: register.addressData.note,
        latitude: register.addressData.latitude,
        longitude: register.addressData.longitude,
        postalCode: register.addressData.postalCode,
      });

      setSubmitData({
        ...submitData,
        patientId: register.addressData.patientId,
        customerId: register.addressData.customerId,
        patientName: data.patientName || register.addressData.patientName,
        patientPhone: register.addressData.patientPhone,
        patientDob: data.patientName ?
          moment(data.patientDob).format("YYYY-MM-DD")
          : moment(register.addressData.patientDob).format("YYYY-MM-DD")
        ,
        addressId: register.addressData.addressId,
        deliveryMethodId: "",
        originalPrice: "",
        transportFee: "",
        totalPriceBeforePromo: "",
        discount: "",
        platformFee: "",
        distance: "",
        promoCode: "",
        promoCodeDiscount: ""
      });

      setAddressId(register.addressData.addressId)
    }
  }, [register.addressData]);

  useEffect(() => { //Saat create alamat
    if (register.submitAddressData) {
      setSubmitData({
        ...submitData,
        patientId: register.submitAddressData.patientId,
        customerId: register.submitAddressData.customerId,
        patientName: register.submitAddressData.patientName,
        patientPhone: register.submitAddressData.patientPhone,
        addressId: register.submitAddressData.addressId,
        deliveryMethodId: "",
        originalPrice: "",
        transportFee: "",
        totalPriceBeforePromo: "",
        discount: "",
        platformFee: "",
        distance: "",
        promoCode: "",
        promoCodeDiscount: ""
      });

      if(register?.submitAddressData?.tikiTariffCode === null){
        if(deliveryMethodId == 28){
          setDeliveryMethodId(17)
          setChangeTikiToAnteraja(true)
        } else if(deliveryMethodId == 29){
          setDeliveryMethodId(16)
          setChangeTikiToAnteraja(true)
        }
      } else {
        if(isTikiActive){
          if(deliveryMethodId == 17){
            setDeliveryMethodId(28)
          } else if(deliveryMethodId == 16){
            setDeliveryMethodId(29)
          }
        }
        setChangeTikiToAnteraja(false)
      }

      setTikiTariffCode(register.submitAddressData.tikiTariffCode)
      setAddressId(register.submitAddressData.addressId)
    }
  }, [register.submitAddressData]);

  useEffect(() => {
    if (register.deliveryPrice) {
      setSubmitData({
        ...submitData,
        transportFee: register.deliveryPrice.total_price,
        totalPriceBeforePromo: register.deliveryPrice.totalPriceBeforePromo,
        originalPrice: register.deliveryPrice.original_price,
        discount: register.deliveryPrice.discount,
        platformFee: register.deliveryPrice.platform_fee,
        distance: register.deliveryPrice.distance,
      });

      if (register.promo) {
        if(register.promo.status === '200'){
          const feeAfterPromo = register.deliveryPrice.total_price - register.promo.discountByPromo;
          setTotalAfterPromo(feeAfterPromo);
          setSubmitData({
            ...submitData,
            promoCodeDiscount: register.promo.discountByPromo,
            promoCode: register.promo.promoCode,
          });
        } else {
          setTotalAfterPromo(register.deliveryPrice.total_price);
          setSubmitData({
          ...submitData,
          promoCodeDiscount: '',
          promoCode: '',});
        }
      }

      if(register.pharmacyName.schedule.length > 0 && (getPharmacyId == 1154 || getPharmacyId == 1155)) {
        setToggle({
          ...toggle,
          deliveryTime: true,
        });
      }
    }
  }, [register.deliveryPrice]);
  
  useEffect(() => {
    if (register.promo) {
      const feeAfterPromo = register.deliveryPrice.total_price - register.promo.discountByPromo;
      setTotalAfterPromo(feeAfterPromo);
      setSubmitData({
        ...submitData,
        promoCodeDiscount: register.promo.discountByPromo,
        promoCode: register.promo.promoCode,
      });
    }
  }, [register.promo]);
  
  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const removeCommas = (num) => {
    return num.toString().replace(/,/g, '');
  };

 
  const handleInputChange = (e) => {
    const value = e.target.value;
    const numericValue = removeCommas(value);
    if (!isNaN(numericValue)) {
      setData({
        ...data,
        drugPrice: formatNumber(numericValue),
      });
    }
    const regex = /^[0-9]*$/;

    if (regex.test(numericValue)) {
      setData((prevData) => ({
        ...prevData,
        drugPrice: numericValue, 
      }));
  
      setSubmitData((prevSubmitData) => ({
        ...prevSubmitData,
        drugPrice: numericValue,
      }));
    }
    
  };

  const handleBlur = () => {
    const numericValue = removeCommas(data.drugPrice);
    if (!isNaN(numericValue)) {
      setData({
        ...data,
        drugPrice: formatNumber(numericValue),
      });
    }
    if (deliveryMethodId && deliveryMethodId != '') {
      handleGetPrice(deliveryMethodId)
    }
  };

  const handleValidatePromo = () => {
    if(promoCode && promoCode != ''){
      const values = {
        totalFee: register.deliveryPrice.total_price,
        pharmacyId: getPharmacyId,
        promoCode: promoCode,
        orderType: 19,
      }
      
      const query = queryString.stringify(values);
      dispatch(validatePromo(query)).catch((err) => {
          setPromoCode(null);
          setTotalAfterPromo(register.deliveryPrice.total_price);
          setSubmitData({
          ...submitData,
          promoCodeDiscount: '',
          promoCode: '',
        });
        swalWithBootstrapButtons.fire({
          iconHtml: '<img src="/img/icon/error.png">',
          text: err.message,
        });
      });
    }
  }

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const submitDeliveryMethod = () => {
    setSubmitDataLoading(true)
    const form = new FormData();

    let items = [
      {
        itemId: 0,
        name: "Items",
        qty: "1",
        uom: "",
        patientNet: submitData.drugPrice,
        payerNet: "0",
        frequency: "",
        instruction: "",
        isSendAvailable: true,
      },
    ];

    form.append("patientName", data.patientName);
    form.append("patientPhone", data.patientPhone);
    form.append("customerId", submitData.customerId);
    form.append("patientId", submitData.patientId);
    form.append("patientAddressId", submitData.addressId);
    form.append("transportFee", submitData.transportFee);
    form.append("discount", submitData.discount);
    form.append("drugType", data.drugType)

    if((deliveryMethodId == 28 || deliveryMethodId == 29) && tikiTariffCode === null){
      if(tikiTariffCode === null){
        if(deliveryMethodId == 28){
          form.append("deliveryMethodId", 17)
        } else if(deliveryMethodId == 29){
          form.append("deliverMethodId", 16)
        }
      }
    } else {
      form.append("deliveryMethodId", deliveryMethodId);
    }

    if(getChannelCode == 34){
      form.append("totalPrice", data.drugPrice);
      form.append("originalPrice", data.pharmacyAmount);
      form.append("items", JSON.stringify(data.pharmacyItems));
      form.append("patientMrNo", data.patientMrNo);
      form.append("partnerTrxId", data.partnerTrxId);
      form.append("partnerCallback", data.partnerCallback);
    }else{
      form.append("totalPrice", submitData.drugPrice);
      form.append("originalPrice", submitData.originalPrice);
      form.append("items", JSON.stringify(items));
      form.append("patientMrNo", data.patientMrNo);
    }
    
    form.append("platformFee", submitData.platformFee);
    form.append("distance", submitData.distance);
    form.append("doctorName", data.doctorName);
    form.append("prescriptionDate", data.prescriptionDate);
    form.append("queueNumber", data.queueNumber);
    form.append("patientDob", moment(data.patientDob).format("YYYY-MM-DD"));
    form.append("patientGender", data.patientGender);

    if (submitData.scheduleTime) {
      form.append("scheduleTime", submitData.scheduleTime);
    }

    if(isPromoActive === 1){
      form.append("promoCode", submitData.promoCode);
      form.append("promoCodeDiscount", submitData.promoCodeDiscount);
    }

    dispatch(submitPharmacyByUnit(getPharmacyId, getChannelCode, form))
      .then((response) => {
        if(response.data.paymentUrl){
          window.location = response.data.paymentUrl
        }else{
          setConfirmationModal(false);
          setSubmitDataLoading(false);
          setToggle({ ...toggle, success: true });
        }
        
      })
      .catch((err) => {
        if (err.response) {
          setSubmitDataLoading(false);
          swalWithBootstrapButtons.fire({
            // icon: "error",
            iconHtml: '<img src="/img/icon/error.png">',
            text: err.response.data.message,
          });
        } else {
          setSubmitDataLoading(false);
          swalWithBootstrapButtons.fire({
            // icon: "error",
            iconHtml: '<img src="/img/icon/error.png">',
            text: err.message,
          });
        }
      });
  };

  const renderConfirmation = () => {
    return (
      <>
      {submitDataLoading ? (
        <>
        <div className="mt-2 row">
        <div className="loading-content">
          <div className="spinner-border loading-color" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="loading-color loading-text">
            {t("Loading Confirmation")}
          </div>
        </div>
        </div>
        </>
      ) : (
        <>
        </>
      )}
      <Modal
        size="lg"
        show={confirmationModal}
        onHide={() => setConfirmationModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="form-label-header" id="contained-modal-title-vcenter">
            {t("Are you sure?")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          {/* pharma his */}
          {(() => {
            if (getChannelCode == 34){
                return (
                  <>
                  <Row>
                    <Col md>
                      <label className="header-title">
                        {t("Patient Information")}
                      </label>
                      <table className="table-patient">
                      <tbody>
                        <tr>
                          <td style={{width: '50%'}}>{t("Nomor MR")}</td>
                          <td>  : {data.patientMrNo}</td>
                        </tr>
                        <tr>
                          <td>{t("Patient Name")}</td>
                          <td>  : {data.patientName}</td>
                        </tr>
                        <tr>
                          <td>{t("Date of Birth")}</td>
                          <td>  : {data.patientDob ? moment(data.patientDob).format("DD-MM-YYYY") : "-"}</td>
                        </tr>
                        <tr>
                          <td>{t("Gender")}</td>
                          <td>  :  {(() => {
                              if (data.patientGender == 1){
                                return ("Laki-laki")
                              }else{
                                return ("Perempuan")
                              }
                              return null;
                              })()}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("Phone Number")}</td>
                          <td>  : 0{data.patientPhone}</td>
                        </tr>
                        {(customFields.remarks && customFields.remarks?.isShown == '1') && (
                              <tr>
                                <td>{t("Doctor Name")}</td>
                                <td>  : {data.doctorName}</td>
                              </tr>
                            )}
                        {(customFields.orderDate && customFields.orderDate?.isShown === '1') && (
                          <tr>
                            <td>{t("Prescription Date")}</td>
                            <td>  : {data.prescriptionDate ? moment(data.prescriptionDate).format("DD-MM-YYYY") : "-"}</td>
                          </tr>
                        )}
                        {(customFields.queueNumber && customFields.queueNumber?.isShown === '1') && (
                          <tr>
                            <td>{t("Queue Number")}</td>
                            <td>  : {data.queueNumber || "-"}</td>
                          </tr>
                        )}
                        
                      </tbody>
                    </table>
                    </Col>
                    <Col md>
                      &nbsp;
                    </Col>
                  </Row>
                  <Row>
                    <Col md>
                      <label className="header-title" style={{paddingTop: 20}}>
                        {t("Delivery Information")}
                      </label>
                      <table className="table-delivery">
                      <tbody>
                        <tr>
                          <td>{(deliveryMethodId == '14' || deliveryMethodId == '32') ? t("Delivery Point on Map") : t("Subdistrict | District | City")}</td>
                          <td>  :  {data.fullAddress ? data.fullAddress : "-"}</td>
                        </tr>
                        <tr>
                          <td>{t("Delivery Address")}</td>
                          <td>  : {data.detailAddress ? data.detailAddress : "-"}</td>
                        </tr>
                        <tr>
                          <td>{t("Delivery Method")}</td>
                          <td>  : {deliveryMethodId ? deliveryMap[deliveryMethodId].display : "-"}</td>
                        </tr>
                      </tbody>
                    </table>
                    </Col>
                  </Row>

                  <Row>
                    <Col md>
                      <label className="header-title" style={{paddingTop: 20}}>
                        {t("Bill Information")}
                      </label>
                      <table className="table-payment">
                      <tbody>
                        <tr>
                          <td  style={{width: '50%'}}>{t("Bill Drug Price")}</td>
                          <td>  :  {data.pharmacyAmount ? formatter.format(data.pharmacyAmount) : "-"}</td>
                        </tr>
                        <tr>
                          <td>{t("Bill Delivery Price")}</td>
                          <td>  : {submitData.transportFee ? formatter.format(submitData.transportFee) : "-"}</td>
                        </tr>
                        <tr>
                          <td><b>{t("Bill Total Price")}</b></td>
                          <td>  : <b>{submitData.transportFee ? formatter.format(parseInt(submitData.transportFee)+parseInt(data.pharmacyAmount)) : "-"}</b></td>
                        </tr>
                      </tbody>
                    </table>
                    </Col>
                    <Col md>
                      &nbsp;
                    </Col>
                  </Row>
                  </>
                )
            }else{
              return (
                <>
                <Row className="mt-2">
                <Col xs={3}>
                  <div className="form-label-confirm">
                    <span>{t("Patient Name")}</span>
                  </div>
                </Col>

                <Col xs={9}>
                :&nbsp;
                  <span className="form-label-confirm-bold">
                    {data.patientName ? data.patientName : "-"}
                  </span>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col xs={3}>
                  <div className="form-label-confirm">
                    <span>{t("Phone Number")}</span>
                  </div>
                </Col>

                <Col xs={9}>
                :&nbsp;
                  <span className="form-label-confirm-bold">
                    0{data.patientPhone ? data.patientPhone : "-"}
                  </span>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col xs={3}>
                  <div className="form-label-confirm">
                    <span>{t("Date of Birth")}</span>
                  </div>
                </Col>

                <Col xs={9}>
                :&nbsp;
                  <span className="form-label-confirm-bold">
                    {data.patientDob
                      ? moment(data.patientDob).format("DD-MM-YYYY")
                      : "-"}
                  </span>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col xs={3}>
                  <div className="form-label-confirm">
                    <span>{t("Gender")}</span>
                  </div>
                </Col>

                <Col xs={9}>
                :&nbsp;
                  <span className="form-label-confirm-bold">
                    {data.patientGender
                      ? data.patientGender == 1
                        ? t("Male")
                        : t("Female")
                      : "-"}
                  </span>
                </Col>
              </Row>

              {(customFields?.orderDate && customFields.orderDate?.isShown === '1') && (
              <Row className="mt-2">
                <Col xs={3}>
                  <div className="form-label-confirm">
                    <span>{t("Prescription Date")}</span>
                  </div>
                </Col>

                <Col xs={9}>
                :&nbsp;
                  <span className="form-label-confirm-bold">
                    {data.prescriptionDate
                      ? moment(data.prescriptionDate).format("DD-MM-YYYY")
                      : "-"}
                  </span>
                </Col>
              </Row>)}

              {(customFields.queueNumber && customFields.queueNumber?.isShown === '1') && (
              <Row className="mt-2">
                <Col xs={3}>
                  <div className="form-label-confirm">
                    <span>{t("Queue Number")}</span>
                  </div>
                </Col>

                <Col xs={9}>
                :&nbsp;
                  <span className="form-label-confirm-bold">
                    {data.queueNumber || "-"}
                  </span>
                </Col>
              </Row>)}

              {(customFields.remarks&&customFields.remarks?.isShown=='1')&&(
              <Row className="mt-2">
              <Col xs={3}>
                <div className="form-label-confirm">
                  <span>{t("Doctor Name")}</span>
                </div>
              </Col>

              <Col xs={9}>
              :&nbsp;
                <span className="form-label-confirm-bold">
                  {data.doctorName ? data.doctorName : "-"}
                </span>
              </Col>
            </Row>
              )}

              <Row className="mt-2">
                <Col xs={3}>
                  <div className="form-label-confirm">
                    <span>{t("Drug Prices")}</span>
                  </div>
                </Col>

                <Col xs={9}>
                :&nbsp;
                  <span className="form-label-confirm-bold">
                  {(!submitData?.drugPrice || submitData.drugPrice === "0")? "-" : formatter.format(submitData.drugPrice)}
                  </span>
                </Col>
              </Row>

              {(customFields.drugType&&customFields.drugType?.isShown=='1')&&(
                <Row className="mt-2">
                    <Col xs={3}>
                      <div className="form-label-confirm">
                       <span>{t("Drug Type")}</span>
                      </div>
                    </Col>
                    
                    <Col xs={3}>
                      :&nbsp;
                      <span className="form-label-confirm-bold">  {data.drugType}</span>
                    </Col>   
                  </Row> 
                  )}
                <Row className="mt-2">
                <Col xs={3}>
                  <div className="form-label-confirm">
                    <span>{(deliveryMethodId == '14' || deliveryMethodId == '32') ? t("Delivery Point on Map") : t("Subdistrict | District | City")}</span>
                  </div>
                </Col>

                <Col xs={9}>
                :&nbsp;
                  <span className="form-label-confirm-bold">
                    {data.fullAddress ? data.fullAddress : "-"}
                  </span>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col xs={3}>
                  <div className="form-label-confirm">
                    <span>{t("Delivery Address")}</span>
                  </div>
                </Col>

                <Col xs={9}>
                :&nbsp;
                  <span className="form-label-confirm-bold">
                    {data.detailAddress ? data.detailAddress : "-"}
                  </span>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col xs={3}>
                  <div className="form-label-confirm">
                    <span>{t("Delivery Method")}</span>
                  </div>
                </Col>

                <Col xs={9}>
                :&nbsp;
                  <span className="form-label-confirm-bold">
                    {deliveryMethodId
                     ? deliveryMap[deliveryMethodId].display
                     : "-"}
                  </span>
                </Col>
              </Row>

              {getPharmacyId != 1010 && (<Row className="mt-2">
                  <Col xs={3}>
                    <div className="form-label-confirm">
                      <span>{t("Transport Fee")}</span>
                    </div>
                  </Col>

                  <Col xs={9}>
                  :&nbsp;
                    { submitData.transportFee ? (
                      submitData.promoCode !== '' ? (
                        <>
                          <span className="form-label-confirm-bold">
                            <s>{formatter.format(submitData.transportFee)}</s> 
                          </span> 
                          <span className="form-label-confirm-bold ml-1">
                            {` ${formatter.format(totalAfterPromo)} (Promo Code: ${submitData.promoCode})`}
                          </span> 
                        </>
                        ):
                        <span className="form-label-confirm-bold">
                          {formatter.format(submitData.transportFee)}
                        </span> 
                      ) :
                        <span>-</span>
                    }
                    <>
                    </>
                  </Col>
                </Row>
              )}
                {((deliveryMethodId == "14" || deliveryMethodId == "32") && submitData?.scheduleTime != "") && (
                  <Row className="mt-2">
                    <Col xs={3}>
                      <div className="form-label-confirm">
                        <span>{t("Delivery Time")}</span>
                      </div>
                    </Col>

                    <Col xs={9}>
                    :&nbsp; 
                      <span className="form-label-confirm-bold">
                        {submitData.scheduleTime
                          ? `${moment(new Date()).format("DD MMM YYYY")} ${
                              submitData.scheduleTime
                            }`
                          : " "}
                      </span>
                    </Col>
                  </Row>
                )}
                </>
              )
            }
            return null;
          })()}

          {/* {deliveryMethodId && deliveryMap[deliveryMethodId]?.type  && (<></>)} */}

          <Row className="mt-2 form-label-description">
            <Col md>
              {register.pharmacyName?.paymentGate ? (<>
                <br/>
                <span>
                  {t(
                    "Please complete your payment through the link sent to your WhatsApp. The drug will be processed by pharmacy after the payment is received & a notification will be sent to WhatsApp after the drug is picked up by the courier."
                  )}
                </span>
                </>
              ) : (<>
              <br/>
                <span>
                  {t(
                    "Please complete your payment at the pharmacy's cashier. The drug will be processed by pharmacy after the payment is received & a notification will be sent to WhatsApp after the drug is picked up by the courier."
                  )}
                </span>
                </>
              )}     
            </Col>
          </Row>

          {<Row className="mt-4 form-label-description">
            <Col md>
              <span className="form-label-caution">
                {t("By clicking the")}<br/><br/>
                {t("terms 1")}<br/>
                {t("terms 2")}<br/>
                {(deliveryMethodId != 14 && deliveryMethodId !=32) && (t("terms 3"))}<br/>
              </span>
            </Col>
          </Row>}

          <Row className="mt-2">
            <Col className="form-group">
              <button
                type="button"
                className="btn btn-submit btn-block"
                onClick={submitDeliveryMethod}
              >
                {t("Submit")}
              </button>
              <button
                type="button"
                className="btn btn-cancel btn-block"
                onClick={() => setConfirmationModal(false)}
              >
                {t("Cancel")}
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      </>
    );
  };

  const handleValidation = () => {

    if (customFields) {
      const customFieldsKey = Object.keys(customFields);
      for (const key of customFieldsKey) {
        const field = customFields[key];
        if (
            field.isMandatory === '1' &&
            (data[key] === null || data[key] === '')
        ) {
          setConfirmationModal(false);
          return swalWithBootstrapButtons.fire({
              iconHtml: '<img src="/img/icon/warning.png">',
              text: `Please input ${field.name}.`,
          });
        }
      }
    }

    if (!deliveryMethodId || deliveryMethodId == "") {
      return swalWithBootstrapButtons.fire({
        // icon: "warning",
        iconHtml: '<img src="/img/icon/warning.png">',
        text: "Delivery Method is required!",
      });
    }

    if (submitData.transportFee === "") {
      return swalWithBootstrapButtons.fire({
        // icon: "warning",
        iconHtml: '<img src="/img/icon/warning.png">',
        text: "Transport Fee is required!",
      });
    }

    if (!addressId) {
      return swalWithBootstrapButtons.fire({
        // icon: "warning",
        iconHtml: '<img src="/img/icon/warning.png">',
        text: "Address is required!",
      });
    }

    if (
      data.prescriptionDate !== "" &&
      data.prescriptionDate !== null &&
      Number(moment(data.prescriptionDate).format("YYYYMMDD")).toString()
        .length !== 8
    ) {
      return swalWithBootstrapButtons.fire({
        // icon: "warning",
        iconHtml: '<img src="/img/icon/warning.png">',
        text: "Prescription Date is not valid!",
      });
    }

    if (toggle.deliveryTime && !submitData.scheduleTime) {
      return swalWithBootstrapButtons.fire({
        // icon: "warning",
        iconHtml: '<img src="/img/icon/warning.png">',
        text: "Please select delivery time!",
      });
    }

    if(promoCode && promoCode != ''){
      const values = {
        totalFee: register.deliveryPrice.total_price,
        pharmacyId: getPharmacyId,
        promoCode: promoCode,
        orderType: 19,
      }

      const query = queryString.stringify(values);
      dispatch(validatePromo(query)).then((res) => {
          setConfirmationModal(true);
        }).catch((err) => {
            setPromoCode(null);
            setTotalAfterPromo(register.deliveryPrice.total_price);
            setSubmitData({
            ...submitData,
            promoCodeDiscount: '',
            promoCode: '',
          });
          swalWithBootstrapButtons.fire({
            iconHtml: '<img src="/img/icon/error.png">',
            text: err.message,
          });
          setConfirmationModal(false);
      });
    } else {
      setConfirmationModal(true);
    }

  };

  const handleDeliveryTime = (event) => {
    setSubmitData({
      ...submitData,
      scheduleTime: event,
    });

    if (event !== "") {
      return swalWithBootstrapButtons.fire({
        // icon: "info",
        iconHtml: '<img src="/img/icon/warning.png">',
        confirmButtonText: `${t("Confirmation Schedule")}`,
        text: `${t("Caution Schedule Please")} ${event}. ${t("Caution Schedule Thank You")}`,
      });
    }
  };

  const handleToggleSchedule = (event) => {
    if (event === "2") {
      setToggle({
        ...toggle,
        deliveryTime: true,
      });
    } else {
      setToggle({
        ...toggle,
        deliveryTime: false,
      });
      setSubmitData({
        ...submitData,
        scheduleTime: "",
      });
    }
  };

  const handleGetPrice = (id) => {
    if (id === "") {
      setSubmitData({
        ...submitData,
        originalPrice: "",
        transportFee: "",
        discount: "",
        platformFee: "",
        distance: "",
        scheduleTime: "",
      });
      setDeliveryMethodId("")
      setToggle({
        ...toggle,
        deliveryTime: false,
      });
      return swalWithBootstrapButtons.fire({
        // icon: "warning",
        iconHtml: '<img src="/img/icon/warning.png">',
        text: "Delivery Method is required!",
      });
    } else {
      setSubmitData({ ...submitData, deliveryMethodId: id });

      const form = new FormData();

      form.append("deliveryMethodId", id);
      form.append("addressId", addressId);
      form.append("totalPrice", submitData.drugPrice);
      form.append("pharmacyId", getPharmacyId);

      dispatch(getDeliveryPrice(form, getChannelCode)).catch((err) => {
        setSubmitData({
          ...submitData,
          deliveryMethodId: "",
          originalPrice: "",
          transportFee: "",
          discount: "",
          platformFee: "",
          distance: "",
        });
        swalWithBootstrapButtons.fire({
          // icon: "error",
          iconHtml: '<img src="/img/icon/error.png">',
          text: "Delivery not available!",
        });
      });
    }
  };

  const submitAddress = () => {

    if (submitData.addressId) {
      if (!tempAddressData.detailAddress) {
        return swalWithBootstrapButtons.fire({
          // icon: "warning",
          iconHtml: '<img src="/img/icon/warning.png">',
          text: "Please input your detail address!",
        });
      }

      const form = new FormData();

      form.append("addressId", submitData.addressId);
      form.append("detailAddress", tempAddressData.detailAddress);
      form.append("subDistId", tempAddressData.subDistId)
      form.append("distId",tempAddressData.distId)
      form.append("cityId",tempAddressData.cityId)
      form.append("stateId", tempAddressData.stateId)

      dispatch(updatePharmacyAddress(form))
        .then(() => {
          setToggle({
            ...toggle,
            address: false,
            addressNonInstant: false
          });

          setData({
            ...data,
            detailAddress: tempAddressData.detailAddress,
          });
        })
        .catch((err) => {
          if (err.response) {
            swalWithBootstrapButtons.fire({
              // icon: "error",
              iconHtml: '<img src="/img/icon/error.png">',
              text: err.response.data.message,
            });
          } else {
            swalWithBootstrapButtons.fire({
              // icon: "error",
              iconHtml: '<img src="/img/icon/error.png">',
              text: err.message,
            });
          }
        });
    } else {
      if (
        ((deliveryMethodId == '14' || deliveryMethodId == '32') && !tempAddressData.latitude) ||
        ((deliveryMethodId == '14' || deliveryMethodId == '32') && !tempAddressData.longitude) ||
        !tempAddressData.fullAddress ||
        ((deliveryMethodId != '14' && deliveryMethodId != '32') && !tempAddressData.postalCode)
      ) {
        return swalWithBootstrapButtons.fire({
          // icon: "warning",
          iconHtml: '<img src="/img/icon/warning.png">',
          text: "Please select your location!",
        });
      }

      if (!tempAddressData.detailAddress) {
        return swalWithBootstrapButtons.fire({
          // icon: "warning",
          iconHtml: '<img src="/img/icon/warning.png">',
          text: "Please input your detail address!",
        });
      }

      const form = new FormData();
      form.append("patientName", data.patientName);
      form.append("patientDob", moment(data.patientDob).format("YYYY-MM-DD"));
      if(isExist) {
        form.append("patientOldDob", moment(submitData.patientDob).format("YYYY-MM-DD"));
      }
      form.append("patientGender", data.patientGender);
      form.append("phone", data.patientPhone);
      form.append("latitude", tempAddressData.latitude);
      form.append("longitude", tempAddressData.longitude);
      form.append("postalCode", tempAddressData.postalCode);
      form.append("fullAddress", tempAddressData.fullAddress);
      form.append("detailAddress", tempAddressData.detailAddress);
      form.append("pharmacyId", getPharmacyId);
      form.append("channelCode", getChannelCode);
      form.append("subDistId", tempAddressData.subDistId)
      form.append("distId",tempAddressData.distId)
      form.append("cityId",tempAddressData.cityId)
      form.append("stateId", tempAddressData.stateId)

      if(deliveryMethodId == '14' || deliveryMethodId == '32'){
        form.append("isInstant", 1)
      } else {
        form.append("isInstant", 0)
      }
      form.append("formVersion", 2);

      dispatch(submitOneTimeAddress(form))
        .then(() => {
          setToggle({
            ...toggle,
            address: false,
            addressNonInstant: false
          });

          setData({
            ...data,
            fullAddress: tempAddressData.fullAddress,
            detailAddress: tempAddressData.detailAddress,
            latitude: tempAddressData.latitude,
            longitude: tempAddressData.longitude,
            postalCode: tempAddressData.postalCode,
          });
        })
        .catch((err) => {
          if (err.response) {
            swalWithBootstrapButtons.fire({
              // icon: "error",
              iconHtml: '<img src="/img/icon/error.png">',
              text: err.response.data.message,
            });
          } else {
            swalWithBootstrapButtons.fire({
              // icon: "error",
              iconHtml: '<img src="/img/icon/error.png">',
              text: err.message,
            });
          }
        });
    }
  };

  const handleAddress = () => {
    if (!data.patientName) {
      return swalWithBootstrapButtons.fire({
        // icon: "warning",
        iconHtml: '<img src="/img/icon/warning.png">',
        text: "Name is required!",
      });
    }

    if (!data.patientPhone) {
      return swalWithBootstrapButtons.fire({
        // icon: "warning",
        iconHtml: '<img src="/img/icon/warning.png">',
        text: "Phone Number is required!",
      });
    }

    if (data.patientPhone.length < 9 || data.patientPhone[0] != "8") {
      return swalWithBootstrapButtons.fire({
        // icon: "warning",
        iconHtml: '<img src="/img/icon/warning.png">',
        text: "Please input Mobile Number (ex. 8123456789)",
      });
    }

    if (!data.patientDob) {
      return swalWithBootstrapButtons.fire({
        // icon: "warning",
        iconHtml: '<img src="/img/icon/warning.png">',
        text: "Date of Birth is required!",
      });
    }
    if (
      Number(moment(data.patientDob).format("YYYYMMDD")).toString().length !== 8
    ) {
      return swalWithBootstrapButtons.fire({
        // icon: "warning",
        iconHtml: '<img src="/img/icon/warning.png">',
        text: "Date of Birth is not valid!",
      });
    }

    if (!data.patientGender) {
      return swalWithBootstrapButtons.fire({
        // icon: "warning",
        iconHtml: '<img src="/img/icon/warning.png">',
        text: "Gender is required!",
      });
    }

    if (!deliveryMethodId || deliveryMethodId == "") {
      return swalWithBootstrapButtons.fire({
        // icon: "warning",
        iconHtml: '<img src="/img/icon/warning.png">',
        text: "Delivery method is required!",
      });
    }

    if(deliveryMethodId == '14' || deliveryMethodId == '32'){
      setToggle({ ...toggle, address: true, addressNonInstant: false });
    } else {
      setToggle({ ...toggle, address: false, addressNonInstant: true });
    }
  };

  const getAddress = (addressData) => {
    if (
      ((deliveryMethodId == '14' || deliveryMethodId == '32') && !addressData.latitude) ||
      ((deliveryMethodId == '14' || deliveryMethodId == '32') && !addressData.longitude) ||
      !addressData.fullAddress ||
      ((deliveryMethodId != '14' && deliveryMethodId == '32') && !addressData.postalCode)
    ) {
      return swalWithBootstrapButtons.fire({
        // icon: "info",
        iconHtml: '<img src="/img/icon/warning.png">',
        confirmButtonText: `Ok`,
        text: "Please select another location!",
      });
    }

    setTempAddressData({
      ...tempAddressData,
      latitude: addressData.latitude,
      longitude: addressData.longitude,
      fullAddress: addressData.fullAddress,
      postalCode: addressData.postalCode,
    });

    setSubmitData({
      ...submitData,
      addressId: "",
    });

    setAddressId(null)
  };

  const selectLocationNonInstant = (e) => {
    setTempAddressData({
      ...tempAddressData,
      fullAddress: e?.name || '',
      postalCode: e?.id || '',
      subDistId: e?.subDistId||'',
      distId: e?.distId || '',
      cityId: e?.cityId || '',
      stateId: e?.stateId || '',
      latitude: 0,
      longitude: 0
    })

    setSubmitData({
      ...submitData,
      addressId: "",
    });

    setAddressId(null)
  }

  const submitPreviousData = () => {
    if (!searchField.phone) {
      return swalWithBootstrapButtons.fire({
        // icon: "info",
        iconHtml: '<img src="/img/icon/warning.png">',
        confirmButtonText: `Ok`,
        text: "Phone Number is required!",
      });
    }

    if (!searchField.dob) {
      return swalWithBootstrapButtons.fire({
        // icon: "info",
        iconHtml: '<img src="/img/icon/warning.png">',
        confirmButtonText: `Ok`,
        text: "Date of Birth is required!",
      });
    }

    setData({
      patientName: "",
      patientPhone: "",
      patientDob: "",
      patientGender: "",
      fullAddress: "",
      detailAddress: "",
      latitude: "",
      longitude: "",
      postalCode: "",
      prescriptionDate: "",
      doctorName: "",
      queueNumber: "",
      drugPrice:"",
      partnerTrxId: "",
      partnerCallback: "",
      pharmacyAmount: 0,
      pharmacyItems: [],
      remarks:'',
      drugType:'',
      orderDate:'',
    });
    setAddressId(null)
    setDeliveryMethodId("")

    let values = {
      phone: searchField.phone,
      dob: moment(searchField.dob).format("YYYY-MM-DD")
    };

    let query = queryString.stringify(values);

    dispatch(findOneTimeAddress(query))
      .then(() => {
        setIsExist(true);

        setPreviousDataModal(false);
        setConfirmationModal(false);

        setSearchField({
          phone: "",
          dob: null,
        });
      })
      .catch((err) => {
        if (err.response) {
          return swalWithBootstrapButtons.fire({
            // icon: "info",
            iconHtml: '<img src="/img/icon/error.png">',
            confirmButtonText: `Ok`,
            text: err.response.data.message,
          });
        } else {
          return swalWithBootstrapButtons.fire({
            // icon: "info",
            iconHtml: '<img src="/img/icon/error.png">',
            confirmButtonText: `Ok`,
            text: err.message,
          });
        }
      });
  };

  const truncateWords = (sentence, amount, tail)  => {
    const words = sentence.split(' ');
  
    if (amount >= words.length) {
      return sentence;
    }
  
    const truncated = words.slice(0, amount);
    return `${truncated.join(' ')}${tail}`;
  }

  const changeDeliveryMethod = (e) => {
    if((e == '14' && deliveryMethodId != '14') || (e != '14' && deliveryMethodId == '14')
    || (e == '32' && deliveryMethodId != '32') || (e != '32' && deliveryMethodId == '32')
    || deliveryMethodId == ''){
      setAddressId(null)
      setData({
              ...data,
              fullAddress: '',
              latitude: '',
              longitude: '',
              postalCode: ''
      })
      setSubmitData({
        ...submitData,
        addressId: ''
      })

      if(submitData.patientId !== ''){
        let values = {
          phone: data.patientPhone,
          dob: moment(data.patientDob).format("YYYY-MM-DD"),
          isInstant: (e == '14' || e == '32') ? 1 : 0
        };
    
        let query = queryString.stringify(values);
    
        dispatch(findOneTimeAddress(query))
          .then(() => {
            setIsExist(true);
    
            setPreviousDataModal(false);
            setConfirmationModal(false);
    
            setSearchField({
              phone: "",
              dob: null,
            });
          })
          .catch((err) => {
            if (err.response) {
              return swalWithBootstrapButtons.fire({
                // icon: "info",
                iconHtml: '<img src="/img/icon/error.png">',
                confirmButtonText: `Ok`,
                text: err.response.data.message,
              });
            } else {
              return swalWithBootstrapButtons.fire({
                // icon: "info",
                iconHtml: '<img src="/img/icon/error.png">',
                confirmButtonText: `Ok`,
                text: err.message,
              });
            }
          });
      }
    }

    setToggle({
      ...toggle,
      deliveryTime: false
    });

    setSubmitData({
      ...submitData,
      scheduleTime: ''
    })
    
    setDeliveryMethodId(e)
  }

  const renderPreviousData = () => {
    return (
      <Modal
        size="lg"
        show={previousDataModal}
        onHide={() => setPreviousDataModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("My Data")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md>
              <div className="form-group">
                <label className="form-label-bold">
                  {t("Phone Number")} <span style={{ color: "red" }}>*</span>
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text form-input-pharmacy">+62</div>
                  </div>
                  <input
                    type="number"
                    className="form-control form-input-pharmacy"
                    placeholder="81234567890"
                    style={{ height: "38px" }}
                    value={searchField.phone}
                    onChange={(e) =>
                      setSearchField({
                        ...searchField,
                        phone: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </Col>

            <Col md>
              <label className="form-label-bold">
                {t("Date of Birth")} <span style={{ color: "red" }}>*</span>
              </label>
              <LocalizationProvider
                className="form-control form-input-pharmacy"
                dateAdapter={AdapterDateFns}
              >
                <DesktopDatePicker
                  label="Input Date of Birth"
                  value={searchField.dob}
                  inputFormat="dd/MM/yyyy"
                  onChange={(event) => {
                    setSearchField({
                      ...searchField,
                      dob: event,
                    });
                  }}
                  renderInput={({  inputRef, inputProps, InputProps }) => (
                    <Box>
                      <div className="form-group">
                        <input
                          className="form-control form-input-pharmacy"
                          ref={inputRef}
                          {...inputProps}
                        />
                        <span className="filter-date" ref={inputRef} onClick={() => { InputProps.endAdornment.props.children.props.onClick() }}><i className="icon-calendar-date" style={{ color: "#ccc" }} aria-hidden="true"></i></span>
                      </div>
                    </Box>
                  )}
                />
              </LocalizationProvider>
            </Col>
          </Row>

          <Row>
            <Col
              md
              className="form-group"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                type="button"
                className="btn btn-submit btn-block"
                onClick={submitPreviousData}
              >
                {t("Submit")}
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  const fetchSubdistrict = (inputValue, callback) => {
    setTimeout(() => {
        if(inputValue.length >= 3) {
          fetch(
            `${process.env.REACT_APP_API}/App/Customer/Me/SubdistrictByKey?key=${inputValue}&limit=20`,
            {
              method: "GET",
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
              },
            }
          )
            .then((resp) => {
              return resp.json();
            })
            .then((data) => {
             
              const tempArray = [];
              
              if(data){
                data.forEach((element) => {
                  tempArray.push({
                    id: element.postalCode,
                    name: element.location,
                    subDistId: element.subDistrictId,
                    distId: element.distId,
                    cityId: element.cityId,
                    stateId: element.stateId
                  });
                });
              }
              tempArray.push({
                id: tempAddressData.postalCode,
                name: tempAddressData.fullAddress,
                subDistId: tempAddressData.subDistId,
                distId: tempAddressData.distId,
                cityId: tempAddressData.cityId,
                stateId: tempAddressData.stateId,
              })
              callback(tempArray);
            })
            .catch((error) => {
              
            });
        } else {
          callback([{
            id: tempAddressData.postalCode,
            name: tempAddressData.fullAddress
          }])
        }
      
    }, 1000);
  };

const timeout = delay => new Promise((_, reject) => {
  setTimeout(() => {
    reject({ timeout: true })
  }, delay)
})

  return (
      <div className="container-fluid bg-wave">
      <div className="container-md container-pharmacy">
        <div className="container container-pharmacy-detail">
        {!toggle.address && !toggle.success && !toggle.addressNonInstant && (<> 
          <div className="row">
            <div className="col-md-12">
              <div className="header-content">
                <div className="header-left">
                  <img 
                    src={!register?.pharmacyName?.logo ? "/img/logo/new-main-logo.png" : register.pharmacyName.logo}
                    data-cfsrc={"/img/logo/new-main-logo.png"}
                    className="image"
                    alt={"aido-logo"}
                    style={{ paddingTop: "30px", width:"210px"}}
                  />
                </div>
                <div className="header-right">
                <LanguageSelect />
              </div>
            </div>
          </div>
        </div>  
        <div className="row">
        <div className="col-md-12">
        <div className="mb-3 mt-2" style={{ textAlign: "center" }}>
              <span
                className="form-title form-header"
              >
                {`${t("Pharmacy Delivery Form")}${
                  register.pharmacyName ? ` ${register.pharmacyName.name}` : ""
                }${getChannelCode === "32" ? "" : ""}`}
              </span>
            </div>
        </div>
        </div>
      </>)}  
        <div className="col-md-12">
        {!toggle.address && !toggle.success && !toggle.addressNonInstant && (
          <>
            <div className="table-wrapper" style={{ borderRadius: "20px" }}>
              <hr />
              <div className="mb-3">
                <span className="form-title" style={{ fontWeight: 700 }}>
                  {t("Patient Data")}
                </span>
                {patientNameReadOnly ? 
                <></> 
                : <div className="form-group">
                    <span className="form-label-bold">
                      {t("Use Data")}{" "}
                      <span className="form-label-use" onClick={() => setPreviousDataModal(true)}>
                        {t("previous data")}
                      </span>
                    </span>
                  </div> 
                } 
                
              </div>
              {(() => {
                if (getChannelCode == 34){
                    return (
                        <Row>
                          <Col md>
                            <label className="header-title">
                              {t("Patient Information")}
                            </label>
                            <table className="table-patient">
                            <tbody>
                              <tr>
                                <td style={{width: '50%'}}>{t("Nomor MR")}</td>
                                <td>  : {data.patientMrNo}</td>
                              </tr>
                              <tr>
                                <td>{t("Patient Name")}</td>
                                <td>  : {data.patientName}</td>
                              </tr>
                              <tr>
                                <td>{t("Date of Birth")}</td>
                                <td>  : {data.patientDob ? moment(data.patientDob).format("DD-MM-YYYY") : "-"}</td>
                              </tr>
                              <tr>
                                <td>{t("Gender")}</td>
                                <td>  :  {(() => {
                                    if (data.patientGender == 1){
                                      return ("Laki-laki")
                                    }else{
                                      return ("Perempuan")
                                    }
                                    return null;
                                    })()}
                                </td>
                              </tr>
                              <tr>
                                <td>{t("Phone Number")}</td>
                                <td>  : 0{data.patientPhone}</td>
                              </tr>
                              {(customFields.remarks && customFields.remarks?.isShown == '1') && (
                              <tr>
                                <td>{t("Doctor Name")}</td>
                                <td>  : {data.doctorName}</td>
                              </tr>
                            )}
                              {(customFields.orderDate && customFields.orderDate?.isShown === '1') && (
                                <tr>
                                  <td>{t("Prescription Date")}</td>
                                  <td>  : {data.prescriptionDate ? moment(data.prescriptionDate).format("DD-MM-YYYY") : "-"}</td>
                                </tr>
                              )}
                              {(customFields.queueNumber && customFields.queueNumber?.isShown === '1') && (
                                <tr>
                                  <td>{t("Queue Number")}</td>
                                  <td>  : {data.queueNumber || "-"}</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          </Col>
                          <Col md>
                            <label className="header-title drug-padding">
                              {t("Drugs Information")}
                            </label>
                            <div class="table-excess-container">
                            <table className="table-excess">
                            <thead>
                              <tr>
                                <th scope="col"> {t("Drug Name")} </th>
                                <th scope="col"> {t("Drug Qty")} </th>
                                <th scope="col"> {t("Drug Price")} </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.pharmacyItems.map((items, i) => {     
                                return (
                                  <>
                                  <tr>
                                    <td> <div className="drug-name truncate">{truncateWords(items.name, 6, '...')}</div></td>
                                    <td> <div className="drug-qty-uom">{items.qty} {items.uom}</div></td>
                                    <td> {formatter.format(items.patientNet)}</td>
                                  </tr>
                                </>
                                ) 
                              })}
                              <tr>
                                <td>&nbsp;</td>
                                <td><b>Total</b></td>
                                <td>{formatter.format(data.pharmacyAmount)}</td>
                              </tr>
                            </tbody>
                          </table>
                          </div>
                          </Col>
                        </Row>
                    )
                }else{
                  return (
                    <>
                    <Row>
                      <Col md>
                        <div className="form-group">
                          <label className="form-label-bold">
                            {t("Patient Name")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-input-pharmacy"
                            placeholder={t("Input Patient Name")}
                            value={data.patientName}
                            readOnly={patientNameReadOnly}
                            onChange={(e) =>
                              setData({
                                ...data,
                                patientName: e.target.value,
                              })
                            }
                          />
                        </div>
                      </Col>

                      <Col md>
                        <div className="form-group">
                          <label className="form-label-bold">
                            {t("Phone Number")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text form-input-pharmacy">+62</div>
                            </div>
                            <input
                              type="number"
                              className="form-control form-input-pharmacy"
                              placeholder="81234567890"
                              disabled={isExist}
                              style={{ height: "38px" }}
                              value={data.patientPhone}
                              readOnly={patientPhoneReadOnly}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  patientPhone: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md>
                        <label className="form-label-bold">
                          {t("Date of Birth")} <span style={{ color: "red" }}>*</span>
                        </label>
                        <LocalizationProvider
                          className="form-control"
                          dateAdapter={AdapterDateFns}
                        >
                          <DesktopDatePicker
                            label={t("Date of Birth")}
                            value={data.patientDob}
                            inputFormat="dd/MM/yyyy"
                            readOnly={patientDobReadOnly}
                            onChange={(event) => {
                              setData({
                                ...data,
                                patientDob: event,
                              });
                            }}
                            renderInput={({  inputRef, inputProps, InputProps }) => (
                              <Box>
                                <div className="form-group">
                                  <input
                                    className="form-control form-input-pharmacy"
                                    ref={inputRef}
                                    {...inputProps}
                                  />
                                  <span className="filter-date" ref={inputRef} onClick={() => { InputProps.endAdornment.props.children.props.onClick() }}><i className="icon-calendar-date" style={{ color: "#ccc" }} aria-hidden="true"></i></span>
                                </div>
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </Col>

                      <Col md>
                        <div className="form-group">
                          <label className="form-label-bold">
                            {t("Gender")} <span style={{ color: "red" }}>*</span>
                          </label>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: "38px",
                              width: "75%",
                              justifyContent: "space-between",
                            }}
                            onChange={(e) =>
                              setData({
                                ...data,
                                patientGender: e.target.value,
                              })
                            }
                          >
                            {/* <span className="form-label form-label-pharmacy">
                              <input
                                type="radio"
                                value="1"
                                checked={data.patientGender === "1"}
                                readOnly
                              />
                              &nbsp;{t("Male")}
                            </span>
                            <span className="form-label form-label-pharmacy">
                              <input
                                type="radio"
                                value="2"
                                checked={data.patientGender === "2"}
                                readOnly
                              />
                              &nbsp;{t("Female")}
                            </span> */}
                            <div class="gender-radio">
                              <span className="gender-padding">
                              <input
                                  type="radio"
                                  value="1"
                                  id="male"
                                  
                                  checked={data.patientGender === "1"}
                                  readOnly
                                />
                                <label for="male">{t("Male")}</label>
                              </span>
                              <span className="gender-padding">
                              <input
                                  type="radio"
                                  id="female"
                                  value="2"
                                  checked={data.patientGender === "2"}
                                  readOnly
                                />
                              <label for="female">{t("Female")}</label>
                              </span>
                            
                          </div>
                          </div>
                          
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      {(customFields.orderDate && customFields.orderDate?.isShown === '1') && (
                        <Col md>
                          <label className="form-label-bold">{t("Prescription Date")} {customFields.orderDate?.isMandatory === '1' && (<span style={{ color: "red" }}>*</span>)}</label>
                          <LocalizationProvider
                            className="form-control form-input-pharmacy"
                            dateAdapter={AdapterDateFns}
                          >
                            <DesktopDatePicker
                              label={t("Prescription Date")}
                              value={data.prescriptionDate}
                              readOnly={prescriptionDateReadOnly}
                              inputFormat="dd/MM/yyyy"
                              onChange={(event) => {
                                setData({
                                  ...data,
                                  orderDate: event,
                                  prescriptionDate: event,
                                });
                              }}
                              renderInput={({  inputRef, inputProps, InputProps }) => (
                                <Box>
                                  <div className="form-group">
                                    <input
                                      className="form-control form-input-pharmacy"
                                      ref={inputRef}
                                      {...inputProps}
                                    />
                                    
                                    <span className="filter-date" ref={inputRef} onClick={() => { InputProps.endAdornment.props.children.props.onClick() }}><i className="icon-calendar-date" style={{ color: "#ccc" }} aria-hidden="true"></i></span>
                                  </div>
                                </Box>
                              )}
                            />
                          </LocalizationProvider>
                        </Col>
                      )}

                      {(customFields.queueNumber && customFields.queueNumber?.isShown === '1') && (
                        <Col md>
                          <div className="form-group">
                            <label className="form-label-bold">{t("Queue Number")} {customFields.queueNumber?.isMandatory === '1' && (<span style={{ color: "red" }}>*</span>)}</label>
                            <input
                              type="text"
                              className="form-control form-input-pharmacy"
                              placeholder={t("Input Queue Number")}
                              value={data.queueNumber}
                              readOnly={queueNumberReadOnly}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  queueNumber: e.target.value,
                                })
                              }
                            />
                          </div>
                        </Col>
                      )}
                      
                      {(customFields.remarks&&customFields.remarks?.isShown=='1')&&(
                      <Col md>
                     <div className="form-group">
                        <label className="form-label-bold">{t("Doctor Name")} {customFields.remarks?.isMandatory === '1' && (<span style={{ color: "red" }}>*</span>)}</label>
                        <input
                          type="text"
                          className="form-control form-input-pharmacy"
                          placeholder={t("Input Doctor Name")}
                          value={data.doctorName}
                          readOnly={doctorNameReadOnly}
                          onChange={(e) =>
                            setData({
                              ...data,
                              doctorName: e.target.value,
                              remarks: e.target.value
                            })
                          }
                        />
                      </div>
                    </Col>
                      )}
                      
                    </Row>
                    <Row >
                    <Col md>
                    <div className="form-group">
                      <label className="form-label-bold">{t("Drug Prices")}</label>
                      <input
                        type="text"
                        className="form-control form-input-pharmacy"
                        placeholder={t("Input Drug Price")}
                        readOnly={drugPriceReadOnly}
                        value={data.drugPrice || ''} // Ensure default value is an empty string
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </Col>
                  
                {(customFields.drugType && customFields.drugType?.isShown=='1')&&(
                  <Col md>
                  <div className="form-group">
                    <label className="form-label-bold">
                      {t("Drug Type")}{" "}
                      {(customFields.drugType?.isMandatory === '1') && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                    </label>
                    <div style={{ display: 'flex', flexDirection:'column', gap: '10px' }}>
                      {customFields.drugType.value.length && customFields.drugType.value.map((item, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            type="radio"
                            name="drugType"
                            value={data.drugType}
                            checked={data.drugType === item[currentLang]}
                            onChange={() => setData({ ...data, drugType: item[currentLang] })}
                            style={{ marginRight: '5px' }}
                          />
                          <label style={{ margin: '0' }}>
                            {item[currentLang]}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>
                )}

                    </Row>  
                    </>
                  )
                }
                
                return null;
              })()}

              <div className="mb-3 mt-3">
                <span className="form-title" style={{ fontWeight: 700 }}>
                  {t("Delivery Method")}
                </span>
              </div>
             
              <Row>
                <Col md>
                  <div className="form-group">
                    <label className="form-label-bold">
                      {t("Delivery Method")} ({t("Estimated delivery time after drug is picked up by courier")}){" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                      <select
                        className="form-control form-input-pharmacy"
                        disabled={register.loading}
                        value={deliveryMethodId}
                        onChange = {(e) => changeDeliveryMethod(e.target.value)}
                      >
                        <option value="">{t("Select Method")}</option>
                        {deliveryOptions.length > 0
                        && deliveryOptions.map((data) => {
                          return (<option value={data.deliveryMethodId}>{data.display}</option>)
                        })}
                      </select>
                  </div>
                </Col>
              </Row>

              {(deliveryMethodId == "14" || deliveryMethodId == "32" ) &&
              register.pharmacyName.isScheduled &&
               !register.loading && (getPharmacyId != 1154 && getPharmacyId != 1155) && (
                  <Row>
                  </Row>
                )}

              {(deliveryMethodId == "14" || deliveryMethodId == "32" ) &&
                register.pharmacyName.isScheduled &&
                !register.loading && (getPharmacyId == 1154 || getPharmacyId == 1155) && (
                  <Row>
                    <Col md>
                      <div className="form-group">
                        <label className="form-label-bold">
                          {t("Delivery Time")}{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "38px",
                            width: "35%",
                            justifyContent: "space-between",
                          }}
                          onChange={(e) => handleToggleSchedule(e.target.value)}
                        >
                          <span className="form-label-pharmacy">
                            <input
                              type="radio"
                              value="2"
                              checked={toggle.deliveryTime === true}
                            />
                            &nbsp;{t("Send later")}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}

              {(deliveryMethodId == "14" || deliveryMethodId == "32" ) &&
                register.pharmacyName.isScheduled && !register.loading && (
                <Row>
                  <Col md>
                    <div className="form-group">

                    <label className="form-label-bold">
                          {t("Delivery Time")}{" "}
                    </label>

                    <select
                        className="form-control form-input-pharmacy"
                        value={submitData.scheduleTime}
                        onChange={(e) => handleDeliveryTime(e.target.value)}
                      >
                        <option value="">{t("Select Delivery Time")}</option>
                        {register.pharmacyName.schedule.length > 0
                          ? register.pharmacyName.schedule.map(
                              (data, index) => (
                                <option value={data} key={index}>
                                  {data}
                                </option>
                              )
                            )
                          : null}
                      </select>
                    </div>
                  </Col>
                </Row>
              )}

              {deliveryMethodId != "" && (
                <>
                  <div className="mb-3 mt-3">
                    <span className="form-title" style={{ fontWeight: 700 }}>
                      {t("Delivery Address")}
                    </span>
                  </div>

                  <Row>
                    <Col md>
                    <div className="form-group form-group-icon">
                      <label className="form-label-bold">
                        {(deliveryMethodId == '14' || deliveryMethodId == '32') ? t("Delivery Point on Map") : t("Subdistrict | District | City")}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <div class="input-set" 
                        style={{
                          cursor: "pointer",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          lineHeight: 1.5
                        }}
                        onClick={handleAddress}>
                            <i className="icon-location" style={{ color: "#ccc" }} aria-hidden="true"></i>
                            <input className="form-control form-input-pharmacy input-field" type="text" placeholder={data.fullAddress ? data.fullAddress : t("Search Location")} readOnly/>
                          </div>
                        </div>
                    </Col>
                  </Row>

                  {data.detailAddress ? (
                    <Row>
                      <Col md>
                        <div className="form-group">
                          <label className="form-label-bold">
                            {t("Delivery Address/Landmark")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <span
                            className="form-control form-input-map"
                            style={{
                              cursor: "pointer",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              lineHeight: 1.5
                            }}
                            onClick={handleAddress}
                          >
                            {data.detailAddress}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                </>
              )}

              {submitData.transportFee !== "" && !register.loading && getPharmacyId != 1010 && (
                <>
                  <div className="mb-3 mt-3">
                    <span className="form-title" style={{ fontWeight: 700 }}>
                      {t("Delivery Fee")}
                    </span>
                  </div>
                  <Row>
                    <Col md>
                      <div className="form-group">
                        <label className="form-label-bold">
                          {getPharmacyId == 1002 || getPharmacyId == 1164 ?
                          <>
                            {t("Delivery Fee (Prices are subject to change at any time)")}{" "}
                          </> : <>
                            {t("Delivery Fee")}{" "}
                          </>}
                          
                          <span style={{ color: "red" }}>*</span>
                        </label>

                      { submitData.totalPriceBeforePromo > submitData.transportFee &&
                      !submitData.isFixPrice ? (
                        <span className="form-control form-input-pharmacy" style={{ lineHeight: 1.5 }}>
                          <span
                            style={{
                              textDecorationLine: "line-through",
                              textDecorationStyle: "solid",
                            }}
                          >
                            {formatter.format(submitData.totalPriceBeforePromo)}
                          </span>{" "}
                          {formatter.format(submitData.transportFee)}
                        </span>
                      ) : (
                        <span className="form-control form-input-pharmacy" style={{ lineHeight: 1.5 }}>
                          {formatter.format(submitData.transportFee)}
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
                </>
              )}

              {!register.loading && isPromoActive === 1 && submitData.transportFee !== "" && (
                <>
                  <Row>
                    <Col className="col-md-12">
                      <label className="form-label-bold">
                            {t("Promo Code")}{" "}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12">
                      <div class="input-group form-group mb-3">
                        <input
                          type="text"
                          class="form-control form-input-pharmacy"
                          placeholder={t("Promo Code")}
                          onBlur={(e) => {
                            setPromoCode(e.target.value)
                            }
                          }
                          />
                        <div class="input-group-append">
                          <Button
                            className="btn btn-action-input-group"
                            type="button"
                            onClick={(e) => handleValidatePromo(promoCode)}
                            >
                              {t("Use")}
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}

              {!register.loading && isPromoActive === 1 && submitData.transportFee !== "" && submitData.promoCode !== "" && (
                <Row>
                  <Col md = {12}>
                    <div className="form-group">
                      <label className="form-label-bold">
                        {t("Total After Promo")} (Promo Code: {promoCode})
                      </label>
                      <span className="form-control form-input-pharmacy" style={{ lineHeight: 1.5 }}>
                        {formatter.format(totalAfterPromo)}
                      </span>
                    </div>
                  </Col>
                </Row>
              )}

              {register.loading ? (
                <Loading />
              ) : (
                <button
                  type="button"
                  className="btn btn-submit btn-block"
                  onClick={handleValidation}
                >
                  {t("Submit")}
                </button>
              )}

              <div className="mt-3 d-flex justify-content-center">
                {/* <span>
                  {t("Need help? Chat")}{" "}
                  <a
                    href={WA}
                    style={{
                      color: "#3183C5",
                      textDecoration: "underline",
                    }}
                  >
                    Aido Support
                  </a>
                </span> */}
              </div>
            </div>
          </>
        )}

        {toggle.address && !toggle.success && (
          <>
            <div
              className="mb-3"
              style={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setToggle({ ...toggle, address: false })}
              >
                <i
                  className="fa fa-chevron-left"
                  style={{ fontSize: "26px", color: "white" }}
                />
              </span>

              <span
                className="form-title"
                style={{
                  fontWeight: 700,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                {t("Delivery Address")}
              </span>
            </div>

            <div className="table-wrapper" style={{ borderRadius: "20px" }}>
                  <Row>
                    {/* <Col md style={{ height: "550px" }}> */}
                    <Col md>
                      <div className="gmap-label">
                      {map === 'Grab' && (
                        <GrabMap
                          placeholder={t(
                            "Type delivery address, e.g. Jl. Buni No. 62 Munjul Cipayung"
                          )}
                          notesLabel={t(
                            "Adjust pin point as close as possible by dragging the icon"
                          )}
                          notesLabelDescription={t("Destinati location")}
                          inputLabel={(deliveryMethodId == '14' || deliveryMethodId == '32') ? t("Pick Delivery Point on Map") : t("Subdistrict | District | City")}
                          existData={{
                            address: tempAddressData.fullAddress,
                            lat: tempAddressData.latitude,
                            lng: tempAddressData.longitude,
                          }}
                          addressData={(addressData) => getAddress(addressData)}
                          isVersion2={true}
                        />
                        )}
                      </div>
                      <div className="gmap-label">
                      {map === 'Google' && (
                        <GoogleMap
                          placeholder={t(
                            "Type delivery address, e.g. Jl. Buni No. 62 Munjul Cipayung"
                          )}
                          notesLabel={t(
                            "Adjust pin point as close as possible by dragging the icon"
                          )}
                          notesLabelDescription={t("Destinati location")}
                          inputLabel={
                            deliveryMethodId === '14' || deliveryMethodId === '32'
                              ? t("Pick Delivery Point on Map")
                              : t("Subdistrict | District | City")
                          }
                          existData={{
                            address: tempAddressData.fullAddress,
                            lat: tempAddressData.latitude,
                            lng: tempAddressData.longitude,
                          }}
                          addressData={(addressData) => getAddress(addressData)}
                          isVersion2={true}

                        />
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md>
                      <div className="form-group">
                        <label className="form-label-bold">
                          {t("Delivery Address/Landmark")}{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control form-input-map"
                          placeholder={t("E.g. Jl. Buni No. 62 RT. 04/RW. 04 Munjul Cipayung, next to Gang Cemara")}
                          value={tempAddressData.detailAddress}
                          onChange={(e) =>
                            setTempAddressData({
                              ...tempAddressData,
                              detailAddress: e.target.value,
                            })
                          }
                        />
                      </div>
                    </Col>
                  </Row>

                

               
                  <button
                    type="button"
                    className="btn btn-submit btn-block"

                    onClick={submitAddress}
                  >
                    {t("Use")}
                  </button>

                  <div className="mt-3 d-flex justify-content-center">
                    {/* <span>
                  {t("Need help? Chat")}{" "}
                  <a
                    href={WA}
                    style={{
                      color: "#3183C5",
                      textDecoration: "underline",
                    }}
                  >
                    Aido Support
                  </a>
                </span> */}
                  </div>
                </div>
              </>
            )}

        {toggle.addressNonInstant && !toggle.success && (
          <>
            <div
              className="mb-3"
              style={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setToggle({ ...toggle, addressNonInstant: false })}
              >
                <i
                  className="fa fa-chevron-left"
                  style={{ fontSize: "26px", color: "white" }}
                />
              </span>

              <span
                className="form-title"
                style={{
                  fontWeight: 700,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                {t("Delivery Address")}
              </span>
            </div>

            <div className="table-wrapper" style={{ borderRadius: "20px" }}>
              <Row>
                <div className="col-md-12">
                  <label className="form-label-bold">
                    {t("Subdistrict | District | City")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
              </Row>
              <Row>
                <div className="col-md-12">
                  <AsyncSelect
                      id="select-location"
                      isClearable
                      cacheOptions
                      defaultValue={[{id: tempAddressData.postalCode, name: tempAddressData.fullAddress}]}
                      loadOptions={fetchSubdistrict}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={
                          option => `${option.name}`
                      }
                      onChange={(e) => selectLocationNonInstant(e)}
                      maxMenuHeight={200}
                      styles={styleConfig}
                      placeholder={t("Search subdistrict or district")}
                      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  />
                </div>
              </Row> 

              <Row className=" mt-3">
                <Col md>
                  <div className="form-group">
                    <label className="form-label-bold">
                      {t("Delivery Address")}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-input-map"
                      placeholder={t("Complete address with house number, block, RT/RW, district, etc")}
                      value={tempAddressData.detailAddress}
                      onChange={(e) =>
                        setTempAddressData({
                          ...tempAddressData,
                          latitude: 0,
                          longitude: 0,
                          detailAddress: e.target.value,
                        })
                      }
                    />
                  </div>
                </Col>
              </Row>

              <button
                type="button"
                className="btn btn-submit btn-block"
                
                onClick={submitAddress}
              >
                {t("Use")}
              </button>

              <div className="mt-3 d-flex justify-content-center">
                {/* <span>
                  {t("Need help? Chat")}{" "}
                  <a
                    href={WA}
                    style={{
                      color: "#3183C5",
                      textDecoration: "underline",
                    }}
                  >
                    Aido Support
                  </a>
                </span> */}
              </div>
            </div>
          </>
        )}

        {toggle.success && (
          <>
            <div className="mb-3">
              <div className="header-content">
                <div className="header-left">
                  <img 
                          src={!register?.pharmacyName?.logo ? "/img/logo/new-main-logo.png" : register.pharmacyName.logo}
                          data-cfsrc={"/img/logo/new-main-logo.png"}
                          className="image"
                          width={'210px'}
                          alt={"aido-logo"}
                          style={{ paddingTop: "30px" }}
                        />
                </div>
                <div className="header-right">
                  <LanguageSelect />
                </div>
              </div>
            </div>
            <div className="content-orderDone mt-5">
            <div className="background-utama">
                <img
                  className="image"
                  style={{ marginTop: '30px' }}
                  src={'/img/icon/success.png'}
                  data-cfsrc={'/img/icon/success.png'}
                  alt={'klinika-logo'}
                />
                <div className="col-12 d-flex justify-content-center align-items-center mt-3">
                  <span className="form-label-header">
                      {t("complete order")}
                    </span>
                </div>
              </div>
              <div className="order-card">
            <div  style={{ padding: "20px"}}>
              <Row className="mt-2">
                <Col xs={3}>
                  <div>
                    <span className="form-label-confirm">Order Id</span>
                  </div>
                </Col>
                <Col xs={9}>
                  :&nbsp;
                  <span className="form-label-confirm-bold">
                    {register.submitPharmacyUnit
                      ? register.submitPharmacyUnit.id
                      : "-"}
                  </span>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col xs={3}>
                  <div>
                    <span className="form-label-confirm">{t("Patient Name")}</span>
                  </div>
                </Col>
                <Col xs={9}>
                  :&nbsp;
                  <span className="form-label-confirm-bold">{data.patientName}</span>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col xs={3}>
                  <div>
                    <span className="form-label-confirm">{(deliveryMethodId == '14' || deliveryMethodId == '32') ? t("Delivery Point on Map") : t("Subdistrict | District | City")}</span>
                  </div>
                </Col>
                <Col xs={9}>
                  :&nbsp;
                  {data.detailAddress ? (
                    <span className="form-label-confirm-bold">
                      {data.fullAddress}{" "}
                      <span style={{ fontWeight: "500" }}>
                        {`(Detail: ${data.detailAddress})`}
                      </span>
                    </span>
                  ) : (
                    <span className="form-label-confirm-bold">
                      {data.fullAddress}
                    </span>
                  )}
                </Col>
              </Row>

              <Row className="mt-2">
                <Col xs={3}>
                  <div>
                    <span className="form-label-confirm">{t("Delivery Method")}</span>
                  </div>
                </Col>
                <Col xs={9}>
                  :&nbsp;
                  {register.submitPharmacyUnit ? (
                    <span className="form-label-confirm-bold">
                      {
                        deliveryMap[
                          register.submitPharmacyUnit.deliveryMethodID
                        ]?.display
                      }
                    </span>
                  ) : (
                    <span className="form-label-confirm-bold">-</span>
                  )}
                </Col>
              </Row>

              {getPharmacyId != 1010 && (
                <Row className="mt-2">
                  <Col xs={3}>
                    <div>
                      <span className="form-label-confirm">{t("Delivery Fee")}</span>
                    </div>
                  </Col>
                  <Col xs={9}>
                    :&nbsp;
                    { submitData.transportFee ? (
                      submitData.promoCode !== '' ? (
                        <>
                          <span className="form-label-confirm-bold">
                            <s>{formatter.format(submitData.transportFee)}</s> 
                          </span> 
                          <span className="form-label-confirm-bold ml-1">
                            {` ${formatter.format(totalAfterPromo)} (Promo Code: ${submitData.promoCode})`}
                          </span> 
                        </>
                        ):
                        <span className="form-label-confirm-bold">
                          {formatter.format(submitData.transportFee)}
                        </span> 
                      ) :
                        <span>-</span>
                    }
                  </Col>
                </Row>
              )}
              
              {(<Row className="mt-2">
                 <Col xs={3}>
                 <div className="form-label-confirm">
                  <span>{t("Drug Prices")}</span>
                 </div>
               </Col>
               
               <Col xs={9}>
                 :&nbsp;
                 <span className="form-label-confirm-bold">{(!submitData?.drugPrice || submitData.drugPrice === "0") ? "-" : formatCurrency(Number(data.drugPrice.replace(/,/g,'')))}</span>
               </Col>   
             </Row>)}
              {(customFields.orderDate && customFields.orderDate?.isShown === '1') && (
                <Row className="mt-2">
                  <Col xs={3}>
                    <div>
                      <span className="form-label-confirm">{t("Prescription Date")}</span>
                    </div>
                  </Col>
                  <Col xs={9}>
                    :&nbsp;
                    <span className="form-label-confirm-bold">
                      {data.prescriptionDate
                        ? moment(data.prescriptionDate).format("DD-MM-YYYY")
                        : "-"}
                    </span>
                  </Col>
                </Row>
              )}

              {(customFields.queueNumber && customFields.queueNumber?.isShown === '1') && (
                <Row className="mt-2">
                  <Col xs={3}>
                    <div>
                      <span className="form-label-confirm">{t("Queue Number")}</span>
                    </div>
                  </Col>
                  <Col xs={9}>
                    :&nbsp;
                    <span className="form-label-confirm-bold">
                      {data.queueNumber || "-"}
                    </span>
                  </Col>
                </Row>
              )}

              {(customFields.remarks&&customFields.remarks?.isShown)&&(
              <Row className="mt-2">
              <Col xs={3}>
                <div>
                  <span className="form-label-confirm">{t("Doctor Name")}</span>
                </div>
              </Col>
              <Col xs={9}>
                :&nbsp;
                <span className="form-label-confirm-bold">
                  {data.doctorName ? data.doctorName : "-"}
                </span>
              </Col>        
            </Row>
              )}
              {(customFields.drugType&&customFields.drugType?.isShown=='1')&&(
                <Row className="mt-2">
                    <Col xs={3}>
                      <div className="form-label-confirm">
                       <span>{t("Drug Type")}</span>
                      </div>
                    </Col>
                    
                    <Col xs={9}>
                      :&nbsp;
                      <span className="form-label-confirm-bold">  {data.drugType}</span>
                    </Col>   
                  </Row>
              )}

              {((deliveryMethodId == "14" || deliveryMethodId == "32" ) && submitData?.scheduleTime != "") && (
                <Row className="mt-2">
                  <Col xs={3}>
                    <div><span className="form-label-confirm">{t("Delivery Time")}</span>
                     
                    </div>
                  </Col>
                  <Col xs={9}>
                    :&nbsp;
                    <span className="form-label-confirm-bold">
                      {submitData.scheduleTime
                        ? `${moment(new Date()).format("DD MMM YYYY")} ${
                            submitData.scheduleTime
                          }`
                        : " "}
                    </span>
                  </Col>
                </Row>
              )}


              <Row className="mt-4 mb-4 form-label-description">
                <Col md>
                  {submitData.transportFee == 0 ? (
                    <span>
                      {t(
                        "We will send a notification through WhatsApp after the drug is picked up by the courier."
                      )}
                    </span>
                  ) : register.pharmacyName.paymentGate ? (
                    <span>
                      {t(
                        "Please complete your payment through the link sent to your WhatsApp. The drug will be processed by pharmacy after the payment is received & a notification will be sent to WhatsApp after the drug is picked up by the courier."
                      )}
                    </span>
                  ) : (
                    <span>
                      {t(
                        "Please complete your payment at the pharmacy's cashier. The drug will be processed by pharmacy after the payment is received & a notification will be sent to WhatsApp after the drug is picked up by the courier."
                      )}
                    </span>
                  )}
                </Col>
              </Row>


              <div className="mt-3 d-flex justify-content-center">
                {/* <span>
                  {t("Need help? Chat")}{" "}
                  <a
                    href={WA}
                    style={{
                      color: "#3183C5",
                      textDecoration: "underline",
                    }}
                  >
                    Aido Support
                  </a>
                </span> */}
              </div>
            </div>
           
            </div>
            <div style={{ padding: '20px' }}>
            <a href="https://aido.id/">
                <button
                  type="button"
                  className="btn btn-submit btn-block"
                >
                  {t("Done")}
                </button>
              </a>
            </div>
            </div>
          </>
        )}
        </div>
        <div className="col-md-12">
            <div className="footer-content">
              <p className="footer-title">Powered by Aido Health All Right Reserved</p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="floating-support">
              <a href={WA} target="_blank">
                <img  src={"/img/logo/chat-support.png"} alt="Aido Support" />
              </a>
            </div>
          </div>
      </div>
      </div>
      {renderPreviousData()}
      {renderConfirmation()}
    </div>
  );
};

export default RegisterPharmacyByUnit;
