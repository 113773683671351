import React, { Fragment, useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../Layout";
import FilterForm from ".././TeleconsultationRefund/FilterHooks.js";
import { useDispatch, useSelector } from "react-redux";
import { getTeleReschedule, 
         getScheduleData, 
         getScheduleNonSiloam, 
         rescheduleTele  } from "../../../actions";
import moment from "moment";
import Loading from "../../../components/Loading";
import Pagination from "../../../components/Pagination";
import Select from 'react-select';
import Swal from 'sweetalert2';
import queryString from "query-string";
import { Modal } from "react-bootstrap";

const TeleReschedule = () => {
  const [query, setQuery] = useState("");
  const [param, setParam] = useState("");
  const [modal, setModal] = useState(false);
  const [dataTarget, setDataTarget] = useState("");
  const [partner, setPartner] = useState("");
  const [dateSchedule, setDateSchedule] = useState("");
  const [timeSchedule, setTimeSchedule] = useState({});
  const [loadSchedule, setLoadSchedule] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const searchKey = useRef("");
  const sortBy = useRef("desc");
  const startDate = useRef("");
  const endDate = useRef("");

  const teleReschedule = useSelector((state) => state.teleReschedule);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getTeleReschedule(query));
  }, [query, setQuery, dispatch]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: teleReschedule.totalData,
    });
  }, [teleReschedule.orders, teleReschedule.totalData]);

  useEffect(() => {
    const isThirpaty = localStorage.getItem("thirpaty");
    if (isThirpaty == 0 && modal) {
      dispatch(getScheduleNonSiloam(partner));
    }
  }, [partner, dataTarget]);

  const hideModal = () => setModal(false);
  const showModal = () => setModal(true);

  const handleSortBy = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      limit: pagination.limit,
      page: pagination.page,
      sortBy: sortBy.current.value,
      searchKey: searchKey.current.value,
      endDate: endDate.current.value,
      startDate: startDate.current.value,
    };

    let qs = queryString.stringify(values, {
      skipEmptyString: true
    });
    setQuery(qs);
  }

  const handleSearch = (page, limit) => {
    let values = {
      sortBy: sortBy.current.value,
      searchKey: searchKey.current.value,
      endDate: endDate.current.value,
      startDate: startDate.current.value,
    };
    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }
    if (limit) {
      values.limit = limit;
    }
    let qs = queryString.stringify(values, {
      skipEmptyString: true
    });
    console.log("qs", qs)
    setQuery(qs);
  }

  const reset = () => {
    setParam("")
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      limit: pagination.limit,
      page: pagination.page,
      sortBy: sortBy.current.value,
      searchKey: searchKey.current.value,
      endDate: (endDate.current.value) = "",
      startDate: (startDate.current.value) = "",
    };

    let qs = queryString.stringify(values, {
      skipEmptyString: true
    });
    setQuery(qs);
  };

  const apply = () => {
    // console.log(`Filter by:
    //        Date: ${inputs.startDate} s.d ${inputs.endDate}
    //        Status: ${inputs.status}
    //        Patient Name: ${inputs.searchKey}`);
    var params = []
    let filter_params = Object.keys(inputs).map(k => inputs[k] !== "" ? params.push(`${encodeURIComponent(k)}=${encodeURIComponent(inputs[k])}`) : true);
    let qs = params.join("&");
    setParam(qs);
  }

  const {inputs, handleInputChange, handleSubmit, handleReset } = FilterForm({startDate: '', endDate: '', searchKey: ''}, apply);

  const reschedule = () => {
    // console.log("ts",timeSchedule)
    const formReschedule = new FormData();
    if (localStorage.getItem("thirpaty") == 1) {
      formReschedule.append("orderId", dataTarget);
      formReschedule.append("scheduleDate", dateSchedule);
      formReschedule.append("scheduleData", JSON.stringify(timeSchedule));

    } else if (localStorage.getItem("thirpaty") == 0) {
      formReschedule.append("orderId", dataTarget);
      formReschedule.append("scheduleId", timeSchedule.id);
      // console.log("thirparty 0", formReschedule.get('scheduleDate'))
      // console.log(formReschedule.get('orderId'))
      // console.log(formReschedule.get('scheduleId'))

    }
   
    if(formReschedule.get('orderId') !== "" && ((formReschedule.get('scheduleDate') != "" && formReschedule.get('scheduleData') != "{}") || 
      (formReschedule.get('scheduleId') != "undefined" && localStorage.getItem("thirpaty") == 0))){
      Swal.fire({
          title: "Reconfirmation",
          text: "Apakah anda yakin ingin reschedule?",
          icon: "warning",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#7cb342",
          cancelButtonText: "No",
      }).then((result) => {
          if (result.isConfirmed) {              
              dispatch(rescheduleTele(formReschedule)).then((res) => {
              
                if (res) {
                  dispatch(getTeleReschedule());
                }
                // Swal.mixin({
                //     toast: true,
                //     position: 'top-end',
                //     showConfirmButton: false,
                //     timer: 3000,
                //     timerProgressBar: false,
                // }).fire({
                //     icon: 'success',
                //     title: 'Reschedule Success'
                // })
                
              });
              console.log("res_data", teleReschedule.res_data);

              setModal(false);
              if (localStorage.getItem("thirpaty") == 0){
                dispatch(getScheduleNonSiloam(partner));
              }
              setDateSchedule("");
              setTimeSchedule({})
              // setTimeout(
              //   () => (window.location = "/re-schedule"),
              //   5000
              // )
          }
        }).catch((error) => {
          if (error.response) {
              dispatch(getTeleReschedule(query));
              setTimeout(() => 
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data.message,
                }), 1000
              )
          } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
          }
      })

    } else {
      Swal.fire({
        icon: 'info',
        title: 'Oops',
        text: "Select schedule first"
      })
    }
  }

  const dataTables = () => {
    return teleReschedule.loading ? (
      <tr>
        <td colSpan="13">
          <Loading />
        </td>
      </tr>
    ) : teleReschedule.error ? teleReschedule.error.message : (
      <Fragment>
        { teleReschedule.orders.length > 0 ? ( Object.values(JSON.parse(JSON.stringify(teleReschedule.orders))).map((item, index) => (
          <tr key={item.order_id}>
            <td>{(pagination.page - 1) * 10 + index + 1}</td>
            <td>{item.order_id}</td>
            <td>{item.patient_name}</td>
            <td>{moment(item.patient_dob).format("DD MMMM YYYY")}</td>
            <td>{item.customer_phone}</td>
            <td>{item.patient_phone}</td>
            <td>{moment(item.scheduleDate).format("DD MMMM YYYY")}</td>
            <td>{item.startTime.slice(0, 5)}</td>
            <td>{item.endTime.slice(0, 5)}</td>
            <td>{item.doctor_name}</td>
            {
              localStorage.getItem("parentId") == 3295 ? (
                <td>{!item.isHealthScreening && !item.isPremium ? "Free Tele" : !item.eventId && item.isPremium ? "Tanya Dokter Premium" : item.isHealthScreening ? "Health Screening" : ""}</td>
              ) : <></>
            }
            {
              item.insurance_img_url != null ? 
                <td>Yes</td> : <td>No</td>
            }
            
            <td>{item.statusDesc}</td>
            <td>
              <div style={{ textAlign: 'center' }}>
                <div className="span2">
                  <p className="mb-3">
                      <Link
                        to={"order/" + item.order_id}>
                        <button type="button" className="btn btn-outline-primary btn-block" 
                          onClick={ 
                            () => {
                            setDataTarget(item.order_id)
                            }
                          }
                          >
                            Detail
                        </button>
                      </Link>
                  </p>
                  { (item.status == 80 || item.status == 81 ||  item.status == 99 || item.status == 93) ? 
                  <p className="mb-3">
                      <a data-id={item.order_id}>
                        <button type="button" 
                          className="btn btn-outline-success btn-block" 
                          data-toggle="modal" 
                          data-target="#editScheduleModal"
                          onClick={
                            () => {
                              setDataTarget(item.order_id);
                              setPartner(item.partnerId);
                              showModal();
                            }
                          }>
                                Reschedule
                        </button>
                      </a>
                  </p> : <p></p>
                  }
                  <p className="mb-3">
                    {item.channel_url == null ? (
                        <a href="" target="_blank">
                          <button type="button" className="btn btn-outline-info btn-block" style={{ color: 'grey', borderColor: 'grey'}} disabled>
                                  Chat
                          </button>
                      </a>  
                      ) :
                      <a href={`${process.env.REACT_APP_CHAT_URL}chat.html?userid=${teleReschedule.parentId}&userToken=${teleReschedule.sendBird}&chatUrl=${item.channel_url}`} target="_blank">
                          <button type="button" className="btn btn-outline-info btn-block" >
                                  Chat
                          </button>
                      </a>  
                      }             
                  </p>
                </div>
              </div>
            </td>
          </tr>
      ))) :  
      
          <tr>
              <td colSpan="13">Empty data</td>
          </tr> }
      </Fragment>
    )
  }

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>
        <div>Reschedule Teleconsultation</div>
        <div className="col">
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6" style={{ paddingBottom: "1em" }} id="btn-add">
          <a href="" style={{ marginRight: "7px" }}>
            <button type="button" className="btn btn-outline-primary active">
              Teleconsultation
            </button>
          </a>
        </div>
      </div>
      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>
        <div className="row">
          <div className="col-md-8" id="btn-add">
            <span id="finance-title" className="mb10">
              Reschedule Teleconsultation
            </span>
          </div>
          <div className="col-md-4" id="search-form">
              <div className="form-inline" style={{ float: "right" }}>
                <div className="input-group">
                  <select
                    id="sortBy"
                    name="sortBy"
                    className="form-control table-search-field"
                    ref={sortBy}

                  >
                    <option value="desc" data-id="desc">
                      Descending
                    </option>
                    <option value="asc" data-id="asc">
                      Ascending
                    </option>
                  </select>
                  <span className="input-group-btn">
                      <button
                        type="submit"
                        className="btn btn-primary table-btn-search"
                        onClick={handleSortBy}
                      >
                        <i className="fa fa-search" />
                      </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb15">
            <div className="link-btn">
              <button
                type="button"
                className="btn btn-link no-padding"
                id="filterBy"
                data-toggle="collapse"
                data-target="#collapseDetailSchedule"
              >
                Filter By
                <i className="fa fa-angle-down" style={{ marginLeft: "10px" }} />
              </button>
            </div>
            <div className="col">
              <hr />
            </div>
          </div>
          
          <div className="collapse" id="collapseDetailSchedule">
            <form id="filter-form" onSubmit={handleSubmit} onReset={handleReset}>
              <div id="filterForm" className="row filter-by">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                      
                      <div className="mb10">Date From</div>
                      <input
                        id="datePickerFrom"
                        className="form-control"
                        type="text"
                        placeholder="Select Date From"
                        onFocus={
                          (e)=> {
                            e.currentTarget.type = "date";
                            e.currentTarget.focus();
                           }
                        }
                        name="startDate"
                        ref={startDate}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="mb10">Date To</div>
                      <input
                        id="datePickerTo"
                        className="form-control"
                        type="text"
                        placeholder="Select Date To"
                        onFocus={
                          (e)=> {
                            e.currentTarget.type = "date";
                            e.currentTarget.focus();
                           }
                        }
                        name="endDate"
                        ref={endDate}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="col">
                    <div className="col-md-12">
                      <div className="mb10">Patient Name</div>
                      <input
                        id="patientName"
                        className="form-control"
                        type="text"
                        placeholder="Input patient name"
                        name="searchKey"
                        ref={searchKey}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="fm-btn-gr text-right">
                <button
                  id="resetBtn"
                  type="reset"
                  className="btn btn-secondary btn-reset"
                  style={{ marginRight: "10px" }}
                  onClick={
                    () => reset()
                  }
                >
                  Reset
                </button>
                <button
                  id="submitBtn"
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => handleSearch()}
                  >
                  Apply
                </button>
              </div>
              <div style={{ width: "100%" }}>
                <hr />
              </div>
            </form>
        </div>
       
        <div className="table-responsive">
          <table
            id="tableScheduler"
            className="table table-striped table-bordered"
          >
            <thead className="table-header">
              <tr>
                <th rowSpan={2} className="table-header-middle">
                  No
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Order ID
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Patient Name
                </th>
                <th rowSpan={2} className="table-header-middle">
                  DOB
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Customer Phone
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Patient Phone
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Schedule Date
                </th>
                <th colSpan={2} className="table-header-middle">
                  Time
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Doctor
                </th>
                {
                  localStorage.getItem("parentId") == 3295 ? (
                    <th rowSpan={2} className="table-header-middle">
                      Type
                    </th>) : <></>
                }
                <th rowSpan={2} className="table-header-middle">
                  Jaminan
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Status
                </th>
                <th rowSpan={2} className="header-action table-header-middle">
                  Action
                </th>
              </tr>
              <tr>
                <th>Start</th>
                <th>End</th>
              </tr>
            </thead>

            <tbody>
              { dataTables() }
            </tbody>
          </table>
        </div>

        { teleReschedule.totalData > 10 ? (
          <Pagination
            props={pagination}
            setProps={setPagination}
            onChange={(page, limit) => {
              handleSearch(page, limit);
            }}
          />
        ) : null}
        
        <Modal show={modal} onHide={hideModal} animation={true} id="editScheduleModal">
            <form id="formUpdateSchedule">
              <div className="modal-content">
                  <div className="modal-header">
                      <h5 className="modal-title" id="editScheduleModalLabel">Reschedule</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                
                  <div className="modal-body">
                      <div className="row">
                          <div className="col-md-12">
                              <label htmlFor="">Order ID</label>
                              <input 
                                className="form-control" 
                                type="text" 
                                id="orderIdReschedule" 
                                name="orderId" 
                                value={dataTarget} disabled/>
                          </div>
                      
                          <input className="form-control" type="hidden" name="partnerIdReschedule" id="partnerIdReschedule" value={partner}/>
                          { localStorage.getItem("thirpaty") == 1 ? (
                          <Fragment>
                          <div className="col-md-12">
                              <label htmlFor="scheduleDate">Schedule Date</label>
                              <input 
                                className="form-control" 
                                type="text" 
                                name="scheduleDate" 
                                id="scheduleDate" 
                                placeholder="Input Schedule Date" 
                                min={moment().format('YYYY-MM-DD')}
                                onFocus={
                                  (e)=> {
                                    e.currentTarget.type = "date";
                                    e.currentTarget.focus();
                                    }
                                }
                                onChange={(e) =>{                   
                                  setDateSchedule(e.target.value)
                                }
                                  
                                }
                                onBlur={ 
                                  () => {
                                    setLoadSchedule(true);
                                    dispatch(getScheduleData({partner: partner, date: dateSchedule}));
                                    setTimeout(() => {
                                      setLoadSchedule(false)
                                    }, 2000);
                                  }
                                }
                                value={ dateSchedule } 
                                required/>
                          </div>
                          <div id="detailSelectPartner" className="col-md-12">
                              <div className="form-group">
                                  <label htmlFor="editSchedule">Schedule Time</label>
                                  <Select
                                    isClearable
                                    isSearchable
                                    isLoading={loadSchedule}
                                    name="scheduleData"
                                    options={teleReschedule.schedule_data.timeslot}
                                    onChange={setTimeSchedule}
                                    uniqueKey="id"
                                    placeholder={"Select Schedule"}
                                    getOptionValue={(option) => JSON.stringify(option)}
                                    getOptionLabel={option => `[${option.startTime} - ${option.endTime}]`}
                                    
                                  />
                              </div>
                          </div>
                          </Fragment>) : (
                          <div id="detailSelectPartner" className="col-md-12">
                              <div className="form-group">
                                  <label htmlFor="scheduleId">Schedule</label>
                                  <Select 
                                    isClearable
                                    isSearchable
                                    isLoading={loadSchedule}
                                    name="scheduleId"
                                    id="editScheduleNonSiloam"
                                    options={teleReschedule.schedule_data}
                                    onChange={setTimeSchedule}
                                    uniqueKey="scheduleId"
                                    placeholder={"Select Schedule"}
                                    getOptionValue={(option) => option.id}
                                    getOptionLabel={
                                      option => `[ ${option.id} | ${moment(option.scheduleDate).format("DD MMM Y")} | ${option.startTime} - ${option.endTime} ]`
                                    }
                                  />
                              </div>
                          </div> 
                          )}   

                      </div>

                  </div>

                  <div className="modal-footer">
                      <button 
                        type="button" 
                        className="btn btn-secondary" 
                        data-dismiss="modal"
                        onClick={hideModal}>Close</button>
                      
                      <button 
                          type="button" 
                          className="btn btn-primary" 
                          id="button-reschedule" 
                          onClick={reschedule}
                          data-toggle="modal"
                          >Reschedule</button>
                  </div>
              </div>
            </form>
          </Modal>
        </div>
    </DashboardLayout>
  );
};

export default TeleReschedule;
