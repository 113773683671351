import { educationConstants } from "../actions/constants";

const initState = {
  dataEducation: [],
  universities: [],
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case educationConstants.GET_EDUCATION_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case educationConstants.GET_EDUCATION_SUCCESS:
      state = {
        ...state,
        dataEducation: action.payload.dataEducation,
        loading: false,
      };
      break;
    case educationConstants.GET_EDUCATION_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case educationConstants.GET_UNIVERSITIES_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case educationConstants.GET_UNIVERSITIES_SUCCESS:
      state = {
        ...state,
        universities: action.payload.universities,
        loading: false,
      };
      break;
    case educationConstants.GET_UNIVERSITIES_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case educationConstants.ADD_NEW_EDUCATION_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case educationConstants.ADD_NEW_EDUCATION_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case educationConstants.ADD_NEW_EDUCATION_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case educationConstants.UPDATE_EDUCATION_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case educationConstants.UPDATE_EDUCATION_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case educationConstants.UPDATE_EDUCATION_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case educationConstants.DELETE_EDUCATION_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case educationConstants.DELETE_EDUCATION_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case educationConstants.DELETE_EDUCATION_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
  }
  return state;
};
