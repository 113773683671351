import React, { useEffect, useState } from "react";
import DashboardLayout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { getDownloadMonthlyReport } from "../../../actions";
import queryString from "query-string";
import moment from "moment";
import ExcelJS from "exceljs";
import saveAs from "file-saver";

const MonthlyReport = () => {
  const [date, setDate] = useState("");
  const [report, setReport] = useState({
    teleOrders: [],
    pharmacyOrders: [],
    homevisit: [],
  });
  const monthlyReports = useSelector((state) => state.monthlyReport);
  const dispatch = useDispatch();

  useEffect(() => {
    if (date !== "") {
      let yearIndex = date.indexOf("-"),
        yearRes = date.substring(0, yearIndex),
        monthLength = date.length,
        monthIndex = date.indexOf("-"),
        monthRes = date.substring(monthIndex + 1, monthLength),
        values = {
          month: monthRes,
          year: yearRes,
        },
        query = queryString.stringify(values);

      dispatch(getDownloadMonthlyReport(query));
    }
  }, [date]);

  useEffect(() => {
    getData(
      monthlyReports.teleOrders,
      monthlyReports.pharmacyOrders,
      monthlyReports.homevisit
    );
  }, [
    monthlyReports.teleOrders,
    monthlyReports.pharmacyOrders,
    monthlyReports.homevisit,
  ]);

  const getData = (teleOrders, pharmacyOrders, homevisit) => {
    setReport({
      teleOrders: teleOrders,
      pharmacyOrders: pharmacyOrders,
      homevisit: homevisit,
    });
  };

  const exportToExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Tele Order");
    let row;

    if (localStorage.getItem("parentId") == 3295) {
      row = worksheet.addRow([
        "scheduleDate",
        "startTime",
        "endTime",
        "order_id",
        "customer_name",
        "patient_name",
        "partner_name",
        "parent_id",
        "parent_name",
        "status",
        "service fee",
        "totalFee (after promo)",
        "amount",
        "kode promo",
        "promo amount",
        "insurance coverage",
        "isPremium",
        "isHealthScreening",
        "event_name",
        "corporate_name",
      ]);
    } else {

    row = worksheet.addRow([
      "scheduleDate",
      "startTime",
      "endTime",
      "order_id",
      "customer_name",
      "patient_name",
      "partner_name",
      "parent_id",
      "parent_name",
      "status",
      "service fee",
      "totalFee (after promo)",
      "amount",
      "kode promo",
      "promo amount",
      "insurance coverage",
    ]);
    
    }
    row.font = { bold: true };

    let data = report.teleOrders;
    if (data.length !== 0) {
      if (localStorage.getItem("parentId") == 3295) {
        data.map((teleOrders) => {
          worksheet.addRow([
            moment(teleOrders.scheduleDate).format("DD MMM YYYY"),
            teleOrders.startTime,
            teleOrders.endTime,
            teleOrders.order_id,
            teleOrders.customer_name,
            teleOrders.patient_name,
            teleOrders.partner_name,
            teleOrders.parent_id,
            teleOrders.parent_name,
            teleOrders.status,
            teleOrders.serviceFee,
            teleOrders.totalFee,
            teleOrders.amount,
            teleOrders.promoCode,
            teleOrders.discountByPromo,
            teleOrders.approved_amt,
            teleOrders.isPremium ? teleOrders.isPremium : 0,
            teleOrders.isHealthScreening,
            teleOrders.event_name,
            teleOrders.corporate_name,
          ]);
        });
      } else {
        
      data.map((teleOrders) => {
        worksheet.addRow([
          moment(teleOrders.scheduleDate).format("DD MMM YYYY"),
          teleOrders.startTime,
          teleOrders.endTime,
          teleOrders.order_id,
          teleOrders.customer_name,
          teleOrders.patient_name,
          teleOrders.partner_name,
          teleOrders.parent_id,
          teleOrders.parent_name,
          teleOrders.status,
          teleOrders.serviceFee,
          teleOrders.totalFee,
          teleOrders.amount,
          teleOrders.promoCode,
          teleOrders.discountByPromo,
          teleOrders.approved_amt,
        ]);
      });

      }
    }

    const datasheet = workbook.addWorksheet("Pharmacy Order");
    row = datasheet.addRow([
      "created_at",
      "order_id",
      "customer_name",
      "patient_name",
      "pharmacy_name",
      "parent_id",
      "status",
      "totalFee",
      "amount",
      "kode promo",
      "promo amount",
      "insurance coverage",
    ]);
    row.font = { bold: true };

    let data2 = report.pharmacyOrders;
    if (data2.length !== 0) {
      data2.map((pharmacyOrders) => {
        datasheet.addRow([
          moment(pharmacyOrders.created_at).format("DD MMM YYYY"),
          pharmacyOrders.order_id,
          pharmacyOrders.customer_name,
          pharmacyOrders.patient_name,
          pharmacyOrders.doctor_name,
          pharmacyOrders.parent_id,
          pharmacyOrders.status,
          pharmacyOrders.totalFee,
          pharmacyOrders.amount,
          pharmacyOrders.promoCode,
          pharmacyOrders.discountByPromo,
          pharmacyOrders.approved_amt,
        ]);
      });
    }

    const datasheet3 = workbook.addWorksheet("Homevisit");
    row = datasheet3.addRow([
      "order id",
      "nama customer",
      "no customer",
      "email customer",
      "nama pasien",
      "dob",
      "address",
      "code promo",
      "promo description",
      "schedule date",
      "schedule start time",
      "schedule end time",
      "created ad",
      "total fee",
    ]);
    row.font = { bold: true };

    let data3 = report.homevisit;
    if (data3.length !== 0) {
      data3.map((homevisit) => {
        datasheet3.addRow([
          homevisit.order_id,
          homevisit.customer_name,
          homevisit.customer_id,
          homevisit.customer_email,
          homevisit.patient_name,
          moment(homevisit.dob).format("DD MMM YYYY"),
          homevisit.fullAddress,
          homevisit.promoCode,
          homevisit.voucher_desc,
          moment(homevisit.scheduleDate).format("DD MMM YYYY"),
          homevisit.startTime,
          homevisit.endTime,
          moment(homevisit.created_at).format("DD MMM YYYY"),
          homevisit.amount,
        ]);
      });
    }

    let today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate(),
      time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds(),
      dateReplace = date.replace(/-/g, ""),
      timeReplace = time.replace(/:/g, "");

    workbook.xlsx.writeBuffer().then((buff) => {
      saveAs(
        new Blob([buff]),
        "MonthlyReport-" + dateReplace + timeReplace + ".xlsx"
      );
    });
  };

  return (
    <DashboardLayout>
      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        <div className="wrapper-form-title" id="btn-add">
          <span className="form-title">Filter Monthly Report</span>
        </div>

        <div className="border-bottom-section">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label for="">Report Date</label>
                <input
                  type="month"
                  className="form-control"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form-btn-group text-right">
          {monthlyReports.loading ? (
            <button type="submit" className="btn btn-primary" disabled>
              Loading...
            </button>
          ) : (
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => exportToExcel()}
            >
              Download
            </button>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default MonthlyReport;
