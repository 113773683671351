import { pharmacyDeliveryConstants } from "../actions/constants";

const initState = {
  link: "",
  orders: [],
  totalData: "",
  details: [],
  pharmacyId: 0,
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case pharmacyDeliveryConstants.GET_ALL_PHARMACY_DELIVERY_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case pharmacyDeliveryConstants.GET_ALL_PHARMACY_DELIVERY_SUCCESS:
      state = {
        ...state,
        orders: action.payload.orders,
        totalData: action.payload.totalData,
        loading: false,
      };
      break;
    case pharmacyDeliveryConstants.GET_ALL_PHARMACY_DELIVERY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case pharmacyDeliveryConstants.GET_ALL_REPORT_PHARMACY_OFFLINE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case pharmacyDeliveryConstants.GET_ALL_REPORT_PHARMACY_OFFLINE_SUCCESS:
      state = {
        ...state,
        orders: action.payload.orders,
        totalData: action.payload.totalData,
        loading: false,
      };
      break;
    case pharmacyDeliveryConstants.GET_ALL_REPORT_PHARMACY_OFFLINE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;  
    case pharmacyDeliveryConstants.GET_PHARMACY_DETAIL_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case pharmacyDeliveryConstants.GET_PHARMACY_DETAIL_SUCCESS:
      state = {
        ...state,
        details: action.payload.details.orders,
        loading: false,
      };
      break;
    case pharmacyDeliveryConstants.GET_PHARMACY_DETAIL_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case pharmacyDeliveryConstants.PHARMACY_PICKUP_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case pharmacyDeliveryConstants.PHARMACY_PICKUP_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case pharmacyDeliveryConstants.PHARMACY_PICKUP_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case pharmacyDeliveryConstants.GET_PHARMACY_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case pharmacyDeliveryConstants.GET_PHARMACY_SUCCESS:
      state = {
        ...state,
        pharmacyId: action.payload.data,
        loading: false,
      };
      break;
    case pharmacyDeliveryConstants.GET_PHARMACY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case pharmacyDeliveryConstants.GET_PHARMACY_REPORT_LINK_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case pharmacyDeliveryConstants.GET_PHARMACY_REPORT_LINK_SUCCESS:
      state = {
        ...state,
        link: action.payload.data,
        totalData: action.payload.totalData,
        loading: false,
      };
      break;
    case pharmacyDeliveryConstants.GET_PHARMACY_REPORT_LINK_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case pharmacyDeliveryConstants.GET_PHARMACY_DELIVERY_AIDO_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case pharmacyDeliveryConstants.GET_PHARMACY_DELIVERY_AIDO_SUCCESS:
      state = {
        ...state,
        pharmacies: action.payload.data,
        loading: false,
      };
      break;
    case pharmacyDeliveryConstants.GET_PHARMACY_DELIVERY_AIDO_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;  
  }
  return state;
};
