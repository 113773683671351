import { useState } from 'react';

const RefundForm = (initialValues, callback) => {
    const [textInputs, setTextInputs] = useState(initialValues);
    const handleRefund = (event) => {
      if (event) event.preventDefault();
        callback();
        setTextInputs({ ...initialValues });
    }
    const handleTextInputChange = (event) => {
      event.persist();
      setTextInputs(textInputs => ({...textInputs, [event.target.name]: event.target.value}));
    }
    return {
      handleRefund,
      handleTextInputChange,
      textInputs
    };
  }

  export default RefundForm;