import { orderPharmacyConstants } from "../actions/constants";

const initState = {
  orders: [],
  order: {},
  foundPartner: [],
  totalData: 0,
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
      case orderPharmacyConstants.GET_ALL_ORDERPHARMACY_REQUEST:
        state = {
          ...state,
          loading: true,
        };
        break;
      case orderPharmacyConstants.GET_ALL_ORDERPHARMACY_SUCCESS:
          state = {
            ...state,
            orders: action.payload.orders,
            foundPartner: action.payload.foundPartner,
            totalData: action.payload.totalData,
            loading: false,
          };
        break;
      case orderPharmacyConstants.GET_ALL_ORDERPHARMACY_FAILURE:
        state = {
          ...state,
          error: action.payload.error,
          loading: false,
        };
      case orderPharmacyConstants.GET_ORDERPHARMACY_DETAIL_REQUEST:
        state = {
          ...state,
          loading: true,
        };
        break;
      case orderPharmacyConstants.GET_ORDERPHARMACY_DETAIL_SUCCESS:
          state = {
            ...state,
            order: action.payload.order,
            foundPartner: action.payload.foundPartner,
            loading: false,
          };
        break;
      case orderPharmacyConstants.GET_ORDERPHARMACY_DETAIL_FAILURE:
        state = {
          ...state,
          error: action.payload.error,
          loading: false,
        };
      case orderPharmacyConstants.GET_LIST_ORDERPHARMACY_SUCCESS:
        state = {
          ...state,
          orders: action.payload.orders,
          foundPartner: action.payload.foundPartner,
          totalData: action.payload.totalData,
          loading: false,
        };
        break;
      case orderPharmacyConstants.GET_LIST_ORDERPHARMACY_REQUEST:
        state = {
          ...state,
          loading: true,
        };
        break;
      case orderPharmacyConstants.GET_LIST_ORDERPHARMACY_FAILURE:
        state = {
          ...state,
          error: action.payload.error,
          loading: false,
        };
      case orderPharmacyConstants.GET_COMPLETE_ORDERPHARMACY_SUCCESS:
        state = {
          ...state,
          orders: action.payload,
          loading: false,
        };
        break;
      case orderPharmacyConstants.GET_COMPLETE_ORDERPHARMACY_REQUEST:
        state = {
          ...state,
          loading: true,
        };
        break;
      case orderPharmacyConstants.GET_COMPLETE_ORDERPHARMACY_FAILURE:
        state = {
          ...state,
          error: action.payload.error,
          loading: false,
        };
      case orderPharmacyConstants.UPDATE_ORDERPHARMACY_STATUS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case orderPharmacyConstants.UPDATE_ORDERPHARMACY_STATUS_SUCCESS:
        return {
          ...state,
          loading: false,
        }
      case orderPharmacyConstants.UPDATE_ORDERPHARMACY_STATUS_FAILURE:
        return {
          ...state,
          error: action.payload.error,
          loading: false,
        }
  }
  return state;
};
