import { myPromoConstants } from "../actions/constants";

const initState = {
  promoData: [],
  amountTypes: [],
  orderTypes: [],
  promoTypes: [],
  promoDataEdit: {},
  totalData: "",
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case myPromoConstants.GET_ALL_PROMO_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myPromoConstants.GET_ALL_PROMO_SUCCESS:
      state = {
        ...state,
        promoData: action.payload.promos,
        totalData: action.payload.totalData,
        loading: false,
      };
      break;
    case myPromoConstants.GET_ALL_PROMO_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myPromoConstants.GET_PROMO_BY_ID_REQUEST:
      state = {
        ...state,
      };
      break;
    case myPromoConstants.GET_PROMO_BY_ID_SUCCESS:
      state = {
        ...state,
        promoDataEdit: action.payload,
      };
      break;
    case myPromoConstants.GET_PROMO_BY_ID_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
      };
      break;
      case myPromoConstants.GET_PROMO_CONSTANTS_REQUEST:
        state = {
          ...state,
          loading: true,
        };
        break;
      case myPromoConstants.GET_PROMO_CONSTANTS_SUCCESS:
        state = {
          ...state,
          amountTypes: action.payload.amountTypes,
          orderTypes: action.payload.orderTypes,
          promoTypes: action.payload.promoTypes,
          loading: false,
        };
        break;
      case myPromoConstants.GET_PROMO_CONSTANTS_FAILURE:
        state = {
          ...state,
          error: action.payload.error,
          loading: false,
        };
        break;
      case myPromoConstants.ADD_NEW_PROMO_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myPromoConstants.ADD_NEW_PROMO_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case myPromoConstants.ADD_NEW_PROMO_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myPromoConstants.UPDATE_PROMO_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myPromoConstants.UPDATE_PROMO_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case myPromoConstants.UPDATE_PROMO_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myPromoConstants.DELETE_PROMO_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myPromoConstants.DELETE_PROMO_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case myPromoConstants.DELETE_PROMO_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
  }
  return state;
};
