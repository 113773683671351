import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addPromo,
  deletePromo,
  getAllPromo,
  getPromoById,
  updatePromo,
  getPromoConstants,
} from "../../../actions";
import Input from "../Components/Input";
import Modal from "../Components/Modal";
import Pagination from "../../../components/Pagination";
import DashboardLayout from "../Layout";
import queryString from "query-string";
import moment from "moment";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { formatDate } from "../../../helpers/getFormattedDate";

const MyPromoTele = () => {
  const [promoData, setPromoData] = useState({
    promoCode: "",
    startDate: "",
    amount: "",
    quantity: "",
    promoTitle: "",
    endDate: "",
    maxAmount: "",
  });
  
  const [id, setId] = useState("");
  const [addPromoModal, setAddPromoModal] = useState(false);
  const [updatePromoModal, setUpdatePromoModal] = useState(false);
  const [viewDetailModal, setViewDetailModal] = useState(false);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const searchKey = useRef("");
  const myPromo = useSelector((state) => state.myPromo);
  
  const dispatch = useDispatch();
  
  const initState = () => {
    setPromoData({
        promoCode: "",
        startDate: "",
        amount: "",
        quantity: "",
        promoTitle: "",
        endDate: "",
        maxAmount: "",
    });
    setId("");
  };

  useEffect(() => {
    dispatch(getAllPromo(search));
  }, [search]);

  useEffect(() => {
    if (id !== "") {
      getData(myPromo.promoDataEdit);
    }
  }, [myPromo.promoDataEdit], [myPromo.constantsData]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: myPromo.totalData,
    });
  }, [myPromo.totalData]);

  const nf = new Intl.NumberFormat();

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const handleCloseModal = () => {
    initState();
    setAddPromoModal(false);
    setUpdatePromoModal(false);
    setViewDetailModal(false);
  };

  const handleAutoComplete = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      searchKey: searchKey.current.value,
      page: pagination.page,
      limit: pagination.limit,
    };

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleSearch = (page, limit) => {
    let values = {
      searchKey: searchKey.current.value,
    };
    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }
    if (limit) {
      values.limit = limit;
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleViewDetail = (promoId) => {
    setId(promoId);
    dispatch(getPromoConstants());
    dispatch(getPromoById(promoId)).then(setViewDetailModal(true));
  };

  const renderViewDetail = () => {
    let amountTypeVal = myPromo.amountTypes.find(amountType => amountType.id === promoData.amountType);
    let orderTypeVal = myPromo.orderTypes.find(orderType => orderType.id === promoData.orderType);
    let promoTypeVal = myPromo.promoTypes.find(promoType => promoType.id === promoData.promoType);
    let professionName = myPromo.promoData.find(promosData => promosData.id === promoData.id);

    return (
      <Modal
        modalTitle={promoData.promoTitle}
        show={viewDetailModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        buttons={[]}
      >      
        <div className="table-responsive">
          <table className="table table-striped table-bordered">            
            <tbody>
              <tr>
                <td width="15%" align="left">Profession</td>
                <td width="5%">:</td>
                <td align="left">{professionName ? professionName.profession != null ? professionName.profession : "-" : "-"}</td>
              </tr>
              <tr>
                <td width="15%" align="left">Promo Type</td>
                <td width="5%">:</td>
                <td align="left">{orderTypeVal ? orderTypeVal.name : "-"}</td>
              </tr>
              <tr>
                <td width="15%" align="left">Order Type</td>
                <td width="5%">:</td>
                <td align="left">{promoTypeVal ? promoTypeVal.name : "-"}</td>
              </tr>
              <tr>
                <td width="15%" align="left">Amount</td>
                <td width="5%">:</td>
                <td align="left">{amountTypeVal ? amountTypeVal.id == 1 ? promoData.amount + " %" : nf.format(promoData.amount) : "-"}</td>
              </tr>
              <tr>
                <td width="15%" align="left">Max Amount</td>
                <td width="5%">:</td>
                <td align="left">{promoData.maxAmount ? nf.format(promoData.maxAmount) : "-"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    );
  };

  const handleAddPromo = () => {
    setAddPromoModal(true);
  };

  const actionAddPromo = () => {
    const form = new FormData();
    form.append("promoCode", promoData.promoCode);
    form.append("startDate", promoData.startDate);
    form.append("amount", promoData.amount);
    form.append("quantity", promoData.quantity);
    form.append("promoTitle", promoData.promoTitle);
    form.append("endDate", promoData.endDate);
    form.append("maxAmount", promoData.maxAmount);
    form.append("promoDesc", "-");
    form.append("amountType", 1);
    form.append("forFirstOrder", 0);
    form.append("isOneTime", 0);
    form.append("orderType", 8);
    form.append("promoCategory", 1);
    form.append("promoType", 1);
    form.append("profession", 1);

    dispatch(addPromo(form, search))
      .then((result) => {
        if (result) {
          dispatch(getAllPromo(search));
        }
      })
      .then(() => {
        initState();
        setAddPromoModal(false);
        setTimeout(
          () => Swal.fire("Success!", "Data Created", "success"),
          1000
        );
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  };

  const renderAddPromo = () => {
    return (
      <Modal
        modalTitle="Add New Promo"
        show={addPromoModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        onSubmit={actionAddPromo}
      >
        <Row>
          <Col md>
            <div className="wrapper-form-title">
              <span style={{ fontSize: "20px" }}>Promo Data</span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="text"
              label="Promo Code"
              placeholder="Input Promo Code"
              value={promoData.promoCode}
              onChange={(e) =>
                setPromoData({
                  ...promoData,
                  promoCode: e.target.value,
                })
              }
            />
          </Col>
          <Col md>
            <Input
              type="text"
              label="Promo Title"
              placeholder="Input Promo Title"
              value={promoData.promoTitle}
              onChange={(e) =>
                setPromoData({
                  ...promoData,
                  promoTitle: e.target.value,
                })
              }
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="date"
              placeholder="Input Promo Start Date"
              label="Start Date"
              value={promoData.startDate}
              onChange={(e) =>
                setPromoData({
                  ...promoData,
                  startDate: e.target.value,
                })
              }
            />
          </Col>
          <Col md>
            <Input
              type="date"
              placeholder="Input Promo End Date"
              label="End Date"
              value={promoData.endDate}
              onChange={(e) =>
                setPromoData({
                  ...promoData,
                  endDate: e.target.value,
                })
              }
            />
          </Col>
        </Row>

        <Row>
        <Col md>
            <div className="form-group">
              <label>% Coverage</label>
              <div className="input-group">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Input Promo Amount"
                  min="0"
                  style={{ height: "38px" }}
                  value={promoData.amount}
                  onChange={(e) =>
                    setPromoData({
                      ...promoData,
                      amount: e.target.value,
                    })
                  }
                />
                <div className="input-group-append">
                  <div className="input-group-text">%</div>
                </div>
              </div>
            </div>
          </Col>
          <Col md>
            <Input
              type="number"
              label="Max Amount Coverage"
              placeholder="Input Promo Max Amount"
              min="0"
              style={{ height: "38px" }}
              value={promoData.maxAmount}
              onChange={(e) =>
                setPromoData({
                  ...promoData,
                  maxAmount: e.target.value,
                })
              }
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="number"
              label="Quantity"
              placeholder="Input Quantity"
              min="0"
              style={{ height: "38px" }}
              value={promoData.quantity}
              onChange={(e) =>
                setPromoData({
                  ...promoData,
                  quantity: e.target.value,
                })
              }
            />
          </Col>
          <Col md></Col>
        </Row>

      </Modal>
    );
  };

  const handleUpdatePromo = (promoId) => {
    setId(promoId);
    dispatch(getPromoById(promoId)).then(setUpdatePromoModal(true));
  };

  const getData = (data) => {
    setPromoData({
      ...promoData,
      id: data.promoDataEdit.id,
      promoCode: data.promoDataEdit.promoCode,
      startDate: moment(data.promoDataEdit.startDate).format("YYYY-MM-DD"),
      amount: data.promoDataEdit.amount,
      quantity: data.promoDataEdit.quantity,
      promoTitle: data.promoDataEdit.promoTitle,
      endDate: moment(data.promoDataEdit.endDate).format("YYYY-MM-DD"),
      maxAmount: data.promoDataEdit.maxAmount,
      profession: data.promoDataEdit.profession,
      amountType: data.promoDataEdit.amountType,
      orderType: data.promoDataEdit.orderType,
      promoType: data.promoDataEdit.promoType,
    });
  };

  const actionUpdatePromo = () => {
    const form = new FormData();

    form.append("promoCode", promoData.promoCode);
    form.append("startDate", promoData.startDate);
    form.append("amount", promoData.amount);
    form.append("quantity", promoData.quantity);
    form.append("promoTitle", promoData.promoTitle);
    form.append("endDate", promoData.endDate);
    form.append("maxAmount", promoData.maxAmount);
    form.append("promoDesc", "-");
    form.append("amountType", 1);
    form.append("forFirstOrder", 0);
    form.append("isOneTime", 0);
    form.append("orderType", 8);
    form.append("promoCategory", 1);
    form.append("promoType", 1);
    form.append("profession", 1);
    
    dispatch(updatePromo(id, form, search))
      .then((result) => {
        if (result) {
          dispatch(getAllPromo(search));
        }
      })
      .then(() => {
        initState();
        setUpdatePromoModal(false);
        setTimeout(
          () => Swal.fire("Success!", "Data Updated", "success"),
          1000
        );
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  };

  const renderUpdatePromo = () => {
    return (
      <Modal
        modalTitle="Edit Promo"
        show={updatePromoModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        buttons={[
          {
            label: "Update",
            color: "warning",
            onClick: actionUpdatePromo,
          },
        ]}
      >
        <Row>
          <Col md>
            <div className="wrapper-form-title">
              <span style={{ fontSize: "20px" }}>Promo Data</span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="text"
              label="Promo Code"
              placeholder="Input Promo Code"
              value={promoData.promoCode}
              onChange={(e) =>
                setPromoData({
                  ...promoData,
                  promoCode: e.target.value,
                })
              }
            />
          </Col>
          <Col md>
            <Input
              type="text"
              label="Promo Title"
              placeholder="Input Promo Title"
              value={promoData.promoTitle}
              onChange={(e) =>
                setPromoData({
                  ...promoData,
                  promoTitle: e.target.value,
                })
              }
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="date"
              placeholder="Input Promo Start Date"
              label="Start Date"
              value={promoData.startDate}
              onChange={(e) =>
                setPromoData({
                  ...promoData,
                  startDate: e.target.value,
                })
              }
            />
          </Col>
          <Col md>
            <Input
              type="date"
              placeholder="Input Promo End Date"
              label="End Date"
              value={promoData.endDate}
              onChange={(e) =>
                setPromoData({
                  ...promoData,
                  endDate: e.target.value,
                })
              }
            />
          </Col>
        </Row>

        <Row>
        <Col md>
            <div className="form-group">
              <label>% Coverage</label>
              <div className="input-group">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Input Promo Amount"
                  min="0"
                  style={{ height: "38px" }}
                  value={promoData.amount}
                  onChange={(e) =>
                    setPromoData({
                      ...promoData,
                      amount: e.target.value,
                    })
                  }
                />
                <div className="input-group-append">
                  <div className="input-group-text">%</div>
                </div>
              </div>
            </div>
          </Col>
          <Col md>
            <Input
              type="number"
              label="Max Amount Coverage"
              placeholder="Input Promo Max Amount"
              min="0"
              style={{ height: "38px" }}
              value={promoData.maxAmount}
              onChange={(e) =>
                setPromoData({
                  ...promoData,
                  maxAmount: e.target.value,
                })
              }
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="number"
              label="Quantity"
              placeholder="Input Quantity"
              min="0"
              style={{ height: "38px" }}
              value={promoData.quantity}
              onChange={(e) =>
                setPromoData({
                  ...promoData,
                  quantity: e.target.value,
                })
              }
            />
          </Col>
          <Col md></Col>
        </Row>

      </Modal>
    );
  };

  const handleDeletePromo = (promoId) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "grey",
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deletePromo(promoId, search))
          .then((result) => {
            if (result) {
              dispatch(getAllPromo(search));
            }
            initState();
            setTimeout(
              () => Swal.fire("Success!", "Data Deleted", "success"),
              500
            );
          })
          .catch((err) => {
            if (err.response) {
              Swal.fire({
                icon: "error",
                text: err.response.data.message,
              });
            } else {
              Swal.fire({
                icon: "error",
                text: err.message,
              });
            }
          });
      }
    });
  };

  const renderPromo = () => {
    return myPromo.loading ? (
      <tr>
        <td colSpan="9">
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        {myPromo.promoData.length > 0 ? (
          myPromo.promoData.map((promo, index) => (
            <tr key={promo.id}>

              <td>{(pagination.page - 1) * 10 + index + 1}</td>
              <td>
                  <a onClick={() => handleViewDetail(promo.id)} style={{ textDecoration: 'underline', color: 'blue', cursor: "pointer", width: "45px" }}>
                    {promo.promoCode ? promo.promoCode : "-"}
                  </a>
              </td>
              
              <td>{promo.promoTitle ? promo.promoTitle : "-"}</td>
              <td>
                {promo.amount ? promo.amount + " %" : "-"}
              </td>
              <td>{promo.maxAmount ? nf.format(promo.maxAmount) : "-"}</td>
              
              <td>{promo.quantity ? promo.quantity : "-"}</td>
              <td>{promo.startDate ? formatDate(promo.startDate) : "-"}</td>
              <td>{promo.endDate ? formatDate(promo.endDate) : "-"}</td>
              <td>
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <span
                    className="form-title"
                    style={{ cursor: "pointer", width: "45px" }}
                    onClick={() => handleUpdatePromo(promo.id)}
                  >
                    <i className="fa fa-pencil"></i>
                  </span>

                  <span
                    className="form-title"
                    style={{ cursor: "pointer", width: "45px" }}
                    onClick={() => handleDeletePromo(promo.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </span>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="9">Empty data</td>
          </tr>
        )}
      </>
    );
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>My Promo</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        <div className="row">
          <div className="col-md-6">
            <span id="finance-title" className="mb10">
              Team Member
            </span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8" style={{ paddingBottom: "1em" }}>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => handleAddPromo()}
            >
              Add New Promo Teleconsultation
            </button>
          </div>

          <div className="col-md-4" id="search-form">
            <div className="input-group" style={{ paddingBottom: "1em" }}>
              <input
                type="text"
                className="form-control table-search-field"
                placeholder="Search"
                onChange={handleAutoComplete}
                ref={searchKey}
              />
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "45px" }}
                >
                  No
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "120px" }}
                >
                  Promo Code
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "350px" }}
                >
                  Promo Title
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "100px" }}
                >
                  Amount
                </th>

                <th 
                  rowSpan={2} 
                  className="table-header-middle"
                  style={{ width: "200px" }}
                >
                  Max Amount
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "150px" }}
                >
                  Quantity
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "200px" }}
                >
                  Start Date
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "200px" }}
                >
                  End Date
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "180px" }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>{renderPromo()}</tbody>
          </table>
        </div>

        {myPromo.totalData > 10 ? (
          <Pagination
            props={pagination}
            setProps={setPagination}
            onChange={(page, limit) => {
              handleSearch(page, limit);
            }}
          />
        ) : null}
      </div>
      {renderAddPromo()}
      {renderUpdatePromo()}
      {renderViewDetail()}
    </DashboardLayout>
  );
};

export default MyPromoTele;
