import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import queryString from "query-string";
import DashboardLayout from "../Layout";
import Swal from "sweetalert2";
import { Link, useLocation } from "react-router-dom";
import { getWeeklyDashboard } from "../../../actions/orderMedicalRecords.actions";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import ReactDatePicker from "react-datepicker";
import { addDays, subDays } from "date-fns";
import { Box, Card, CardContent, CardMedia } from "@mui/material";

const DashboardMedicalRecord = () => {
  const [search, setSearch] = useState(`startDate=${moment(subDays(new Date(), 6)).format("YYYY-MM-DD")}&endDate=${moment().format("YYYY-MM-DD")}&isHealthScreening=0`);
  const [data, setData] = useState({
      totalOrder: "",
      totalMrFilled: "",
      totalNoPatient: "",
      totalNoDoctor: "",
      totalDoctorLate: "",
      totalPatientCovid: "",
    });
  const [downloadFromDate, setDownloadFromDate] = useState(subDays(new Date(), 6));
  const [downloadToDate, setDownloadToDate] = useState(new Date());
  const [orderType, setOrderType] = useState(0);
  const [partnerId, setPartnerId] = useState("");
  const [report, setReport] = useState({
    orders: [],
  });
  const searchKey = useRef("");
  // const orderType = useRef(0);
  const startDate = useRef("");
  const endDate = useRef("");
  const orderMr = useSelector((state) => state.orderMedicalRecords);
  const { state } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWeeklyDashboard(search));
  }, [dispatch, search]);

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("report");
    let row;

    let title = worksheet.addRow([null, null, `${moment(downloadFromDate).format("D MMM YYYY")} - ${moment(downloadToDate).format("D MMM YYYY")}`]);
    worksheet.mergeCells("C1:H1");
    worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
    title.font = { bold: true };

    row = worksheet.addRow([
      "Partner ID",
      "Doctor Name",
      "Total Order",
      "Medical Record (Filled)",
      "No Patient",
      "No Doctor",
      "Doctor Late",
      "Covid Patient"
    ]);
    worksheet.mergeCells("A1:A2");
    worksheet.mergeCells("B1:B2");
    worksheet.getCell("A1").value = "Partner ID";
    worksheet.getCell("B1").value = "Doctor Name";
    worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getCell("B1").alignment = { vertical: 'middle', horizontal: 'center' };
    row.font = { bold: true };
    worksheet.getColumn("A").width = 8.78;
    worksheet.getColumn("B").width = 31;
    worksheet.getColumn("C").width = 9.89;
    worksheet.getColumn("D").width = 19.67;
    worksheet.getColumn("E").width = 8.78;
    worksheet.getColumn("F").width = 9.22;
   
    // let data = report.orders;
    if (orderMr.partnerOrders.length !== 0) {
      orderMr.partnerOrders.map((item) => {
        worksheet.addRow([
          item.partner,
          item.doctor_firstname ? item.doctor_lastname ? item.doctor_firstname+" "+item.doctor_lastname : item.doctor_firstname : item.doctor_name,
          item.totalOrder,
          item.mrFilled,
          item.clickNoPatient,
          item.noDoctor,
          item.doctorLate,
          item.patientCovid,
        ]);
      });

      let totalRow = worksheet.addRow([
        null,
        "Total",
        orderMr.totalOrder,
        orderMr.totalMrFilled,
        orderMr.totalNoPatient,
        orderMr.totalNoDoctor,
        orderMr.totalDoctorLate,
        orderMr.totalPatientCovid,
      ])
      totalRow.font = { bold: true };
      console.log(totalRow.getCell('D'));

      let percentRow = worksheet.addRow([
        null,
        "Percentage",
        null,
        orderMr.totalMrFilled/(orderMr.totalOrder-orderMr.totalNoPatient),
        orderMr.totalNoPatient/orderMr.totalOrder,
        null,
        orderMr.totalPatientCovid/orderMr.totalMrFilled,
      ])
      percentRow.numFmt = '0%';
      percentRow.font = { bold: true };
    }

    let today = new Date(),
    dateNow =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate(),
      time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds(),
      dateReplace = dateNow.replace(/-/g, ""),
      timeReplace = time.replace(/:/g, "");

    workbook.xlsx.writeBuffer().then((buff) => {
      saveAs(
        new Blob([buff]),
        "MR-WeeklyReport-" + dateReplace + timeReplace + ".xlsx"
      );
    });
  };

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };
  
  const handleReset = () => {
    let values = {};

    if (downloadFromDate !== "") {
      setDownloadFromDate(subDays(new Date(), 6));
      values.startDate = moment(subDays(new Date(), 6)).format("YYYY-MM-DD");
    }
    
    if (downloadToDate !== "") {
      setDownloadToDate(new Date());
      values.endDate = moment(new Date()).format("YYYY-MM-DD");
    }

    if (orderType !== "") {
      setOrderType("");
    }

    if (partnerId !== "") {
      setPartnerId("");
    }

    if (searchKey.current.value !== "") {
      searchKey.current.value = "";
    }

    let query = queryString.stringify(values, {
      skipEmptyString: true
    });
    setSearch(query);
  };

  const handleSearch = (page, limit) => {
    let values = {};

    if (moment(downloadFromDate).format("YYYY-MM-DD") == "Invalid date") { 
      return Swal.fire({
        icon: "warning",
        text: "Start Date is required!",
        showConfirmButton: false,
        timer: 2000,
      });
    }

    if (moment(downloadToDate).format("YYYY-MM-DD") == "Invalid date") { 
      return Swal.fire({
        icon: "warning",
        text: "End Date is required!",
        showConfirmButton: false,
        timer: 2000,
      });
    }

    if (downloadFromDate !== "" && moment(downloadFromDate).format("YYYY-MM-DD") !== "Invalid date") {
      values.startDate = moment(downloadFromDate).format("YYYY-MM-DD");
    }

    if (downloadToDate !== "" && moment(downloadToDate).format("YYYY-MM-DD") !== "Invalid date") {
      values.endDate = moment(downloadToDate).format("YYYY-MM-DD");
    }

    if (orderType !== "") {
      values.isHealthScreening = orderType != -1 ? orderType : "";
    }

    if (partnerId !== "") {
        values.partner = partnerId;
    }

    if (searchKey.current.value !== "") {
      values.searchKey = searchKey.current.value;
    }

    let query = queryString.stringify(values, {
      skipEmptyString: true
    });
    console.log("quue", query);
    setSearch(query);
  };

  return (
    <DashboardLayout>
      {/* <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Medical Record Dashboard</div>

        <div className="col">
          <hr />
        </div>
      </div> */}

      <div className="table-wrapper">
        <div className="mr-container mb-2" 
          >
        </div>

        <div className="row mb15" style={{ justifyContent: "space-between", paddingRight: "15px" }}>
          <div className="link-btn">
            <button
              type="button"
              className="btn btn-link no-padding"
              style={{ textDecoration: "none", boxShadow: "none" }}
              data-toggle="collapse"
              data-target="#collapseDashboardMr"
            >
              Filter By
              <i className="fa fa-angle-down" style={{ marginLeft: "10px" }} />
            </button>
          </div>

          {/* <div className="col">
            <hr />
          </div> */}

          <div className="form-btn-group text-right mt-0 pl-3 pt-3">
            <button
              type="button"
              className="btn btn-outline-primary"
              style={{ marginRight: "5px", marginBottom: "0.5em" }}
              onClick={() => downloadExcel()}
              disabled={orderMr.loading ? true : false}
            >
              {orderMr.loading ? "Loading..." : "Download"}
            </button>
          </div>
        </div>

        <div 
          className="collapse show" 
          id="collapseDashboardMr">
          <div id="filterForm" className="row filter-by">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="">Start Date</label>
                    <ReactDatePicker
                      className="form-control"
                      placeholderText="Select Start Date"
                      dateFormat="dd/MM/yyyy"
                      selected={downloadFromDate}
                      onChange={(date) => setDownloadFromDate(date)}
                      ref={startDate}
                      selectsStart
                      startDate={downloadFromDate}
                      endDate={downloadToDate}
                      minDate={downloadToDate ? subDays(downloadToDate, 6) : false}
                      maxDate={new Date()}
                      isClearable={downloadFromDate ? true : false}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="">End Date</label>
                    <ReactDatePicker
                      className="form-control"
                      placeholderText="Select End Date"
                      dateFormat="dd/MM/yyyy"
                      selected={downloadToDate}
                      onChange={(date) => setDownloadToDate(date)}
                      ref={endDate}
                      selectsEnd
                      startDate={downloadFromDate}
                      endDate={downloadToDate}
                      minDate={downloadFromDate}
                      maxDate={downloadFromDate ? addDays(downloadFromDate, 6) > new Date() ? new Date() : addDays(downloadFromDate, 6) : new Date()}
                      isClearable={downloadToDate ? true : false}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <label htmlFor="orderType">Order Type</label>
                  <select 
                    className="form-control" 
                    value={orderType} 
                    // ref={orderType}
                    onChange={(e) => setOrderType(e.target.value)} 
                    style={{ fontWeight: 200 }}
                    name="orderType"
                    onFocus={
                      (e) => {
                        e.currentTarget.style.fontWeight = 400
                      } 
                    }
                    >
                    <option value="-1">Select Order Type</option>
                    <option value="">All</option>
                    <option value="0">Free Tele</option>
                    <option value="1">Health Screening</option>
                  </select>
                </div>
                
                <div className="col-md-3">
                  <div className="mb10">Doctor Name</div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Doctor Name"
                    ref={searchKey}
                    />
                  {/* <label htmlFor="orderType">Doctor Name</label>
                  <select 
                    className="form-control" 
                    // ref={searchKey} 
                    onChange={(e)=> setPartnerId(e.target.value)} 
                    style={{ fontWeight: 200 }}
                    name="partnerId"
                    onKeyPress={(e) => e.key === 'Enter' ? handleSearch() : null}
                    onFocus={
                      (e) => {
                        e.currentTarget.style.fontWeight = 400
                      } 
                    }
                    >
                      <option value="-1">Doctor Name</option>
                      { 
                        orderMr.partnerOrders.length > 0 && orderMr.partnerOrders.map((item, index) => {
                          return (
                            <option value={item.partner}>{item.doctor_name}</option>
                          )
                        })
                      }
                  </select> */}
                </div>
              </div>
            </div>
          </div>

          <div className="text-right" style={{ marginTop: "25px" }}>
            <button
              type="button"
              className="btn btn-secondary"
              style={{ width: "100px", marginRight: "5px" }}
              onClick={() => handleReset()}
            >
              Reset
            </button>

            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100px" }}
              onClick={() => handleSearch()}
              // disabled={(moment(downloadFromDate).format("YYYY-MM-DD") !== "Invalid date" && moment(downloadToDate).format("YYYY-MM-DD") !== "Invalid date") ? false : true}
            >
              Apply
            </button>
          </div>

          <div style={{ width: "100%" }}>
            <hr />
          </div>
        </div>

        { 
          orderMr.loading ? (<Loading/>) : 
            orderMr.error ? orderMr.error : (
              <>
              
                <div className="row justify-content-space-around pl-1 pr-1 mb15">
                  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb25">
                    <Card sx={{ display: 'flex', boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)", borderRadius: "8px" }}>
                      <CardMedia
                        component="img"
                        sx={{ width: 55, height: 55, verticalAlign: "middle" }}
                        style={{ margin: "27px 10px 27px 27px", verticalAlign: "middle" }}
                        image="/img/icon/total-order.png"
                        alt="Total Order"
                      />
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                          <h5 className="dashboard-title-mr">Total Order</h5>
                          <h1 className="dashboard-number-mr">{orderMr.totalOrder}</h1>
                        </CardContent>
                      </Box>
                    </Card>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb25">
                    <Card sx={{ display: 'flex', boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)", borderRadius: "8px" }}>
                      <CardMedia
                          component="img"
                          sx={{ width: 55, height: 55, verticalAlign: "middle" }}
                          style={{ margin: "27px 10px 27px 27px", verticalAlign: "middle" }}
                          image="/img/icon/mr_filled.png"
                          alt="Medical Record"
                        />
                      <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                          <h5 className="dashboard-title-mr">Medical Record (Filled)</h5>
                          <div className="row justify-content-space-between">
                            <div className="col">
                              <h1 className="dashboard-number-mr">{orderMr.totalMrFilled}</h1>
                            </div>
                            <div className="col" style={{ marginRight: "10px", color: "#51A49A"}}>
                              <h1 className="dashboard-number-mr text-right">{`${orderMr.totalOrder > 0 ? Math.round((orderMr.totalMrFilled/(orderMr.totalOrder-orderMr.totalNoPatient))*100) : 0}%`}</h1>

                            </div>
                          </div>
                        </CardContent>
                      </Box>
                    </Card>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb25">
                    <Card sx={{ display: 'flex', boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)", borderRadius: "8px" }}>
                      <CardMedia
                          component="img"
                          sx={{ width: 55, height: 55, verticalAlign: "middle" }}
                          style={{ margin: "27px 10px 27px 27px", verticalAlign: "middle" }}
                          image="/img/icon/no_patient.png"
                          alt="No Patient"
                        />
                      <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                          <h5 className="dashboard-title-mr">No Patient</h5>
                          <div className="row justify-content-space-between">
                            <div className="col">
                              <h1 className="dashboard-number-mr">{orderMr.totalNoPatient}</h1>
                            </div>
                            <div className="col" style={{ marginRight: "10px", color: "#F2BD00" }}>
                              <h1 className="dashboard-number-mr text-right">{`${orderMr.totalOrder > 0 ? Math.round(orderMr.totalNoPatient/orderMr.totalOrder*100) : 0}%`}</h1>

                            </div>
                          </div>
                        </CardContent>
                      </Box>
                    </Card>
                  </div>

                  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb25">
                    <Card sx={{ display: 'flex', boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)", borderRadius: "8px" }}>
                      <CardMedia
                          component="img"
                          sx={{ width: 55, height: 55, verticalAlign: "middle" }}
                          style={{ margin: "27px 10px 27px 27px", verticalAlign: "middle" }}
                          image="/img/icon/no_doctor.png"
                          alt="No Doctor"
                        />
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                          <h5 className="dashboard-title-mr">No Doctor</h5>
                          <h1 className="dashboard-number-mr">{orderMr.totalNoDoctor}</h1>
                        </CardContent>
                      </Box>
                    </Card>
                  </div>
                 
                  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb25">
                    <Card sx={{ display: 'flex', boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)", borderRadius: "8px" }}>
                      <CardMedia
                          component="img"
                          sx={{ width: 55, height: 55, verticalAlign: "middle" }}
                          style={{ margin: "27px 10px 27px 27px", verticalAlign: "middle" }}
                          image="/img/icon/doctor_late.png"
                          alt="Doctor Late"
                        />
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                          <h5 className="dashboard-title-mr">Doctor Late</h5>
                          <h1 className="dashboard-number-mr">{orderMr.totalDoctorLate}</h1>
                        </CardContent>
                      </Box>
                    </Card>
                  </div>

                  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb25">
                    <Card sx={{ display: 'flex', boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)", borderRadius: "8px" }}>
                      <CardMedia
                          component="img"
                          sx={{ width: 55, height: 55, verticalAlign: "middle" }}
                          style={{ margin: "27px 10px 27px 27px", verticalAlign: "middle" }}
                          image="/img/icon/covid_patient.png"
                          alt="Covid Patient"
                        />
                      <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                          <h5 className="dashboard-title-mr">Covid Patient</h5>
                          <div className="row justify-content-space-between">
                            <div className="col">
                              <h1 className="dashboard-number-mr">{orderMr.totalPatientCovid}</h1>
                            </div>
                            <div className="col" style={{ marginRight: "10px", color: "#DE152D" }}>
                              <h1 className="dashboard-number-mr text-right">{`${orderMr.totalMrFilled > 0 ? Math.round(orderMr.totalPatientCovid/orderMr.totalMrFilled*100) : 0}%`}</h1>

                            </div>
                          </div>
                        </CardContent>
                      </Box>
                    </Card>
                  </div>
                </div>
              </>
              
            )
        }

      </div>
    </DashboardLayout>
  );
};

export default DashboardMedicalRecord;
