import { waitingInvoiceConstants } from "../actions/constants";

const initState = {
  orders: [],
  totalData: "",
  parentId: "",
  sendBird: "",
  details: {
    order: {},
  },
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case waitingInvoiceConstants.GET_ALL_WAITING_INVOICE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case waitingInvoiceConstants.GET_ALL_WAITING_INVOICE_SUCCESS:
      state = {
        ...state,
        orders: action.payload.orders,
        totalData: action.payload.totalData,
        parentId: action.payload.parentId,
        sendBird: action.payload.sendBird,
        loading: false,
      };
      break;
    case waitingInvoiceConstants.GET_ALL_WAITING_INVOICE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case waitingInvoiceConstants.GET_COMPLETED_DETAIL_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case waitingInvoiceConstants.GET_COMPLETED_DETAIL_SUCCESS:
      state = {
        ...state,
        details: action.payload.details,
        loading: false,
      };
      break;
    case waitingInvoiceConstants.GET_COMPLETED_DETAIL_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case waitingInvoiceConstants.UPLOAD_INVOICE_BILLING_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case waitingInvoiceConstants.UPLOAD_INVOICE_BILLING_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case waitingInvoiceConstants.UPLOAD_INVOICE_BILLING_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case waitingInvoiceConstants.ADD_EXCESS_PAYMENT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case waitingInvoiceConstants.ADD_EXCESS_PAYMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case waitingInvoiceConstants.ADD_EXCESS_PAYMENT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
  }
  return state;
};
