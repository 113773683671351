import axios from "../helpers/axios";
import { orderVaccineConstants } from "./constants";

export const getAllOrderVaccine = (q) => {
  return async (dispatch) => {
    dispatch({ type: orderVaccineConstants.GET_ALL_ORDER_VACCINE_REQUEST });
    const res = await axios.get(
      `/Order/Vaccine?${q}`, 
    );
    if (res.status === 200) {
      const { orders, totalData } = res.data;
      dispatch({
        type: orderVaccineConstants.GET_ALL_ORDER_VACCINE_SUCCESS,
        payload: { orders, totalData },
      });
    } else {
      dispatch({
        type: orderVaccineConstants.GET_ALL_ORDER_VACCINE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getOrderVaccineDetail = (id) => {
    return async (dispatch) => {
      dispatch({ type: orderVaccineConstants.ORDER_VACCINE_DETAIL_REQUEST });
      const res = await axios.get(
        `Order/Vaccine/Detail/${id}`, 
      );
      if (res.status === 200) {
        const { order } = res.data;
        dispatch({
          type: orderVaccineConstants.ORDER_VACCINE_DETAIL_SUCCESS,
          payload: { order },
        });
      } else {
        dispatch({
          type: orderVaccineConstants.ORDER_VACCINE_DETAIL_FAILURE,
          payload: { error: res.data.error },
        });
      }
    };
};

export const getSkriningDetail = (id) => {
    return async (dispatch) => {
      dispatch({ type: orderVaccineConstants.GET_SKRINING_DETAIL_REQUEST });
      const res = await axios.get(
        `Skrining/Order/Detail/${id}`, 
      );
      if (res.status === 200) {
        let skrining_detail = res.data;
        dispatch({
          type: orderVaccineConstants.GET_SKRINING_DETAIL_SUCCESS,
          payload: { skrining_detail },
        });
      } else {
        dispatch({
          type: orderVaccineConstants.GET_SKRINING_DETAIL_FAILURE,
          payload: { error: res.data.error },
        });
      }
    };
};

export const getResultOrderVaccine = (id) => {
    return async (dispatch) => {
      dispatch({ type: orderVaccineConstants.ORDER_VACCINE_RESULT_REQUEST });
      const res = await axios.get(
        `Skrining/Result/${id}`, 
      );
      if (res.status === 200) {
        let skrining_result = res.data
        dispatch({
          type: orderVaccineConstants.ORDER_VACCINE_RESULT_SUCCESS,
          payload: { skrining_result },
        });
      } else {
        dispatch({
          type: orderVaccineConstants.ORDER_VACCINE_RESULT_FAILURE,
          payload: { error: res.data.error },
        });
      }
    };
};

export const updateOrderVaccine = (dataBody, query) => {
    return async (dispatch) => {
      dispatch({ type: orderVaccineConstants.UPDATE_ORDER_VACCINE_REQUEST });
      const res = await axios.put(
        `Order/Vaccine/Update`, dataBody
      );
      if (res.status === 200) {
        dispatch({
          type: orderVaccineConstants.UPDATE_ORDER_VACCINE_SUCCESS,
          payload: res.data,
        });
        dispatch(getAllOrderVaccine(query));
      } else {
        dispatch({
          type: orderVaccineConstants.UPDATE_ORDER_VACCINE_FAILURE,
          payload: { error: res.data.error },
        });
      }
    };
};

export const updateVaccineNote = (id, dataBody, query) => {
    return async (dispatch) => {
      dispatch({ type: orderVaccineConstants.UPDATE_VACCINE_NOTE_REQUEST });
      const res = await axios.put(
        `Order/Vaccine/Note`, dataBody
      );
      if (res.status === 200) {
        dispatch({
          type: orderVaccineConstants.UPDATE_VACCINE_NOTE_SUCCESS,
          payload: res.data,
        });
        dispatch(getOrderVaccineDetail(id));
        dispatch(getAllOrderVaccine(query));
      } else {
        dispatch({
          type: orderVaccineConstants.UPDATE_VACCINE_NOTE_FAILURE,
          payload: { error: res.data.error },
        });
      }
    };
};

export const getQuestion = (q) => {
  return async (dispatch) => {
    dispatch({ type: orderVaccineConstants.GET_QUESTION_REQUEST });
    const res = await axios.get(
      `Skrining/Question?${q}`, 
    );
    if (res.status === 200) {
      let questions = res.data;
      dispatch({
        type: orderVaccineConstants.GET_QUESTION_SUCCESS,
        payload: { questions },
      });
    } else {
      dispatch({
        type: orderVaccineConstants.GET_QUESTION_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getOrderForm = (id, token) => {
    return async (dispatch) => {
      dispatch({ type: orderVaccineConstants.GET_ORDER_FORM_REQUEST });
      const res = await axios.get(
        `Skrining/Order/Form/${id}${token}`,  
      );
      if (res.status === 200) {
        dispatch({
          type: orderVaccineConstants.GET_ORDER_FORM_SUCCESS,
          payload: { skrining_detail: res.data },
        });
      } else {
        dispatch({
          type: orderVaccineConstants.GET_ORDER_FORM_FAILURE,
          payload: { error: res.data.error },
        });
      }
    };
};

export const updateSkrining = (dataBody, query) => {
  return async (dispatch) => {
    dispatch({ type: orderVaccineConstants.UPDATE_SKRINING_REQUEST });
    const res = await axios.put(
      `Skrining/Add`, dataBody
    );
    if (res.status === 200) {
      dispatch({
        type: orderVaccineConstants.UPDATE_SKRINING_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllOrderVaccine(query));
    } else {
      dispatch({
        type: orderVaccineConstants.UPDATE_SKRINING_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const postVaccineReport = (dataBody) => {
  return async (dispatch) => {
    dispatch({ type: orderVaccineConstants.GET_VACCINE_DAILY_REPORT_REQUEST });
    const res = await axios.post(
      `Vaccine/Report/Daily`, dataBody
    );
    if (res.status === 200) {
      const { orders } = res.data;
      dispatch({
        type: orderVaccineConstants.GET_VACCINE_DAILY_REPORT_SUCCESS,
        payload: { orders },
      });
    } else {
      dispatch({
        type: orderVaccineConstants.GET_VACCINE_DAILY_REPORT_FAILURE,
        payload: { error: res.data.error },
      });
    } 
  };
};
