import axios from "../helpers/axios";
import { authConstants } from "./constants";

export const login = (user) => {
  return async (dispatch) => {
    dispatch({ type: authConstants.LOGIN_REQUEST });
    const res = await axios.post("/Login", {
      ...user,
    });

    if (res.status === 200) {
      const { accessToken, user, parentId, thirpaty } = res.data;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("parentId", parentId);
      localStorage.setItem("thirpaty", thirpaty);

      const chatUrl = process.env.REACT_APP_CHAT_URL;
      const userID = user != null ? user.id : "";
      const userToken = user != null ? user.sendbirdToken : "";
      const chatRooms = user != null ? user.chatrooms : "";

      // window.open(
      //   chatUrl +
      //     "chat.html?userid=" +
      //     userID +
      //     "&userToken=" +
      //     userToken +
      //     "&chatUrl="
      // );

      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: {
          accessToken,
          user,
          parentId,
          thirpaty,
        },
      });
    } else {
      if (res.status === 400) {
        dispatch({
          type: authConstants.LOGIN_FAILURE,
          payload: { error: res.data.error },
        });
      }
    }
  };
};

export const isUserLogin = () => {
  return async (dispatch) => {
    const accessToken = localStorage.getItem("accessToken");
    const user = JSON.parse(localStorage.getItem("user"));
    if (accessToken && user) {
      const parentId = localStorage.getItem("parentId");
      const thirpaty = localStorage.getItem("thirpaty");
      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: {
          accessToken,
          user,
          parentId,
          thirpaty,
        },
      });
    } else {
      dispatch({
        type: authConstants.LOGIN_FAILURE,
        payload: { error: "User not login" },
      });
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    localStorage.clear();
    dispatch({ type: authConstants.LOGOUT_SUCCESS });
  };
};
