import axios from "../helpers/axios";
import axiosPartner from "../helpers/axiosPartner";
import axiosParent from "../helpers/axiosParent";
import { dokterWorklistConstants } from "./constants";

export const getDoktertWorklist = (q) => {
  return async (dispatch) => {
    dispatch({ type: dokterWorklistConstants.GET_ALL_DOKTERWORKLIST_REQUEST });
    
    const res = await axios.get(`/Order/Partner?${q}`);
    // const res = await axios.get(`/order/partner/teleconsultation?${q}`);
    if (res.status === 200) {
      const { orders, parentId, totalData } = res.data;
      dispatch({
        type: dokterWorklistConstants.GET_ALL_DOKTERWORKLIST_SUCCESS,
        payload: { orders, parentId, totalData },
      });
    } else {
      dispatch({
        type: dokterWorklistConstants.GET_ALL_DOKTERWORKLIST_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const generatePrescription = (form) => {
  return async (dispatch) => {
    dispatch({ type: dokterWorklistConstants.GENERATE_PRESCRIPTION_REQUEST });
    const res = await axios.post("/Order/Doctor/generatePrescription", form);
    if (res.status === 200) {
      dispatch({
        type: dokterWorklistConstants.GENERATE_PRESCRIPTION_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: dokterWorklistConstants.GENERATE_PRESCRIPTION_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const sendEmailDocument = (id) => {
  return async (dispatch) => {
    dispatch({
      type: dokterWorklistConstants.SEND_EMAIL_TELE_DOCUMENT_REQUEST,
    });
    const res = await axios.get(`Order/Tele/documentSendEmail/${id}`);
    if (res.status === 200) {
      dispatch({
        type: dokterWorklistConstants.SEND_EMAIL_TELE_DOCUMENT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: dokterWorklistConstants.SEND_EMAIL_TELE_DOCUMENT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const createMedicalRecordsV2 = (form) => {
  return async (dispatch) => {
    dispatch({ type: dokterWorklistConstants.GENERATE_PRESCRIPTION_REQUEST });
    const res = await axios.post("/Order/Doctor/createMedicalRecordsV2", form);
    if (res.status === 200) {
      dispatch({
        type: dokterWorklistConstants.GENERATE_PRESCRIPTION_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: dokterWorklistConstants.GENERATE_PRESCRIPTION_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const addFreeTeleSummary = (form) => {
  return async (dispatch) => {
    dispatch({ type: dokterWorklistConstants.FREETELE_SUMMARY_REQUEST });
    const res = await axios.post("/Order/Doctor/addFreeTeleSummary", form);
    if (res.status === 200) {
      dispatch({
        type: dokterWorklistConstants.FREETELE_SUMMARY_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: dokterWorklistConstants.FREETELE_SUMMARY_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const uploadConsentFile = (orderId, file) => {
  const formData = new FormData();
  formData.append("file", file);

  return async (dispatch) => {
    dispatch({ type: dokterWorklistConstants.UPLOAD_CONSENT_REQUEST });
    const res = await axiosPartner.post(
      "/App/Partner/Me/Order/" + orderId + "/MedicalRecord/UploadConsent",
      formData
    );
    if (res.status === 200) {
      dispatch({
        type: dokterWorklistConstants.UPLOAD_CONSENT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: dokterWorklistConstants.UPLOAD_CONSENT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const uploadPharmacyFile = (orderId, file) => {
  const formData = new FormData();
  formData.append("file", file);

  return async (dispatch) => {
    dispatch({ type: dokterWorklistConstants.UPLOAD_PHARMACY_REQUEST });
    const res = await axiosPartner.post(
      "/App/Partner/Me/Order/" + orderId + "/MedicalRecord/UploadPharmacy",
      formData
    );
    if (res.status === 200) {
      dispatch({
        type: dokterWorklistConstants.UPLOAD_PHARMACY_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: dokterWorklistConstants.UPLOAD_PHARMACY_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const sendReminderToPatient = (form) => {
  return async (dispatch) => {
    dispatch({
      type: dokterWorklistConstants.SEND_REMINDER_FREETELE_REQUEST,
    });
    const res = await axios.post(`/Order/Doctor/sendReminderToPatient`, form);
    if (res.status === 200) {
      dispatch({
        type: dokterWorklistConstants.SEND_REMINDER_FREETELE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: dokterWorklistConstants.SEND_REMINDER_FREETELE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const findIcd10 = (type, q) => {
  return async (dispatch) => {
    dispatch({ type: dokterWorklistConstants.GET_ICD10_REQUEST });
    
    const res = await axiosParent.get(`/App/Hospital/Icd/${type}?searchKey=${q}`);
    if (res.status === 200) {
      // const { results, totalData } = res.data;
      dispatch({
        type: dokterWorklistConstants.GET_ICD10_SUCCESS,
        payload: { results: res.data.data, totalData: res.data.totalData },
      });
    } else {
      // console.log("action icd",res.data)
      dispatch({
        type: dokterWorklistConstants.GET_ICD10_FAILURE,
        payload: { error: res.data },
      });
    }
  };
};

export const getTestLabList = () => {
  return async (dispatch) => {
    dispatch({ type: dokterWorklistConstants.GET_TEST_LAB_REQUEST });
    
    const res = await axiosParent.get(`/App/Hospital/TestLabOption`);
    if (res.status === 200) {
      dispatch({
        type: dokterWorklistConstants.GET_TEST_LAB_SUCCESS,
        payload: { testLabList: res.data.data.testLabList },
      });
    } else {
      dispatch({
        type: dokterWorklistConstants.GET_TEST_LAB_FAILURE,
        payload: { error: res.data },
      });
    }
  };
};

export const getMedicines = (q) => {
  return async (dispatch) => {
    dispatch({ type: dokterWorklistConstants.GET_MEDICINES_REQUEST });
    
    const res = await axiosParent.get(`/App/Hospital/Medicines?searchKey=${q}`);
    if (res.status === 200) {
      dispatch({
        type: dokterWorklistConstants.GET_MEDICINES_SUCCESS,
        payload: { medicines: res.data.data.medicines, totalData: res.data.data.totalData },
      });
    } else {
      dispatch({
        type: dokterWorklistConstants.GET_MEDICINES_FAILURE,
        payload: { error: res.data },
      });
    }
  };
};

export const getMedicalDetail = (id) => {
  return async (dispatch) => {
    dispatch({
      type: dokterWorklistConstants.GET_MEDICAL_DETAIL_REQUEST,
    });
    const res = await axios.get(`/Order/Doctor/medicalDetail/${id}`);
    if (res.status === 200) {
      dispatch({
        type: dokterWorklistConstants.GET_MEDICAL_DETAIL_SUCCESS,
        payload: { order: res.data.data },
      });
    } else {
      dispatch({
        type: dokterWorklistConstants.GET_MEDICAL_DETAIL_FAILURE,
        payload: { error: res.data },
      });
    }
  };
};

export const createMedicalRecordsV3 = (form) => {
  return async (dispatch) => {
    dispatch({
      type: dokterWorklistConstants.CREATE_MEDICAL_RECORD_REQUEST,
    });
    const res = await axios.post(`/Order/Doctor/createMedicalRecordsV3`, form);
    if (res.status === 200) {
      dispatch({
        type: dokterWorklistConstants.CREATE_MEDICAL_RECORD_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: dokterWorklistConstants.CREATE_MEDICAL_RECORD_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const notifyEmergencyCase = (form) => {
  return async (dispatch) => {
    dispatch({
      type: dokterWorklistConstants.NOTIFY_EMERGENCY_REQUEST,
    });
    const res = await axios.post(`/Order/Doctor/notifyEmergencyCase`, form);
    if (res.status === 200) {
      dispatch({
        type: dokterWorklistConstants.NOTIFY_EMERGENCY_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: dokterWorklistConstants.NOTIFY_EMERGENCY_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const reportNoPatient = (form) => {
  return async (dispatch) => {
    dispatch({
      type: dokterWorklistConstants.REPORT_NOPATIENT_REQUEST,
    });
    const res = await axios.put(`/Order/Doctor/updatePatientNotJoined`, form);
    if (res.status === 200) {
      dispatch({
        type: dokterWorklistConstants.REPORT_NOPATIENT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: dokterWorklistConstants.REPORT_NOPATIENT_FAILURE,
        payload: res.data,
      });
    }
  };
};

export const getAllMedicalHistory = (patientId, q) => {
  return async (dispatch) => {
    dispatch({ type: dokterWorklistConstants.GET_ALL_MEDICALHISTORY_REQUEST });

    if(patientId == undefined) {
      dispatch({
        type: dokterWorklistConstants.GET_ALL_MEDICALHISTORY_FAILURE,
        payload: { error: "Patient ID undefined" },
      });
    }
    
    const res = await axios.get(`/Order/Doctor/medicalHistory/${patientId}?${q}`);
    if (res.status === 200) {
      dispatch({
        type: dokterWorklistConstants.GET_ALL_MEDICALHISTORY_SUCCESS,
        payload: { 
          patient: res.data.data.patient,
          medicalRecords: res.data.data.medicalRecords, 
          totalData: res.data.data.totalData 
        },
      });
    } else {
      dispatch({
        type: dokterWorklistConstants.GET_ALL_MEDICALHISTORY_FAILURE,
        payload: { error: res.data },
      });
    }
  };
};

export const getBmiAndIdealWeight = (form) => {
  return async (dispatch) => {
    dispatch({
      type: dokterWorklistConstants.GET_BMI_REQUEST,
    });
    const res = await axiosParent.post(`/App/Hospital/getBmiAndIdealWeight`, form);
    if (res.status === 200) {
      dispatch({
        type: dokterWorklistConstants.GET_BMI_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: dokterWorklistConstants.GET_BMI_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getEventHS = (eventId, sortBy) => {
  eventId = eventId != null ? eventId : 0;
  sortBy = sortBy != null ? sortBy : 0;
  return async (dispatch) => {
    dispatch({ type: dokterWorklistConstants.GET_EVENTHS_REQUEST });
    
    const res = await axiosParent.get(`/App/Hospital/EventHs/${eventId}?sortBy=${sortBy}`);
    if (res.status === 200) {
      dispatch({
        type: dokterWorklistConstants.GET_EVENTHS_SUCCESS,
        payload: { eventList: res.data.data },
      });
    } else {
      dispatch({
        type: dokterWorklistConstants.GET_EVENTHS_FAILURE,
        payload: { error: res.data },
      });
    }
  };
};