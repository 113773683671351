import React, { Fragment, useEffect, useState } from "react";
import DashboardLayout from "../../Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  getPaymentReportDetail,
} from "../../../../actions";
import { useParams } from "react-router";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import Loading from "../../../../components/Loading";

const Breadcrumb = (props) => {
  return (
    <nav key={props.label} aria-label="breadcrumb" id="breadcrumb-id">
      <ol className="breadcrumb pl-0">
        <li className="breadcrumb-item">
          <a>Dashboard</a>
        </li>
        <li className="breadcrumb-item" aria-current="page">
          <Link to={props.path}>{props.menu}</Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {props.label}
        </li>
      </ol>
    </nav>
  );
};

const PaymentReportDetail = () => {
  let { orderId } = useParams();
  let history = useHistory();

  const paymentReport = useSelector((state) => state.paymentReport);
  const dispatch = useDispatch();

  const role = JSON.parse(localStorage.getItem("user"));
  const roleUser = role.user_role_management_id.id;
  const q = {};

  const { loading, error, order } = paymentReport;

  useEffect(() => {
    dispatch(getPaymentReportDetail(orderId));
  }, [orderId, dispatch]);
  
  const nf = new Intl.NumberFormat();

  return (
    <DashboardLayout>
      <Breadcrumb
        path={"/payment-report"}
        menu={"Payment Report"}
        label={"Detail - Payment Report"}
      />
      <div className="form-wrapper" id="detail-complete-call-teleconsul-order">
        {loading ? (
          <Loading />
        ) : error ? (
          error.meesage
        ) : (
          <Fragment>
            <div className="wrapper-form-title">
              <span className="form-title">
              Payment Report Detail
              </span>
            </div>

            <div className="border-bottom-section">
              <div className="table-responsive">
                <table
                  id="tableScheduler"
                  className="table table-striped table-bordered"
                >
                  <tbody className="text-left">
                    <tr>
                      <td>Order Id</td>
                      <td colSpan="2">{order.order_id}</td>
                    </tr>
                    <tr>
                      <td>Customer Name</td>
                      <td>{order.customer_name}</td>
                    </tr>
                    <tr>
                      <td>Customer Phone</td>
                      <td>{order.customer_phone}</td>
                    </tr>
                    <tr>
                      <td>Doctor</td>
                      <td>{order.doctor_name}</td>
                    </tr>
                    <tr>
                      <td>Total Fee</td>
                      <td>Rp. {nf.format(order.totalFee)}</td>
                    </tr>
                    <tr>
                      <td>Payment Date</td>
                      <td colSpan="2">{order.paymentDate}</td>
                    </tr>
                    <tr>
                      <td>Payment Status</td>
                      <td>{order.paymentStatus}</td>
                    </tr>
                    <tr>
                      <td>Order Status</td>
                      <td>{order.statusDesc}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </Fragment>
        )}
      </div>
    </DashboardLayout>
  );
};

export default PaymentReportDetail;
