import { useState } from 'react';

const FilterForm = (initialValues, callback) => {
    const [inputs, setInputs] = useState(initialValues);
    const handleSubmit = (event) => {
      if (event) event.preventDefault();
        callback();
        // setInputs({ ...initialValues });
    }
    const handleInputChange = (event) => {
      event.persist();
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
    }
    const handleReset = (event) => {
      setInputs({ ...initialValues });
    }
    return {
      handleReset,
      handleSubmit,
      handleInputChange,
      inputs
    };
  }

  export default FilterForm;