import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getCovidDetail } from "../../../actions";
import DatePicker from "react-datepicker";
import { Button, Modal } from "react-bootstrap";
import Layout from "../Layout";

const Detail = () => {
  const { state } = useLocation();
  const history = useHistory();
  const [date, setDate] = useState(
    state.requestDate
      ? state.requestDate
      : new Date().setDate(new Date().getDate() + 1)
  );
  const [loginModal, setLoginModal] = useState(false);
  const testCovid = useSelector((state) => state.testCovid);
  const dispatch = useDispatch();
  const { loading, error, detailData } = testCovid;

  useEffect(() => {
    dispatch(getCovidDetail(state.packageServiceId));
  }, []);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const actionSelect = () => {
    const check = window.localStorage.getItem("accessToken");

    if (check) {
      history.push({
        pathname: "/test-covid/form",
        state: {
          ...state,
          requestDate: date,
          serviceType: detailData.serviceType,
        },
      });
    } else {
      setLoginModal(true);
    }
  };

  const renderSelectLogin = () => {
    return (
      <Modal
        size="lg"
        show={loginModal}
        onHide={() => setLoginModal(false)}
        keyboard={false}
        centered
      >
        <Modal.Header
          style={{
            justifyContent: "center",
            alignSelf: "center",
            borderBottom: "none",
            paddingBottom: 0,
          }}
        >
          <Modal.Title style={{ fontSize: "inherit" }}>
            Masuk/Daftar
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p style={{ fontSize: "12px", textAlign: "center" }}>
            Silakan masuk atau daftar dulu untuk melanjutkan proses pemesanan
          </p>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <div className="col">
            <Link
              to={{
                pathname: "/test-covid/login",
                state: {
                  ...state,
                  requestDate: date,
                  serviceType: detailData.serviceType,
                },
              }}
            >
              <Button
                className="btn"
                variant="outline-primary"
                size="sm"
                style={{ width: "100%" }}
              >
                Masuk
              </Button>
            </Link>
          </div>

          <div className="col pl-0">
            <Link
              to={{
                pathname: "/test-covid/register",
                state: {
                  ...state,
                  requestDate: date,
                  serviceType: detailData.serviceType,
                },
              }}
            >
              <Button
                className="btn"
                variant="primary"
                size="sm"
                style={{ width: "100%" }}
              >
                Register
              </Button>
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <Layout title="Test Covid - 19" link="/test-covid/list">
      {loading ? (
        <Loading />
      ) : (
        <div className="detail-res">
          <div className="list-res">
            <span style={{ fontWeight: "bold", fontSize: "20px" }}>{`${
              detailData.package.name ? detailData.package.name : "-"
            } ${detailData.partner.name ? detailData.partner.name : ""}`}</span>
          </div>

          <div className="list-res">
            <span style={{ fontWeight: "bold" }}>Jenis:</span>
            <span>Homevisit</span>
          </div>

          <div className="list-res">
            <span style={{ fontWeight: "bold" }}>Pilih Hari:</span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: "50%", marginRight: "10px" }}>
                <DatePicker
                  className="form-control"
                  selected={date}
                  showMonthDropdown
                  showYearDropdown
                  minDate={new Date().setDate(new Date().getDate() + 1)}
                  maxDate={new Date().setMonth(new Date().getMonth() + 2, 0)}
                  onFocus={(e) => (e.target.readOnly = true)}
                  onChange={(data) => setDate(data)}
                />
              </div>

              <i className="fa fa-calendar-days" />
            </div>
          </div>

          <div className="list-res">
            <div
              className="danger"
              dangerouslySetInnerHTML={{ __html: detailData.description }}
            />
          </div>

          <div className="list-res">
            <span>{detailData.cartInfo ? detailData.cartInfo : ""}</span>
          </div>

          <div className="list-res">
            <button
              type="button"
              className="btn btn-primary"
              onClick={actionSelect}
            >
              Pilih
            </button>
          </div>
        </div>
      )}
      {renderSelectLogin()}
    </Layout>
  );
};

export default Detail;
