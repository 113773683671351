import axios from "axios";
import { parentApi } from "../urlConfig";

const accessToken = window.localStorage.getItem("accessToken");

const axiosIntance = axios.create({
  baseURL: parentApi,
  headers: {
    Authorization: accessToken ? `Bearer ${accessToken}` : "",
    'Content-Type': 'multipart/form-data'
  },
});

export default axiosIntance;
