import React, {Fragment, useEffect, useState } from "react";
import DashboardLayout from "../../../Layout";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrderPharmacy, getOrderPharmacyDetail, updateStatusOrderPharmacy  } from "../../../../../actions";
import { useParams } from "react-router";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import Loading from "../../../../../components/Loading";
import Swal from 'sweetalert2';
import { Table } from "react-bootstrap";

const OrderPharmacyDetail = () => {
  let { orderId } = useLocation();

  const orderPharmacy = useSelector((state) => state.orderPharmacy);
  const dispatch = useDispatch();

  const [ statusCode, setStatusCode ] = useState(null);

  const {loading, error} = orderPharmacy;
  
  useEffect(() => {
    dispatch(getOrderPharmacyDetail(orderId));   
    }, [dispatch, orderId]
  );
  
  const changeStatus = () => {
    // console.log(statusCode)
    // let flag = false;
    // (statusCode !== null || statusCode != data.status) ?
    //     flag = true : flag = false

    const form = new FormData();
    statusCode == null ? form.append("status", orderPharmacy.order.status) : form.append("status", statusCode)
    // console.log(form.get("status"))
    let message;
    (form.get("status") == orderPharmacy.order.status) ? message = 'Save status?' : message = `Apakah anda yakin ingin mengubah status?`;
    if(orderId !== "" && (form.get("status") !== null)){
        Swal.fire({
            title: "Reconfirmation",
            text: message,
            icon: "warning",
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#7cb342",
            cancelButtonText: "No",
        }).then((result) => {
            if (result.isConfirmed) {
                // console.log("statuscode", statusCode);
                dispatch(updateStatusOrderPharmacy(orderId, form)).then((res) => {
                  if (res) {
                    dispatch(getAllOrderPharmacy());
                  }
                }).then(
                    setTimeout(() => 
                        Swal.fire({
                            icon: 'success',
                            text: 'Update Order Pharmacy Success',
                        }), 1000
                    )
                );
            } 
          });
    }
  }
  let data = orderPharmacy.order; 
  const teleStatus = [
    { 
        statusCode: 20,
        statusDesc: 'Order Baru'
    },
    { 
        statusCode: 21,
        statusDesc: 'Ekses Klaim & Obat'
    },
    { 
        statusCode: 22,
        statusDesc: 'Ekses Klaim & Obat Dikonfirmasi'
    },
    { 
        statusCode: 29,
        statusDesc: 'Dalam Perjalanan'
    },
    { 
        statusCode: 88,
        statusDesc: 'Cancelled By Customer'
    },
    { 
        statusCode: 89,
        statusDesc: 'Cancelled By CMS'
    },
    { 
        statusCode: 99,
        statusDesc: 'Pesanan Selesai'
    },
  ]; 
  const nf = new Intl.NumberFormat();

  return (
    
    <DashboardLayout>
        {/* <Breadcrumb 
            path={"/teleconsultation-all-data"} 
            menu={"List Teleconsultation"} 
            label={"Detail - List Teleconsultation"}
            /> */}
        <div className="form-wrapper" id="detail-complete-call-teleconsul-order">
        { loading ? <Loading/> : error ? error.message : (
        <Fragment>
           <div className="wrapper-form-title">
               <span className="form-title">Pharmacy Order Detail</span>
           </div>
   
           <div className="border-bottom-section">
               
               <div className="table-responsive">
                   <table id="tableScheduler" className="table table-striped table-bordered">
                           
                        <tbody className="text-left">
                            <tr>
                               <td>Order Id</td>
                               <td colSpan="2">{ data.order_id }</td>
                           </tr>
                           <tr>
                               <td>Customer</td>
                               <td>{ data.customer_name }</td>
                           </tr>
                           <tr>
                               <td>Customer Phone</td>
                               <td>{ data.customer_phone }</td>
                           </tr>
                           <tr>
                               <td>Patient</td>
                               <td>{ data.patient_name }</td>
                           </tr>
                           <tr>
                               <td>Delivery Address</td>
                               <td>{ data.address }</td>
                           </tr>
                           <tr>
                               <td>Total Amount</td>
                               <td>Rp. {nf.format(data.totalFee)}</td>
                           </tr>
                           <tr>
                                <td>Status</td>
                                <td>
                                    <div className="form-group">
                                        <select 
                                            className="form-control" 
                                            name="status" 
                                            onChange={(e) => setStatusCode(e.target.value)} 
                                            onFocus={(e) => setStatusCode(e.target.value)} 
                                            value={statusCode}
                                            >
                                                { teleStatus.map((statusData) => (
                                                    <option 
                                                        key={statusData.statusCode}
                                                        value={statusData.statusCode}
                                                        selected={ data.status === statusData.statusCode ? (true) : false}
                                                        >{ statusData.statusDesc }</option>
                                                ))}
                                        </select>
                                    </div>
                                </td>
                           </tr>
                        </tbody>
                           
                       
                   </table>
                </div>
              
               
            </div>

            <div className="form-btn-group text-right">
                <button type="button" className="btn btn-primary" onClick={changeStatus}>Save Status</button>
            </div>

    </Fragment>
        )}
       </div>
    </DashboardLayout>
  );
};

export default OrderPharmacyDetail;
