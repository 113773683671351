import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPharmacyDeliveryAllCanceled, getPharmacyList } from "../../../actions";
import Pagination from "../../../components/Pagination";
import DashboardLayout from "../Layout";
import queryString from "query-string";
import moment from "moment";
import { SelectOptions } from "../../../components/Input";

const PharmacyDeliveryAllCanceled = () => {
  const startDate = useRef("");
  const endDate = useRef("");
  const partnerId = localStorage.getItem("parentId");
  const [search, setSearch] = useState(`partnerId=${partnerId}&orderStatus=89,87`);
  const [hospital, setHospital] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const searchKey = useRef("");
  const pharmacyDeliveryAllCanceled = useSelector((state) => state.pharmacyDeliveryAllCanceled);
  const pharmacyDelivery = useSelector((state) => state.pharmacyDelivery);
  const dispatch = useDispatch();

  const role = JSON.parse(localStorage.getItem("user"));
  const roleUser = role.user_role_management_id.id;

  useEffect(() => {
    dispatch(getPharmacyDeliveryAllCanceled(search));
    if(roleUser == 43 || roleUser == 44) {
      dispatch(getPharmacyList());
    }
  }, [search]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: pharmacyDeliveryAllCanceled.totalData,
    });
  }, [pharmacyDeliveryAllCanceled.totalData]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const handleReset = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      partnerId: partnerId,
      orderStatus: "89,87",
      page: pagination.page,
      limit: pagination.limit,
    };

    if (startDate.current.value !== "") {
        startDate.current.value = "";
    }
    if (endDate.current.value !== "") {
        endDate.current.value = "";
    }
    setHospital(null);

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleSearch = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      partnerId: partnerId,
      orderStatus: "89,87",
      page: pagination.page,
      limit: pagination.limit,
    };

    if (startDate.current.value !== "") {
        values.startDate = startDate.current.value;
    }
    if (endDate.current.value !== "") {
        values.endDate = endDate.current.value;
    }
    if (hospital?.value) {
      values.hospital = hospital?.value;
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handlePage = (page, limit) => {

    let values = {
      partnerId: partnerId,
      orderStatus: "89,87",
      searchKey: searchKey.current.value,
      startDate: startDate.current.value,
      endDate: endDate.current.value,
      hospital: hospital?.value
    };

    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }

    if (limit) {
      values.limit = limit;
    }
    let query = queryString.stringify(values);
    setSearch(query);
  };

  const renderPharmacyAll = () => {
    return pharmacyDeliveryAllCanceled.loading ? (
      <tr>
        <td colSpan={(roleUser == 43 || roleUser == 44) ? "10" : "9"}>
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        {pharmacyDeliveryAllCanceled.orders && pharmacyDeliveryAllCanceled.orders.length > 0 ? (
          pharmacyDeliveryAllCanceled.orders.map((data, index) => (
            <tr key={index}>
              <td>{(pagination.page - 1) * 10 + index + 1}</td>
              <td>{data.orderId ? data.orderId : "-"}</td>
              <td>
                {data.createdAt ? moment(data.createdAt).format("lll") : "-"}
              </td>
              <td>{data.patientName ? data.patientName : "-"}</td>
              <td>
                {data.patientDOB ? moment(data.patientDOB).format("ll") : "-"}
              </td>
              <td>{data.patientPhone ? data.patientPhone : "-"}</td>
              <td>{data.patientAddress ? data.patientAddress : "-"}</td>
              <td>{data.deliveryName ? data.deliveryName : "-"}</td>
              <td>{data.notes ? data.notes  : "-"}</td>
              {(roleUser == 43 || roleUser == 44) && (
              <td>{data.pharmacyName ? data.pharmacyName  : "-"}</td>
              )}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={(roleUser == 43 || roleUser == 44) ? "10" : "9"}>Empty data</td>
          </tr>
        )}
      </>
    );
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Pharmacy Delivery Offline Canceled</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        <div className="row mb15">
            <div className="link-btn">
                <button
                type="button"
                className="btn btn-link no-padding"
                style={{ textDecoration: "none", boxShadow: "none" }}
                data-toggle="collapse"
                data-target="#collapseDetailSchedule"
                >
                Filter By
                <i className="fa fa-angle-down" style={{ marginLeft: "10px" }} />
                </button>
            </div>
            <div className="col">
                <hr />
            </div>
        </div>

        <div className="collapse" id="collapseDetailSchedule">
                <div id="filterForm" className="row filter-by">
                    <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="mb10">Date From</div>
                            <input type="date" className="form-control" ref={startDate} />
                        </div>
                        <div className="col-md-4">
                            <div className="mb10">Date To</div>
                            <input type="date" className="form-control" ref={endDate} />
                        </div>

                        {(roleUser == 43 || roleUser == 44) && (
                        <div className="col-md-4">
                          <div className="mb10">Unit</div>
                          <SelectOptions
                            options={pharmacyDelivery.pharmacies}
                            value={hospital}
                            setValue={setHospital}
                            isClearable={true}
                          />
                        </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="text-right" style={{ marginTop: "25px" }}>
                <button
                type="button"
                className="btn btn-secondary"
                style={{ width: "100px", marginRight: "5px" }}
                onClick={() => handleReset()}
                >
                Reset
                </button>

                <button
                type="button"
                className="btn btn-primary"
                style={{ width: "100px" }}
                onClick={() => handleSearch()}
                >
                Apply
                </button>
            </div>

            <div style={{ width: "100%" }}>
                <hr />
            </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "45px" }}
                >
                  No
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "100px" }}
                >
                  Order Id Pharmacy
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "100px" }}
                >
                  Created At
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Patient Name
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Patient DOB
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Patient Phone
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Patient Address
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Delivery Method
                </th>

                <th rowSpan={2} className="table-header-middle">
                Cancel Reason
                </th>

                {(roleUser == 43 || roleUser == 44) && (
                <th rowSpan={2} className="table-header-middle">
                Unit Name
                </th>
                )}
              </tr>
            </thead>
            <tbody>{renderPharmacyAll()}</tbody>
          </table>
        </div>

        {pharmacyDeliveryAllCanceled.totalData > 10 ? (
          <Pagination
            props={pagination}
            setProps={setPagination}
            onChange={(page, limit) => {
              handlePage(page, limit);
            }}
          />
        ) : null}
      </div>
    </DashboardLayout>
  );
};

export default PharmacyDeliveryAllCanceled;