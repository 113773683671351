import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRegisterPatientOV } from "../../../actions";
import Pagination from "../../../components/Pagination";
import DashboardLayout from "../Layout";
import queryString from "query-string";
import moment from "moment";

const PaymentOfflineVoucher = () => {
  const [search, setSearch] = useState(`&searchKey=&registerDate=`);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const searchKey = useRef("");
  const registerDate = useRef("");
  const paymentOfflineVoucher = useSelector((state) => state.paymentOfflineVoucher);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRegisterPatientOV(search));
  }, [search]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: paymentOfflineVoucher.totalData,
    });
  }, [paymentOfflineVoucher.totalData]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const handleReset = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      page: pagination.page,
      limit: pagination.limit,
    };
    if (searchKey.current.value !== "" || searchKey.current.value === "") {
      values.searchKey = searchKey.current.value = "";
    }
    if (
      registerDate.current.value !== "" ||
      registerDate.current.value === ""
    ) {
      values.registerDate = registerDate.current.value = "";
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleSearch = (page, limit) => {
    let values = {
      searchKey: searchKey.current.value,
      registerDate: registerDate.current.value,
    };
    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }
    if (limit) {
      values.limit = limit;
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const renderPaymentOfflineVoucher = () => {
    return paymentOfflineVoucher.loading ? (
      <tr>
        <td colSpan="7">
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        {paymentOfflineVoucher.data.length > 0 ? (
          paymentOfflineVoucher.data.map((data, index) => (
            <tr key={data.id}>
              <td>{(pagination.page - 1) * 10 + index + 1}</td>
              <td>
                {data.created_at ? moment(data.created_at).format("ll") : "-"}
              </td>
              <td>{data.id ? data.id : "-"}</td>
              <td>{data.patientName ? data.patientName : "-"}</td>
              <td>{data.doctorName ? data.doctorName : "-"}</td>
              <td>{data.status ? data.status : "-"}</td>
              <td>{data.voucherDesc ? data.voucherDesc : "-"}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="7">Empty data</td>
          </tr>
        )}
      </>
    );
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Payment Offline Voucher</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        <div className="row">
          <div className="form-group col-md-4">
            <div className="mb10">Patient Name</div>
            <input
              type="text"
              className="form-control"
              placeholder="Search Patient Name"
              ref={searchKey}
            />
          </div>

          <div className="form-group col-md-4">
            <div className="mb10">Registration Date</div>
            <input type="date" className="form-control" ref={registerDate} />
          </div>

          <div className="form-group text-left col-md-4 mt-auto">
            <button
              type="button"
              className="btn btn-secondary"
              style={{
                width: "100px",
                marginRight: "5px",
              }}
              onClick={() => handleReset()}
            >
              Reset
            </button>

            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100px" }}
              onClick={() => handleSearch()}
            >
              Apply
            </button>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "45px" }}
                >
                  No
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "100px" }}
                >
                  Registration Date
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Order ID
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "150px" }}
                >
                  Patient Name
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "200px" }}
                >
                  Doctor Name
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Status
                </th>  

                <th rowSpan={2} className="table-header-middle">
                  Voucher Description
                </th> 

              </tr>
            </thead>
            <tbody>{renderPaymentOfflineVoucher()}</tbody>
          </table>
        </div>

        {paymentOfflineVoucher.totalData > 10 ? (
          <Pagination
            props={pagination}
            setProps={setPagination}
            onChange={(page, limit) => {
              handleSearch(page, limit);
            }}
          />
        ) : null}
      </div>
    </DashboardLayout>
  );
};

export default PaymentOfflineVoucher;
