import { orderRequestConstants } from "../actions/constants";

const initState = {
  loading: false,
  error: null,
  orders: [],
  allStatus: [],
  totalData: null,
  sendbirdToken: "",
  userId: null,
  order: {},
  RequestTime: {},
  allPartner: [],
  partners: [],
  totalPartner: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case orderRequestConstants.GET_ORDER_REQUEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderRequestConstants.GET_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload.orders,
        totalData: action.payload.totalData,
        allStatus: action.payload.allStatus,
        sendbirdToken: action.payload.sendbirdToken,
        userId: action.payload.userId,
      };
    case orderRequestConstants.GET_ORDER_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case orderRequestConstants.ORDER_REQUEST_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderRequestConstants.ORDER_REQUEST_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        order: action.payload.orders[0],
        RequestTime: action.payload.RequestTime[0],
      };
    case orderRequestConstants.ORDER_REQUEST_DETAIL_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case orderRequestConstants.GET_PARTNERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderRequestConstants.GET_PARTNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        partners: action.payload.partners,
        totalPartner: action.payload.totalPartner,
        allPartner: action.payload.allPartner,
      };
    case orderRequestConstants.GET_PARTNERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case orderRequestConstants.CREATE_ORDER_REQUEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderRequestConstants.CREATE_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case orderRequestConstants.CREATE_ORDER_REQUEST_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    default:
      return state;
  }
};
