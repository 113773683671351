import { healthScreeningConstants } from "../actions/constants";

const initState = {
  orders: [],
  eventLists: [],
  data: [],
  totalData: 0,
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case healthScreeningConstants.GET_ALL_EVENTHS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case healthScreeningConstants.GET_ALL_EVENTHS_SUCCESS:
      state = {
        ...state,
        eventLists: action.payload.eventLists,
        loading: false,
      };
      break;
    case healthScreeningConstants.GET_ALL_EVENTHS_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case healthScreeningConstants.GET_ALL_HS_REQUEST:
        state = {
          ...state,
          loading: true,
        };
        break;
    case healthScreeningConstants.GET_ALL_HS_SUCCESS:
        state = {
          ...state,
          orders: action.payload.data,
          totalData: action.payload.totalData,
          loading: false,
        };
        break;
    case healthScreeningConstants.GET_ALL_HS_FAILURE:
        state = {
          ...state,
          error: action.payload.error,
          loading: false,
        };
    case healthScreeningConstants.CHECKIN_HS_REQUEST:
        state = {
          ...state,
          data: action.payload,
          loading: false,
        };
        break;
    case healthScreeningConstants.CHECKIN_HS_SUCCESS:
        state = {
          ...state,
          loading: true,
        };
        break;
    case healthScreeningConstants.CHECKIN_HS_FAILURE:
        state = {
          ...state,
          error: action.payload.error,
          loading: false,
        };
  }
  return state;
};
