import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import Input from "../../Dashboard/Components/Input";
import Modal from "../../Dashboard/Components/Modal";
import GoogleMap from "../../Dashboard/Components/GoogleMap";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Layout from "../Layout";
import {
  createOtpRegister,
  getCustomerSnorlax,
  initializeCustomer,
  updateCustomerSnorlax,
  verifyOtp,
  verifyOtpEmail,
} from "../../../actions";
import "./register.scss";
import Swal from "sweetalert2";
import validator from "validator";
import OtpInput from "react-otp-input";

const Register = () => {
  const { state } = useLocation();
  const history = useHistory();
  const [newUserData, setNewUserData] = useState({
    fullName: "",
    email: "",
    phone: "",
    emailPhone: "",
    emailPhoneType: "",
    birthday: "",
  });
  const [newProfileData, setNewProfileData] = useState({
    fullName: "",
    email: "",
    phone: "",
    birthday: "",
    gender: "1",
    nationalIdNo: "",
    latitude: "",
    longitude: "",
    addressLine1: "",
    addressLine2: "",
    postalCode: "",
  });
  const [location, setLocation] = useState(null);
  const [otp, setOtp] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [counter, setCounter] = useState(0);
  const [mapModal, setMapModal] = useState(false);
  const testCovid = useSelector((state) => state.testCovid);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if (testCovid.initData) {
      dispatch(getCustomerSnorlax());
    }
  }, [testCovid.initData]);

  if (testCovid.updatedData) {
    history.push({
      pathname: "/test-covid/form",
      state: state,
    });
  }

  const renderInputName = () => {
    return (
      <>
        <div className="form-content-res">
          <div className="form-left-res">
            <div className="middle-res" style={{ marginBottom: "20px" }}>
              <div className="register-logo-res">
                <img
                  data-cfsrc="/img/logo/main-logo.png"
                  src="/img/logo/main-logo.png"
                  alt="aido-logo"
                />
              </div>
              <h4>Hai,</h4>
              <p>Selamat datang di aido health!</p>
            </div>

            <div className="form-group">
              <label>Siapa nama kamu?</label>
              <input
                type="text"
                className="form-control"
                minLength={3}
                maxLength={20}
                placeholder="Masukkan Nama"
                value={newUserData.fullName}
                onChange={(e) =>
                  setNewUserData({ ...newUserData, fullName: e.target.value })
                }
              />

              <table style={{ marginTop: "20px" }}>
                <tbody>
                  <tr>
                    <td>
                      <i
                        className="fa fa-info-circle"
                        style={{ fontSize: "14px", color: "#53575B" }}
                        aria-hidden="true"
                      />
                    </td>
                    <td>
                      <span>
                        Data yang kamu bagikan dijamin keamanannya dan hanya
                        untuk kepentingan administrasi berobat.
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderInputEmailOrPhone = () => {
    return (
      <>
        <div className="form-content-res">
          <div className="form-left-res">
            <div className="middle-res" style={{ marginBottom: "20px" }}>
              <div className="register-logo-res">
                <img
                  data-cfsrc="/img/logo/main-logo.png"
                  src="/img/logo/main-logo.png"
                  alt="aido-logo"
                />
              </div>
              <h4>Hai, {newUserData.fullName}</h4>
              <p>Senang berkenalan dengan kamu!</p>
            </div>

            <div className="form-group">
              <label>
                Masukkan email atau nomor telepon kamu agar kami bisa
                memverifikasi akun kamu
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Masukkan Email atau nomor telepon"
                value={newUserData.emailPhone}
                onChange={(e) =>
                  setNewUserData({
                    ...newUserData,
                    emailPhone: e.target.value,
                  })
                }
              />

              <span style={{ color: "#666", marginTop: "5px" }}>
                Contoh: 08123456789 atau nama@email.com{" "}
              </span>

              <table style={{ marginTop: "20px" }}>
                <tbody>
                  <tr>
                    <td>
                      <i
                        className="fa fa-info-circle"
                        style={{ fontSize: "14px", color: "#53575B" }}
                        aria-hidden="true"
                      />
                    </td>
                    <td>
                      <span>
                        Data yang kamu bagikan dijamin keamanannya dan hanya
                        untuk kepentingan administrasi berobat.
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderOTP = () => {
    if (testCovid.message !== "Success") {
      setActiveStep(1);
    }

    return (
      <>
        <div className="form-content-res">
          <div className="form-left-res">
            <div className="middle-res" style={{ marginBottom: "20px" }}>
              <div className="register-logo-res">
                <img
                  data-cfsrc="/img/logo/main-logo.png"
                  src="/img/logo/main-logo.png"
                  alt="aido-logo"
                />
              </div>
              <h4>
                Verifikasi{" "}
                {newUserData.emailPhoneType == "email"
                  ? "email"
                  : "nomor telepon"}
              </h4>
              <p style={{ fontSize: "12px", lineHeight: "20px" }}>
                Masukkan kode 6 digit aido health yang telah <br /> dikirim ke{" "}
                {newUserData.emailPhone}.
              </p>
            </div>

            <OtpInput
              containerStyle={{
                justifyContent: "center",
                marginTop: "10px",
                marginBottom: "20px",
              }}
              inputStyle={{
                fontSize: "20px",
                fontWeight: 600,
                width: "2em",
                borderRadius: "0px",
                backgroundColor: "rgba(0, 0, 0, 0)",
                border: "0px inset  rgba(0, 0, 0, 0.3)",
                borderBottom: `${
                  otp.length > 5
                    ? "1px solid #007bff"
                    : "1px inset rgba(0, 0, 0, 0.3)"
                }`,
              }}
              focusStyle={{
                borderRadius: 0,
                border: `${
                  testCovid.authenticate
                    ? "0px inset rgba(0, 0, 0, 0.3)"
                    : "0px inset #007bff"
                }`,
                borderBottom: `${
                  testCovid.authenticate
                    ? "1px inset rgba(0, 0, 0, 0.3)"
                    : "1px inset #007bff"
                }`,
                outline: "none",
              }}
              value={otp}
              onChange={setOtp}
              isInputNum={true}
              numInputs={6}
              isDisabled={testCovid.authenticate}
              separator={<span>&nbsp;</span>}
            />

            <table style={{ marginTop: "5px" }}>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center", marginRight: "10px" }}>
                    {counter === 0 ? null : (
                      <span>
                        <b> 00:{counter < 10 ? `0${counter}` : counter}</b>
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  const renderInputbirthday = () => {
    if (!testCovid.accessToken) {
      setActiveStep(2);
    }

    return (
      <>
        <div className="form-content-res">
          <div className="form-left-res">
            <div className="middle-res" style={{ marginBottom: "20px" }}>
              <div className="register-logo-res">
                <img
                  data-cfsrc="/img/logo/main-logo.png"
                  src="/img/logo/main-logo.png"
                  alt="aido-logo"
                />
              </div>
              <h4>Terima kasih!</h4>
              <p>Akun kamu sudah berhasil terdaftar</p>
              <p style={{ fontSize: "12px", lineHeight: "17px" }}>
                Pilih tanggal lahir kamu, agar kami lebih memahami tentang
                kondisi kesehatan kamu
              </p>
            </div>

            <div className="form-group">
              <input
                type="date"
                className="form-control"
                value={newUserData.birthday}
                onChange={(e) =>
                  setNewUserData({ ...newUserData, birthday: e.target.value })
                }
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderProfile = () => {
    if (!testCovid.initData) {
      setActiveStep(3);
    }

    return (
      <>
        <div className="form-content-res">
          <div className="form-left-res">
            <div className="middle-res">
              <span>Lengkapi Profil</span>
            </div>

            <div className="form-group">
              <table style={{ marginBottom: "10px" }}>
                <tbody>
                  <tr>
                    <td>
                      <i
                        className="fa fa-info-circle"
                        style={{ fontSize: "14px", color: "#53575B" }}
                        aria-hidden="true"
                      />
                    </td>
                    <td>
                      <span>
                        Data yang kamu bagikan dijamin keamanannya dan hanya
                        untuk kepentingan administrasi berobat.
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="form-group" style={{ marginBottom: "60px" }}>
                <label style={{ marginTop: "20px" }}>Nama Lengkap</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Masukkan Nama"
                  value={newProfileData.fullName}
                  disabled
                />

                <label style={{ marginTop: "20px" }}>Nomor Telepon</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Masukkan Nomor Telepon"
                  value={newProfileData.phone}
                  disabled={newUserData.phone}
                  onChange={(e) =>
                    setNewProfileData({
                      ...newProfileData,
                      phone: e.target.value,
                    })
                  }
                />

                <label style={{ marginTop: "20px" }}>Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Masukkan Email"
                  value={newProfileData.email}
                  disabled={newUserData.email}
                  onChange={(e) =>
                    setNewProfileData({
                      ...newProfileData,
                      email: e.target.value,
                    })
                  }
                />

                <label style={{ marginTop: "20px" }}>Jenis Kelamin</label>
                <select
                  className="form-control"
                  value={newProfileData.gender}
                  onChange={(e) =>
                    setNewProfileData({
                      ...newProfileData,
                      gender: e.target.value,
                    })
                  }
                >
                  <option value="1">Laki-laki</option>
                  <option value="2">Perempuan</option>
                </select>

                <label style={{ marginTop: "20px" }}>Tanggal Lahir</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Masukkan Tanggal Lahir"
                  value={newProfileData.birthday}
                  onChange={(e) =>
                    setNewProfileData({
                      ...newProfileData,
                      birthday: e.target.value,
                    })
                  }
                />

                <label style={{ marginTop: "20px" }}>
                  Nomor KTP/SIM/PASSPORT
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Masukkan Nomor KTP/SIM/PASSPORT"
                  value={newProfileData.nationalIdNo}
                  onChange={(e) =>
                    setNewProfileData({
                      ...newProfileData,
                      nationalIdNo: e.target.value,
                    })
                  }
                />

                <label style={{ marginTop: "20px" }}>Alamat Sesuai KTP</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Masukkan Alamat"
                  value={newProfileData.addressLine1}
                  onClick={() => setMapModal(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleData = (addressData) => {
    setLocation({
      latitude: addressData.latitude,
      longitude: addressData.longitude,
      fullAddress: addressData.fullAddress,
      postalCode: addressData.postalCode,
    });
  };

  const actionSetLocation = () => {
    if (location) {
      setNewProfileData({
        ...newProfileData,
        latitude: location.latitude,
        longitude: location.longitude,
        addressLine1: location.fullAddress,
        postalCode: location.postalCode,
      });
    }
    setMapModal(false);
  };

  const renderMap = () => {
    return (
      <Modal
        modalTitle="Cari Lokasi"
        show={mapModal}
        size="lg"
        handleClose={() => setMapModal(false)}
        buttons={[
          {
            label: "Apply",
            color: "primary",
            onClick: actionSetLocation,
          },
        ]}
      >
        <Row>
          <Col md style={{ height: "350px" }}>
            <GoogleMap addressData={(addressData) => handleData(addressData)} />
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Alamat</label>
              <div className="input-group">
                <span>{location ? location.fullAddress : "-"}</span>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="text"
              label="Detail Alamat"
              placeholder="Nomor rumah, apartement, dll"
              value={newProfileData.addressLine2}
              onChange={(e) =>
                setNewProfileData({
                  ...newProfileData,
                  addressLine2: e.target.value,
                })
              }
            />
          </Col>
        </Row>
      </Modal>
    );
  };

  const handleNext = () => {
    var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    var form = new FormData();

    if (activeStep === 0) {
      if (!newUserData.fullName) {
        return Swal.fire({
          icon: "error",
          text: "Nama tidak boleh kosong!",
          showConfirmButton: false,
          timer: 2000,
        });
      } else if (newUserData.fullName.length < 3) {
        return Swal.fire({
          icon: "error",
          text: "Nama kurang dari 3 karakter!",
          showConfirmButton: false,
          timer: 2000,
        });
      } else if (format.test(newUserData.fullName)) {
        return Swal.fire({
          icon: "error",
          text: "Tidak boleh ada spesial karakter!",
          showConfirmButton: false,
          timer: 2000,
        });
      }
      setActiveStep(1);
    }

    if (activeStep === 1) {
      if (!newUserData.emailPhone) {
        return Swal.fire({
          icon: "error",
          text: "Email atau Nomor telepon tidak boleh kosong!",
          showConfirmButton: false,
          timer: 2000,
        });
      } else if (validator.isEmail(newUserData.emailPhone)) {
        setNewUserData({
          ...newUserData,
          emailPhoneType: "email",
          email: newUserData.emailPhone,
        });
        form.append("email", newUserData.emailPhone);
      } else if (validator.isMobilePhone(newUserData.emailPhone)) {
        setNewUserData({
          ...newUserData,
          emailPhoneType: "phone",
          phone: newUserData.emailPhone,
        });
        form.append("phone", newUserData.emailPhone);
      }

      dispatch(createOtpRegister(form))
        .then(() => {
          setTimeout(() => {
            setCounter(30);
            setActiveStep(2);
          }, 2000);
        })
        .catch((err) => {
          if (err.response) {
            Swal.fire({
              icon: "error",
              text: err.response.data.message,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: err.message,
            });
          }
        });
    }

    if (activeStep === 2) {
      if (otp.length === 6) {
        if (newUserData.email && newUserData.emailPhoneType === "email") {
          const customer = {
            email: newUserData.email,
            otp: otp,
          };

          dispatch(verifyOtpEmail(customer))
            .then(() => {
              setTimeout(() => {
                setActiveStep(3);
              }, 2000);
            })
            .catch((err) => {
              if (err.response) {
                return Swal.fire({
                  icon: "error",
                  text: err.response.data.message,
                });
              } else {
                return Swal.fire({
                  icon: "error",
                  text: err.message,
                });
              }
            });
        } else if (
          newUserData.phone &&
          newUserData.emailPhoneType === "phone"
        ) {
          const customer = {
            phone: newUserData.phone,
            otp: otp,
          };

          dispatch(verifyOtp(customer))
            .then(() => {
              setTimeout(() => {
                setActiveStep(3);
              }, 2000);
            })
            .catch((err) => {
              if (err.response) {
                return Swal.fire({
                  icon: "error",
                  text: err.response.data.message,
                });
              } else {
                return Swal.fire({
                  icon: "error",
                  text: err.message,
                });
              }
            });
        }
      } else {
        return Swal.fire({
          icon: "error",
          text: "OTP tidak sesuai!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    }

    if (activeStep === 3) {
      if (!newUserData.birthday) {
        return Swal.fire({
          icon: "error",
          text: "Tanggal lahir tidak boleh kosong!",
          showConfirmButton: false,
          timer: 2000,
        });
      }

      const data = new FormData();

      data.append("otp", newUserData.emailPhoneType);
      data.append("fullName", newUserData.fullName);
      data.append("birthday", newUserData.birthday);
      data.append("referBy", null);

      dispatch(initializeCustomer(data))
        .then(() => {
          setTimeout(() => {
            setActiveStep(4);
            setNewProfileData({
              ...newProfileData,
              fullName: newUserData.fullName,
              email: newUserData.email,
              phone: newUserData.phone,
              birthday: newUserData.birthday,
            });

            if (document.getElementsByClassName("header-tc")[0]) {
              document.getElementsByClassName("header-tc")[0].style.display =
                "none";
            }
          }, 2000);
        })
        .catch((err) => {
          if (err.response) {
            return Swal.fire({
              icon: "error",
              text: err.response.data.message,
            });
          } else {
            return Swal.fire({
              icon: "error",
              text: err.message,
            });
          }
        });
    }

    if (activeStep === 4) {
      if (
        !newProfileData.email ||
        !newProfileData.phone ||
        !newProfileData.gender ||
        !newProfileData.birthday ||
        !newProfileData.nationalIdNo ||
        !newProfileData.addressLine1
      ) {
        return Swal.fire({
          icon: "error",
          text: "Semua data harus diisi!",
          showConfirmButton: false,
          timer: 2000,
        });
      }

      const profileData = new FormData();

      profileData.append("childId", testCovid.customerData.profile.patientId);
      if (newUserData.emailPhoneType === "email") {
        profileData.append("phone", newProfileData.phone);
      }
      if (newUserData.emailPhoneType === "phone") {
        profileData.append("email", newProfileData.email);
      }
      profileData.append("gender", newProfileData.gender);
      profileData.append("nationalIdNo", newProfileData.nationalIdNo);
      profileData.append("latitude", newProfileData.latitude);
      profileData.append("longitude", newProfileData.longitude);
      profileData.append("addressLine1", newProfileData.addressLine1);
      profileData.append("addressLine2", newProfileData.addressLine2);
      profileData.append("postalCode", newProfileData.postalCode);

      dispatch(updateCustomerSnorlax(profileData)).catch((err) => {
        if (err.response) {
          return Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          return Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
    }
  };

  const style = createMuiTheme({
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: "#f7f8fc",
        },
      },
      MuiStepIcon: {
        root: {
          "&$active": {
            color: "#38a8ff",
          },
          "&$completed": {
            color: "#38a8ff",
          },
        },
      },
      MuiStepLabel: {
        label: {
          "&$active": {
            color: "#38a8ff",
          },
          "&$completed": {
            color: "#38a8ff",
          },
        },
      },
    },
  });

  const getLabel = () => {
    return ["1", "2", "3"];
  };

  return (
    <Layout link="/test-covid/detail" state={state}>
      <section className="sign-page-res">
        <div className="sign-container-res">
          {activeStep === 0 && renderInputName()}
          {activeStep === 1 && renderInputEmailOrPhone()}
          {activeStep === 2 && renderOTP()}
          {activeStep === 3 && renderInputbirthday()}
          {activeStep === 4 && renderProfile()}

          <div
            style={{
              padding: "10px",
            }}
          >
            {activeStep !== 2 && activeStep !== 4 ? (
              <ThemeProvider theme={style}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {getLabel().map((label) => (
                    <Step key={label}>
                      <StepLabel></StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </ThemeProvider>
            ) : activeStep === 4 ? (
              <></>
            ) : (
              <table style={{ marginBottom: "20px" }}>
                <tbody>
                  <tr>
                    <td style={{ float: "left" }}>
                      <button
                        type="button"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          color: "#38a8ff",
                          border: "none",
                          backgroundColor: "#f7f8fc",
                        }}
                      >
                        Masuk via{" "}
                        {newUserData.emailPhoneType === "email"
                          ? "nomor telepon"
                          : "email"}
                      </button>
                    </td>
                    <td style={{ float: "right" }}>
                      {counter === 0 ? (
                        <button
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            color: "#38a8ff",
                            border: "none",
                            backgroundColor: "#f7f8fc",
                          }}
                          onClick={() => setCounter(30)}
                        >
                          Minta kode lagi
                        </button>
                      ) : (
                        <button
                          type="button"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            color: "grey",
                            border: "none",
                            backgroundColor: "#f7f8fc",
                          }}
                        >
                          Minta kode lagi
                        </button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}

            {activeStep === 3 ? (
              <p
                style={{
                  fontSize: "12px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                Dengan daftar, kamu menyetujui
                <a
                  href="https://aido.id/terms-of-service"
                  target="_blank"
                  style={{ color: "#38a8ff" }}
                >
                  {" "}
                  Syarat & Ketentuan{" "}
                </a>{" "}
                dan
                <a
                  href="https://aido.id/privacy-policy"
                  target="_blank"
                  style={{ color: "#38a8ff" }}
                >
                  {" "}
                  Kebijakan Privasi{" "}
                </a>
                yang berlaku di aido health.
              </p>
            ) : (
              <></>
            )}

            <button
              type="button"
              className="btn-block btn btn-primary"
              onClick={() => handleNext()}
            >
              {activeStep === 2
                ? "Verifikasi"
                : activeStep === 4
                ? "Simpan"
                : "Selanjutnya"}
            </button>
          </div>
        </div>
      </section>
      {renderMap()}
    </Layout>
  );
};

export default Register;
