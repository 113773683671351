import React, { useState } from "react";
import "../../Login/login.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import AidoLogo from "../../../assets/img/logo/main-logo.png"
import BackIcon from "../../../assets/img/icon/ic_arrow_back.png"
import { forgotPassword } from "../../../actions/user.actions";
import Swal from 'sweetalert2';

const ForgotPassword = () => {
    let history = useHistory();
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("hospital");
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const result = useSelector((state) => state.userProfile);

    const submitForgotPassword = (e) => {
        e.preventDefault();
        const user = {
            email,
            role
        };
        setLoading(true);
        dispatch(forgotPassword(user))
            .then((res) => {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    text: 'Forgot password email successfully sent.',
                    timer: 1000
                })
                history.replace("/login")
            }).catch((error) => {
                setLoading(false);
                if (error.response) {              
                        Swal.fire({
                            icon: 'error',
                            text: error.response.data.message,
                        })
                        .then((isConfirm) =>{
                            if (isConfirm) {
                                history.push("/forgot-password");
                            } 
                        }
                        )

                } else {
                    // Something happened in setting up the request that triggered an Error
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.message,
                    })
                }
            });
    };
    return (
        <div className="row" style={{ width: '100%' }}>
            <div className="col-md-7 no-padding container-login-form-1" >
            <div className="login-form-1" style={{ backgroundSize: 'cover', background: `url(/banner1.jpg)` }}>
                    <div className="login-title-container">
                        <h3>Connecting Healthcare to Your Home</h3>
                    </div>
                </div>
            </div>
            <div className="col-md-5 login-form-2">
                <div className="login-logo">
                    <a>
                        <img src={AidoLogo} alt="aido-logo"/>
                    </a>
                </div>
                <div style={{ height: '100%' }}>
                    <div className="wrapper-btn-back">
                        <Link to="/login">
                            <img src={BackIcon} height="20px" alt="icon-back"/>
                        </Link>
                    </div>
                    <div>
                        <h3 className="mb-2">Forgot Password</h3>
                        <span className="subtitle-span mb-1" style={{ fontSize: '15px'}}>Plese input your email, we'll send you link for reset password</span>
                    </div>
                    <form onSubmit={submitForgotPassword} >
                        <div className="form-group">
                            <label className="pure-material-textfield-outlined">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name="email" 
                                    placeholder="Email Address" 
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)} 
                                    required
                                    />
                            </label>
                        </div>
                        <input type="hidden" name="role" placeholder=" " value={role} />
                        
                        <button 
                            className="btnSubmit" 
                            type="submit" 
                            disabled={loading}>
                            {loading ? "Loading..." : "FORGOT PASSWORD"}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;