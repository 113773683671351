import React, { Fragment, useEffect, useState } from "react";
import MaterialTable from "material-table";
import DashboardLayout from "../../../Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  getVaccinationEvent,
  deleteEvent,
  getResultOrderVaccine,
} from "../../../../../actions";
import Loading from "../../../../../components/Loading";
import moment from "moment";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";

const VaccinationEventDetail = () => {
  const myVaccinationEvent = useSelector((state) => state.myVaccinationEvent);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { loading, error } = myVaccinationEvent;

  useEffect(() => {
    dispatch(getVaccinationEvent(state));
  }, []);

  const removeEvent = () => {
    if (state !== null || state !== "") {
      Swal.fire({
        title: "Reconfirmation",
        text: "Are you sure want to delete this event?",
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#58A8F8",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          dispatch(deleteEvent(state)).then((res) => {
            if (res) {
              dispatch(getResultOrderVaccine(state));
            }
            Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: false,
            }).fire({
              icon: "success",
              title: "deleted successfully.",
            });
            setTimeout(
              () => (window.location = "/waiting-invoice-teleconsultation"),
              1000
            );
          });
        }
      });
    }
  };

  let event = myVaccinationEvent.event;

  return (
    <DashboardLayout>
      {loading ? (
        <div className="table-wrapper">
          <Loading />
        </div>
      ) : error ? (
        error.message
      ) : (
        <Fragment>
          <div className="table-wrapper">
            <div className="alert alert-info" style={{ textAlign: "center" }}>
              <p style={{ fontSize: "15px", marginBottom: "10px" }}>
                Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
                aido, silahkan hubungi aido support dengan click chat aido
                support.
                <br />
                <a
                  href="https://wa.me/628118481436"
                  style={{ color: "#51BED3" }}
                  target="blank"
                >
                  Chat Aido Support
                </a>
              </p>
            </div>

            <div className="wrapper-form-title d-flex justify-content-between">
              <span className="form-title">Vaccination Information</span>
              <button
                className="btn btn-outline-primary px-5"
                onClick={removeEvent}
              >
                Remove Event
              </button>
            </div>
            <div className="mb-4">
              <div className="row mb-2">
                <div className="col-4">Event Name</div>
                <div
                  className="col font-weight-600"
                  id="form2--submitted-data-fullName"
                >
                  : <b>{event.eventName}</b>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">Vaccine Type</div>
                <div
                  className="col font-weight-600"
                  id="form2--submitted-data-vaccineType"
                >
                  : <b>{event.vaccineType}</b>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">Location</div>
                <div
                  className="col font-weight-600"
                  id="form2--submitted-data-fulladdress"
                >
                  : <b>{event.location}</b>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">Company Name</div>
                <div
                  className="col font-weight-600"
                  id="form2--submitted-data-companyName"
                >
                  : <b>{event.companyName}</b>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">Start Date</div>
                <div
                  className="col font-weight-600"
                  id="form2--submitted-data-startDate"
                >
                  : <b>{event.startDate}</b>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">End Date</div>
                <div
                  className="col font-weight-600"
                  id="form2--submitted-data-endDate"
                >
                  : <b>{event.endDate}</b>
                </div>
              </div>
            </div>

            <div className="wrapper-form-title mb-3 d-flex justify-content-between">
              <span className="form-title">
                Employee Data (
                {event.employeeData ? event.employeeData.length : null})
              </span>
            </div>

            <MaterialTable
              minRows={10}
              columns={[
                {
                  title: "No",
                  field: "index",
                  width: null,
                  cellStyle: { width: 45 },
                  headerStyle: { tableLayout: "auto" },
                },
                { title: "Full Name", field: "full_name" },
                { title: "DOB", field: "dob" },
                { title: "NIK", field: "nik" },
                { title: "Email", field: "email" },
              ]}
              data={
                event.employeeData
                  ? event.employeeData.map((item, index) => ({
                      index: index + 1,
                      full_name: item.full_name,
                      dob: moment(item.dob).format("YYYY-MM-DD"),
                      nik: item.nik,
                      email: item.email,
                    }))
                  : null
              }
              options={{
                search: true,
                headerStyle: {
                  backgroundColor: "rgba(57,169,255,.18)",
                  color: "#39a9ff",
                  boxSizing: "border-box",
                  border: "1px solid #cfd4d9",
                  fontFamily: "source sans pro",
                  fontWeight: 700,
                  width: 45,
                  padding: ".75rem",
                },
                rowStyle: {
                  border: "1px solid #cfd4d9",
                  height: 43,
                  padding: ".75rem",
                },
                cellStyle: {
                  border: "1px solid #cfd4d9",
                  padding: ".75rem",
                },
                searchFieldStyle: {
                  fontFamily: "source sans pro",
                },
                showTitle: false,
                tableLayout: "auto",
                pageSize: event.employeeData
                  ? event.employeeData.length < 10
                    ? event.employeeData.length
                    : 10
                  : null,
                pageSizeOptions: event.employeeData
                  ? event.employeeData.length < 10
                    ? [event.employeeData.length, 10, 25, 50, 100]
                    : [10, 25, 50, 100]
                  : [10, 25, 50, 100],
              }}
              style={{
                color: "#212529",
                boxShadow: "none",
              }}
            />
          </div>
        </Fragment>
      )}
    </DashboardLayout>
  );
};

export default VaccinationEventDetail;
