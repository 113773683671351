import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Layout from "../Layout";
import Select from "react-select";
import queryString from "query-string";
import { getCovidAreaList, getCovidList } from "../../../actions";
import "./index.scss";

const List = () => {
  const { search } = useLocation();
  const [searchFilter, setSearchFilter] = useState("serviceType=1");
  const [filter, setFilter] = useState({
    area: null,
    type: "1",
  });
  const [link, setLink] = useState("aidoapp://aido.id/deepLink/home");
  const testCovid = useSelector((state) => state.testCovid);
  const dispatch = useDispatch();
  const { loading, error, listData, areaListData } = testCovid;

  const areaData = areaListData.areaLayanan.map((data, index) => {
    return { id: index + 1, name: data };
  });

  useEffect(() => {
    if (search) {
      localStorage.setItem("accessToken", queryString.parse(search).token);
    }

    const getToken = localStorage.getItem("accessToken");

    if (getToken) {
      setLink(`aidoapp://aido.id/deepLink/home?token=${getToken}`);
    }
  }, []);

  useEffect(() => {
    dispatch(getCovidAreaList());
  }, []);

  useEffect(() => {
    dispatch(getCovidList(searchFilter));
  }, [searchFilter]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const filterSearch = (data) => {
    setFilter({
      ...filter,
      area: data,
    });

    let values = {};

    if (data != null) {
      values.searchKey = data.name;
    }

    if (filter.type != "") {
      values.serviceType = filter.type;
    }

    let query = queryString.stringify(values);
    setSearchFilter(query);
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (
    <Layout title="Test Covid - 19" link={link} target>
      <div className="search-res">
        <Select
          isClearable
          isSearchable
          placeholder={"Pilih Lokasi"}
          options={areaData}
          onChange={filterSearch}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.name}
        />
      </div>

      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          {listData.packages.length > 0 ? (
            listData.packages.map((data, index) => (
              <div className="col-res" key={index}>
                <Link
                  to={{
                    pathname: "/test-covid/detail",
                    state: {
                      id: data.id,
                      packageServiceId: data.package_service_id,
                      partnerId: data.partner_id,
                      price: data.price,
                    },
                  }}
                >
                  <div className="card-res">
                    <div className="img-res">
                      <img
                        src={data.imgUrl}
                        style={{
                          height: "100%",
                          width: "120px",
                          borderRadius: "5px 0 0 5px",
                        }}
                      />
                    </div>
                    <div className="content-res">
                      <span style={{ fontSize: "20px", color: "#0077ff" }}>
                        Homevisit
                      </span>

                      <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                        {`${data.packageName} ${data.hospitalName}`}
                      </span>

                      <span className="dot-res" style={{ fontSize: "14px" }}>
                        {`Area: ${data.area_layanan ? data.area_layanan : "-"}`}
                      </span>

                      <span style={{ fontSize: "18px", color: "#0077ff" }}>
                        {data.price ? formatter.format(data.price) : "IDR 0"}
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <div className="col-res">
              <p>Empty data</p>
            </div>
          )}
        </div>
      )}
    </Layout>
  );
};

export default List;
