import authReducer from "./auth.reducers";
import myTeamReducer from "./myTeam.reducers";
import myPromoReducers from "./myPromo.reducers";
import educationReducer from "./education.reducers";
import myScheduleReducer from "./mySchedule.reducers";
import dailyReportReducer from "./dailyReport.reducers";
import orderByIdReducer from "./orderById.reducers";
import teleRefundReducer from "./teleRefund.reducers";
import teleRescheduleReducers from "./teleReschedule.reducers";
import waitingTeleReducers from "./waitingTele.reducers";
import paymentReportReducers from "./paymentReport.reducers";
import monthlyReportReducer from "./monthlyReport.reducers";
import waitingInvoiceReducer from "./waitingInvoice.reducers";
import myOrderReducers from "./myOrder.reducers";
import orderTeleReducers from "./orderTele.reducers";
import orderRequestReducers from "./orderRequest.reducers";
import userReducers from "./user.reducers";
import registerReducer from "./register.reducers";
import orderDailyReportReducer from "./orderDailyReport.reducers";
import orderVaccineReducers from "./orderVaccine.reducers";
import myVaccinationEventReducers from "./myVaccinationEvent.reducers";
import scheduleVaccineReducers from "./scheduleVaccine.reducers";
import myMedicalReducers from "./myMedical.reducers";
import myProceduresReducers from "./myProcedures.reducers";
import dokterWorklistReducers from "./dokterWorklist.reducers";
import orderHistoryReducers from "./orderHistory.reducers";
import orderPharmacyReducers from "./orderPharmacy.reducers";
import paymentOfflineReducers from "./paymentOffline.reducers";
import paymentOfflineVoucherReducers from "./paymentOfflineVoucher.reducers";
import pharmacyDeliveryAllReducers from "./pharmacyDeliveryAll.reducers";
import pharmacyDeliveryAllCanceledReducers from "./pharmacyDeliveryAllCanceled.reducers";
import pharmacyDeliveryReducers from "./pharmacyDelivery.reducers";
import pharmacyWaitingDeliveryReducers from "./pharmacyWaitingDelivery.reducers";
import pharmacyDeliveryDoneReducers from "./pharmacyDeliveryDone.reducers";
import pharmacyDeliveryAllUnitReducers from "./pharmacyDeliveryAllUnit.reducers";
import testCovidReducer from "./testCovid.reducers";
import gpOnsiteReducer from "./gponsite.reducers";
import orderMedicalRecordsReducers from "./orderMedicalRecords.reducers";
import healthScreeningReducers from "./healthScreening.reducers";
import addressReducers from "./address.reducers";
import configReducers from "./config.reducers";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  auth: authReducer,
  myTeam: myTeamReducer,
  myPromo: myPromoReducers,
  education: educationReducer,
  mySchedule: myScheduleReducer,
  dailyReport: dailyReportReducer,
  orderById: orderByIdReducer,
  teleRefund: teleRefundReducer,
  teleReschedule: teleRescheduleReducers,
  completedCallTele: waitingTeleReducers,
  paymentReport: paymentReportReducers,
  monthlyReport: monthlyReportReducer,
  waitingInvoice: waitingInvoiceReducer,
  myOrder: myOrderReducers,
  orderTele: orderTeleReducers,
  orderRequest: orderRequestReducers,
  register: registerReducer,
  orderDailyReport: orderDailyReportReducer,
  userProfile: userReducers,
  orderVaccine: orderVaccineReducers,
  myVaccinationEvent: myVaccinationEventReducers,
  scheduleVaccine: scheduleVaccineReducers,
  myMedical: myMedicalReducers,
  myProcedures: myProceduresReducers,
  dokterWorklist: dokterWorklistReducers,
  orderHistory: orderHistoryReducers,
  orderPharmacy: orderPharmacyReducers,
  paymentOffline: paymentOfflineReducers,
  paymentOfflineVoucher: paymentOfflineVoucherReducers,
  pharmacyDeliveryAll: pharmacyDeliveryAllReducers,
  pharmacyDeliveryAllCanceled: pharmacyDeliveryAllCanceledReducers,
  pharmacyDelivery: pharmacyDeliveryReducers,
  pharmacyWaitingDelivery: pharmacyWaitingDeliveryReducers,
  pharmacyDeliveryDone: pharmacyDeliveryDoneReducers,
  pharmacyDeliveryAllUnit: pharmacyDeliveryAllUnitReducers,
  testCovid: testCovidReducer,
  gpOnsite: gpOnsiteReducer,
  orderMedicalRecords: orderMedicalRecordsReducers,
  healthScreening: healthScreeningReducers,
  address: addressReducers,
  config: configReducers,
});

export default rootReducer;
