import axios from "../helpers/axios";
import { myMedicalConstants } from "./constants";

export const getAllMedical = (search) => {
  return async (dispatch) => {
    dispatch({ type: myMedicalConstants.GET_ALL_MEDICAL_REQUEST });
    const res = await axios.get(`/MedicalSupply?${search}`);
    if (res.status === 200) {
      dispatch({
        type: myMedicalConstants.GET_ALL_MEDICAL_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: myMedicalConstants.GET_ALL_MEDICAL_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getMedicalById = (id) => {
  return async (dispatch) => {
    dispatch({ type: myMedicalConstants.GET_MEDICAL_BY_ID_REQUEST });
    const res = await axios.get(`/MedicalSupply/${id}`);
    if (res.status === 200) {
      dispatch({
        type: myMedicalConstants.GET_MEDICAL_BY_ID_SUCCESS,
        payload: { medicalData: res.data },
      });
    } else {
      dispatch({
        type: myMedicalConstants.GET_MEDICAL_BY_ID_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const addMedical = (form, search) => {
  return async (dispatch) => {
    dispatch({ type: myMedicalConstants.ADD_NEW_MEDICAL_REQUEST });
    const res = await axios.post("/MedicalSupply", form);
    if (res.status === 200) {
      dispatch({
        type: myMedicalConstants.ADD_NEW_MEDICAL_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllMedical(search));
    } else {
      dispatch({
        type: myMedicalConstants.ADD_NEW_MEDICAL_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const updateMedical = (id, form, search) => {
  return async (dispatch) => {
    dispatch({ type: myMedicalConstants.UPDATE_MEDICAL_REQUEST });
    const res = await axios.put(`/MedicalSupply/${id}`, form);
    if (res.status === 200) {
      dispatch({
        type: myMedicalConstants.UPDATE_MEDICAL_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllMedical(search));
    } else {
      dispatch({
        type: myMedicalConstants.UPDATE_MEDICAL_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const deleteMedical = (id, search) => {
  return async (dispatch) => {
    dispatch({ type: myMedicalConstants.DELETE_MEDICAL_REQUEST });
    const res = await axios.delete(`/MedicalSupply/${id}`, {
      payload: {
        id,
      },
    });
    if (res.status === 200) {
      dispatch({ type: myMedicalConstants.DELETE_MEDICAL_SUCCESS });
      dispatch(getAllMedical(search));
    } else {
      dispatch({
        type: myMedicalConstants.DELETE_MEDICAL_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
