import axios from "../helpers/axiosPartner";
import { orderHistoryConstants } from "./constants";

export const getOrderAktif = (search) => {
  return async (dispatch) => {
    dispatch({ type: orderHistoryConstants.GET_AKTIF_ORDERHISTORY_REQUEST });
    const res = await axios.get(`/App/Partner/OrderActive?${search}`);
    if (res.status === 200) {
      dispatch({
        type: orderHistoryConstants.GET_AKTIF_ORDERHISTORY_SUCCESS,
        payload: res.data.orders.sort((d1, d2) =>
          new Date(d1.scheduleDate).toDateString() !=
          new Date(d2.scheduleDate).toDateString()
            ? new Date(
                new Date(d1.scheduleDate).toDateString() + " " + d2.startTime
              ) -
              new Date(
                new Date(d2.scheduleDate).toDateString() + " " + d1.startTime
              )
            : new Date(
                new Date(d2.scheduleDate).toDateString() + " " + d1.startTime
              ) -
              new Date(
                new Date(d1.scheduleDate).toDateString() + " " + d2.startTime
              )
        ),
      });
    } else {
      dispatch({
        type: orderHistoryConstants.GET_AKTIF_ORDERHISTORY_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getOrderDone = (search) => {
  return async (dispatch) => {
    dispatch({ type: orderHistoryConstants.GET_DONE_ORDERHISTORY_REQUEST });

    const res = await axios.get(`/App/Partner/OrderDone?${search}`);
    if (res.status === 200) {
      dispatch({
        type: orderHistoryConstants.GET_DONE_ORDERHISTORY_SUCCESS,
        payload: res.data.orders.sort((d1, d2) =>
          new Date(d1.scheduleDate).toDateString() !=
          new Date(d2.scheduleDate).toDateString()
            ? new Date(
                new Date(d1.scheduleDate).toDateString() + " " + d2.startTime
              ) -
              new Date(
                new Date(d2.scheduleDate).toDateString() + " " + d1.startTime
              )
            : new Date(
                new Date(d2.scheduleDate).toDateString() + " " + d1.startTime
              ) -
              new Date(
                new Date(d1.scheduleDate).toDateString() + " " + d2.startTime
              )
        ),
      });
    } else {
      dispatch({
        type: orderHistoryConstants.GET_DONE_ORDERHISTORY_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getOrderHistoryDetail = (orderId) => {
  return async (dispatch) => {
    dispatch({ type: orderHistoryConstants.GET_DETAIL_ORDERHISTORY_REQUEST });
    const res = await axios.get(`/Order/` + orderId);
    if (res.status === 200) {
      dispatch({
        type: orderHistoryConstants.GET_DETAIL_ORDERHISTORY_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: orderHistoryConstants.GET_DETAIL_ORDERHISTORY_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getListRiwayatKesehatan = (custId, patientId) => {
  return async (dispatch) => {
    dispatch({ type: orderHistoryConstants.GET_LIST_RIWAYAT_REQUEST });
    // const res = await axios.get('/App/Partner/Me/Order/'+patientId+'/MedicalRecord');
    const res = await axios.get(
      "/Order?customer=" +
        custId +
        "&patient=" +
        patientId +
        "&isMedicalRecord=true&orderType=1"
    );
    if (res.status === 200) {
      dispatch({
        type: orderHistoryConstants.GET_LIST_RIWAYAT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: orderHistoryConstants.GET_LIST_RIWAYAT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getDetailRiwayatKesehatan = (orderId) => {
  return async (dispatch) => {
    dispatch({ type: orderHistoryConstants.GET_DETAIL_RIWAYAT_REQUEST });
    const res = await axios.get("/Order/" + orderId);
    if (res.status === 200) {
      dispatch({
        type: orderHistoryConstants.GET_DETAIL_RIWAYAT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: orderHistoryConstants.GET_DETAIL_RIWAYAT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getAdditionalService = (formData) => {
  return async (dispatch) => {
    dispatch({ type: orderHistoryConstants.GET_ADDITIONAL_SERVICE_REQUEST });
    // const res = await axios.get('/App/Partner/Me/Order/'+orderId+'/OrderProduct');
    const res = await axios.post("/App/Partner/Me/findAdditional", formData);
    if (res.status === 200) {
      dispatch({
        type: orderHistoryConstants.GET_ADDITIONAL_SERVICE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: orderHistoryConstants.GET_ADDITIONAL_SERVICE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const createOrderProducts = (productName) => {
  const formData = new FormData();
  formData.append("productName", productName);

  return async (dispatch) => {
    dispatch({ type: orderHistoryConstants.CREATE_ORDER_PRODUCT_REQUEST });
    const res = await axios.post("/App/Partner/Me/Product", formData);
    if (res.status === 200) {
      dispatch({
        type: orderHistoryConstants.CREATE_ORDER_PRODUCT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: orderHistoryConstants.CREATE_ORDER_PRODUCT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const updateOrderProducts = (orderId, productData) => {
  const formData = new FormData();
  formData.append("products", productData);

  return async (dispatch) => {
    dispatch({ type: orderHistoryConstants.UPDATE_ORDER_PRODUCT_REQUEST });
    const res = await axios.post(
      "/App/Partner/Me/Order/" + orderId + "/OrderProduct",
      formData
    );
    if (res.status === 200) {
      dispatch({
        type: orderHistoryConstants.UPDATE_ORDER_PRODUCT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: orderHistoryConstants.UPDATE_ORDER_PRODUCT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const createMedicalRecords = (orderId, medicalRecordData) => {
  return async (dispatch) => {
    dispatch({ type: orderHistoryConstants.CREATE_MEDICAL_RECORD_REQUEST });
    const res = await axios.post(
      "/App/Partner/Me/Order/" + orderId + "/MedicalRecord",
      medicalRecordData
    );
    if (res.status === 200) {
      dispatch({
        type: orderHistoryConstants.CREATE_MEDICAL_RECORD_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: orderHistoryConstants.CREATE_MEDICAL_RECORD_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const updateStatusOrderProducts = (orderId, statusCode) => {
  const formData = new FormData();
  formData.append("orderId", orderId);
  formData.append("status", statusCode);
  return async (dispatch) => {
    dispatch({ type: orderHistoryConstants.UPDATE_STATUS_ORDER_REQUEST });
    const res = await axios.post(
      "/App/Partner/Me/Order/UpdateStatusOrder",
      formData
    );
    if (res.status === 200) {
      dispatch({
        type: orderHistoryConstants.UPDATE_STATUS_ORDER_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: orderHistoryConstants.UPDATE_STATUS_ORDER_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const uploadConsentFile = (orderId, file) => {
  const formData = new FormData();
  formData.append("file", file);

  return async (dispatch) => {
    dispatch({ type: orderHistoryConstants.UPLOAD_CONSENT_REQUEST });
    const res = await axios.post(
      "/App/Partner/Me/Order/" + orderId + "/MedicalRecord/UploadConsent",
      formData
    );
    if (res.status === 200) {
      dispatch({
        type: orderHistoryConstants.UPLOAD_CONSENT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: orderHistoryConstants.UPLOAD_CONSENT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const uploadPharmacyFile = (orderId, file) => {
  const formData = new FormData();
  formData.append("file", file);

  return async (dispatch) => {
    dispatch({ type: orderHistoryConstants.UPLOAD_PHARMACY_REQUEST });
    const res = await axios.post(
      "/App/Partner/Me/Order/" + orderId + "/MedicalRecord/UploadPharmacy",
      formData
    );
    if (res.status === 200) {
      dispatch({
        type: orderHistoryConstants.UPLOAD_PHARMACY_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: orderHistoryConstants.UPLOAD_PHARMACY_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const uploadOrderGallery = (orderId, file) => {
  const formData = new FormData();
  formData.append("file", file);

  return async (dispatch) => {
    dispatch({ type: orderHistoryConstants.UPLOAD_GALLERY_REQUEST });
    const res = await axios.post(
      "/App/Partner/Me/Order/" + orderId + "/MedicalRecord/UploadGallery",
      formData
    );
    if (res.status === 200) {
      dispatch({
        type: orderHistoryConstants.UPLOAD_GALLERY_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: orderHistoryConstants.UPLOAD_GALLERY_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
