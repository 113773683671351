import { orderDailyReportConstants } from "../actions/constants";

const initState = {
  orders: [],
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case orderDailyReportConstants.FIND_ORDER_DAILY_REPORT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderDailyReportConstants.FIND_ORDER_DAILY_REPORT_SUCCESS:
      state = {
        ...state,
        orders: action.payload.orders,
        loading: false,
      };
      break;
    case orderDailyReportConstants.FIND_ORDER_DAILY_REPORT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
  }
  return state;
};
