import React from 'react';
import Select from 'react-select';

const SelectOptions = ({
  name,
  options,
  value,
  setValue,
  isDisabled,
  isLoading,
  isClearable,
  isSearchable,
  isMulti,
}) => {
  return (
    <React.Fragment>
      <Select
        className="basic-single"
        classNamePrefix="select"
        onChange={setValue}
        value={value || null}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable={isSearchable}
        name={name}
        isMulti={isMulti}
        options={options}
      />
    </React.Fragment>
  );
};

export default SelectOptions;
