import React from "react";

const ThankYou = () => {
  return (
    <div className="row justify-content-md-center" style={{marginTop: '250px', color: 'green'}}>
        <h3>Terima kasih telah mengisi Form Skrining COVID-19</h3>
    </div>
  );
};

export default ThankYou;


