import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../../Layout";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetail, getRelation } from "../../../../actions";
import { useLocation } from "react-router";
import moment from "moment";

const MyOrderDetail = () => {
  const myOrder = useSelector((state) => state.myOrder);
  const register = useSelector((state) => state.register);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { loading, error, orderDetail } = myOrder;

  useEffect(() => {
    dispatch(getOrderDetail(state.orderId));
  }, [state.orderId]);

  useEffect(() => {
    dispatch(getRelation());
  }, []);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (
    <DashboardLayout>
      {loading ? (
        <div
          className="table-wrapper"
          style={{
            marginTop: "20px",
            minHeight: "auto",
          }}
        >
          <Loading />
        </div>
      ) : (
        <>
          <div
            className="table-wrapper"
            style={{
              marginTop: "20px",
              minHeight: "auto",
            }}
          >
            <div className="wrapper-form-title">
              <span className="form-title">Patient Info</span>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Patient Name
                  </span>
                  <span className="col-md-8">
                    {orderDetail.patientName ? orderDetail.patientName : "-"}
                  </span>
                </div>
              </div>

              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Patient Address
                  </span>
                  <span className="col-md-8">
                    {orderDetail.patientAddress
                      ? orderDetail.patientAddress
                      : "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Gender
                  </span>
                  <span className="col-md-8">
                    {orderDetail.patientGender
                      ? orderDetail.patientGender === 1
                        ? "Male"
                        : "Female"
                      : "-"}
                  </span>
                </div>
              </div>

              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Complaints
                  </span>
                  <span className="col-md-8">
                    {orderDetail.complaints ? orderDetail.complaints : "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Date of Birth
                  </span>
                  <span className="col-md-8">
                    {orderDetail.patientBirthday
                      ? moment(orderDetail.patientBirthday).format("LL")
                      : "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Age
                  </span>
                  <span className="col-md-8">
                    {orderDetail.patientBirthday
                      ? new Date().getFullYear() -
                        new Date(orderDetail.patientBirthday).getFullYear()
                      : "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Phone Number
                  </span>
                  <span className="col-md-8">
                    {orderDetail.patientPhone
                      ? `+62${orderDetail.patientPhone}`
                      : "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Email
                  </span>
                  <span className="col-md-8">
                    {orderDetail.patientEmail ? orderDetail.patientEmail : "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="table-wrapper"
            style={{
              marginTop: "20px",
              minHeight: "auto",
            }}
          >
            <div className="wrapper-form-title">
              <span className="form-title">Responsible Info</span>
            </div>

            <div className="row">
              <div className="form-group col-md-4">
                <div className="row">
                  <span className="col-md-6" style={{ fontWeight: "bold" }}>
                    Responsible Name
                  </span>
                  <span className="col-md-6">
                    {orderDetail.responsibleName
                      ? orderDetail.responsibleName
                      : "-"}
                  </span>
                </div>
              </div>

              <div className="form-group col-md-4">
                <div className="row">
                  <span className="col-md-6" style={{ fontWeight: "bold" }}>
                    Phone Number
                  </span>
                  <span className="col-md-6">
                    {orderDetail.responsiblePhone
                      ? `+62${orderDetail.responsiblePhone}`
                      : "-"}
                  </span>
                </div>
              </div>

              <div className="form-group col-md-4">
                <div className="row">
                  <span className="col-md-6" style={{ fontWeight: "bold" }}>
                    Relationship
                  </span>
                  <span className="col-md-6">
                    {orderDetail.relation
                      ? register.relation.data
                          .filter((x) => x.id == orderDetail.relation)
                          .map((x) => x.relationMapping)
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="table-wrapper"
            style={{
              marginTop: "20px",
              minHeight: "auto",
            }}
          >
            <div className="wrapper-form-title">
              <span className="form-title">{`Order Details #${state.orderId}`}</span>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Status
                  </span>
                  <span className="col-md-8">
                    {orderDetail.statusDesc ? orderDetail.statusDesc : "-"}
                  </span>
                </div>
              </div>

              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Visit Address
                  </span>
                  <span className="col-md-8">
                    {orderDetail.orderAddress ? orderDetail.orderAddress : "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Service Package
                  </span>
                  <span className="col-md-8">
                    {orderDetail.packageName ? orderDetail.packageName : "-"}
                  </span>
                </div>
              </div>

              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Notes
                  </span>
                  <span className="col-md-8">
                    {orderDetail.additionalNote
                      ? orderDetail.additionalNote
                      : "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Price
                  </span>
                  <span className="col-md-8">
                    {orderDetail.totalFee
                      ? formatter.format(orderDetail.totalFee)
                      : "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Visit Date Request
                  </span>
                  <span className="col-md-8">
                    {orderDetail.requestDate
                      ? moment(orderDetail.requestDate).format("LL")
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="table-wrapper"
            style={{
              marginTop: "20px",
              minHeight: "auto",
            }}
          >
            <div className="wrapper-form-title">
              <span className="form-title">Partner Info</span>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Partner Name
                  </span>
                  <span className="col-md-8">
                    {orderDetail.partnerName ? orderDetail.partnerName : "-"}
                  </span>
                </div>
              </div>

              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Visit Date
                  </span>
                  <span className="col-md-8">
                    {orderDetail.scheduleDate
                      ? moment(orderDetail.scheduleDate).format("LL")
                      : "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Profession
                  </span>
                  <span className="col-md-8">
                    {orderDetail.professionName
                      ? orderDetail.professionName
                      : "-"}
                  </span>
                </div>
              </div>

              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Visit Time
                  </span>
                  <span className="col-md-8">
                    {orderDetail.startTime && orderDetail.endTime
                      ? `${moment(orderDetail.startTime, "HH:mm:ss").format(
                          "HH:mm"
                        )} - ${moment(orderDetail.endTime, "HH:mm:ss").format(
                          "HH:mm"
                        )}`
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-4 mb-4" style={{ justifyContent: "end" }}>
            <div className="col-md-4">
              <Link
                to={{
                  pathname: "/my-order",
                }}
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ width: "100%" }}
                >
                  Back
                </button>
              </Link>
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default MyOrderDetail;
