import { myTeamConstants } from "../actions/constants";

const initState = {
  teamMembers: [],
  totalData: "",
  specialist: [],
  userData: {},
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case myTeamConstants.GET_ALL_TEAMS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myTeamConstants.GET_ALL_TEAMS_SUCCESS:
      state = {
        ...state,
        teamMembers: action.payload.teamMembers,
        totalData: action.payload.totalData,
        loading: false,
      };
      break;
    case myTeamConstants.GET_ALL_TEAMS_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myTeamConstants.GET_SPECIALIST_REQUEST:
      state = {
        ...state,
      };
      break;
    case myTeamConstants.GET_SPECIALIST_SUCCESS:
      state = {
        ...state,
        specialist: action.payload.specialist,
      };
      break;
    case myTeamConstants.GET_SPECIALIST_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
      };
      break;
    case myTeamConstants.GET_TEAMS_BY_ID_REQUEST:
      state = {
        ...state,
      };
      break;
    case myTeamConstants.GET_TEAMS_BY_ID_SUCCESS:
      state = {
        ...state,
        userData: action.payload.userData,
      };
      break;
    case myTeamConstants.GET_TEAMS_BY_ID_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
      };
      break;
    case myTeamConstants.ADD_NEW_TEAMS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myTeamConstants.ADD_NEW_TEAMS_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case myTeamConstants.ADD_NEW_TEAMS_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
      case myTeamConstants.ADD_NEW_TEAMS_TELE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myTeamConstants.ADD_NEW_TEAMS_TELE_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case myTeamConstants.ADD_NEW_TEAMS_TELE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myTeamConstants.UPDATE_TEAMS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myTeamConstants.UPDATE_TEAMS_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case myTeamConstants.UPDATE_TEAMS_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myTeamConstants.DELETE_TEAMS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myTeamConstants.DELETE_TEAMS_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case myTeamConstants.DELETE_TEAMS_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
  }
  return state;
};
