import axios from "../helpers/axios";
import { orderRequestConstants } from "./constants";

export const getOrderRequest = (q) => {
  return async (dispatch) => {
    dispatch({ type: orderRequestConstants.GET_ORDER_REQUEST_REQUEST });
    const res = await axios.get(`/OrderRequest?${q}`);
    if (res.status === 200) {
      const { orders, totalData, allStatus, sendbirdToken, userId } = res.data;
      dispatch({
        type: orderRequestConstants.GET_ORDER_REQUEST_SUCCESS,
        payload: { orders, totalData, allStatus, sendbirdToken, userId },
      });
    } else {
      dispatch({
        type: orderRequestConstants.GET_ORDER_REQUEST_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getOrderRequestDetail = (id) => {
  return async (dispatch) => {
    dispatch({ type: orderRequestConstants.ORDER_REQUEST_DETAIL_REQUEST });
    const res = await axios.get(`OrderRequest/${id}`);
    if (res.status === 200) {
      const { orders, RequestTime } = res.data;
      dispatch({
        type: orderRequestConstants.ORDER_REQUEST_DETAIL_SUCCESS,
        payload: { orders, RequestTime },
      });
    } else {
      dispatch({
        type: orderRequestConstants.ORDER_REQUEST_DETAIL_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getPartners = (search) => {
  return async (dispatch) => {
    dispatch({ type: orderRequestConstants.GET_PARTNERS_REQUEST });
    const res = await axios.get(`/Order/findPartner?${search}`);
    if (res.status === 200) {
      const { partners, totalPartner, allPartner } = res.data;
      dispatch({
        type: orderRequestConstants.GET_PARTNERS_SUCCESS,
        payload: { partners, totalPartner, allPartner },
      });
    } else {
      dispatch({
        type: orderRequestConstants.GET_PARTNERS_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const createOrderRequest = (id, dataBody) => {
  return async (dispatch) => {
    dispatch({ type: orderRequestConstants.CREATE_ORDER_REQUEST_REQUEST });
    const res = await axios.post(`OrderRequest/${id}`, dataBody);
    if (res.status === 200) {
      dispatch({
        type: orderRequestConstants.CREATE_ORDER_REQUEST_SUCCESS,
        payload: res.data,
      });
      dispatch(getOrderRequest());
    } else {
      dispatch({
        type: orderRequestConstants.CREATE_ORDER_REQUEST_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
