import React, { useEffect, useState, useRef } from "react";
import DashboardLayout from "../../Layout";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import {
    getAllOrderPharmacy,
} from "../../../../actions";
import { formatDate } from "../../../../helpers/getFormattedDate";
import { Link } from "react-router-dom";
import Loading from "../../../../components/Loading";
import Pagination from "../../../../components/Pagination";
import Swal from "sweetalert2";

const AllOrderPharmacy = () => {
  const [query, setQuery] = useState("");

  const [paginationProps, setPaginationProps] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });

  const dateFrom = useRef("");
  const dateTo = useRef("");
  const patientName = useRef("");
  const status = useRef("");

  const result = useSelector((state) => state.orderPharmacy);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrderPharmacy(query));
  }, [query]);

  useEffect(() => {
    setPaginationProps({
        ...paginationProps,
        totalData: result.totalData,
      });
  }, [result.totalData]);

  const handleSearch = (page, limit) => {
    let values = {
        searchKey: patientName.current.value,
    };
    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPaginationProps({
        ...paginationProps,
        page: 1,
      });
    }

    if (limit) {
      values.limit = limit;
    }

    if (status.current.value !== "") {
        values.status = status.current.value;
    }

    if (dateFrom.current.value !== "") {
      values.startDate = dateFrom.current.value;
    }

    if (dateTo.current.value !== "") {
      values.endDate = dateTo.current.value;
    }

    let qs = queryString.stringify(values, {
        skipEmptyString: true
    });
    setQuery(qs);
  };

  // reset filter & pagination
  const handleReset = (page, limit) => {
    setPaginationProps({
      ...paginationProps,
      page: 1,
    });

    let values = {
      searchKey: patientName.current.value,
      page: paginationProps.page,
      limit: paginationProps.limit,
    };
    if (dateFrom.current.value !== "") {
      dateFrom.current.value = "";
    }
    if (dateTo.current.value !== "") {
      dateTo.current.value = "";
    }

    let query = queryString.stringify(values);
    setQuery(query);
  };

  const DataTables = () => {
    const nf = new Intl.NumberFormat();
  
    let orders = result.orders;
    // const chatVal = result?.foundPartner || [];
    return result.loading ? (
      <tr>
        <td colSpan="12">
          <Loading />
        </td>
      </tr>
      ) : result.error ? result.error.message : (
        <>
          { orders.length > 0 ? 
            ( orders.map((value, index) => (
                <tr key={index}>
                  <td>{(paginationProps.page - 1) * 10 + index + 1}</td>
                  <td>{value.order_id}</td>
                  <td>{value.customer_name}</td>
                  <td>{value.customer_phone}</td>
                  <td>{value.patient_name}</td>
                  <td>{value.address}</td>
                  <td>Rp. {nf.format(value.totalFee)}</td>
                  <td>{value.statusDesc}</td>
                  <td>
                      <div className="span2">
                          <p>
                              {/* <a href="{{ route('tele_all_order_pharmacy_detail', ['id' => $_data['order_id']]) }}"> */}
                              <Link
                                // to={"order/tele/all-pharmacy/detail/" + value.order_id}
                                to={{
                                  pathname: "all-pharmacy/detail",
                                  orderId: value.order_id,
                                }}
                                >
                                  <button type="button" className="btn btn-outline-success btn-block">
                                      Detail
                                  </button>
                              </Link>
                              {/* </a> */}
                          </p>
                      </div>
                  </td>
                </tr>
              ))
            ) :     
                <tr>
                  <td colSpan="9">Empty Data</td>
                </tr>
           }
        </>
      )
  }

  return (
    <DashboardLayout>
      <div className="row revenue-title">
          <div className="col">
            <hr />
          </div>
          <div>List Order Pharmacy</div>
          <div className="col">
            <hr />
          </div>
          
      </div>

      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        <div className="row">
          <div className="col-md-6" id="btn-add">
            <span id="finance-title" className="mb10">
              List Order Pharmacy
            </span>
          </div>
        </div>
        
        {/* Filter */}
        {/* <Filter {...filterProps} /> */}
        <div className="row mb15" style={{ marginBottom: '15px'}}>
          <div className="link-btn">
            <button
              type="button"
              className="btn btn-link no-padding"
              id="filterBy"
              data-toggle="collapse"
              data-target="#collapseDetailSchedule"
            >
              Filter By
              <i className="fa fa-angle-down" style={{ marginLeft: "10px" }} />
            </button>
          </div>
          <div className="col">
            <hr />
          </div>
        </div>

        <div className="collapse" id="collapseDetailSchedule">
          {/* <form id="filter-form" onReset={handleReset}> */}
          <div className="row">
              <div className="col-md-6">
                  <div className = "row">
                      <div className="col-md-6 pr-md-0 pl-1">
                          <div className="form-group mb-md-0" style={{marginRight: "10px"}}>
                              <label>Date From</label>
                              <input 
                                className="form-control"
                                type="text"
                                placeholder="Select Date From"
                                onFocus={
                                  (e)=> {
                                    e.currentTarget.type = "date";
                                    e.currentTarget.focus();
                                   }
                                }
                                 ref={dateFrom} />
                          </div>
                      </div>
                      <div className="col-md-6 pr-md-0 pl-1">
                          <div className="form-group mb-md-0">
                              <label>Date To</label>
                              <input 
                                className="form-control" 
                                type="text"
                                placeholder="Select Date To"
                                onFocus={
                                  (e)=> {
                                    e.currentTarget.type = "date";
                                    e.currentTarget.focus();
                                   }
                                }
                                ref={dateTo} />
                          </div>
                      </div>
                  </div>
              </div>
            <div className="col-md-3 neutral-border">
              <div className="form-group mb-md-0">
                <label>Status</label>
                <select className="form-control" ref={status} >
                  <option value="">Select status</option>
                  <option value={20} data-id="20">Order Baru</option>
                  <option value={21} data-id="21">Ekses Klaim & Obat</option>
                  <option value={22} data-id="22">Ekses Klaim & Obat Dikonfirmasi </option>
                  <option value={29} data-id="29">Dalam Perjalanan</option>
                  <option value={88} data-id="88">Cancelled By Customer</option>
                  <option value={89} data-id="89">Cancelled By CMS</option>
                  <option value={99} data-id="99">Pesanan Selesai</option>
                </select>
              </div>
            </div>
            <div className="col-md-3">
            <div className="form-group mb-md-0">
                  <label>Patient Name</label>
                  <input 
                    type="text" 
                    className="form-control" 
                    placeholder="Input patient name"
                    ref={patientName} />
              </div>
            </div>
          </div>
          <div className="fm-btn-gr text-right">
              <button
                  type="button"
                  className="btn btn-secondary"
                  style={{ marginRight: "10px" }}
                  onClick={() =>
                  handleReset()
                  }
              >
              Reset
            </button>
            <button
              onClick={() =>
                handleSearch()
              }
              type="button"
              className="btn btn-primary"
            >
              Apply
            </button>
          </div>

          <div style={{ width: "100%" }}>
            <hr />
          </div>
          {/* </form> */}
        </div>

        {/* Table */}
        <div className="table-responsive">
          <table
            id="tableScheduler"
            className="table table-striped table-bordered"
          >
            <thead className="table-header">
              {/* Rows: 17 */}
              <tr>
                <th rowSpan={2} className="table-header-middle">
                  No
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Order ID
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Customer Name
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Customer Phone
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Patient Name
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Delivery Address
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Total Amount
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Status
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {DataTables()}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        { result.totalData > 10 ? (
          <Pagination
            props={paginationProps}
            setProps={setPaginationProps}
            onChange={(page, limit) => {
              handleSearch(page, limit);
            }}
          />
        ) : null}
      </div>
    </DashboardLayout>
  );
}

export default AllOrderPharmacy;