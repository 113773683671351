import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import DashboardLayout from "../../../Layout";

import { useDispatch, useSelector } from "react-redux";

import {
    getDetailRiwayatKesehatan,
} from "../../../../../actions";

// Style
import "../../orderHistory.css";

// Helpers
import { formatDate } from "../../../../../helpers/getFormattedDate";

export default function OrderHistoryDetailLihatRiwayat() {
    const result = useSelector((state) => state.orderHistory);
    const { state } = useLocation();
    const dispatch = useDispatch();  
    
    const orderId = state.orderId != undefined ? state.orderId : 0;

    useEffect(() => {
        document.getElementById("btnSummaryVisit").click();
        dispatch(getDetailRiwayatKesehatan(orderId));
    }, []);

    const detailriwayatDataVal = result.detailRiwayat != undefined ? result.detailRiwayat : [];
    
    const partnerName = detailriwayatDataVal.length != 0 ? 
                            detailriwayatDataVal.partner != undefined ? 
                                detailriwayatDataVal.partner.user != undefined ? 
                                    detailriwayatDataVal.partner.user.fullName : ""
                                : ""
                            : "";

    const professionName = detailriwayatDataVal.length != 0 ? 
                            detailriwayatDataVal.partner != undefined ? 
                                detailriwayatDataVal.partner.profession != undefined ? 
                                    detailriwayatDataVal.partner.profession.name : "" 
                                : ""
                            : "";
    const imgUrl = detailriwayatDataVal.length != 0 ? 
                        detailriwayatDataVal.partner != undefined ? 
                            detailriwayatDataVal.partner.user != undefined ? 
                                detailriwayatDataVal.partner.user.avatar : "https://aido-app.s3-ap-southeast-1.amazonaws.com/assets/users/icons/anonymous.png"
                            : "https://aido-app.s3-ap-southeast-1.amazonaws.com/assets/users/icons/anonymous.png"
                        : "https://aido-app.s3-ap-southeast-1.amazonaws.com/assets/users/icons/anonymous.png";

    const scheduleDate = detailriwayatDataVal.length != 0 ? 
                            detailriwayatDataVal.schedule != undefined ? 
                                formatDate(detailriwayatDataVal.schedule.scheduleDate) : ""
                            : "";
   
    const startTime = detailriwayatDataVal.length != 0 ? 
                        detailriwayatDataVal.schedule != undefined ? 
                            detailriwayatDataVal.schedule.startTime : ""
                        : "";
    const endTime = detailriwayatDataVal.length != 0 ? 
                        detailriwayatDataVal.schedule != undefined ? 
                            detailriwayatDataVal.schedule.endTime : ""
                        : "";
    
    const complaints = detailriwayatDataVal.length != 0 ? 
                        detailriwayatDataVal.complaints != undefined ? 
                            detailriwayatDataVal.complaints : ""
                        : "";
    const analysis = detailriwayatDataVal.length != 0 ? 
                        detailriwayatDataVal.medicalRecord != undefined ? 
                            detailriwayatDataVal.medicalRecord.analysis : ""
                        : "";
    const carePlan = detailriwayatDataVal.length != 0 ? 
                        detailriwayatDataVal.medicalRecord != undefined ? 
                            detailriwayatDataVal.medicalRecord.carePlan : ""
                        : "";
    
    const referToHospital = detailriwayatDataVal.length != 0 ? 
                                detailriwayatDataVal.medicalRecord != undefined ? 
                                    detailriwayatDataVal.medicalRecord.isReferenceToHospital ? "Ya" : "Tidak"
                                        : ""
                                : "";
    
    const referToHospitalNote = detailriwayatDataVal.length != 0 ? 
                                    detailriwayatDataVal.medicalRecord != undefined ? 
                                        detailriwayatDataVal.medicalRecord.referenceToHospitalNote : ""
                                    : "";
   const pharmacyNote = detailriwayatDataVal.length != 0 ? 
                            detailriwayatDataVal.medicalRecord != undefined ? 
                                detailriwayatDataVal.medicalRecord.pharmacyNote : ""
                            : ""; 

    const isPharmacyDelivery = detailriwayatDataVal.length != 0 ? 
                                detailriwayatDataVal.medicalRecord != undefined ? 
                                    detailriwayatDataVal.medicalRecord.isPharmacyDelivery != 0 ? 
                                        "Ya" : "Tidak"
                                    : ""
                                : "";
    const pharmacyPhoto = detailriwayatDataVal.length != 0 ? 
                            detailriwayatDataVal.medicalRecord != undefined ? 
                                detailriwayatDataVal.medicalRecord.pharmacyPhoto : ""
                            : ""; 
    const consentPhoto = detailriwayatDataVal.length != 0 ? 
                            detailriwayatDataVal.medicalRecord != undefined ? 
                                detailriwayatDataVal.medicalRecord.consentPhoto : ""
                            : ""; 
    const dataProsedur = detailriwayatDataVal.length != 0 ? detailriwayatDataVal.listProsedur : [];
    const orderGallery = detailriwayatDataVal.length != 0 ? detailriwayatDataVal.orderGallery : [];

    const prevPageUrl = "/order-history/detail/riwayat";
    const nextPageConsentFormUrl = "/order-history/detail/consentform";
    const nextPageLihatProsedurUrl = "/order-history/detail/listprosedur";
    const nextPageLampiranFotoUrl = "/order-history/detail/ordergallery";
    const stateVal = {  "id" : state.id, "statusCode": state.statusCode, "detailData": state.detailData, type: "riwayat",
                        "orderId": orderId, "consentPhoto" : consentPhoto, "dataProsedur": dataProsedur, "orderGallery": orderGallery
                    };
    
    const openPage = (pageName) => {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent2");
        for (i = 0; i < tabcontent.length; i++) {
          tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablink2");
        for (i = 0; i < tablinks.length; i++) {
          tablinks[i].style.backgroundColor = "white";
          tablinks[i].style.borderBottom = "4px solid white";
        }
        document.getElementById(pageName).style.display = "block";
        var el = "btn"+pageName;
        document.getElementById(el).style.borderBottom = "4px solid #38a8ff";
    };

    return (
        <DashboardLayout>        
            <div className="col-12" >
                <div className="card-detail" style={{background:"#38a8ff", margin:"30px 0px 20px 0px", borderRadius: "10px 10px 0px 0px"}}>
                    <Link to={{ pathname: prevPageUrl, state: stateVal}}>
                        <i className="fa fa-arrow-left" style={{fontSize: "18px", color: "white"}} aria-hidden="true" /> 
                    </Link>
                    <span style={{margin: "0px 30px 0px 30px", color: "white", fontSize: "16px"}}> 
                        Lihat Riwayat Kesehatan
                    </span>
                </div>
                <div className="card-detail">
                    <table>
                        <tbody>
                            <tr>
                                <td style={{width: "30px"}}>
                                    <img
                                        className="rounded-circle navbar-avatar-img"
                                        src={imgUrl}
                                    />
                                </td>
                                <td>
                                    <div style={{fontSize:"15px", fontWeight: "bold", margin: "0px 0px 5px 0px"}}>
                                        {partnerName}
                                    </div>
                                    <div>
                                        <span>{professionName}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="card-detail">
                    <div>
                        <i className="fa fa-calendar" aria-hidden="true"  /> 
                        <span style={{margin: "0px 0px 0px 10px"}}>{scheduleDate}</span>
                    </div>
                    <div>
                        <i className="fa fa-clock-o" aria-hidden="true"  /> 
                        <span style={{margin: "10px 0px 0px 10px"}}>{startTime} - {endTime}</span>
                    </div>
                </div>
                <Link to = {{ pathname: nextPageConsentFormUrl, state: stateVal }}>
                    <div className="card-detail">
                        <i className="fa fa-file-text-o" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                        <span style={{margin: "0px 30px 0px 10px"}}> Lihat Consent Form</span>
                        <i className="fa fa-angle-right" aria-hidden="true" 
                            style={{fontSize :"22px", fontWeight: 'bolder', right: 33, position: 'absolute'}} /> 
                    </div>
                </Link>

                <Link to = {{ pathname: nextPageLihatProsedurUrl, state: stateVal }}>
                    <div className="card-detail">
                        <i className="fa fa-file-text-o" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                        <span style={{margin: "0px 30px 0px 10px"}}> Lihat Prosedur</span>
                        <i className="fa fa-angle-right" aria-hidden="true" 
                            style={{fontSize :"22px", fontWeight: 'bolder', right: 33, position: 'absolute'}} /> 
                    </div>
                </Link>
                <Link to = {{ pathname: nextPageLampiranFotoUrl, state: stateVal }}>
                    <div className="card-detail">
                        <i className="fa fa-file-text-o" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                        <span style={{margin: "0px 30px 0px 10px"}}> Lampiran Foto</span>
                        <i className="fa fa-angle-right" aria-hidden="true" 
                            style={{fontSize :"22px", fontWeight: 'bolder', right: 33, position: 'absolute'}} /> 
                    </div>
                </Link>

                <div>
                    <button className="tablink2" id="btnSummaryVisit" onClick={() => {openPage('SummaryVisit');}}>Summary Visit</button>
                    <button className="tablink2" id="btnResep" onClick={() => {openPage('Resep');}}>Resep</button>
                    <div id="SummaryVisit" className="tabcontent2">
                        <div className="card-detail">
                            <div>
                                <i className="fa fa-file-text-o" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                                <span style={{margin: "0px 10px 0px 10px"}}> Keluhan </span>
                            </div>
                            <div style={{fontSize: '14px', margin: '10px 10px 15px 28px'}}>
                                {complaints}
                            </div>
                        </div>
                        <div className="card-detail">
                            <div>
                                <i className="fa fa-file-text-o" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                                <span style={{margin: "0px 10px 0px 10px"}}> Pemeriksaan Fisik </span>
                            </div>
                            <div style={{fontSize: '14px', margin: '10px 10px 15px 28px'}}>
                                {analysis}
                            </div>
                        </div>
                        <div className="card-detail">
                            <div>
                                <i className="fa fa-file-text-o" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                                <span style={{margin: "0px 10px 0px 10px"}}> Diagnosa </span>
                            </div>
                            <div style={{fontSize: '14px', margin: '10px 10px 15px 28px'}}>
                                {carePlan}
                            </div>
                        </div>
                        <div className="card-detail">
                            <div>
                                <i className="fa fa-file-text-o" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                                <span style={{margin: "0px 10px 0px 10px"}}> Rencana Perawatan </span>
                            </div>
                            <div style={{fontSize: '14px', margin: '10px 10px 15px 28px'}}>
                                {referToHospital + '\n' + referToHospitalNote} 
                            </div>
                        </div>
                    </div>
                    <div id="Resep" className="tabcontent2">
                        <div className="card-detail">
                            <div>
                                <i className="fa fa-heartbeat" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                                <span style={{margin: "0px 10px 0px 10px"}}> 
                                    Pesan-Antar obat ? {isPharmacyDelivery}                                
                                </span>
                            </div>
                            <div style={{margin: '10px 10px 15px 10px'}}>
                                <img style={{height: 250, width: 250, alignSelf: 'stretch'}} src={pharmacyPhoto}/>
                            </div>
                        </div>
                        <div className="card-detail">
                            <div>
                                <i className="fa fa-file-text-o" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                                <span style={{margin: "0px 10px 0px 10px"}}> Catatan </span>
                            </div>
                            <div style={{fontSize: '14px', margin: '10px 10px 15px 28px'}}>
                                {pharmacyNote} 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
};