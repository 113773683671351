import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../../Layout";
import { useDispatch, useSelector } from "react-redux";
import { getOrderRequestDetail, getRelation } from "../../../../actions";
import { useLocation } from "react-router";
import moment from "moment";

const OrderRequestDetail = () => {
  const orderRequest = useSelector((state) => state.orderRequest);
  const register = useSelector((state) => state.register);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { loading, error, order, RequestTime } = orderRequest;

  useEffect(() => {
    dispatch(getOrderRequestDetail(state.requestId));
  }, [state.requestId]);

  useEffect(() => {
    dispatch(getRelation());
  }, []);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (
    <DashboardLayout>
      {loading ? (
        <div
          className="table-wrapper"
          style={{
            marginTop: "20px",
            minHeight: "auto",
          }}
        >
          <Loading />
        </div>
      ) : (
        <>
          <div
            className="table-wrapper"
            style={{
              marginTop: "20px",
              minHeight: "auto",
            }}
          >
            <div className="wrapper-form-title">
              <span className="form-title">Patient Info</span>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Patient Name
                  </span>
                  <span className="col-md-8">
                    {order.patientName ? order.patientName : "-"}
                  </span>
                </div>
              </div>

              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Patient Address
                  </span>
                  <span className="col-md-8">
                    {order.patientSiloAddress ? order.patientSiloAddress : "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Gender
                  </span>
                  <span className="col-md-8">
                    {order.gender
                      ? order.gender === 1
                        ? "Male"
                        : "Female"
                      : "-"}
                  </span>
                </div>
              </div>

              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Complaints
                  </span>
                  <span className="col-md-8">
                    {order.complaints ? order.complaints : "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Date of Birth
                  </span>
                  <span className="col-md-8">
                    {order.birthday ? moment(order.birthday).format("LL") : "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Age
                  </span>
                  <span className="col-md-8">
                    {order.birthday
                      ? new Date().getFullYear() -
                        new Date(order.birthday).getFullYear()
                      : "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Phone Number
                  </span>
                  <span className="col-md-8">
                    {order.patientSiloPhone
                      ? `+62${order.patientSiloPhone}`
                      : "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Email
                  </span>
                  <span className="col-md-8">
                    {order.patientSiloEmail ? order.patientSiloEmail : "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="table-wrapper"
            style={{
              marginTop: "20px",
              minHeight: "auto",
            }}
          >
            <div className="wrapper-form-title">
              <span className="form-title">Responsible Info</span>
            </div>

            <div className="row">
              <div className="form-group col-md-4">
                <div className="row">
                  <span className="col-md-6" style={{ fontWeight: "bold" }}>
                    Responsible Name
                  </span>
                  <span className="col-md-6">
                    {order.customerName ? order.customerName : "-"}
                  </span>
                </div>
              </div>

              <div className="form-group col-md-4">
                <div className="row">
                  <span className="col-md-6" style={{ fontWeight: "bold" }}>
                    Phone Number
                  </span>
                  <span className="col-md-6">
                    {order.customerPhone ? `+62${order.customerPhone}` : "-"}
                  </span>
                </div>
              </div>

              <div className="form-group col-md-4">
                <div className="row">
                  <span className="col-md-6" style={{ fontWeight: "bold" }}>
                    Relationship
                  </span>
                  <span className="col-md-6">
                    {order.relation
                      ? register.relation.data
                          .filter((x) => x.id == order.relation)
                          .map((x) => x.relationMapping)
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="table-wrapper"
            style={{
              marginTop: "20px",
              minHeight: "auto",
            }}
          >
            <div className="wrapper-form-title">
              <span className="form-title">{`Order Details #${state.requestId}`}</span>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Status
                  </span>
                  <span className="col-md-8">
                    {order.statusDesc ? order.statusDesc : "-"}
                  </span>
                </div>
              </div>

              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Visit Address
                  </span>
                  <span className="col-md-8">
                    {order.orderAddress ? order.orderAddress : "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Service Package
                  </span>
                  <span className="col-md-8">
                    {order.voucherTitle ? order.voucherTitle : "-"}
                  </span>
                </div>
              </div>

              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Notes
                  </span>
                  <span className="col-md-8">
                    {order.additionalNote ? order.additionalNote : "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Price
                  </span>
                  <span className="col-md-8">
                    {order.price ? formatter.format(order.price) : "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="row">
                  <span className="col-md-4" style={{ fontWeight: "bold" }}>
                    Visit Date Request
                  </span>
                  <span className="col-md-8">
                    {RequestTime.requestDate
                      ? moment(RequestTime.requestDate).format("LL")
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-4 mb-4" style={{ justifyContent: "end" }}>
            <div className="col-md-4">
              <Link
                to={{
                  pathname: "/order-request/assign",
                  state: {
                    requestId: state.requestId,
                    visitDate: RequestTime.requestDate
                      ? moment(RequestTime.requestDate).format("YYYY-MM-DD")
                      : "",
                  },
                }}
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ width: "100%" }}
                >
                  Assign Partner
                </button>
              </Link>
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default OrderRequestDetail;
