import { registerConstants } from "../actions/constants";

const initState = {
  data: [],
  allData: [],
  totalData: "",
  userData: {
    patients: [],
    users: {},
    visitData: [],
  },
  relation: {
    data: [],
  },
  patientDetail: [],
  packageData: [],
  hospitalData: [],
  pharmacyName: null,
  addressData: null,
  submitAddressData: null,
  updateAddressData: null,
  submitPharmacyUnit: null,
  doctorListData: [],
  country: [],
  province: [],
  city: [],
  district: [],
  subdistrict: [],
  postalcode: [],
  schedule_data: [],
  insuranceData: [],
  urlInsurance: [],
  urlIdentity: [],
  findUsers: [],
  deliveryMethod: [],
  gosendPrice: [],
  deliveryPrice: null,
  clinicData: [],
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case registerConstants.GET_REGISTER_LIST_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.GET_REGISTER_LIST_SUCCESS:
      state = {
        ...state,
        data: action.payload.data,
        totalData: action.payload.totalData,
        loading: false,
      };
      break;
    case registerConstants.GET_REGISTER_LIST_SUCCESS:
      state = {
        ...state,
        allData: action.payload.allData,
        loading: false,
      };
      break;
    case registerConstants.GET_REGISTER_LIST_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.GET_REGISTER_BY_ID_REQUEST:
      state = {
        ...state,
      };
      break;
    case registerConstants.GET_REGISTER_BY_ID_SUCCESS:
      state = {
        ...state,
        userData: action.payload.userData,
      };
      break;
    case registerConstants.GET_REGISTER_BY_ID_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
      };
      break;
    case registerConstants.GET_RELATION_LIST_REQUEST:
      state = {
        ...state,
      };
      break;
    case registerConstants.GET_RELATION_LIST_SUCCESS:
      state = {
        ...state,
        relation: action.payload.relation,
      };
      break;
    case registerConstants.GET_RELATION_LIST_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
      };
      break;
    case registerConstants.FIND_PATIENT_DETAIL_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.FIND_PATIENT_DETAIL_SUCCESS:
      state = {
        ...state,
        patientDetail: action.payload.patientDetail,
        loading: false,
      };
      break;
    case registerConstants.FIND_PATIENT_DETAIL_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.GET_ADDRESS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.GET_ADDRESS_SUCCESS:
      state = {
        ...state,
        addressData: action.payload.addressData,
        loading: false,
      };
      break;
    case registerConstants.GET_ADDRESS_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.GET_PACKAGE_REQUEST:
      state = {
        ...state,
      };
      break;
    case registerConstants.GET_PACKAGE_SUCCESS:
      state = {
        ...state,
        packageData: action.payload.packageData,
      };
      break;
    case registerConstants.GET_PACKAGE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
      };
      break;
    case registerConstants.SUBMIT_SINGLE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.SUBMIT_SINGLE_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case registerConstants.SUBMIT_SINGLE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.SUBMIT_MULTIPLE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.SUBMIT_MULTIPLE_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case registerConstants.SUBMIT_MULTIPLE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.CREATE_ORDER_HOMECARE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.CREATE_ORDER_HOMECARE_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case registerConstants.CREATE_ORDER_HOMECARE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.SUBMIT_PATIENT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.SUBMIT_PATIENT_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case registerConstants.SUBMIT_PATIENT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.GET_DOCTOR_REQUEST:
      state = {
        ...state,
      };
      break;
    case registerConstants.GET_DOCTOR_SUCCESS:
      state = {
        ...state,
        doctorListData: action.payload,
      };
      break;
    case registerConstants.GET_DOCTOR_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
      };
      break;
    case registerConstants.GET_COUNTRY_REQUEST:
      state = {
        ...state,
      };
      break;
    case registerConstants.GET_COUNTRY_SUCCESS:
      state = {
        ...state,
        country: action.payload,
      };
      break;
    case registerConstants.GET_COUNTRY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
      };
      break;
    case registerConstants.GET_STATE_REQUEST:
      state = {
        ...state,
      };
      break;
    case registerConstants.GET_STATE_SUCCESS:
      state = {
        ...state,
        province: action.payload,
      };
      break;
    case registerConstants.GET_STATE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
      };
      break;
    case registerConstants.GET_CITY_REQUEST:
      state = {
        ...state,
      };
      break;
    case registerConstants.GET_CITY_SUCCESS:
      state = {
        ...state,
        city: action.payload,
      };
      break;
    case registerConstants.GET_CITY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
      };
      break;
    case registerConstants.GET_DISTRICT_REQUEST:
      state = {
        ...state,
      };
      break;
    case registerConstants.GET_DISTRICT_SUCCESS:
      state = {
        ...state,
        district: action.payload,
      };
      break;
    case registerConstants.GET_DISTRICT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
      };
      break;
    case registerConstants.GET_SUBDISTRICT_REQUEST:
      state = {
        ...state,
      };
      break;
    case registerConstants.GET_SUBDISTRICT_SUCCESS:
      state = {
        ...state,
        subdistrict: action.payload,
      };
      break;
    case registerConstants.GET_SUBDISTRICT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
      };
      break;
    case registerConstants.GET_POSTALCODE_REQUEST:
      state = {
        ...state,
      };
      break;
    case registerConstants.GET_POSTALCODE_SUCCESS:
      state = {
        ...state,
        postalcode: action.payload,
      };
      break;
    case registerConstants.GET_POSTALCODE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
      };
      break;
    case registerConstants.GET_SCHEDULE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case registerConstants.GET_SCHEDULE_DATA_SUCCESS:
      return {
        ...state,
        schedule_data: action.payload.schedule_data,
        loading: false,
      };
    case registerConstants.GET_SCHEDULE_DATA_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case registerConstants.GET_SCHEDULE_NONSILOAM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case registerConstants.GET_SCHEDULE_NONSILOAM_SUCCESS:
      return {
        ...state,
        schedule_data: action.payload.schedule_data,
        loading: false,
      };
    case registerConstants.GET_SCHEDULE_NONSILOAM_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case registerConstants.GET_REGISTERPATIENT_LIST_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.GET_REGISTERPATIENT_LIST_SUCCESS:
      state = {
        ...state,
        data: action.payload.data,
        totalData: action.payload.totalData,
        loading: false,
      };
      break;
    case registerConstants.GET_REGISTERPATIENT_LIST_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.GET_INSURANCE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.GET_INSURANCE_SUCCESS:
      state = {
        ...state,
        insuranceData: action.payload,
        loading: false,
      };
      break;
    case registerConstants.GET_INSURANCE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.UPLOAD_INSURANCE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.UPLOAD_INSURANCE_SUCCESS:
      state = {
        ...state,
        urlInsurance: action.payload,
        loading: false,
      };
      break;
    case registerConstants.UPLOAD_INSURANCE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.UPLOAD_IDENTITY_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.UPLOAD_IDENTITY_SUCCESS:
      state = {
        ...state,
        urlIdentity: action.payload,
        loading: false,
      };
      break;
    case registerConstants.UPLOAD_IDENTITY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.UPDATE_PATIENT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.UPDATE_PATIENT_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case registerConstants.UPDATE_PATIENT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.FIND_USERPHONEEMAIL_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.FIND_USERPHONEEMAIL_SUCCESS:
      state = {
        ...state,
        findUsers: action.payload,
        loading: false,
      };
      break;
    case registerConstants.FIND_USERPHONEEMAIL_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.GET_PHARMACY_NAME_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.GET_PHARMACY_NAME_SUCCESS:
      state = {
        ...state,
        pharmacyName: action.payload.data,
        loading: false,
      };
      break;
    case registerConstants.GET_PHARMACY_NAME_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.SUBMIT_PHARMACY_UNIT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.SUBMIT_PHARMACY_UNIT_SUCCESS:
      state = {
        ...state,
        submitPharmacyUnit: action.payload.data,
        loading: false,
      };
      break;
    case registerConstants.SUBMIT_PHARMACY_UNIT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.GET_DELIVERY_METHOD_REQUEST:
      state = {
        ...state,
      };
      break;
    case registerConstants.GET_DELIVERY_METHOD_SUCCESS:
      state = {
        ...state,
        deliveryMethod: action.payload,
      };
      break;
    case registerConstants.GET_DELIVERY_METHOD_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
      };
      break;
    case registerConstants.GET_ADDRESSBYORDER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.GET_ADDRESSBYORDER_SUCCESS:
      state = {
        ...state,
        addressData: action.payload,
        loading: false,
      };
      break;
    case registerConstants.GET_ADDRESSBYORDER_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.GET_GOSENDPRICE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.GET_GOSENDPRICE_SUCCESS:
      state = {
        ...state,
        gosendPrice: action.payload.data,
        loading: false,
      };
      break;
    case registerConstants.GET_GOSENDPRICE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.GET_DELIVERYPRICE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.GET_DELIVERYPRICE_SUCCESS:
      state = {
        ...state,
        deliveryPrice: action.payload.data,
        loading: false,
      };
      break;
    case registerConstants.GET_DELIVERYPRICE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.GET_ONETIMEADDRESS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.GET_ONETIMEADDRESS_SUCCESS:
      state = {
        ...state,
        addressData: action.payload.data,
        loading: false,
      };
      break;
    case registerConstants.GET_ONETIMEADDRESS_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.SUBMIT_ADDRESS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.SUBMIT_ADDRESS_SUCCESS:
      state = {
        ...state,
        submitAddressData: action.payload.data,
        loading: false,
      };
      break;
    case registerConstants.SUBMIT_ADDRESS_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.UPDATE_ADDRESS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.UPDATE_ADDRESS_SUCCESS:
      state = {
        ...state,
        updateAddressData: action.payload.data,
        loading: false,
      };
      break;
    case registerConstants.UPDATE_ADDRESS_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.GET_HOSPITAL_LIST_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.GET_HOSPITAL_LIST_SUCCESS:
      state = {
        ...state,
        hospitalData: action.payload.data,
        loading: false,
      };
      break;
    case registerConstants.GET_HOSPITAL_LIST_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
      case registerConstants.GET_DETAIL_CLINIC_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.GET_DETAIL_CLINIC_SUCCESS:
      state = {
        ...state,
        clinicData: action.payload.data,
        loading: false,
      };
      break;
    case registerConstants.GET_DETAIL_CLINIC_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case registerConstants.GET_PROMO_VALIDATION_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case registerConstants.GET_PROMO_VALIDATION_SUCCESS:
      state = {
        ...state,
        promo: action.payload.data,
        loading: false,
      };
      break;
    case registerConstants.GET_PROMO_VALIDATION_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
  }
  return state;
};
