import axios from "../helpers/axios";
import axiosParent from "../helpers/axiosParent";
import { educationConstants } from "./constants";

export const getEducation = (id) => {
  return async (dispatch) => {
    dispatch({ type: educationConstants.GET_EDUCATION_REQUEST });
    const res = await axios.get(`/TeamMember/${id}/education?`);
    if (res.status === 200) {
      dispatch({
        type: educationConstants.GET_EDUCATION_SUCCESS,
        payload: { dataEducation: res.data },
      });
    } else {
      dispatch({
        type: educationConstants.GET_EDUCATION_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getUniversities = () => {
  return async (dispatch) => {
    dispatch({ type: educationConstants.GET_UNIVERSITIES_REQUEST });
    const res = await axiosParent.get("/University");
    if (res.status === 200) {
      dispatch({
        type: educationConstants.GET_UNIVERSITIES_SUCCESS,
        payload: { universities: res.data },
      });
    } else {
      dispatch({
        type: educationConstants.GET_UNIVERSITIES_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const addEducation = (id, form) => {
  return async (dispatch) => {
    dispatch({ type: educationConstants.ADD_NEW_EDUCATION_REQUEST });
    const res = await axios.post(`/TeamMember/${id}/education`, form);
    if (res.status === 200) {
      dispatch({
        type: educationConstants.ADD_NEW_EDUCATION_SUCCESS,
        payload: res.data,
      });
      dispatch(getEducation(id));
    } else {
      dispatch({
        type: educationConstants.ADD_NEW_EDUCATION_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const updateEducation = (id, eduId, form) => {
  return async (dispatch) => {
    dispatch({ type: educationConstants.UPDATE_EDUCATION_REQUEST });
    const res = await axios.put(`/TeamMember/${id}/education/${eduId}`, form);
    if (res.status === 200) {
      dispatch({
        type: educationConstants.UPDATE_EDUCATION_SUCCESS,
        payload: res.data,
      });
      dispatch(getEducation(id));
    } else {
      dispatch({
        type: educationConstants.UPDATE_EDUCATION_SUCCESS,
        payload: { error: res.data.error },
      });
    }
  };
};

export const deleteEducation = (id, eduId) => {
  return async (dispatch) => {
    dispatch({ type: educationConstants.DELETE_EDUCATION_REQUEST });
    const res = await axios.delete(`/TeamMember/${id}/education/${eduId}`);
    if (res.status === 200) {
      dispatch({ type: educationConstants.DELETE_EDUCATION_SUCCESS });
      dispatch(getEducation(id));
    } else {
      dispatch({
        type: educationConstants.DELETE_EDUCATION_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
