import { orderMedicalRecordsConstant } from "../actions/constants";

const initState = {
  medicalRecords: [],
  dataDownload: [],
  totalData: 0,
  loading: false,
  loadingDownload: false,
  error: null,
  partnerOrders: [],
  totalOrder: 0,
  totalMrFilled: 0, 
  totalNoPatient: 0, 
  totalNoDoctor: 0, 
  totalDoctorLate: 0, 
  totalPatientCovid: 0,
};

export default (state = initState, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case orderMedicalRecordsConstant.GET_ALL_MEDICALRECORDS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderMedicalRecordsConstant.GET_ALL_MEDICALRECORDS_SUCCESS:
      state = {
        ...state,
        medicalRecords: action.payload.medicalRecords,
        totalData: action.payload.totalData,
        loading: false,
      };
      break;
    case orderMedicalRecordsConstant.GET_ALL_MEDICALRECORDS_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case orderMedicalRecordsConstant.GET_MEDICAL_REPORT_REQUEST:
      state = {
        ...state,
        loadingDownload: true,
      };
      break;
    case orderMedicalRecordsConstant.GET_MEDICAL_REPORT_SUCCESS:
      state = {
        ...state,
        loadingDownload: false,
        dataDownload: action.payload.orders,
      };
      break;
    case orderMedicalRecordsConstant.GET_MEDICAL_REPORT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loadingDownload: false,
      };
      break;
    case orderMedicalRecordsConstant.GET_WEEKLY_DASHBOARD_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderMedicalRecordsConstant.GET_WEEKLY_DASHBOARD_SUCCESS:
      state = {
        ...state,
        loading: false,
        partnerOrders: action.payload.partnerOrders,
        totalOrder: action.payload.totalData,
        totalMrFilled: action.payload.totalMrFilled,
        totalNoPatient: action.payload.totalNoPatient,
        totalNoDoctor: action.payload.totalNoDoctor,
        totalDoctorLate: action.payload.totalDoctorLate,
        totalPatientCovid: action.payload.totalPatientCovid,
      };
      break;
    case orderMedicalRecordsConstant.GET_WEEKLY_DASHBOARD_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
  }
  return state;
};
