import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../actions";

const Header = ({ listMenu, menuType, setMenuType }) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const userLogout = () => {
    dispatch(logout());
  };

  const handleSwitch = () => {
    if (menuType == "0") {
      localStorage.setItem("menuType", "1");
      setMenuType(localStorage.getItem("menuType"));
    } else if (menuType == "1") {
      localStorage.setItem("menuType", "0");
      setMenuType(localStorage.getItem("menuType"));
    }
  };

  const renderProfile = () => {
    return (
      <>
        {auth.user ? (
          <button
            type="button"
            id="dropdownMenuButton"
            className="btn navbar-right-button"
            style={{ backgroundColor: "#fff" }}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              className="rounded-circle navbar-avatar-img"
              data-cfsrc={auth.user.avatar}
              src={auth.user.avatar}
              alt={auth.user.fullName}
            />
            <span className="navbar-username">{auth.user.email}</span>
            <img
              className="button-right-expand"
              data-cfsrc="/img/icon/ic_expand_more.png"
              src="/img/icon/ic_expand_more.png"
              alt="icon-expand"
            />
          </button>
        ) : null}
      </>
    );
  };

  const pathUrl = window.location.pathname;

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="form-title"
            style={{ cursor: "pointer" }}
            onClick={handleSwitch}
          >
            <i className="fa fa-bars"></i>
          </span>
          {listMenu.map((menu) => {
            return pathUrl === menu.path ? (
              <span key={menu.label} className="navbar-title text-left">
                {menu.label}
              </span>
            ) : pathUrl === menu.childPath ? (
              <span key={menu.childLabel} className="navbar-title text-left">
                {menu.childLabel}
              </span>
            ) : pathUrl === menu.childPath2 ? (
              <span key={menu.childLabel2} className="navbar-title text-left">
                {menu.childLabel2}
              </span>
            ) : null;
          })}
        </div>
        <div className="btn-group pull-right dropdown-menu-navbar">
          {renderProfile()}
          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuButton"
          >
            <a
              id="chatHeader"
              className="dropdown-item"
              href="https://wa.me/628118481436"
              target="blank"
            >
              <span className="dropdown-icon">
                <img
                  data-cfsrc="/img/icon/message-blue.png"
                  width="18px"
                  height="18px"
                  src="/img/icon/message-blue.png"
                  alt="message"
                />
              </span>
              Chat Customer Service
            </a>
            <Link to="/change-password" className="dropdown-item">
              <span className="dropdown-icon">
                <img
                  data-cfsrc="/img/icon/lock-blue.png"
                  width="18px"
                  height="18px"
                  src="/img/icon/lock-blue.png"
                  alt="lock"
                />
              </span>
              Change Password
            </Link>
            <a
              id="logoutButtonUser"
              className="dropdown-item"
              href="/login"
              onClick={userLogout}
            >
              <span className="dropdown-icon">
                <img
                  data-cfsrc="/img/icon/ic_logout.png"
                  width="18px"
                  height="18px"
                  src="/img/icon/ic_logout.png"
                  alt="exit"
                />
              </span>
              Logout
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
