import React from "react";
import { Link } from "react-router-dom";

const Sidebar = ({ listMenu }) => {
  return (
    <>
      <div className="sidebar-header text-center" style={{ padding: "15px" }}>
        <img
          className="sidebar-small-logo"
          data-cfsrc="/img/logo/short_logo.png"
          src="/img/logo/short_logo.png"
          alt="aido-logo"
        />
      </div>
      <ul className="list-unstyled components">
        {listMenu &&
          listMenu.map((menu) => (
            <li
              key={menu.label}
              className="list-menu"
              style={
                window.location.pathname === menu.path ||
                window.location.pathname === menu.childPath ||
                window.location.pathname === menu.childPath2
                  ? {
                      fontWeight: "bold",
                      backgroundColor: "blueviolet",
                    }
                  : null
              }
            >
              <Link to={menu.path}>
                <span style={{ padding: "10px" }}>
                  <i className="sidebar-icon">
                    <img
                      className="sidebar-icon"
                      data-cfsrc={menu.icon}
                      src={menu.icon}
                      alt="icon"
                    />
                  </i>
                </span>
              </Link>
            </li>
          ))}
      </ul>
    </>
  );
};

export default Sidebar;
