import React, { Fragment, useEffect, useState } from "react";
import DashboardLayout from "../../Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompletedCallDetail,
  updateStatusCompletedCall,
  updateDocumentCompletedCall,
  sendEmailDocument,
} from "../../../../actions";
import { useParams } from "react-router";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import Loading from "../../../../components/Loading";
import Swal from "sweetalert2";
import { Badge, Modal } from "react-bootstrap";

const Breadcrumb = (props) => {
  return (
    <nav key={props.label} aria-label="breadcrumb" id="breadcrumb-id">
      <ol className="breadcrumb pl-0">
        <li className="breadcrumb-item">
          <a>Dashboard</a>
        </li>
        <li className="breadcrumb-item" aria-current="page">
          <Link to={props.path}>{props.menu}</Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {props.label}
        </li>
      </ol>
    </nav>
  );
};

const CompletedCallTeleDetail = () => {
  let { orderId } = useParams();
  let history = useHistory();
  const [modalPrescription, setModalPrescription] = useState(false);
  const [modalLab, setModalLab] = useState(false);
  const [modalOtherRef, setModalOtherRef] = useState(false);
  const [document, setDocument] = useState([]);
  const [categoryDoc, setCategoryDoc] = useState("");

  const completedCallTele = useSelector((state) => state.completedCallTele);
  const dispatch = useDispatch();

  const role = JSON.parse(localStorage.getItem("user"));
  const roleUser = role.user_role_management_id.id;
  const q = {};

  const { loading, error, order } = completedCallTele;

  useEffect(() => {
    dispatch(getCompletedCallDetail(orderId));
  }, [orderId, dispatch]);

  const hideModal = () => {
    setModalPrescription(false);
    setModalLab(false);
    setModalOtherRef(false);
  };

  const handleChangeFile = (event) => {
    setDocument(event.target.files);
  };

  const uploadDocument = () => {
    const formData = new FormData();
    formData.append("category", categoryDoc);
    for (let i = 0; i < document.length; i++) {
      formData.append(`document`, document[i]);
    }

    dispatch(updateDocumentCompletedCall(orderId, formData))
      .then((result) => {
        if (result) {
          dispatch(getCompletedCallDetail(orderId));
        }
      })
      .then(() => {
        setCategoryDoc("");
        setDocument([]);
        hideModal();
      })
      .then(
        setTimeout(
          () =>
            Swal.fire({
              icon: "success",
              text: "Update Teleconsultation Success",
            }),
          1000
        )
      );
  };

  const sendEmail = () => {
    dispatch(sendEmailDocument(orderId))
      .then((result) => {
        dispatch(getCompletedCallDetail(orderId));
        setTimeout(
          () =>
            Swal.fire({
              icon: "success",
              text: "Send Document Success",
            }),
          1000
        );
      })
      .catch((error) => {
        if (error.response) {
          dispatch(getCompletedCallDetail(orderId));
          setTimeout(
            () =>
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response.data.message,
              }),
            1000
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };

  const updateStatus = () => {
    const form = new FormData();
    form.append("status", completedCallTele.nextStatus.code);
    if (orderId !== "" && form.get("status") !== null) {
      Swal.fire({
        title: "Reconfirmation",
        text: `Apakah anda yakin ingin update status ke "${completedCallTele.nextStatus.description}"?`,
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#7cb342",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          // Zzz...
          dispatch(updateStatusCompletedCall(orderId, form, q))
            .then(() =>
              setTimeout(
                () =>
                  Swal.fire({
                    icon: "success",
                    text: "Update Teleconsultation Success",
                  }),
                500
              )
            )
            .then(() =>
              setTimeout(
                () => (window.location = "/completed-call-teleconsultation"),
                1500
              )
            );
        }
      });
    }
  };

  const changeStatus = () => {
    const form = new FormData();
    form.append("status", completedCallTele.nextStatus.code);
    if (orderId !== "" && form.get("status") !== null) {
      Swal.fire({
        title: "Reconfirmation",
        text: `Anda belum mengirimkan surat rujukan ke pasien, apakah anda yakin untuk melanjutkan proses ini??`,
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#7cb342",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          // Hftt...
          dispatch(updateStatusCompletedCall(orderId, form, q))
            .then(() =>
              setTimeout(
                () =>
                  Swal.fire({
                    icon: "success",
                    text: "Update Teleconsultation Success",
                  }),
                500
              )
            )
            .then(() =>
              setTimeout(
                () => (window.location = "/completed-call-teleconsultation"),
                1500
              )
            );
        }
      });
    }
  };

  let file = "";

  return (
    <DashboardLayout>
      <Breadcrumb
        path={"/completed-call-teleconsultation"}
        menu={"List Waiting for Teleconsultation"}
        label={"Detail - List Waiting for Teleconsultation"}
      />
      <div className="form-wrapper" id="detail-complete-call-teleconsul-order">
        {loading ? (
          <Loading />
        ) : error ? (
          error.meesage
        ) : (
          <Fragment>
            <div className="wrapper-form-title">
              <span className="form-title">
                Completed Call Teleconsultation Detail
              </span>
            </div>

            <div className="border-bottom-section">
              <div className="table-responsive">
                <table
                  id="tableScheduler"
                  className="table table-striped table-bordered"
                >
                  <tbody className="text-left">
                    <tr>
                      <td>Order Id</td>
                      <td colSpan="2">{order.order_id}</td>
                    </tr>
                    <tr>
                      <td>Customer</td>
                      <td>{order.customer_name}</td>
                    </tr>
                    <tr>
                      <td>Patient</td>
                      <td>{order.patient_name}</td>
                    </tr>
                    <tr>
                      <td>Patien DOB</td>
                      <td>{moment(order.patient_dob).format("DD MMM Y")}</td>
                    </tr>
                    <tr>
                      <td>Patient Phone</td>
                      <td>{order.patient_phone}</td>
                    </tr>
                    <tr>
                      <td>Doctor</td>
                      <td>{order.doctor_name}</td>
                    </tr>
                    {
                      localStorage.getItem("parentId") == 3295 ? (
                        <>
                          <tr>
                            <td>Type</td>
                            <td>
                              {!order.isHealthScreening && !order.isPremium ? "Free Tele" : !order.eventId && order.isPremium ? "Tanya Dokter Premium" : order.isHealthScreening ? "Health Screening" : ""}
                            </td>
                          </tr>
                          {
                            order.isHealthScreening ? 
                              <tr>
                                  <td>Event Name</td>
                                  <td>{order.event_name} {order.corporate_name ? ` - ${order.corporate_name}` : ""}</td>
                              </tr> : <></>
                          }
                        </>
                      ) : <></>
                    }
                    <tr>
                      <td>Schedule</td>
                      <td>
                        {moment(order.scheduleDate)
                          .utc()
                          .format("D MMMM Y HH:mm:ss")}
                      </td>
                    </tr>
                    <tr>
                      <td>Start</td>
                      <td>{order.startTime}</td>
                    </tr>
                    <tr>
                      <td>End</td>
                      <td>{order.endTime}</td>
                    </tr>
                    <tr>
                      <td>Gender</td>
                      <td>{order.gender}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{order.email}</td>
                    </tr>
                    <tr>
                      <td>Jaminan</td>
                      <td>
                        {order.insurance_img_url != null ? (
                          <span className="label label-info">
                            <a
                              href={order.insurance_img_url}
                              target="_blank"
                              download
                            >
                              {order.insurance_img_url}
                            </a>
                          </span>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>{order.statusDesc}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {completedCallTele.nextStatus &&
            completedCallTele.nextStatus !== 0 ? (
              <div className="form-btn-group text-right mb-2">
                <input
                  type="hidden"
                  name="nextStatus"
                  value="{{ $data['nextStatus']['code'] }}"
                />
                {roleUser !== 20 ? (
                  <button
                    type="button"
                    className="btn btn-outline-primary mr-1"
                    onClick={sendEmail}
                  >
                    Send Email
                  </button>
                ) : null}

                {roleUser === 20 ? (
                  order.status === 85 ? (
                    <button className="btn btn-primary" disabled>
                      Next Status:{completedCallTele.nextStatus.description}
                    </button>
                  ) : order.consultDetail &&
                    order.consultDetail.sendEmailFlag == false &&
                    (order.consultDetail.prescription == 1 ||
                      order.consultDetail.labReference == 1 ||
                      order.consultDetail.ortherRef == 1 ||
                      order.consultDetail.homecare == 1) ? (
                    <>
                      <button
                        type="button"
                        onClick={changeStatus}
                        className="btn btn-primary"
                      >
                        Next Status : {completedCallTele.nextStatus.description}
                      </button>
                    </>
                  ) : (
                    <button 
                      type="button" 
                      className="btn btn-primary"
                      onClick={updateStatus}
                      >
                      Next Status : {completedCallTele.nextStatus.description}
                    </button>
                  )
                ) : order.consultDetail &&
                  order.consultDetail.sendEmailFlag == false &&
                  (order.consultDetail.prescription == 1 ||
                    order.consultDetail.labReference == 1 ||
                    order.consultDetail.ortherRef == 1 ||
                    order.consultDetail.homecare == 1) ? (
                  <>
                    <button
                      type="button"
                      onClick={changeStatus}
                      className="btn btn-primary"
                    >
                      Next Status : {completedCallTele.nextStatus.description}
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary mb-1"
                    onClick={updateStatus}
                  >
                    Next Status : {completedCallTele.nextStatus.description}
                  </button>
                )}
              </div>
            ) : null}

            <div className="wrapper-form-title">
              <span className="form-title">Teleconsultation Document</span>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-outline-primary mb-2 mr-1"
                  data-toggle="modal"
                  data-target="#prescriptionUploadModal"
                  onClick={() => {
                    setModalPrescription(true);
                    setCategoryDoc("prescription");
                  }}
                >
                  + Upload Prescription
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary mb-2 mr-1"
                  data-toggle="modal"
                  data-target="#labReferenceUploadModal"
                  onClick={() => {
                    setModalLab(true);
                    setCategoryDoc("lab_ref");
                  }}
                >
                  + Upload Lab Reference
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary mb-2 mr-1"
                  data-toggle="modal"
                  data-target="#otherReferenceUploadModal"
                  onClick={() => {
                    setModalOtherRef(true);
                    setCategoryDoc("other_ref");
                  }}
                >
                  + Upload Other Reference
                </button>
                {roleUser === 20 ? (
                  <button
                    type="button"
                    className="btn btn-outline-primary mb-2 mr-1"
                    onClick={sendEmail}
                  >
                    Send Email
                  </button>
                ) : null}
              </div>
            </div>

            <div className="border-bottom-section">
              <table className="table table-striped table-bordered">
                <thead className="table-header">
                  <tr>
                    <th className="table-header-middle">No</th>
                    <th className="table-header-middle">Document URL</th>
                    <th className="table-header-middle">Category</th>
                  </tr>
                </thead>

                <tbody>
                  {order.document ? (
                    order.document.length > 0 ? (
                      order.document.map((doc, index) => (
                        <tr key={index}>
                          <td className="num"></td>
                          <td>
                            {" "}
                            <a href={doc.docUrl} target="_blank">
                              {doc.docUrl}
                            </a>
                          </td>
                          <td>
                            <Badge pill variant="primary">
                              {doc.category}
                            </Badge>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr key="empty">
                        <td colSpan="13">Empty data</td>
                      </tr>
                    )
                  ) : null}
                </tbody>
              </table>
            </div>

            <Modal show={modalPrescription} onHide={hideModal} animation={true}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Prescription Document Upload
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hideModal}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <form onSubmit={uploadDocument} encType="multipart/form-data">
                  <div className="modal-body">
                    <input type="hidden" name="category" value="prescription" />
                    <div className="form-group">
                      <label>
                        Prescription Document{" "}
                        <small>
                          <i>(max 100kb)</i>
                        </small>
                      </label>
                      <input
                        type="file"
                        className="form-control-file"
                        name="document"
                        onChange={handleChangeFile}
                        ref={(input) => {
                          file = input;
                        }}
                        multiple
                        required
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="reset"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={() => setModalPrescription(false)}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={uploadDocument}
                    >
                      Save changes
                    </button>
                  </div>
                </form>
              </div>
            </Modal>

            <Modal show={modalLab} onHide={hideModal} animation={true}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Lab Reference Document Upload
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hideModal}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <form encType="multipart/form-data">
                  <div className="modal-body">
                    <input type="hidden" name="category" value="lab_ref" />
                    <div className="form-group">
                      <label>
                        Lab Reference Document{" "}
                        <small>
                          <i>(max 100kb)</i>
                        </small>
                      </label>
                      <input
                        type="file"
                        className="form-control-file"
                        name="document[]"
                        onChange={handleChangeFile}
                        ref={(input) => {
                          file = input;
                        }}
                        multiple
                        required
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="reset"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={() => setModalLab(false)}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={uploadDocument}
                    >
                      Save changes
                    </button>
                  </div>
                </form>
              </div>
            </Modal>

            <Modal show={modalOtherRef} onHide={hideModal} animation={true}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Other Reference Document Upload
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hideModal}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <form encType="multipart/form-data">
                  <div className="modal-body">
                    <input type="hidden" name="category" value="other_ref" />
                    <div className="form-group">
                      <label>
                        Other Reference Document{" "}
                        <small>
                          <i>(max 100kb)</i>
                        </small>
                      </label>
                      <input
                        type="file"
                        className="form-control-file"
                        name="document[]"
                        onChange={handleChangeFile}
                        ref={(input) => {
                          file = input;
                        }}
                        multiple
                        required
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={() => setModalOtherRef(false)}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={uploadDocument}
                    >
                      Save changes
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
          </Fragment>
        )}
      </div>
    </DashboardLayout>
  );
};

export default CompletedCallTeleDetail;
