import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "../../Layout";
import {
  addEducation,
  deleteEducation,
  getEducation,
  getUniversities,
  updateEducation,
} from "../../../../actions";
import Modal from "../../Components/Modal";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";

const EducationTele = () => {
  const [educationData, setEducationData] = useState({
    degree: "",
    major: "",
    university: "",
    startYear: "",
    endYear: "",
  });
  const [id, setId] = useState("");
  const [addEducationModal, setAddEducationModal] = useState(false);
  const [updateEducationModal, setUpdateEducationModal] = useState(false);
  const education = useSelector((state) => state.education);
  const { state } = useLocation();
  const dispatch = useDispatch();

  const initState = () => {
    setEducationData({
      degree: "",
      major: "",
      university: "",
      startYear: "",
      endYear: "",
    });
    setId("");
  };

  useEffect(() => {
    dispatch(getUniversities());
    dispatch(getEducation(state));
  }, []);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  // Serius Mode On
  const year = () => {
    let max = new Date().getFullYear(),
      min = max - 60,
      result = [];

    for (let i = max; i >= min; i--) {
      result.push(i);
    }
    return result;
  };

  const handleCloseModal = () => {
    initState();
    setAddEducationModal(false);
    setUpdateEducationModal(false);
  };

  const handleAddEducation = () => {
    setAddEducationModal(true);
  };

  const actionAddEducation = () => {
    const form = new FormData();
    form.append("degree", educationData.degree);
    form.append("major", educationData.major);
    form.append("university", educationData.university);
    form.append("startYear", educationData.startYear);
    form.append("endYear", educationData.endYear);

    dispatch(addEducation(state, form))
      .then((result) => {
        if (result) {
          dispatch(getEducation(state));
        }
      })
      .then(() => {
        initState();
        setAddEducationModal(false);
        setTimeout(
          () => Swal.fire("Success!", "Data Created", "success"),
          1000
        );
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  };

  const renderAddEducation = () => {
    return (
      <Modal
        modalTitle="Add Education"
        show={addEducationModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        onSubmit={actionAddEducation}
      >
        <Row>
          <Col md>
            <div className="form-group">
              <label>Degree</label>
              <select
                className="form-control"
                value={educationData.degree}
                onChange={(e) =>
                  setEducationData({ ...educationData, degree: e.target.value })
                }
              >
                <option value="">Select Degree</option>
                <option value="Diploma 1">Diploma 1</option>
                <option value="Diploma 3">Diploma 3</option>
                <option value="Diploma 4">Diploma 4</option>
                <option value="S1 + Pendidikan Profesi">
                  S1 + Pendidikan Profesi
                </option>
                <option value="Spesialis">Spesialis</option>
                <option value="Sub-Spesialis">Sub-Spesialis</option>
                <option value="S2">S2</option>
              </select>
            </div>
          </Col>

          <Col md>
            <div className="form-group">
              <label>University Name</label>
              <select
                className="form-control"
                value={educationData.university}
                onChange={(e) =>
                  setEducationData({
                    ...educationData,
                    university: e.target.value,
                  })
                }
              >
                <option value="">Select University</option>
                {education.universities
                  ? education.universities.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Major</label>
              <select
                className="form-control"
                value={educationData.major}
                onChange={(e) =>
                  setEducationData({ ...educationData, major: e.target.value })
                }
              >
                <option value="">Select Major</option>
                <option value="1">Kedokteran</option>
                <option value="2">Keperawatan</option>
                <option value="3">Rehabilitasi Medis</option>
                <option value="4">Others</option>
              </select>
            </div>
          </Col>

          <Col>
            <div className="form-group">
              <label>Start Year</label>
              <select
                className="form-control"
                value={educationData.startYear}
                onChange={(e) =>
                  setEducationData({
                    ...educationData,
                    startYear: e.target.value,
                  })
                }
              >
                <option value="">Select Year</option>
                {year().map((data) => (
                  <option key={data} value={data}>
                    {data}
                  </option>
                ))}
              </select>
            </div>
          </Col>

          <Col>
            <div className="form-group">
              <label>End Year</label>
              <select
                className="form-control"
                value={educationData.endYear}
                onChange={(e) =>
                  setEducationData({
                    ...educationData,
                    endYear: e.target.value,
                  })
                }
              >
                <option value="">Select Year</option>
                {year().map((data) => (
                  <option key={data} value={data}>
                    {data}
                  </option>
                ))}
              </select>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  };

  const handleUpdateEducation = (data) => {
    setEducationData({
      degree: data.degree,
      major: data.major.id,
      university: data.university.id,
      startYear: data.startYear,
      endYear: data.endYear,
    });
    setId(data.id);
    setUpdateEducationModal(true);
  };

  const actionUpdateEducation = () => {
    const form = new FormData();
    form.append("degree", educationData.degree);
    form.append("major", educationData.major);
    form.append("university", educationData.university);
    form.append("startYear", educationData.startYear);
    form.append("endYear", educationData.endYear);

    dispatch(updateEducation(state, id, form))
      .then((result) => {
        if (result) {
          dispatch(getEducation(state));
        }
      })
      .then(() => {
        initState();
        setUpdateEducationModal(false);
        setTimeout(
          () => Swal.fire("Success!", "Data Updated", "success"),
          1000
        );
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  };

  const renderUpdateEducation = () => {
    return (
      <Modal
        modalTitle="Update Education"
        show={updateEducationModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        buttons={[
          {
            label: "Update",
            color: "warning",
            onClick: actionUpdateEducation,
          },
        ]}
      >
        <Row>
          <Col md>
            <div className="form-group">
              <label>Degree</label>
              <select
                className="form-control"
                value={educationData.degree}
                onChange={(e) =>
                  setEducationData({ ...educationData, degree: e.target.value })
                }
              >
                <option value="">Select Degree</option>
                <option value="Diploma 1">Diploma 1</option>
                <option value="Diploma 3">Diploma 3</option>
                <option value="Diploma 4">Diploma 4</option>
                <option value="S1 + Pendidikan Profesi">
                  S1 + Pendidikan Profesi
                </option>
                <option value="Spesialis">Spesialis</option>
                <option value="Sub-Spesialis">Sub-Spesialis</option>
                <option value="S2">S2</option>
              </select>
            </div>
          </Col>

          <Col md>
            <div className="form-group">
              <label>University Name</label>
              <select
                className="form-control"
                value={educationData.university}
                onChange={(e) =>
                  setEducationData({
                    ...educationData,
                    university: e.target.value,
                  })
                }
              >
                <option value="">Select University</option>
                {education.universities
                  ? education.universities.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Major</label>
              <select
                className="form-control"
                value={educationData.major}
                onChange={(e) =>
                  setEducationData({ ...educationData, major: e.target.value })
                }
              >
                <option value="">Select Major</option>
                <option value="1">Kedokteran</option>
                <option value="2">Keperawatan</option>
                <option value="3">Rehabilitasi Medis</option>
                <option value="4">Others</option>
              </select>
            </div>
          </Col>

          <Col>
            <div className="form-group">
              <label>Start Year</label>
              <select
                className="form-control"
                value={educationData.startYear}
                onChange={(e) =>
                  setEducationData({
                    ...educationData,
                    startYear: e.target.value,
                  })
                }
              >
                <option value="">Select Year</option>
                {year().map((data) => (
                  <option key={data} value={data}>
                    {data}
                  </option>
                ))}
              </select>
            </div>
          </Col>

          <Col>
            <div className="form-group">
              <label>End Year</label>
              <select
                className="form-control"
                value={educationData.endYear}
                onChange={(e) =>
                  setEducationData({
                    ...educationData,
                    endYear: e.target.value,
                  })
                }
              >
                <option value="">Select Year</option>
                {year().map((data) => (
                  <option key={data} value={data}>
                    {data}
                  </option>
                ))}
              </select>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  };

  const handleDeleteEducation = (data) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "grey",
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteEducation(state, data.id))
          .then((result) => {
            if (result) {
              dispatch(getEducation(state));
            }
            initState();
            setTimeout(
              () => Swal.fire("Success!", "Data Deleted", "success"),
              500
            );
          })
          .catch((err) => {
            if (err.response) {
              Swal.fire({
                icon: "error",
                text: err.response.data.message,
              });
            } else {
              Swal.fire({
                icon: "error",
                text: err.message,
              });
            }
          });
      }
    });
  };

  const renderEducation = () => {
    return education.loading ? (
      <tr>
        <td colSpan="6">
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        {education.dataEducation.length > 0 ? (
          education.dataEducation.map((data, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{data.degree ? data.degree : "-"}</td>
              <td>{data.university.name ? data.university.name : "-"}</td>
              <td>{data.startYear ? data.startYear : "-"}</td>
              <td>{data.endYear ? data.endYear : "-"}</td>
              <td>
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <span
                    className="form-title"
                    style={{ cursor: "pointer", width: "45px" }}
                    onClick={() => handleUpdateEducation(data)}
                  >
                    <i className="fa fa-pencil"></i>
                  </span>

                  <span
                    className="form-title"
                    style={{ cursor: "pointer", width: "45px" }}
                    onClick={() => handleDeleteEducation(data)}
                  >
                    <i className="fa fa-trash"></i>
                  </span>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="6">Empty data</td>
          </tr>
        )}
      </>
    );
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Add Education</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="row">
          <div className="col-md-6">
            <span id="finance-title" className="mb10">
              Education Data
            </span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8" style={{ paddingBottom: "1em" }}>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => handleAddEducation()}
            >
              Add Education Data
            </button>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "45px" }}
                >
                  No
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "200px" }}
                >
                  Degree
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "400px" }}
                >
                  University Name
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Start Year
                </th>

                <th rowSpan={2} className="table-header-middle">
                  End Year
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>{renderEducation()}</tbody>
          </table>
        </div>
      </div>
      {renderAddEducation()}
      {renderUpdateEducation()}
    </DashboardLayout>
  );
};

export default EducationTele;
