import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addTeams,
  deleteTeams,
  getAllTeams,
  getSpecialist,
  getTeamsById,
  updateTeams,
} from "../../../actions";
import Input from "../Components/Input";
import Modal from "../Components/Modal";
import Pagination from "../../../components/Pagination";
import DashboardLayout from "../Layout";
import queryString from "query-string";
import moment from "moment";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const MyTeamHomecare = () => {
  const [personalData, setPersonalData] = useState({
    avatar: [],
    isAvatarExist: "0",
    fullName: "",
    email: "",
    phone: "",
    dateOfBirth: "",
    gender: "",
    KTPNumber: "",
    ktp: [],
    isKTPExist: "0",
  });
  const [professionData, setProfessionData] = useState({
    profession: "",
    specialist: "",
    PK: "",
    workingExperience: "",
    STRNumber: "",
    str: [],
    isSTRExist: "0",
    SIPNumber: "",
    sip: [],
    isSIPExist: "0",
    description: "",
    teleconsultation: "0",
  });
  const [feeManagement, setFeeManagement] = useState({
    serviceFee: "",
    hospitalRevSharing: 0,
  });
  const [fileName, setFileName] = useState({
    avaName: "Upload Image",
    ktpName: "Upload Image",
    sipName: "Upload Image",
    strName: "Upload Image",
  });
  const [id, setId] = useState("");
  const [addTeamsModal, setAddTeamsModal] = useState(false);
  const [updateTeamsModal, setUpdateTeamsModal] = useState(false);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const searchKey = useRef("");
  const role = "";
  const myTeam = useSelector((state) => state.myTeam);
  const dispatch = useDispatch();

  const initState = () => {
    setPersonalData({
      avatar: [],
      isAvatarExist: "0",
      fullName: "",
      email: "",
      phone: "",
      dateOfBirth: "",
      gender: "",
      KTPNumber: "",
      ktp: [],
      isKTPExist: "0",
    });
    setProfessionData({
      profession: "",
      specialist: "",
      PK: "",
      workingExperience: "",
      STRNumber: "",
      str: [],
      isSTRExist: "0",
      SIPNumber: "",
      sip: [],
      isSIPExist: "0",
      description: "",
      teleconsultation: "0",
    });
    setFeeManagement({
      serviceFee: "",
      hospitalRevSharing: 0,
    });
    setFileName({
      avaName: "Upload Image",
      ktpName: "Upload Image",
      sipName: "Upload Image",
      strName: "Upload Image",
    });
    setId("");
  };

  useEffect(() => {
    dispatch(getAllTeams(role, search));
  }, [role, search]);

  useEffect(() => {
    let data = "";

    if (professionData.profession == "") {
      setProfessionData({
        ...professionData,
        specialist: "",
        PK: "",
      });
    } else if (professionData.profession == "1") {
      setProfessionData({
        ...professionData,
        PK: "",
      });
      data = "1";
    } else if (professionData.profession == "2") {
      data = "2";
    } else if (professionData.profession == "3") {
      setProfessionData({
        ...professionData,
        PK: "",
      });
      data = "3";
    } else if (professionData.profession == "8") {
      setProfessionData({
        ...professionData,
        PK: "",
      });
      data = "2";
    }
    dispatch(getSpecialist(data));
  }, [professionData.profession]);

  useEffect(() => {
    if (id !== "") {
      getData(myTeam.userData);
    }
  }, [myTeam.userData]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: myTeam.totalData,
    });
  }, [myTeam.totalData]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const handleCloseModal = () => {
    initState();
    setAddTeamsModal(false);
    setUpdateTeamsModal(false);
  };

  const handleAutoComplete = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      searchKey: searchKey.current.value,
      page: pagination.page,
      limit: pagination.limit,
    };

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleSearch = (page, limit) => {
    let values = {
      searchKey: searchKey.current.value,
    };
    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }
    if (limit) {
      values.limit = limit;
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleAddTeams = () => {
    setAddTeamsModal(true);
  };

  const actionAddTeams = () => {
    const form = new FormData();

    form.append("isAvatarExist", personalData.isAvatarExist);
    form.append("fullName", personalData.fullName);
    form.append("email", personalData.email);
    form.append("phone", personalData.phone);
    form.append("dateOfBirth", personalData.dateOfBirth);
    form.append("gender", personalData.gender);
    form.append("KTPNumber", personalData.KTPNumber);
    form.append("isKTPExist", personalData.isKTPExist);
    form.append("profession", professionData.profession);
    form.append("specialist", professionData.specialist);
    form.append("PK", professionData.PK);
    form.append("workingExperience", professionData.workingExperience);
    form.append("STRNumber", professionData.STRNumber);
    form.append("isSTRExist", professionData.isSTRExist);
    form.append("SIPNumber", professionData.SIPNumber);
    form.append("isSIPExist", professionData.isSIPExist);
    form.append("description", professionData.description);
    form.append("teleconsultation", professionData.teleconsultation);
    form.append("serviceFee", feeManagement.serviceFee);
    form.append("hospitalRevSharing", feeManagement.hospitalRevSharing);

    form.append("avatar", personalData.avatar);
    form.append("ktp", personalData.ktp);
    form.append("str", professionData.str);
    form.append("sip", professionData.sip);

    dispatch(addTeams(role, form, search))
      .then((result) => {
        if (result) {
          dispatch(getAllTeams(role, search));
        }
      })
      .then(() => {
        initState();
        setAddTeamsModal(false);
        setTimeout(
          () => Swal.fire("Success!", "Data Created", "success"),
          1000
        );
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  };

  const renderAddTeams = () => {
    return (
      <Modal
        modalTitle="Add Healthcare Professional"
        show={addTeamsModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        onSubmit={actionAddTeams}
      >
        <Row>
          <Col md>
            <div className="wrapper-form-title">
              <span style={{ fontSize: "20px" }}>Personal Data</span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Profile Picture</label>
              <Form.File
                custom
                label={fileName.avaName}
                accept="image/*"
                data-browse="Drag & Drop or Browse"
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    setFileName({
                      ...fileName,
                      avaName: e.target.files[0].name,
                    });
                    setPersonalData({
                      ...personalData,
                      avatar: e.target.files[0],
                      isAvatarExist: "1",
                    });
                  } else {
                    setFileName({
                      ...fileName,
                      avaName: "Upload Image",
                    });
                    setPersonalData({
                      ...personalData,
                      avatar: [],
                      isAvatarExist: "0",
                    });
                  }
                }}
              />
            </div>
          </Col>
          <Col md>
            <Input
              type="text"
              label="Full Name"
              placeholder="Input Full Name"
              value={personalData.fullName}
              onChange={(e) =>
                setPersonalData({
                  ...personalData,
                  fullName: e.target.value,
                })
              }
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="text"
              label="Email"
              placeholder="Input Email"
              value={personalData.email}
              onChange={(e) =>
                setPersonalData({
                  ...personalData,
                  email: e.target.value,
                })
              }
            />
          </Col>
          <Col md>
            <div className="form-group">
              <label>Phone Number</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">+62</div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Input Phone Number"
                  value={personalData.phone}
                  onChange={(e) =>
                    setPersonalData({
                      ...personalData,
                      phone: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="date"
              label="Date of Birth"
              value={personalData.dateOfBirth}
              onChange={(e) =>
                setPersonalData({
                  ...personalData,
                  dateOfBirth: e.target.value,
                })
              }
            />
          </Col>
          <Col md>
            <div className="form-group">
              <label>Gender</label>
              <select
                className="form-control"
                value={personalData.gender}
                onChange={(e) =>
                  setPersonalData({
                    ...personalData,
                    gender: e.target.value,
                  })
                }
              >
                <option value="">Select Gender</option>
                <option value="1">Male</option>
                <option value="2">Female</option>
              </select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="text"
              label="KTP Number"
              placeholder="Input KTP Number"
              value={personalData.KTPNumber}
              onChange={(e) =>
                setPersonalData({
                  ...personalData,
                  KTPNumber: e.target.value,
                })
              }
            />
          </Col>
          <Col md>
            <div className="form-group">
              <label>KTP Picture</label>
              <Form.File
                custom
                label={fileName.ktpName}
                accept="image/*"
                data-browse="Drag & Drop or Browse"
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    setFileName({
                      ...fileName,
                      ktpName: e.target.files[0].name,
                    });
                    setPersonalData({
                      ...personalData,
                      ktp: e.target.files[0],
                      isKTPExist: "1",
                    });
                  } else {
                    setFileName({
                      ...fileName,
                      ktpName: "Upload Image",
                    });
                    setPersonalData({
                      ...personalData,
                      ktp: [],
                      isKTPExist: "0",
                    });
                  }
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="wrapper-form-title">
              <span style={{ fontSize: "20px" }}>Profession Data</span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Profession</label>
              <select
                className="form-control"
                value={professionData.profession}
                onChange={(e) =>
                  setProfessionData({
                    ...professionData,
                    profession: e.target.value,
                  })
                }
              >
                <option value="">Select Profession</option>
                <option value="1">Doctor</option>
                <option value="2">Nurse</option>
                <option value="3">Medical Rehabilitation Therapist</option>
                <option value="8">Lab Analyst</option>
              </select>
            </div>
          </Col>
          <Col md>
            <div className="form-group">
              <label>Speciality</label>
              {professionData.profession ? (
                <select
                  className="form-control"
                  value={professionData.specialist}
                  onChange={(e) =>
                    setProfessionData({
                      ...professionData,
                      specialist: e.target.value,
                    })
                  }
                >
                  <option value="">Select Speciality</option>
                  {myTeam.specialist
                    ? myTeam.specialist.map((sp) => (
                        <option key={sp.id} value={sp.id}>
                          {sp.name}
                        </option>
                      ))
                    : null}
                </select>
              ) : (
                <select
                  className="form-control"
                  value={professionData.specialist}
                  disabled
                >
                  <option value="">Select Speciality</option>
                </select>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>PK (Nurse only)</label>
              {professionData.profession == "2" ? (
                <select
                  className="form-control"
                  value={professionData.PK}
                  onChange={(e) =>
                    setProfessionData({
                      ...professionData,
                      PK: e.target.value,
                    })
                  }
                >
                  <option value="">Select PK (Nurse only)</option>
                  <option value="1">PK 1</option>
                  <option value="2">PK 2</option>
                  <option value="3">PK 3</option>
                  <option value="4">PK 4</option>
                </select>
              ) : (
                <select
                  className="form-control"
                  value={professionData.PK}
                  disabled
                >
                  <option value="">Select PK (Nurse only)</option>
                </select>
              )}
            </div>
          </Col>
          <Col md>
            <Input
              type="number"
              label="Working Experience (Year)"
              placeholder="Input Working Experience (Year)"
              min="0"
              style={{ height: "38px" }}
              value={professionData.workingExperience}
              onChange={(e) =>
                setProfessionData({
                  ...professionData,
                  workingExperience: e.target.value,
                })
              }
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="text"
              label="STR Number"
              placeholder="Input STR Number"
              value={professionData.STRNumber}
              onChange={(e) =>
                setProfessionData({
                  ...professionData,
                  STRNumber: e.target.value,
                })
              }
            />
          </Col>
          <Col md>
            <div className="form-group">
              <label>STR Picture</label>
              <Form.File
                custom
                label={fileName.strName}
                accept="image/*"
                data-browse="Drag & Drop or Browse"
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    setFileName({
                      ...fileName,
                      strName: e.target.files[0].name,
                    });
                    setProfessionData({
                      ...professionData,
                      str: e.target.files[0],
                      isSTRExist: "1",
                    });
                  } else {
                    setFileName({
                      ...fileName,
                      strName: "Upload Image",
                    });
                    setProfessionData({
                      ...professionData,
                      str: [],
                      isSTRExist: "0",
                    });
                  }
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="text"
              label="SIP Number"
              placeholder="Input SIP Number"
              value={professionData.SIPNumber}
              onChange={(e) =>
                setProfessionData({
                  ...professionData,
                  SIPNumber: e.target.value,
                })
              }
            />
          </Col>
          <Col md>
            <div className="form-group">
              <label>SIP Picture</label>
              <Form.File
                custom
                label={fileName.sipName}
                accept="image/*"
                data-browse="Drag & Drop or Browse"
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    setFileName({
                      ...fileName,
                      sipName: e.target.files[0].name,
                    });
                    setProfessionData({
                      ...professionData,
                      sip: e.target.files[0],
                      isSIPExist: "1",
                    });
                  } else {
                    setFileName({
                      ...fileName,
                      sipName: "Upload Image",
                    });
                    setProfessionData({
                      ...professionData,
                      sip: [],
                      isSIPExist: "0",
                    });
                  }
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Summary Profile</label>
              <textarea
                rows="3"
                className="form-control"
                placeholder="Input Summary Profile"
                value={professionData.description}
                onChange={(e) =>
                  setProfessionData({
                    ...professionData,
                    description: e.target.value,
                  })
                }
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="wrapper-form-title">
              <span style={{ fontSize: "20px" }}>Fee Management</span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Service Fee</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">IDR</div>
                </div>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Input Service Fee"
                  min="0"
                  style={{ height: "38px" }}
                  value={feeManagement.serviceFee}
                  onChange={(e) =>
                    setFeeManagement({
                      ...feeManagement,
                      serviceFee: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </Col>
          <Col md>
            <div className="form-group">
              <label>Provider Revenue Sharing (%)</label>
              <div className="input-group">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Input Provider Revenue Sharing (%)"
                  min="0"
                  max="100"
                  style={{ height: "38px" }}
                  value={feeManagement.hospitalRevSharing}
                  onChange={(e) =>
                    setFeeManagement({
                      ...feeManagement,
                      hospitalRevSharing: e.target.value,
                    })
                  }
                />
                <div className="input-group-append">
                  <div className="input-group-text">%</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  };

  const handleUpdateTeams = (teamId) => {
    setId(teamId);
    dispatch(getTeamsById(teamId)).then(setUpdateTeamsModal(true));
  };

  const getData = (data) => {
    setPersonalData({
      ...personalData,
      fullName: data.user.fullName,
      email: data.user.email,
      phone: data.user.phone,
      dateOfBirth: moment(data.user.birthday).format("YYYY-MM-DD"),
      gender: data.user.gender,
      KTPNumber: data.KTPNumber,
    });
    setProfessionData({
      ...professionData,
      profession: data.profession,
      specialist: data.specialist,
      PK: data.PK,
      workingExperience: data.workExperience,
      STRNumber: data.STRNumber,
      SIPNumber: data.SIPNumber,
      description: data.description,
    });
    setFeeManagement({
      serviceFee: data.serviceFee,
      hospitalRevSharing: data.hospital_rev_sharing,
    });
    setFileName({
      avaName: data.user.avatar ? data.user.avatar : "Upload Image",
      ktpName: data.KTPFile ? data.KTPFile : "Upload Image",
      sipName: data.SIPFile ? data.SIPFile : "Upload Image",
      strName: data.STRFile ? data.STRFile : "Upload Image",
    });
  };

  const actionUpdateTeams = () => {
    const form = new FormData();

    form.append("isAvatarExist", personalData.isAvatarExist);
    form.append("fullName", personalData.fullName);
    form.append("email", personalData.email);
    form.append("phone", personalData.phone);
    form.append("dateOfBirth", personalData.dateOfBirth);
    form.append("gender", personalData.gender);
    form.append("KTPNumber", personalData.KTPNumber);
    form.append("isKTPExist", personalData.isKTPExist);
    form.append("profession", professionData.profession);
    form.append("specialist", professionData.specialist);
    form.append("PK", professionData.PK);
    form.append("workingExperience", professionData.workingExperience);
    form.append("STRNumber", professionData.STRNumber);
    form.append("isSTRExist", professionData.isSTRExist);
    form.append("SIPNumber", professionData.SIPNumber);
    form.append("isSIPExist", professionData.isSIPExist);
    form.append("description", professionData.description);
    form.append("teleconsultation", professionData.teleconsultation);
    form.append("serviceFee", feeManagement.serviceFee);
    form.append("hospitalRevSharing", feeManagement.hospitalRevSharing);

    if (personalData.avatar) {
      form.append("avatar", personalData.avatar);
    }
    if (personalData.ktp) {
      form.append("ktp", personalData.ktp);
    }
    if (professionData.str) {
      form.append("str", professionData.str);
    }
    if (professionData.sip) {
      form.append("sip", professionData.sip);
    }

    dispatch(updateTeams(role, id, form, search))
      .then((result) => {
        if (result) {
          dispatch(getAllTeams(role, search));
        }
      })
      .then(() => {
        initState();
        setUpdateTeamsModal(false);
        setTimeout(
          () => Swal.fire("Success!", "Data Updated", "success"),
          1000
        );
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  };

  const renderUpdateTeams = () => {
    return (
      <Modal
        modalTitle="Update Healthcare Professional"
        show={updateTeamsModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        buttons={[
          {
            label: "Update",
            color: "warning",
            onClick: actionUpdateTeams,
          },
        ]}
      >
        <Row>
          <Col md>
            <div className="wrapper-form-title">
              <span style={{ fontSize: "20px" }}>Personal Data</span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Profile Picture</label>
              <Form.File
                custom
                label={fileName.avaName}
                accept="image/*"
                data-browse="Drag & Drop or Browse"
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    setFileName({
                      ...fileName,
                      avaName: e.target.files[0].name,
                    });
                    setPersonalData({
                      ...personalData,
                      avatar: e.target.files[0],
                      isAvatarExist: "1",
                    });
                  } else {
                    setFileName({
                      ...fileName,
                      avaName: "Upload Image",
                    });
                    setPersonalData({
                      ...personalData,
                      avatar: [],
                      isAvatarExist: "0",
                    });
                  }
                }}
              />
            </div>
          </Col>
          <Col md>
            <Input
              type="text"
              label="Full Name"
              placeholder="Input Full Name"
              value={personalData.fullName}
              onChange={(e) =>
                setPersonalData({
                  ...personalData,
                  fullName: e.target.value,
                })
              }
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="text"
              label="Email"
              placeholder="Input Email"
              value={personalData.email}
              onChange={(e) =>
                setPersonalData({
                  ...personalData,
                  email: e.target.value,
                })
              }
            />
          </Col>
          <Col md>
            <div className="form-group">
              <label>Phone Number</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">+62</div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Input Phone Number"
                  value={personalData.phone}
                  onChange={(e) =>
                    setPersonalData({
                      ...personalData,
                      phone: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="date"
              label="Date of Birth"
              value={personalData.dateOfBirth}
              onChange={(e) =>
                setPersonalData({
                  ...personalData,
                  dateOfBirth: e.target.value,
                })
              }
            />
          </Col>
          <Col md>
            <div className="form-group">
              <label>Gender</label>
              <select
                className="form-control"
                value={personalData.gender}
                onChange={(e) =>
                  setPersonalData({
                    ...personalData,
                    gender: e.target.value,
                  })
                }
              >
                <option value="">Select Gender</option>
                <option value="1">Male</option>
                <option value="2">Female</option>
              </select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="text"
              label="KTP Number"
              placeholder="Input KTP Number"
              value={personalData.KTPNumber}
              onChange={(e) =>
                setPersonalData({
                  ...personalData,
                  KTPNumber: e.target.value,
                })
              }
            />
          </Col>
          <Col md>
            <div className="form-group">
              <label>KTP Picture</label>
              <Form.File
                custom
                label={fileName.ktpName}
                accept="image/*"
                data-browse="Drag & Drop or Browse"
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    setFileName({
                      ...fileName,
                      ktpName: e.target.files[0].name,
                    });
                    setPersonalData({
                      ...personalData,
                      ktp: e.target.files[0],
                      isKTPExist: "1",
                    });
                  } else {
                    setFileName({
                      ...fileName,
                      ktpName: "Upload Image",
                    });
                    setPersonalData({
                      ...personalData,
                      ktp: [],
                      isKTPExist: "0",
                    });
                  }
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="wrapper-form-title">
              <span style={{ fontSize: "20px" }}>Profession Data</span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Profession</label>
              <select
                className="form-control"
                value={professionData.profession}
                onChange={(e) =>
                  setProfessionData({
                    ...professionData,
                    profession: e.target.value,
                  })
                }
              >
                <option value="">Select Profession</option>
                <option value="1">Doctor</option>
                <option value="2">Nurse</option>
                <option value="3">Medical Rehabilitation Therapist</option>
                <option value="8">Lab Analyst</option>
              </select>
            </div>
          </Col>
          <Col md>
            <div className="form-group">
              <label>Speciality</label>
              {professionData.profession ? (
                <select
                  className="form-control"
                  value={professionData.specialist}
                  onChange={(e) =>
                    setProfessionData({
                      ...professionData,
                      specialist: e.target.value,
                    })
                  }
                >
                  <option value="">Select Speciality</option>
                  {myTeam.specialist
                    ? myTeam.specialist.map((sp) => (
                        <option key={sp.id} value={sp.id}>
                          {sp.name}
                        </option>
                      ))
                    : null}
                </select>
              ) : (
                <select
                  className="form-control"
                  value={professionData.specialist}
                  disabled
                >
                  <option value="">Select Speciality</option>
                </select>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>PK (Nurse only)</label>
              {professionData.profession == "2" ? (
                <select
                  className="form-control"
                  value={professionData.PK}
                  onChange={(e) =>
                    setProfessionData({
                      ...professionData,
                      PK: e.target.value,
                    })
                  }
                >
                  <option value="">Select PK (Nurse only)</option>
                  <option value="1">PK 1</option>
                  <option value="2">PK 2</option>
                  <option value="3">PK 3</option>
                  <option value="4">PK 4</option>
                </select>
              ) : (
                <select
                  className="form-control"
                  value={professionData.PK}
                  disabled
                >
                  <option value="">Select PK (Nurse only)</option>
                </select>
              )}
            </div>
          </Col>
          <Col md>
            <Input
              type="number"
              label="Working Experience (Year)"
              placeholder="Input Working Experience (Year)"
              min="0"
              style={{ height: "38px" }}
              value={professionData.workingExperience}
              onChange={(e) =>
                setProfessionData({
                  ...professionData,
                  workingExperience: e.target.value,
                })
              }
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="text"
              label="STR Number"
              placeholder="Input STR Number"
              value={professionData.STRNumber}
              onChange={(e) =>
                setProfessionData({
                  ...professionData,
                  STRNumber: e.target.value,
                })
              }
            />
          </Col>
          <Col md>
            <div className="form-group">
              <label>STR Picture</label>
              <Form.File
                custom
                label={fileName.strName}
                accept="image/*"
                data-browse="Drag & Drop or Browse"
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    setFileName({
                      ...fileName,
                      strName: e.target.files[0].name,
                    });
                    setProfessionData({
                      ...professionData,
                      str: e.target.files[0],
                      isSTRExist: "1",
                    });
                  } else {
                    setFileName({
                      ...fileName,
                      strName: "Upload Image",
                    });
                    setProfessionData({
                      ...professionData,
                      str: [],
                      isSTRExist: "0",
                    });
                  }
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="text"
              label="SIP Number"
              placeholder="Input SIP Number"
              value={professionData.SIPNumber}
              onChange={(e) =>
                setProfessionData({
                  ...professionData,
                  SIPNumber: e.target.value,
                })
              }
            />
          </Col>
          <Col md>
            <div className="form-group">
              <label>SIP Picture</label>
              <Form.File
                custom
                label={fileName.sipName}
                accept="image/*"
                data-browse="Drag & Drop or Browse"
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    setFileName({
                      ...fileName,
                      sipName: e.target.files[0].name,
                    });
                    setProfessionData({
                      ...professionData,
                      sip: e.target.files[0],
                      isSIPExist: "1",
                    });
                  } else {
                    setFileName({
                      ...fileName,
                      sipName: "Upload Image",
                    });
                    setProfessionData({
                      ...professionData,
                      sip: [],
                      isSIPExist: "0",
                    });
                  }
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Summary Profile</label>
              <textarea
                rows="3"
                className="form-control"
                placeholder="Input Summary Profile"
                value={professionData.description}
                onChange={(e) =>
                  setProfessionData({
                    ...professionData,
                    description: e.target.value,
                  })
                }
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="wrapper-form-title">
              <span style={{ fontSize: "20px" }}>Fee Management</span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Service Fee</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">IDR</div>
                </div>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Input Service Fee"
                  min="0"
                  style={{ height: "38px" }}
                  value={feeManagement.serviceFee}
                  onChange={(e) =>
                    setFeeManagement({
                      ...feeManagement,
                      serviceFee: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </Col>
          <Col md>
            <div className="form-group">
              <label>Provider Revenue Sharing (%)</label>
              <div className="input-group">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Input Provider Revenue Sharing (%)"
                  min="0"
                  max="100"
                  style={{ height: "38px" }}
                  value={feeManagement.hospitalRevSharing}
                  onChange={(e) =>
                    setFeeManagement({
                      ...feeManagement,
                      hospitalRevSharing: e.target.value,
                    })
                  }
                />
                <div className="input-group-append">
                  <div className="input-group-text">%</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  };

  const handleDeleteTeams = (teamId) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "grey",
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteTeams(teamId, role, search))
          .then((result) => {
            if (result) {
              dispatch(getAllTeams(role, search));
            }
            initState();
            setTimeout(
              () => Swal.fire("Success!", "Data Deleted", "success"),
              500
            );
          })
          .catch((err) => {
            if (err.response) {
              Swal.fire({
                icon: "error",
                text: err.response.data.message,
              });
            } else {
              Swal.fire({
                icon: "error",
                text: err.message,
              });
            }
          });
      }
    });
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const renderTeams = () => {
    return myTeam.loading ? (
      <tr>
        <td colSpan="9">
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        {myTeam.teamMembers.length > 0 ? (
          myTeam.teamMembers.map((team, index) => (
            <tr key={team.id}>
              <td>{(pagination.page - 1) * 10 + index + 1}</td>
              <td>{team.id ? team.id : "-"}</td>
              <td>{team.fullName ? team.fullName : "-"}</td>
              <td>{team.phone ? "+62" + team.phone : "-"}</td>
              <td>
                {team.serviceFee ? formatter.format(team.serviceFee) : "-"}
              </td>
              <td>
                {team.hospitalRevSharing ? `${team.hospitalRevSharing}%` : "-"}
              </td>
              <td>
                {team.partnerRevSharing ? `${team.partnerRevSharing}%` : "-"}
              </td>
              <td>
                {team.active === 0 ? (
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    Not Active
                  </span>
                ) : team.isActive === 1 ? (
                  <span style={{ color: "green", fontWeight: "bold" }}>
                    Active
                  </span>
                ) : (team.isActive === 0 && team.additionalNote === null) ||
                  team.additionalNote === "" ? (
                  <span style={{ color: "blue", fontWeight: "bold" }}>
                    {team.statusDescription}
                  </span>
                ) : (team.isActive === 0 && team.additionalNote !== null) ||
                  team.additionalNote !== "" ? (
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    {team.additionalNote}
                  </span>
                ) : (
                  "-"
                )}
              </td>
              <td>
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <Link
                    to={{
                      pathname: "/my-team-homecare/education",
                      state: team.id,
                    }}
                  >
                    <span
                      className="form-title"
                      style={{ cursor: "pointer", width: "45px" }}
                    >
                      <i className="fa fa-graduation-cap"></i>
                    </span>
                  </Link>

                  <span
                    className="form-title"
                    style={{ cursor: "pointer", width: "45px" }}
                    onClick={() => handleUpdateTeams(team.id)}
                  >
                    <i className="fa fa-pencil"></i>
                  </span>

                  <span
                    className="form-title"
                    style={{ cursor: "pointer", width: "45px" }}
                    onClick={() => handleDeleteTeams(team.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </span>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="9">Empty data</td>
          </tr>
        )}
      </>
    );
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>My Team Homecare</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        <div className="row">
          <div className="col-md-6">
            <span id="finance-title" className="mb10">
              Team Member
            </span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8" style={{ paddingBottom: "1em" }}>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => handleAddTeams()}
            >
              Add Healthcare Professional
            </button>
          </div>

          <div className="col-md-4" id="search-form">
            <div className="input-group" style={{ paddingBottom: "1em" }}>
              <input
                type="text"
                className="form-control table-search-field"
                placeholder="Search Name"
                onChange={handleAutoComplete}
                ref={searchKey}
              />
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "45px" }}
                >
                  No
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "120px" }}
                >
                  Partner ID
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "250px" }}
                >
                  Name
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Phone Number
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Service Fee
                </th>

                <th colSpan={2} className="table-header-middle">
                  Revenue Sharing
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Status
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "180px" }}
                >
                  Action
                </th>
              </tr>

              <tr>
                <th>Provider</th>
                <th>Partner</th>
              </tr>
            </thead>
            <tbody>{renderTeams()}</tbody>
          </table>
        </div>

        {myTeam.totalData > 10 ? (
          <Pagination
            props={pagination}
            setProps={setPagination}
            onChange={(page, limit) => {
              handleSearch(page, limit);
            }}
          />
        ) : null}
      </div>
      {renderAddTeams()}
      {renderUpdateTeams()}
    </DashboardLayout>
  );
};

export default MyTeamHomecare;
