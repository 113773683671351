import axios from "../helpers/axios";
import { myTeamConstants } from "./constants";

export const getAllTeams = (role, search) => {
  return async (dispatch) => {
    dispatch({ type: myTeamConstants.GET_ALL_TEAMS_REQUEST });
    const res = await axios.get(`/TeamMember${role}?${search}`);
    if (res.status === 200) {
      dispatch({
        type: myTeamConstants.GET_ALL_TEAMS_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: myTeamConstants.GET_ALL_TEAMS_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getSpecialist = (profession) => {
  return async (dispatch) => {
    dispatch({ type: myTeamConstants.GET_SPECIALIST_REQUEST });
    const res = await axios.get(`/Specialist?profession=${profession}`);
    if (res.status === 200) {
      dispatch({
        type: myTeamConstants.GET_SPECIALIST_SUCCESS,
        payload: { specialist: res.data },
      });
    } else {
      dispatch({
        type: myTeamConstants.GET_SPECIALIST_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getTeamsById = (id) => {
  return async (dispatch) => {
    dispatch({ type: myTeamConstants.GET_TEAMS_BY_ID_REQUEST });
    const res = await axios.get(`/TeamMember/${id}`);
    if (res.status === 200) {
      dispatch({
        type: myTeamConstants.GET_TEAMS_BY_ID_SUCCESS,
        payload: { userData: res.data },
      });
    } else {
      dispatch({
        type: myTeamConstants.GET_TEAMS_BY_ID_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const addTeams = (role, form, search) => {
  return async (dispatch) => {
    dispatch({ type: myTeamConstants.ADD_NEW_TEAMS_REQUEST });
    const res = await axios.post(`/TeamMember${role}`, form);
    if (res.status === 200) {
      dispatch({
        type: myTeamConstants.ADD_NEW_TEAMS_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllTeams(role, search));
    } else {
      dispatch({
        type: myTeamConstants.ADD_NEW_TEAMS_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const addTeamsTele = (role, form, search) => {
  return async (dispatch) => {
    dispatch({ type: myTeamConstants.ADD_NEW_TEAMS_TELE_REQUEST });
    const res = await axios.post(`/TeamMember/Teleconsultation${role}`, form);
    if (res.status === 200) {
      dispatch({
        type: myTeamConstants.ADD_NEW_TEAMS_TELE_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllTeams(role, search));
    } else {
      dispatch({
        type: myTeamConstants.ADD_NEW_TEAMS_TELE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const updateTeams = (role, id, form, search) => {
  return async (dispatch) => {
    dispatch({ type: myTeamConstants.UPDATE_TEAMS_REQUEST });
    const res = await axios.put(`/TeamMember${role}/${id}`, form);
    if (res.status === 200) {
      dispatch({
        type: myTeamConstants.UPDATE_TEAMS_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllTeams(role, search));
    } else {
      dispatch({
        type: myTeamConstants.UPDATE_TEAMS_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const deleteTeams = (id, role, search) => {
  return async (dispatch) => {
    dispatch({ type: myTeamConstants.DELETE_TEAMS_REQUEST });
    const res = await axios.delete(`/TeamMember/${id}`, {
      payload: {
        id,
      },
    });
    if (res.status === 200) {
      dispatch({ type: myTeamConstants.DELETE_TEAMS_SUCCESS });
      dispatch(getAllTeams(role, search));
    } else {
      dispatch({
        type: myTeamConstants.DELETE_TEAMS_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
