import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findReportV2 } from "../../../actions";
import DashboardLayout from "../Layout";
import moment from "moment";
import ExcelJS from "exceljs";
import saveAs from "file-saver";

const OrderDailyReport = () => {
  const [filterReport, setFilterReport] = useState({
    stDate: "",
    enDate: "",
    orderType: "",
  });
  const [report, setReport] = useState({
    orders: [],
  });
  const orderDailyReport = useSelector((state) => state.orderDailyReport);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      filterReport.stDate !== "" &&
      filterReport.enDate !== "" &&
      filterReport.orderType !== ""
    ) {
      const form = new FormData();
      form.append("stDate", filterReport.stDate);
      form.append("enDate", filterReport.enDate);
      form.append("orderType", filterReport.orderType);

      dispatch(findReportV2(form));
    }
  }, [filterReport]);

  useEffect(() => {
    getData(orderDailyReport.orders);
  }, [orderDailyReport.orders]);

  const getData = (orders) => {
    setReport({
      orders: orders,
    });
  };

  const handleDownload = () => {
    const nf = new Intl.NumberFormat();
    const workbook = new ExcelJS.Workbook();
    let row;
    let fileName = filterReport.orderType == "1" ? "homevisit-" : "package-";

    const worksheet = workbook.addWorksheet("order");

    row = worksheet.addRow([
      "ID",
      "Order Id",
      "Tanggal Order Dibuat",
      "Jadwal Dipilih (Hari)",
      "Jadwal Dipilih (Jam)",
      "Tenaga Kesehatan",
      "Nama Pasien",
      "Pemeriksaan",
      "Harga",
      "Referensi",
      "Asal",
      "Remarks",
      "Alamat"
    ]);
    row.font = { bold: true };

    report.orders.map((data) => {
      worksheet.addRow([
        data.RN,
        data.orderId,
        data.orderDate ? moment(data.orderDate).format("DD MMM YYYY") : "-",
        data.scheduleDate ? data.scheduleDay+', '+moment(data.scheduleDate).format("DD MMM YYYY") : "-",
        (data.startTime ? moment(data.startTime, "HH:mm:ss").format("HH:mm") : "-") + 
        ' - ' + (data.endTime ? moment(data.endTime, "HH:mm:ss").format("HH:mm") : "-"),
        data.doctorName,
        data.patientName,
        data.packageName,
        data.totalFee ? 'Rp. '+ nf.format(data.totalFee) : 'Rp. -',
        data.reference,
        data.sources,
        data.complaints,
        data.address
      ]);
    });

      let date = new Date().getTime();
      workbook.xlsx.writeBuffer().then((buff) => {
        saveAs(new Blob([buff]), `${fileName}${date}.xlsx`);
      });

  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Order Daily Report</div>

        <div className="col">
          <hr />
        </div>
      </div>
      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        <div className="wrapper-form-title" id="btn-add">
          <span className="form-title">Filter Report</span>
        </div>

        <div className="row">
          <div className="form-group col-md-4">
            <label className="mb10">Start Date</label>
            <input
              className="form-control"
              type="date"
              value={filterReport.stDate}
              onChange={(e) =>
                setFilterReport({ ...filterReport, stDate: e.target.value })
              }
            />
          </div>

          <div className="form-group col-md-4">
            <label className="mb10">End Date</label>
            <input
              className="form-control"
              type="date"
              value={filterReport.enDate}
              onChange={(e) =>
                setFilterReport({ ...filterReport, enDate: e.target.value })
              }
            />
          </div>

          <div className="form-group col-md-4">
            <label className="mb10">Order Type</label>
            <select
              className="form-control"
              value={filterReport.orderType}
              onChange={(e) =>
                setFilterReport({ ...filterReport, orderType: e.target.value })
              }
            >
              <option value="">Select Order Type</option>
              <option value="1">Home Visit</option>
              <option value="6">Package</option>
            </select>
          </div>
        </div>

        <div style={{ width: "100%" }}>
          <hr />
        </div>

        <div className="text-right" style={{ marginTop: "25px" }}>
          {orderDailyReport.loading ? (
            <button type="button" className="btn btn-primary" disabled>
              Loading...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleDownload()}
            >
              Download
            </button>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default OrderDailyReport;
