import React, { useEffect, useState, useRef } from "react";
import DashboardLayout from "../Layout";
import queryString from "query-string";
// Query
import { useDispatch, useSelector } from "react-redux";
import {
  getDataGPOnsite,
  updateOrderGPOnsiteStatus
} from "../../../actions";
// Helpers
import moment from "moment";
// Pagination
import Pagination from "../../../components/Pagination";

// Alert
import Swal from "sweetalert2";

export default function GPOnsite() {
    
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const result = useSelector((state) => state.gpOnsite);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDataGPOnsite(query));
  }, [query, setQuery, dispatch]);

  // Pagination
  const [paginationProps, setPaginationProps] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });

  useEffect(() => {
    setPaginationProps((prev) => {
      return {
        ...prev,
        totalData: result.totalData,
      };
    });
  }, [result]);


  // Filter
  const searchType = useRef(null);
  const searchKey = useRef(null);

  // Submit Query & Pagination
  const submitFilter = (page, limit) => {
    let values = {};
    if (page) {
      values.page = page;
    } else {
      // Reset if searched
      values.page = 1;
      setPaginationProps((prev) => {
        return {
          ...prev,
          page: 1,
        };
      });
    }

    if (limit) {
      values.limit = limit;
    }
    
    if (searchType.current.value !== "") {
        values.searchType = searchType.current.value;

        if (searchKey.current.value !== "") {
            values.searchKey = searchKey.current.value;
        }
    }

    let query = queryString.stringify(values);
    setQuery(query);
  };

  // reset filter & pagination
  const handleReset = (page, limit) => {
    searchType.current.value = "";
    searchKey.current.value = "";

    let values = {};
    if (page) {
      values.page = page;
    } else {
      // Reset if searched
      values.page = 1;
      setPaginationProps((prev) => {
        return {
          ...prev,
          page: 1,
        };
      });
    }

    if (limit) {
      values.limit = limit;
    }

    if (searchType.current.value !== "") {
        values.searchType = searchType.current.value;

        if (searchKey.current.value !== "") {
            values.searchKey = searchKey.current.value;
        }
    }

    let query = queryString.stringify(values);
    setQuery(query);
  };

  const filterProps = {
    searchType,
    searchKey,
    submitFilter,
    handleReset,
  };

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const setOrderDone = (orderId) => {
    setLoading(true);

    dispatch(updateOrderGPOnsiteStatus(orderId))
      .then(() => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Order GP Onsite Status successfully updated",
          showConfirmButton: false,
        });
        window.location = "/gp-onsite"
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  }

  return (
    <DashboardLayout>
      <Layout>        
        {/* Filter */}
        <Filter {...filterProps} />
        {/* Table */}
        {loading ? <Loading /> : (
          <div className="table-responsive">
            <table
              id="tableGPOnsite"
              className="table table-striped table-bordered"
            >
              <thead className="table-header">
                {/* Rows: 17 */}
                <tr>
                  <th rowSpan={2} className="table-header-middle">
                    No
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    Order ID
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    Customer Name
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    DOB
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    Customer Phone
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    Order Date
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    Status
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {DataTables(result, paginationProps, setOrderDone)}
              </tbody>
            </table>
          </div>
        )}
        {/* Pagination */}
        <Pagination
          props={paginationProps}
          setProps={setPaginationProps}
          onChange={(page, limit) => {
            submitFilter(page, limit);
          }}
        />
      </Layout>
    </DashboardLayout>
  );
}

const DataTables = (result, paginationProps, setOrderDone) => {
  // Response Data
  const orders = result?.orders || [];
  
  return (
    <>
      {/* Empty */}
      {orders.length === 0 && (
        <tr>
          <td colSpan="8">Empty Data</td>
        </tr>
      )}

      {/* Has Data */}
      {orders.map((value, index) => {
        return (
          <tr key={index}>
            <td>{(paginationProps.page - 1) * 10 + index + 1}</td>
            <td>{value.orderId}</td>
            <td>{value.customerName}</td>
            <td>{value.dob}</td>
            <td>{value.customerPhone}</td>
            <td>{value.orderDate}</td>
            <td>{value.statusDesc}</td>
            <td width={"150px"} style={{ verticalAlign: "middle"}}>
              {
                <div style={{marginTop: "10px"}}>
                    <button className="btn btn-outline-info" onClick={() => setOrderDone(value.orderId)}>
                        Done
                    </button> 
                </div>
              }
            </td>
          </tr>
        );
      })}
    </>
  );
};

const Layout = ({ children }) => {
  // Default Layout
  return (
    <>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>
        <div>Order GP Onsite</div>
        <div className="col">
          <hr />
        </div>
      </div>
      <div className="table-wrapper">       
        {children}
      </div>
    </>
  );
};

const Filter = (props) => {
  const { searchType, searchKey, submitFilter, handleReset } = props;
  return (
    <>
      <div className="row mb15">
        <div className="link-btn">
          <button
            type="button"
            className="btn btn-link no-padding"
            id="filterBy"
            data-toggle="collapse"
            data-target="#collapseDetailGPOnsite"
          >
            Filter By
            <i className="fa fa-angle-down" style={{ marginLeft: "10px" }} />
          </button>
        </div>
        <div className="col">
          <hr />
        </div>
      </div>
      <div className="collapse" id="collapseDetailGPOnsite">
        <div className="row">
        <div className="col-md-3 neutral-border">
            <div className="form-group mb-md-0">
              <label>Search Type</label>
              <select className="form-control" ref={searchType}>
                <option value="">Select Type</option>
                <option value="orderId">Order Id</option>
                <option value="customerName">CustomerName</option>
                <option value="customerPhone">CustomerPhone</option>
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group mb-md-0">
              <label>Search: </label>
              <input type="text" className="form-control" ref={searchKey} />
            </div>
          </div>
        </div>
        <div className="fm-btn-gr text-right">
          <button
            type="button"
            className="btn btn-secondary"
            style={{ marginRight: "10px" }}
            onClick={() => {
              handleReset();
            }}
          >
            Reset
          </button>
          <button
            onClick={() => {
              submitFilter();
            }}
            type="button"
            className="btn btn-primary"
          >
            Apply
          </button>
        </div>

        <div style={{ width: "100%" }}>
          <hr />
        </div>
      </div>
    </>
  );
};
