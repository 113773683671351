import axios from "../helpers/axios";
import axiosPartner from "../helpers/axiosPartner";
import { paymentOfflineConstants } from "./constants";

export const getListPaymentOffline = (search) => {
  return async (dispatch) => {
    dispatch({ type: paymentOfflineConstants.GET_REGISTERPATIENT_LIST_REQUEST });
    const res = await axios.get(`/Ipd/listPatientPayOffline?${search}`);
    if (res.status === 200) {
      dispatch({
        type: paymentOfflineConstants.GET_REGISTERPATIENT_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: paymentOfflineConstants.GET_REGISTERPATIENT_LIST_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getRegisterPatient = (search) => {
    return async (dispatch) => {
      dispatch({ type: paymentOfflineConstants.GET_REGISTERPATIENT_LIST_REQUEST });
      const res = await axios.get(`/Ipd/listPatient?${search}`);
      if (res.status === 200) {
        dispatch({
          type: paymentOfflineConstants.GET_REGISTERPATIENT_LIST_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: paymentOfflineConstants.GET_REGISTERPATIENT_LIST_FAILURE,
          payload: { error: res.data.error },
        });
      }
    };
};

export const findPatients = (form) => {
  return async (dispatch) => {
    dispatch({ type: paymentOfflineConstants.FIND_PATIENT_DETAIL_REQUEST });
    const res = await axios.post("/Ipd/patient/find", form);
    if (res.status === 200) {
      dispatch({
        type: paymentOfflineConstants.FIND_PATIENT_DETAIL_SUCCESS,
        payload: { patientDetail: res.data },
      });
    } else {
      dispatch({
        type: paymentOfflineConstants.FIND_PATIENT_DETAIL_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const findAddress = (search) => {
  return async (dispatch) => {
    dispatch({ type: paymentOfflineConstants.GET_ADDRESS_REQUEST });
    const res = await axios.get(`Ipd/address/find?${search}`);
    if (res.status === 200) {
      dispatch({
        type: paymentOfflineConstants.GET_ADDRESS_SUCCESS,
        payload: { addressData: res.data },
      });
    } else {
      dispatch({
        type: paymentOfflineConstants.GET_ADDRESS_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const submitPayment = (form) => {
  return async (dispatch) => {
    dispatch({ type: paymentOfflineConstants.SUBMIT_PAYMENT_REQUEST });
    const res = await axios.post("/Ipd/submitDataPayment", form);
    if (res.status === 200) {
      dispatch({
        type: paymentOfflineConstants.SUBMIT_PAYMENT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: paymentOfflineConstants.SUBMIT_PAYMENT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getCountry = () => {
  return async (dispatch) => {
    dispatch({ type: paymentOfflineConstants.GET_COUNTRY_REQUEST });
    const res = await axiosPartner.get(`/App/Customer/Me/Country`);
    if (res.status === 200) {
      dispatch({
        type: paymentOfflineConstants.GET_COUNTRY_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: paymentOfflineConstants.GET_COUNTRY_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getProvince = (id) => {
  return async (dispatch) => {
    dispatch({ type: paymentOfflineConstants.GET_STATE_REQUEST });
    const res = await axiosPartner.get(`/App/Customer/Me/State/${id}`);
    if (res.status === 200) {
      dispatch({
        type: paymentOfflineConstants.GET_STATE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: paymentOfflineConstants.GET_STATE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getCity = (id) => {
  return async (dispatch) => {
    dispatch({ type: paymentOfflineConstants.GET_CITY_REQUEST });
    const res = await axiosPartner.get(`/App/Customer/Me/City/${id}`);
    if (res.status === 200) {
      dispatch({
        type: paymentOfflineConstants.GET_CITY_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: paymentOfflineConstants.GET_CITY_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getDistrict = (id) => {
  return async (dispatch) => {
    dispatch({ type: paymentOfflineConstants.GET_DISTRICT_REQUEST });
    const res = await axiosPartner.get(`/App/Customer/Me/District/${id}`);
    if (res.status === 200) {
      dispatch({
        type: paymentOfflineConstants.GET_DISTRICT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: paymentOfflineConstants.GET_DISTRICT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getSubDistrict = (id) => {
  return async (dispatch) => {
    dispatch({ type: paymentOfflineConstants.GET_SUBDISTRICT_REQUEST });
    const res = await axiosPartner.get(`/App/Customer/Me/Subdistrict/${id}`);
    if (res.status === 200) {
      dispatch({
        type: paymentOfflineConstants.GET_SUBDISTRICT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: paymentOfflineConstants.GET_SUBDISTRICT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getPostalCode = (id) => {
  return async (dispatch) => {
    dispatch({ type: paymentOfflineConstants.GET_POSTALCODE_REQUEST });
    const res = await axiosPartner.get(`/App/Customer/Me/PostalCode/${id}`);
    if (res.status === 200) {
      dispatch({
        type: paymentOfflineConstants.GET_POSTALCODE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: paymentOfflineConstants.GET_POSTALCODE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};