import React, { useEffect, useState, useRef } from "react";
import DashboardLayout from "../../Layout";
import queryString from "query-string";
// Query
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getDataCheckInHS,
  checkInHS
} from "../../../../actions";

// Helpers
import moment from "moment";

// Pagination
import Pagination from "../../../../components/Pagination";

// Alert
import Swal from "sweetalert2";

export default function CheckInHSEvent() {
  let { eventId } = useParams();
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const result = useSelector((state) => state.healthScreening);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDataCheckInHS(eventId, query));
  }, [eventId, query, setQuery, dispatch]);

  // Pagination
  const [paginationProps, setPaginationProps] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });

  useEffect(() => {
    setPaginationProps((prev) => {
      return {
        ...prev,
        totalData: result.totalData,
      };
    });
  }, [result]);


  // Filter
  const type = useRef(null);
  const search = useRef(null);

  // Submit Query & Pagination
  const submitFilter = (page, limit) => {
    let values = {};
    if (page) {
      values.page = page;
    } else {
      // Reset if searched
      values.page = 1;
      setPaginationProps((prev) => {
        return {
          ...prev,
          page: 1,
        };
      });
    }

    if (limit) {
      values.limit = limit;
    }
    
    if (type.current.value !== "") {
        values.type = type.current.value;

        if (search.current.value !== "") {
            values.search = search.current.value;
        }
    }

    let query = queryString.stringify(values);
    setQuery(query);
  };

  // reset filter & pagination
  const handleReset = (page, limit) => {
    type.current.value = "";
    search.current.value = "";

    let values = {};
    if (page) {
      values.page = page;
    } else {
      // Reset if searched
      values.page = 1;
      setPaginationProps((prev) => {
        return {
          ...prev,
          page: 1,
        };
      });
    }

    if (limit) {
      values.limit = limit;
    }

    if (type.current.value !== "") {
        values.type = type.current.value;

        if (search.current.value !== "") {
            values.search = search.current.value;
        }
    }

    let query = queryString.stringify(values);
    setQuery(query);
  };

  const filterProps = {
    type,
    search,
    submitFilter,
    handleReset,
  };

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const setOrderDone = (orderId) => {
    setLoading(true);

    var orderData = result ? result.orders : [];
    var filterData = orderData.find(val => val.orderId = orderId);

    var orderId = filterData ? filterData.orderId : null;
    var eventIds = filterData ? filterData.eventId : null; //parentEventId
    var eventName = filterData ? filterData.eventName : null;
    var serviceName = filterData ? filterData.serviceName : null;
    var corporateName = filterData ? filterData.corporateName : null;
    var customerPhone = filterData ? filterData.customerPhone : null;
    var customerName = filterData ? filterData.customerName : null;
    var orderType = filterData ? filterData.orderType : null;
    var scheduleDate = filterData ? filterData.scheduleDate : null;
    var sortBy = filterData ? filterData.sortBy : null;

    if(orderType != 8){
      scheduleDate = filterData ? filterData.startDate : null;
    }

    var formData = new FormData();

    formData.append("orderId", orderId);
    formData.append("eventId", eventIds);
    formData.append("eventName", eventName);
    formData.append("serviceName", serviceName);
    formData.append("corporateName", corporateName);
    formData.append("customerPhone", customerPhone);
    formData.append("customerName", customerName);
    formData.append("orderType", orderType);
    formData.append("scheduleDate", scheduleDate);
    formData.append("sortBy", sortBy);
    
    dispatch(checkInHS(formData))
      .then(() => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Check In Health Screening Successfully",
          showConfirmButton: false,
        });
        window.location = "/CheckIn-HS/"+eventId
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  }

  return (
    <DashboardLayout>
      <Layout>        
        {/* Filter */}
        <Filter {...filterProps} />
        {/* Table */}
        {loading ? <Loading /> : (
          <div className="table-responsive">
            <table
              id="tableGPOnsite"
              className="table table-striped table-bordered"
            >
              <thead className="table-header">
                {/* Rows: 17 */}
                <tr>
                  <th rowSpan={2} className="table-header-middle">
                    No
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    Order ID
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    Schedule Date
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    Partner Name
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    Customer Name
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    Customer Phone
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    Event Name
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    Corporate Name
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    Status
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {DataTables(result, paginationProps, setOrderDone)}
              </tbody>
            </table>
          </div>
        )}
      </Layout>
    </DashboardLayout>
  );
}

const DataTables = (result, paginationProps, setOrderDone) => {
  // Response Data
  const orders = result?.orders || [];
  
  return (
    <>
      {/* Empty */}
      {orders.length === 0 && (
        <tr>
          <td colSpan="8">Empty Data</td>
        </tr>
      )}

      {/* Has Data */}
      {orders.map((value, index) => {
        return (
          <tr key={index}>
            <td>{(paginationProps.page - 1) * 10 + index + 1}</td>
            <td>{value.orderId}</td>
            <td>{value.orderType == 3 ? value.startDate : value.scheduleDate}</td>
            <td>{value.partnerName}</td>
            <td>{value.customerName}</td>
            <td>{"0"+value.customerPhone}</td>
            <td>{value.eventNameDesc}</td>
            <td>{value.corporateName}</td>
            <td>{value.statusDesc}</td>
            <td width={"150px"} style={{ verticalAlign: "middle"}}>
              { value.statusCode == 99 ? <div></div> :
                <div style={{marginTop: "10px"}}>
                    <button className="btn btn-outline-info" onClick={() => setOrderDone(value.orderId)}>
                        Check In
                    </button> 
                </div>
              }
            </td>
          </tr>
        );
      })}
    </>
  );
};

const Layout = ({ children }) => {
  // Default Layout
  return (
    <>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>
        <div style={{marginTop: "20px"}}>Check In Health Screening</div>
        <div className="col">
          <hr />
        </div>
      </div>
      <div className="table-wrapper">       
        {children}
      </div>
    </>
  );
};

const Filter = (props) => {
  const { type, search, submitFilter, handleReset } = props;
  return (
    <>
      <div className="row mb15">
        <div className="link-btn">
          <button
            type="button"
            className="btn btn-link no-padding"
            id="filterBy"
            data-toggle="collapse"
            data-target="#collapseDetailGPOnsite"
          >
            Filter By
            <i className="fa fa-angle-down" style={{ marginLeft: "10px" }} />
          </button>
        </div>
        <div className="col">
          <hr />
        </div>
      </div>
      <div className="collapse" id="collapseDetailGPOnsite">
        <div className="row">
        <div className="col-md-3 neutral-border">
            <div className="form-group mb-md-0">
              <label>Search Type</label>
              <select className="form-control" ref={type}>
                <option value="">Select Type</option>
                <option value="orderId">Order Id</option>
                <option value="customerName">CustomerName</option>
                <option value="customerPhone">CustomerPhone</option>
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group mb-md-0">
              <label>Search: </label>
              <input type="text" className="form-control" ref={search} />
            </div>
          </div>
        </div>
        <div className="fm-btn-gr text-right">
          <button
            type="button"
            className="btn btn-secondary"
            style={{ marginRight: "10px" }}
            onClick={() => {
              handleReset();
            }}
          >
            Reset
          </button>
          <button
            onClick={() => {
              submitFilter();
            }}
            type="button"
            className="btn btn-primary"
          >
            Apply
          </button>
        </div>

        <div style={{ width: "100%" }}>
          <hr />
        </div>
      </div>
    </>
  );
};
