import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addSchedules,
  deleteSchedules,
  getAllSchedules,
  getAllTeams,
  importSchedules,
  multipleDeleteSchedules,
  updateSchedules,
  clearImportSchedule,
} from "../../../actions";
import Input from "../Components/Input";
import Modal from "../Components/Modal";
import Pagination from "../../../components/Pagination";
import DashboardLayout from "../Layout";
import queryString from "query-string";
import moment from "moment";
import Select from "react-select";
import Swal from "sweetalert2";
import ExcelJS from "exceljs";
import saveAs from "file-saver";

const MySchedule = () => {
  const [scheduleData, setScheduleData] = useState({
    partnerId: "",
    startTime: "",
    endTime: "",
    scheduleDate: "",
    isPremium: "",
  });
  const [partner, setPartner] = useState("");
  const [id, setId] = useState("");
  const [fileName, setFileName] = useState("Upload Excel");
  const [excel, setExcel] = useState([]);
  const [isPremium, setIsPremium] = useState(false);
  const [addSchedulesModal, setAddSchedulesModal] = useState(false);
  const [updateSchedulesModal, setUpdateSchedulesModal] = useState(false);
  const [importSchedulesModal, setImportSchedulesModal] = useState(false);
  const [loadingConvert, setLoadingConvert] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkId, setCheckId] = useState({});
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const searchKey = useRef("");
  const sortBy = useRef("desc");
  const startDate = useRef("");
  const endDate = useRef("");
  const assignedToOrder = useRef("");
  const role = "/TeleconsultationOption";
  const myTeam = useSelector((state) => state.myTeam);
  const mySchedule = useSelector((state) => state.mySchedule);
  const dispatch = useDispatch();

  const initState = () => {
    setScheduleData({
      partnerId: "",
      startTime: "",
      endTime: "",
      scheduleDate: "",
      isPremium: "",
    });
    setPartner("");
    setId("");
    setFileName("Upload Excel");
    setExcel([]);
    setIsPremium(false);
  };

  useEffect(() => {
    dispatch(getAllSchedules(search));
  }, [search]);

  useEffect(() => {
    dispatch(getAllTeams(role));
  }, [role]);

  useEffect(() => {
    let allChecked = true;

    for (const name in checkId) {
      if (checkId[name] === false) {
        allChecked = false;
      }
    }
    if (allChecked) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [checkId]);

  useEffect(() => {
    let getData = {};

    mySchedule.schedules.forEach((item) => {
      getData[item.id] = false;
    });
    setCheckId(getData);
    setPagination({
      ...pagination,
      totalData: mySchedule.totalData,
    });
  }, [mySchedule.schedules, mySchedule.totalData]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const handleCloseModal = () => {
    initState();
    setAddSchedulesModal(false);
    setUpdateSchedulesModal(false);
    setImportSchedulesModal(false);
    setLoadingConvert(false);
  };

  const handleImport = () => {
    setImportSchedulesModal(true);
  };

  // Serius Mode On
  const handleExcelFile = (e) => {
    setLoadingConvert(true);

    e.preventDefault();
    const workbook = new ExcelJS.Workbook();
    let files = e.target.files[0];
    let dataSet = [];

    workbook.xlsx.load(files).then(() => {
        // const worksheet = workbook.getWorksheet("template");
      const worksheet = workbook.getWorksheet(1);
      let dateNow = moment();
      // console.log(worksheet._rows.length);
      let header = worksheet.getRow(1).values;

      worksheet.eachRow((row) => {
        let partner_id, monday, tuesday, wednesday, thursday, friday, saturday, sunday, _duration, dayCounter;
        partner_id = row.getCell(1).value;
        monday = row.getCell(2).value;
        tuesday = row.getCell(3).value;
        wednesday = row.getCell(4).value;
        thursday = row.getCell(5).value;
        friday = row.getCell(6).value;
        saturday = row.getCell(7).value;
        sunday = row.getCell(8).value;
        _duration = row.getCell(9).value;
        if (_duration == null || _duration == 0) {
           _duration = 15; 
        } else {
          _duration = _duration;
        }

        let endDate = moment(row.getCell(10).value, "YYYY-MM-DD");
        let maxEndDate = dateNow.clone().add(3, 'month').endOf('month');

        if (endDate > maxEndDate) {
          dayCounter = Math.ceil(moment.duration(maxEndDate.diff(dateNow.format("YYYY-MM-DD"))).asDays());
        } else if (endDate.format("YYYY-MM-DD") == maxEndDate.format("YYYY-MM-DD")){
          dayCounter = Math.ceil(moment.duration(maxEndDate.diff(dateNow.format("YYYY-MM-DD"))).asDays());
        }
        else {
          dayCounter = Math.ceil(moment.duration(endDate.diff(dateNow.format("YYYY-MM-DD"))).asDays());
        }
        // console.log("maxEndDate", maxEndDate.format("YYYY-MM-DD"));
       
        if (dayCounter == 0) {
          dayCounter = 1; 
        } else {
          dayCounter = dayCounter;
        }
        // let dayCounter = moment.duration(endDate.diff(dateNow.format("YYYY-MM-DD"))).asDays();
        // console.log("endDate", endDate.format("YYYY-MM-DD"));
        // console.log("dayCounter", dayCounter);
        let startTemp, startTime, endTime, scheduleDate;
        let temp = dateNow;
        for (let dayIdx = 0; dayIdx < dayCounter; dayIdx++) {
          scheduleDate = temp;
          if (temp.isoWeekday() == 1 && monday != null){
            let timerange = monday.split("-");
            let start, end;
            if (typeof timerange[0] !== "string") {
              start =  moment(timerange[0], "HH:mm").utc().format("HH:mm");
              end =  moment(timerange[1], "HH:mm").utc().format("HH:mm");
            } else {
              start = moment(timerange[0], "HH:mm");
              end = moment(timerange[1], "HH:mm");
            }
            let totalMin = moment.duration(end.diff(start)).asMinutes();
            let events = totalMin / _duration;  // _duration = row.getCell(9).value
            // console.log("timerange", timerange);
            // console.log("start", start);
            // console.log("end", end);
            // console.log("totalMin", totalMin);
            // console.log("events", events);
            startTemp = start;
            for (let index = 0; index < events; index++) {
              startTime = startTemp;
              endTime = startTime.clone().add(_duration, 'minutes');
              dataSet.push({
                partnerId: partner_id,
                startTime: startTime.format("HH:mm"),
                endTime: endTime.format("HH:mm"),
                scheduleDate: scheduleDate.format("YYYY-MM-DD")
              });
              startTemp = endTime;   
            }
          }
          else if (temp.isoWeekday() == 2 && tuesday != null){
            let timerange = tuesday.split("-");
            let start, end;
            if (typeof timerange[0] !== "string") {
              start =  moment(timerange[0], "HH:mm").utc().format("HH:mm");
              end =  moment(timerange[1], "HH:mm").utc().format("HH:mm");
            } else {
              start = moment(timerange[0], "HH:mm");
              end = moment(timerange[1], "HH:mm");
            }
            let totalMin = moment.duration(end.diff(start)).asMinutes();
            let events = totalMin / _duration;  // _duration = row.getCell(9).value

            startTemp = start;
            for (let index = 0; index < events; index++) {
              startTime = startTemp;
              endTime = startTime.clone().add(_duration, 'minutes');
              dataSet.push({
                partnerId: partner_id,
                startTime: startTime.format("HH:mm"),
                endTime: endTime.format("HH:mm"),
                scheduleDate: scheduleDate.format("YYYY-MM-DD")
              });   
              startTemp = endTime;   
            }
          }
          else if (temp.isoWeekday() == 3 && wednesday != null){
            let timerange = wednesday.split("-");
            let start, end;
            if (typeof timerange[0] !== "string") {
              start =  moment(timerange[0], "HH:mm").utc().format("HH:mm");
              end =  moment(timerange[1], "HH:mm").utc().format("HH:mm");
            } else {
              start = moment(timerange[0], "HH:mm");
              end = moment(timerange[1], "HH:mm");
            }
            let totalMin = moment.duration(end.diff(start)).asMinutes();
            let events = totalMin / _duration;  // _duration = row.getCell(9).value

            startTemp = start;
            for (let index = 0; index < events; index++) {
              startTime = startTemp;
              endTime = startTime.clone().add(_duration, 'minutes');
              dataSet.push({
                partnerId: partner_id,
                startTime: startTime.format("HH:mm"),
                endTime: endTime.format("HH:mm"),
                scheduleDate: scheduleDate.format("YYYY-MM-DD")
              });   
              startTemp = endTime;   
            }
          }
          else if (temp.isoWeekday() == 4 && thursday != null){
            let timerange = thursday.split("-");
            let start, end;
            if (typeof timerange[0] !== "string") {
              start =  moment(timerange[0], "HH:mm").utc().format("HH:mm");
              end =  moment(timerange[1], "HH:mm").utc().format("HH:mm");
            } else {
              start = moment(timerange[0], "HH:mm");
              end = moment(timerange[1], "HH:mm");
            }
            let totalMin = moment.duration(end.diff(start)).asMinutes();
            let events = totalMin / _duration;  // _duration = row.getCell(9).value

            startTemp = start;
            for (let index = 0; index < events; index++) {
              startTime = startTemp;
              endTime = startTime.clone().add(_duration, 'minutes');
              dataSet.push({
                partnerId: partner_id,
                startTime: startTime.format("HH:mm"),
                endTime: endTime.format("HH:mm"),
                scheduleDate: scheduleDate.format("YYYY-MM-DD")
              });   
              startTemp = endTime;   
            }
          }
          else if (temp.isoWeekday() == 5 && friday != null){
            let timerange = friday.split("-");
            let start, end;
            if (typeof timerange[0] !== "string") {
              start =  moment(timerange[0], "HH:mm").utc().format("HH:mm");
              end =  moment(timerange[1], "HH:mm").utc().format("HH:mm");
            } else {
              start = moment(timerange[0], "HH:mm");
              end = moment(timerange[1], "HH:mm");
            }
            let totalMin = moment.duration(end.diff(start)).asMinutes();
            let events = totalMin / _duration;  // _duration = row.getCell(9).value

            startTemp = start;
            for (let index = 0; index < events; index++) {
              startTime = startTemp;
              endTime = startTime.clone().add(_duration, 'minutes');
              dataSet.push({
                partnerId: partner_id,
                startTime: startTime.format("HH:mm"),
                endTime: endTime.format("HH:mm"),
                scheduleDate: scheduleDate.format("YYYY-MM-DD")
              });   
              startTemp = endTime;   
            }
          }
          else if (temp.isoWeekday() == 6 && saturday != null){
            let timerange = saturday.split("-");
            let start, end;
            if (typeof timerange[0] !== "string") {
              start =  moment(timerange[0], "HH:mm").utc().format("HH:mm");
              end =  moment(timerange[1], "HH:mm").utc().format("HH:mm");
            } else {
              start = moment(timerange[0], "HH:mm");
              end = moment(timerange[1], "HH:mm");
            }
            let totalMin = moment.duration(end.diff(start)).asMinutes();
            let events = totalMin / _duration;  // _duration = row.getCell(9).value

            startTemp = start;
            for (let index = 0; index < events; index++) {
              startTime = startTemp;
              endTime = startTime.clone().add(_duration, 'minutes');
              dataSet.push({
                partnerId: partner_id,
                startTime: startTime.format("HH:mm"),
                endTime: endTime.format("HH:mm"),
                scheduleDate: scheduleDate.format("YYYY-MM-DD")
              });   
              startTemp = endTime;   
            }
          }
          else if (temp.isoWeekday() == 7 && sunday != null){
            let timerange = sunday.split("-");
            let start, end;
            if (typeof timerange[0] !== "string") {
              start =  moment(timerange[0], "HH:mm").utc().format("HH:mm");
              end =  moment(timerange[1], "HH:mm").utc().format("HH:mm");
            } else {
              start = moment(timerange[0], "HH:mm");
              end = moment(timerange[1], "HH:mm");
            }
            let totalMin = moment.duration(end.diff(start)).asMinutes();
            let events = totalMin / _duration;  // _duration = row.getCell(9).value

            startTemp = start;
            for (let index = 0; index < events; index++) {
              startTime = startTemp;
              endTime = startTime.clone().add(_duration, 'minutes');
              dataSet.push({
                partnerId: partner_id,
                startTime: startTime.format("HH:mm"),
                endTime: endTime.format("HH:mm"),
                scheduleDate: scheduleDate.format("YYYY-MM-DD")
              });   
              startTemp = endTime;   
            }
          }

          temp = scheduleDate.clone().add(1, 'days');
         
          
        } 
        // if (typeof row.getCell(2).value !== "string") {
        //   row.getCell(2).value = moment(row.getCell(2).value)
        //     .utc()
        //     .format("HH:mm:ss");
        // }
        // if (typeof row.getCell(3).value !== "string") {
        //   row.getCell(3).value = moment(row.getCell(3).value)
        //     .utc()
        //     .format("HH:mm:ss");
        // }
        
        // const object = Object.assign(
        //   {},
        //   row.values.filter((e) => e !== null)
        // );

        // const renameKeys = (obj, newKeys) => {
        //   const keyValues = Object.keys(obj).map((key) => {
        //     const newKey = newKeys[key] || key;
        //     return { [newKey]: obj[key] };
        //   });
        //   return Object.assign({}, ...keyValues);
        // };

        // const newKeys = {
        //   0: "partnerId",
        //   1: "startTime",
        //   2: "endTime",
        //   3: "scheduleDate",
        // };

        // const renamedObj = renameKeys(object, newKeys);
        // dataSet.push(renamedObj);
      });
      setLoadingConvert(false);
      console.log(dataSet);
    });
    setExcel(dataSet);
  };

  const actionImportExcel = () => {
    if (fileName === "Upload Excel") {
      Swal.fire({
        icon: "error",
        text: "Please choose document first",
      });
    } else {
      // let data = excel.slice(1, excel.length);
      if (excel.length > 0) {
        dispatch(importSchedules({ jsonData: excel, isPremium: isPremium}, search))
          .then((result) => {
            if (result) {
              dispatch(getAllSchedules(search));
            }
          })
          .then(() => {
            initState();
            setImportSchedulesModal(false);
            // console.log("dataError", mySchedule.dataError);
            if (mySchedule.loading == false) {
              Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: false,
              }).fire({
                icon: "success",
                title: "Import Success",
              });
            }
          });
      }
      //  else if (excel.length === 1) {
      //   Swal.fire("Oops", "Seems like there's nothing in this file yet");
      // } 
      else {
        Swal.fire("Oops", "Seems like there's nothing in this file yet");
      }
    }
  };

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const datasheet = workbook.addWorksheet("Sheet1");
    let row;

    row = datasheet.addRow([
      "partnerId",
      "startTime",
      "endTime",
      "scheduleDate",
      "active",
      "error",
    ]);
    row.font = { bold: true };
    mySchedule.dataError.map((data) => {
      datasheet.addRow([
        data.partnerId,
        data.startTime,
        data.endTime,
        moment(data.scheduleDate).format("YYYY-MM-DD"),
        data.active,
        data.error,
      ]);
    });

    workbook.xlsx.writeBuffer().then((buff) => {
      saveAs(new Blob([buff]), `errorImportSchedule.xlsx`);
    });
  };

  const handleClear = () => {
    dispatch(clearImportSchedule(search)).then(
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
      }).fire({
        icon: "success",
        title: "Clear error data success",
      })
    );
  };

  const renderImportSchedules = () => {
    return (
      <Modal
        modalTitle="Import Schedule"
        show={importSchedulesModal}
        size="lg"
        handleClose={ mySchedule.loading ? null : () => handleCloseModal()}
        buttons={ mySchedule.loading ? [{
          label: "Loading...",
          color: "primary",
          disabled: true,
          spinner:  <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
        }] :
        [
          {
            label: "Import",
            color: "primary",
            onClick: actionImportExcel,
          },
        ]}
      >
        <Row>
          <Col md>
            <div className="form-group">
              <label>Excel File (.xlsx)</label>
              <Form.File
                custom
                label={fileName}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                data-browse="Drag & Drop or Browse"
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    setFileName(e.target.files[0].name);
                    handleExcelFile(e);
                  } else {
                    setFileName("Upload Excel");
                    setExcel([]);
                  }
                }}
                disabled={mySchedule.loading ? true : false}
              />
            </div>
          </Col>
        </Row>
        {
          localStorage.getItem("parentId") == 3295 ? (
          <Row>
            <Col md>
              <div className="form-control" style={{ border: "none", padding: 0 }}>
                <input
                      type="checkbox" style={{margin:"0px 20px 0px 10px"}}
                      checked={isPremium} 
                      onChange={checkIsPremium}
                    />
                <label>Tanya Dokter Premium</label>
              </div>
            </Col>
          </Row>) : <></>
        }
        { loadingConvert ? (
          <Row className="justify-content-center">
            <Loading />
          </Row>) : <></>
        }
        {/* <Button variant="primary" disabled>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Loading...
        </Button> */}
      </Modal>
    );
  };

  const handleDownload = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("template");
    const datasheet = workbook.addWorksheet("partner");
    let row;

    row = worksheet.addRow([
      "Partner ID",
      "Senin",
      "Selasa",
      "Rabu",
      "Kamis",
      "Jumat",
      "Sabtu",
      "Minggu",
      "Durasi",
      "End Date",
    ]);
    // row = worksheet.addRow([
    //   "partnerId",
    //   "startTime",
    //   "endTime",
    //   "scheduleDate",
    // ]);
    row.font = { bold: true };
    worksheet.getColumn('B').width = 12;
    worksheet.getColumn('C').width = 12;
    worksheet.getColumn('D').width = 12;
    worksheet.getColumn('E').width = 12;
    worksheet.getColumn('F').width = 12;
    worksheet.getColumn('G').width = 12;
    worksheet.getColumn('H').width = 12;
    worksheet.getColumn('J').width = 12;
    worksheet.addRow([myTeam.teamMembers[0].id, "10:30-11:00", null, "13:15-14:00", null, null, null, null, 15, moment().format("YYYY-MM-DD")]);
    // worksheet.addRow([397, "10:00:00", "11:00:00", "2021-01-30"]);

    row = datasheet.addRow(["ID", "Name"]);
    row.font = { bold: true };

    let data = myTeam.teamMembers;
    data.map((partner) => {
      datasheet.addRow([partner.id, partner.fullName]);
    });

    let date = new Date().getTime();
    workbook.xlsx.writeBuffer().then((buff) => {
      saveAs(new Blob([buff]), `template-${date}.xlsx`);
    });
  };

  const handleSortBy = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      searchKey: searchKey.current.value,
      sortBy: sortBy.current.value,
      startDate: startDate.current.value,
      endDate: endDate.current.value,
      assignedToOrder: assignedToOrder.current.value,
      page: pagination.page,
      limit: pagination.limit,
    };

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleAutoComplete = (e) => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      searchKey: searchKey.current.value,
      sortBy: sortBy.current.value,
      startDate: startDate.current.value,
      endDate: endDate.current.value,
      assignedToOrder: assignedToOrder.current.value,
      page: pagination.page,
      limit: pagination.limit,
    };

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleReset = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      searchKey: searchKey.current.value,
      sortBy: sortBy.current.value,
      startDate: (startDate.current.value = ""),
      endDate: (endDate.current.value = ""),
      assignedToOrder: (assignedToOrder.current.value = ""),
      page: pagination.page,
      limit: pagination.limit,
    };

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleSearch = (page, limit) => {
    let values = {
      searchKey: searchKey.current.value,
      sortBy: sortBy.current.value,
      startDate: startDate.current.value,
      endDate: endDate.current.value,
      assignedToOrder: assignedToOrder.current.value,
    };
    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }
    if (limit) {
      values.limit = limit;
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const checkIsPremium = (e) => {
    let name = e.target.name,
      checked = e.target.checked;

      setIsPremium(checked)

      // if(checked){
      //   document.getElementById("isPremium").readOnly = false;
      // }
      // else{
      //   setIsPremium(checked)
      //   document.getElementById("isPremium").readOnly = true;
      // }

  };

  const handleAddSchedules = () => {
    setAddSchedulesModal(true);
  };

  const actionAddSchedules = () => {
    const form = new FormData();
    // console.log("partner", partner)
    form.append("partnerId", partner.id);
    form.append("startTime", scheduleData.startTime);
    form.append("endTime", scheduleData.endTime);
    form.append("scheduleDate", scheduleData.scheduleDate);
    form.append("isPremium", scheduleData.isPremium);

    dispatch(addSchedules(form, search))
      .then((result) => {
        if (result) {
          dispatch(getAllSchedules(search));
        }
      })
      .then(() => {
        initState();
        setAddSchedulesModal(false);
        setTimeout(() => Swal.fire("Success!", "Data Created", "success"), 500);
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  };

  const renderAddSchedules = () => {
    return (
      <Modal
        modalTitle="Add New Schedule"
        show={addSchedulesModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        onSubmit={actionAddSchedules}
      >
        <Row>
          <Col md>
            <div className="form-group">
              <label>Name</label>
              {/* <select
                className="form-control"
                value={scheduleData.partnerId}
                onChange={(e) =>
                  setScheduleData({
                    ...scheduleData,
                    partnerId: e.target.value,
                  })
                }
              >
                <option value="">Select Name</option>
                {myTeam.teamMembers
                  ? myTeam.teamMembers.map((team) => (
                      <option key={team.id} value={team.id}>
                        {team.fullName}
                      </option>
                    ))
                  : null}
              </select> */}
              
              <Select
                  className="basic-single"
                  isSearchable
                  isClearable
                  isLoading={myTeam.loading}
                  name="partner"
                  options={myTeam.teamMembers}
                  onChange={setPartner}
                  uniqueKey="id"
                  placeholder={"Select Name"}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={option => `${option.fullName}`}
                  
                />
            </div>
          </Col>
          <Col md>
            <Input
              type="date"
              label="Date"
              value={scheduleData.scheduleDate}
              onChange={(e) =>
                setScheduleData({
                  ...scheduleData,
                  scheduleDate: e.target.value,
                })
              }
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="time"
              label="Start Time"
              value={scheduleData.startTime}
              onChange={(e) =>
                setScheduleData({
                  ...scheduleData,
                  startTime: e.target.value,
                })
              }
            />
          </Col>
          <Col md>
            <Input
              type="time"
              label="End Time"
              value={scheduleData.endTime}
              onChange={(e) =>
                setScheduleData({
                  ...scheduleData,
                  endTime: e.target.value,
                })
              }
            />
          </Col>
        </Row>
        {
          localStorage.getItem("parentId") == 3295 ? (
          <Row>
            <Col md>
              <div className="form-control" style={{ border: "none", padding: 0 }}>
                <input
                      type="checkbox" style={{margin:"0px 20px 0px 10px"}}
                      checked={scheduleData.isPremium} 
                      onChange={(e) =>
                        setScheduleData({
                          ...scheduleData,
                          isPremium: e.target.checked,
                        })
                      }
                    />
                <label>Tanya Dokter Premium</label>
              </div>
            </Col>
          </Row>) : <></>
        }
        
      </Modal>
    );
  };

  const handleUpdateSchedules = (schedule) => {
    setScheduleData({
      partnerId: schedule.partner,
      startTime: schedule.startTime,
      endTime: schedule.endTime,
      scheduleDate: moment(schedule.scheduleDate).format("YYYY-MM-DD"),
      isPremium: schedule.isPremium,
    });
    setId(schedule.id);
    setUpdateSchedulesModal(true);
  };

  const actionUpdateSchedules = () => {
    const form = new FormData();
    form.append("startTime", scheduleData.startTime);
    form.append("endTime", scheduleData.endTime);
    form.append("scheduleDate", scheduleData.scheduleDate);
    form.append("isPremium", scheduleData.isPremium);

    dispatch(updateSchedules(id, form, search))
      .then((result) => {
        if (result) {
          dispatch(getAllSchedules(search));
        }
      })
      .then(() => {
        initState();
        setUpdateSchedulesModal(false);
        setTimeout(() => Swal.fire("Success!", "Data Updated", "success"), 500);
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  };

  const renderUpdateSchedules = () => {
    return (
      <Modal
        modalTitle="Update Schedule"
        show={updateSchedulesModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        buttons={[
          {
            label: "Update",
            color: "warning",
            onClick: actionUpdateSchedules,
          },
        ]}
      >
        <Row>
          <Col md>
            <div className="form-group">
              <label>Name</label>
              <select
                className="form-control"
                value={scheduleData.partnerId}
                disabled
              >
                <option value={scheduleData.partnerId}>
                  {scheduleData.partnerId}
                </option>
              </select>
            </div>
          </Col>
          <Col md>
            <Input
              type="date"
              label="Date"
              value={scheduleData.scheduleDate}
              onChange={(e) =>
                setScheduleData({
                  ...scheduleData,
                  scheduleDate: e.target.value,
                })
              }
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="time"
              label="Start Time"
              value={scheduleData.startTime}
              onChange={(e) =>
                setScheduleData({
                  ...scheduleData,
                  startTime: e.target.value,
                })
              }
            />
          </Col>
          <Col md>
            <Input
              type="time"
              label="End Time"
              value={scheduleData.endTime}
              onChange={(e) =>
                setScheduleData({
                  ...scheduleData,
                  endTime: e.target.value,
                })
              }
            />
          </Col>
        </Row>
        {
          localStorage.getItem("parentId") == 3295 ? (
          <Row>
            <Col md>
              <div className="form-control" style={{ border: "none", padding: 0 }}>
                <input
                      type="checkbox" style={{margin:"0px 20px 0px 10px"}}
                      checked={scheduleData.isPremium} 
                      onChange={(e) =>
                        setScheduleData({
                          ...scheduleData,
                          isPremium: e.target.checked,
                        })
                      }
                    />
                <label>Tanya Dokter Premium</label>
              </div>
            </Col>
          </Row>) : <></>
        }
      </Modal>
    );
  };

  const handleDeleteSchedules = (scheduleId) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "grey",
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteSchedules(scheduleId, search))
          .then((result) => {
            if (result) {
              dispatch(getAllSchedules(search));
            }
            initState();
            setTimeout(
              () => Swal.fire("Success!", "Data Deleted", "success"),
              200
            );
          })
          .catch((err) => {
            if (err.response) {
              Swal.fire({
                icon: "error",
                text: err.response.data.message,
              });
            } else {
              Swal.fire({
                icon: "error",
                text: err.message,
              });
            }
          });
      }
    });
  };

  const handleCheck = (e) => {
    let name = e.target.name,
      checked = e.target.checked;

    if (name === "checkAll") {
      setCheckAll(checked);
      setCheckId((prevState) => {
        const newState = { ...prevState };
        for (const name in newState) {
          newState[name] = checked;
        }
        return newState;
      });
    } else {
      setCheckId((prevState) => {
        const newState = { ...prevState };
        newState[name] = !prevState[name];
        return newState;
      });
    }
  };

  const handleMultipleDeleteSchedules = () => {
    let getId = Object.keys(checkId)
      .filter((k) => checkId[k])
      .toString();

    const form = new FormData();
    form.append("teleScheduleIds", getId);

    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "grey",
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(multipleDeleteSchedules(form, search))
          .then((result) => {
            if (result) {
              dispatch(getAllSchedules(search));
            }
            initState();
            setTimeout(
              () => Swal.fire("Success!", "Data Deleted", "success"),
              200
            );
          })
          .catch((err) => {
            if (err.response) {
              Swal.fire({
                icon: "error",
                text: err.response.data.message,
              });
            } else {
              Swal.fire({
                icon: "error",
                text: err.message,
              });
            }
          });
      }
    });
  };

  const renderSchedules = () => {
    return mySchedule.loading ? (
      <tr>
        <td colSpan="9">
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        {mySchedule.schedules.length > 0 ? (
          mySchedule.schedules.map((schedule, index) => (
            <tr key={schedule.id}>
              <td>
                <input
                  type="checkbox"
                  name={schedule.id}
                  checked={checkId[schedule.id]}
                  onChange={handleCheck}
                />
              </td>
              <td>{(pagination.page - 1) * 10 + index + 1}</td>
              <td>{schedule.partner ? schedule.partner : "-"}</td>
              <td>{schedule.profession ? schedule.profession : "-"}</td>
              {
                  localStorage.getItem("parentId") == 3295 ? (
                  <td>
                    {schedule.isPremium ? <span style={{ color: "green", fontWeight: "bold" }}>Yes</span> : <span style={{ color: "red", fontWeight: "bold" }}>No</span>}
                  </td>) : <></>
              }
              <td>
                {schedule.assignedOrder === null ? (
                  <span style={{ color: "red", fontWeight: "bold" }}>No</span>
                ) : (
                  <span style={{ color: "green", fontWeight: "bold" }}>
                    Yes
                  </span>
                )}
              </td>
              <td>
                {schedule.scheduleDate
                  ? moment(schedule.scheduleDate).format("LL")
                  : "-"}
              </td>
              <td>
                {schedule.startTime
                  ? moment(schedule.startTime, "HH:mm:ss").format("HH:mm")
                  : "-"}
              </td>
              <td>
                {schedule.endTime
                  ? moment(schedule.endTime, "HH:mm:ss").format("HH:mm")
                  : "-"}
              </td>
              <td>
                {schedule.assignedOrder === null ? (
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <span
                      className="form-title"
                      style={{ cursor: "pointer", width: "45px" }}
                      onClick={() => handleUpdateSchedules(schedule)}
                    >
                      <i className="fa fa-pencil"></i>
                    </span>

                    <span
                      className="form-title"
                      style={{ cursor: "pointer", width: "45px" }}
                      onClick={() => handleDeleteSchedules(schedule.id)}
                    >
                      <i className="fa fa-trash"></i>
                    </span>
                  </div>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="9">Empty data</td>
          </tr>
        )}
      </>
    );
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>My Schedule</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        <div className="row">
          <div className="col-md-8">
            <span id="finance-title" className="mb10">
              Detail Schedule
            </span>
          </div>
          <div className="col-md-4">
            <select
              className="form-control table-search-field"
              style={{ marginBottom: "1em" }}
              onChange={handleSortBy}
              defaultValue={"desc"}
              ref={sortBy}
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8" style={{ paddingBottom: "1em" }}>
            <button
              type="button"
              className="btn btn-outline-primary"
              style={{ marginRight: "5px", marginBottom: "1em" }}
              onClick={() => handleAddSchedules()}
            >
              Add New Schedule
            </button>

            <button
              type="button"
              className="btn btn-outline-primary"
              style={{ marginRight: "5px", marginBottom: "1em" }}
              onClick={() => handleImport()}
            >
              Import Schedule
            </button>

            <button
              type="button"
              className="btn btn-outline-primary"
              style={{ marginRight: "5px", marginBottom: "1em" }}
              onClick={() => handleDownload()}
            >
              Download Template
            </button>

            <button
              type="button"
              className="btn btn-outline-danger"
              style={{ marginBottom: "1em" }}
              onClick={() => handleMultipleDeleteSchedules()}
            >
              Delete Schedule
            </button>
          </div>

          <div className="col-md-4" style={{ marginBottom: "1em" }}>
            <div className="input-group">
              <input
                type="text"
                className="form-control table-search-field"
                placeholder="Search Name"
                // onChange={handleAutoComplete}
                onKeyPress={(e) => e.key === 'Enter' ? handleSearch() : null}
                ref={searchKey}
              />
              <span className="input-group-btn">
                <button
                  type="submit"
                  className="btn btn-primary table-btn-search"
                  onClick={() => handleSearch()}
                >
                  <i className="fa fa-search"></i>
                </button>
              </span>
            </div>
          </div>
        </div>

        {!mySchedule.loading && mySchedule.dataError ? (
          mySchedule.dataError.length > 0 ? (
            <div className="row">
              <div className="col-md-12">
                <div
                  className="well"
                  style={{
                    marginBottom: "10px",
                    padding: "20px",
                    background: "#e2abab6b",
                    borderRadius: "5px",
                  }}
                >
                  <p style={{ marginBottom: "1rem" }}>
                    <b>Error Import Data</b>
                  </p>
                  {mySchedule.dataError.map((item, index) => (
                    <li
                      key={index + 1}
                      className="errorMsgStyle"
                      style={{ fontSize: "12px" }}
                    >
                      partnerId : {item.partnerId} - Error : {item.error}
                    </li>
                  ))}
                  <div className="" style={{ marginTop: "20px" }}>
                    <button
                      id="resetError"
                      className="btn btn-danger"
                      style={{ marginRight: "10px" }}
                      onClick={handleClear}
                    >
                      <span className="fa fa-trash text-white"></span> Clear
                    </button>

                    <button
                      className="btn btn-success"
                      target="_blank"
                      style={{ marginRight: "10px" }}
                      onClick={() => downloadExcel()}
                    >
                      <span className="fa fa-download text-white"></span>{" "}
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null
        ) : null}

        <div className="row mb15">
          <div className="link-btn">
            <button
              type="button"
              className="btn btn-link no-padding"
              style={{ textDecoration: "none", boxShadow: "none" }}
              data-toggle="collapse"
              data-target="#collapseDetailSchedule"
            >
              Filter By
              <i className="fa fa-angle-down" style={{ marginLeft: "5px" }} />
            </button>
          </div>

          <div className="col">
            <hr />
          </div>
        </div>

        <div id="collapseDetailSchedule" className="collapse">
          <div id="filterForm" className="row filter-by">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <div className="mb10">Date From</div>
                  <input type="date" className="form-control" ref={startDate} />
                </div>

                <div className="col-md-4">
                  <div className="mb10">Date To</div>
                  <input type="date" className="form-control" ref={endDate} />
                </div>

                <div className="col-md-4">
                  <div className="mb10">Assigned to Order</div>
                  <select className="form-control" ref={assignedToOrder}>
                    <option value="">Select Assigned</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="text-right" style={{ marginTop: "25px" }}>
            <button
              type="button"
              className="btn btn-secondary"
              style={{ width: "100px", marginRight: "5px" }}
              onClick={() => handleReset()}
            >
              Reset
            </button>

            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100px" }}
              onClick={() => handleSearch()}
            >
              Apply
            </button>
          </div>

          <div style={{ width: "100%" }}>
            <hr />
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th rowSpan={2} className="table-header-middle">
                  {mySchedule.schedules.length > 0 ? (
                    <input
                      type="checkbox"
                      name="checkAll"
                      checked={checkAll}
                      onChange={handleCheck}
                    />
                  ) : (
                    <span>-</span>
                  )}
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "45px" }}
                >
                  No
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "300px" }}
                >
                  Name
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Profession
                </th>

                {
                  localStorage.getItem("parentId") == 3295 ? (
                  <th rowSpan={2} className="table-header-middle">
                    Premium
                  </th>) : <></>
                }

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "100px" }}
                >
                  Assigned to order
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Date
                </th>

                <th colSpan={2} className="table-header-middle">
                  Time
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "150px" }}
                >
                  Action
                </th>
              </tr>

              <tr>
                <th>Start</th>
                <th>End</th>
              </tr>
            </thead>
            <tbody>{renderSchedules()}</tbody>
          </table>
        </div>

        {mySchedule.totalData > 10 ? (
          <Pagination
            props={pagination}
            setProps={setPagination}
            onChange={(page, limit) => {
              handleSearch(page, limit);
            }}
          />
        ) : null}
      </div>
      {renderAddSchedules()}
      {renderUpdateSchedules()}
      {renderImportSchedules()}
    </DashboardLayout>
  );
};

export default MySchedule;
