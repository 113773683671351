import React, { useEffect, useState, useRef } from "react";
import DashboardLayout from "../Layout";
import FilterForm from "./FilterHooks.js";
import RefundForm from "./RefundForm.js";
import { useDispatch, useSelector } from "react-redux";
import { getAllTeleRefund, doRefund } from "../../../actions";
import moment from "moment";
import queryString from "query-string";
import { Link } from "react-router-dom";
import Loading from "../../../components/Loading";
import Pagination from "../../../components/Pagination";
import Swal from 'sweetalert2';

const TeleconsultationRefund = () => {
  const [query, setQuery] = useState("");
  const [param, setParam] = useState("");
  const [modal, setModal] = useState(false);
  const [dataTarget, setDataTarget] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const sortBy = useRef("desc");
  const searchKey = useRef("");
  const startDate = useRef("");
  const endDate = useRef("");
  const status = useRef("");

  const teleRefund = useSelector((state) => state.teleRefund);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllTeleRefund(query));
  }, [query, dispatch]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: teleRefund.totalData,
    });
  }, [teleRefund]);
  
  const handleSortBy = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      sortBy: sortBy.current.value,
  
      searchKey: searchKey.current.value,
      endDate: endDate.current.value,
      startDate: startDate.current.value,
      page: pagination.page,
      limit: pagination.limit,
    };

    if (status.current.value !== "") {
      values.status = status.current.value;
    }

    let qs = queryString.stringify(values, {
      skipEmptyString: true
    });
    setQuery(qs);
  }

  const handleSearch = (page, limit) => {
    let values = {
      sortBy: sortBy.current.value,
      searchKey: searchKey.current.value,
      endDate: endDate.current.value,
      startDate: startDate.current.value,
    };
    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }
    if (limit) {
      values.limit = limit;
    }

    if (status.current.value !== "") {
      values.status = status.current.value;
    }

    let qs = queryString.stringify(values, {
      skipEmptyString: true
    });
    setQuery(qs);
  }

  const reset = () => {
    setParam("")
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      limit: pagination.limit,
      page: pagination.page,
      sortBy: sortBy.current.value,
      searchKey: searchKey.current.value,
      status: (status.current.value) = "",
      endDate: (endDate.current.value) = "",
      startDate: (startDate.current.value) = "",
    };

    let qs = queryString.stringify(values, {
      skipEmptyString: true
    });

    setQuery(qs);
  };
  const apply = () => {
    // console.log(`Filter by:
    //        Date: ${inputs.startDate} s.d ${inputs.endDate}
    //        Status: ${inputs.status}
    //        Patient Name: ${inputs.searchKey}`);
    var params = []
    let filter_params = Object.keys(inputs).map(k => inputs[k] !== "" ? params.push(`${encodeURIComponent(k)}=${encodeURIComponent(inputs[k])}`) : true);
    let qs = params.join("&");
    setParam(qs);
  }

  const submitRefund = () => {
    if(dataTarget !== "" && textInputs.reason !== "" ){
      Swal.fire({
          title: "Reconfirmation",
          text: "Apakah anda yakin ingin refund?",
          icon: "warning",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#7cb342",
          cancelButtonText: "No",
      }).then((result) => {
          if (result.value) {
              textInputs.orderId = dataTarget;
              // console.log("textInputs ", textInputs);
              dispatch(doRefund(textInputs, query)).then((res) => {
                if (res) {
                  dispatch(getAllTeleRefund());
                }
                setModal(false);
                Swal.fire({
                  icon: 'success',
                  text: 'Order Refund Success',
                  timer: 3000
                })
              }).catch((error) => {
                if (error.response) {
                    dispatch(getAllTeleRefund(query));
                    setTimeout(() => 
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.data.message,
                    }), 1000
                    )
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
          } 
        });
    }
  }

  const {inputs, handleInputChange, handleSubmit, handleReset } = FilterForm({startDate: '', endDate: '', status: '', searchKey: ''}, apply);
  const {textInputs, handleTextInputChange, handleRefund } = RefundForm({remark: '', reason: ''}, submitRefund);
  
  return (

    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>
        <div>Order</div>
        <div className="col">
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6" style={{ paddingBottom: "1em" }} id="btn-add">
          <a href="" style={{ marginRight: "7px" }}>
            <button type="button" className="btn btn-outline-primary active">
              Teleconsultation
            </button>
          </a>
          {/* <a href={`${process.env.REACT_APP_STG}`} target="_blank"> */}
          <Link to="/order/tele/pharmacy">
            <button 
              type="button" 
              className="btn btn-outline-primary">
              Pesan Antar Obat
            </button>
          </Link>
          {/* </a> */}
        </div>
      </div>
      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>
        <div className="row">
          <div className="col-md-6" id="btn-add">
            <span id="finance-title" className="mb10">
              Order
            </span>
          </div>
          <div className="col-md-6" id="search-form">
            <div className="form-inline" style={{ float: "right" }}>
              <div className="input-group">
                <select
                  id="sortBy"
                  name="sortBy"
                  className="form-control table-search-field"
                  ref={sortBy}
                  >
                  <option value="desc" data-id="desc" >
                    Descending
                  </option>
                  <option value="asc" data-id="asc">
                    Ascending
                  </option>
                </select>
                <span className="input-group-btn">
                    <button
                      type="submit"
                      onClick={handleSortBy}
                      className="btn btn-primary table-btn-search"
                    >
                      <i className="fa fa-search" />
                    </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb15">
          <div className="link-btn">
            <button
              type="button"
              className="btn btn-link no-padding"
              id="filterBy"
              data-toggle="collapse"
              data-target="#collapseDetailSchedule"
            >
              Filter By
              <i className="fa fa-angle-down" style={{ marginLeft: "10px" }} />
            </button>
          </div>
          <div className="col">
            <hr />
          </div>
        </div>
        <div className="collapse" id="collapseDetailSchedule">
        <form onSubmit={handleSubmit} onReset={handleReset}>
          <div id="filterForm" className="row filter-by">
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb10">Date From</div>
                  <input
                    id="datePickerFrom"
                    className="form-control"
                    type="text"
                    placeholder="Select Date From"
                    onFocus={
                      (e)=> {
                        e.currentTarget.type = "date";
                        e.currentTarget.focus();
                       }
                    }
                    name="startDate"
                    ref={startDate}
                  />
                </div>
                <div className="col-md-6">
                  <div className="mb10">Date To</div>
                  <input
                    id="datePickerTo"
                    className="form-control"
                    type="text"
                    placeholder="Select Date To"
                    onFocus={
                      (e)=> {
                        e.currentTarget.type = "date";
                        e.currentTarget.focus();
                       }
                    }
                    name="endDate"
                    ref={endDate}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 neutral-border pf-c">
              <div className="col">
                <div className="col-md-12">
                  <div className="mb10">Status</div>
                    <select id="status" name="status" className="form-control" 
                      ref={status}
                      >
                        <option value="">Select status</option>
                        <option value={80} data-id={80}>New Order</option>
                        <option value={88} data-id={88}>Cancelled By Customer</option>
                        <option value={89} data-id={89}>Cancelled By CMS</option>
                        <option value={99} data-id={99}>Completed</option>
                    </select>                     
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="col">
                <div className="col-md-12">
                  <div className="mb10">Patient Name</div>
                  <input
                    id="patientName"
                    className="form-control"
                    type="text"
                    placeholder="Input patient name"
                    name="searchKey"
                    ref={searchKey}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="fm-btn-gr text-right">
            <button
              id="resetBtn"
              type="reset"
              className="btn btn-secondary btn-reset"
              style={{ marginRight: "10px" }}
              onClick={
                () => reset()
              }
            >
              Reset
            </button>
            <button
              id="submitBtn"
              type="submit"
              onClick={ () => handleSearch()
            }
              className="btn btn-primary"
            >
              Apply
            </button>
          </div>
        </form>
          <div style={{ width: "100%" }}>
            <hr />
          </div>
        </div>
    
        <div className="table-responsive">
          <table
            id="tableScheduler"
            className="table table-striped table-bordered"
          >
            <thead className="table-header">
              <tr>
                <th rowSpan={2} className="table-header-middle">
                  {" "}
                  No
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Order ID
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Patient Name
                </th>
                <th rowSpan={2} className="table-header-middle">
                  DOB
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Customer Phone
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Patient Phone
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Schedule Date
                </th>
                <th colSpan={2} className="table-header-middle">
                  Time
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Doctor
                </th>
                {
                  localStorage.getItem("parentId") == 3295 ? (
                    <th rowSpan={2} className="table-header-middle">
                      Type
                    </th>) : <></>
                }
                <th rowSpan={2} className="table-header-middle">
                  Status
                </th>
                <th rowSpan={2} className="header-action table-header-middle">
                  Action
                </th>
              </tr>
              <tr>
                <th>Start</th>
                <th>End</th>
              </tr>
            </thead>
            <tbody>
              {
                teleRefund.loading ? (
                  <tr>
                    <td colSpan="12">
                      <Loading />
                    </td>
                  </tr>
                ) : teleRefund.error ? teleRefund.error.message : (

                teleRefund.orders.length > 0 ? ( Object.values(JSON.parse(JSON.stringify(teleRefund.orders))).map((patient, index) => (                  
                    <tr key={patient.order_id}>
                      <td>{(pagination.page - 1) * 10 + index + 1}</td>
                      <td>{patient.order_id}</td>
                      <td>{patient.patient_name}</td>
                      <td>{moment(patient.patient_dob).format("DD MMMM YYYY")}</td>
                      <td>{patient.customer_phone}</td>
                      <td>{patient.patient_phone}</td>
                      <td>{moment(patient.scheduleDate).format("DD MMMM YYYY")}</td>
                      <td>{patient.startTime.slice(0, 5)}</td>
                      <td>{patient.endTime.slice(0, 5)}</td>
                      <td>{patient.doctor_name}</td>
                      {
                        localStorage.getItem("parentId") == 3295 ? (
                          <td>{!patient.isHealthScreening && !patient.isPremium ? "Free Tele" : !patient.eventId && patient.isPremium ? "Tanya Dokter Premium" : patient.isHealthScreening ? "Health Screening" : ""}</td>
                        ) : <></>
                      }
                      <td>{patient.statusDesc}</td>
                      <td>
                        <div style={{ textAlign: "center" }}>
                          <a data-id={patient.order_id}>
                            <button
                              className="btn btn-outline-warning"
                              type="button"
                              data-toggle="modal"
                              data-target="#refundOrder"
                              onClick={
                                () => {
                                  setDataTarget(patient.order_id)
                                  setModal(true)
                                }

                              }
                            >
                              Refund
                            </button>
                          </a>
                        </div>
                      </td>
                    </tr>
              
                ))) :  
                    <tr>
                       <td colSpan="13">Empty data</td>
                    </tr>
                )}
              
             
            </tbody>
          </table>
        </div>

        { teleRefund.totalData > 10 ? (
          <Pagination
            props={pagination}
            setProps={setPagination}
            onChange={(page, limit) => {
              handleSearch(page, limit);
            }}
          />
        ) : null}

        <div className="modal fade modal-refund" id="refundOrder" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Order Refund</h5>
              </div>
              <form
                id="formRefund" onSubmit={handleRefund}>
                <input
                  type="hidden"
                  name="_token"
                  defaultValue="QRhWcNNkAfFR8CJaiXgxu5hpvnHEy9G4L0Ux6nXU"
                />
                <input type="hidden" id="orderId" name="orderId" defaultValue />
                <div className="modal-body">
                  <div className="row">
                    <div id="editReason" className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="orderId">Order ID</label>
                        <input
                          type="text"
                          id="fakeOrderId"         
                          value={dataTarget}           
                          className="form-control" disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div id="editReason" className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="reason">Reason</label>
                        <input
                          type="text"
                          id="editReason"
                          className="form-control"
                          placeholder="Input you reason"
                          name="reason"
                          value={textInputs.reason}
                          onChange={handleTextInputChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div id="editRemark" className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="remark">Remark</label>
                        <textarea
                          id="editRemark"
                          className="form-control"
                          name="remark"
                          value={textInputs.remark}
                          onChange={handleTextInputChange}
                      
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <a onClick={submitRefund}>
                    <button
                      type="button"
                      className="btn btn-primary"
                      id="button-refund"
                      data-toggle="modal"
                    >
                      Save
                    </button>
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default TeleconsultationRefund;