import { myOrderConstants } from "../actions/constants";

const initState = {
  orders: [],
  totalData: 0,
  orderDetail: {},
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case myOrderConstants.GET_ALL_ORDER_SUCCESS:
      state = {
        ...state,
        orders: action.payload.orders,
        totalData: action.payload.totalData,
        loading: false,
      };
      break;
    case myOrderConstants.GET_ALL_ORDER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myOrderConstants.GET_ALL_ORDER_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myOrderConstants.GET_ORDER_DETAIL_SUCCESS:
      state = {
        ...state,
        orderDetail: action.payload.orderDetail[0],
        loading: false,
      };
      break;
    case myOrderConstants.GET_ORDER_DETAIL_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myOrderConstants.GET_ORDER_DETAIL_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myOrderConstants.GET_DOCTOR_NAMES_BY_PROFESSION_SUCCESS:
      state = {
        ...state,
        doctors: action.payload,
        loading: false,
      };
      break;
    case myOrderConstants.GET_DOCTOR_NAMES_BY_PROFESSION_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myOrderConstants.GET_DOCTOR_NAMES_BY_PROFESSION_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myOrderConstants.UPDATE_ORDER_DOCTOR_NAME_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case myOrderConstants.UPDATE_ORDER_DOCTOR_NAME_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myOrderConstants.UPDATE_ORDER_DOCTOR_NAME_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
  }
  return state;
};
