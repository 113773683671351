import axios from "../helpers/axios";
import { paymentReportConstants } from "./constants";

export const getPaymentReport = (q) => {
  return async (dispatch) => {
    dispatch({ type: paymentReportConstants.GET_PAYMENT_REPORT_REQUEST });
    const res = await axios.get(`/Order/paymentReport?${q}`);
    if (res.status === 200) {
      const { orders, totalData, sendBird, parentId } = res.data;
      console.log('getPaymentReport', orders)
      dispatch({
        type: paymentReportConstants.GET_PAYMENT_REPORT_SUCCESS,
        payload: { orders, totalData, sendBird, parentId },
      });
    } else {
      dispatch({
        type: paymentReportConstants.GET_PAYMENT_REPORT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getPaymentReportDetail = (id) => {
  return async (dispatch) => {
    dispatch({
      type: paymentReportConstants.PAYMENT_REPORT_DETAIL_REQUEST,
    });
    const res = await axios.get(`Order/paymentReport/${id}`);
    if (res.status === 200) {
      const { order, nextStatus } = res.data;
      dispatch({
        type: paymentReportConstants.PAYMENT_REPORT_DETAIL_SUCCESS,
        payload: { order, nextStatus },
      });
    } else {
      dispatch({
        type: paymentReportConstants.PAYMENT_REPORT_DETAIL_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
