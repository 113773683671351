import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import DashboardLayout from "../../../Layout";

import { useDispatch, useSelector } from "react-redux";

import {
    getAdditionalService,
} from "../../../../../actions";

// Style
import "../../orderHistory.css";

export default function OrderHistoryDetailLayanan() {
    const result = useSelector((state) => state.orderHistory);
    const dispatch = useDispatch();    
    const { state } = useLocation();
    const [listMedical, setListMedical] = useState([]);
    var selectedProcedureData = [];
    var selectedMedicalData = [];

    const cbMedical = useRef(null);
    const tfCountMedical = useRef(null);

    const parentId = state.detailData != undefined ? 
                        state.detailData.partner != undefined ? state.detailData.partner.parentId : 0
                        : 0;
    const professionId = state.detailData != undefined ? 
                            state.detailData.partner != undefined ? 
                                state.detailData.partner.profession != undefined ? state.detailData.partner.profession.id : 0
                                : 0
                            : 0;

    const formData = new FormData();
    formData.append("parentId", parentId);
    formData.append("professionId", professionId);

    useEffect(() => {
        document.getElementById("btnProsedur").click();
        dispatch(getAdditionalService(formData));        
    }, []);

    const layananData = result.additionalService != undefined ? result.additionalService : [];
    const allProducts = layananData.length != 0 ? layananData.filter(item => item.productType == 2) : [];
    const medicalSupplies = layananData.length != 0 ? layananData.filter(item => item.productType == 1) : [];   
                            
    const btnAddMedicalSupply = () => {

        for(var i = 0; i < allProducts.length; i++){
            var tfProducts = document.getElementById("tf"+allProducts[i].id).value;
            allProducts[i].quantity = tfProducts;
        }

        if(cbMedical.current.value != ""){
            const getMedicalSupplyVal = medicalSupplies.find(item => item.id == cbMedical.current.value);
            setListMedical(listMedical => [...listMedical, {
                id: cbMedical.current.value,
                medicalSupply: getMedicalSupplyVal.length != 0 ? getMedicalSupplyVal.productName : "",
                price: getMedicalSupplyVal.length != 0 ? getMedicalSupplyVal.price : "",
                quantity: tfCountMedical.current.value,
            }]);
        }
        
    };

    const btnRemoveMedicalSupply = (id) => {
        setListMedical(listMedical => listMedical.filter(item => item.id !== id));        
    };

    const openPage = (pageName) => {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent1");
        for (i = 0; i < tabcontent.length; i++) {
          tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablink1");
        for (i = 0; i < tablinks.length; i++) {
          tablinks[i].style.backgroundColor = "#38a8ff";
          tablinks[i].style.borderBottom = "4px solid #38a8ff";
        }
        document.getElementById(pageName).style.display = "block";
        var el = "btn"+pageName;
        document.getElementById(el).style.borderBottom = "4px solid rgba(255, 255, 255, 0.377)";
    };

    const btnPlusOnClick = (serviceId) => {        
        var getVal = document.getElementById("tf"+serviceId).value;
        getVal = parseInt(getVal) + 1;
        document.getElementById("tf"+serviceId).value = getVal;
    };

    const btnMinOnClick = (serviceId) => {
        
        var getVal = document.getElementById("tf"+serviceId).value;
        getVal = parseInt(getVal) - 1;

        if( getVal >= 0){
            document.getElementById("tf"+serviceId).value = getVal;
        }
        else{
            document.getElementById("tf"+serviceId).value = 0;
        }
    };

    const KirimLayanan = () => {
        selectedProcedureData = [];
        selectedMedicalData = [];

        for(var i = 0; i < allProducts.length; i++){
            var tfProducts = document.getElementById("tf"+allProducts[i].id).value;

            if(parseInt(tfProducts) > 0){
                selectedProcedureData.push({
                    order: allProducts[i].id,
                    name: allProducts[i].productName,
                    price: allProducts[i].price,
                    quantity: parseInt(tfProducts),
                    isNew : null,
                    note : ""
                })
            }
        }

        for(var i = 0; i < listMedical.length; i++){
            var tfMedSupply = document.getElementById("tfMS"+listMedical[i].id).value;

            if(parseInt(tfMedSupply) > 0){
                selectedMedicalData.push({
                    order: listMedical[i].id,
                    name: listMedical[i].medicalSupply,
                    price: listMedical[i].price,
                    quantity: parseInt(tfMedSupply),
                    isNew : null,
                    note : ""
                })
            }
        }

        localStorage.removeItem("selectedProcedureData");
        localStorage.removeItem("selectedMedicalData");

        localStorage.setItem("selectedProcedureData", JSON.stringify(selectedProcedureData));
        localStorage.setItem("selectedMedicalData", JSON.stringify(selectedMedicalData));

        document.getElementById("redirectPage").click();
    }

    const nf = new Intl.NumberFormat();

    const prevPageUrl = "/order-history/detail";
    const stateVal = {
                        "id": state.id, 
                        "statusCode": state.statusCode, 
                        "detailData": state.detailData,
                     };  

    return (
        <DashboardLayout>        
            <div className="col-12" >
                <div className="card-detail" style={{background:"#38a8ff", margin:"30px 0px 0px 0px", borderRadius: "10px 10px 0px 0px"}}>
                    <Link to={{ pathname: prevPageUrl, state: stateVal }}>
                        <i className="fa fa-arrow-left" style={{fontSize: "18px", color: "white"}} aria-hidden="true" /> 
                    </Link>
                    <span style={{margin: "0px 30px 0px 30px", color: "white", fontSize: "16px"}}> 
                        Isi Prosedur
                    </span>
                </div>
                <button className="tablink1" id="btnProsedur" onClick={() => {openPage('Prosedur');}}>Prosedur</button>
                <button className="tablink1" id="btnMedicalSupply" onClick={() => {openPage('MedicalSupply');}}>Medical Supply</button>
                    <div id="Prosedur" className="tabcontent1">
                        
                        <div style={{fontSize: "20px", color: "#38a8ff", textAlign:"center", margin: "0px 10px 10px 10px"}}> Pilih Prosedur</div> 
                        <div style={{fontSize: "14px", textAlign:"center", margin: "10px 10px 30px 10px"}}>mohon untuk memilih prosedur yang akan dilakukan pada saat home visit</div>
                        
                        {allProducts.length > 0 ? 
                        (
                            allProducts.map((data, index) => (
                                <div className="card-detail" key={data.id}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>{data.productName}</td>
                                                <td style={{width: "80px", color:"lightgrey", fontSize:"12px"}}>Rp. {nf.format(data.price)},-</td>
                                                <td style={{width: "100px"}}>
                                                    <button className="btnPlusMin" onClick={() => {btnMinOnClick(data.id);}} >-</button>
                                                    <input type="text" id={"tf"+data.id} value={data.quantity != undefined ? data.quantity : 0} readOnly={true}
                                                    style={{width:"25px", height:"25px", textAlign:"center", border: "none"}} />
                                                    <button className="btnPlusMin" onClick={() => {btnPlusOnClick(data.id);}}  
                                                        style={{color: "#38a8ff"}}>+</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            ))
                        ) 
                        : 
                        (
                            <h5 style={{fontSize:"18px", color: "grey", margin:"20px 0px 20px 0px"}}>Tidak ada prosedur yang dipilih</h5>
                        )}
                        
                        <button className="btnNextAction" style={{margin: "20px 0px 20px 0px"}} onClick={() => {openPage('MedicalSupply');}} > Selanjutnya </button>
                        
                    </div>

                    <div id="MedicalSupply" className="tabcontent1">
                        <div className="card-detail">
                            <div>
                                <i className="fa fa-file-text-o" style={{fontSize: "15px"}} aria-hidden="true" /> 
                                <span style={{margin: "0px 30px 0px 10px"}}> 
                                    Medical Supply
                                </span>
                            </div>
                            <div style={{margin: '10px 0px 10px 0px'}}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <select className="form-control" style={{fontSize:"12px"}} ref={cbMedical}>
                                                    <option value="">Medical Supply</option>
                                                    { medicalSupplies.length > 0 ?
                                                        (
                                                            medicalSupplies.map((data, index) => (
                                                                <option key={data.id} value={data.id}>{data.productName}</option>
                                                            ))
                                                        )
                                                        :
                                                        (
                                                            <option></option>

                                                        )
                                                    
                                                    }
                                                </select>
                                            </td>
                                            <td style={{width: "85px"}}>
                                                <button className="btnPlusMin" onClick={() => {btnMinOnClick("MS");}} >-</button>
                                                <input type="text" id={"tfMS"} value="0" readOnly={true} ref={tfCountMedical}
                                                    style={{width:"25px", height:"25px", textAlign:"center", border: "none"}} />
                                                <button className="btnPlusMin" onClick={() => {btnPlusOnClick("MS");}}  
                                                    style={{color: "#38a8ff"}}>+</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button className="btnTambah" onClick={() => {btnAddMedicalSupply();}}> + Tambah </button>
                            </div>
                        </div>
                        <div className="card-detail">
                            <div style={{margin:"0px 0px 10px 0px"}}>Medical Supply yang dipilih :</div>
                                
                                        {
                                            listMedical.length > 0 ? 
                                            (
                                                listMedical.map((data, index) => (
                                                    <table key={index}>
                                                        <tbody>
                                                            <tr style={{height:"50px"}}>
                                                                <td>
                                                                    <label>{data.medicalSupply}</label>
                                                                </td>
                                                                <td style={{width: "85px"}}>
                                                                    <button className="btnPlusMin" onClick={() => {btnMinOnClick("MS"+data.id);}} >-</button>
                                                                    <input type="text" id={"tfMS"+data.id} value= {data.quantity} readOnly={true}
                                                                        style={{width:"25px", height:"25px", textAlign:"center", border: "none"}} />
                                                                    <button className="btnPlusMin" onClick={() => {btnPlusOnClick("MS"+data.id);}}  
                                                                        style={{color: "#38a8ff"}}>+</button>
                                                                </td>
                                                                <td align="right" width="20px">
                                                                    <button className="btnPlusMin" style={{border: "none"}} onClick={() => {btnRemoveMedicalSupply(data.id);}}>
                                                                        <i className="fa fa-trash"/>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                    
                                                        </tbody>
                                                    </table>
                                                ))
                                            )
                                            :
                                            (
                                                <p></p>
                                            )
                                        }
                        </div>
                        <button className="btnNextAction" style={{margin: "20px 0px 20px 0px"}} onClick={() => {KirimLayanan();}} > Kirim </button>
                        <Link to={{ pathname: prevPageUrl, state: stateVal }} id="redirectPage" />
                    </div>
            </div>
        </DashboardLayout>
    );
};