import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Login from "./pages/Login";
import {
  MyTeamTele,
  EducationTele,
  MyPromoTele,
  MySchedule,
  TeleReschedule,
  TeleconsultationRefund,
  DailyReport,
  SearchOrderById,
  MonthlyReport,
  TeleRescheduleDetail,
  WaitingTele,
  PaymentReport,
  PaymentReportDetail,
  CompletedCallTeleDetail,
  WaitingInvoice,
  WaitingInvoiceDetail,
  MyOrder,
  MyOrderDetail,
  MyOrderReschedule,
  DokterWorklist,
  Teleconsultation,
  TeleDetail,
  OrderRequest,
  OrderRequestDetail,
  OrderRequestAssign,
  OrderDailyReport,
  MyVaccinationEvent,
  VaccinationEventDetail,
  OrderVaccine,
  SkriningPrint,
  SkriningForm,
  ThankYou,
  VaccineDailyReport,
  MyMedical,
  MyProcedures,
  ScheduleVaccine,
  MyTeamHomecare,
  EducationHomecare,
  AddGotongRoyong,
  AddNasional,
  OrderHistory,
  OrderHistoryDetail,
  OrderHistoryDetailProfile,
  OrderHistoryDetailRiwayat,
  OrderHistoryDetailLihatRiwayat,
  OrderHistoryDetailConsentForm,
  OrderHistoryDetailListProsedur,
  OrderHistoryDetailLampiranFoto,
  OrderHistoryDetailLayanan,
  OrderPharmacy,
  AllOrderPharmacy,
  CompletedPharmacy,
  RegisterPatient,
  RegisterPatientAdd,
  PaymentOffline,
  PaymentOfflineAdd,
  PaymentOfflineVoucher,
  OrderPharmacyDetail,
  PharmacyDeliveryAll,
  PharmacyDeliveryAllCanceled,
  PharmacyDelivery,
  PharmacyDeliveryDetail,
  PharmacyDeliveryPrint,
  PharmacyWaitingDelivery,
  PharmacyWaitingDeliveryDetail,
  PharmacyWaitingDeliveryPrint,
  PharmacyDeliveryDone,
  PharmacyDeliveryDoneDetail,
  PharmacyDeliveryAllUnit,
  PharmacyDeliveryAllUnitDetail,
  ReportPharmacyOffline,
  RegisterOrder,
  RegisterPharmacyUnit,
  ReportingPharmacyDelivery,
  MedicalRecords,
  GPOnsite,
  GPOnsiteDone,
  CheckInHS,
  CheckInHSEvent,
  RegisterPharmacyUnitV2,
  RegisterPharmacyUnitOnBreak,
} from "./pages/Dashboard";
import PrivateRoute from "./components/HOC/PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import { isUserLogin } from "./actions";
import ChangePassword from "./pages/Profile/ChangePassword";
import ForgotPassword from "./pages/Profile/ForgotPassword";
import {
  Detail,
  List,
  Order,
  VerifyOtp,
  Register,
  FormRegist,
  PaymentMethod,
  Promo,
  Success,
} from "./pages/TestCovid";
import LoginTestCovid from "./pages/TestCovid/Login";
import MedicalHistory from "./pages/Dashboard/DokterWorklist/MedicalRecords/MedicalHistory";
import MedicalHistoryDetail from "./pages/Dashboard/DokterWorklist/MedicalRecords/MedicalHistoryDetail";
import OrderMedicalRecords from "./pages/Dashboard/OrderMedicalRecords";
import DashboardMedicalRecord from "./pages/Dashboard/DashboardMedicalRecord";

const Routes = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const role = JSON.parse(localStorage.getItem("user"));
  const routeManagement = role
    ? Object.keys(JSON.parse(role.user_role_management_id.route_group))
    : [];

  useEffect(() => {
    if (!auth.authenticate) {
      dispatch(isUserLogin());
    }
  }, [auth.authenticate]);

  return (
    <Router>
      <Switch>
        {/* Teleconsultation */}
        {routeManagement.includes("team_member_management_tele") && (
          <PrivateRoute path="/my-team-tele" exact component={MyTeamTele} />
        )}
        {routeManagement.includes("team_member_management_tele") && (
          <PrivateRoute
            path="/my-team-tele/education"
            exact
            component={EducationTele}
          />
        )}
        {routeManagement.includes("promo_teleconsultation") && (
          <PrivateRoute path="/my-promo-tele" exact component={MyPromoTele} />
        )}
        {routeManagement.includes("schedule_teleconsultation") && (
          <PrivateRoute path="/my-schedule-tele" exact component={MySchedule} />
        )}
        {routeManagement.includes("completed_call_teleconsultation") && (
          <PrivateRoute
            path="/completed-call-teleconsultation"
            exact
            component={WaitingTele}
          />
        )}
        {routeManagement.includes("payment_report") && (
          <PrivateRoute
            path="/payment-report"
            exact
            component={PaymentReport}
          />
        )}
        {routeManagement.includes("payment_report") && (
          <PrivateRoute
            path="/payment-report/:orderId"
            exact
            component={PaymentReportDetail}
          />
        )}
        {routeManagement.includes("completed_call_teleconsultation") && (
          <PrivateRoute
            path="/completed-call-teleconsultation/:orderId"
            exact
            component={CompletedCallTeleDetail}
          />
        )}
        {routeManagement.includes("order") && (
          <PrivateRoute path="/re-schedule" exact component={TeleReschedule} />
        )}
        {routeManagement.includes("order") && (
          <PrivateRoute
            path="/order/:orderId"
            exact
            component={TeleRescheduleDetail}
          />
        )}
        {routeManagement.includes("waiting_invoice_teleconsultation") && (
          <PrivateRoute
            path="/waiting-invoice-teleconsultation"
            exact
            component={WaitingInvoice}
          />
        )}
        {routeManagement.includes("waiting_invoice_teleconsultation") && (
          <PrivateRoute
            path="/waiting-invoice-teleconsultation/detail"
            exact
            component={WaitingInvoiceDetail}
          />
        )}
        {routeManagement.includes("pharmacy_offline") && (
          <PrivateRoute
            path="/report-pharmacy-offline"
            exact
            component={ReportPharmacyOffline}
          />
        )}
        {(routeManagement.includes("pharmacy_delivery_teleconsultation") || routeManagement.includes("pharmacy_delivery_monitoring")) && (
          <PrivateRoute
            path="/pharmacy-delivery-all"
            exact
            component={PharmacyDeliveryAll}
          />
        )}
        {(routeManagement.includes("pharmacy_delivery_teleconsultation") || routeManagement.includes("pharmacy_delivery_monitoring")) && (
        <PrivateRoute
          path="/pharmacy-delivery-all-canceled"
          exact
          component={PharmacyDeliveryAllCanceled}
        />
        )}
        {(routeManagement.includes("pharmacy_delivery_teleconsultation") || routeManagement.includes("pharmacy_delivery_monitoring")) && (
          <PrivateRoute
            path="/pharmacy-delivery"
            exact
            component={PharmacyDelivery}
          />
        )}
        {(routeManagement.includes("pharmacy_delivery_teleconsultation") || routeManagement.includes("pharmacy_delivery_monitoring")) && (
          <PrivateRoute
            path="/pharmacy-delivery/detail"
            exact
            component={PharmacyDeliveryDetail}
          />
        )}
        {(routeManagement.includes("pharmacy_delivery_teleconsultation") || routeManagement.includes("pharmacy_delivery_monitoring")) && (
          <PrivateRoute
            path="/pharmacy-delivery/print"
            exact
            component={PharmacyDeliveryPrint}
          />
        )}
        {(routeManagement.includes("pharmacy_delivery_teleconsultation") || routeManagement.includes("pharmacy_delivery_monitoring")) && (
          <PrivateRoute
            path="/pharmacy-waiting-delivery"
            exact
            component={PharmacyWaitingDelivery}
          />
        )}
        {(routeManagement.includes("pharmacy_delivery_teleconsultation") || routeManagement.includes("pharmacy_delivery_monitoring")) && (
          <PrivateRoute
            path="/pharmacy-waiting-delivery/detail"
            exact
            component={PharmacyWaitingDeliveryDetail}
          />
        )}
        {(routeManagement.includes("pharmacy_delivery_teleconsultation") || routeManagement.includes("pharmacy_delivery_monitoring")) && (
          <PrivateRoute
            path="/pharmacy-waiting-delivery/print"
            exact
            component={PharmacyWaitingDeliveryPrint}
          />
        )}
        {(routeManagement.includes("pharmacy_delivery_teleconsultation") || routeManagement.includes("pharmacy_delivery_monitoring")) && (
          <PrivateRoute
            path="/pharmacy-delivery-done"
            exact
            component={PharmacyDeliveryDone}
          />
        )}
        {(routeManagement.includes("pharmacy_delivery_teleconsultation") || routeManagement.includes("pharmacy_delivery_monitoring")) && (
          <PrivateRoute
            path="/pharmacy-delivery-done/detail"
            exact
            component={PharmacyDeliveryDoneDetail}
          />
        )}
        {routeManagement.includes("pharmacy_delivery_teleconsultation") && (
          <PrivateRoute
            path="/pharmacy-delivery/reporting"
            exact
            component={ReportingPharmacyDelivery}
          />
        )}
        {(routeManagement.includes("pharmacy_delivery_monitoring")) && (
          <PrivateRoute
            path="/pharmacy-delivery-all-unit"
            exact
            component={PharmacyDeliveryAllUnit}
          />
        )}
        {(routeManagement.includes("pharmacy_delivery_monitoring")) && (
          <PrivateRoute
            path="/pharmacy-delivery-all-unit/detail"
            exact
            component={PharmacyDeliveryAllUnitDetail}
          />
        )}
        {routeManagement.includes("order_refund") && (
          <PrivateRoute
            path="/tele-refund"
            exact
            component={TeleconsultationRefund}
          />
        )}
        {routeManagement.includes("order_pharmacy") && (
          <PrivateRoute
            path="/order/tele/pharmacy"
            exact
            component={OrderPharmacy}
          />
        )}
        {routeManagement.includes("order_pharmacy_delivery") && (
          <PrivateRoute
            exact
            path="/order/tele/all-pharmacy"
            component={AllOrderPharmacy}
          />
        )}
        {routeManagement.includes("order_pharmacy_delivery") && (
          <PrivateRoute
            exact
            path="/order/tele/all-pharmacy/detail"
            component={OrderPharmacyDetail}
          />
        )}
        {routeManagement.includes("order_pharmacy") && (
          <PrivateRoute
            path="/order/complete/pharmacy/:orderId"
            exact
            component={CompletedPharmacy}
          />
        )}
        {routeManagement.includes("daily_report") && (
          <PrivateRoute path="/daily-report" exact component={DailyReport} />
        )}
        {routeManagement.includes("order_by_id") && (
          <PrivateRoute path="/order-by-id" exact component={SearchOrderById} />
        )}
        {routeManagement.includes("monthly_recap") && (
          <PrivateRoute
            path="/monthly-report"
            exact
            component={MonthlyReport}
          />
        )}
        {routeManagement.includes("order_tele_data") && (
          <PrivateRoute
            path="/teleconsultation-all-data"
            exact
            component={Teleconsultation}
          />
        )}
        {routeManagement.includes("order_partner") && (
          <PrivateRoute
            path="/dokter-worklist"
            exact
            component={DokterWorklist}
          />
        )}
        {routeManagement.includes("order_partner") && (
          <PrivateRoute
            path="/dokter-worklist/detail"
            exact
            component={MedicalRecords}
          />
        )}
        {routeManagement.includes("order_partner") && (
          <PrivateRoute
            path="/dokter-worklist/medical-records"
            exact
            component={MedicalHistory}
          />
        )}
        {routeManagement.includes("order_partner") && (
          <PrivateRoute
            path="/dokter-worklist/medical-records/detail"
            exact
            component={MedicalHistoryDetail}
          />
        )}
        {routeManagement.includes("order_tele_data") && (
          <PrivateRoute
            path="/teleconsultation-all-data/detail/:orderId"
            exact
            component={TeleDetail}
          />
        )}
        {routeManagement.includes("report_gds") && (
          <PrivateRoute
            path="/pharmacy-delivery/reporting"
            exact
            component={ReportingPharmacyDelivery}
          />
        )}

        {/* Homecare */}
        {routeManagement.includes("team_member_management") && (
          <PrivateRoute
            path="/my-team-homecare"
            exact
            component={MyTeamHomecare}
          />
        )}
        {routeManagement.includes("team_member_management") && (
          <PrivateRoute
            path="/my-team-homecare/education"
            exact
            component={EducationHomecare}
          />
        )}
        {routeManagement.includes("promo_teleconsultation") && (
          <PrivateRoute path="/my-promo-tele" exact component={MyPromoTele} />
        )}
        {routeManagement.includes("medical_supplies_management") && (
          <PrivateRoute path="/medical-supplies" exact component={MyMedical} />
        )}
        {routeManagement.includes("procedure_management") && (
          <PrivateRoute path="/procedures" exact component={MyProcedures} />
        )}
        {routeManagement.includes("order_list") && (
          <PrivateRoute path="/my-order" exact component={MyOrder} />
        )}
        {routeManagement.includes("order_list") && (
          <PrivateRoute
            path="/my-order/detail"
            exact
            component={MyOrderDetail}
          />
        )}
        {routeManagement.includes("order_list") && (
          <PrivateRoute
            path="/my-order/reschedule"
            exact
            component={MyOrderReschedule}
          />
        )}
        {routeManagement.includes("order_request") && (
          <PrivateRoute path="/order-request" exact component={OrderRequest} />
        )}
        {routeManagement.includes("order_request") && (
          <PrivateRoute
            path="/order-request/detail"
            exact
            component={OrderRequestDetail}
          />
        )}
        {routeManagement.includes("order_request") && (
          <PrivateRoute
            path="/order-request/assign"
            exact
            component={OrderRequestAssign}
          />
        )}
        {routeManagement.includes("register_single_management") && (
          <PrivateRoute
            path="/register-order"
            exact
            component={RegisterOrder}
          />
        )}
        {routeManagement.includes("medical_records") &&
          localStorage.getItem("parentId") == 3295 && (
            <PrivateRoute
              path="/medical-records"
              exact
              component={OrderMedicalRecords}
            />
          )}
        {routeManagement.includes("medical_records") &&
          localStorage.getItem("parentId") == 3295 && (
            <PrivateRoute
              path="/dashboard"
              exact
              component={DashboardMedicalRecord}
            />
          )}

        {routeManagement.includes("order_list") && (
          <PrivateRoute
            path="/order-daily-report"
            exact
            component={OrderDailyReport}
          />
        )}

        {/* Vaccine */}
        {routeManagement.includes("vaccine_management") && (
          <PrivateRoute
            path="/my-vaccination-event"
            exact
            component={MyVaccinationEvent}
          />
        )}
        {routeManagement.includes("vaccine_management") && (
          <PrivateRoute
            path="/my-vaccination-event/detail"
            exact
            component={VaccinationEventDetail}
          />
        )}
        {routeManagement.includes("vaccine_management") && (
          <PrivateRoute
            exact
            path="/my-vaccination-event/nasional"
            component={AddNasional}
          />
        )}
        {routeManagement.includes("vaccine_management") && (
          <PrivateRoute
            exact
            path="/my-vaccination-event/gotong-royong"
            component={AddGotongRoyong}
          />
        )}
        {routeManagement.includes("schedule_vaccine") && (
          <PrivateRoute
            path="/schedule-vaccine"
            exact
            component={ScheduleVaccine}
          />
        )}
        {routeManagement.includes("order_vaccine") && (
          <PrivateRoute path="/vaksin" exact component={OrderVaccine} />
        )}
        {routeManagement.includes("order_vaccine") && (
          <PrivateRoute
            exact
            path="/vaksin-print/:orderId"
            component={SkriningPrint}
          />
        )}
        {routeManagement.includes("order_vaccine") && (
          <PrivateRoute
            exact
            path="/vaksin-form/:orderId"
            component={SkriningForm}
          />
        )}
        {routeManagement.includes("order_vaccine") && (
          <PrivateRoute exact path="/thank-you" component={ThankYou} />
        )}
        {routeManagement.includes("vaccine_daily_report") && (
          <PrivateRoute
            path="/order-vaccine/daily-report"
            exact
            component={VaccineDailyReport}
          />
        )}

        {routeManagement.includes("partner_management") && (
          <PrivateRoute path="/order-history" exact component={OrderHistory} />
        )}
        {routeManagement.includes("partner_management") && (
          <PrivateRoute
            path="/order-history/detail"
            exact
            component={OrderHistoryDetail}
          />
        )}
        {routeManagement.includes("partner_management") && (
          <PrivateRoute
            path="/order-history/detail/profile"
            exact
            component={OrderHistoryDetailProfile}
          />
        )}
        {routeManagement.includes("partner_management") && (
          <PrivateRoute
            path="/order-history/detail/riwayat"
            exact
            component={OrderHistoryDetailRiwayat}
          />
        )}
        {routeManagement.includes("partner_management") && (
          <PrivateRoute
            path="/order-history/detail/lihatriwayat"
            exact
            component={OrderHistoryDetailLihatRiwayat}
          />
        )}
        {routeManagement.includes("partner_management") && (
          <PrivateRoute
            path="/order-history/detail/consentform"
            exact
            component={OrderHistoryDetailConsentForm}
          />
        )}
        {routeManagement.includes("partner_management") && (
          <PrivateRoute
            path="/order-history/detail/listprosedur"
            exact
            component={OrderHistoryDetailListProsedur}
          />
        )}
        {routeManagement.includes("partner_management") && (
          <PrivateRoute
            path="/order-history/detail/ordergallery"
            exact
            component={OrderHistoryDetailLampiranFoto}
          />
        )}
        {routeManagement.includes("partner_management") && (
          <PrivateRoute
            path="/order-history/detail/layanan"
            exact
            component={OrderHistoryDetailLayanan}
          />
        )}
        {routeManagement.includes("register_teleconsultation") && (
          <PrivateRoute
            path="/register-teleconsultation"
            exact
            component={RegisterPatient}
          />
        )}
        {routeManagement.includes("register_teleconsultation") && (
          <PrivateRoute
            path="/register-teleconsultation/add"
            exact
            component={RegisterPatientAdd}
          />
        )}
        {routeManagement.includes("register_teleconsultation") && (
          <PrivateRoute
            path="/payment-offline"
            exact
            component={PaymentOffline}
          />
        )}
        {routeManagement.includes("register_teleconsultation") && (
          <PrivateRoute
            path="/payment-offline/add"
            exact
            component={PaymentOfflineAdd}
          />
        )}
        {routeManagement.includes("register_teleconsultation") && (
          <PrivateRoute
            path="/payment-offlinevoucher"
            exact
            component={PaymentOfflineVoucher}
          />
        )}

        {routeManagement.includes("gp_onsite") && (
          <PrivateRoute path="/gp-onsite" exact component={GPOnsite} />
        )}

        {routeManagement.includes("gp_onsite") && (
          <PrivateRoute path="/gp-onsite/done" exact component={GPOnsiteDone} />
        )}

        {routeManagement.includes("health_screening") && (
          <PrivateRoute path="/CheckIn-HS" exact component={CheckInHS} />
        )}
        {routeManagement.includes("health_screening") && (
          <PrivateRoute
            exact
            path="/CheckIn-HS/:eventId"
            component={CheckInHSEvent}
          />
        )}

        {/* Other */}
        <PrivateRoute
          exact
          path="/change-password"
          component={ChangePassword}
        />

        {/* Order Pharmacy By Unit (As for now in production, v1 is no longer used so the url is redirected to /add-address-v1) */}
        <Route path="/order-pharmacy/add-address-v1"
          component={RegisterPharmacyUnitV2} // Redirect from old form so every units now use the new form

        />

        {/* Order Pharmacy By Unit Version 2 (As for now in production, v2 is used so the url is redirected to /add-address)*/}
        <Route
          path="/order-pharmacy/add-address"
          component={RegisterPharmacyUnitV2}
        />

        {/* Redirect from old form so every units now use the new form */}
       

        {/* Test Covid */}
        <Route path="/test-covid/list" component={List} />
        <Route path="/test-covid/detail" component={Detail} />
        <Route path="/test-covid/login" component={LoginTestCovid} />
        <Route path="/test-covid/verifyOtp" component={VerifyOtp} />
        <Route path="/test-covid/register" component={Register} />
        <Route path="/test-covid/form" component={FormRegist} />
        <Route path="/test-covid/order" component={Order} />
        <Route path="/test-covid/payment-method" component={PaymentMethod} />
        <Route path="/test-covid/promo" component={Promo} />
        <Route path="/test-covid/success" component={Success} />

        {/* Home Screen */}
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
