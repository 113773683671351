import axios from "../helpers/axios";
import { myVaccinationEventConstants } from "./constants";

export const getAllVaccinationEvent = (query) => {
  return async (dispatch) => {
    dispatch({ type: myVaccinationEventConstants.GET_ALL_EVENTS_REQUEST });
    const res = await axios.get(
      `/Event/List?parentId=${localStorage.getItem("parentId")}&${query}`
    );
    if (res.status === 200) {
      dispatch({
        type: myVaccinationEventConstants.GET_ALL_EVENTS_SUCCESS,
        payload: { events: res.data },
      });
    } else {
      dispatch({
        type: myVaccinationEventConstants.GET_ALL_EVENTS_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getVaccinationEvent = (id) => {
  return async (dispatch) => {
    dispatch({
      type: myVaccinationEventConstants.VACCINATION_EVENT_DETAIL_REQUEST,
    });
    const res = await axios.get(`/GotongRoyong/detail/${id}`);
    if (res.status === 200) {
      dispatch({
        type: myVaccinationEventConstants.VACCINATION_EVENT_DETAIL_SUCCESS,
        payload: { event: res.data, employeeData: res.data.employeeData },
      });
    } else {
      dispatch({
        type: myVaccinationEventConstants.VACCINATION_EVENT_DETAIL_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const createEventGotongRoyong = (form) => {
  return async (dispatch) => {
    dispatch({
      type: myVaccinationEventConstants.CREATE_VACCINATION_EVENT_REQUEST,
    });
    const res = await axios.post(`/GotongRoyong/submit`, form);
    if (res.status === 200) {
      dispatch({
        type: myVaccinationEventConstants.CREATE_VACCINATION_EVENT_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllVaccinationEvent());
    } else {
      dispatch({
        type: myVaccinationEventConstants.CREATE_VACCINATION_EVENT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const createEventNasional = (form) => {
  return async (dispatch) => {
    dispatch({
      type: myVaccinationEventConstants.CREATE_VACCINATION_EVENT_REQUEST,
    });
    const res = await axios.post(`/Nasional/submit`, form);
    if (res.status === 200) {
      dispatch({
        type: myVaccinationEventConstants.CREATE_VACCINATION_EVENT_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllVaccinationEvent());
    } else {
      dispatch({
        type: myVaccinationEventConstants.CREATE_VACCINATION_EVENT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const deleteEvent = (id, query) => {
  return async (dispatch) => {
    dispatch({
      type: myVaccinationEventConstants.DELETE_VACCINATION_EVENT_REQUEST,
    });
    const res = await axios.delete(`/GotongRoyong/delete?partnerId=${id}`);
    if (res.status === 200) {
      dispatch({
        type: myVaccinationEventConstants.DELETE_VACCINATION_EVENT_SUCCESS,
      });
      dispatch(getAllVaccinationEvent(query));
    } else {
      dispatch({
        type: myVaccinationEventConstants.DELETE_VACCINATION_EVENT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
