import axios from "../helpers/axios";
import { pharmacyDeliveryAllUnitConstants } from "./constants";

export const getPharmacyDeliveryAllUnit = (search) => {
  return async (dispatch) => {
    dispatch({
      type: pharmacyDeliveryAllUnitConstants.GET_PHARMACY_ALLUNIT_REQUEST,
    });
    const res = await axios.get(`/Order/getPharmacyDeliveryAllUnit?${search}`);
    if (res.status === 200) {
      dispatch({
        type: pharmacyDeliveryAllUnitConstants.GET_PHARMACY_ALLUNIT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: pharmacyDeliveryAllUnitConstants.GET_PHARMACY_ALLUNIT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getDetailPharmacyDeliveryAllUnit = (id) => {
  return async (dispatch) => {
    dispatch({
      type: pharmacyDeliveryAllUnitConstants.GET_DETAIL_PHARMACY_ALLUNIT_REQUEST,
    });
    const res = await axios.get(
      `/Order/getDetailPharmacyDeliveryAllUnit/${id}`
    );
    if (res.status === 200) {
      dispatch({
        type: pharmacyDeliveryAllUnitConstants.GET_DETAIL_PHARMACY_ALLUNIT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: pharmacyDeliveryAllUnitConstants.GET_DETAIL_PHARMACY_ALLUNIT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
