import React, { useEffect } from "react";

export default function Pagination({ props, setProps, onChange }) {
  let { page, limit, totalData } = props;
  let lastPage = Math.ceil(totalData / limit);

  const isLastPage = () => {
    if (page === Math.ceil(totalData / limit)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    onChange(page, limit);
  }, [page, limit, totalData, onChange]);

  return (
    <>
      <div className="row">
        <div className="col-md-12" style={{ marginTop: "10px" }}>
          <nav aria-label="..." className="pull-right">
            <ul className="pagination" style={{ margin: "auto", justifyContent: "flex-end", marginTop: "10px"}}>
              <li
                className={`page-item page-first ${page === 1 && "disabled"}`}
              >
                <div
                  className="page-link"
                  onClick={() => {
                    setProps((prev) => {
                      return {
                        ...prev,
                        page: 1,
                      };
                    });
                  }}
                >
                  First
                </div>
              </li>
              <li className={`page-item ${page === 1 && "disabled"}`}>
                <div
                  className="page-link"
                  onClick={() => {
                    setProps((prev) => {
                      return {
                        ...prev,
                        page: prev.page - 1,
                      };
                    });
                  }}
                >
                  <i
                    className="fa fa-caret-left"
                    style={{
                      color: `${(page === 1 && "#6c757d") || "#007bff"}`,
                    }}
                  ></i>
                </div>
              </li>
              {[...Array(lastPage)].map((e, i) => {
                i = i + 1;
                return (
                  <React.Fragment key={i}>
                    {page > 2 && i === 2 ? (
                      <li className="page-item disabled">
                        <span className="page-link">...</span>
                      </li>
                    ) : (
                      ""
                    )}
                    {i === page ? (
                      <li className="page-item active">
                        <div className="page-link">
                          {i}
                          <span className="sr-only">(current)</span>
                        </div>
                      </li>
                    ) : i === page + 1 ||
                      i === page + 2 ||
                      i === page - 1 ||
                      i === page - 2 ||
                      i === lastPage ||
                      i === 1 ? (
                      <li className="page-item">
                        <div
                          className="page-link"
                          onClick={() => {
                            setProps((prev) => {
                              return {
                                ...prev,
                                page: i,
                              };
                            });
                          }}
                        >
                          {i}
                        </div>
                      </li>
                    ) : (
                      ""
                    )}

                    {page < lastPage - 2 && i === lastPage - 1 ? (
                      <li className="page-item disabled">
                        <span className="page-link">...</span>
                      </li>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                );
              })}

              <li className={`page-item ${isLastPage() && "disabled"}`}>
                <div
                  className="page-link"
                  onClick={() => {
                    setProps((prev) => {
                      return {
                        ...prev,
                        page: prev.page + 1,
                      };
                    });
                  }}
                >
                  <i
                    className="fa fa-caret-right"
                    style={{
                      color: `${(isLastPage() && "#6c757d") || "#007bff"}`,
                    }}
                  ></i>
                </div>
              </li>
              <li
                className={`page-item page-last ${isLastPage() && "disabled"}`}
              >
                <div
                  className="page-link"
                  onClick={() => {
                    setProps((prev) => {
                      return {
                        ...prev,
                        page: lastPage,
                      };
                    });
                  }}
                >
                  Last
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}
