import React, { useEffect, useState, useRef } from "react";
import DashboardLayout from "../Layout";
import queryString from "query-string";
// Query
import { useDispatch, useSelector } from "react-redux";
import {
    getAllOrderPharmacy, getListOrderPharmacy,
} from "../../../actions";
// Helpers
import { formatDate } from "../../../helpers/getFormattedDate";

// Pagination
import Pagination from "../../../components/Pagination";
// Alert
import Swal from "sweetalert2";

export default function OrderPharmacy() {

  // Query
  const [query, setQuery] = useState("");
  const result = useSelector((state) => state.orderPharmacy);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListOrderPharmacy(query));
  }, [query, setQuery, dispatch]);

  // Pagination
  const [paginationProps, setPaginationProps] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });

  useEffect(() => {
    setPaginationProps((prev) => {
      return {
        ...prev,
        totalData: result.totalData,
      };
    });
  }, [result]);

  // Filter
  const dateFrom = useRef(null);
  const dateTo = useRef(null);
  const patientName = useRef(null);
  const status = useRef(null);

  // Submit Query & Pagination
   // Submit Query & Pagination
   const submitFilter = (page, limit) => {
    let values = {};
    if (page) {
      values.page = page;
    } else {
      // Reset if searched
      values.page = 1;
      setPaginationProps((prev) => {
        return {
          ...prev,
          page: 1,
        };
      });
    }

    if (limit) {
      values.limit = limit;
    }

    if (status.current.value !== "") {
        values.searchKey = patientName.current.value;
    }

    if (patientName.current.value !== "") {
      values.searchKey = patientName.current.value;
    }
    
    if (dateFrom.current.value !== "") {
      values.startDate = dateFrom.current.value;
    }

    if (dateTo.current.value !== "") {
      values.endDate = dateTo.current.value;
    }

    let query = queryString.stringify(values);
    setQuery(query);
  };

  // reset filter & pagination
  const handleReset = (page, limit) => {
    dateFrom.current.value = "";
    dateTo.current.value = "";
    patientName.current.value = "";
    status.current.value = "";

    let values = {};
    if (page) {
      values.page = page;
    } else {
      // Reset if searched
      values.page = 1;
      setPaginationProps((prev) => {
        return {
          ...prev,
          page: 1,
        };
      });
    }

    if (limit) {
      values.limit = limit;
    }

    if (status.current.value !== "") {
        values.searchKey = patientName.current.value;
      }

    if (patientName.current.value !== "") {
      values.searchKey = patientName.current.value;
    }
    
    if (dateFrom.current.value !== "") {
      values.startDate = dateFrom.current.value;
    }

    if (dateTo.current.value !== "") {
      values.endDate = dateTo.current.value;
    }

    let query = queryString.stringify(values);
    setQuery(query);
  };

  const filterProps = {
    status,
    dateFrom,
    dateTo,
    patientName,
    submitFilter,
    handleReset,
  };

  return (
    <DashboardLayout>
      <Layout>
        {/* Title */}
        <div className="row">
          <div className="col-md-6" id="btn-add">
            <span id="finance-title" className="mb10">
              List Order (Pharmacy)
            </span>
          </div>
        </div>
        {/* Filter */}
        <Filter {...filterProps} />
        {/* Table */}
        <div className="table-responsive">
          <table
            id="tableScheduler"
            className="table table-striped table-bordered"
          >
            <thead className="table-header">
              {/* Rows: 17 */}
              <tr>
                <th rowSpan={2} className="table-header-middle">
                  No
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Order ID
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Customer Name
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Customer Phone
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Patient Name
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Delivery Address
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Total Amount
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Status
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {DataTables(result, paginationProps)}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        <Pagination
          props={paginationProps}
          setProps={setPaginationProps}
          onChange={(page, limit) => {
            submitFilter(page, limit);
          }}
        />
      </Layout>
    </DashboardLayout>
  );
}

const alertMessage = (id) => {
    const urlQuery = "";
    if(typeof window.location.href.split('?')[1] !== 'undefined'){
        urlQuery = window.location.href.split('?')[1];
    }
    if(id !== ""){
        Swal.fire({
            title: "Reconfirmation",
            text: "apakah anda yakin ingin complete order pharmacy dengan id " + id + "?",
            icon: "warning",
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#7cb342",
            cancelButtonText: "No",
        }).then((result) => {
            if (result.value) {
                if(id != null) {
                    window.location.replace("/order/complete/pharmacy/" + id);
                }
            } 
        });
    }
}

const DataTables = (result, paginationProps) => {
  const nf = new Intl.NumberFormat();

  const orders = result?.orders || [];
  const chatVal = result?.foundPartner || [];
  return (
    <>
      {/* Empty */}
      {orders.length === 0 && (
        <tr>
          <td colSpan="9">Empty Data</td>
        </tr>
      )}

      {/* Has Data */}
      {orders.map((value, index) => {
        return (
          <tr key={index}>
            <td>{(paginationProps.page - 1) * 10 + index + 1}</td>
            <td>{value.order_id}</td>
            <td>{value.customer_name}</td>
            <td>{value.customer_phone}</td>
            <td>{value.patient_name}</td>
            <td>{value.address}</td>
            <td>Rp. {nf.format(value.totalPrice)}</td>
            <td>{value.statusDesc}</td>
            <td>
                <div className="span2">
                    <p style={{marginBottom: "10px"}}>
                        {chatVal.sendbird_token != null && value.channel_url != null ?
                            <a href={process.env.REACT_APP_CHAT_URL+"chat.html?userid="+chatVal.userId+"&userToken="+chatVal.sendbird_token+"&chatUrl="+value.channel_url} 
                                target="_blank">
                                <button type="button" className="btn btn-outline-info btn-block">
                                    Chat Customer
                                </button>
                            </a>
                            :
                            <button type="button" className="btn btn-outline-info btn-block"  style={{background: "#ccc", color: "grey", borderColor: "grey"}} disabled>
                                Chat Customer 
                            </button>
                        }
                    </p>
                    <p style = {{marginBottom: "10px"}}>
                        {value.order_id != null ?
                            <button type="button" onClick={() => {alertMessage(value.order_id);}} className="btn btn-outline-success btn-block">
                                Complete Order
                            </button>
                            :
                            <button type="button" className="btn btn-outline-info btn-block"  style={{background:"#ccc", color: "grey", borderColor: "grey"}} disabled>
                                Complete Order
                            </button>
                        }
                    </p>
                    <p>
                        <button type="button" className="btn btn-outline-info btn-block"  style={{background:"#ccc", color: "grey", borderColor: "grey"}} disabled>
                            Next Status
                        </button>
                    </p>
                </div>
            </td>
          </tr>
        );
      })}
    </>
  );
};

const Layout = ({ children }) => {
  // Default Layout
  return (
    <>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>
        <div>List Order (Pharmacy)</div>
        <div className="col">
          <hr />
        </div>
        
      </div>
      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>
        {children}
      </div>
    </>
  );
};

const Filter = (props) => {
  const { status, dateFrom, dateTo, patientName, submitFilter, handleReset } = props;

  return (
    <>
      <div className="row mb15">
        <div className="link-btn">
          <button
            type="button"
            className="btn btn-link no-padding"
            id="filterBy"
            data-toggle="collapse"
            data-target="#collapseDetailSchedule"
          >
            Filter By
            <i className="fa fa-angle-down" style={{ marginLeft: "10px" }} />
          </button>
        </div>
        <div className="col">
          <hr />
        </div>
      </div>
      <div className="collapse" id="collapseDetailSchedule">
        <div className="row">
            <div className="col-md-6">
                <div className = "row">
                    <div className="col-md-6 pr-md-0">
                        <div className="form-group mb-md-0" style={{marginRight: "10px"}}>
                            <label>Date From</label>
                            <input type="date" className="form-control" ref={dateFrom} />
                        </div>
                    </div>
                    <div className="col-md-6 pr-md-0">
                        <div className="form-group mb-md-0">
                            <label>Date To</label>
                            <input type="date" className="form-control" ref={dateTo} />
                        </div>
                    </div>
                </div>
            </div>
          <div className="col-md-3 neutral-border">
            <div className="form-group mb-md-0">
              <label>Status</label>
              <select className="form-control" ref={status}>
                <option value="">Select status</option>
                <option value="98">Menunggu Pembayaran</option>
                <option value="22">Telah Dibayar</option>
                <option value="88">Cancelled By Customer</option>
                <option value="89">Cancelled By CMS</option>
                <option value="99">Completed</option>
              </select>
            </div>
          </div>
          <div className="col-md-3">
          <div className="form-group mb-md-0">
                <label>Patient Name</label>
                <input type="text" className="form-control" ref={patientName} />
            </div>
          </div>
        </div>
        <div className="fm-btn-gr text-right">
            <button
                type="button"
                className="btn btn-secondary"
                style={{ marginRight: "10px" }}
                onClick={() => {
                handleReset();
                }}
            >
            Reset
          </button>
          <button
            onClick={() => {
              submitFilter();
            }}
            type="button"
            className="btn btn-primary"
          >
            Apply
          </button>
        </div>

        <div style={{ width: "100%" }}>
          <hr />
        </div>
      </div>
    </>
  );
};
