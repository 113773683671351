import axios from "../helpers/axios";
import { orderByIdConstants } from "./constants";

export const getOrderById = (id) => {
  return async (dispatch) => {
    dispatch({ type: orderByIdConstants.GET_ORDER_BY_ID_REQUEST });
    console.log('get order', id)
    const res = await axios.get(
      `/Order/Swab/${id}`, 
    );
    if (res.status === 200) {
      const { data, status } = res;
      console.log('res.data', data)
      console.log('res.status', status)
      dispatch({
        type: orderByIdConstants.GET_ORDER_BY_ID_SUCCESS,
        payload: { data, status },
      });
    } else {
      dispatch({
        type: orderByIdConstants.GET_ORDER_BY_ID_FAILURE,
        payload: { error: res.data.error },
      });
    } 
  };
};

export const updateStatus = (id) => {
  return async (dispatch) => {
    dispatch({ type: orderByIdConstants.UPDATE_ORDER_STATUS_REQUEST });
    console.log('update order', id)
    const res = await axios.put(`/Order/Swab/${id}`);
    if (res.status === 200) {
      const { data, status } = res;
      dispatch({
        type: orderByIdConstants.UPDATE_ORDER_STATUS_SUCCESS,
        payload: { data, status },
      });
    } else {
      dispatch({
        type: orderByIdConstants.UPDATE_ORDER_STATUS_SUCCESS,
        payload: { error: res.data.error },
      });
    }
  };
};
