import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import DashboardLayout from "../Layout";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getOrderRequest } from "../../../actions";

const OrderRequest = () => {
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const startDate = useRef("");
  const endDate = useRef("");
  const name = useRef("");
  const orderRequest = useSelector((state) => state.orderRequest);
  const dispatch = useDispatch();
  const { loading, error, orders, totalData } = orderRequest;

  useEffect(() => {
    dispatch(getOrderRequest(search));
  }, [search]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: totalData,
    });
  }, [totalData]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const handleReset = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      page: pagination.page,
      limit: pagination.limit,
    };

    if (startDate.current.value !== "") {
      startDate.current.value = "";
    }

    if (endDate.current.value !== "") {
      endDate.current.value = "";
    }

    if (name.current.value !== "") {
      name.current.value = "";
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleSearch = (page, limit) => {
    let values = {};

    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }

    if (limit) {
      values.limit = limit;
    }

    if (startDate.current.value !== "") {
      values.startDate = startDate.current.value;
    }

    if (endDate.current.value !== "") {
      values.endDate = endDate.current.value;
    }

    if (name.current.value !== "") {
      values.name = name.current.value;
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const renderOrders = () => {
    return loading ? (
      <tr>
        <td colSpan="8">
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        {orders.length > 0 ? (
          orders.map((item, index) => (
            <tr key={item.requestId}>
              <td>{(pagination.page - 1) * 10 + index + 1}</td>
              <td>{item.requestId ? item.requestId : "-"}</td>
              <td>{item.patientName ? item.patientName : "-"}</td>
              <td>{item.patientPhone ? `+62${item.patientPhone}` : "-"}</td>
              <td>{item.voucherTitle ? item.voucherTitle : "-"}</td>
              <td>
                {item.dateRequest ? moment(item.dateRequest).format("ll") : "-"}
              </td>
              <td>{item.description ? item.description : "-"}</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <Link
                    to={{
                      pathname: "/order-request/detail",
                      state: { requestId: item.requestId },
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-outline-success btn-block"
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      Follow Up
                    </button>
                  </Link>

                  {item.channel_url != null ? (
                    <a
                      href={`${process.env.REACT_APP_CHAT_URL}chat.html?userid=${orderRequest.userId}&userToken=${orderRequest.sendbirdToken}&chatUrl=${item.channel_url}`}
                      target="_blank"
                    >
                      <button
                        type="button"
                        className="btn btn-block btn-outline-primary"
                      >
                        Chat
                      </button>
                    </a>
                  ) : null}
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="8">Empty data</td>
          </tr>
        )}
      </>
    );
  };

  return (
    <DashboardLayout>
      <>
        <div className="row revenue-title">
          <div className="col">
            <hr />
          </div>

          <div>Order Request</div>

          <div className="col">
            <hr />
          </div>
        </div>

        <div className="table-wrapper">
          <div className="alert alert-info" style={{ textAlign: "center" }}>
            <p style={{ fontSize: "15px", marginBottom: "10px" }}>
              Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
              aido, silahkan hubungi aido support dengan click chat aido
              support.
              <br />
              <a
                href="https://wa.me/628118481436"
                style={{ color: "#51BED3" }}
                target="blank"
              >
                Chat Aido Support
              </a>
            </p>
          </div>

          <div className="row">
            <div className="col-md-6" id="btn-add">
              <span id="finance-title" className="mb10">
                Order Request List
              </span>
            </div>
          </div>

          <div className="row mb15">
            <div className="link-btn">
              <button
                type="button"
                className="btn btn-link no-padding"
                style={{ textDecoration: "none", boxShadow: "none" }}
                data-toggle="collapse"
                data-target="#collapseDetailSchedule"
              >
                Filter By
                <i className="fa fa-angle-down" style={{ marginLeft: "5px" }} />
              </button>
            </div>

            <div className="col">
              <hr />
            </div>
          </div>

          <div className="collapse" id="collapseDetailSchedule">
            <div id="filterForm" className="row filter-by">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb10">From Date</div>
                    <input
                      type="date"
                      className="form-control"
                      ref={startDate}
                    />
                  </div>

                  <div className="col-md-4">
                    <div className="mb10">To Date</div>
                    <input type="date" className="form-control" ref={endDate} />
                  </div>

                  <div className="col-md-4">
                    <div className="mb10">Patient Name</div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Patient Name"
                      ref={name}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="fm-btn-gr text-right">
              <button
                type="button"
                className="btn btn-secondary btn-reset"
                style={{ width: "100px", marginRight: "5px" }}
                onClick={() => handleReset()}
              >
                Reset
              </button>

              <button
                type="button"
                className="btn btn-primary"
                style={{ width: "100px" }}
                onClick={() => handleSearch()}
              >
                Apply
              </button>
            </div>

            <div style={{ width: "100%" }}>
              <hr />
            </div>
          </div>

          <div className="table-responsive">
            <table
              id="tableScheduler"
              className="table table-striped table-bordered"
            >
              <thead className="table-header">
                <tr>
                  <th
                    rowSpan={2}
                    className="table-header-middle"
                    style={{ width: "45px" }}
                  >
                    No
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Request ID
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Patient Name
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Patient Number
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Service Package
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Created Date
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Status
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Action
                  </th>
                </tr>
              </thead>

              <tbody>{renderOrders()}</tbody>
            </table>
          </div>

          {totalData > 10 ? (
            <Pagination
              props={pagination}
              setProps={setPagination}
              onChange={(page, limit) => {
                handleSearch(page, limit);
              }}
            />
          ) : null}
        </div>
      </>
    </DashboardLayout>
  );
};

export default OrderRequest;
