import React from "react";
import { Form } from "react-bootstrap";

const Input = (props) => {
  let input = null;
  switch (props.type) {
    case "text":
    default:
      input = (
        <Form.Group>
          {props.label && <Form.Label>{props.label}</Form.Label>}
          <Form.Control
            name={props.name}
            type={props.type}
            placeholder={props.placeholder}
            value={props.value}
            accept={props.accept}
            rows={props.rows}
            min={props.min}
            onChange={props.onChange}
            {...props}
          />
          <Form.Text className="text-muted">{props.errorMessage}</Form.Text>
        </Form.Group>
      );
  }

  return input;
};

export default Input;
