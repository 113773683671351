import axios from "../helpers/axios";
import { myPromoConstants } from "./constants";

export const getAllPromo = (search) => {
  return async (dispatch) => {
    dispatch({ type: myPromoConstants.GET_ALL_PROMO_REQUEST });
    const res = await axios.get(`/Promo/Teleconsultation?${search}`);
    if (res.status === 200) {
      dispatch({
        type: myPromoConstants.GET_ALL_PROMO_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: myPromoConstants.GET_ALL_PROMO_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getPromoById = (id) => {
    return async (dispatch) => {
      dispatch({ type: myPromoConstants.GET_PROMO_BY_ID_REQUEST });
      const res = await axios.get(`/Promo/${id}`);
      if (res.status === 200) {
        dispatch({
          type: myPromoConstants.GET_PROMO_BY_ID_SUCCESS,
          payload: { promoDataEdit: res.data },
        });
      } else {
        dispatch({
          type: myPromoConstants.GET_PROMO_BY_ID_FAILURE,
          payload: { error: res.data.error },
        });
      }
    };
};

export const getPromoConstants = () => {
  return async (dispatch) => {
    dispatch({ type: myPromoConstants.GET_PROMO_CONSTANTS_REQUEST });
    const res = await axios.get(`/PromoConstants`);
    if (res.status === 200) {
      dispatch({
        type: myPromoConstants.GET_PROMO_CONSTANTS_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: myPromoConstants.GET_PROMO_CONSTANTS_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const addPromo = (form, search) => {
  return async (dispatch) => {
    dispatch({ type: myPromoConstants.ADD_NEW_PROMO_REQUEST });
    const res = await axios.post(`/Promo`, form);
    if (res.status === 200) {
      dispatch({
        type: myPromoConstants.ADD_NEW_PROMO_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllPromo(search));
    } else {
      dispatch({
        type: myPromoConstants.ADD_NEW_PROMO_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const updatePromo = (id, form, search) => {
  return async (dispatch) => {
    dispatch({ type: myPromoConstants.UPDATE_PROMO_REQUEST });
    const res = await axios.put(`/Promo/${id}`, form);
    if (res.status === 200) {
      dispatch({
        type: myPromoConstants.UPDATE_PROMO_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllPromo(search));
    } else {
      dispatch({
        type: myPromoConstants.UPDATE_PROMO_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const deletePromo = (id, search) => {
  return async (dispatch) => {
    dispatch({ type: myPromoConstants.DELETE_PROMO_REQUEST });
    const res = await axios.delete(`/Promo/${id}`, {
      payload: {
        id,
      },
    });
    if (res.status === 200) {
      dispatch({ type: myPromoConstants.DELETE_PROMO_SUCCESS });
      dispatch(getAllPromo(search));
    } else {
      dispatch({
        type: myPromoConstants.DELETE_PROMO_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
