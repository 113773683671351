import axios from "../helpers/axios";
import { teleRefundConstants } from "./constants";

export const getAllTeleRefund = (q) => {
  return async (dispatch) => {
    dispatch({ type: teleRefundConstants.GET_ALL_TELEREFUND_REQUEST });
    const res = await axios.get(
      `/Order/newAndRescheduleTeleconsultation?${q}`, 
    );
    if (res.status === 200) {
      const { orders, totalData, sendBird, parentId } = res.data;
      dispatch({
        type: teleRefundConstants.GET_ALL_TELEREFUND_SUCCESS,
        payload: { orders, totalData, sendBird, parentId },
      });
    } else {
      dispatch({
        type: teleRefundConstants.GET_ALL_TELEREFUND_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const doRefund = (data, query) => {
  return async (dispatch) => {
    dispatch({ type: teleRefundConstants.DO_REFUND_REQUEST });
    const res = await axios.post(
      "/Order/Refund", data 
    );
    if (res.status === 200) {
      const { orders } = res.data;
      dispatch({
        type: teleRefundConstants.DO_REFUND_SUCCESS,
        payload: { orders },
      });
      dispatch(getAllTeleRefund(query))
    } else {
      dispatch({
        type: teleRefundConstants.DO_REFUND_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};