import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addMedical,
  deleteMedical,
  getAllMedical,
  getMedicalById,
  updateMedical,
} from "../../../actions";
import Input from "../Components/Input";
import Modal from "../Components/Modal";
import Pagination from "../../../components/Pagination";
import DashboardLayout from "../Layout";
import moment from "moment";
import Swal from "sweetalert2";

const MyMedical = () => {
  const [price, setPrice] = useState("");
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [addMedicalModal, setAddMedicalModal] = useState(false);
  const [updateMedicalModal, setUpdateMedicalModal] = useState(false);
  const [key, setKey] = useState("");
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const myMedical = useSelector((state) => state.myMedical);
  const dispatch = useDispatch();

  const initState = () => {
    setName("");
    setPrice("");
  };

  useEffect(() => {
    dispatch(getAllMedical(search));
    if (id !== "") {
      getData(myMedical.medicalData);
    }
  }, [search, myMedical.medicalData]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: myMedical.totalData,
    });
  }, [myMedical.totalData]);

  const handleCloseModal = () => {
    initState();
    setAddMedicalModal(false);
    setUpdateMedicalModal(false);
  };

  const handleAutoComplete = (e) => {
    setKey(e.target.value);
    setPagination({
      ...pagination,
      page: 1,
      limit: 10,
    });
    setSearch(
      `page=${pagination.page}&limit=${pagination.limit}&search=${e.target.value}`
    );
  };

  const handlePagination = (page, limit) => {
    setSearch(`page=${page}&limit=${limit}&search=${key}`);
  };

  const handleAddMedical = () => {
    setAddMedicalModal(true);
  };

  const actionAddMedical = () => {
    const form = new FormData();
    form.append("productName", name);
    form.append("price", price);

    dispatch(addMedical(form, search))
      .then((result) => {
        if (result) {
          dispatch(getAllMedical(search));
        }
      })
      .then(() => {
        initState();
        setAddMedicalModal(false);
      })
      .then(
        setTimeout(() => Swal.fire("Success!", "Data Created", "success"), 3000)
      );
  };

  const renderAddMedical = () => {
    return (
      <Modal
        modalTitle="Add New Medical Supplies"
        show={addMedicalModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        onSubmit={actionAddMedical}
      >
        <Row>
          <Col md>
            <Input
              label="Name"
              placeholder="Input Medical Supplies Name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
          <Col md>
            <Input
              label="Price"
              placeholder="Input Price"
              type="text"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </Col>
        </Row>
      </Modal>
    );
  };

  const handleUpdateMedical = (medicalId) => {
    setId(medicalId);
    dispatch(getMedicalById(medicalId)).then(
      setTimeout(() => setUpdateMedicalModal(true), 200)
    );
  };

  const getData = (data) => {
    setName(data.productName);
    setPrice(data.price);
  };

  const actionUpdateMedical = () => {
    const form = new FormData();
    form.append("productName", name);
    form.append("price", price);

    dispatch(updateMedical(id, form, search))
      .then((result) => {
        if (result) {
          dispatch(getAllMedical(search));
        }
      })
      .then(() => {
        initState();
        setUpdateMedicalModal(false);
      })
      .then(
        setTimeout(() => Swal.fire("Success!", "Data Updated", "success"), 1000)
      );
  };

  const renderUpdateMedical = () => {
    return (
      <Modal
        modalTitle="Update Medical Supplies"
        show={updateMedicalModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        buttons={[
          {
            label: "Update",
            color: "warning",
            onClick: actionUpdateMedical,
          },
        ]}
      >
        <Row>
          <Col md>
            <Input
              label="Name"
              placeholder="Input Medical Supplies Name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
          <Col md>
            <Input
              label="Price"
              placeholder="Input Price"
              type="text"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </Col>
        </Row>
      </Modal>
    );
  };

  const handledeleteMedical = (medicalId) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "grey",
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteMedical(medicalId, search))
          .then((result) => {
            if (result) {
              dispatch(getAllMedical(search));
            }
          })
          .then(() => {
            initState();
            setTimeout(
              () => Swal.fire("Success!", "Data Deleted", "success"),
              200
            );
          });
      }
    });
  };

  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const renderMedical = () => {
    return myMedical.loading ? (
      <td colSpan="6">
        <Loading />
      </td>
    ) : (
      <>
        {myMedical.medicalSuppliess.length > 0 ? (
          myMedical.medicalSuppliess.map((medical) => (
            <tr key={medical.id}>
              <td className="num"></td>
              <td>{medical.productName}</td>
              <td>{formatter.format(medical.price)}</td>
              <td>
                <div style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    className="btn"
                    onClick={() => handleUpdateMedical(medical.id)}
                  >
                    <img
                      src="/img/icon/ic_edit.png"
                      alt="edit"
                      className="container-img"
                    />
                  </button>

                  <button
                    type="button"
                    className="btn"
                    onClick={() => handledeleteMedical(medical.id)}
                  >
                    <img
                      src="/img/icon/ic_delete.png"
                      alt="delete"
                      className="container-img"
                    />
                  </button>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="6">Empty data</td>
          </tr>
        )}
      </>
    );
  };

  return (
    <DashboardLayout>
      {/* <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>My Medical Supplies</div>

        <div className="col">
          <hr />
        </div>
      </div> */}

      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        {/* <div className="row">
          <div className="col-md-6">
            <span id="finance-title" className="mb10">
              Medical Supplies
            </span>
          </div>
        </div> */}

        <div className="row">
          <div className="col-md-8" style={{ paddingBottom: "1em" }}>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => handleAddMedical()}
            >
              Add New Medical Supply
            </button>
          </div>

          <div className="col-md-4" id="search-form">
            <div className="input-group" style={{ paddingBottom: "1em" }}>
              <input
                className="form-control table-search-field"
                type="text"
                value={key}
                onChange={handleAutoComplete}
                placeholder="Search Name"
              />
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "45px" }}
                >
                  No
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "120px" }}
                >
                  Medical Supplies
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "350px" }}
                >
                  Price
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "180px" }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>{renderMedical()}</tbody>
          </table>
        </div>

        {myMedical.totalData > 10 ? (
          <Pagination
            props={pagination}
            setProps={setPagination}
            onChange={(page, limit) => {
              handlePagination(page, limit);
            }}
          />
        ) : null}
      </div>
      {renderAddMedical()}
      {renderUpdateMedical()}
    </DashboardLayout>
  );
};

export default MyMedical;
