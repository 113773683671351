import React, { useEffect, useState } from "react";
import moment from "moment";
import CreatableSelect from 'react-select/creatable';
import DashboardLayout from "../../Layout";
import Swal from "sweetalert2";
import { Link, useLocation } from "react-router-dom";
import { Avatar } from "@mui/material";
import { Col, Form, Row } from "react-bootstrap";

import "./index.scss";

const Breadcrumb = (props) => {
  return (
      <nav key={props.label} aria-label="breadcrumb" id="breadcrumb-id">
          <ol className="breadcrumb pl-0">
            <li className="breadcrumb-item">
              <a>Dashboard</a>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <Link to={props.path}>{props.menu}</Link>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <Link to={props.childPath}>{props.childMenu}</Link>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <Link to={props.childPath2}>{props.childMenu2}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {props.label}
            </li>
            
          </ol>
      </nav>
  )
}

const MedicalHistoryDetail = () => {
  const [patientData, setPatientData] = useState({
    patientName: "",
    patientDob: "",
    patientAge: "",
    patientGender: "",
    patientPhone: "",
    patientEmail: "",
    avatar: "",
  });
  const [medicalRecord, setMedicalRecord] = useState({
      orderId: "",
      scheduleDate: "",
      specialist: "",
      icdCode: "",
      diagnosis: "",
      partnerName: "",
      medicalRecordId: "",
      icdDiseases: "",
      icdProcedures: "",
      isReferToSpecialist: "",
      specialist_ref: "",
      isReferToLab: "",
      lab_reference: "",
      isReferToOtherPlan: "",
      recommendationNote: "",
      labResult: "",
      pharmacyPhoto: "",
    });
  const { state } = useLocation();

  useEffect(() => {
    if ( state ==  undefined || state == {} ) {
      Swal.fire({
        icon: "error",
        title: "Oops..",
        text: "Something went wrong. ID Not initialized",
      }).then(() => {
          window.location = "/dokter-worklist";
      });
    } else {
      getPatientData();
      showDetail();
    }
  }, [state]);

  const getPatientData = () => {
    let data = state.patient;
    // console.log("data",data);
    setPatientData({
      patientName: data.patientName ? data.patientName : "",
      patientDob: data.patientDob ? moment(data.patientDob).format("ll") : "",
      patientAge: data.patientAge ? data.patientAge : "",
      patientGender: data.patientGender ? data.patientGender : "",
      patientPhone: data.patientPhone ? data.patientPhone : "",
      patientEmail: data.patientEmail ? data.patientEmail : "",
      avatar: data.avatar && data.avatar != "https://aido-app.s3-ap-southeast-1.amazonaws.com/assets/users/icons/anonymous.png" ? data.avatar : (data.patientGender == "Perempuan" ? "/img/icon/ic-female-profile.png" : "/img/icon/ic-male-profile.png"),
    });

  };

  const showDetail = () => {
    let value = state.mr;
    setMedicalRecord({
      orderId: value.order_id,
      scheduleDate: value.scheduleDate,
      specialist: value.specialist,
      diagnosis: value.diagnosis,
      partnerName: (value.doctor_firstname ? (value.doctor_lastname ? `${value.doctor_firstname} ${value.doctor_lastname} ${value.doctor_name ? " - "+value.doctor_name : ""}` : value.doctor_firstname) : value.doctor_name),
      medicalRecordId: value.medicalRecordId,
      icdDiseases: value.icdDiseases,
      icdProcedures: value.icdProcedures,
      isReferToSpecialist: value.isReferToSpecialist,
      specialist_ref: value.specialist_ref,
      isReferToLab: value.isReferToLab,
      lab_reference: value.lab_reference,
      isReferToOtherPlan: value.isReferToOtherPlan,
      recommendationNote: value.recommendationNote,
      labResult: value.labResult,
      isHealthScreening: value.isHealthScreening,
      carePlan: value.carePlan,
      pharmacyPhoto: value.pharmacyPhoto,
    });
  };

  // const isValidJSONString = (str) => {
  //   try {
  //     JSON.parse(str);
  //   } catch (e) {
  //     return false;
  //   }
  //   return true;
  // }

  const renderDetail = () => {
    return (
      <div className="mr-container">
        <div className="pl-2 pr-2">
          <Row style={{ justifyContent: "flex-end", paddingTop: "15px"}}>
                          
          {medicalRecord.labResult ? (
                  <a href={medicalRecord.labResult} target="_blank"
                    >
                    <button 
                      className="btn btn-info justify-content-flex-end text-mr mt-1 pl-4 pr-4 mr-2" 
                      style={{ float: "right", fontSize: "0.775rem" }}
                      // href={soap.labResult} target="_blank"
                      // onClick={() => {
                      //   window.open(`${soap.labResult}`, "_blank");
                      // }}
                      >Lab Result </button>
                  </a>
                ) : (<></>)}

          {medicalRecord.pharmacyPhoto && (
              // <a href={medicalRecord.pharmacyPhoto} target="_blank"
              //   >
                <button 
                  className="btn btn-outline-info justify-content-flex-end text-mr mt-1 pl-4 pr-4 mr-2" 
                  style={{ float: "right", fontSize: "0.775rem" }}
                  onClick={() => {
                    window.open(`${medicalRecord.pharmacyPhoto}`, "_blank");
                  }}
                  >Prescription </button>
              // </a>
            )}
            
          </Row>
          <Row style={{ padding: "10px 7px", paddingTop: 0}}>
                          
            <label>Consultation Date</label>
            <input 
              disabled
              type="text" 
              className="form-control mr-disabled" 
              value={medicalRecord.scheduleDate ? moment(medicalRecord.scheduleDate).format("ll") : "-"} 
            />
            
          </Row>
          <Row style={{ padding: "10px 7px", paddingTop: 0}}>
                          
            <label>Specialist</label>
            <input 
              disabled
              type="text" 
              className="form-control mr-disabled" 
              value={medicalRecord.specialist ? medicalRecord.specialist : "-"} 
            />
            
          </Row>
          <Row style={{ padding: "10px 7px", paddingTop: 0}}>
                          
            <label>Doctor</label>
            <input 
              disabled
              type="text" 
              className="form-control mr-disabled" 
              value={medicalRecord.partnerName ? medicalRecord.partnerName : "-"} 
            />
            
          </Row>
          <Row style={{ padding: "10px 7px", paddingTop: 0}}>
                          
            <label>Diagnosis</label>
            <textarea 
              disabled
              rows={2}
              type="text" 
              className="form-control mr-disabled" 
              value={medicalRecord.diagnosis ? medicalRecord.diagnosis : "-"} 
            />
            
          </Row>
          <Row style={{ padding: "10px 7px", paddingTop: 0}}>
                          
            <label>ICD Diseases</label>
            <CreatableSelect
                id="mr-select-diseases"
                isMulti
                isDisabled
                placeholder={medicalRecord.icdDiseases && medicalRecord.icdProcedures.length > 0 ? false : "-"}
                getOptionValue={(option) => option.id}
                getOptionLabel={
                    option => `${option.code} - ${option.name}`
                }
                value={medicalRecord.icdDiseases ? medicalRecord.icdDiseases : []}
                maxMenuHeight={200}

            />
            
          </Row>

          {/* { medicalRecord.icdProcedures && medicalRecord.icdProcedures.length > 0 ? ( */}
            <Row style={{ padding: "10px 7px", paddingTop: 0}}>       
              <label>ICD Procedures</label>
              <CreatableSelect
                  id="mr-select-procedures"
                  isMulti
                  isDisabled
                  placeholder={medicalRecord.icdProcedures && medicalRecord.icdProcedures.length > 0 ? false : "-"}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={
                      option => `${option.code} - ${option.name}`
                  }
                  value={medicalRecord.icdProcedures ? medicalRecord.icdProcedures : []}
                  maxMenuHeight={200}

              />   
            </Row>
          {/* ) : <></> } */}
          <Row style={{ padding: "1px 7px 0px"}}>
            <label style={{ marginBottom: "4px", marginTop: "4px"}}><b>Plan</b></label>
          </Row>
          <Row style={{ padding: "10px 7px", paddingTop: 0}}>
                          
            <label>Specialist</label>
            <input 
              disabled
              type="text" 
              className="form-control mr-disabled" 
              placeholder={medicalRecord.specialist_ref ? medicalRecord.specialist_ref : "-"}
              value={medicalRecord.specialist_ref ? medicalRecord.specialist_ref : ""} 
            />
            
          </Row>
          <Row style={{ padding: "10px 7px", paddingTop: 0}}>
            
            <label>Laboratorium</label>
            <CreatableSelect
              id="mr-select-lab"
              className="mr-disabled"
              isMulti
              isDisabled
              placeholder={Array.isArray(medicalRecord.lab_reference) ? (medicalRecord.lab_reference && medicalRecord.lab_reference.length > 0 ? false : "-") : (medicalRecord.lab_reference ? false : "-")}
              getOptionValue={(option) => option.id}
              getOptionLabel={
                  option => `${option.test_name} ${option.description ? ("("+option.description+")") : ""}`
              }
              value={ Array.isArray(medicalRecord.lab_reference) ? (medicalRecord.lab_reference && medicalRecord.lab_reference.length > 0 ? medicalRecord.lab_reference : []) : (medicalRecord.lab_reference ? [{id: null, test_name: medicalRecord.lab_reference}] : [])}
            />
            
          </Row>
          <Row style={{ padding: "10px 7px", paddingTop: 0}}>
            <label>Other</label>
            <input 
              type="text" 
              disabled
              className="form-control mr-disabled" 
              placeholder={medicalRecord.recommendationNote ? medicalRecord.recommendationNote : "-"}
              value={medicalRecord.recommendationNote ? medicalRecord.recommendationNote : ""} 
              />
          </Row>
          { medicalRecord.isHealthScreening ?  (
            <Row style={{ padding: "10px 7px", paddingTop: 0}}>
              <label>Care Plan</label>
              <textarea
                type="text" 
                rows="2" 
                className="form-control mr-disabled" 
                value={medicalRecord.carePlan ? medicalRecord.carePlan : "-"} 
                disabled
                />
            </Row>
            ) : (<></>
          )}
        </div>
      </div>
    );
  };

  const listMenu = document.getElementById("/dokter-worklist")
   
  if (listMenu) {
    listMenu.style.fontWeight = "bold";
    listMenu.style.backgroundColor = "blueviolet";
  } 

  return (
    <DashboardLayout>
      <Breadcrumb 
          path={{ pathname: "/dokter-worklist", state: { q: state.q }}} 
          menu={"Dokter Worklist"} 
          childPath={{ pathname: "/dokter-worklist/detail", state: { patientId: state.patientId, orderId: state.orderId, q: state.q } }} 
          childMenu={"Teleconsultation Patient Service"} 
          childMenu2={"Medical Record"} 
          childPath2={{ pathname: "/dokter-worklist/medical-records", state: { patientId: state.patientId, orderId: state.orderId, q: state.q } }}
          label={"Detail"}
          />
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Medical Record</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="mr-container mb-2" 
          >
          <div className="table-wrapper text-mr" style={{ boxShadow: "none", padding: "20px"}}>
            
            <div className="row w-100">
              <div className="col-md-2">
                <div className="row justify-content-center" style={{ marginTop: "15px" }}>
                  <Avatar
                    className="align-items-center"
                    sx={{ width: 100, height: 100}}
                    src={patientData.avatar}
                    />
                </div>
              </div>
              <div className="col-10">
                <div className="row mb-2">
                  <div className="col">
                    <Form.Group as={Row} 
                      className="mb-0" 
                      controlId="formPlaintextEmail">
                      <Form.Label column sm="5">
                        Patient Name
                      </Form.Label>
                      <Col sm="7" className="mr-field">
                        <Form.Control plaintext readOnly disabled value={patientData.patientName}/>
                      </Col>
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group as={Row} className="mb-0" controlId="formPlaintextEmail">
                      <Form.Label column sm="4">
                        Gender
                      </Form.Label>
                      <Col sm="8" className="mr-field">
                        <Form.Control plaintext readOnly disabled value={patientData.patientGender} />
                      </Col>
                    </Form.Group>
                  </div>  
                </div>

                <div className="row mb-2">
                  <div className="col">
                    <Form.Group as={Row} 
                      className="mb-0" 
                      controlId="formPlaintextEmail">
                      <Form.Label column sm="5">
                        Date of Birth
                      </Form.Label>
                      <Col sm="7" className="mr-field">
                        <Form.Control plaintext readOnly disabled value={patientData.patientDob}/>
                      </Col>
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group as={Row} className="mb-0" controlId="formPlaintextEmail">
                      <Form.Label column sm="4">
                        Age
                      </Form.Label>
                      <Col sm="8" className="mr-field">
                        <Form.Control plaintext readOnly disabled value={patientData.patientAge} />
                      </Col>
                    </Form.Group>
                  </div>  
                </div>

                <div className="row">
                  <div className="col">
                    <Form.Group as={Row} 
                      className="mb-0" 
                      controlId="formPlaintextEmail">
                      <Form.Label column sm="5">
                        Phone No
                      </Form.Label>
                      <Col sm="7" className="mr-field">
                        <Form.Control plaintext readOnly disabled value={patientData.patientPhone}/>
                      </Col>
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group as={Row} className="mb-0" controlId="formPlaintextEmail">
                      <Form.Label column sm="4">
                        Email
                      </Form.Label>
                      <Col sm="8" className="mr-field">
                        <Form.Control plaintext readOnly disabled value={patientData.patientEmail} />
                      </Col>
                    </Form.Group>
                  </div>  
                </div>           
              </div>
            </div>
          </div>
        </div>

        {
          state ? 
          renderDetail() 
          : <></>
        }
      </div>
    </DashboardLayout>
  );
};

export default MedicalHistoryDetail;
