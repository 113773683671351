import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import {
  findPatients,
  getDoctor,
  getRelation,
  getCountry,
  getProvince,
  getCity,
  getDistrict,
  getSubDistrict,
  getPostalCode,
  findAddress,
  submitPatient,
  getScheduleData,
  getScheduleNonSiloam,
  findInsurances,
  uploadInsuranceFile,
  uploadIdentityFile,
  updatePatient,
  findUserByPhoneEmail,
} from "../../../../actions";
import GoogleMap from "../../Components/GoogleMap";
import Input from "../../Components/Input";
import Modal from "../../Components/Modal";
import DashboardLayout from "../../Layout";
import queryString from "query-string";
import moment from "moment";
import Swal from "sweetalert2";

const RegisterPatientAdd = () => {
  const [patientData, setPatientData] = useState({
    customerId: "",
    patientId: "",
    patientId_Silo: "",
    patientSiloId: null,
    patientName: "",
    patientEmail: "",
    patientDob: "",
    patientGender: "",
    patientAddress: "",
    patientNational: "",
    patientNationalOption: "",
    patientPhone: "",
    patientCountry: "",
    patientProvince: "",
    patientCity: "",
    patientDistrict: "",
    patientSubDistrict: "",
    patientPostalCode: "",
  });
  const [newPatientData, setNewPatientData] = useState({
    patientName: "",
    patientEmail: "",
    patientDob: "",
    patientGender: "",
    patientAddress: "",
    patientNational: "",
    patientNationalOption: "",
    patientPhone: "",
    patientCountry: "",
    patientProvince: "",
    patientCity: "",
    patientDistrict: "",
    patientSubDistrict: "",
    patientPostalCode: "",
  });
  const [totalNewPatientData, setTotalNewPatientData] = useState([]);
  const [teleconsultation, setTeleconsultation] = useState({
    teleDoctorId: "",
    teleDoctorSiloId: "",
    teleDoctorName: "",
    teleAddressId: "",
    teleLatitude: null,
    teleLongitude: null,
    teleFullAddress: "",
    telePostalCode: "",
    teleDetailAddress: "",
    teleAddressName: "",
    teleScheduleDate: "",
    teleScheduleTime: "",
    teleServiceFee: "",
    teleComplaints: "",
    teleInsurance: "",
  });
  const [addressData, setAddressData] = useState([]);
  const [newAddressData, setNewAddressData] = useState({
    latitude: null,
    longitude: null,
    fullAddress: "",
    postalCode: "",
    detailAddress: "",
    addressName: "",
  });
  const [validation, setValidation] = useState({
    patientForm: false,
    teleDoctorForm: false,
    teleAddressForm: false,
  });
  const [addPatientModal, setAddPatientModal] = useState(false);
  const [addDoctorModal, setAddDoctorModal] = useState(false);
  const [addAddressModal, setAddAddressModal] = useState(false);
  const [filterDoctor, setFilterDoctor] = useState("");
  const [addNewButton, setAddNewButton] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [customerId, setCustomerId] = useState("");
  const name = useRef("");
  const birthday = useRef("");
  const auth = useSelector((state) => state.auth);
  const register = useSelector((state) => state.register);
  const [timeSchedule, setTimeSchedule] = useState({});
  const dispatch = useDispatch();

  const initState = () => {
    setNewPatientData({
      patientName: "",
      patientEmail: "",
      patientDob: "",
      patientGender: "",
      patientAddress: "",
      patientNational: "",
      patientNationalOption: "",
      patientPhone: "",
      patientCountry: "",
      patientProvince: "",
      patientCity: "",
      patientDistrict: "",
      patientSubDistrict: "",
      patientPostalCode: "",
    });
    setFilterDoctor("");
    setNewAddressData({
      latitude: null,
      longitude: null,
      fullAddress: "",
      postalCode: "",
      detailAddress: "",
      addressName: "",
    });
    setAddNewButton(0);
  };

  useEffect(() => {
    if (customerId) {
      let values = {
          customerId: customerId,
        },
        query = queryString.stringify(values);

      dispatch(findAddress(query));
    }
  }, [customerId]);

  useEffect(() => {
    let values = {
      partnerId: localStorage.getItem("parentId"),
    },
    query = queryString.stringify(values);

    dispatch(findInsurances(query));
  }, []);

  useEffect(() => {
    dispatch(getRelation());
    dispatch(getCountry());
  }, []);

  useEffect(() => {
    let values = {
        parentId: localStorage.getItem("parentId"),
      },
      query = queryString.stringify(values);

    dispatch(getDoctor(query));
  }, []);

  const handleGetSchedule = (dateSchedule) => {
    if(teleconsultation.teleDoctorId == 0){
      teleconsultation.teleScheduleDate = "";
      return;
    }
    dispatch(getScheduleData({partner: teleconsultation.teleDoctorId, date: dateSchedule}));
    setTeleconsultation({
      ...teleconsultation,
      teleScheduleDate: dateSchedule,
    });
  };

  const handleGetProvince = (id) => {
    console.log(id);
    dispatch(getProvince(id));
    setNewPatientData({
      ...newPatientData,
      patientCountry: id,
    });
  };

  const handleGetCity = (id) => {
    dispatch(getCity(id));
    setNewPatientData({
      ...newPatientData,
      patientProvince: id,
    });
  };

  const handleGetDistrict = (id) => {
    dispatch(getDistrict(id));
    setNewPatientData({
      ...newPatientData,
      patientCity: id,
    });
  };

  const handleGetSubDistrict = (id) => {
    dispatch(getSubDistrict(id));
    setNewPatientData({
      ...newPatientData,
      patientDistrict: id,
    });
  };

  const handleGetPostalCode = (id) => {
    dispatch(getPostalCode(id));
    setNewPatientData({
      ...newPatientData,
      patientSubDistrict: id,
    });
  };

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    showCloseButton: true,
    timer: 3000,
  });

  const handleCloseModal = () => {
    initState();
    setAddPatientModal(false);
    setAddDoctorModal(false);
    setAddAddressModal(false);
  };

  const handleNext = (nextStep) => {
    var timescheduleId = timeSchedule.id ? timeSchedule.id : "";

    if(nextStep == 1 && patientData.patientName == ""){
      return Swal.fire({
        icon: "warning",
        title: "Choose patient!",
        showConfirmButton: false,
      })    
    }

    if(nextStep == 2)
    {
      if( teleconsultation.teleDoctorId == "" || teleconsultation.teleDoctorName == "" ||
          teleconsultation.teleFullAddress == "" || teleconsultation.teleScheduleDate == "" ||
          teleconsultation.teleServiceFee == "" || timescheduleId == "")
      {
        return Swal.fire({
          icon: "warning",
          title: "Fill all mandatory fields!",
          showConfirmButton: false,
        })   
      }
    }
    setActiveStep((step) => step + 1);
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const actionSubmitPatient = () => {
    const insuranceCard = register.urlInsurance != undefined ? register.urlInsurance : [];
    const identityCard = register.urlIdentity != undefined ? register.urlIdentity : [];

    var insuranceCardUrl = insuranceCard.url != undefined ? insuranceCard.url : "";
    var insuranceCardName = insuranceCard.ImgNameSilo != undefined ? insuranceCard.ImgNameSilo : "";
    var identityCardUrl = identityCard.url != undefined ? identityCard.url : "";
    var identityCardName = identityCard.ImgNameSilo != undefined ? identityCard.ImgNameSilo : "";

    const form = new FormData();
    if (patientData.customerId && patientData.patientId) {
      form.append("customerId", patientData.customerId);
      form.append("patientId", patientData.patientId);
      form.append("patientId_Silo", patientData.patientId_Silo);
      form.append("patientSiloId", patientData.patientSiloId);
    }
    form.append("patientName", patientData.patientName);
    form.append("patientEmail", patientData.patientEmail);
    form.append("patientDob", patientData.patientDob);
    form.append("patientGender", patientData.patientGender);
    form.append("patientAddress", patientData.patientAddress);
    form.append("patientNational", patientData.patientNational);
    form.append("patientNationalOption", patientData.patientNationalOption);
    form.append("patientPhone", patientData.patientPhone);
    form.append("patientCountry", patientData.patientCountry);
    form.append("patientProvince", patientData.patientProvince);
    form.append("patientCity", patientData.patientCity);
    form.append("patientDistrict", patientData.patientDistrict);
    form.append("patientSubDistrict", patientData.patientSubDistrict);
    form.append("patientPostalCode", patientData.patientPostalCode);
    form.append("teleDoctorId", teleconsultation.teleDoctorId);
    form.append("teleAddressId", teleconsultation.teleAddressId);
    form.append("teleLatitude", teleconsultation.teleLatitude);
    form.append("teleLongitude", teleconsultation.teleLongitude);
    form.append("teleFullAddress", teleconsultation.teleFullAddress);
    form.append("telePostalCode", teleconsultation.telePostalCode);
    form.append("teleDetailAddress", teleconsultation.teleDetailAddress);
    form.append("teleAddressName", teleconsultation.teleAddressName);
    form.append("teleServiceFee", teleconsultation.teleServiceFee);
    form.append("teleInsurance", teleconsultation.teleInsurance);
    form.append("teleInsuranceCardUrl", insuranceCardUrl);
    form.append("teleInsuranceCardName", insuranceCardName);
    form.append("teleIdentityCardUrl", identityCardUrl);
    form.append("teleIdentityCardName", identityCardName);

    if (localStorage.getItem("thirpaty") == 1) {
      form.append("scheduleData", JSON.stringify(timeSchedule));
    } else if (localStorage.getItem("thirpaty") == 0) {
      form.append("scheduleId", timeSchedule.id);
    }

    if (teleconsultation.teleComplaints) {
      form.append("teleComplaints", teleconsultation.teleComplaints);
    }
    if (auth.user) {
      form.append("createdBy", auth.user.id);
    }

    dispatch(submitPatient(form))
      .then(() => {
        setTimeout(
          () =>
            Swal.fire({
              icon: "success",
              title: "Data Created",
              showConfirmButton: false,
            }),
          1000
        );
        setTimeout(() => (window.location = "/register-teleconsultation"), 2000);
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  };

  const renderPreviewData = () => {
    return (
      <>
        <div className="wrapper-form-title">
          <span className="form-title">Patient Data</span>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "250px" }}
                >
                  Name
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Gender
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Date of Birth
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Phone Number
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Identity Number
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Full Address
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {patientData.patientName ? patientData.patientName : "-"}
                </td>
                <td>
                  {patientData.patientGender == "1"
                    ? "Male"
                    : patientData.patientGender == "2"
                    ? "Female"
                    : "-"}
                </td>
                <td>
                  {patientData.patientDob
                    ? moment(patientData.patientDob).format("LL")
                    : "-"}
                </td>
                <td>
                  {patientData.patientPhone
                    ? "+62" + patientData.patientPhone
                    : "-"}
                </td>
                <td>
                  {patientData.patientNational &&
                  patientData.patientNationalOption == "1"
                    ? `KTP ${patientData.patientNational}`
                    : patientData.patientNational &&
                      patientData.patientNationalOption == "2"
                    ? `SIM ${patientData.patientNational}`
                    : patientData.patientNational &&
                      patientData.patientNationalOption == "3"
                    ? `Passport ${patientData.patientNational}`
                    : "-"}
                </td>
                <td>
                  {patientData.patientAddress
                    ? patientData.patientAddress
                    : "-"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="wrapper-form-title">
          <span className="form-title">Teleconsultation</span>
        </div>

        <div className="form-group">
          <div style={{ display: "flex" }}>
            <span className="col-4">Doctor Name</span>
            <span className="col">{`: ${
              teleconsultation.teleDoctorName
                ? teleconsultation.teleDoctorName
                : "-"
            }`}</span>
          </div>

          <div style={{ display: "flex" }}>
            <span className="col-4">Full Address</span>
            <span className="col">{`: ${
              teleconsultation.teleFullAddress
                ? teleconsultation.teleFullAddress
                : "-"
            }`}</span>
          </div>

          <div style={{ display: "flex" }}>
            <span className="col-4">Schedule Date</span>
            <span className="col">{`: ${
              teleconsultation.teleScheduleDate
                ? moment(teleconsultation.teleScheduleDate).format("LL")
                : "-"
            }`}</span>
          </div>

          <div style={{ display: "flex" }}>
            <span className="col-4">Schedule Time</span>
            <span className="col">{`: ${
              timeSchedule.startTime && timeSchedule.endTime
                ? timeSchedule.startTime + " - " +timeSchedule.endTime
                : "-"
            }`}</span>
          </div>

          <div style={{ display: "flex" }}>
            <span className="col-4">Total Payment</span>
            <span className="col">{`: ${
              teleconsultation.teleServiceFee
                ? formatter.format(teleconsultation.teleServiceFee)
                : "-"
            }`}</span>
          </div>

          <div style={{ display: "flex" }}>
            <span className="col-4">Complaints</span>
            <span className="col">{`: ${
              teleconsultation.teleComplaints
                ? teleconsultation.teleComplaints
                : "-"
            }`}</span>
          </div>
        </div>

        <button
          type="button"
          className="btn btn-primary"
          style={{ width: "100%" }}
          onClick={actionSubmitPatient}
        >
          Submit Data
        </button>
      </>
    )
  };

  const handleAddDoctor = () => {
    setAddDoctorModal(true);
  };

  const handleCheckDoctorData = (data) => {
    setValidation({
      ...validation,
      teleDoctorForm: data.id,
    });
    setTeleconsultation({
      ...teleconsultation,
      teleDoctorName: data.doctorName,
      teleDoctorId: data.id,
      teleDoctorSiloId: data.silo_id,
      teleServiceFee: data.serviceFee,
    });

    if(localStorage.getItem("thirpaty") != 1)
    {
      dispatch(getScheduleNonSiloam(data.id));
    }

    Toast.fire({
      icon: "success",
      title: "Data Selected",
    });
    setAddDoctorModal(false);
    initState();


  };

  const renderAddDoctor = () => {
    return (
      <Modal
        modalTitle="Select Doctor"
        show={addDoctorModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        buttons={[]}
        close
      >
        <Row>
          <Col md>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search ID or Name"
                value={filterDoctor}
                onChange={(e) => setFilterDoctor(e.target.value)}
              />
            </div>
          </Col>
          <Col md></Col>
        </Row>

        <Row>
          <Col md>
            <div className="table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="table-header">
                  <tr>
                    <th
                      rowSpan={2}
                      className="table-header-middle"
                      style={{ width: "45px" }}
                    >
                      +
                    </th>

                    <th
                      rowSpan={2}
                      className="table-header-middle"
                      style={{ width: "120px" }}
                    >
                      Doctor Name
                    </th>

                    <th rowSpan={2} className="table-header-middle">
                      Specialist
                    </th>

                    <th
                      rowSpan={2}
                      className="table-header-middle"
                      style={{ width: "120px" }}
                    >
                      Service Fee
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {register.doctorListData.length > 0 ? (
                    register.doctorListData
                      .filter((data) => {
                        if (filterDoctor === "") return data;
                        else if (
                          data.id.toString().includes(filterDoctor) ||
                          data.doctorName
                            .toLowerCase()
                            .includes(filterDoctor.toLowerCase())
                        )
                          return data;
                      })
                      .map((data) => (
                        <tr key={data.id}>
                          <td>
                            <input
                              type="radio"
                              name="doctorListData"
                              checked={validation.teleDoctorForm === data.id}
                              value={data.id}
                              onChange={() => handleCheckDoctorData(data)}
                            />
                          </td>
                          <td>
                            {data.doctorName ? data.doctorName : "-"}
                          </td>
                          <td>
                            {data.specialistName ? data.specialistName : "-"}
                          </td>
                          <td>{data.serviceFee ? data.serviceFee : "-"}</td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan="4">Empty data</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  };

  const handleAddAddress = () => {
    setAddAddressModal(true);
  };

  const handleCheckAddressData = (data) => {
    setValidation({
      ...validation,
      teleAddressForm: data.fullAddress,
    });
    setTeleconsultation({
      ...teleconsultation,
      teleAddressId: data.address_id != undefined ? data.address_id : "",
      teleLatitude: data.latitude,
      teleLongitude: data.longitude,
      teleFullAddress: data.fullAddress,
      telePostalCode: data.postalCode,
      teleDetailAddress: data.detailAddress != undefined ? data.detailAddress : data.note != undefined ?  data.note : "",
      teleAddressName: data.addressName != undefined ? data.addressName : data.name != undefined ?  data.name : "",
    });
    Toast.fire({
      icon: "success",
      title: "Data Selected",
    });
    setAddAddressModal(false);
    initState();
  };

  const handleAddNewAddress = () => {
    if (newAddressData.fullAddress && newAddressData.addressName) {
      setAddressData([
        ...addressData,
        {
          latitude: newAddressData.latitude,
          longitude: newAddressData.longitude,
          fullAddress: newAddressData.fullAddress,
          postalCode: newAddressData.postalCode,
          detailAddress: newAddressData.detailAddress,
          addressName: newAddressData.addressName,
        },
      ]);
      initState();
    }
  };

  const handlePatientAddress = (addressData) => {
    setNewPatientData({
      ...newPatientData,
      patientAddress: addressData.fullAddress,
    });
  };

  const handleData = (addressData) => {
    setNewAddressData({
      ...newAddressData,
      latitude: addressData.latitude,
      longitude: addressData.longitude,
      fullAddress: addressData.fullAddress,
      postalCode: addressData.postalCode,
    });
  };

  const renderAddAddress = () => {
    return (
      <Modal
        modalTitle="Select Address"
        show={addAddressModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        buttons={[]}
        close
      >
        {addNewButton === 0 && (
          <>
            <Row>
              <Col md>
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setAddNewButton(1)}
                  >
                    Add New Address
                  </button>
                </div>
              </Col>
              <Col md></Col>
            </Row>

            <Row>
              <Col md>
                <div className="table-responsive">
                  <table className="table table-striped table-bordered">
                    <thead className="table-header">
                      <tr>
                        <th
                          rowSpan={2}
                          className="table-header-middle"
                          style={{ width: "45px" }}
                        >
                          +
                        </th>

                        <th
                          rowSpan={2}
                          className="table-header-middle"
                          style={{ width: "300px" }}
                        >
                          Full Address
                        </th>

                        <th
                          rowSpan={2}
                          className="table-header-middle"
                          style={{ width: "300px" }}
                        >
                          Detail Address
                        </th>

                        <th rowSpan={2} className="table-header-middle">
                          Address Name
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {addressData.length > 0 &&
                        addressData.map((data, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                type="radio"
                                name="addressData"
                                checked={
                                  validation.teleAddressForm ===
                                  data.fullAddress
                                }
                                value={data.fullAddress}
                                onChange={() => handleCheckAddressData(data)}
                              />
                            </td>
                            <td>{data.fullAddress ? data.fullAddress : "-"}</td>
                            <td>
                              {data.detailAddress ? data.detailAddress : "-"}
                            </td>
                            <td>{data.addressName ? data.addressName : "-"}</td>
                          </tr>
                        ))}
                      {register.addressData.length > 0 &&
                        register.addressData.map((data) => (
                          <tr key={data.id}>
                            <td>
                              <input
                                type="radio"
                                name="addressData"
                                checked={
                                  validation.teleAddressForm ===
                                  data.fullAddress
                                }
                                value={data.fullAddress}
                                onChange={() => handleCheckAddressData(data)}
                              />
                            </td>
                            <td>{data.fullAddress ? data.fullAddress : "-"}</td>
                            <td>{data.note ? data.note : "-"}</td>
                            <td>{data.name ? data.name : "-"}</td>
                          </tr>
                        ))}
                      {addressData.length === 0 &&
                        register.addressData.length === 0 && (
                          <tr>
                            <td colSpan="4">Empty data</td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </>
        )}

        {addNewButton === 1 && (
          <>
            <Row>
              <Col md style={{ height: "350px" }}>
                <GoogleMap
                  addressData={(addressData) => handleData(addressData)}
                />
              </Col>
            </Row>

            <Row>
              <Col md>
                <div className="form-group">
                  <label>Full Address</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Selected Full Address"
                    value={newAddressData.fullAddress}
                    disabled
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md>
                <div className="form-group">
                  <label>
                    Detail Address{" "}
                    <span style={{ color: "#A1A4A8" }}>(Optional)</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Input Detail Address"
                    value={newAddressData.detailAddress}
                    onChange={(e) =>
                      setNewAddressData({
                        ...newAddressData,
                        detailAddress: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>

              <Col md>
                <div className="form-group">
                  <label>Address Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Input Address Name"
                    value={newAddressData.addressName}
                    onChange={(e) =>
                      setNewAddressData({
                        ...newAddressData,
                        addressName: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md>
                <div className="form-group text-right">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      width: "100px",
                      marginRight: "5px",
                    }}
                    onClick={handleAddNewAddress}
                  >
                    Apply
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary"
                    style={{ width: "100px" }}
                    onClick={() => {
                      setAddNewButton(0);
                      initState();
                    }}
                  >
                    Back
                  </button>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Modal>
    );
  };

  const handleInsurance = (insuranceId) => {
    setTeleconsultation({
      ...teleconsultation,
      teleInsurance: insuranceId,
    })
  }

  const uploadDocumentInsurance = () => {
    if(teleconsultation.teleDoctorId == "" || teleconsultation.teleDoctorId == null){
      document.getElementById("insuranceCardFile").value = "";
      return Swal.fire({ icon: "warning", title: "Select Doctor!"})
    }

    var insuranceCardFile = document.getElementById("insuranceCardFile").files;
    dispatch(uploadInsuranceFile(teleconsultation.teleDoctorId, insuranceCardFile[0]))
      .then(()=>{
        Toast.fire({
          icon: "success",
          title: "Insurance Card Uploaded!",
        });
      });
  };

  const uploadDocumentIdentity = () => {
    if(teleconsultation.teleDoctorId == "" || teleconsultation.teleDoctorId == null){
      document.getElementById("identityCardFile").value = "";
      return Swal.fire({ icon: "warning", title: "Select Doctor!"})
    }
    var IdentityCardFile = document.getElementById("identityCardFile").files;
    dispatch(uploadIdentityFile(teleconsultation.teleDoctorId, IdentityCardFile[0]))
      .then(()=>{
        Toast.fire({
          icon: "success",
          title: "Identity Card Uploaded!",
        });
      });          
  };

  const renderTeleconsultation = () => {
    return (
      <>
        <div className="wrapper-form-title">
          <span className="form-title">Teleconsultation</span>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <div className="mb10">Doctor <span style={{color:"red"}}>*</span></div>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Select Doctor"
                value={teleconsultation.teleDoctorName}
                onChange={(e) =>
                  setTeleconsultation({
                    ...teleconsultation,
                    teleDoctorName: e.target.value,
                  })
                }
                onClick={handleAddDoctor}
              />
              <div className="input-group-append">
                <div className="input-group-text">Browse</div>
              </div>
            </div>
          </div>

          <div className="form-group col-md-6">
            <div className="mb10">Address <span style={{color:"red"}}>*</span></div>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Select Address"
                value={teleconsultation.teleFullAddress}
                onChange={(e) =>
                  setTeleconsultation({
                    ...teleconsultation,
                    teleFullAddress: e.target.value,
                  })
                }
                onClick={handleAddAddress}
              />
              <div className="input-group-append">
                <div className="input-group-text">Browse</div>
              </div>
            </div>
          </div>
        </div>

        {localStorage.getItem("thirpaty") == 1 ? 
          (
            <div className="row">
              <div className="form-group col-md-6">
                <div className="mb10">Schedule Date <span style={{color:"red"}}>*</span></div>
                <div className="input-group">
                  <input
                    type="date"
                    className="form-control"
                    min={moment().format("YYYY-MM-DD")}
                    value={teleconsultation.teleScheduleDate}
                    onChange={(e) => handleGetSchedule(e.target.value)}
                  />
                </div>
              </div>
    
              <div className="form-group col-md-6">
                <div className="mb10">Schedule Time <span style={{color:"red"}}>*</span></div>
                <Select
                  isClearable 
                  isSearchable 
                  name="scheduleData"
                  options={register.schedule_data.timeslot}
                  onChange={setTimeSchedule}
                  uniqueKey="id"
                  placeholder={"Select Schedule"}
                  getOptionValue={(option) => JSON.stringify(option)}
                  getOptionLabel={option => `[${option.startTime} - ${option.endTime}]`}
                  isLoading={register.loading}
                />
              </div>
            </div>
          ) 
          : 
          (
            <div className="row">
              <div className="form-group col-md-12">
                <div className="mb10">Schedule <span style={{color:"red"}}>*</span></div>
                <Select 
                  isClearable
                  isSearchable 
                  name="scheduleId"
                  id="editScheduleNonSiloam"
                  options={register.schedule_data.timeslot}
                  onChange={setTimeSchedule}
                  uniqueKey="scheduleId"
                  placeholder={"Select Schedule"}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={
                    option => `[ ${option.id} | ${moment(option.scheduleDate).format("DD MMM Y")} | ${option.startTime} - ${option.endTime} ]`
                  }
                />
              </div>
            </div>
          )
        
        }

        <div className="row">
          <div className="form-group col-md-6">
            <div className="mb10">Total Payment <span style={{color:"red"}}>*</span></div>
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">IDR</div>
              </div>
              <input
                type="number"
                className="form-control"
                placeholder="Input Total Payment"
                min="0"
                style={{ height: "38px" }}
                value={teleconsultation.teleServiceFee}
                onChange={(e) =>
                  setTeleconsultation({
                    ...teleconsultation,
                    teleServiceFee: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="form-group col-md-6">
            <div className="mb10">
              Complaints <span style={{ color: "#A1A4A8" }}>(Optional)</span>
            </div>
            <textarea
              rows="3"
              className="form-control"
              placeholder="Input Complaints"
              value={teleconsultation.teleComplaints}
              onChange={(e) =>
                setTeleconsultation({
                  ...teleconsultation,
                  teleComplaints: e.target.value,
                })
              }
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-12">
            <div className="mb10">Payer</div>
              <select 
                className="form-control"
                value={teleconsultation.teleInsurance}
                onChange={(e) => handleInsurance(e.target.value)}
              >
                <option value="">Select Payer</option>
                  { register.insuranceData.length > 0 ?
                    (
                      register.insuranceData.map((data, index) => (
                        <option key={data.id} value={data.id}>{data.insuranceName}</option>
                      ))
                    )
                    :
                    (
                      <option></option>
                    )
                }
             </select>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <div className="mb10">Payer Card/Letter</div>              
              <input 
                id="insuranceCardFile"
                type="file" 
                className="form-control-file"
                accept="image/png, image/gif, image/jpeg" 
                onChange={() => uploadDocumentInsurance()} 
              />
          </div>

          <div className="form-group col-md-6">
            <div className="mb10">Identity Card (KTP)</div>              
              <input 
                id="identityCardFile"
                type="file" 
                className="form-control-file"
                accept="image/png, image/gif, image/jpeg" 
                onChange={() => uploadDocumentIdentity()} 
              />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-12">
            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100%" }}
              onClick={() => handleNext(2)}
            >
              Preview Data
            </button>
          </div>
        </div>
      </>
    );
  };

  const handleAddPatient = () => {
    setAddPatientModal(true);
  };

  const actionAddPatient = () => {
    if(patientData.patientId == ""){
      dispatch(findUserByPhoneEmail("phone="+newPatientData.patientPhone+"&email="+newPatientData.patientEmail))
        .then(() => {
          Swal.fire("Success!", "Data Created", "success");
        })
        .catch((err) => {
          if (err.response) {
            return Swal.fire({
              icon: "error",
              text: err.response.data.message,
            }).then(() => {window.location = "/register-teleconsultation/add"})
          } else {
            return Swal.fire({
              icon: "error",
              text: err.message,
            }).then(() => {window.location = "/register-teleconsultation/add"})
          }
        });

      let obj = {
        patientName: newPatientData.patientName,
        patientEmail: newPatientData.patientEmail,
        patientDob: newPatientData.patientDob,
        patientGender: newPatientData.patientGender,
        patientAddress: newPatientData.patientAddress,
        patientNational: newPatientData.patientNational,
        patientNationalOption: newPatientData.patientNationalOption,
        patientPhone: newPatientData.patientPhone,
        patientCountry: newPatientData.patientCountry,
        patientProvince: newPatientData.patientProvince,
        patientCity: newPatientData.patientCity,
        patientDistrict: newPatientData.patientDistrict,
        patientSubDistrict: newPatientData.patientSubDistrict,
        patientPostalCode: newPatientData.patientPostalCode,
      };

      setTotalNewPatientData([...totalNewPatientData, obj]);
      initState();
      setAddPatientModal(false);
    }
    else{
      const form = new FormData();
      form.append("patientId", patientData.patientId);
      form.append("patientName", newPatientData.patientName);
      form.append("patientEmail", newPatientData.patientEmail);
      form.append("patientDob", newPatientData.patientDob);
      form.append("patientGender", newPatientData.patientGender);
      form.append("patientAddress", newPatientData.patientAddress);
      form.append("patientNational", newPatientData.patientNational);
      form.append("patientNationalOption", newPatientData.patientNationalOption);
      form.append("patientPhone", newPatientData.patientPhone);
      form.append("patientCountry", newPatientData.patientCountry);
      form.append("patientProvince", newPatientData.patientProvince);
      form.append("patientCity", newPatientData.patientCity);
      form.append("patientDistrict", newPatientData.patientDistrict);
      form.append("patientSubDistrict", newPatientData.patientSubDistrict);
      form.append("patientPostalCode", newPatientData.patientPostalCode);

      dispatch(updatePatient(form))
        .then(() => {
          handleSearch();
          Swal.fire({
            icon: "success",
            title: "Data Patient Updated",
            showConfirmButton: false,
          });
        })
        .catch((err) => {
          if (err.response) {
            Swal.fire({
              icon: "error",
              text: err.response.data.message,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: err.message,
            });
          }
        });

      setAddPatientModal(false);

    }
  };

  const renderAddPatient = () => {
    return (
      <Modal
        modalTitle="Add New Patient Data"
        show={addPatientModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        onSubmit={actionAddPatient}
      >
        <Row>
          <Col md>
            <Input
              type="text"
              label="Patient Name"
              placeholder="Input Patient Name"
              value={newPatientData.patientName}
              onChange={(e) =>
                setNewPatientData({
                  ...newPatientData,
                  patientName: e.target.value,
                })
              }
            />
          </Col>
          <Col md>
            <Input
              type="text"
              label="Patient Email"
              placeholder="Input Patient Email"
              value={newPatientData.patientEmail}
              onChange={(e) =>
                setNewPatientData({
                  ...newPatientData,
                  patientEmail: e.target.value,
                })
              }
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="date"
              label="Date of Birth"
              // value={newPatientData.patientDob}
              value={newPatientData.patientDob}
              onChange={(e) =>
                setNewPatientData({
                  ...newPatientData,
                  patientDob: e.target.value,
                })
              }
            />
          </Col>
          <Col md>
            <div className="form-group">
              <label>Gender</label>
              <select
                className="form-control"
                value={newPatientData.patientGender}
                onChange={(e) =>
                  setNewPatientData({
                    ...newPatientData,
                    patientGender: e.target.value,
                  })
                }
              >
                <option value="">Select Gender</option>
                <option value="1">Male</option>
                <option value="2">Female</option>
              </select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Phone Number</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">+62</div>
                </div>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Input Phone Number"
                  style={{ height: "38px" }}
                  value={newPatientData.patientPhone}
                  onChange={(e) =>
                    setNewPatientData({
                      ...newPatientData,
                      patientPhone: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </Col>
          <Col md>
            <div className="form-group">
              <label>Identity Number</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <select
                    className="form-control"
                    value={newPatientData.patientNationalOption}
                    onChange={(e) =>
                      setNewPatientData({
                        ...newPatientData,
                        patientNationalOption: e.target.value,
                      })
                    }
                  >
                    <option value="">Select ID</option>
                    <option value="1">KTP</option>
                    <option value="2">SIM</option>
                    <option value="3">Passport</option>
                  </select>
                </div>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Input Identity Number"
                  style={{ height: "38px" }}
                  value={newPatientData.patientNational}
                  onChange={(e) =>
                    setNewPatientData({
                      ...newPatientData,
                      patientNational: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md style={{ height: "350px", marginTop: "20px" }}>
            <GoogleMap
              addressData={(addressData) => handlePatientAddress(addressData)}
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Full Address</label>
              <textarea
                rows="3"
                className="form-control"
                placeholder="Input Full Address"
                value={newPatientData.patientAddress}
                onChange={(e) =>
                  setNewPatientData({
                    ...newPatientData,
                    patientAddress: e.target.value,
                  })
                }
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Country</label>
              <div className="input-group">
                <select
                  className="form-control"
                  value={newPatientData.patientCountry}
                  onChange={(e) => handleGetProvince(e.target.value)}
                >
                  <option value="">Select Country</option>
                  {register.country.length > 0 ? (
                    register.country.map((data, index) => (
                      <option value={data.id} key={index}>
                        {data.name}
                      </option>
                    ))
                  ) : (
                    <option value=""></option>
                  )}
                </select>
              </div>
            </div>
          </Col>
          <Col md>
            <div className="form-group">
              <label>Province</label>
              <div className="input-group">
                <select
                  className="form-control"
                  value={newPatientData.patientProvince}
                  onChange={(e) => handleGetCity(e.target.value)}
                >
                  <option value="">Select Province</option>
                  {register.province.length > 0 ? (
                    register.province.map((data, index) => (
                      <option value={data.id} key={index}>
                        {data.name}
                      </option>
                    ))
                  ) : (
                    <option value=""></option>
                  )}
                </select>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>City</label>
              <div className="input-group">
                <select
                  className="form-control"
                  value={newPatientData.patientCity}
                  onChange={(e) => handleGetDistrict(e.target.value)}
                >
                  <option value="">Select City</option>
                  {register.city.length > 0 ? (
                    register.city.map((data, index) => (
                      <option value={data.id} key={index}>
                        {data.name}
                      </option>
                    ))
                  ) : (
                    <option value=""></option>
                  )}
                </select>
              </div>
            </div>
          </Col>
          <Col md>
            <div className="form-group">
              <label>District</label>
              <div className="input-group">
                <select
                  className="form-control"
                  value={newPatientData.patientDistrict}
                  onChange={(e) => handleGetSubDistrict(e.target.value)}
                >
                  <option value="">Select District</option>
                  {register.district.length > 0 ? (
                    register.district.map((data, index) => (
                      <option value={data.id} key={index}>
                        {data.name}
                      </option>
                    ))
                  ) : (
                    <option value=""></option>
                  )}
                </select>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Subdistrict</label>
              <div className="input-group">
                <select
                  className="form-control"
                  value={newPatientData.patientSubDistrict}
                  onChange={(e) => handleGetPostalCode(e.target.value)}
                >
                  <option value="">Select Subdistrict</option>
                  {register.subdistrict.length > 0 ? (
                    register.subdistrict.map((data, index) => (
                      <option value={data.id} key={index}>
                        {data.name}
                      </option>
                    ))
                  ) : (
                    <option value=""></option>
                  )}
                </select>
              </div>
            </div>
          </Col>
          <Col md>
            <div className="form-group">
              <label>Postal Code</label>
              <div className="input-group">
                <select
                  className="form-control"
                  value={newPatientData.patientPostalCode}
                  onChange={(e) =>
                    setNewPatientData({
                      ...newPatientData,
                      patientPostalCode: e.target.value,
                    })
                  }
                >
                  <option value="">Select Postal Code</option>
                  {register.postalcode.length > 0 ? (
                    register.postalcode.map((data, index) => (
                      <option value={data.code} key={index}>
                        {data.code}
                      </option>
                    ))
                  ) : (
                    <option value=""></option>
                  )}
                </select>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  };

  const handleUpdatePatient = (data) => {
    setAddPatientModal(true);
    handleGetProvince(data.countryId);
    handleGetCity(data.stateId);
    handleGetDistrict(data.cityId);
    handleGetSubDistrict(data.districtId);
    handleGetPostalCode(data.subdistrictId);

    setPatientData({
      ...patientData,
      patientId: data.patientId,
    });
    setNewPatientData({
      ...newPatientData,
      patientName: data.patientName,
      patientEmail: data.email,
      patientDob: moment(data.birthday).format('yyyy-MM-DD'),
      patientGender: data.gender,
      patientAddress: data.address,
      patientNational: data.national_id_no,
      patientNationalOption: data.national_id_type,
      patientPhone: data.phone,
      patientCountry: data.countryId,
      patientProvince: data.stateId,
      patientCity: data.cityId,
      patientDistrict: data.districtId,
      patientSubDistrict: data.subdistrictId,
      patientPostalCode: data.postalCode,
    });
  };

  const handleCheckPatientData = (data) => {
    if (data.patientId && data.customerId) {
      setValidation({
        ...validation,
        patientForm: data.patientId,
      });
      setPatientData({
        ...patientData,
        customerId: data.customerId,
        patientId: data.patientId,
        patientId_Silo: data.id,
        patientSiloId: data.patient_id_silo,
        patientName: data.patientName,
        patientEmail: data.email,
        patientDob: data.birthday,
        patientGender: data.gender,
        patientAddress: data.address,
        patientNational: data.national_id_no,
        patientNationalOption: data.national_id_type,
        patientPhone: data.phone,
        patientCountry: data.country,
        patientProvince: data.state,
        patientCity: data.city,
        patientDistrict: data.district,
        patientSubDistrict: data.subdistrict,
        patientPostalCode: data.postalCode,
      });

      if (data.customerId) {
        setCustomerId(data.customerId);
      }

      Toast.fire({
        icon: "success",
        title: "Data Selected",
      });
    } else {
      setValidation({
        ...validation,
        patientForm: data.patientName,
      });
      setPatientData({
        ...patientData,
        patientName: data.patientName,
        patientEmail: data.patientEmail,
        patientDob: data.patientDob,
        patientGender: data.patientGender,
        patientAddress: data.patientAddress,
        patientNational: data.patientNational,
        patientNationalOption: data.patientNationalOption,
        patientPhone: data.patientPhone,
        patientCountry: data.patientCountry,
        patientProvince: data.patientProvince,
        patientCity: data.patientCity,
        patientDistrict: data.patientDistrict,
        patientSubDistrict: data.patientSubDistrict,
        patientPostalCode: data.patientPostalCode,
      });

      Toast.fire({
        icon: "success",
        title: "Data Selected",
      });
    }
  };

  const renderDetailPatientData = () => {
    return register.loading ? (
      <tr>
        <td colSpan="8">
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        {register.patientDetail.length > 0 &&
          register.patientDetail.map((data) => (
            <tr key={data.patientId}>
              <td>
                <input
                  type="radio"
                  name="patientData"
                  checked={validation.patientForm === data.patientId}
                  value={data.patientId}
                  onChange={() => handleCheckPatientData(data)}
                />
              </td>
              <td>{data.patientName ? data.patientName : "-"}</td>
              <td>
                {data.gender == "1"
                  ? "Male"
                  : data.gender == "2"
                  ? "Female"
                  : "-"}
              </td>
              <td>
                {data.birthday ? moment(data.birthday).format("LL") : "-"}
              </td>
              <td>{data.phone ? "+62" + data.phone : "-"}</td>
              <td>
                {data.national_id_no && data.national_id_type == "1"
                  ? `KTP ${data.national_id_no}`
                  : data.national_id_no && data.national_id_type == "2"
                  ? `SIM ${data.national_id_no}`
                  : data.national_id_no && data.national_id_type == "3"
                  ? `Passport ${data.national_id_no}`
                  : "-"}
              </td>
              <td>{data.address ? data.address : "-"}</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-block btn-outline-primary"
                    style={{
                      marginBottom: "10px",
                    }}
                    onClick={() => handleUpdatePatient(data)}
                  >
                    Update
                  </button>
                </div>
              </td>
            </tr>
          ))}
        {totalNewPatientData.length > 0 &&
          totalNewPatientData.map((data, index) => (
            <tr key={index}>
              <td>
                <input
                  type="radio"
                  name="patientData"
                  checked={validation.patientForm === data.patientName}
                  value={data.patientName}
                  onChange={() => handleCheckPatientData(data)}
                />
              </td>
              <td>{data.patientName ? data.patientName : "-"}</td>
              <td>
                {data.patientGender == "1"
                  ? "Male"
                  : data.patientGender == "2"
                  ? "Female"
                  : "-"}
              </td>
              <td>
                {data.patientDob ? moment(data.patientDob).format("LL") : "-"}
              </td>
              <td>{data.patientPhone ? "+62" + data.patientPhone : "-"}</td>
              <td>
                {data.patientNational && data.patientNationalOption == "1"
                  ? `KTP ${data.patientNational}`
                  : data.patientNational && data.patientNationalOption == "2"
                  ? `SIM ${data.patientNational}`
                  : data.patientNational && data.patientNationalOption == "3"
                  ? `Passport ${data.patientNational}`
                  : "-"}
              </td>
              <td>{data.patientAddress ? data.patientAddress : "-"}</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-block btn-outline-primary"
                    style={{
                      marginBottom: "10px",
                    }}
                    onClick={() => handleUpdatePatient(data)}
                  >
                    Update
                  </button>
                </div>
              </td>
            </tr>
          ))}
        {register.patientDetail.length === 0 &&
          totalNewPatientData.length === 0 && (
            <tr>
              <td colSpan="8">Empty data</td>
            </tr>
          )}
      </>
    );
  };

  const handleReset = () => {
    const form = new FormData();
    form.append("name", (name.current.value = ""));
    form.append("birthday", (birthday.current.value = ""));

    dispatch(findPatients(form)).then(() => setTotalNewPatientData([]));
  };

  const handleSearch = () => {
    const form = new FormData();
    form.append("name", name.current.value);
    form.append("birthday", birthday.current.value);

    dispatch(findPatients(form));
  };

  const renderPatientData = () => {
    return (
      <>
        <div className="wrapper-form-title">
          <span className="form-title">Patient Data</span>
        </div>

        <div className="row">
          <div className="form-group col-md-3">
            <div className="mb10">Name</div>
            <input
              type="text"
              className="form-control"
              placeholder="Search Patient Name"
              ref={name}
            />
          </div>

          <div className="form-group col-md-3">
            <div className="mb10">Date of Birth</div>
            <input type="date" className="form-control" ref={birthday} />
          </div>

          <div className="form-group col-md-3 mt-auto">
            <button
              type="button"
              className="btn btn-secondary"
              style={{
                width: "100px",
                marginRight: "5px",
              }}
              onClick={() => handleReset()}
            >
              Reset
            </button>

            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100px" }}
              onClick={() => handleSearch()}
            >
              Apply
            </button>
          </div>

          <div
            className="form-group col-md-3"
            style={{ borderLeft: "1px solid grey" }}
          >
            <div className="mb10">User is not registered yet?</div>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={handleAddPatient}
            >
              Add New Patient Data
            </button>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "45px" }}
                >
                  +
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "200px" }}
                >
                  Name
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Gender
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Date of Birth
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Phone Number
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Identity Number
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Full Address
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>{renderDetailPatientData()}</tbody>
          </table>
        </div>

        <div className="row mt-4">
          <div className="col-md-12">
            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100%" }}
              onClick={() => handleNext(1)}
            >
              Next Step
            </button>
          </div>
        </div>
      </>
    );
  };

  const style = createMuiTheme({
    overrides: {
      MuiStepIcon: {
        root: {
          "&$active": {
            color: "#38a8ff",
          },
          "&$completed": {
            color: "#38a8ff",
          },
        },
      },
      MuiStepLabel: {
        label: {
          "&$active": {
            color: "#38a8ff",
          },
          "&$completed": {
            color: "#38a8ff",
          },
        },
      },
    },
  });

  const getLabel = () => {
    return ["Patient Data", "Teleconsultation", "Submit"];
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Register Teleconsultation</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        <div className="row">
          <div className="col-md-6">
            <span id="finance-title" className="mb10">
              Registration Steps
            </span>
          </div>
        </div>

        <ThemeProvider theme={style}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {getLabel().map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </ThemeProvider>

        {activeStep === 0 && renderPatientData()}
        {activeStep === 1 && renderTeleconsultation()}
        {activeStep === 2 && renderPreviewData()}
      </div>
      {renderAddPatient()}
      {renderAddDoctor()}
      {renderAddAddress()}
    </DashboardLayout>
  );
};

export default RegisterPatientAdd;
