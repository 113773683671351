import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../Layout";
import { createOtp, verifyOtp, verifyOtpEmail } from "../../../actions";
import { Form, Button, ButtonToolbar } from "react-bootstrap";
import OtpInput from "react-otp-input";
import Swal from "sweetalert2";

const VerifyOtp = () => {
  const { state } = useLocation();
  const history = useHistory();
  const [otp, setOtp] = useState("");
  const testCovid = useSelector((state) => state.testCovid);
  const { error } = testCovid;
  const dispatch = useDispatch();
  const seconds = testCovid.userId ? 30 : 0;
  const [counter, setCounter] = useState(seconds);
  const [errors, setErrors] = useState({
    phone: false,
    email: false,
    otp: false,
  });

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    return () => clearInterval(timer);
  }, [counter, testCovid.userId]);

  if (!testCovid.userId) {
    history.push({
      pathname: "/test-covid/login",
      state: state,
    });
  }

  if (testCovid.authenticate) {
    history.push({
      pathname: "/test-covid/form",
      state: state,
    });
  }

  const submitLogin = () => {
    console.log("otp", otp);
    if (otp !== "" && otp.length == 6) {
      console.log("auth phone", testCovid.phone);
      if (testCovid.phone) {
        const customer = {
          phone: testCovid.phone,
          otp: otp,
        };
        console.log(customer);
        dispatch(verifyOtp(customer)).catch((err) => {
          if (err.response) {
            Swal.fire({
              icon: "error",
              text: err.response.data.message,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: err.message,
            });
          }
        });
      } else if (testCovid.email) {
        const customer = {
          email: testCovid.email,
          otp: otp,
        };
        console.log(customer);
        dispatch(verifyOtpEmail(customer)).catch((err) => {
          if (err.response) {
            Swal.fire({
              icon: "error",
              text: err.response.data.message,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: err.message,
            });
          }
        });
      }
    }
  };

  const sendOtp = () => {
    if (testCovid.email) {
      var data = { email: testCovid.email };
    } else {
      var data = { phone: testCovid.phone };
    }
    dispatch(createOtp(data))
      .then(() => {
        setCounter(30);
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  };

  return (
    <Layout link="/test-covid/login" state={state}>
      <div className="row justify-content-center">
        <div className="login-logo-tc">
          <img
            data-cfsrc="/img/logo/main-logo.png"
            src="/img/logo/main-logo.png"
            alt="aido-logo"
          />
        </div>
      </div>

        <h4>
          {testCovid.phone ? "Verifikasi Nomor Telepon" : "Verifikasi Email"}
        </h4>
        <Form.Group className="mb-3" controlId="formGroupEmail">
          <Form.Label>
            Masukkan kode OTP 6 digit aido health yang telah dikirim{" "}
            <span>
              {testCovid.phone
                ? "ke " + testCovid.phone
                : testCovid.email
                ? "ke " + testCovid.email
                : null}
            </span>
          </Form.Label>

          <OtpInput
            containerStyle={{
              justifyContent: "center",
              marginTop: "10px",
              marginBottom: "20px",
            }}
            inputStyle={{
              fontSize: "20px",
              fontWeight: 600,
              width: "2em",
              borderRadius: "0px",
              backgroundColor: "rgba(0, 0, 0, 0)",
              border: "0px inset  rgba(0, 0, 0, 0.3)",
              borderBottom: `${
                otp.length > 5
                  ? "1px solid #007bff"
                  : "1px inset rgba(0, 0, 0, 0.3)"
              }`,
            }}
            focusStyle={{
              borderRadius: 0,
              border: `${
                testCovid.authenticate
                  ? "0px inset rgba(0, 0, 0, 0.3)"
                  : "0px inset #007bff"
              }`,
              borderBottom: `${
                testCovid.authenticate
                  ? "1px inset rgba(0, 0, 0, 0.3)"
                  : "1px inset #007bff"
              }`,
              outline: "none",
            }}
            value={otp}
            onChange={setOtp}
            isInputNum={true}
            numInputs={6}
            isDisabled={testCovid.authenticate}
            separator={<span>&nbsp;</span>}
          />

          {counter && counter > 0 ? (
            <div className="row justify-content-center">
              <small>
                <b>00:{counter.toString().padStart(2, "0")}</b>
              </small>
            </div>
          ) : null}

          {error && otp === error.customerOtp && (
            <div
              style={{ color: "red", marginTop: "30px", textAlign: "center" }}
            >
              <label>{error.message}</label>
            </div>
          )}
        </Form.Group>

        <ButtonToolbar
          className="justify-content-between"
          style={{ marginBottom: "20px" }}
        >
          <Button
            className="btn-outline"
            variant="link"
            disabled={testCovid.authenticate}
            onClick={() => {
              history.push({
                pathname: "/test-covid/login",
                state: state,
              });
            }}
          >
            {testCovid.phone
              ? "Masuk via email"
              : testCovid.email
              ? "Masuk via nomor telepon"
              : "Masuk via email"}
          </Button>
          <Button variant="link" disabled={counter} onClick={sendOtp}>
            Minta kode lagi
          </Button>
        </ButtonToolbar>

        <Button
          className="btn-block"
          variant={otp !== "" && otp.length == 6 ? "primary" : "secondary"}
          onClick={submitLogin}
          disabled={otp === "" ? true : false}
        >
          Verifikasi
        </Button>
    </Layout>
  );
};

export default VerifyOtp;
