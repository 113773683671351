import axios from "../helpers/axios";
import { orderTeleConstants } from "./constants";

export const getAllTeleOrder = (q) => {
  return async (dispatch) => {
    dispatch({ type: orderTeleConstants.GET_ALL_ORDER_TELE_REQUEST });
    const res = await axios.get(
      `/Order/getAllTeleOrder?${q}`, 
    );
    if (res.status === 200) {
      const { orders, totalData, sendBird, parentId } = res.data;
      dispatch({
        type: orderTeleConstants.GET_ALL_ORDER_TELE_SUCCESS,
        payload: { orders, totalData, sendBird, parentId },
      });
    } else {
      dispatch({
        type: orderTeleConstants.GET_ALL_ORDER_TELE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getOrderTeleDetail = (id) => {
    return async (dispatch) => {
      dispatch({ type: orderTeleConstants.ORDER_TELE_DETAIL_REQUEST });
      const res = await axios.get(
        `Order/completedCallTeleconsultation/${id}`, 
      );
      if (res.status === 200) {
        const { order } = res.data;
        // console.log("ord", order)
        dispatch({
          type: orderTeleConstants.ORDER_TELE_DETAIL_SUCCESS,
          payload: { order },
        });
      } else {
        dispatch({
          type: orderTeleConstants.ORDER_TELE_DETAIL_FAILURE,
          payload: { error: res.data.error },
        });
      }
    };
};

export const getTeleStatus = () => {
    return async (dispatch) => {
      dispatch({ type: orderTeleConstants.GET_TELE_STATUS_REQUEST });
      const res = await axios.get(
        `/Order/Tele/Status`, 
      );
      if (res.status === 200) {
        const { status } = res.data;
        dispatch({
          type: orderTeleConstants.GET_TELE_STATUS_SUCCESS,
          payload: { status },
        });
      } else {
        dispatch({
          type: orderTeleConstants.GET_TELE_STATUS_FAILURE,
          payload: { error: res.data.error },
        });
      }
    };
};

export const changeStatusOrderTele = (id, dataBody) => {
    return async (dispatch) => {
      dispatch({ type: orderTeleConstants.CHANGE_ORDER_TELE_STATUS_REQUEST });
      const res = await axios.put(
        `Order/completedCallTeleconsultation/updateStatus/${id}`, dataBody
      );
      if (res.status === 200) {
        dispatch({
          type: orderTeleConstants.CHANGE_ORDER_TELE_STATUS_SUCCESS,
          payload: res.data,
        });
        dispatch(getAllTeleOrder());
      } else {
        dispatch({
          type: orderTeleConstants.CHANGE_ORDER_TELE_STATUS_FAILURE,
          payload: { error: res.data.error },
        });
      }
    };
};