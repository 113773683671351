import React, { useEffect, useState, useRef } from "react";
import styles from "../style.scss";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getSkriningDetail,
  getResultOrderVaccine,
} from "../../../../../actions";

const SkriningPrint = () => {
  let { orderId } = useParams();
  const orderVaccine = useSelector((state) => state.orderVaccine);
  const dispatch = useDispatch();

  const { skrining_detail, skrining_result } = orderVaccine;

  useEffect(() => {
    dispatch(getSkriningDetail(orderId));
    dispatch(getResultOrderVaccine(orderId));
  }, [orderId]);

  return (
    <div style={styles}>
      <div
        id="div_print"
        className="form-wrapper wrapper-vaccine"
        style={{ margin: "auto" }}
      >
        <div>
          <div className="wrapper-form-title" style={{ textAlign: "center" }}>
            <span className="form-title">
              Print Skrining Vaksinasi COVID-19*
            </span>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Nama</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={skrining_detail.name}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>NIK</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={skrining_detail.nik}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="wrapper-form-title">
            <span className="form-title">Pertanyaan</span>
          </div>

          <div
            style={{
              borderStyle: "solid",
              borderColor: "#58A8F8",
              borderRadius: "5px",
              display: "block",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <>
              {skrining_result ? (
                skrining_result.length > 0 ? (
                  skrining_result.map((item, index) => (
                    <div key={index + 1}>
                      <div style={{ paddingLeft: "10px" }}>
                        {" "}
                        {item.id}. {item.question}
                      </div>

                      <div style={{ paddingLeft: "35px" }}>
                        <input
                          type="radio"
                          id="yes"
                          name={`answer_${item.id}`}
                          value="1"
                          checked={item.answer === "1" ? true : false}
                          readOnly
                        />{" "}
                        <label
                          htmlFor={`answer_${item.id}`}
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                          }}
                        >
                          Ya
                        </label>
                        <input
                          type="radio"
                          id="no"
                          name={`answer_${item.id}`}
                          value="0"
                          checked={item.answer === "0" ? true : false}
                          readOnly
                          style={{ marginLeft: "30px" }}
                        />{" "}
                        <label
                          htmlFor={`answer_${item.id}`}
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            paddingRight: "0px",
                          }}
                        >
                          Tidak
                        </label>
                      </div>
                    </div>
                  ))
                ) : (
                  <div style={{ textAlign: "center" }}>Empty data</div>
                )
              ) : null}
            </>
          </div>

          <div
            style={{
              borderStyle: "solid",
              borderColor: "#58A8F8",
              borderRadius: "5px",
              display: "block",
              justifyContent: "center",
            }}
          >
            <>
              <div style={{ textAlign: "center" }}>Tanda Tangan Pasien :</div>
              <div style={{ height: "150px", width: "100%" }}></div>
            </>
          </div>
          <button
            className="btn btn-primary submit non-printable"
            style={{ marginTop: "20px" }}
            onClick={() => window.print()}
          >
            Print
          </button>
        </div>
      </div>
    </div>
  );
};

export default SkriningPrint;
