import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { getOrderAktif, getOrderDone } from "../../../actions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "./orderHistory.css";

export default function OrderHistory() {
  const [date, setDate] = useState({
    active: new Date(),
    done: new Date(),
  });
  const result = useSelector((state) => state.orderHistory);
  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementById("btnAktif").click();
  }, []);

  useEffect(() => {
    dispatch(getOrderAktif(`date=${moment(date.active).format("YYYY-MM-DD")}`));
  }, [date.active]);

  useEffect(() => {
    dispatch(getOrderDone(`date=${moment(date.done).format("YYYY-MM-DD")}`));
  }, [date.done]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const aktifDataVal =
    result.listDataAktif != undefined ? result.listDataAktif : [];
  const selesaiDataVal =
    result.listDataDone != undefined ? result.listDataDone : [];

  const urlPath = "/order-history/detail";

  const openPage = (pageName) => {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablink");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].style.backgroundColor = "#999999";
    }
    document.getElementById(pageName).style.display = "block";
    var el = "btn" + pageName;
    document.getElementById(el).style.backgroundColor = "#38a8ff";
  };

  return (
    <DashboardLayout>
      <button
        className="tablink"
        id="btnAktif"
        onClick={() => {
          openPage("Aktif");
        }}
      >
        Aktif
      </button>

      <button
        className="tablink"
        id="btnSelesai"
        onClick={() => {
          openPage("Selesai");
        }}
      >
        Selesai
      </button>

      <div id="Aktif" className="tabcontent">
        {result.loading ? (
          <Loading />
        ) : (
          <>
            <div className="row mb-4">
              <DatePicker
                className="form-control"
                selected={date.active}
                showMonthDropdown
                showYearDropdown
                onChange={(data) => setDate({ ...date, active: data })}
              />
            </div>

            <div className="row">
              {aktifDataVal.length > 0 ? (
                aktifDataVal.map((data, index) => (
                  <div className="column" key={index}>
                    <Link
                      to={{
                        pathname: urlPath,
                        state: { id: data.order_id },
                      }}
                    >
                      <div className="card">
                        <div className="container">
                          <div className="row">
                            <div className="col-9">
                              <h4>
                                {data.packageName ? data.packageName : "-"}
                              </h4>
                            </div>

                            <div className="col-3">
                              <h6 className="text-right">
                                {data.order_id ? data.order_id : "-"}
                              </h6>
                            </div>
                          </div>

                          <hr />

                          <h6>
                            <i
                              className="fa fa-user"
                              aria-hidden="true"
                              style={{ color: "rgb(51, 51, 51)" }}
                            ></i>{" "}
                            {`${data.patient_name ? data.patient_name : "-"} (${
                              data.patient_dob
                                ? new Date().getFullYear() -
                                  new Date(data.patient_dob).getFullYear()
                                : "-"
                            })`}
                          </h6>

                          <h6>
                            <i
                              className="fa fa-phone"
                              aria-hidden="true"
                              style={{ color: "rgb(51, 51, 51)" }}
                            ></i>{" "}
                            {data.patient_phone ? data.patient_phone : "-"}
                          </h6>

                          <h6>{data.orderAddress ? data.orderAddress : "-"}</h6>

                          <h6>
                            <i
                              className="fa fa-calendar"
                              aria-hidden="true"
                              style={{ color: "rgb(51, 51, 51)" }}
                            ></i>{" "}
                            {data.scheduleDate
                              ? moment(data.scheduleDate).format(
                                  "dddd, DD MMMM, YYYY"
                                )
                              : "-"}
                          </h6>

                          <h6>
                            <i
                              className="fa fa-clock-o"
                              aria-hidden="true"
                              style={{ color: "rgb(51, 51, 51)" }}
                            ></i>{" "}
                            {data.startTime && data.endTime
                              ? `${moment(data.startTime, "HH:mm:ss").format(
                                  "LT"
                                )} - ${moment(data.endTime, "HH:mm:ss").format(
                                  "LT"
                                )}`
                              : "-"}
                          </h6>

                          <h6 style={{ fontWeight: "bold" }}>
                            {data.statusDesc ? data.statusDesc : "-"}
                          </h6>

                          <h6 style={{ fontWeight: "bold" }}>
                            {data.timeUpdated
                              ? moment(data.timeUpdated).format("LLLL")
                              : "-"}
                          </h6>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))
              ) : (
                <p>
                  <b>Empty data</b>
                </p>
              )}
            </div>
          </>
        )}
      </div>

      <div id="Selesai" className="tabcontent">
        {result.loading ? (
          <Loading />
        ) : (
          <>
            <div className="row mb-4">
              <DatePicker
                className="form-control"
                selected={date.done}
                showMonthDropdown
                showYearDropdown
                onChange={(data) => setDate({ ...date, done: data })}
              />
            </div>

            <div className="row">
              {selesaiDataVal.length > 0 ? (
                selesaiDataVal.map((data, index) => (
                  <div className="column" key={index}>
                    <Link
                      to={{
                        pathname: "/order-history/detail",
                        state: { id: data.order_id },
                      }}
                    >
                      <div className="card">
                        <div className="container">
                          <div className="row">
                            <div className="col-9">
                              <h4>
                                {data.packageName ? data.packageName : "-"}
                              </h4>
                            </div>

                            <div className="col-3">
                              <h6 className="text-right">
                                {data.order_id ? data.order_id : "-"}
                              </h6>
                            </div>
                          </div>

                          <hr />

                          <h6>
                            <i
                              className="fa fa-user"
                              aria-hidden="true"
                              style={{ color: "rgb(51, 51, 51)" }}
                            ></i>{" "}
                            {`${data.patient_name ? data.patient_name : "-"} (${
                              data.patient_dob
                                ? new Date().getFullYear() -
                                  new Date(data.patient_dob).getFullYear()
                                : "-"
                            })`}
                          </h6>

                          <h6>
                            <i
                              className="fa fa-phone"
                              aria-hidden="true"
                              style={{ color: "rgb(51, 51, 51)" }}
                            ></i>{" "}
                            {data.patient_phone ? data.patient_phone : "-"}
                          </h6>

                          <h6>{data.orderAddress ? data.orderAddress : "-"}</h6>

                          <h6>
                            <i
                              className="fa fa-calendar"
                              aria-hidden="true"
                              style={{ color: "rgb(51, 51, 51)" }}
                            ></i>{" "}
                            {data.scheduleDate
                              ? moment(data.scheduleDate).format(
                                  "dddd, DD MMMM, YYYY"
                                )
                              : "-"}
                          </h6>

                          <h6>
                            <i
                              className="fa fa-clock-o"
                              aria-hidden="true"
                              style={{ color: "rgb(51, 51, 51)" }}
                            ></i>{" "}
                            {data.startTime && data.endTime
                              ? `${moment(data.startTime, "HH:mm:ss").format(
                                  "LT"
                                )} - ${moment(data.endTime, "HH:mm:ss").format(
                                  "LT"
                                )}`
                              : "-"}
                          </h6>

                          <h6 style={{ fontWeight: "bold" }}>
                            {data.statusDesc ? data.statusDesc : "-"}
                          </h6>

                          <h6 style={{ fontWeight: "bold" }}>
                            {data.timeUpdated
                              ? moment(data.timeUpdated).format("LLLL")
                              : "-"}
                          </h6>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))
              ) : (
                <p>
                  <b>Empty data</b>
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </DashboardLayout>
  );
}
