import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import queryString from "query-string";
import CreatableSelect from 'react-select/creatable';
import DashboardLayout from "../Layout";
import Modal from "../Components/Modal";
import Pagination from "../../../components/Pagination";
import Swal from "sweetalert2";
import { Link, useLocation } from "react-router-dom";
import { getAllMedicalRecords, postDownloadMR } from "../../../actions/orderMedicalRecords.actions";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import ReactDatePicker from "react-datepicker";
import { addDays, subDays } from "date-fns";

const OrderMedicalRecords = () => {
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const [medicalRecord, setMedicalRecord] = useState({
      patientName: "",
      patientDob: "",
      gender: "",
      orderId: "",
      scheduleDate: "",
      specialist: "",
      icdCode: "",
      diagnosis: "",
      partnerName: "",
      medicalRecordId: "",
      icdDiseases: "",
      icdProcedures: "",
      isReferToSpecialist: "",
      specialist_ref: "",
      isReferToLab: "",
      lab_reference: "",
      isReferToOtherPlan: "",
      recommendationNote: "",
      labResult: "",
      pharmacyPhoto: "",
    });
  const [show, setShow] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [downloadFromDate, setDownloadFromDate] = useState("");
  const [downloadToDate, setDownloadToDate] = useState("");
  const [orderType, setOrderType] = useState(-1);
  const [report, setReport] = useState({
    orders: [],
  });
  const searchKey = useRef("");
  const patientName = useRef("");
  const startDate = useRef("");
  const endDate = useRef("");
  const orderMr = useSelector((state) => state.orderMedicalRecords);
  const { state } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllMedicalRecords(search));
  }, [dispatch, search]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: orderMr.totalData,
    });
  }, [orderMr.totalData]);

  useEffect(() => {
    // console.log("download date", downloadFromDate, "-", downloadToDate, orderType)
    if ((moment(downloadFromDate).format("YYYY-MM-DD") !== "Invalid date" && moment(downloadToDate).format("YYYY-MM-DD") !== "Invalid date") && (orderType === "" || orderType === "0" || orderType === "1")) {
      const form = new FormData();
      form.append("startDate", moment(downloadFromDate).format("YYYY-MM-DD"));
      form.append("endDate",  moment(downloadToDate).format("YYYY-MM-DD"));
      if (orderType !== "") {
        form.append("isHealthScreening", orderType);
      }

      dispatch(postDownloadMR(form));
    }
  }, [downloadFromDate, downloadToDate, orderType]);

  useEffect(() => {
    getDataDownload(orderMr.dataDownload);
  }, [orderMr.dataDownload]);

  const getDataDownload = (orders) => {
    setReport({
      orders: orders
    });
  };

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("order");
    let row;

    row = worksheet.addRow([
      "scheduleDate",
      "startTime",
      "endTime",
      "order_id",
      "event_id",
      "event_name",
      "corporate_name",
      "customer_name",
      "patient_name",
      "status",
      "partner_name",
      "doctor_name",
      "isPremium",
      "isHealthScreening",
      "isPatientCovid",
      "diagnosis",
      "customResep",
      "prescription",
      "specialist_ref",
      "lab_reference",
      "recommendationNote",
      "emergencyInfo",
      "patient_not_joined",
      "no_patient_at",   
    ]);
    row.font = { bold: true };

    // let data = report.orders;
    if (report.orders.length !== 0) {
      report.orders.map((order) => {
        worksheet.addRow([
          moment(order.scheduleDate).format("DD MMM YYYY"),
          order.startTime,
          order.endTime,
          order.order_id,
          order.eventId,
          order.event_name,
          order.corporate_name,
          order.customer_name,
          order.patient_name,
          order.status_description,
          order.doctor_name,
          order.doctor_firstname ? order.doctor_lastname ? order.doctor_firstname+" "+order.doctor_lastname : order.doctor_firstname : "",
          order.isPremium ? order.isPremium : 0,
          order.isHealthScreening,
          order.isPatientCovid ? order.isPatientCovid : 0,
          order.diagnosis,
          order.customResep,
          order.pharmacyPhoto,
          order.specialist_ref,
					order.lab_reference,
					order.recommendationNote, // Other
					order.emergency_info, // Keterangan
          order.patient_not_joined,
          order.no_patient_at ? moment(order.no_patient_at).format("DD MMM YYYY HH:mm") : null,
        ]);
      });
    }

    let today = new Date(),
    dateNow =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate(),
      time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds(),
      dateReplace = dateNow.replace(/-/g, ""),
      timeReplace = time.replace(/:/g, "");

    workbook.xlsx.writeBuffer().then((buff) => {
      saveAs(
        new Blob([buff]),
        "MedicalReport-" + dateReplace + timeReplace + ".xlsx"
      );
    });
  };

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };
  
  const handleReset = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      page: pagination.page,
      limit: pagination.limit,
    };

    if (startDate.current.value !== "") {
      startDate.current.value = "";
    }
    
    if (endDate.current.value !== "") {
      endDate.current.value = "";
    }

    if (patientName.current.value !== "") {
      patientName.current.value = "";
    }

    if (searchKey.current.value !== "") {
      searchKey.current.value = "";
    }

    let query = queryString.stringify(values, {
      skipEmptyString: true
    });
    setSearch(query);
  };

  const handleSearch = (page, limit) => {
    let values = {};

    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }

    if (limit) {
      values.limit = limit;
    }

    if (startDate.current.value !== "") {
      values.startDate = startDate.current.value;
    }

    if (endDate.current.value !== "") {
      values.endDate = endDate.current.value;
    }

    if (patientName.current.value !== "") {
      values.patientName = patientName.current.value;
    }

    if (searchKey.current.value !== "") {
        values.searchKey = searchKey.current.value;
      }

    let query = queryString.stringify(values, {
      skipEmptyString: true
    });
    setSearch(query);
  };

  const closeDownloadModal = () => {
    if (!orderMr.loadingDownload) {
      setDownloadFromDate("");
      setDownloadToDate("");
      setOrderType(-1);
      setReport({
        orders: []
      });
    }
    setDownloadModal(false);
  };

  const showDetail = (value) => {
    setShow(true);
    setMedicalRecord({
      patientName: value.patient_name,
      patientDob: value.patient_dob,
      gender: value.gender,
      orderId: value.order_id,
      scheduleDate: value.scheduleDate,
      specialist: value.specialist,
      diagnosis: value.diagnosis,
      partnerName: (value.doctor_firstname ? (value.doctor_lastname ? `${value.doctor_firstname} ${value.doctor_lastname} ${value.doctor_name ? " - "+value.doctor_name : ""}` : value.doctor_firstname) : value.doctor_name),
      medicalRecordId: value.medicalRecordId,
      icdDiseases: value.icdDiseases,
      icdProcedures: value.icdProcedures,
      isReferToSpecialist: value.isReferToSpecialist,
      specialist_ref: value.specialist_ref,
      isReferToLab: value.isReferToLab,
      lab_reference: value.lab_reference,
      isReferToOtherPlan: value.isReferToOtherPlan,
      recommendationNote: value.recommendationNote,
      labResult: value.labResult,
      pharmacyPhoto: value.pharmacyPhoto,
    });
  };

  const renderDetail = () => {
    return (
      <Modal
        modalTitle="Medical Record Detail"
        show={show}
        size="md"
        handleClose={() => setShow(false)}
        close={true}
        buttons={[
        ]}
      >
      <div className="mr-container">
        <div className="pl-2 pr-2">
          <Row style={{ justifyContent: "flex-end", marginTop: "4px"}}>
                          
            {medicalRecord.labResult ? (
                    <a href={medicalRecord.labResult} target="_blank"
                        >
                        <button 
                        className="btn btn-info justify-content-flex-end text-mr mt-1 pl-4 pr-4 mr-2" 
                        style={{ float: "right", fontSize: "0.775rem" }}
                        // href={soap.labResult} target="_blank"
                        // onClick={() => {
                        //   window.open(`${soap.labResult}`, "_blank");
                        // }}
                        >Lab Result </button>
                    </a>
                    ) : (<></>)}

            {medicalRecord.pharmacyPhoto && (
                <a href={medicalRecord.pharmacyPhoto} target="_blank"
                  >
                    <button 
                    className="btn btn-outline-info justify-content-flex-end text-mr mt-1 pl-4 pr-4 mr-2" 
                    style={{ float: "right", fontSize: "0.775rem" }}
                    >Prescription </button>
                </a>
                )}
            
          </Row>
          
          <label style={{ marginBottom: "4px"}}><b>Plan</b></label>
          <Row style={{ padding: "10px 13.9px", paddingTop: 0}}>
                          
            <label>Specialist</label>
            <input 
              disabled
              type="text" 
              className="form-control mr-disabled" 
              placeholder={medicalRecord.specialist_ref ? false : "-"}
              value={medicalRecord.specialist_ref ? medicalRecord.specialist_ref : ""} 
            />
            
          </Row>
          <Row style={{ padding: "10px 13.9px", paddingTop: 0}}>
            
            <label>Laboratorium</label>
            <CreatableSelect
                id="mr-select-lab"
                isMulti
                isDisabled
                placeholder={medicalRecord.lab_reference ? false : "-"}
                getOptionValue={(option) => option.id}
                getOptionLabel={
                    option => `${option.test_name} ${option.description ? ("("+option.description+")") : ""}`
                }
                value={medicalRecord.lab_reference ? medicalRecord.lab_reference : []}
              />
            
          </Row>
          <Row style={{ padding: "10px 13.9px", paddingTop: 0}}>
            <label>Other</label>
            <input 
              type="text" 
              disabled
              className="form-control mr-disabled" 
              placeholder={medicalRecord.specialist_ref ? false : "-"}
              value={medicalRecord.recommendationNote ? medicalRecord.recommendationNote : ""} 
              />
          </Row>
        </div>
        </div>
      </Modal>
    );
  };

  const renderMedicalRecord = (data, pagination, showDetail) => {
    const medicalRecords = data?.medicalRecords || [];

    return (
      <>
        {/* Empty */}
        {medicalRecords.length === 0 && (
          <tr>
            <td colSpan="18">Empty Data</td>
          </tr>
        )}

        {/* Has Data */}
        {medicalRecords.map((item, index) => {
          return (
            <tr key={item.order_id}>
              <td>{(pagination.page - 1) * 10 + index + 1}</td>
              <td>{item.order_id ? item.order_id : "-"}</td>
              <td>{item.patient_name ? item.patient_name : ""}</td>
              <td>{item.scheduleDate ? `${moment(item.scheduleDate).format("ll")} ${item.startTime ? moment(item.startTime, "HH:mm:ss").format("HH:mm") + ' - ' + moment(item.endTime, "HH:mm:ss").format("HH:mm") : ""}` : "-"}</td>
              <td>{(item.doctor_firstname ? (item.doctor_lastname ? `${item.doctor_firstname} ${item.doctor_lastname} ${item.doctor_name ? " - "+item.doctor_name : ""}` : item.doctor_firstname) : item.doctor_name)}</td>
              <td>{item.specialist ? item.specialist : "-"}</td>
              {/* <td>{item.icdDiseases ? (item.icdDiseases.length > 2 ? (item.icdDiseases.slice(0,2).map(icd => icd.code).join("; ")) : item.icdDiseases.map(icd => icd.code).join("; ")) : "-"}</td> */}
              <td>{item.icdCode ? (item.icdCode.length > 3 ? (item.icdCode.slice(0,2).map(icd => icd).join("; ")) : item.icdCode.map(icd => icd).join("; ")) : "-"}</td>
              <td>{item.diagnosis ? item.diagnosis : "-"}</td>
              <td style={{ width: "120px", textAlign: "justify" }}>
                  <div 
                    >
                    
                      <label className="mb-0" htmlFor="isReferToSpecialist" style={{ wordWrap: "break-word" }}>
                      <input
                        type="checkbox"
                        className="mb-1 mr-1"
                        name="isReferToSpecialist"
                        checked={item.isReferToSpecialist}
                        disabled
                        style={{ verticalAlign: "middle", width: "12px", height: "12px" }}
                      /> Specialist</label>
                  </div>
                  <div>
                      <label className="mb-0" htmlFor="isReferToSpecialist" style={{ wordWrap: "break-word" }}>
                      <input
                        type="checkbox"
                        className="mb-1 mr-1"
                        name="isReferToLab"
                        checked={item.isReferToLab}
                        disabled
                        style={{ verticalAlign: "middle", width: "12px", height: "12px" }}
                      /> Lab</label>
                  </div>
                  <div>
                      <label className="mb-0" htmlFor="isReferToSpecialist" style={{ wordWrap: "break-word" }}>
                      <input
                        type="checkbox"
                        className="mb-1 mr-1"
                        name="isReferToOtherPlan"
                        checked={item.isReferToOtherPlan}
                        disabled
                        style={{ verticalAlign: "middle", width: "12px", height: "12px" }}
                      />Other</label>
                  </div>

              </td>
              <td style={{ verticalAlign: "middle"}}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  {( item.labResult || item.pharmacyPhoto || item.isReferToSpecialist == 1 || item.isReferToLab == 1 || item.isReferToOtherPlan == 1 ) ?
                    // <Link 
                    //   to={{ pathname: "/dokter-worklist/medical-records/detail", state: { patientId: item.patientId, orderId: state.orderId, mr: item, patient: patientData}}}>
                      <button 
                        type="button"
                        className="btn btn-block btn-outline-primary"
                        style={{
                          marginBottom: "10px",
                        }}
                        onClick={() => showDetail(item)}
                      >
                        Detail
                      </button>
                    // </Link>
                    : "-"
                  }

                </div>
              </td>
            </tr>
          )
        })
        }
      </>
    );
  };

  const renderDownloadReport = () => {
    return (
      <Modal
        modalTitle="Download Report"
        show={downloadModal}
        size="md"
        handleClose={() => closeDownloadModal()}
        buttons={ orderMr.loadingDownload ? [{
          label: "Loading...",
          color: "primary",
          disabled: true,
            spinner:  <Spinner
                        className="mr-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
        }] :
        [
          {
            label: "Download",
            color: "primary",
            disabled: (downloadFromDate && downloadToDate && (orderType === "" || orderType === "0" || orderType === "1")) ? false : true,
            onClick: downloadExcel,
          },
        ]}
      >
        <div className="mr-container">
          <div className="px-3">
              <div className="row mt10 mb10">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="">Start Date</label>
                    <ReactDatePicker
                      className="form-control"
                      placeholderText="Select Start Date"
                      selected={downloadFromDate}
                      onChange={(date) => setDownloadFromDate(date)}
                      selectsStart
                      startDate={downloadFromDate}
                      endDate={downloadToDate}
                      minDate={downloadToDate ? subDays(downloadToDate, 6) : false}
                      maxDate={new Date()}
                      isClearable={downloadFromDate ? true : false}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="">End Date</label>
                    <ReactDatePicker
                      className="form-control"
                      placeholderText="Select End Date"
                      selected={downloadToDate}
                      onChange={(date) => setDownloadToDate(date)}
                      selectsEnd
                      startDate={downloadFromDate}
                      endDate={downloadToDate}
                      minDate={downloadFromDate}
                      maxDate={downloadFromDate ? addDays(downloadFromDate, 6) > new Date() ? new Date() : addDays(downloadFromDate, 6) : new Date()}
                      isClearable={downloadToDate ? true : false}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <label htmlFor="orderType">Order Type</label>
                  <select 
                    className="form-control" 
                    value={orderType} 
                    onChange={(e) => setOrderType(e.target.value)} 
                    style={{ fontWeight: 200 }}
                    name="orderType"
                    onFocus={
                      (e) => {
                        e.currentTarget.style.fontWeight = 400
                      } 
                    }
                    >
                    <option value="-1">Select Order Type</option>
                    <option value="">All</option>
                    <option value="0">Free Tele</option>
                    <option value="1">Health Screening</option>
                  </select>
                </div>
              </div>

          </div>
        </div>
      </Modal>
    );
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Medical Record</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="mr-container mb-2" 
          >
        </div>

        <div className="row">
          <div className="col-md-8" style={{ paddingBottom: "1em" }}>
            <button
              type="button"
              className="btn btn-outline-primary"
              style={{ marginRight: "5px", marginBottom: "0.5em" }}
              onClick={() => setDownloadModal(true)}
            >
              Download Report
            </button>
          </div>
        </div>

        <div className="row mb15">
          <div className="link-btn">
            <button
              type="button"
              className="btn btn-link no-padding"
              style={{ textDecoration: "none", boxShadow: "none" }}
              data-toggle="collapse"
              data-target="#collapseMedicalHistory"
            >
              Filter By
              <i className="fa fa-angle-down" style={{ marginLeft: "10px" }} />
            </button>
          </div>

          <div className="col">
            <hr />
          </div>
        </div>

        <div className="collapse" id="collapseMedicalHistory">
          <div id="filterForm" className="row filter-by">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <div className="mb10">Date From</div>
                  <input 
                    type="date" 
                    className="form-control" 
                    ref={startDate} 
                    />
                </div>
                <div className="col-md-3">
                  <div className="mb10">Date To</div>
                  <input 
                    type="date" 
                    className="form-control" 
                    ref={endDate} 
                    />
                </div>
                <div className="col-md-3">
                  <div className="mb10">Patient Name</div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Patient"
                    ref={patientName}
                    />
                </div>
                <div className="col-md-3">
                  <div className="mb10">Doctor Name</div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Doctor Name"
                    ref={searchKey}
                    />
                </div>
              </div>
            </div>
          </div>

          <div className="text-right" style={{ marginTop: "25px" }}>
            <button
              type="button"
              className="btn btn-secondary"
              style={{ width: "100px", marginRight: "5px" }}
              onClick={() => handleReset()}
            >
              Reset
            </button>

            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100px" }}
              onClick={() => handleSearch()}
            >
              Apply
            </button>
          </div>

          <div style={{ width: "100%" }}>
            <hr />
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "45px" }}
                >
                  No
                </th>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "45px" }}
                >
                  Order ID
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Patient Name
                </th>
                <th 
                  rowSpan={2} 
                  className="table-header-middle"
                  style={{ width: "100px" }}
                  >
                  Date
                </th>
                <th 
                  rowSpan={2} 
                  className="table-header-middle"
                  style={{ width: "150px" }}
                >
                  Doctor
                </th>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "80px" }}
                >
                  Specialist
                </th>

                <th rowSpan={2} className="table-header-middle">
                  ICD Code
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Diagnosis
                </th>

               

                <th rowSpan={2} className="table-header-middle">
                  Plan
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Action
                </th>
              </tr>

            </thead>
            <tbody>{renderMedicalRecord(orderMr, pagination, showDetail)}</tbody>
          </table>
        </div>
        {renderDetail()}
        {renderDownloadReport()}

        {orderMr.totalData > 10 ? (
          <Pagination
            props={pagination}
            setProps={setPagination}
            onChange={(page, limit) => {
              handleSearch(page, limit);
            }}
          />
        ) : null}
      </div>
    </DashboardLayout>
  );
};

export default OrderMedicalRecords;
