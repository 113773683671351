import axios from "../helpers/axios";
import axiosParent from "../helpers/axiosParent";
import { userConstants } from "./constants";

export const changePassword = (dataBody) => {
  return async (dispatch) => {
    dispatch({ type: userConstants.CHANGE_PASSWORD_REQUEST });
    const res = await axios.put(
      `/Password`, dataBody
    );
    if (res.status === 200) {
      dispatch({
        type: userConstants.CHANGE_PASSWORD_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: userConstants.CHANGE_PASSWORD_FAILURE,
        payload: { error: res.data.message },
      });
    }
  };
};

export const forgotPassword = (dataBody) => {
  return async (dispatch) => {
    dispatch({ type: userConstants.FORGOT_PASSWORD_REQUEST });
    const res = await axiosParent.post(
      `/ForgotPassword`, dataBody 
    );
    if (res.status === 200) {
      // const { message } = res.data;
      let message = res.data;
      dispatch({
        type: userConstants.FORGOT_PASSWORD_SUCCESS,
        payload: message,
      });
    } else {
      dispatch({
        type: userConstants.FORGOT_PASSWORD_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};