import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getRegister, getRegisterById, getRelation } from "../../../actions";
import Modal from "../Components/Modal";
import Pagination from "../../../components/Pagination";
import DashboardLayout from "../Layout";
import queryString from "query-string";
import moment from "moment";
import { Link } from "react-router-dom";

const RegisterSingle = () => {
  const [registerInfoModal, setRegisterInfoModal] = useState(false);
  const [search, setSearch] = useState(
    `parentId=${localStorage.getItem("parentId")}&searchKey=&registerDate=`
  );
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const parentId = localStorage.getItem("parentId");
  const searchKey = useRef("");
  const registerDate = useRef("");
  const register = useSelector((state) => state.register);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRegister(search));
  }, [search]);

  useEffect(() => {
    dispatch(getRelation());
  }, []);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: register.totalData,
    });
  }, [register.totalData]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const handleCloseModal = () => {
    setRegisterInfoModal(false);
  };

  const handleReset = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      parentId: parentId,
      page: pagination.page,
      limit: pagination.limit,
    };
    if (searchKey.current.value !== "" || searchKey.current.value === "") {
      values.searchKey = searchKey.current.value = "";
    }
    if (
      registerDate.current.value !== "" ||
      registerDate.current.value === ""
    ) {
      values.registerDate = registerDate.current.value = "";
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleSearch = (page, limit) => {
    let values = {
      parentId: parentId,
      searchKey: searchKey.current.value,
      registerDate: registerDate.current.value,
    };
    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }
    if (limit) {
      values.limit = limit;
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const handleRegisterInfo = (dataId) => {
    dispatch(getRegisterById(dataId)).then(setRegisterInfoModal(true));
  };

  const renderRegisterInfo = () => {
    if (!register.userData) {
      return null;
    }

    let data = register.userData;
    let patient = register.userData.patients;
    let user = register.userData.users;

    return (
      <Modal
        modalTitle="Information Detail"
        show={registerInfoModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        buttons={[]}
        close
      >
        <Row>
          <Col md>
            <div
              className="form-group"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <span>{`Registration Date: ${
                data.registerDate
                  ? moment(data.registerDate, "DD/MM/YYYY HH:mm").format("LL")
                  : "-"
              }`}</span>
              <span>
                Created By:{" "}
                {data.createdBy ? (
                  <a
                    href={`mailto:${data.createdBy}`}
                    target="_blank"
                    style={{ color: "blue" }}
                  >
                    {data.createdBy}
                  </a>
                ) : (
                  "-"
                )}
              </span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="wrapper-form-title">
              <span style={{ fontSize: "20px" }}>Patient Data</span>
            </div>

            <div className="table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="table-header">
                  <tr>
                    <th
                      rowSpan={2}
                      className="table-header-middle"
                      style={{ width: "200px" }}
                    >
                      Name
                    </th>

                    <th rowSpan={2} className="table-header-middle">
                      Gender
                    </th>

                    <th rowSpan={2} className="table-header-middle">
                      Date of Birth
                    </th>

                    <th rowSpan={2} className="table-header-middle">
                      Phone Number
                    </th>

                    <th rowSpan={2} className="table-header-middle">
                      Identity Number
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {patient ? (
                    patient.map((data) => (
                      <tr key={data.patientId}>
                        <td>{data.name ? data.name : "-"}</td>
                        <td>
                          {data.gender == "1"
                            ? "Male"
                            : data.gender == "2"
                            ? "Female"
                            : "-"}
                        </td>
                        <td>
                          {data.birthday
                            ? moment(data.birthday).format("LL")
                            : "-"}
                        </td>
                        <td>{data.phone ? "+62" + data.phone : "-"}</td>
                        <td>
                          {data.national_id_no && data.national_id_type == "1"
                            ? `KTP ${data.national_id_no}`
                            : data.national_id_no &&
                              data.national_id_type == "2"
                            ? `SIM ${data.national_id_no}`
                            : data.national_id_no &&
                              data.national_id_type == "3"
                            ? `Passport ${data.national_id_no}`
                            : "-"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">Empty data</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="wrapper-form-title">
              <span style={{ fontSize: "20px" }}>Responsible Person Data</span>
            </div>

            <div className="form-group">
              <div style={{ display: "flex" }}>
                <span className="col-4">Application Username</span>
                <span className="col">{`: ${
                  user.full_name ? user.full_name : "-"
                }`}</span>
              </div>

              <div style={{ display: "flex" }}>
                <span className="col-4">Date of Birth</span>
                <span className="col">
                  {`: ${
                    user.birthday ? moment(user.birthday).format("LL") : "-"
                  }`}
                </span>
              </div>

              <div style={{ display: "flex" }}>
                <span className="col-4">Relationship</span>
                <span className="col">
                  {`: ${register.relation.data
                    .filter(
                      (x) =>
                        x.id ==
                        patient.map((data, index) => {
                          if (index === 0) {
                            return data.relation;
                          }
                        })
                    )
                    .map((x) => x.relationMapping)}`}
                </span>
              </div>

              <div style={{ display: "flex" }}>
                <span className="col-4">Gender</span>
                <span className="col">
                  {`: ${
                    user.gender == "1"
                      ? "Male"
                      : user.gender == "2"
                      ? "Female"
                      : "-"
                  }`}
                </span>
              </div>

              <div style={{ display: "flex" }}>
                <span className="col-4">Phone Number</span>
                <span className="col">{`: ${
                  user.phone ? "+62" + user.phone : "-"
                }`}</span>
              </div>

              <div style={{ display: "flex" }}>
                <span className="col-4">Email Address</span>
                <span className="col">{`: ${
                  user.email ? user.email : "-"
                }`}</span>
              </div>

              <div style={{ display: "flex" }}>
                <span className="col-4">Full Address</span>
                <span className="col">
                  {`: ${user.fullAddress ? user.fullAddress : "-"}`}
                </span>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="wrapper-form-title">
              <span style={{ fontSize: "20px" }}>Homecare Service</span>
            </div>

            <div className="form-group">
              <div style={{ display: "flex" }}>
                <span className="col-4">Homecare Service ID</span>
                <span className="col">
                  {`: ${data.packageId ? data.packageId : "-"}`}
                </span>
              </div>

              {data.visitData
                ? data.visitData.map((data, index) => (
                    <div style={{ display: "flex" }}>
                      <span className="col-4">{`Homecare Visit Date ${
                        index + 1
                      }`}</span>
                      <span className="col">{`: ${
                        data.visitDate
                          ? moment(data.visitDate).format("LL")
                          : "-"
                      }`}</span>
                    </div>
                  ))
                : null}

              <div style={{ display: "flex" }}>
                <span className="col-4">Total Payment</span>
                <span className="col">{`: ${
                  data.totalPrice ? formatter.format(data.totalPrice) : "-"
                }`}</span>
              </div>

              <div style={{ display: "flex" }}>
                <span className="col-4">Notes</span>
                <span className="col">{`: ${
                  data.notes ? data.notes : "-"
                }`}</span>
              </div>

              <div style={{ display: "flex" }}>
                <span className="col-4">Complaints</span>
                <span className="col">{`: ${
                  data.complaints ? data.complaints : "-"
                }`}</span>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  };

  const renderRegister = () => {
    return register.loading ? (
      <tr>
        <td colSpan="10">
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        {register.data.length > 0 ? (
          register.data.map((data, index) => (
            <tr key={data.id}>
              <td>{(pagination.page - 1) * 10 + index + 1}</td>
              <td>
                {data.created_at ? moment(data.created_at).format("ll") : "-"}
              </td>
              <td>{data.id ? data.id : "-"}</td>
              <td>{data.patientName ? data.patientName : "-"}</td>
              <td>{data.userName ? data.userName : "-"}</td>
              <td>{data.userPhone ? data.userPhone : "-"}</td>
              <td>{data.serviceName ? data.serviceName : "-"}</td>
              <td>{data.created_by ? data.created_by : "-"}</td>
              <td>{data.note ? data.note : "-"}</td>
              <td>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <span
                    className="form-title"
                    style={{ cursor: "pointer", width: "45px" }}
                    onClick={() => handleRegisterInfo(data.id)}
                  >
                    <i className="fa fa-info" style={{ color: "#00acef" }}></i>
                  </span>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="10">Empty data</td>
          </tr>
        )}
      </>
    );
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Register Single</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        <div className="wrapper-form-title">
          <span className="form-title">Registration</span>
        </div>

        <div className="row">
          <div className="col" style={{ paddingBottom: "1em" }}>
            <Link to={{ pathname: "/register-single/add" }}>
              <button type="button" className="btn btn-outline-primary">
                New Registration
              </button>
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-4">
            <div className="mb10">Patient Name</div>
            <input
              type="text"
              className="form-control"
              placeholder="Search Patient Name"
              ref={searchKey}
            />
          </div>

          <div className="form-group col-md-4">
            <div className="mb10">Registration Date</div>
            <input type="date" className="form-control" ref={registerDate} />
          </div>

          <div className="form-group text-left col-md-4 mt-auto">
            <button
              type="button"
              className="btn btn-secondary"
              style={{
                width: "100px",
                marginRight: "5px",
              }}
              onClick={() => handleReset()}
            >
              Reset
            </button>

            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100px" }}
              onClick={() => handleSearch()}
            >
              Apply
            </button>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "45px" }}
                >
                  No
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "100px" }}
                >
                  Registration Date
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Order ID
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "150px" }}
                >
                  Patient Name
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "150px" }}
                >
                  Responsible Person Name
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "150px" }}
                >
                  Responsible Person Phone
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "200px" }}
                >
                  Service
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Created By
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Notes
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>{renderRegister()}</tbody>
          </table>
        </div>

        {register.totalData > 10 ? (
          <Pagination
            props={pagination}
            setProps={setPagination}
            onChange={(page, limit) => {
              handleSearch(page, limit);
            }}
          />
        ) : null}
      </div>
      {renderRegisterInfo()}
    </DashboardLayout>
  );
};

export default RegisterSingle;
