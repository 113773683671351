import axios from "../helpers/axios";
import { gpOnsiteConstants } from "./constants";

export const getDataGPOnsite = (q) => {
  return async (dispatch) => {
    dispatch({ type: gpOnsiteConstants.GET_ALL_GPONSITE_REQUEST });
    
    const res = await axios.get(`/Order/GPOnsite?status=80&${q}`);
    if (res.status === 200) {
      const { orders, totalData } = res.data;
      dispatch({
        type: gpOnsiteConstants.GET_ALL_GPONSITE_SUCCESS,
        payload: { orders, totalData },
      });
    } else {
      dispatch({
        type: gpOnsiteConstants.GET_ALL_GPONSITE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getDataGPOnsiteDone = (q) => {
    return async (dispatch) => {
      dispatch({ type: gpOnsiteConstants.GET_ALL_GPONSITE_REQUEST });
      
      const res = await axios.get(`/Order/GPOnsite?status=99&${q}`);
      if (res.status === 200) {
        const { orders, totalData } = res.data;
        dispatch({
          type: gpOnsiteConstants.GET_ALL_GPONSITE_SUCCESS,
          payload: { orders, totalData },
        });
      } else {
        dispatch({
          type: gpOnsiteConstants.GET_ALL_GPONSITE_FAILURE,
          payload: { error: res.data.error },
        });
      }
    };
  };

export const updateOrderGPOnsiteStatus = (id) => {
    return async (dispatch) => {
      dispatch({ type: gpOnsiteConstants.UPDATE_GPONSITE_REQUEST });
      const res = await axios.put(`/Order/GPOnsite/${id}`);
      if (res.status === 200) {
        dispatch({
          type: gpOnsiteConstants.UPDATE_GPONSITE_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: gpOnsiteConstants.UPDATE_GPONSITE_FAILURE,
          payload: { error: res.data.error },
        });
      }
    };
};