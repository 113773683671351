import axios from "../helpers/axios";
import { paymentOfflineVoucherConstants } from "./constants";

export const getRegisterPatientOV = (search) => {
    return async (dispatch) => {
      dispatch({ type: paymentOfflineVoucherConstants.GET_REGISTERPATIENT_LIST_REQUEST });
      const res = await axios.get(`/Ipd/listPatientOV?parentId=${localStorage.getItem("parentId")}${search}`);
      if (res.status === 200) {
        dispatch({
          type: paymentOfflineVoucherConstants.GET_REGISTERPATIENT_LIST_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: paymentOfflineVoucherConstants.GET_REGISTERPATIENT_LIST_FAILURE,
          payload: { error: res.data.error },
        });
      }
    };
};