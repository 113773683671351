import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "../../Layout";
import {
  createMedicalRecordsV3,
  findIcd10,
  getBmiAndIdealWeight,
  getMedicalDetail,
  getSpecialist,
  getTestLabList,
  notifyEmergencyCase,
  reportNoPatient,
  sendReminderToPatient,
  getEventHS,
} from "../../../../actions";
import Modal from "../../Components/Modal";
import { Col, Form, Row } from "react-bootstrap";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import {Tabs, Tab, Box, TextField, InputLabel, RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, Stack, FormControl, FormGroup, Checkbox } from '@mui/material';
// import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import Swal from "sweetalert2";
import moment from "moment";
import "./index.scss";
import { borderRadius } from "@mui/system";

const Breadcrumb = (props) => {
  return (
    <nav key={props.label} aria-label="breadcrumb" id="breadcrumb-id">
        <ol className="breadcrumb pl-0">
          <li className="breadcrumb-item">
            <a>Dashboard</a>
          </li>
          <li className="breadcrumb-item" aria-current="page">
            <Link to={props.path}>{props.menu}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {props.label}
          </li>
          
        </ol>
    </nav>
  )
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

const MedicalRecords = () => {
    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
      setValue(newValue);
    };

    const [soap, setSoap] = useState({
      orderId: "",
      patientId: "",
      scheduleId: "",
      medicalRecordId: "",
      partnerName: "",
      scheduleDate: "",
      scheduleTime: "",
      timezone: "",
      patientName: "",
      age: "",
      ageStr: "",
      gender: "",
      dob: "",
      orderComplaints: "",
      subject: "", // Subject - Complaints
      height: "",
      weight: "",
      idealWeight: "",
      imt: "",
      smoke: "",
      exercise: "",
      drinkCoffee: "",
      drinkAlcohol: "",
      allergy: "",
      drinkWater2L: "",
      bloodTransfusion: "",
      surgeryOrInpatient: "",
      other: "",
      diagnosis: "", // Assesment
      icdDisease: "",
      icdProcedure: "",
      specialistReference: "",
      labReference: "",
      recommendationNote: "",
      customResep: "",
      patientEmail: "",
      patientPhone: "",
      SIPNumber: "",
      isHealthScreening: "",
      analysis: "",
      carePlan: "",
      labResult: "",
      specialistReferenceName: "",
      prescriptionItems: [],
      isPatientCovid: "",
      isNeedNextEvent: "",
      eventId: "",
      parentEventId: 0,
      sortBy: 0,
    });
    const [addItem, setAddItem] = useState({
      medicine: "",
      qty: "",
      remarks: "",
    });
    const [packageCovid, setPackageCovid] = useState({
      paketA: false,
      paketB: false,
    });
    const [emergencyModal, setEmergencyModal] = useState(false);
    const [selectedDiseases, setSelectedDiseases] = useState([]);
    const [selectedProcedure, setSelectedProcedure] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState([]);
    const [prescriptions, setPrescriptions] = useState([]);
    const [selectedSpecialist, setSelectedSpecialist] = useState([]);
    const [selectedLab, setSelectedLab] = useState([]);
    // const [loadingPage, setLoading] = useState(false);
    const dokterWorklist = useSelector((state) => state.dokterWorklist);
    const myTeam = useSelector((state) => state.myTeam);
    const { state } = useLocation();
    const dispatch = useDispatch();
    let { orderId } = useParams();
    let history = useHistory();

    const initState = () => {
      // setSoap({
      //   // partnerName: "",
      //   // scheduleDate: "",
      //   // scheduleTime: "",
      //   // timezone: "",
      //   // patientName: "",
      //   // age: "",
      //   // gender: "",
      //   // dob: "",
      //   subject: "", // Subject - Complaints
      //   height: "",
      //   weight: "",
      //   idealWeight: "",
      //   imt: "",
      //   diagnosis: "", // Assesment
      //   icdDisease: "",
      //   icdProcedure: "",
      //   specialistReference: "",
      //   labReference: "",
      //   recommendationNote: "",
      //   customResep: "",
      //   patientEmail: "",
      //   patientPhone: "",
      //   SIPNumber: "",
      // });
      setAddItem({
        medicine: "",
        qty: "",
        remarks: "",
      });
    };

    useEffect(() => {
      // console.log("state", state)
      if (state == undefined) {
        Swal.fire({
          icon: "error",
          title: "Oops..",
          text: "Something went wrong. ID Not initialized",
        }).then(() => {
            window.location = "/dokter-worklist";
        });
      }
      if (state == 0) {
        Swal.fire({
          icon: "error",
          title: "Oops..",
          text: "Something went wrong. ID Not initialized",
        }).then(() => {
            window.location = "/dokter-worklist";
        });
      }
      dispatch(getMedicalDetail(state.orderId));
    }, [dispatch]);

    useEffect(() => {
      // let data = dokterWorklist.order
      getSoap(dokterWorklist.order);
      // dispatch(findIcd10(1, "A00"));
    }, [dokterWorklist.order]);

    useEffect(() => {
      dispatch(getSpecialist(1));
      dispatch(getTestLabList());
    }, [dokterWorklist.order]);

    useEffect(() => {
      dispatch(getEventHS(soap.parentEventId, soap.sortBy));
    }, [soap.parentEventId, soap.sortBy]);

    useEffect(() => {
       if ((soap.height && !soap.weight) || (!soap.height && soap.weight) || (!soap.height && !soap.weight)) {
        setSoap({
          ...soap,
          imt: "",
          idealWeight: ""
        });
      }

      if (soap.weight !== "" && soap.height !== "" && (soap.gender !== "" || soap.gender != null) && (Math.abs(soap.height.length - soap.weight.length) <= 1)) {
        const formBmi = new FormData();
        formBmi.append("gender", soap.gender);
        formBmi.append("weight", soap.weight);
        formBmi.append("height", soap.height);
  
        Promise.race([
          dispatch(getBmiAndIdealWeight({
            gender: soap.gender,
            weight: soap.weight,
            height: soap.height,
          }))        
        , timeout(500)])
      } else if (soap.weight !== "" && soap.height !== "" && (soap.gender !== "" || soap.gender != null) && (Math.abs(soap.height.length - soap.weight.length) > 1) && soap.imt && soap.idealWeight) {
        // console.log("prevState", soap.imt, soap.idealWeight)
        dispatch(getBmiAndIdealWeight({
          gender: soap.gender,
          weight: soap.weight,
          height: soap.height,
        }))
      }
     
    }, [soap.gender, soap.weight, soap.height]);
  
    useEffect(() => {
      getBmi(dokterWorklist.bmi, dokterWorklist.idealWeight);
    }, [dokterWorklist.bmi, dokterWorklist.idealWeight]);
  
    const getBmi = (bmi, idealWeight) => {
      setSoap({
        ...soap,
        imt: bmi ? bmi : "",
        idealWeight: idealWeight ? idealWeight : ""
      });
    };

    const { loading, error, order } = dokterWorklist;

    const Loading = () => {
      return (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    };

    const remindPatient = (orderId) => {
  
      var formOrder = new FormData();
      formOrder.append("orderId", orderId);
      dispatch(sendReminderToPatient(formOrder))
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Send Reminder Success.",
            showConfirmButton: true,
          }).then(() => {
            // window.location.reload();
          })
        })
        .catch((err) => {
          // setLoading(false);
          if (err.response) {
            Swal.fire({
              icon: "error",
              text: err.response.data.message,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: err.message,
            });
          }
        });
    }

    const noPatient = (orderId) => {
      var formPatient = new FormData();
      formPatient.append("orderId", orderId);
      formPatient.append("flag", true);
      dispatch(reportNoPatient(formPatient))
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Thank you!",
            text: "Patient not joined has been reported",
            showConfirmButton: true,
            timer: 5000,
          }).then(() => {
            window.location = "/dokter-worklist";
          })
        })
        .catch((err) => {
          if (err.response) {
            Swal.fire({
              icon: "error",
              text: err.response.data.message,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: err.message,
            });
          }
        });
    }

    const fetchIcdDisease = (inputValue, callback) => {
        setTimeout(() => {
          if(inputValue.length >= 2) {
            fetch(
              `${process.env.REACT_APP_API}/App/Hospital/Icd/1?searchKey=${inputValue}`,
              {
                method: "GET",
                headers: {
                  Authorization: window.localStorage.getItem("accessToken") ? `Bearer ${window.localStorage.getItem("accessToken")}` : "",
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
                },
              }
            )
              .then((resp) => {
                return resp.json();
              })
              .then((data) => {
                  // console.log(data)
  
                const tempArray = [];
                if (data.data) {
                  if (data.data.results.length) {
                    data.data.results.forEach((element) => {
                      tempArray.push({
                        // label: `${element.code} - ${element.name}`,
                        // value: element.id,
                        id: element.id,
                        code: element.code,
                        name: element.name
                      });
                    });
                  } 
                  // else {
                  //   tempArray.push({
                  //     label: `${data.message}`,
                  //     value: data,
                  //   });
                  // }
                }
                callback(tempArray);
              })
              .catch((error) => {
                console.log(error, "catch the hoop");
              });
          }
          
        }, 1000);
      };

    const fetchIcdProcedures = (inputValue, callback) => {
        setTimeout(() => {
            if(inputValue.length >= 2) {
              fetch(
                `${process.env.REACT_APP_API}/App/Hospital/Icd/2?searchKey=${inputValue}`,
                {
                  method: "GET",
                  headers: {
                    Authorization: window.localStorage.getItem("accessToken") ? `Bearer ${window.localStorage.getItem("accessToken")}` : "",
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                  },
                }
              )
                .then((resp) => {
                  return resp.json();
                })
                .then((data) => {
                    // console.log(data)
    
                  const tempArray = [];
                  if (data.data) {
                    if (data.data.results.length) {
                      data.data.results.forEach((element) => {
                        tempArray.push({
                          // label: `${element.code} - ${element.name}`,
                          // value: element.id,
                          id: element.id,
                          code: element.code,
                          name: element.name
                        });
                      });
                    } 
                    // else {
                    //   tempArray.push({
                    //     label: `${data.message}`,
                    //     value: data,
                    //   });
                    // }
                  }
                  callback(tempArray);
                })
                .catch((error) => {
                  console.log(error, "catch the hoop");
                });
            }
          
        }, 1000);
      };

    const timeout = delay => new Promise((_, reject) => {
      setTimeout(() => {
        reject({ timeout: true })
      }, delay)
    })
    
    const fetchMedicines = (inputValue, callback) => {
      if(inputValue.length >= 3) {
        Promise.race([fetch(
          `${process.env.REACT_APP_API}/App/Hospital/Medicines?searchKey=${inputValue}`,
          {
            method: "GET",
            headers: {
              Authorization: window.localStorage.getItem("accessToken") ? `Bearer ${window.localStorage.getItem("accessToken")}` : "",
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            },
          }), timeout(1500)])
          .then(response => response.json())
          .then(result => {
            // or do anything else with result
            const tempArray = [];
            if (result.data) {
              if (result.data.medicines.length) {
                result.data.medicines.forEach((element) => {
                  tempArray.push({
                    // label: `${element.code} - ${element.name}`,
                    // value: element.id,
                    id: element.id,
                    item_name: element.item_name,
                    compound: element.compound
                  });
                });
              } 
            }
            callback(tempArray);
          }).catch(err => {
            if (err.timeout) {
              console.log('Request was timeout');
              // or do anything else if request was timeout
            } else {
              console.log(err)
              // if other errors occured
            }
          });
      }
    };

    const handleCloseModal = () => {
      // initState();
      setEmergencyModal(false);
    };

    const handleEmergency = (data) => {
      setEmergencyModal(true);
    };

    const actionEmergency = () => {
      
      const form = new FormData();
      form.append("emergencyInfo", soap.emergencyInfo);
      form.append("orderId", soap.orderId);
      if (soap.medicalRecordId) {
        form.append("medicalRecordId", soap.medicalRecordId);
      } 
      if (soap.diagnosis) {
        form.append("diagnosis", soap.diagnosis);
      }      
      form.append("patientName", soap.patientName);
      form.append("patientPhone", soap.patientPhone);
      form.append("doctorName", soap.partnerName);
      form.append("gender", soap.gender);
      
          dispatch(notifyEmergencyCase(form))
            .then((result) => {
              if (result) {
                dispatch(getMedicalDetail(soap.orderId));
              }
            })
            .then(() => {
              setEmergencyModal(false);
              setTimeout(
                () => Swal.fire("Success!", "Notify Support an Emergency Case Success", "success").then(() => {
                  window.location.reload();
                }),
                1000
              );

            })
            .catch((err) => {
              if (err.response) {
                Swal.fire({
                  icon: "error",
                  text: err.response.data.message,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  text: err.message,
                });
              }
            });
    };

    const renderEmergency = () => {
      return (
        <Modal
          modalTitle="Emergency"
          show={emergencyModal}
          size="lg"
          handleClose={() => handleCloseModal()}
          buttons={[
            {
              label: "Send",
              color: "primary",
              onClick: actionEmergency,
            },
          ]}
        >
          <Row className="pl-3 pr-3">
              
              <TextField
                id="mr-select-emergencyInfo"
                label="Description"
                fullWidth
                multiline
                rows={2}
                placeholder="Input here"
                value={soap.emergencyInfo}
                onChange={(e) => setSoap({
                    ...soap,
                    emergencyInfo: e.target.value,
                })}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                />
          </Row>
        </Modal>
      );
    };

    const getSoap = () => {
      let data = dokterWorklist.order;

      setSoap({
        orderId: data.order_id,
        scheduleId: data.scheduleId,
        patientId: data.patientId,
        medicalRecordId: data.medicalRecordId,
        partnerName: `${data.doctor_firstname ? data.doctor_firstname : data.doctor_name} ${data.doctor_lastname ? data.doctor_lastname : ""}`,
        scheduleDate: data.scheduleDate ? moment(data.scheduleDate).format("MMM D, YYYY") : "",
        startTime: data.startTime && moment(data.startTime, "HH:mm:ss").isValid() ? moment(data.startTime, "HH:mm:ss").format("HH:mm") : "",
        endTime: data.endTime && moment(data.endTime, "HH:mm:ss").isValid() ? moment(data.endTime, "HH:mm:ss").format("HH:mm") : "",
        timezone: data.timezone ? data.timezone : "",
        zoom_url: data.zoom_url,
        patientName: data.patient_name ? data.patient_name : "",
        age: data.age ? data.age : "-",
        ageStr: data.ageStr ? data.ageStr : "-",
        gender: data.gender ? data.gender : "",
        dob: data.patient_dob && moment(data.patient_dob).isValid() ? moment(data.patient_dob).format("LL") : "-",
        orderComplaints: data.order_complaints,
        subject: data.complaints, // Subject - Complaints
        height: data.body_height ? data.body_height.toString() : "",
        weight: data.body_weight ? data.body_weight.toString() : "",
        idealWeight: data.ideal_weight ? data.ideal_weight : "",
        imt: data.imt ? data.imt : "",
        smoke: data.analysis && data.analysis.smoke,
        exercise: data.analysis && data.analysis.exercise,
        drinkCoffee: data.analysis && data.analysis.drinkCoffee,
        drinkAlcohol: data.analysis && data.analysis.drinkAlcohol,
        allergy: data.analysis && data.analysis.allergy,
        drinkWater2L: data.analysis && data.analysis.drinkWater2L,
        bloodTransfusion: data.analysis && data.analysis.bloodTransfusion,
        surgeryOrInpatient: data.analysis && data.analysis.surgeryOrInpatient,
        other: data.analysis && data.analysis.other ? data.analysis.other : "",
        diagnosis: data.diagnosis ? data.diagnosis : "", // Assesment
        // icdDisease: "",
        // icdProcedure: "",
        specialistReference: data.specialist,
        labReference: data.lab_reference,
        recommendationNote: data.recommendationNote,
        customResep: data.customResep ? data.customResep : "",
        patientEmail: data.email,
        patientPhone: data.patient_phone,
        SIPNumber: data.SIPNumber ? data.SIPNumber : "",
        emergencyInfo: data.emergencyInfo ? data.emergencyInfo : "",
        isHealthScreening: data.isHealthScreening ? data.isHealthScreening : 0,
        analysis: data.analysis ? data.analysis : "",
        carePlan: data.carePlan ? data.carePlan : "",
        labResult: data.labResult,
        prescriptionItems: data.prescriptionItems,
        isPatientCovid: data.isPatientCovid == 1 ? true : false,
        isNeedNextEvent: data.isNeedNextEvent,
        eventId: data.eventId,
        parentEventId: data.parentEventId,
        sortBy: data.sortBy,
      });
      // console.log("soap getSoap", soap)
      if ((!soap.imt || !soap.idealWeight) && soap.gender && soap.weight && soap.height) {
        Promise.race([
          dispatch(getBmiAndIdealWeight({
            gender: soap.gender,
            weight: parseFloat(soap.weight),
            height: parseFloat(soap.height),
          }))        
        , timeout(500)])
      }
      setPackageCovid({
        paketA: data.customResep && data.customResep == "A" ? true : false,
        paketB: data.customResep && data.customResep == "B" ? true : false
      });
      setSelectedDiseases(data.icdDiseases);
      setSelectedProcedure(data.icdProcedures && data.icdProcedures.length > 0 ? data.icdProcedures : []);
      setPrescriptions(data.prescriptionItems && data.prescriptionItems.length > 0 ? data.prescriptionItems : []);
      setSelectedSpecialist(data.specialist && data.specialist.id ? data.specialist : null);
      setSelectedLab(data.lab_reference);

    };

    const submitSoap = () => {
      const patternFloat =
      /^[+-]?([0-9]*[.])?[0-9]+$/i;
      if(soap.height && !patternFloat.test(soap.height)) {
        return Swal.fire({
          icon: "warning",
          text: "Height must be a float or number",
        });
      }
      if(soap.weight && !patternFloat.test(soap.weight)) {
        return Swal.fire({
          icon: "warning",
          text: "Weight must be a float or number",
        });
      }
      if(soap.imt && !patternFloat.test(soap.imt)) {
        return Swal.fire({
          icon: "warning",
          text: "Body Mass Index must be a float or number",
        });
      }
      if(soap.idealWeight && !patternFloat.test(soap.idealWeight)) {
        return Swal.fire({
          icon: "warning",
          text: "Ideal Weight must be a float or number",
        });
      }

      if (!soap.diagnosis) {
        return Swal.fire({
          icon: "warning",
          title: "Diagnosis is required!",
        });
      }

      if (!selectedDiseases && !soap.isHealthScreening) {
        return Swal.fire({
          icon: "warning",
          title: "ICD - Disease is required!",
        });
      }

      if (prescriptions && ((!addItem.medicine && addItem.remarks) || (!addItem.medicine && addItem.qty))) {
        return Swal.fire({
          icon: "warning",
          title: "Select medicine first!",
        });
      }

      if (prescriptions && ((addItem.medicine && !addItem.qty && addItem.remarks) || (addItem.medicine && !addItem.qty))) {
        return Swal.fire({
          icon: "warning",
          title: "Input quantity!",
        });
      }

      if (prescriptions && (addItem.medicine && addItem.qty && !addItem.remarks || addItem.medicine && !addItem.remarks)) {
        return Swal.fire({
          icon: "warning",
          title: "Input how to take medicine!",
        });
      }

      if (!selectedEvent && soap.isHealthScreening && soap.isNeedNextEvent) {
        return Swal.fire({
          icon: "warning",
          title: "Select Next Event is required!",
        });
      }

      const form = new FormData();
      form.append("orderId", soap.orderId);
      form.append("scheduleId", soap.scheduleId);
      if(soap.isHealthScreening) {
        form.append("isHealthScreening", soap.isHealthScreening);
      }
      // if(soap.analysis) {
      //   form.append("analysis", soap.analysis);
      // }
      if(soap.carePlan) {
        form.append("carePlan", soap.carePlan);
      }
      if(soap.medicalRecordId) {
        form.append("medicalRecordId", soap.medicalRecordId);
      }
      form.append("subject", soap.subject);
      if(soap.height) {
        form.append("bodyHeight", parseFloat(soap.height));
      }
      if(soap.weight) {
        form.append("bodyWeight", parseFloat(soap.weight));
      }
      if(soap.imt) {
        form.append("bmi", parseFloat(soap.imt));
      }
      if(soap.idealWeight) {
        form.append("idealWeight", parseFloat(soap.idealWeight));
      }
      form.append("smoke", soap.smoke);
      form.append("exercise", soap.exercise);
      form.append("drinkCoffee", soap.drinkCoffee);
      form.append("drinkAlcohol", soap.drinkAlcohol);  
      form.append("allergy", soap.allergy);
      form.append("drinkWater2L", soap.drinkWater2L); 
      form.append("bloodTransfusion", soap.bloodTransfusion);
      form.append("surgeryOrInpatient", soap.surgeryOrInpatient);
      form.append("other", soap.other);
      form.append("diagnosis", soap.diagnosis);
      
      if(selectedDiseases) {
        form.append("icdDisease", JSON.stringify(selectedDiseases));
      }
      if(selectedProcedure) {
        form.append("icdProcedure", JSON.stringify(selectedProcedure));
      }
      if(selectedSpecialist && selectedSpecialist.id) {
        // console.log(selectedSpecialist);
        form.append("specialistReference", selectedSpecialist.id);
        form.append("isReferToSpecialist", 1);
        form.append("specialistReferenceName", selectedSpecialist.name);
      }
      if(selectedLab) {
        form.append("labReference", JSON.stringify(selectedLab));
        form.append("isReferToLab", 1);
      }
      if(soap.recommendationNote) {
        form.append("recommendationNote", soap.recommendationNote);
        form.append("isReferToOtherPlan", 1);
      }
      if(soap.customResep) {
        form.append("customResep", soap.customResep);
      }

      if(selectedEvent) {
        form.append("parentEventId", soap.parentEventId);
        form.append("eventId", soap.eventId);
        form.append("nextEvent", selectedEvent.code);
      }

      if(soap.isNeedNextEvent){
        form.append("isNeedNextEvent", soap.isNeedNextEvent);
      }

      form.append("patientName", soap.patientName);
      form.append("patientEmail", soap.patientEmail);
      form.append("patientPhone", soap.patientPhone);
      form.append("doctorName", soap.partnerName);
      form.append("SIPNumber", soap.SIPNumber);
      form.append("age", soap.age);
      form.append("gender", soap.gender);
      form.append("dob", soap.dob);
      form.append("isPatientCovid", soap.isPatientCovid);

      Swal.fire({
        title: "Reconfirmation",
        text: "Are you sure you want to save the medical records?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#58A8F8",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if(prescriptions) {
            if (addItem.medicine) {
              prescriptions.push(addItem);
              
            }
            form.append("prescriptionItems", JSON.stringify(prescriptions));
          }

          dispatch(createMedicalRecordsV3(form))
            .then((result) => {
              if (result) {
                dispatch(getMedicalDetail(state.orderId));
              }
              // setLoading(false);
              // initState();
              
                  Swal.fire({
                    title: "Success!", 
                    text: `${soap.medicalRecordId ? "Create" : "Update" } Medical Records Success`,
                    icon: "success",
                    timer: 3000,})
                // .then(() => {
                  setTimeout(
                    () => {
                    setAddItem({
                      medicine: "",
                      qty: "",
                      remarks: "",
                    }); 
                    
                  // window.location = "/dokter-worklist";
                  history.push({
                      pathname: "/dokter-worklist",
                      state: { q: state.q }
                    });

                // })
                },
                3000
              );
            })
            .catch((err) => {
              if (err.response) {
                Swal.fire({
                  icon: "error",
                  text: err.response.data.message,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  text: err.message,
                });
              }
            });
        }
      });
    
    };

    const handleDeleteItem = (id) => {
      console.log("prescriptions",prescriptions);

      const newList = prescriptions.filter(item => item.medicine.id != id )
      console.log(newList);
      setPrescriptions(newList);
    }

    const handleAddItem = (data) => {
      const readyForSubmit = () => {
        if (
          addItem.medicine === "" ||
          addItem.medicine === {} ||
          addItem.remarks === "" || 
          addItem.qty === "" || 
          (!addItem.medicine && addItem.remarks)
        ) {
          return false;
        }
        return true;
      };
  
      if (readyForSubmit()) {
        setPrescriptions([
          ...prescriptions,
          {
            medicine: data.medicine,
            qty: data.qty,
            remarks: data.remarks,
          }
        ])

        initState();
      }
    };

    const handleCheck = (event) => {

        if (event.target.name == "paketA") {
          
          setPackageCovid({
            ...packageCovid,
            paketA: event.target.checked,
            paketB: false
          })
          setPrescriptions([
            {
              medicine: {  
                id: 5160,
                item_name: "Multivitamin",
                compound: ""
              },
              qty: "10 TABLET",
              // remarks: "10 TABLET \n1x1",
              remarks: "1x1",
            }
          ]);
          setSoap({
            ...soap,
            customResep: "A",
          })
        } else if (event.target.name == "paketB") {
          setPackageCovid({
            ...packageCovid,
            paketB: event.target.checked,
            paketA: false
          })
          setPrescriptions([
            {
              medicine: {  
                compound: "Favipiravir 200 mg",
                id: 1603,
                item_name: "Favipiravir 200 mg tablet"
                
              },
              qty: "40 TABLET",
              // remarks: "40 TABLET \n"+
              remarks:
              "2X8 TABLET (HARI 1) \n"+
              "2X3 TABLET (HARI 2-5)"
            },
            {
              medicine: {  
                id: 5160,
                item_name: "Multivitamin",
                compound: ""
              },
              qty: "10 TABLET",
              // remarks: "10 TABLET \n1x1",
              remarks: "1x1",
            },
            {
              medicine: {  
                id: 2387,
                item_name: "Paracetamol 500 mg tablet",
                compound: "Paracetamol 500 mg"
  
              },
              qty: "10 TABLET",
              // remarks: "10 TABLET \n1x1",
              remarks: "1x1",
            }
          ]);
          setSoap({
            ...soap,
            customResep: "B",
          })
        }

        if (!event.target.checked){
          setPrescriptions([
          ]);
          setSoap({
            ...soap,
            customResep: "",
          })
        }
    }

    const checkPatientCovid = (e) => {
      let name = e.target.name,
        checked = e.target.checked;
  
        if (checked) {
          setSoap({ ...soap, diagnosis: "Covid-19", isPatientCovid: checked })
        } else {
          setSoap({ ...soap, isPatientCovid: checked })
        }
  
    };

    const listMenu = document.getElementById("/dokter-worklist")
   
    if (listMenu) {
      // console.log("listMenu", listMenu);
      listMenu.style.fontWeight = "bold";
      listMenu.style.backgroundColor = "blueviolet";
    } 
  
    const errorPackage = [packageCovid.paketA, packageCovid.paketB].filter((v) => v).length > 1;

    return (
      <DashboardLayout>
        <Breadcrumb 
          path={{ pathname: "/dokter-worklist", state: { q: state.q }}} 
          menu={"Dokter Worklist"} 
          label={"Teleconsultation Patient Service"}
          />
        <div className="row revenue-title">
          <div className="col">
            <hr />
          </div>

          <div>Teleconsultation Patient Service</div>

          <div className="col">
            <hr />
          </div>
        </div>

        <div className="table-wrapper" id="table-wrapper-mr" 
          // style={{marginBottom: "140px"}}
          >

        {loading ? (
            <Loading />
          ) : error ? (
            error.meesage
          ) : (<>

                
          <div className="row pl-2">
            <div className="col-lg-7 col-md-12 col-sm-12" style={{ paddingBottom: "1em" }}>
                <div className="row">
                  <div className="col-md-5 col-sm-4">
                    <Form.Group as={Row} 
                      className="mb-0 pb-0 pt-0" 
                      controlId="formPlaintextEmail"
                      style={{padding: "0.15rem 0.5rem"}}
                      >     
                      <Form.Label column sm="5" xs="6"
                        className="mr-field text-mr"
                        style={{fontSize: ".775rem", textAlign: "justify", color: "black"}}
                        >
                        Doctor: 
                      </Form.Label>
                      <Col sm="7" xs="6" className="mr-field pl-0">
                        <Form.Control plaintext readOnly disabled 
                          className="text-mr"
                          value={soap.partnerName}
                          style={{fontSize: ".775rem", textAlign: "right", color: "black", lineHeight: 1}}
                          />
                      </Col>
                    </Form.Group>
                  </div>
                  <div className="col-md-7 col-sm-8">
                    <Form.Group as={Row} 
                      className="mb-0 pb-0 pt-0" 
                      controlId="formPlaintextEmail"
                      style={{padding: "0.15rem 0.5rem", borderRadius: "20px"}}
                      >     
                      <Form.Label column sm="5" xs="6"
                        className="mr-field text-mr"
                        style={{fontSize: ".775rem", textAlign: "justify", color: "black", overflow: "visible"}}
                        >
                        Schedule Time: 
                      </Form.Label>
                      <Col sm="7" xs="6" className="mr-field pl-0">
                        <Form.Control plaintext readOnly disabled 
                          className="text-mr"
                          value={`${soap.scheduleDate} ${soap.startTime ? "at" : ""} ${soap.startTime}-${soap.endTime} ${soap.timezone}`}
                          style={{fontSize: ".775rem", textAlign: "right", color: "black", lineHeight: 1, wordWrap: "break-word", wordBreak: "keep-all"}}
                          />
                      </Col>
                    </Form.Group>
                  </div>
                </div>
         
              </div>
            <div className="col-lg-5 col-md-12 col-sm-12" style={{ paddingTop: "1px", paddingBottom: "1em", paddingLeft: "0.40rem" }}>
              <div className="mr-button-group">
           
                <button
                  type="button"
                  className="btn btn-sm btn-dark w-100 text-mr"
                  style={{fontSize: ".775rem"}}
                  onClick={() => noPatient(soap.orderId)}
                >
                  No Patient
                </button>
             
                <button
                  type="button"
                  className="btn btn-sm btn-info w-100 text-mr"
                  style={{fontSize: ".775rem"}}
                  
                  onClick={() => remindPatient(soap.orderId)}
                >
                  Remind Patient
                </button>
               
                <a className="w-100" href={soap.zoom_url} target="_blank">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary w-100 text-mr"
                    style={{fontSize: ".775rem"}}
                  > 
                    Join Meeting
                  </button>
                  </a>

              </div>
            </div>
          </div>

          <div className="row pl-2 justify-content-space-between">
            <div className="col-lg-5 col-md-6">
              <Form.Group as={Row} 
                className="mb-0 pb-0 pt-0" 
                controlId="formPlaintextEmail"
                style={{padding: "0.15rem 0.5rem"}}
                >     
                <Form.Label column xl="4" md="5" sm="4" xs="6"
                  className="text-mr"
                  style={{fontSize: ".775rem", textAlign: "justify", color: "black"}}
                  >
                  Patient Name: 
                </Form.Label>
                <Col xl="7" md="7" sm="8" xs="6" className="mr-field">
                  <Form.Control plaintext readOnly disabled 
                    className="text-mr"
                    value={soap.patientName}
                    style={{fontSize: ".775rem", textAlign: "justify", color: "black", lineHeight: 1}}
                    />
                </Col>
              </Form.Group>
            </div>

            <div className="col-lg-2 col-md-6">

              <Form.Group as={Row} 
                className="mb-0 pb-0 pt-0" 
                controlId="formPlaintextEmail"
                style={{padding: "0.15rem 0.5rem"}}
                >     
                <Form.Label column xl="4" md="4" sm="4" xs="6"
                  className="text-mr"
                  style={{fontSize: ".775rem", textAlign: "justify", color: "black"}}
                  >
                  Age: 
                </Form.Label>
                <Col xl="8" md="8" sm="8" xs="6" className="mr-field">
                  <Form.Control plaintext readOnly disabled 
                    className="text-mr"
                    value={(soap.age ? soap.age : "-") + " tahun"}
                    style={{fontSize: ".775rem", textAlign: "justify", color: "black", lineHeight: 1}}
                    />
                </Col>
              </Form.Group>

            </div>

            <div className="col-lg-3 col-md-6">
              <Form.Group as={Row} 
                className="mb-0 pb-0 pt-0" 
                controlId="formPlaintextEmail"
                style={{padding: "0.15rem 0.5rem"}}
                >     
                <Form.Label column xl="4" md="5" sm="4" xs="6"
                  className="text-mr"
                  style={{fontSize: ".775rem", textAlign: "justify", color: "black"}}
                  >
                  Gender: 
                </Form.Label>
                <Col xl="8" md="7" sm="8" xs="6" className="mr-field">
                  <Form.Control plaintext readOnly disabled 
                    className="text-mr"
                    value={soap.gender}
                    style={{fontSize: ".775rem", textAlign: "justify", color: "black", lineHeight: 1}}
                    />
                </Col>
              </Form.Group>
            </div>

            <div className="col-lg-2 col-md-3">
              <Link
                  to={{ pathname: "/dokter-worklist/medical-records", state: { patientId: soap.patientId, orderId: soap.orderId, q: state.q }}}
                >
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary w-100 text-mr"
                    style={{ fontSize: "0.775rem"}}
                  >
                    Medical Records
                  </button>
                </Link>
            </div>
          </div>

          <div className="mr-container">
            {/* <div> */}
              <button 
                className="btn btn-sm btn-danger justify-content-flex-end text-mr mt-1 pl-4 pr-4" 
                style={{ float: "right", fontSize: "0.775rem" }}
                onClick={() => handleEmergency(soap)}
                >
                  Emergency
                </button>
                {soap.labResult ? (
                  <a href={soap.labResult} target="_blank"
                    >
                    <button 
                      className="btn btn-sm btn-outline-info justify-content-flex-end text-mr mt-1 pl-4 pr-4 mr-3" 
                      style={{ float: "right", fontSize: "0.775rem" }}
                      >Lab Result </button>
                  </a>
                ) : (<></>)}
                
             
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChangeTab} aria-label="patient-service" 
                  //   className={classes.root}
                    >
                      <Tab label="Assesment" {...a11yProps(0)} className="mr-tab-label"/>
                      <Tab label="Prescription" {...a11yProps(1)} className="mr-tab-label"/>
                      <Tab label="Plan (Recommendation)" {...a11yProps(2)} className="mr-tab-label"/>
                      
                  </Tabs>
              </Box>
              <div className="table-wrapper">
                  <TabPanel value={value} index={0}>
                      <Row className="mb-3">
                          <Col md>
                              <label>Patient Complaints</label>
                              <TextField
                                  id="filled-multiline-flexible"
                                  // label="Complaints"
                                  hiddenLabel
                                  InputLabelProps={<InputLabel label="complaints" variant="outlined"/>}
                                  multiline
                                  maxRows={4}
                                  rows={3}
                                  fullWidth
                                  value={soap.orderComplaints ? soap.orderComplaints : "-"}
                                  onChange={(e) => setSoap({
                                      ...soap,
                                      orderComplaints: e.target.value,
                                  })}
                                  variant="filled"
                                  sx={{fontFamily: "Source Sans Pro"}}
                                  inputProps={{
                                    readOnly: true,
                                    autoFocus: false,
                                    disabled: true,
                                  }}
                                  />
                                  
                          </Col>
                          <Col md>
                              <label>Subject (Complaints)</label>
                              <TextField
                                  id="filled-multiline-flexible"
                                  // label="Complaints"
                                  hiddenLabel
                                  InputLabelProps={<InputLabel label="complaints" variant="outlined"/>}
                                  multiline
                                  placeholder="Input Complaints"
                                  maxRows={4}
                                  rows={3}
                                  fullWidth
                                  value={soap.subject}
                                  onChange={(e) => setSoap({
                                      ...soap,
                                      subject: e.target.value,
                                  })}
                                  variant="filled"
                                  sx={{fontFamily: "Source Sans Pro"}}
                                  />
                                  
                          </Col>
                      </Row>
                          <>
                            <label>Object (Analysis)</label>
                            <Row className="mb-0 mt-1">
                                <Col md="3" xs="6" className="mb-2">
                                      <TextField
                                        id="mr-height"
                                        label="Height (Cm)"
                                        variant="outlined"
                                        value={soap.height}
                                        onChange={(e) => setSoap({
                                            ...soap,
                                            height: e.target.value,
                                        })}
                                        fullWidth
                                        size="small"
                                        InputLabelProps={{
                                          shrink: true,
                                          disableAnimation: true,
                                        }}
                                        />
                                </Col>
                                <Col md="3" xs="6" className="mb-2">
                                      <TextField
                                        id="mr-weight"
                                        label="Weight (Kg)"
                                        variant="outlined"
                                        value={soap.weight}
                                        onChange={(e) => setSoap({
                                            ...soap,
                                            weight: e.target.value,
                                        })}
                                        sx={{fontSize: 8}}
                                        fullWidth
                                        size="small"
                                        InputLabelProps={{
                                          shrink: true,
                                          disableAnimation: true,
                                        }}
                                        />
                                </Col>
                            
                                <Col md="3" xs="6" className="mb-2">
                                      <TextField                                            
                                        id="mr-imt"
                                        label={<label>BMI (Kg/M<sup>2</sup>)</label>}
                                        variant="outlined"
                                        value={soap.imt}
                                        onChange={(e) => setSoap({
                                            ...soap,
                                            imt: e.target.value,
                                        })}
                                        sx={{fontSize: 8}}
                                        fullWidth
                                        size="small"
                                        InputLabelProps={{
                                          shrink: true,
                                          disableAnimation: true,
                                        }}
                                        />
                                </Col>
                                <Col md="3" xs="6" className="mb-2">         
                                      <TextField                                        
                                        id="mr-idealWeight"
                                        label={<label>Ideal Weight (Kg)</label>}
                                        variant="outlined"
                                        value={soap.idealWeight}
                                        onChange={(e) => setSoap({
                                            ...soap,
                                            idealWeight: e.target.value,
                                        })}
                                        sx={{fontSize: 8}}
                                        fullWidth
                                        size="small"
                                        InputLabelProps={{
                                          shrink: true,
                                          disableAnimation: true,
                                        }}
                                        />
                                </Col>
                            </Row>
                            <Row className="mb-0 pr-3">
                              <Col md={3} sm={6}>
                                  <label className="mr-label" 
                                    >Smoke</label>
                                  <RadioGroup
                                    row
                                    id="mr-rokok"
                                    className="p-1"
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={soap.smoke}
                                    onChange={(e) => setSoap({
                                      ...soap,
                                      smoke: e.target.value,
                                    })}
                                  >
                                    <FormControlLabel className="text-mr mr-object" value={1} control={<Radio size="small" />} label="Yes" />
                                    <FormControlLabel className="text-mr mr-object" value={0} control={<Radio size="small" />} label="No" />
                                  </RadioGroup>
                              </Col>
                              <Col md={3} sm={6}>
                                  <label className="mr-label">Exercise</label>
                                  <RadioGroup
                                    row
                                    id="mr-olahraga"
                                    className="p-1"
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={soap.exercise}
                                    onChange={(e) => setSoap({
                                      ...soap,
                                      exercise: e.target.value,
                                    })}
                                  >
                                    <FormControlLabel className="text-mr mr-object" value={1} control={<Radio size="small" />} label="Yes" />
                                    <FormControlLabel className="text-mr mr-object" value={0} control={<Radio size="small" />} label="No" />
                                  </RadioGroup>
                              </Col>
                              <Col md={3} sm={6}>
                                  <label className="mr-label">Drink Coffee</label>
                                  <RadioGroup
                                    row
                                    id="mr-kopi"
                                    className="p-1"
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={soap.drinkCoffee}
                                    onChange={(e) => setSoap({
                                      ...soap,
                                      drinkCoffee: e.target.value,
                                    })}
                                  >
                                    <FormControlLabel className="text-mr mr-object" value={1} control={<Radio size="small" />} label="Yes" />
                                    <FormControlLabel className="text-mr mr-object" value={0} control={<Radio size="small" />} label="No" />
                                  </RadioGroup>
                              </Col>
                              <Col md={3} sm={6}>
                                  <label className="mr-label">Drink Alcohol</label>
                                  <RadioGroup
                                    row
                                    id="mr-alkohol"
                                    className="p-1"
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={soap.drinkAlcohol}
                                    onChange={(e) => setSoap({
                                      ...soap,
                                      drinkAlcohol: e.target.value,
                                    })}
                                  >
                                    <FormControlLabel className="text-mr mr-object" value={1} control={<Radio size="small" />} label="Yes" />
                                    <FormControlLabel className="text-mr mr-object" value={0} control={<Radio size="small" />} label="No" />
                                  </RadioGroup>
                              </Col>
                            </Row>
                            <Row className="mb-0 pr-3">
                              <Col md={3} sm={6}>
                                <label className="mr-label">Drink Water 2L/day</label>
                                <RadioGroup
                                  row
                                  id="mr-drinkWater"
                                  className="p-1"
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={soap.drinkWater2L}
                                  onChange={(e) => setSoap({
                                    ...soap,
                                    drinkWater2L: e.target.value,
                                  })}
                                >
                                  <FormControlLabel className="text-mr mr-object" value={1} control={<Radio size="small" />} label="Yes" />
                                  <FormControlLabel className="text-mr mr-object" value={0} control={<Radio size="small" />} label="No" />
                                </RadioGroup>
                              </Col>
                              <Col md={3} sm={6}>
                                <label className="mr-label">Allergy</label>
                                <RadioGroup
                                  row
                                  id="mr-alergi"
                                  className="p-1"
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={soap.allergy}
                                  onChange={(e) => setSoap({
                                    ...soap,
                                    allergy: e.target.value,
                                  })}
                                >
                                  <FormControlLabel className="text-mr mr-object" value={1} control={<Radio size="small" />} label="Yes" />
                                  <FormControlLabel className="text-mr mr-object" value={0} control={<Radio size="small" />} label="No" />
                                </RadioGroup>
                              </Col>
                              <Col md={3} sm={6}>
                                <label className="mr-label">Blood Transfusion</label>
                                <RadioGroup
                                  row
                                  id="mr-bloodTransfusion"
                                  className="p-1"
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={soap.bloodTransfusion}
                                  onChange={(e) => setSoap({
                                    ...soap,
                                    bloodTransfusion: e.target.value,
                                  })}
                                >
                                  <FormControlLabel className="text-mr mr-object" value={1} control={<Radio size="small" />} label="Yes" />
                                  <FormControlLabel className="text-mr mr-object" value={0} control={<Radio size="small" />} label="No" />
                                </RadioGroup>
                              </Col>
                              <Col md={3} sm={6}>
                                <label className="mr-label">Surgery / Inpatient</label>
                                <RadioGroup
                                  row
                                  id="mr-surgery"
                                  className="p-1"
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={soap.surgeryOrInpatient}
                                  onChange={(e) => setSoap({
                                    ...soap,
                                    surgeryOrInpatient: e.target.value,
                                  })}
                                >
                                  <FormControlLabel className="text-mr mr-object" value={1} control={<Radio size="small" />} label="Yes" />
                                  <FormControlLabel className="text-mr mr-object" value={0} control={<Radio size="small" />} label="No" />
                                </RadioGroup>
                              </Col>
                          </Row>
                          <Row className="mb-2 pl-3 pr-3">
                           
                              <TextField                                        
                                  id="mr-analysis"
                                  label={<label>Other</label>}
                                  variant="outlined"
                                  multiline
                                  maxRows={3}
                                  rows={2}
                                  value={soap.other}
                                  onChange={(e) => setSoap({
                                    ...soap,
                                    other: e.target.value,
                                  })}
                                  fullWidth
                                  size="small"
                                  InputLabelProps={{
                                    shrink: true,
                                    disableAnimation: true,
                                  }}
                                  />
                                      
                          </Row>
                          {/* <Row className="mt-3" style={{justifyContent: "space-between"}}>
                            <label className="ml-3">Assesment (Diagnosis)<span className="text-danger">*</span></label>
                            <FormGroup
                              row
                              id="mr-alergi"
                              className="p-1"
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox checked={soap.isPatientCovid} onChange={checkPatientCovid} name="isPatientCovid" sx={{ padding: 0, fontSize: "1rem", fontFamily: "Source Sans Pro", paddingRight: "5px" }}/>
                                  }
                                  label="Covid-19"
                                  className="mr-label"
                                  sx={{ fontSize: "1rem", fontFamily: "Source Sans Pro"}}
                                />
                            </FormGroup>
                          </Row> */}
                          <Row className="mt-3 mb-1 pr-3 pl-3">
                            <label>Assesment (Diagnosis)<span className="text-danger">*</span></label>
                            <TextField
                              id="filled-multiline-flexible"
                              hiddenLabel
                              InputLabelProps={<InputLabel label="complaints" variant="outlined"/>}
                              multiline
                              maxRows={3}
                              rows={2}
                              fullWidth
                              value={soap.diagnosis}
                              placeholder="Input Diagnosis"
                              onChange={(e) => setSoap({
                                  ...soap,
                                  diagnosis: e.target.value,
                              })}
                              variant="filled"
                                />

                            <FormGroup
                              row
                              id="mr-alergi"
                              className="p-1 ml-1 mt-1"
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox checked={soap.isPatientCovid} onChange={checkPatientCovid} name="isPatientCovid" sx={{ padding: 0, fontSize: "1rem", fontFamily: "Source Sans Pro", paddingRight: "5px" }}/>
                                  }
                                  label="Covid-19"
                                  className="mr-label"
                                  sx={{ fontSize: "1rem", fontFamily: "Source Sans Pro"}}
                                />
                            </FormGroup>
                          </Row>
                            
                         
                          </>

                      <Row style={{ padding: "13.9px", paddingTop: "8px"}}>
                          <label>ICD - Disease{ !soap.isHealthScreening ? <span className="text-danger">*</span> : <></>}</label>
                          <AsyncSelect
                              id="mr-select-diseases"
                              isMulti
                              isClearable
                              cacheOptions
                              defaultOptions={dokterWorklist.icd}
                              loadOptions={fetchIcdDisease}
                              getOptionValue={(option) => option.id}
                              getOptionLabel={
                                  option => `${option.code != undefined ? `${option.code} - ${option.name}` : ""}`
                              }
                              onChange={setSelectedDiseases}
                              closeMenuOnSelect={false}
                              // getValue={(option) => option.value }
                              placeholder="Type at least 2 characters to display the ICD options"
                              value={selectedDiseases}
                              maxMenuHeight={200}
                          />
                        
                      </Row>

                      <Row style={{ padding: "13.9px", paddingTop: 0}}>
                          <label>ICD - Procedure</label>
                          <AsyncSelect
                              id="mr-select-procedures"
                              isMulti
                              isClearable
                              cacheOptions
                              defaultOptions={dokterWorklist.results}
                              loadOptions={fetchIcdProcedures}
                              getOptionValue={(option) => option.id}
                              getOptionLabel={
                                  option => `${option.code} - ${option.name}`
                              }
                              onChange={setSelectedProcedure}
                              value={selectedProcedure}
                              maxMenuHeight={200}
                              placeholder="Type at least 2 characters to display the ICD options"

                          />
                        
                      </Row>

                      {
                        soap.isHealthScreening && soap.isNeedNextEvent ? 
                        (<Row style={{ padding: "13.9px", paddingTop: 0}}>
                            <label>Event List <span className="text-danger">*</span> </label>

                            <Select
                              isClearable
                              id="mr-select-event"
                              onChange={setSelectedEvent}
                              options={dokterWorklist.eventList}
                              getOptionValue={(option) => option.code}
                              getOptionLabel={
                                  option => option.name
                              }
                              placeholder="Select Next Event"
                              value={selectedEvent}
                            />
                          
                        </Row>
                        ) : (<></>)
                      }
                  
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Row>
                      <Col md={12} sm={12}>
                        <FormControl
                          error={errorPackage}
                          component="fieldset"
                          // sx={{ m: 3 }}
                          variant="standard"
                        >
                          <FormLabel sx={{ color: "#000", fontSize: "1rem", fontFamily: "Source Sans Pro", marginBottom: 0}}>Prescription for COVID</FormLabel>
                          <FormGroup
                             row
                             id="mr-alergi"
                             className="p-1"
                             aria-labelledby="demo-row-radio-buttons-group-label"
                             name="row-radio-buttons-group"
                            >
                            <FormControlLabel
                              control={
                                <Checkbox checked={packageCovid.paketA} onChange={(e) => handleCheck(e)} name="paketA" />
                              }
                              label="Package A"
                              className="mr-label"
                              sx={{ fontSize: "1rem", fontFamily: "Source Sans Pro"}}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox checked={packageCovid.paketB} onChange={(e) => handleCheck(e)} name="paketB" />
                              }
                              label="Package B"
                              className="mr-label"
                              sx={{ fontSize: "1rem", fontFamily: "Source Sans Pro"}}
                            />
                          </FormGroup>
                        </FormControl>
                      </Col>
                    </Row>
                    {
                      prescriptions && prescriptions.length > 0 ? 
                      prescriptions.map((prescription, index) => (
                      <div key={index} className="mb-2">
                          <Row style={{ padding: "13.9px", paddingTop: 0}}>
                             
                                <Stack
                                  direction="row" alignItems="center" spacing={1} justifyContent="space-between"
                                  sx={{width: "100%"}}
                                  ><label>Medicine</label>
                                  {
                                    (  ( packageCovid.paketA && (prescription.medicine.id == 5160)) || 
                                       ( (packageCovid.paketB && ( (prescription.medicine.id == 5160) || 
                                                                    (prescription.medicine.id == 1603) || 
                                                                    (prescription.medicine.id == 2387) )) ) ) ?
                                       <></> : <IconButton 
                                       color="inherit" 
                                       aria-label="delete" 
                                       component="span"
                                       onClick={() => handleDeleteItem(prescription.medicine.id)}
                                       size="small"
                                       className="mr-trash"
                                       disabled={soap.packageCovid ? true : false}
                                       >
                                       <i className="fa fa-trash" style={{color: "rgba(0, 0, 0, 0.54)"}}></i>
                                     </IconButton>
                                    }
                                  </Stack>
                              
                              <Select
                                  className="mr-medicine"
                                  getOptionValue={(option) => option}
                                  getOptionLabel={
                                      option => `${option.item_name} ${option.compound ? "- "+option.compound : ""}`
                                  }
                                  isDisabled
                                  value={prescription.medicine}
                                  maxMenuHeight={200}
                                  placeholder="Type at least 3 characters to display the medicine options"
                                  InputLabelProps={{
                                    shrink: true,
                                    disableAnimation: true,
                                  }}

                              />
                            
                          </Row>
                          <Row className="mb-4">
                            <Col>
                              <label>Quantity</label>
                              <TextField
                                  id="filled-multiline-flexible"
                                  // label="Medicine Detail"
                                  hiddenLabel
                                  InputLabelProps={<InputLabel label="quantity" variant="outlined"/>}
                                  multiline
                                  rows={2}
                                  fullWidth
                                  placeholder="Input quantity"
                                  value={prescription.qty}
                                  variant="filled"
                                  sx={{fontFamily: "Source Sans Pro"}}
                                  inputProps={{
                                    readOnly: true,
                                    autoFocus: false,
                                    disabled: true,
                                  }}
                                  />
                            </Col>
                            <Col>
                              <label>How to take medicine</label>
                      
                              <TextField
                                  id="filled-multiline-flexible"
                                  // label="Medicine Detail"
                                  hiddenLabel
                                  InputLabelProps={<InputLabel label="complaints" variant="outlined"/>}
                                  multiline
                                  rows={2}
                                  fullWidth
                                  placeholder="Input how to take medicine"
                                  value={prescription.remarks}
                                  variant="filled"
                                  sx={{fontFamily: "Source Sans Pro"}}
                                  inputProps={{
                                    readOnly: true,
                                    autoFocus: false,
                                    disabled: true,
                                  }}
                                  />
                            </Col>
                          </Row>
                        </div>
                        )) : <></>
                      
                    }
                        <Row style={{ padding: "13.9px", paddingTop: 0}}>
                            <label>Medicine</label>
                            <AsyncSelect
                                id="mr-select-medicines"
                                className="mr-medicine"
                                isClearable
                                cacheOptions
                                defaultOptions={dokterWorklist.medicines}
                                loadOptions={fetchMedicines}
                                getOptionValue={(option) => option}
                                getOptionLabel={
                                    option => `${option.item_name} ${option.compound ? "| "+option.compound : ""}`
                                }
                                onChange={
                                  (e) => {
                                  setAddItem({
                                    ...addItem,
                                    medicine: e
                                  })
                                }
                                }
                                value={addItem.medicine}
                                maxMenuHeight={200}
                                placeholder="Type at least 3 characters to display the medicine options"

                            />
                          
                        </Row>
                        <Row className="mb-4">
                          <Col>
                            <label>Quantity</label>
                            <TextField
                              id="filled-multiline-flexible"
                              // label="Medicine Detail"
                              hiddenLabel
                              InputLabelProps={<InputLabel label="quantity" variant="outlined"/>}
                              multiline
                              rows={2}
                              fullWidth
                              placeholder="Input quantity"
                              value={addItem.qty}
                              onChange={(e) => {
                                setAddItem({
                                  ...addItem,
                                  qty: e.target.value
                                })
                              }}
                              variant="filled"
                              sx={{fontFamily: "Source Sans Pro"}}
                              error={!(addItem && addItem.medicine) && addItem.qty ? true : ((addItem && addItem.medicine && !addItem.qty && addItem.remarks) ? true : false)}
                              helperText={!(addItem && addItem.medicine) && addItem.qty ? "Select medicine first." : ((addItem && addItem.medicine && !addItem.qty && addItem.remarks) ? "Input quantity" : false)}
                              />
                          </Col>
                          <Col>
                            <label>How to take medicine</label>
                  
                            <TextField
                                id="filled-multiline-flexible"
                                // label="Medicine Detail"
                                hiddenLabel
                                InputLabelProps={<InputLabel label="complaints" variant="outlined"/>}
                                multiline
                                rows={2}
                                fullWidth
                                placeholder="Input how to take medicine"
                                value={addItem.remarks}
                                onChange={(e) => {
                                  setAddItem({
                                    ...addItem,
                                    remarks: e.target.value
                                  })
                                }}
                                variant="filled"
                                sx={{fontFamily: "Source Sans Pro"}}
                                error={!(addItem && addItem.medicine) && addItem.remarks ? true : false}
                                helperText={!(addItem && addItem.medicine) && addItem.remarks ? "Select medicine first." : false}
                                />
                          </Col>
                      </Row>
                        <button
                          id="add-medicine-btn"
                          className="btn btn-sm btn-outline-primary pl-3 pr-3"
                          type="button"
                          // style={{ width: "100%" }}
                          onClick={() => handleAddItem(addItem)}
                        >
                          <i className="fa fa-plus-circle mr-2"></i>
                          <span>Add Medicine</span>
                        </button>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                      <Row style={{ padding: "13.9px", paddingTop: 0}}>
                        
                        <label>Specialist</label>
                        <Select
                            isClearable
                            id="mr-select-specialist"
                            onChange={setSelectedSpecialist}
                            options={myTeam.specialist}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={
                                option => option.name
                            }
                            placeholder="Select Specialist"
                            value={selectedSpecialist}
                          />
                        
                      </Row>
                      <Row style={{ padding: "13.9px", paddingTop: 0}}>
                        
                        <label>Laboratorium</label>
                        <CreatableSelect
                            id="mr-select-lab"
                            isMulti
                            onChange={setSelectedLab}
                            options={dokterWorklist.testLabList}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={
                                option => `${option.test_name} ${option.description ? ("("+option.description+")") : ""}`
                            }
                            placeholder="Select Lab"
                            value={selectedLab}
                          />
                        
                      </Row>
                      <Row style={{ padding: "13.9px", paddingTop: 0}}>
                        <label>Other</label>
                          <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Input other recommendation e.g. Chest Radiograph" 
                            value={soap.recommendationNote} 
                            onChange={(e) => setSoap({
                              ...soap,
                              recommendationNote: e.target.value,
                            })}
                            />
                      </Row>

                      { soap.isHealthScreening ? (
                        <Row style={{ padding: "13.9px", paddingTop: 0}}>
                          <label>Care Plan</label>
                          <textarea
                            type="text" 
                            rows="3" 
                            className="form-control" 
                            placeholder="Input care plan here" 
                            value={soap.carePlan} 
                            onChange={(e) => setSoap({
                              ...soap,
                              carePlan: e.target.value,
                            })}
                            />
                        </Row>
                        ) : <></>}
                  </TabPanel>

              </div>
              <Row style={{ justifyContent: "flex-end", padding: "10px" }}>
                {/* <button
                  type="button"
                  className="btn btn-danger button-footer"
                  onClick={() => handleEmergency(soap)}
                >
                  Emergency
                </button> */}
                <button
                  type="button"
                  className={"btn btn-primary button-footer submit-mr"}
                  onClick={submitSoap}
                >
                  Submit
                </button>

              </Row>
          </div>
          </>)}

        </div>
        {renderEmergency()}
        
      </DashboardLayout>
    );
};

export default MedicalRecords;