import { paymentOfflineConstants } from "../actions/constants";

const initState = {
  data: [],
  totalData: "",
  userData: {
    patients: [],
    users: {},
  },
  patientDetail: [],
  addressData: [],
  country: [],
  province: [],
  city: [],
  district: [],
  subdistrict: [],
  postalcode: [],
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case paymentOfflineConstants.FIND_PATIENT_DETAIL_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case paymentOfflineConstants.FIND_PATIENT_DETAIL_SUCCESS:
      state = {
        ...state,
        patientDetail: action.payload.patientDetail,
        loading: false,
      };
      break;
    case paymentOfflineConstants.FIND_PATIENT_DETAIL_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case paymentOfflineConstants.GET_ADDRESS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case paymentOfflineConstants.GET_ADDRESS_SUCCESS:
      state = {
        ...state,
        addressData: action.payload.addressData,
        loading: false,
      };
      break;
    case paymentOfflineConstants.GET_ADDRESS_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case paymentOfflineConstants.SUBMIT_PAYMENT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case paymentOfflineConstants.SUBMIT_PAYMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case paymentOfflineConstants.SUBMIT_PAYMENT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case paymentOfflineConstants.GET_COUNTRY_REQUEST:
      state = {
        ...state,
      };
      break;
    case paymentOfflineConstants.GET_COUNTRY_SUCCESS:
      state = {
        ...state,
        country: action.payload,
      };
      break;
    case paymentOfflineConstants.GET_COUNTRY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
      };
      break;
    case paymentOfflineConstants.GET_STATE_REQUEST:
      state = {
        ...state,
      };
      break;
    case paymentOfflineConstants.GET_STATE_SUCCESS:
      state = {
        ...state,
        province: action.payload,
      };
      break;
    case paymentOfflineConstants.GET_STATE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
      };
      break;
    case paymentOfflineConstants.GET_CITY_REQUEST:
      state = {
        ...state,
      };
      break;
    case paymentOfflineConstants.GET_CITY_SUCCESS:
      state = {
        ...state,
        city: action.payload,
      };
      break;
    case paymentOfflineConstants.GET_CITY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
      };
      break;
    case paymentOfflineConstants.GET_DISTRICT_REQUEST:
      state = {
        ...state,
      };
      break;
    case paymentOfflineConstants.GET_DISTRICT_SUCCESS:
      state = {
        ...state,
        district: action.payload,
      };
      break;
    case paymentOfflineConstants.GET_DISTRICT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
      };
      break;
    case paymentOfflineConstants.GET_SUBDISTRICT_REQUEST:
      state = {
        ...state,
      };
      break;
    case paymentOfflineConstants.GET_SUBDISTRICT_SUCCESS:
      state = {
        ...state,
        subdistrict: action.payload,
      };
      break;
    case paymentOfflineConstants.GET_SUBDISTRICT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
      };
      break;
    case paymentOfflineConstants.GET_POSTALCODE_REQUEST:
      state = {
        ...state,
      };
      break;
    case paymentOfflineConstants.GET_POSTALCODE_SUCCESS:
      state = {
        ...state,
        postalcode: action.payload,
      };
      break;
    case paymentOfflineConstants.GET_POSTALCODE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
      };
      break;
    case paymentOfflineConstants.GET_REGISTERPATIENT_LIST_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case paymentOfflineConstants.GET_REGISTERPATIENT_LIST_SUCCESS:
      state = {
        ...state,
        data: action.payload.data,
        totalData: action.payload.totalData,
        loading: false,
      };
      break;
    case paymentOfflineConstants.GET_REGISTERPATIENT_LIST_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
  }
  return state;
};
