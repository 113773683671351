import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addExcessPayment,
  getCompletedDetail,
  uploadInvoiceBilling,
} from "../../../../actions";
import DashboardLayout from "../../Layout";
import Input from "../../Components/Input";
import Modal from "../../Components/Modal";
import { Col, Form, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";

const WaitingInvoiceDetail = () => {
  const [excessData, setExcessData] = useState({
    isStruk: "0",
    isPharmacy: "1",
    totalFee: "",
    price: "",
    isSendAvailable: 0,
    insuranceDoc: [],
  });
  const [invoiceDoc, setInvoiceDoc] = useState([]);
  const [category, setCategory] = useState("");
  const [fileName, setFileName] = useState({
    invoiceName: "Upload File",
    insuranceName: "Upload File",
  });
  const [addExcessModal, setAddExcessModal] = useState(false);
  const [uploadInvoiceModal, setUploadInvoiceModal] = useState(false);
  const waitingInvoice = useSelector((state) => state.waitingInvoice);
  const { state } = useLocation();
  const dispatch = useDispatch();

  const [checkId, setCheckId] = useState({});

  const initState = () => {
    setExcessData({
      isStruk: "0",
      isPharmacy: "1",
      totalFee: "",
      price: "",
      isSendAvailable: 0,
      insuranceDoc: [],
    });
    setInvoiceDoc([]);
    setCategory("");
    setFileName({
      invoiceName: "Upload File",
      insuranceName: "Upload File",
    });
  };

  useEffect(() => {
    dispatch(getCompletedDetail(state));
  }, []);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const handleCheck = (e) => {
    let name = e.target.name,
      checked = e.target.checked;

      setExcessData({ ...excessData, isSendAvailable: checked })

      setCheckId((prevState) => {
        const newState = { ...prevState };
        newState[name] = !prevState[name];
        return newState;
      });
  };

  const handleCloseModal = () => {
    initState();
    setAddExcessModal(false);
    setUploadInvoiceModal(false);
  };

  const handleAddExcess = () => {
    setCategory("struk");
    setAddExcessModal(true);
  };

  const actionAddExcess = () => {
    const form = new FormData();

    form.append("isStruk", excessData.isStruk);
    form.append("isPharmacy", excessData.isPharmacy);

    if (excessData.totalFee == "") {
      form.append("totalFee", 0);
    } else {
      form.append("totalFee", excessData.totalFee);
    }
    if (excessData.price == "") {
      form.append("price", 0);
    } else {
      form.append("price", excessData.price);
    }
    form.append("category", category);
    form.append("struk", excessData.insuranceDoc);
    form.append("isSendAvailable", excessData.isSendAvailable);
    // for (var pair of form.entries()) {
    //   console.log(pair[0], pair[1]);
    // }
    var flagExcess = false;
    if ( waitingInvoice.details.order.orderPharmacy && waitingInvoice.details.order.orderPharmacy.length > 0) {  
      flagExcess = true;
    }

    var messagesConfirm;
    (flagExcess && (form.get("totalFee") !== "" || form.get("price") !== "")) ? messagesConfirm = "Apakah anda yakin ingin menambahkan ekses lagi?" : messagesConfirm = "Apakah anda yakin untuk melanjutkan proses ini?"
    Swal.fire({
      title: "Reconfirmation",
      text: messagesConfirm,
      icon: "warning",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#7cb342",
      cancelButtonText: "No",
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
        dispatch(addExcessPayment(state, form))
          .then(() => {
            initState();
            setAddExcessModal(false);
          })
          .then(() => {
            setTimeout(
              () =>
                Swal.fire({
                  icon: "success",
                  title: "Data Created",
                  showConfirmButton: false,
                }),
              1000
            );
            setTimeout(
              () => (window.location = "/waiting-invoice-teleconsultation"),
              2000
            );
          })
          .catch((err) => {
            if (err.response) {
              Swal.fire({
                icon: "error",
                text: err.response.data.message,
              });
            } else {
              Swal.fire({
                icon: "error",
                text: err.message,
              });
            }
        });
      } else {
        initState();
        setAddExcessModal(false);
      }
    });
  };

  const renderAddExcess = () => {
    return (
      <Modal
        modalTitle="Add Excess to be Paid"
        show={addExcessModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        onSubmit={actionAddExcess}
      >
        <Row>
          <Col md>
            <Input type="text" label="Order ID" value={state} readOnly />
          </Col>
          <Col md>
            <div className="form-group">
              <label>Insurance Validation Receipt</label>
              <Form.File
                custom
                label={fileName.insuranceName}
                data-browse="Drag & Drop or Browse"
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    setFileName({
                      ...fileName,
                      insuranceName: e.target.files[0].name,
                    });
                    setExcessData({
                      ...excessData,
                      isStruk: "1",
                      insuranceDoc: e.target.files[0],
                    });
                  } else {
                    setFileName({
                      ...fileName,
                      insuranceName: "Upload Image",
                    });
                    setExcessData({
                      ...excessData,
                      insuranceDoc: [],
                    });
                  }
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="text"
              label="Teleconsultation Excess"
              placeholder="Input total fee"
              min="0"
              style={{ height: "38px" }}
              value={excessData.totalFee}
              onFocus={
                (e)=> {
                  e.currentTarget.type = "number";
                  e.currentTarget.focus();
                 }
              }
              onChange={(e) =>
                setExcessData({ ...excessData, totalFee: e.target.value })
              }
            />
          </Col>
          <Col md>
            <Input
              type="text"
              label="Pharmacy Excess"
              placeholder="Input price"
              min="0"
              style={{ height: "38px" }}
              value={excessData.price}
              onFocus={
                (e)=> {
                  e.currentTarget.type = "number";
                  e.currentTarget.focus();
                 }
              }
              onChange={(e) =>
                setExcessData({ ...excessData, price: e.target.value })
              }
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <label>is Send Available </label>
            <input
              type="checkbox" style={{margin:"0px 0px 0px 20px"}}
              checked={excessData.isSendAvailable} 
              onChange={handleCheck}
            /> 
          </Col>
        </Row>
      </Modal>
    );
  };

  const handleUploadInvoice = () => {
    setCategory("invoice");
    setUploadInvoiceModal(true);
  };

  const actionUploadInvoice = () => {
    const form = new FormData();

    form.append("category", category);

    for (let i = 0; i < invoiceDoc.length; i++) {
      form.append("document", invoiceDoc[i]);
    }

    dispatch(uploadInvoiceBilling(state, form))
      .then(() => {
        initState();
        setUploadInvoiceModal(false);
      })
      .then(() => {
        setTimeout(
          () =>
            Swal.fire({
              icon: "success",
              title: "Data Uploaded",
              showConfirmButton: false,
            }),
          1000
        );
        setTimeout(
          () => (window.location = "/waiting-invoice-teleconsultation"),
          2000
        );
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  };

  const renderUploadInvoice = () => {
    return (
      <Modal
        modalTitle="Upload Invoice"
        show={uploadInvoiceModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        buttons={[
          {
            label: "Upload",
            color: "primary",
            onClick: actionUploadInvoice,
          },
        ]}
      >
        <Row>
          <Col md>
            <div className="form-group">
              <label>Invoice File</label>
              <Form.File
                custom
                multiple
                label={fileName.invoiceName}
                data-browse="Drag & Drop or Browse"
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    setFileName({
                      ...fileName,
                      invoiceName: e.target.files[0].name,
                    });
                    setInvoiceDoc(e.target.files);
                  } else if (e.target.files.length > 1) {
                    setFileName({
                      ...fileName,
                      invoiceName: `${e.target.files.length} Files`,
                    });
                    setInvoiceDoc(e.target.files);
                  } else {
                    setFileName({
                      ...fileName,
                      invoiceName: "Upload File",
                    });
                    setInvoiceDoc([]);
                  }
                }}
              />
            </div>
          </Col>
        </Row>
      </Modal>
    );
  };

  const renderDetails = () => {
    let detail = waitingInvoice.details.order;

    return waitingInvoice.loading ? (
      <tr>
        <td>
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        <tr>
          <td style={{ width: "200px" }}>Order Id</td>
          <td>{detail.order_id ? detail.order_id : "-"}</td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Customer Name</td>
          <td>{detail.customer_name ? detail.customer_name : "-"}</td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Patient Name</td>
          <td>{detail.patient_name ? detail.patient_name : "-"}</td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Patient Date of Birth</td>
          <td>
            {detail.patient_dob ? moment(detail.patient_dob).format("LL") : "-"}
          </td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Patient Phone</td>
          <td>{detail.patient_phone ? detail.patient_phone : "-"}</td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Doctor Name</td>
          <td>{detail.doctor_name ? detail.doctor_name : "-"}</td>
        </tr>
        {
          localStorage.getItem("parentId") == 3295 ? (
            <>
              <tr>
                <td>Type</td>
                <td>
                  {!detail.isHealthScreening && !detail.isPremium ? "Free Tele" : !detail.eventId && detail.isPremium ? "Tanya Dokter Premium" : detail.isHealthScreening ? "Health Screening" : ""}
                </td>
              </tr>
              {
                detail.isHealthScreening ? 
                  <tr>
                      <td>Event Name</td>
                      <td>{detail.event_name} {detail.corporate_name ? ` - ${detail.corporate_name}` : ""}</td>
                  </tr> : <></>
              }
            </>
          ) : <></>
        }
        <tr>
          <td style={{ width: "200px" }}>Schedule</td>
          <td>
            {detail.scheduleDate
              ? moment(detail.scheduleDate).format("LL")
              : "-"}
          </td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Start Time</td>
          <td>
            {detail.startTime
              ? moment(detail.startTime, "HH:mm:ss").format("HH:mm")
              : "-"}
          </td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>End Time</td>
          <td>
            {detail.endTime
              ? moment(detail.endTime, "HH:mm:ss").format("HH:mm")
              : "-"}
          </td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Gender</td>
          <td>
            {detail.gender === "Laki-Laki"
              ? "Male"
              : detail.gender === "Perempuan"
              ? "Female"
              : "-"}
          </td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Email Address</td>
          <td>{detail.email ? detail.email : "-"}</td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Promo Code</td>
          <td>{detail.promoCode ? detail.promoCode : "-"}</td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Promo Title</td>
          <td>{detail.promoTitle ? detail.promoTitle : "-"}</td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Promo Amount</td>
          <td>
            {detail.discountByPromo ? "IDR " + detail.discountByPromo : "-"}
          </td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Insurance Picture</td>
          <td>
            {detail.insurance_img_url ? (
              <>
                <span>{detail.insurance_img_url}</span>
                <a
                  className="form-title"
                  style={{ float: "right" }}
                  href={detail.insurance_img_url}
                  target="_blank"
                >
                  <i className="fa fa-eye"></i>
                </a>
              </>
            ) : (
              "-"
            )}
          </td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>KTP Picture</td>
          <td>
            {detail.identity_img ? (
              <>
                <span>{detail.identity_img}</span>
                <a
                  className="form-title"
                  style={{ float: "right" }}
                  href={detail.identity_img}
                  target="_blank"
                >
                  <i className="fa fa-eye"></i>
                </a>
              </>
            ) : (
              "-"
            )}
          </td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Drug Delivery Address</td>
          <td>
            {detail.address_delivery_drug ? detail.address_delivery_drug : "-"}
          </td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Status</td>
          <td>{detail.statusDesc ? detail.statusDesc : "-"}</td>
        </tr>
      </>
    );
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Teleconsultation Detail</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="wrapper-form-title">
          <span className="form-title">Teleconsultation Data</span>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <tbody className="text-left">{renderDetails()}</tbody>
          </table>
        </div>

        <div className="form-title">
          <hr />
        </div>

        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "40px" }}
        >
          {waitingInvoice.details.order.channel_url === null ? (
            <a style={{ marginRight: "5px" }}>
              <button
                type="button"
                className="btn btn-block btn-outline-secondary"
                disabled
              >
                Chat
              </button>
            </a>
          ) : (
            <a
              href={`${process.env.REACT_APP_CHAT_URL}chat.html?userid=${waitingInvoice.details.parentId}&userToken=${waitingInvoice.details.sendBird}&chatUrl=${waitingInvoice.details.order.channel_url}`}
              target="_blank"
              style={{ marginRight: "5px" }}
            >
              <button
                type="button"
                className="btn btn-block btn-outline-primary"
              >
                Chat
              </button>
            </a>
          )}

          <button
            type="button"
            className="btn btn-outline-primary"
            style={{ marginRight: "5px" }}
            onClick={() => handleAddExcess()}
          >
            Add Excess
          </button>

          {waitingInvoice.details.order.status === 93 ||
          waitingInvoice.details.order.status === 85 ||
          waitingInvoice.details.order.status === 86 ? (
            <button
              type="button"
              className="btn btn-outline-success"
              onClick={() => handleUploadInvoice()}
            >
              Upload Invoice
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-outline-secondary"
              disabled
            >
              Upload Invoice
            </button>
          )}
        </div>
      </div>
      {renderAddExcess()}
      {renderUploadInvoice()}
    </DashboardLayout>
  );
};

export default WaitingInvoiceDetail;
