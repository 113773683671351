import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  createAddressSnorlax,
  createCustomerSnorlax,
  getCovidDetail,
  getCustomerSnorlax,
} from "../../../actions";
import { Col, Row } from "react-bootstrap";
import Input from "../../Dashboard/Components/Input";
import Modal from "../../Dashboard/Components/Modal";
import GoogleMap from "../../Dashboard/Components/GoogleMap";
import moment from "moment";
import Layout from "../Layout";
import Swal from "sweetalert2";
import "./index.scss";

const FormRegist = () => {
  const { state } = useLocation();
  const history = useHistory();
  const [data, setData] = useState({
    nik: "",
    patientId: "",
    latitude: "",
    longitude: "",
    fullAddress: "",
    detailAddress: "",
    postalCode: "",
  });
  const [patientData, setPatientData] = useState(null);
  const [newPatientData, setNewPatientData] = useState({
    customerId: "",
    relation: "",
    fullName: "",
    email: "",
    phone: "",
    gender: "",
    birthday: "",
    nationalIdNo: "",
    latitude: "",
    longitude: "",
    fullAddress: "",
    detailAddress: "",
    postalCode: "",
  });
  const [patientId, setPatientId] = useState(null);
  const [location, setLocation] = useState({
    latitude: "",
    longitude: "",
    fullAddress: "",
    detailAddress: "",
    postalCode: "",
  });
  const [check, setCheck] = useState({
    checkAddress: false,
    checkNik: false,
    checkAddPatient: false,
  });
  const [patientModal, setPatientModal] = useState(false);
  const [mapModal, setMapModal] = useState(false);
  const testCovid = useSelector((state) => state.testCovid);
  const dispatch = useDispatch();
  const { loading, error, detailData, customerData } = testCovid;

  const relationMap = {
    1: "Saya",
    2: "Orang Tua",
    5: "Anak",
    6: "Suami",
    7: "Istri",
    8: "Lainnya",
  };

  useEffect(() => {
    dispatch(getCustomerSnorlax());
    dispatch(getCovidDetail(state.packageServiceId));
  }, []);

  useEffect(() => {
    if (customerData) {
      setPatientData({
        profileId: customerData.profile.profileId,
        patientId: customerData.profile.patientId,
        fullName: customerData.profile.fullName,
        email: customerData.profile.email,
        phone: customerData.profile.phone,
        nationalIdNo: customerData.profile.nationalIdNo,
        latitude: customerData.profile.latitude,
        longitude: customerData.profile.longitude,
        fullAddress: customerData.profile.address,
        detailAddress: customerData.profile.detailAddress,
        postalCode: customerData.profile.postalCode,
      });

      setData({
        ...data,
        patientId: customerData.profile.patientId,
      });

      setNewPatientData({
        ...newPatientData,
        customerId: customerData.profile.profileId,
        email: customerData.profile.email,
        phone: customerData.profile.phone,
      });

      setPatientId(customerData.profile.patientId);
    }
  }, [customerData]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const actionSetPatient = () => {
    customerData.patients
      .filter((data) => data.patientId == patientId)
      .map((patient) => {
        setPatientData({
          profileId: patient.profileId,
          patientId: patient.patientId,
          fullName: patient.fullName,
          email: patient.email,
          phone: patient.phone,
          nationalIdNo: patient.nationalIdNo,
          latitude: patient.latitude,
          longitude: patient.longitude,
          fullAddress: patient.address,
          detailAddress: patient.detailAddress,
          postalCode: patient.postalCode,
        });

        setData((prev) => {
          return {
            ...prev,
            patientId: patient.patientId,
          };
        });

        if (check.checkAddress) {
          setData((prev) => {
            return {
              ...prev,
              latitude: patient.latitude,
              longitude: patient.longitude,
              fullAddress: patient.address,
              detailAddress: patient.detailAddress,
              postalCode: patient.postalCode,
            };
          });
        }
      });

    setPatientModal(false);
  };

  const actionCreatePatient = () => {
    if (
      !newPatientData.relation ||
      !newPatientData.fullName ||
      !newPatientData.gender ||
      !newPatientData.birthday ||
      !newPatientData.nationalIdNo ||
      !newPatientData.fullAddress
    ) {
      return Swal.fire({
        icon: "error",
        text: "Semua data harus diisi!",
        showConfirmButton: false,
        timer: 2000,
      });
    }

    const newData = new FormData();

    newData.append("customerId", newPatientData.customerId);
    newData.append("relation", newPatientData.relation);
    newData.append("fullName", newPatientData.fullName);
    newData.append("gender", newPatientData.gender);
    newData.append("birthday", newPatientData.birthday);
    newData.append("nationalIdNo", newPatientData.nationalIdNo);
    newData.append("latitude", newPatientData.latitude);
    newData.append("longitude", newPatientData.longitude);
    newData.append("addressLine1", newPatientData.fullAddress);
    newData.append("addressLine2", newPatientData.detailAddress);
    newData.append("postalCode", newPatientData.postalCode);
    newData.append("email", newPatientData.email);
    newData.append("phone", newPatientData.phone);

    dispatch(createCustomerSnorlax(newData))
      .then(() => {
        dispatch(getCustomerSnorlax());
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });

    setNewPatientData({
      ...newPatientData,
      relation: "",
      fullName: "",
      gender: "",
      birthday: "",
      nationalIdNo: "",
      latitude: "",
      longitude: "",
      fullAddress: "",
      detailAddress: "",
      postalCode: "",
    });

    setCheck({
      ...check,
      checkAddPatient: false,
    });

    setPatientModal(false);
  };

  const handleSwitch = () => {
    if (!check.checkAddPatient) {
      setCheck({
        ...check,
        checkAddPatient: true,
      });
    } else {
      setCheck({
        ...check,
        checkAddPatient: false,
      });
    }
  };

  const renderPatient = () => {
    return (
      <Modal
        modalTitle="Pilih Pasien"
        show={patientModal}
        size="lg"
        handleClose={() => {
          setCheck({
            ...check,
            checkAddPatient: false,
          });

          setNewPatientData({
            ...newPatientData,
            relation: "",
            fullName: "",
            gender: "",
            birthday: "",
            nationalIdNo: "",
            latitude: "",
            longitude: "",
            fullAddress: "",
            detailAddress: "",
            postalCode: "",
          });

          setPatientModal(false);
        }}
        buttons={[
          {
            label: "Apply",
            color: "primary",
            onClick: check.checkAddPatient
              ? actionCreatePatient
              : actionSetPatient,
          },
        ]}
      >
        <Row>
          <Col md className="text-right">
            <div className="form-group">
              <span
                style={{ color: "#0077ff", cursor: "pointer" }}
                onClick={handleSwitch}
              >
                {check.checkAddPatient ? "Pilih Pasien" : "Tambah Pasien (+)"}
              </span>
            </div>
          </Col>
        </Row>

        {!check.checkAddPatient && (
          <Row>
            <Col md>
              <div className="form-group">
                <select
                  className="form-control"
                  value={patientId}
                  onChange={(e) => setPatientId(e.target.value)}
                >
                  {customerData
                    ? customerData.patients.map((data) => (
                        <option key={data.patientId} value={data.patientId}>
                          {`${data.fullName} - ${relationMap[data.relation]}`}
                        </option>
                      ))
                    : null}
                </select>
              </div>
            </Col>
          </Row>
        )}

        {check.checkAddPatient && (
          <>
            <Row>
              <Col md>
                <div className="form-group">
                  <label>Hubungan Keluarga</label>
                  <select
                    className="form-control"
                    value={newPatientData.relation}
                    onChange={(e) =>
                      setNewPatientData({
                        ...newPatientData,
                        relation: e.target.value,
                      })
                    }
                  >
                    <option>Hubungan Keluarga</option>
                    {Object.keys(relationMap)
                      .filter((data) => data != 1)
                      .map((data) => (
                        <option key={data} value={data}>
                          {relationMap[data]}
                        </option>
                      ))}
                  </select>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md>
                <Input
                  type="text"
                  label="Nama Lengkap"
                  placeholder="Nama Lengkap"
                  value={newPatientData.fullName}
                  onChange={(e) =>
                    setNewPatientData({
                      ...newPatientData,
                      fullName: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col md>
                <div className="form-group">
                  <label>Jenis Kelamin</label>
                  <select
                    className="form-control"
                    value={newPatientData.gender}
                    onChange={(e) =>
                      setNewPatientData({
                        ...newPatientData,
                        gender: e.target.value,
                      })
                    }
                  >
                    <option value="1">Laki-laki</option>
                    <option value="2">Perempuan</option>
                  </select>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md>
                <Input
                  type="date"
                  label="Tanggal Lahir"
                  value={newPatientData.birthday}
                  onChange={(e) =>
                    setNewPatientData({
                      ...newPatientData,
                      birthday: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col md>
                <Input
                  type="number"
                  label="Nomor KTP/SIM/PASSPORT"
                  placeholder="Nomor KTP/SIM/PASSPORT"
                  value={newPatientData.nationalIdNo}
                  onChange={(e) =>
                    setNewPatientData({
                      ...newPatientData,
                      nationalIdNo: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col md>
                <Input
                  type="text"
                  label="Alamat Sesuai KTP"
                  placeholder="Alamat Sesuai KTP"
                  value={newPatientData.fullAddress}
                  onClick={() => {
                    setPatientModal(false);
                    setMapModal(true);
                  }}
                />
              </Col>
            </Row>
          </>
        )}
      </Modal>
    );
  };

  const handleCheck = (e) => {
    let name = e.target.name,
      checked = e.target.checked;

    if (name === "nik") {
      setCheck({
        ...check,
        checkNik: checked,
      });

      if (checked === false) {
        setData({
          ...data,
          nik: "",
        });
      }
    } else if (name === "address") {
      setCheck({
        ...check,
        checkAddress: checked,
      });

      if (checked === true) {
        setData({
          ...data,
          latitude: patientData.latitude,
          longitude: patientData.longitude,
          fullAddress: patientData.fullAddress,
          detailAddress: patientData.detailAddress,
          postalCode: patientData.postalCode,
        });
      }

      if (checked === false) {
        setData({
          ...data,
          latitude: "",
          longitude: "",
          fullAddress: "",
          detailAddress: "",
          postalCode: "",
        });
      }
    }
  };

  const handleData = (addressData) => {
    setLocation({
      ...location,
      latitude: addressData.latitude,
      longitude: addressData.longitude,
      fullAddress: addressData.fullAddress,
      postalCode: addressData.postalCode,
    });
  };

  const actionSetLocation = () => {
    if (!location.fullAddress || !location.detailAddress) {
      return Swal.fire({
        icon: "error",
        text: "Semua data harus diisi!",
        showConfirmButton: false,
        timer: 2000,
      });
    }

    if (check.checkAddPatient) {
      setNewPatientData({
        ...newPatientData,
        latitude: location.latitude,
        longitude: location.longitude,
        fullAddress: location.fullAddress,
        detailAddress: location.detailAddress,
        postalCode: location.postalCode,
      });

      setLocation({
        latitude: "",
        longitude: "",
        fullAddress: "",
        detailAddress: "",
        postalCode: "",
      });

      setMapModal(false);

      setPatientModal(true);
    } else {
      setData({
        ...data,
        latitude: location.latitude,
        longitude: location.longitude,
        fullAddress: location.fullAddress,
        detailAddress: location.detailAddress,
        postalCode: location.postalCode,
      });

      setLocation({
        latitude: "",
        longitude: "",
        fullAddress: "",
        detailAddress: "",
        postalCode: "",
      });

      setMapModal(false);
    }
  };

  const renderMap = () => {
    return (
      <Modal
        modalTitle="Cari Lokasi"
        show={mapModal}
        size="lg"
        handleClose={() => {
          setLocation({
            latitude: "",
            longitude: "",
            fullAddress: "",
            detailAddress: "",
            postalCode: "",
          });

          setMapModal(false);

          if (check.checkAddPatient) {
            setPatientModal(true);
          }
        }}
        buttons={[
          {
            label: "Apply",
            color: "primary",
            onClick: actionSetLocation,
          },
        ]}
      >
        <Row>
          <Col md style={{ height: "350px" }}>
            <GoogleMap addressData={(addressData) => handleData(addressData)} />
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Alamat</label>
              <div className="input-group">
                <span>{location.fullAddress ? location.fullAddress : "-"}</span>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="text"
              label="Detail Alamat"
              placeholder="Nomor rumah, apartement, dll"
              value={location.detailAddress}
              onChange={(e) =>
                setLocation({
                  ...location,
                  detailAddress: e.target.value,
                })
              }
            />
          </Col>
        </Row>
      </Modal>
    );
  };

  const actionSubmit = () => {
    if (state.serviceType === 1) {
      if (!data.fullAddress) {
        return Swal.fire({
          icon: "error",
          text: "Alamat tidak boleh kosong!",
          showConfirmButton: false,
          timer: 2000,
        });
      }

      if (data.nik !== "" && data.nik.length !== 16) {
        return Swal.fire({
          icon: "error",
          text: "NIK tidak boleh kurang dari 16 karakter!",
          showConfirmButton: false,
          timer: 2000,
        });
      }

      const addressData = new FormData();

      addressData.append("latitude", data.latitude);
      addressData.append("longitude", data.longitude);
      addressData.append("fullAddress", data.fullAddress);
      addressData.append("note", data.detailAddress);
      addressData.append("postalCode", data.postalCode);

      dispatch(createAddressSnorlax(addressData))
        .then(() => {
          history.push({
            pathname: "/test-covid/order",
            state: {
              ...state,
              patientId: data.patientId,
            },
          });
        })
        .catch((err) => {
          if (err.response) {
            return Swal.fire({
              icon: "error",
              text: err.response.data.message,
            });
          } else {
            return Swal.fire({
              icon: "error",
              text: err.message,
            });
          }
        });
    } else {
      history.push({
        pathname: "/test-covid/order",
        state: {
          ...state,
          patientId: data.patientId,
        },
      });
    }
  };

  return (
    <Layout title="Test Covid - 19" link="/test-covid/detail" state={state}>
      {loading ? (
        <Loading />
      ) : (
        <div className="detail-res">
          <div className="list-res">
            <span style={{ fontWeight: "bold", fontSize: "20px" }}>
              {`${detailData.package.name ? detailData.package.name : "-"} ${
                detailData.partner.name ? detailData.partner.name : ""
              }`}
            </span>
          </div>

          <div className="card-form-res">
            <span>Jadwal Dipilih</span>
            <span>{moment(state.requestDate).format("LL")}</span>
          </div>

          <div
            className="card-res"
            onClick={() => setPatientModal(true)}
            style={{ cursor: "pointer" }}
          >
            <div className="img-res">
              <img
                src="https://aido-app.s3-ap-southeast-1.amazonaws.com/assets/users/icons/anonymous.png"
                style={{
                  height: "90px",
                  width: "90px",
                  borderRadius: "100px",
                  padding: "10px",
                }}
              />
            </div>

            <div className="content-res" style={{ width: "100%" }}>
              <div style={{ padding: "5px", fontWeight: "bold" }}>
                <span>{patientData ? patientData.fullName : "-"}</span>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px",
                }}
              >
                <span style={{ color: "#0077ff" }}>Ganti Pasien</span>
                <i
                  className="fa fa-chevron-right"
                  style={{ fontSize: "20px", color: "grey" }}
                />
              </div>
            </div>
          </div>

          {state.serviceType === 1 && (
            <>
              <div
                className="card-res card-col-res"
                onClick={() => setMapModal(true)}
                style={{ cursor: "pointer" }}
              >
                <div className="content-form-res">
                  <span>
                    <i
                      className="fas fa-map-marker-alt"
                      style={{ fontSize: "20px", color: "#0077ff" }}
                    />{" "}
                    Alamat Test
                  </span>
                  <i
                    className="fa fa-chevron-right"
                    style={{ fontSize: "20px", color: "grey" }}
                  />
                </div>

                {data.fullAddress && (
                  <div
                    className="content-form-res"
                    style={{ marginTop: "-30px" }}
                  >
                    <span>{data.fullAddress}</span>
                  </div>
                )}
              </div>

              <div className="list-res">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    name="address"
                    onChange={handleCheck}
                  />
                  <span style={{ marginLeft: "5px" }}>
                    Sama dengan alamat KTP pasien
                  </span>
                </div>
              </div>

              {check.checkNik && (
                <div className="list-res">
                  <label>NIK (Nomor Induk Kependudukan)</label>
                  <input
                    type="number"
                    className="form-control"
                    value={data.nik}
                    onChange={(e) =>
                      setData({
                        ...data,
                        nik: e.target.value,
                      })
                    }
                  />
                </div>
              )}

              <div className="list-res">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input type="checkbox" name="nik" onChange={handleCheck} />
                  <span style={{ marginLeft: "5px" }}>
                    Saya bersedia terhubung PeduliLindungi dan memberikan data
                    lengkap
                  </span>
                </div>
              </div>
            </>
          )}

          <div className="list-res">
            <button
              type="button"
              className="btn btn-primary"
              onClick={actionSubmit}
            >
              Lanjutkan
            </button>
          </div>
        </div>
      )}
      {renderPatient()}
      {renderMap()}
    </Layout>
  );
};

export default FormRegist;
