import { addressConstants } from "../actions/constants";

const initState = {
  subdistrictByKey: [],
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case addressConstants.GET_SUBDISTRICT_BY_KEY_REQUEST:
      state = {
        ...state,
      };
      break;
    case addressConstants.GET_SUBDISTRICT_BY_KEY_SUCCESS:
      state = {
        ...state,
        subdistrictByKey: action.payload,
      };
      break;
    case addressConstants.GET_SUBDISTRICT_BY_KEY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
      };
    break;
  }
  return state;
};
