import axios from "../helpers/axios";
import { waitingForTeleConstants } from "./constants";

export const getCompletedCall = (q) => {
  return async (dispatch) => {
    dispatch({ type: waitingForTeleConstants.GET_COMPLETED_CALL_TELE_REQUEST });
    const res = await axios.get(`/Order/completedCallTeleconsultation?${q}`);
    if (res.status === 200) {
      const { orders, totalData, sendBird, parentId } = res.data;
      dispatch({
        type: waitingForTeleConstants.GET_COMPLETED_CALL_TELE_SUCCESS,
        payload: { orders, totalData, sendBird, parentId },
      });
    } else {
      dispatch({
        type: waitingForTeleConstants.GET_COMPLETED_CALL_TELE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getCompletedCallDetail = (id) => {
  return async (dispatch) => {
    dispatch({
      type: waitingForTeleConstants.COMPLETED_CALL_TELE_DETAIL_REQUEST,
    });
    const res = await axios.get(`Order/completedCallTeleconsultation/${id}`);
    if (res.status === 200) {
      const { order, nextStatus } = res.data;
      dispatch({
        type: waitingForTeleConstants.COMPLETED_CALL_TELE_DETAIL_SUCCESS,
        payload: { order, nextStatus },
      });
    } else {
      dispatch({
        type: waitingForTeleConstants.COMPLETED_CALL_TELE_DETAIL_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const updateStatusCompletedCall = (id, dataBody, q) => {
  return async (dispatch) => {
    dispatch({
      type: waitingForTeleConstants.UPDATE_ORDER_TELE_STATUS_REQUEST,
    });
    const res = await axios.put(
      `Order/completedCallTeleconsultation/updateStatus/${id}`,
      dataBody
    );
    if (res.status === 200) {
      dispatch({
        type: waitingForTeleConstants.UPDATE_ORDER_TELE_STATUS_SUCCESS,
        payload: res.data,
      });
      dispatch(getCompletedCall(q));
    } else {
      dispatch({
        type: waitingForTeleConstants.UPDATE_ORDER_TELE_STATUS_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const updateDocumentCompletedCall = (id, dataBody) => {
  return async (dispatch) => {
    dispatch({
      type: waitingForTeleConstants.UPLOAD_ORDER_TELE_DOCUMENT_REQUEST,
    });
    const res = await axios.put(
      `Order/completedCallTeleconsultation/updateDocument/${id}`,
      dataBody
    );
    if (res.status === 200) {
      dispatch({
        type: waitingForTeleConstants.UPLOAD_ORDER_TELE_DOCUMENT_SUCCESS,
        payload: res.data,
      });
      dispatch(getCompletedCallDetail(id));
    } else {
      dispatch({
        type: waitingForTeleConstants.UPLOAD_ORDER_TELE_DOCUMENT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const sendEmailDocument = (id) => {
  return async (dispatch) => {
    dispatch({
      type: waitingForTeleConstants.SEND_EMAIL_TELE_DOCUMENT_REQUEST,
    });
    const res = await axios.get(`Order/Tele/documentSendEmail/${id}`);
    if (res.status === 200) {
      dispatch({
        type: waitingForTeleConstants.SEND_EMAIL_TELE_DOCUMENT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: waitingForTeleConstants.SEND_EMAIL_TELE_DOCUMENT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
