import React, { useEffect, useState, useRef } from "react";
import Loading from "../../../../../components/Loading";
import styles from "../style.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation, useParams } from "react-router";
import {
  getResultOrderVaccine,
  getQuestion,
  getOrderForm,
  updateSkrining,
} from "../../../../../actions";
import Swal from "sweetalert2";
import SkriningValidation from "../SkriningValidation";

const SkriningForm = () => {
  let { orderId } = useParams();
  let history = useHistory();
  const token = useLocation().search;
  const [answer, setAnswer] = useState({});
  const orderVaccine = useSelector((state) => state.orderVaccine);
  const dispatch = useDispatch();

  const { loading, skrining_detail, skrining_result, questions } = orderVaccine;

  useEffect(() => {
    dispatch(getResultOrderVaccine(orderId));
    dispatch(getOrderForm(orderId, token)).catch((error) => {
      if (error.response) {
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: error.message,
        });
      }
    });
    dispatch(getQuestion());
  }, [orderId, dispatch]);

  const handleChange = (e) => {
    let name = e.target.name,
      value = e.target.value;
    setAnswer((prevState) => {
      const newState = { ...prevState };
      newState[name] = value;
      return newState;
    });
  };

  const handleSubmit = () => {
    const formData = new FormData();
    let answers = [];

    formData.append("orderId", orderId);
    questions.map((item) =>
      Object.entries(answer).map(([key, value]) =>
        [key].toString().split("_")[1] === `${item.id}`
          ? answers.push({
              skrining_id: item.id,
              question: item.question,
              answer: value,
            })
          : null
      )
    );
    formData.append("items", JSON.stringify(answers));

    if (
      formData.get("orderId") !== null &&
      JSON.parse(formData.get("items")).length === 16
    ) {
      Swal.fire({
        title: "Reconfirmation",
        text: "Apakah anda yakin ingin mengirim form?",
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#7cb342",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          dispatch(updateSkrining(formData)).then((res) => {
            if (res) {
              dispatch(getResultOrderVaccine(orderId));
            }
            Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: false,
            }).fire({
              icon: "success",
              title: "Send Form Success",
            });
            history.replace("/thank-you");
          });
          setAnswer({});
        }
      });
    }
  };

  const FormSubmitted = () => {
    return (
      <div className="caution" style={styles}>
        <div className="col-md-12">
          <h4 className="font-caution">Form skrining COVID-19 telah terisi</h4>
        </div>

        <div className="col-md-12">
          <h4 className="font-caution">
            Mohon hubungi aido support untuk pertanyaan lebih lanjut.
          </h4>
        </div>

        <div className="col-md-12">
          <div className="float">
            <a
              className="float"
              href="https://api.whatsapp.com/send?phone=628118481436"
              target="_blank"
            >
              <span
                id="float-text"
                className="myfloat-text text-white font-weight-bold"
              >
                {" "}
                <i
                  id="float-logo"
                  className="fab fa-whatsapp my-float text-white"
                ></i>{" "}
                Bantuan
              </span>
            </a>
          </div>
        </div>
      </div>
    );
  };

  return loading ? (
    <Loading />
  ) : token === "" || token.split("=")[1] === "undefined" ? (
    <SkriningValidation />
  ) : skrining_result.length > 0 ? (
    <FormSubmitted />
  ) : (
    <>
      <div
        id="div_print"
        className="form-wrapper wrapper-vaccine"
        style={{ margin: "auto" }}
      >
        <div>
          <div className="wrapper-form-title" style={{ textAlign: "center" }}>
            <span className="form-title">
              Form Skrining Sebelum Vaksinasi COVID-19*
            </span>
          </div>
          <form>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Nama</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={skrining_detail.name}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>NIK</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={skrining_detail.nik}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="wrapper-form-title">
              <span className="form-title">Pertanyaan</span>
            </div>

            <div
              style={{
                borderStyle: "solid",
                borderColor: "#58A8F8",
                borderRadius: "5px",
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <>
                {questions ? (
                  questions.length > 0 ? (
                    questions.map((item, index) => (
                      <div key={index + 1}>
                        <div style={{ paddingLeft: "10px" }}>
                          {" "}
                          {item.id}. {item.question}
                        </div>

                        <div style={{ paddingLeft: "35px" }}>
                          <input
                            type="radio"
                            id={`yes_${item.id}`}
                            name={`answer_${item.id}`}
                            value="1"
                            onChange={handleChange}
                            required
                          />{" "}
                          <label
                            style={{
                              fontWeight: "400",
                              fontFamily: "sans-serif",
                            }}
                          >
                            Ya
                          </label>
                          <input
                            type="radio"
                            id={`no_${item.id}`}
                            name={`answer_${item.id}`}
                            value="0"
                            onChange={handleChange}
                            style={{ marginLeft: "30px" }}
                            required
                          />{" "}
                          <label
                            style={{
                              fontWeight: "400",
                              fontFamily: "sans-serif",
                              paddingRight: "0px",
                            }}
                          >
                            Tidak
                          </label>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div style={{ textAlign: "center" }}>Empty data</div>
                  )
                ) : null}
              </>
            </div>
            <button
              type="button"
              className="btn btn-primary submit"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default SkriningForm;
