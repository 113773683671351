import React, { useEffect, useState } from "react";
import Loading from "../../../../components/Loading";
import styles from "./style.scss";
import { useDispatch, useSelector } from "react-redux";

const SkriningValidation = () => {
  const orderVaccine = useSelector((state) => state.orderVaccine);
  const dispatch = useDispatch();

  const {loading, error, skrining_detail} = orderVaccine;

  return (
    <div className="caution" style={styles}>
        <div className="col-md-12">
            <h4 className="font-caution">Form tidak tervalidasi</h4>
        </div>
        <div className="col-md-12">
            <h4 className="font-caution">Mohon hubungi aido support untuk pertanyaan lebih lanjut.</h4>
        </div>
        <div className="col-md-12">
            <div className="float">
                <a href="https://api.whatsapp.com/send?phone=628118481436" className="float" target="_blank">
                    <span id="float-text" className="myfloat-text text-white font-weight-bold">
                        <i id="float-logo" className="fab fa-whatsapp my-float text-white"></i> 
                        Bantuan
                    </span>
                </a>
            </div>
        </div>
    </div>
  );
};

export default SkriningValidation;


