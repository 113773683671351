import { myVaccinationEventConstants } from "../actions/constants";

const initState = {
  loading: false,
  error: null,
  events: [],
  totalData: null,
  event: {},
};

export default (state = initState, action) => {
  switch (action.type) {
    case myVaccinationEventConstants.GET_ALL_EVENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case myVaccinationEventConstants.GET_ALL_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        events: action.payload.events.data,
        totalData: action.payload.events.totalData,
      };
    case myVaccinationEventConstants.GET_ALL_EVENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case myVaccinationEventConstants.VACCINATION_EVENT_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case myVaccinationEventConstants.VACCINATION_EVENT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        event: action.payload.event,
        employeeData: action.payload.employeeData,
      }
    case myVaccinationEventConstants.VACCINATION_EVENT_DETAIL_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case myVaccinationEventConstants.CREATE_VACCINATION_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case myVaccinationEventConstants.CREATE_VACCINATION_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case myVaccinationEventConstants.CREATE_VACCINATION_EVENT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case myVaccinationEventConstants.DELETE_VACCINATION_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case myVaccinationEventConstants.DELETE_VACCINATION_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case myVaccinationEventConstants.DELETE_VACCINATION_EVENT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    default: 
      return state;
  }
};
