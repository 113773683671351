import axios from "../helpers/axios";
import { orderPharmacyConstants } from "./constants";
import Swal from "sweetalert2";

export const getAllOrderPharmacy = (q) => {
  return async (dispatch) => {
    dispatch({ type: orderPharmacyConstants.GET_ALL_ORDERPHARMACY_REQUEST });
    
    const res = await axios.get(`/Order/AllPharmacies?${q}`);
    if (res.status === 200) {
      const { orders, parentId, totalData } = res.data;
      dispatch({
        type: orderPharmacyConstants.GET_ALL_ORDERPHARMACY_SUCCESS,
        payload: { orders, parentId, totalData },
      });
    } else {
      dispatch({
        type: orderPharmacyConstants.GET_ALL_ORDERPHARMACY_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getListOrderPharmacy = (q) => {
  return async (dispatch) => {
    dispatch({ type: orderPharmacyConstants.GET_LIST_ORDERPHARMACY_REQUEST });
    
    const res = await axios.get(`/Order/Pharmacies?${q}`);
    if (res.status === 200) {
      const { orders, parentId, totalData } = res.data;
      dispatch({
        type: orderPharmacyConstants.GET_LIST_ORDERPHARMACY_SUCCESS,
        payload: { orders, parentId, totalData },
      });
    } else {
      dispatch({
        type: orderPharmacyConstants.GET_LIST_ORDERPHARMACY_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getCompletePharmacy = (id) => {
  return async (dispatch) => {
    dispatch({ type: orderPharmacyConstants.GET_COMPLETE_ORDERPHARMACY_REQUEST });
    
    const res = await axios.put(`/Order/Pharmacy/Complete/${id}`);
    if (res.status === 200) {
      dispatch({
        type: orderPharmacyConstants.GET_COMPLETE_ORDERPHARMACY_SUCCESS,
        payload: res.data,
      });

      Swal.fire({
          title: "Order Pharmacy Completed",
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#7cb342",
      }).then((result) => {
          if (result.value) {
            window.location.replace("/order/tele/pharmacy");
          } 
      });
      
    } else {
      dispatch({
        type: orderPharmacyConstants.GET_COMPLETE_ORDERPHARMACY_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getOrderPharmacyDetail = (id) => {
  return async (dispatch) => {
    dispatch({ type: orderPharmacyConstants.GET_ORDERPHARMACY_DETAIL_REQUEST });
    const res = await axios.get(
      `/Order/AllPharmacies/Detail/${id}`, 
    );
    if (res.status === 200) {
      const { order, foundPartner } = res.data;
      console.log("ord", order)
      dispatch({
        type: orderPharmacyConstants.GET_ORDERPHARMACY_DETAIL_SUCCESS,
        payload: { order, foundPartner },
      });
    } else {
      dispatch({
        type: orderPharmacyConstants.GET_ORDERPHARMACY_DETAIL_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const updateStatusOrderPharmacy = (id, dataBody) => {
  return async (dispatch) => {
    dispatch({ type: orderPharmacyConstants.UPDATE_ORDERPHARMACY_STATUS_REQUEST });
    const res = await axios.put(
      `Order/AllPharmacies/updateStatus/${id}`, dataBody
    );
    if (res.status === 200) {
      dispatch({
        type: orderPharmacyConstants.UPDATE_ORDERPHARMACY_STATUS_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllOrderPharmacy());
    } else {
      dispatch({
        type: orderPharmacyConstants.UPDATE_ORDERPHARMACY_STATUS_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};