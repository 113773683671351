import axios from "../helpers/axios";
import { pharmacyDeliveryAllCanceledConstants } from "./constants";

export const getPharmacyDeliveryAllCanceled = (search) => {
  return async (dispatch) => {
    dispatch({
      type: pharmacyDeliveryAllCanceledConstants.GET_PHARMACY_ALL_CANCELED_REQUEST,
    });
    const res = await axios.get(`/Order/getPharmacyDeliveryAllCanceled?${search}`);
    if (res.status === 200) {
      dispatch({
        type: pharmacyDeliveryAllCanceledConstants.GET_PHARMACY_ALL_CANCELED_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: pharmacyDeliveryAllCanceledConstants.GET_PHARMACY_ALL_CANCELED_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const cancelOrder = (form) => {
  return async (dispatch) => {
    dispatch({ type: pharmacyDeliveryAllCanceledConstants.UPDATE_PHARMACY_ALL_CANCELED_REQUEST });
    const res = await axios.post("/Order/getPharmacyDeliveryAllCanceled/cancelOrder", form);
    if (res.status === 200) {
      dispatch({
        type: pharmacyDeliveryAllCanceledConstants.UPDATE_PHARMACY_ALL_CANCELED_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: pharmacyDeliveryAllCanceledConstants.UPDATE_PHARMACY_ALL_CANCELED_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};