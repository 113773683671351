import React, { Fragment, useEffect, useState } from "react";
import DashboardLayout from "../../Layout";
import { useDispatch, useSelector } from "react-redux";
import {
    getCompletePharmacy,
} from "../../../../actions";
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";

const Breadcrumb = (props) => {
  return (
    <nav key={props.label} aria-label="breadcrumb" id="breadcrumb-id">
      <ol className="breadcrumb pl-0">
        <li className="breadcrumb-item">
          <a>Dashboard</a>
        </li>
        <li className="breadcrumb-item" aria-current="page">
          <Link to={props.path}>{props.menu}</Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {props.label}
        </li>
      </ol>
    </nav>
  );
};

const CompletedPharmacy = () => {
  let { orderId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompletePharmacy(orderId));
  }, [orderId, dispatch]);

  return (
    <DashboardLayout>
    </DashboardLayout>
  );
};

export default CompletedPharmacy;
