import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../Layout";
import FilterForm from ".././TeleconsultationRefund/FilterHooks.js";
import Loading from "../../../components/Loading";
import Pagination from "../../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import queryString from "query-string";
import { getCompletedCall } from "../../../actions";

const WaitingTele = () => {
  const [query, setQuery] = useState("");
  const [param, setParam] = useState("");
  const [dataTarget, setDataTarget] = useState("");

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const sortBy = useRef("normalSort");
  const searchKey = useRef("");
  const startDate = useRef("");
  const endDate = useRef("");
  const status = useRef("");

  const completedCallTele = useSelector((state) => state.completedCallTele);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompletedCall(query));
  }, [query, setQuery, dispatch]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: completedCallTele.totalData,
    });
  }, [completedCallTele]);

  const handleSearch = (page, limit) => {
    let values = {
      sortBy: sortBy.current.value,
      searchKey: searchKey.current.value,
      endDate: endDate.current.value,
      startDate: startDate.current.value,
    };
    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }
    if (limit) {
      values.limit = limit;
    }

    if (status.current.value !== "") {
      values.status = status.current.value;
    }

    let qs = queryString.stringify(values, {
      skipEmptyString: true
    });
    setQuery(qs);
  }

  const handleSortBy = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      sortBy: sortBy.current.value,
      page: pagination.page,
      limit: pagination.limit,
      searchKey: searchKey.current.value,
      endDate: endDate.current.value,
      startDate: startDate.current.value,
    };

    if (status.current.value !== "") {
      values.status = status.current.value;
    }

    let qs = queryString.stringify(values, {
      skipEmptyString: true
    });
    setQuery(qs);
  }

  const reset = () => {
    setParam("")
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      limit: pagination.limit,
      page: pagination.page,
      sortBy: sortBy.current.value,
      searchKey: searchKey.current.value,
      status: (status.current.value) = "",
      endDate: (endDate.current.value) = "",
      startDate: (startDate.current.value) = "",
    };

    let qs = queryString.stringify(values, {
      skipEmptyString: true
    });
    setQuery(qs);
  };

  const apply = () => {
    var params = []
    let filter_params = Object.keys(inputs).map(k => inputs[k] !== "" ? params.push(`${encodeURIComponent(k)}=${encodeURIComponent(inputs[k])}`) : true);
    let qs = params.join("&");
    setParam(qs);
  }

  const {inputs, handleInputChange, handleSubmit, handleReset } = FilterForm({startDate: '', endDate: '', status: '', searchKey: ''}, apply);

  const dataTables = () => {
    return completedCallTele.loading ? (
      <tr>
        <td colSpan="12">
          <Loading />
        </td>
      </tr>
    ) : completedCallTele.error ? completedCallTele.error.message : (
      <Fragment>
        { completedCallTele.orders.length > 0 ? ( Object.values(JSON.parse(JSON.stringify(completedCallTele.orders))).map((item, index) => (
          <tr key={item.order_id}>
            <td className="num"></td>
            <td>{item.order_id}</td>
            <td>{item.patient_name}</td>
            <td>{moment(item.patient_dob).format("DD MMMM YYYY")}</td>
            <td>{item.customer_phone}</td>
            <td>{item.patient_phone}</td>
            <td>{moment(item.scheduleDate).format("DD MMMM YYYY")}</td>
            <td>{item.startTime.slice(0, 5)}</td>
            <td>{item.endTime.slice(0, 5)}</td>
            <td>{item.doctor_name}</td>
            {
              localStorage.getItem("parentId") == 3295 ? (
                <td>{!item.isHealthScreening && !item.isPremium ? "Free Tele" : !item.eventId && item.isPremium ? "Tanya Dokter Premium" : item.isHealthScreening ? "Health Screening" : ""}</td>
              ) : <></>
            }
            <td>{item.statusDesc}</td>
            <td>
              <div style={{ textAlign: 'center' }}>
                <div className="span2">
                  <p className="mb-3">
                      <Link
                        to={"/completed-call-teleconsultation/" + item.order_id}>
                        <button type="button" className="btn btn-outline-primary btn-block" 
                          onClick={ 
                            () => {
                            setDataTarget(item.order_id)
                            }
                          }
                          >
                            Detail
                        </button>
                      </Link>
                  </p>

                  <p className="mb-3">
                    {item.channel_url == null ? (
                      <a href="" target="_blank">
                        <button type="button" className="btn btn-outline-info btn-block" style={{ color: 'grey', borderColor: 'grey'}} disabled>
                                Chat
                        </button>
                    </a>  
                    ) :
                    <a href={`${process.env.REACT_APP_CHAT_URL}chat.html?userid=${completedCallTele.parentId}&userToken=${completedCallTele.sendBird}&chatUrl=${item.channel_url}`} target="_blank">
                        <button type="button" className="btn btn-outline-info btn-block" >
                                Chat
                        </button>
                    </a>  
                    }
                               
                  </p>
                </div>
              </div>
            </td>
          </tr>
      ))) :  
      
          <tr>
              <td colSpan="13">Empty data</td>
          </tr> }
      </Fragment>
    )
  }

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>
        <div>List Teleconsultation</div>
        <div className="col">
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6" style={{ paddingBottom: "1em" }} id="btn-add">
          <a href="" style={{ marginRight: "7px" }}>
            <button type="button" className="btn btn-outline-primary active">
              Teleconsultation
            </button>
          </a>
        </div>
      </div>
      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>
        <div className="row">
          <div className="col-md-6" id="btn-add">
            <span id="finance-title" className="mb10">
              List Teleconsultation
            </span>
          </div>
          <div className="col-md-6" id="search-form">
              <div className="form-inline" style={{ float: "right" }}>
                <div className="input-group">
                  <select
                    id="sortBy"
                    name="sortBy"
                    className="form-control table-search-field"
                    ref={sortBy}
                  >
                    <option value="normalSort" data-id="normalSort">
                      Urutan Standar
                    </option>
                    <option value="desc" data-id="desc">
                      Descending
                    </option>
                    <option value="asc" data-id="asc">
                      Ascending
                    </option>
                  </select>
                  <span className="input-group-btn">
                    <button
                      type="submit"
                      onClick={handleSortBy}
                      className="btn btn-primary table-btn-search"
                      >
                        <i className="fa fa-search" />
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb15">
            <div className="link-btn">
              <button
                type="button"
                className="btn btn-link no-padding"
                id="filterBy"
                data-toggle="collapse"
                data-target="#collapseDetailSchedule"
              >
                Filter By
                <i className="fa fa-angle-down" style={{ marginLeft: "10px" }} />
              </button>
            </div>
            <div className="col">
              <hr />
            </div>
          </div>
          
          <div className="collapse" id="collapseDetailSchedule">
            <form id="filter-form" onSubmit={handleSubmit} onReset={handleReset}>
              <div id="filterForm" className="row filter-by">
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-6">
                      <input type="text" value={query} hidden onChange={(e) => setQuery(e.target.value)} />
                      
                      <div className="mb10">Date From</div>
                      <input
                        id="datePickerFrom"
                        className="form-control"
                        type="text"
                        placeholder="Select Date From"
                        onFocus={
                          (e)=> {
                            e.currentTarget.type = "date";
                            e.currentTarget.focus();
                           }
                        }
                        name="startDate"
                        ref={startDate}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="mb10">Date To</div>
                      <input
                        id="datePickerTo"
                        className="form-control"
                        type="text"
                        placeholder="Select Date To"
                        onFocus={
                          (e)=> {
                            e.currentTarget.type = "date";
                            e.currentTarget.focus();
                           }
                        }
                        name="endDate"
                        ref={endDate}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 neutral-border pf-c">
                  <div className="col">
                    <div className="col-md-12">
                      <div className="mb10">Status</div>
                        <select 
                          id="status" 
                          name="status" 
                          className="form-control" 
                          ref={status}
                          >
                            <option value="">Select status</option>
                            <option value={80} data-id={80}>New Order</option>
                            <option value={88} data-id={88}>Cancelled By Customer</option>
                            <option value={89} data-id={89}>Cancelled By CMS</option>
                            <option value={99} data-id={99}>Completed</option>
                        </select>                     
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="col">
                    <div className="col-md-12">
                      <div className="mb10">Patient Name</div>
                      <input
                        id="patientName"
                        className="form-control"
                        type="text"
                        placeholder="Input patient name"
                        name="searchKey"
                        ref={searchKey}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="fm-btn-gr text-right">
                <button
                  id="resetBtn"
                  type="reset"
                  className="btn btn-secondary btn-reset"
                  style={{ marginRight: "10px" }}
                  onClick={
                    () => reset()
                  }
                >
                  Reset
                </button>
                <button
                  id="submitBtn"
                  type="submit"
                  className="btn btn-primary"
                  onClick={ () => handleSearch() }
                  >
                  Apply
                </button>
              </div>
              <div style={{ width: "100%" }}>
                <hr />
              </div>
            </form>
        </div>
       
        <div className="table-responsive">
          <table
            id="tableScheduler"
            className="table table-striped table-bordered"
          >
            <thead className="table-header">
              <tr>
                <th rowSpan={2} className="table-header-middle">
                  No
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Order ID
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Patient Name
                </th>
                <th rowSpan={2} className="table-header-middle">
                  DOB
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Customer Phone
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Patient Phone
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Schedule Date
                </th>
                <th colSpan={2} className="table-header-middle">
                  Time
                </th>
                <th rowSpan={2} className="table-header-middle">
                  Doctor
                </th>
                {
                  localStorage.getItem("parentId") == 3295 ? (
                    <th rowSpan={2} className="table-header-middle">
                      Type
                    </th>) : <></>
                }
                <th rowSpan={2} className="table-header-middle">
                  Status
                </th>
                <th rowSpan={2} className="header-action table-header-middle">
                  Action
                </th>
              </tr>
              <tr>
                <th>Start</th>
                <th>End</th>
              </tr>
            </thead>

            <tbody>
              { dataTables() }
            </tbody>
          </table>
        </div>

        { completedCallTele.totalData > 10 ? (
          <Pagination
            props={pagination}
            setProps={setPagination}
            onChange={(page, limit) => {
              handleSearch(page, limit);
            }}
          />
        ) : null}
        
        </div>
    </DashboardLayout>
  );
};

export default WaitingTele;
