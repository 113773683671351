import { paymentOfflineVoucherConstants } from "../actions/constants";

const initState = {
  data: [],
  totalData: "",
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case paymentOfflineVoucherConstants.GET_REGISTERPATIENT_LIST_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case paymentOfflineVoucherConstants.GET_REGISTERPATIENT_LIST_SUCCESS:
      state = {
        ...state,
        data: action.payload.data,
        totalData: action.payload.totalData,
        loading: false,
      };
      break;
    case paymentOfflineVoucherConstants.GET_REGISTERPATIENT_LIST_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
  }
  return state;
};
