import { orderTeleConstants } from "../actions/constants";

const initState = {
  orders: [],
  loading: false,
  error: null,
  order: {},
  status: [],
  totalData: null,
  sendBird: "",
  parentId: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case orderTeleConstants.GET_ALL_ORDER_TELE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderTeleConstants.GET_ALL_ORDER_TELE_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload.orders,
        totalData: action.payload.totalData,
        sendBird: action.payload.sendBird,
        parentId: action.payload.parentId,
      };
    case orderTeleConstants.GET_ALL_ORDER_TELE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case orderTeleConstants.ORDER_TELE_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderTeleConstants.ORDER_TELE_DETAIL_SUCCESS:
      return {
        ...state,
        order: action.payload.order,
        loading: false,
      }
    case orderTeleConstants.ORDER_TELE_DETAIL_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case orderTeleConstants.GET_TELE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderTeleConstants.GET_TELE_STATUS_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        loading: false,
      }
    case orderTeleConstants.GET_TELE_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case orderTeleConstants.CHANGE_ORDER_TELE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderTeleConstants.CHANGE_ORDER_TELE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case orderTeleConstants.CHANGE_ORDER_TELE_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    default: 
      return state;
  }
};
