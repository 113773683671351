import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import DashboardLayout from "../../Layout";
import { useDispatch, useSelector } from "react-redux";
import { getOrderHistoryDetail } from "../../../../actions";
import "../orderHistory.css";

import ViewDetailHistoryOther from "./ViewDetailHistoryOther";
import ViewDetailHistoryDone from "./ViewDetailHistoryDone";

export default function OrderHistoryDetail() {
  const result = useSelector((state) => state.orderHistory);
  const { state } = useLocation();
  const [val, setVal] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;
    dispatch(getOrderHistoryDetail(state.id)).then(() => {
      if (mounted) {
        navigator.geolocation.getCurrentPosition(function (position) {
          setVal({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        });
      }
    });

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const detailDataVal =
    result.listDataDetail != undefined ? result.listDataDetail : [];
  const orderStatusData =
    detailDataVal.orderStatus != undefined ? detailDataVal.orderStatus : [];
  const statusVal = orderStatusData.length != 0 ? orderStatusData.code : null;

  return (
    <DashboardLayout>
      {result.loading ? (
        <Loading />
      ) : (
        <>
          {statusVal != null ? (
            statusVal != 14 && statusVal != 98 && statusVal != 99 ? (
              <ViewDetailHistoryOther
                state={state}
                detailDataVal={detailDataVal}
                latitude={val.latitude}
                longitude={val.longitude}
                result={result}
              />
            ) : (
              <ViewDetailHistoryDone
                state={state}
                detailDataVal={detailDataVal}
                result={result}
              />
            )
          ) : (
            <p>
              <b>Not available</b>
            </p>
          )}
        </>
      )}
    </DashboardLayout>
  );
}
