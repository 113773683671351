import axios from "../helpers/axios";
import { myProceduresConstants } from "./constants";

export const getAllProcedures = (search) => {
  return async (dispatch) => {
    dispatch({ type: myProceduresConstants.GET_ALL_PROCEDURES_REQUEST });
    const res = await axios.get(`/Procedure?${search}`);
    if (res.status === 200) {
      dispatch({
        type: myProceduresConstants.GET_ALL_PROCEDURES_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: myProceduresConstants.GET_ALL_PROCEDURES_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getProceduresById = (id) => {
  return async (dispatch) => {
    dispatch({ type: myProceduresConstants.GET_PROCEDURES_BY_ID_REQUEST });
    const res = await axios.get(`/Procedure/${id}`);
    if (res.status === 200) {
      dispatch({
        type: myProceduresConstants.GET_PROCEDURES_BY_ID_SUCCESS,
        payload: { proceduresData: res.data },
      });
    } else {
      dispatch({
        type: myProceduresConstants.GET_PROCEDURES_BY_ID_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const addProcedures = (form, search) => {
  console.log("form:",form)
  return async (dispatch) => {
    dispatch({ type: myProceduresConstants.ADD_NEW_PROCEDURES_REQUEST });
    const res = await axios.post("/Procedure", form);
    if (res.status === 200) {
      dispatch({
        type: myProceduresConstants.ADD_NEW_PROCEDURES_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllProcedures(search));
    } else {
      dispatch({
        type: myProceduresConstants.ADD_NEW_PROCEDURES_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const updateProcedures = (id, form, search) => {
  return async (dispatch) => {
    dispatch({ type: myProceduresConstants.UPDATE_PROCEDURES_REQUEST });
    const res = await axios.put(`/Procedure/${id}`, form);
    if (res.status === 200) {
      dispatch({
        type: myProceduresConstants.UPDATE_PROCEDURES_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllProcedures(search));
    } else {
      dispatch({
        type: myProceduresConstants.UPDATE_PROCEDURES_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const deleteProcedures = (id, search) => {
  return async (dispatch) => {
    dispatch({ type: myProceduresConstants.DELETE_PROCEDURES_REQUEST });
    const res = await axios.delete(`/Procedure/${id}`, {
      payload: {
        id,
      },
    });
    if (res.status === 200) {
      dispatch({ type: myProceduresConstants.DELETE_PROCEDURES_SUCCESS });
      dispatch(getAllProcedures(search));
    } else {
      dispatch({
        type: myProceduresConstants.DELETE_PROCEDURES_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
