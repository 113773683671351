import { waitingForTeleConstants } from "../actions/constants";

const initState = {
  orders: [],
  totalData: null,
  sendBird: "",
  parentId: null,
  loading: false,
  error: null,
  order: {},
  nextStatus: {},
};

export default (state = initState, action) => {
  switch (action.type) {
    case waitingForTeleConstants.GET_COMPLETED_CALL_TELE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case waitingForTeleConstants.GET_COMPLETED_CALL_TELE_SUCCESS:
      return {
        ...state,
        orders: action.payload.orders,
        totalData: action.payload.totalData,
        sendBird: action.payload.sendBird,
        parentId: action.payload.parentId,
        loading: false,
      };
    case waitingForTeleConstants.GET_COMPLETED_CALL_TELE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case waitingForTeleConstants.COMPLETED_CALL_TELE_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case waitingForTeleConstants.COMPLETED_CALL_TELE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        order: action.payload.order,
        nextStatus: action.payload.nextStatus,
      }
    case waitingForTeleConstants.COMPLETED_CALL_TELE_DETAIL_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case waitingForTeleConstants.UPDATE_ORDER_TELE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case waitingForTeleConstants.UPDATE_ORDER_TELE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case waitingForTeleConstants.UPDATE_ORDER_TELE_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case waitingForTeleConstants.UPLOAD_ORDER_TELE_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case waitingForTeleConstants.UPLOAD_ORDER_TELE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case waitingForTeleConstants.UPLOAD_ORDER_TELE_DOCUMENT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case waitingForTeleConstants.SEND_EMAIL_TELE_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case waitingForTeleConstants.SEND_EMAIL_TELE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case waitingForTeleConstants.SEND_EMAIL_TELE_DOCUMENT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    default: 
      return state;
  }
};
