import React, { useEffect, useState } from "react";
import DashboardLayout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getOrderById, updateStatus } from "../../../actions";
import Swal from "sweetalert2";


const SearchOrderById = () => {
  const [query, setQuery] = useState(false);
  const [modal, setModal] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [search , setSearch ] = useState([]);
  const [dataTarget, setDataTarget] = useState("");
  const dispatch = useDispatch();
  const orderByIds = useSelector((state) => state.orderById);

  useEffect(() => {
    if (query == true) {
      if (orderId != '') {
      console.log("orderId", orderId);
      dispatch(getOrderById(orderId));
      }
    }
  }, [query, orderId]);

  useEffect(() => {
    getData(orderByIds.data, orderByIds.data.status);
    console.log('orderByIds.data', orderByIds.data)
    console.log('orderByIds.status', orderByIds.data.status)
  }, [orderByIds.data]);


  const getData = (orders, status) => {
    console.log('data form', orders)
    console.log('status form', status)
    if(status){
      if(status != 200){
        Swal.fire({
            title: "Failed",
            text: "Order yang anda masukan tidak sesuai",
            icon: "warning",
            showCancelButton: false,
            showLoaderOnConfirm: true,
            confirmButtonText: "Back",
            confirmButtonColor: "#7cb342",
            cancelButtonText: "No",
        }).then((result) => {
          console.log('serach ulang')
          setSearch({
            orderId: '',
            zoomUrl: '',
            customerName: '',
            customerDob: '',
            customerPhone: '',
            customerEmail: '',
            customerGender: '',
            testDate: '',
            statusCode: '',
            statusDesc: '',
            hospitalName: '',
          });
          setOrderId("")
        })
      }else{
        setSearch({
          orderId: orders.data.orderId,
          zoomUrl: orders.data.zoomUrl,
          customerName: orders.data.customerName,
          customerDob: orders.data.customerDob,
          customerPhone: orders.data.customerPhone,
          customerEmail: orders.data.customerEmail,
          customerGender: orders.data.customerGender,
          testDate: orders.data.testDate,
          statusCode: orders.data.statusCode,
          statusDesc: orders.data.statusDesc,
          hospitalName: orders.data.hospitalName
        });
        setQuery(false)
      }
    }
  };

  const submitStatus = () => {
      Swal.fire({
          title: "Reconfirmation",
          text: "Apakah anda yakin ingin update status?",
          icon: "warning",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#7cb342",
          cancelButtonText: "No",
      }).then((result) => {
          if (result.value) {
              var orderId = search.orderId;
              dispatch(updateStatus(orderId)).then((res) => {
                setModal(false);
                Swal.fire({
                  icon: 'success',
                  text: 'Update Status Success',
                  timer: 3000
                })
                console.log('redirectSchedule', search.zoomUrl);
                window.location.href = search.zoomUrl;
              }).catch((error) => {
                if (error.response) {
                    setTimeout(() => 
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.data.message,
                    }), 1000
                    )
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
          } 
        });
  }

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Search Order By ID</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        <div className="wrapper-form-title" id="btn-add">
          <span className="form-title">Search Order By ID</span>
        </div>

        <div className="border-bottom-section">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group mb-2">
                <label htmlFor="" className="sr-only">Order ID : </label>
                <input
                    type="input"
                    placeholder="Masukan order ID"
                    className="form-control"
                    // value={orderId}
                    onChange={(e) => setOrderId(e.target.value)}
                  />
              </div>
            </div>
            <div className="col-md-3">
            
           
              
            {orderByIds.loading ? (
            <button type="submit" className="btn btn-primary" disabled>
              Loading...
            </button>
          ) : (
            <button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => setQuery(true)}
            >
              Search
            </button>
          )}

            </div>
          </div>
        </div>

        <div className="table-responsive">
        <div className="row">
          <div className="col-md">
              <div className="form-group">
                <label className="form-label">Full Name</label>
                <input label="Patient Name" type="text" className="form-control" value={search.customerName} readOnly/>
              </div>

              <div className="form-group">
                <label className="form-label">DOB</label>
                <input label="DOB" type="text" className="form-control" value={search.customerDob} readOnly/>
              </div>

              <div className="form-group">
                <label className="form-label">Phone</label>
                <input label="Phone" type="text" className="form-control" value={search.customerPhone} readOnly/>
              </div>
              <div className="form-group">
                <label className="form-label">Email</label>
                <input label="Phone" type="text" className="form-control" value={search.customerEmail} readOnly/>
              </div>
              <div className="form-group">
                <label className="form-label">Hospital</label>
                <input label="Phone" type="text" className="form-control" value={search.hospitalName} readOnly/>
              </div>
              <div className="form-group">
                <label className="form-label">Test Date</label>
                  <input label="Phone" type="text" className="form-control" value={search.testDate} readOnly/>
              </div>
              <div className="form-group">
                <label className="form-label">Status</label>
                <input label="Phone" type="text" className="form-control" value={search.statusDesc} readOnly/>
              </div>
              {orderByIds.loading ? (
                <button type="submit" className="btn btn-primary" disabled>
                  Loading...
                </button>
              ) : (
                <a onClick={submitStatus}>
                    <button
                      type="button"
                      className="btn btn-primary"
                      id="button-refund"
                      data-toggle="modal"
                    >
                      Konfirmasi Kedatangan
                    </button>
                  </a>
              )}
          </div>
        </div>
        </div>

      </div>
    </DashboardLayout>
  );
};

export default SearchOrderById;
