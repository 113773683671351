import { dailyReportConstants } from "../actions/constants";

const initState = {
  orders: [],
  orders2: [],
  loading: false,
  error: null,
};

export default (state = initState, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case dailyReportConstants.GET_DAILY_REPORT_SUCCESS:
        state = {
          ...state,
          orders: action.payload.orders,
          orders2: action.payload.orders2,
          loading: false,
        };
        break;
      case dailyReportConstants.GET_DAILY_REPORT_REQUEST:
        state = {
          ...state,
          loading: true,
        };
        break;
      case dailyReportConstants.GET_DAILY_REPORT_FAILURE:
        state = {
          ...state,
          error: action.payload.error,
          loading: false,
        };
        break;
    }
    return state;        
  };
  