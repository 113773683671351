import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import DashboardLayout from "../Layout";
import Input from "../Components/Input";
import Modal from "../Components/Modal";
import queryString from "query-string";
// Query
import { useDispatch, useSelector } from "react-redux";
import {
  getDoktertWorklist,
  generatePrescription,
  sendEmailDocument,
  createMedicalRecordsV2,
  uploadConsentFile,
  uploadPharmacyFile,
  addFreeTeleSummary,
  sendReminderToPatient
} from "../../../actions";
// Helpers
import { formatDate } from "../../../helpers/getFormattedDate";
import moment from "moment";
// Style
import "./dokterworklist.scss";
// Pagination
import Pagination from "../../../components/Pagination";

// Alert
import Swal from "sweetalert2";
import { Link, useHistory, useLocation } from "react-router-dom";

export default function DokterWorklist() {
  // Query
  const [addPrescriptionModal, setAddPrescriptionModal] = useState(false);
  const [prescriptionData, setPrescriptionData] = useState({
    orderId: "",
    doctorName: "",
    SIPNumber: "",
    patientName: "",
    age: "",
    prescription: "",
    prescriptionType: "",
    gender: "",
  });
  const [addSummaryModal, setAddSummary] = useState(false);
  const [addMedicalModal, setAddMedicalModal] = useState(false);
  const [medicalData, setMedicalData] = useState({
    orderId: "",
    patientName: "",
    patientPhone: "",
    patientDob: "",
    age: "",
    gender: "",
    complaints: "",
    medicalRecordId: "",
    analysis: "",
    carePlan: "",
    diagnosis: "",
    referToHospital: "",
    referToHospitalNote: "",
    pharmacyPhoto: "",
    consentPhoto: "",
    customResep: "",
    doctorName: "",
    SIPNumber: "",
    age: "",
    gender: "",
  });
  const [loading, setLoading] = useState(false);
  const result = useSelector((state) => state.dokterWorklist);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [query, setQuery] = useState(state && state.q ? `page=${state.q.page}&limit=${state.q.limit}` : "");

  let history = useHistory();

  useEffect(() => {
    dispatch(getDoktertWorklist(query));
  }, [query, setQuery, dispatch]);

  // Pagination
  const [paginationProps, setPaginationProps] = useState({
    page: state && state.q && state.q.page ? state.q.page : 1,
    limit: state && state.q && state.q.limit ? state.q.limit : 10,
    totalData: state && state.q && state.q.totalData ? state.q.totalData : 0
  });

  useEffect(() => {
    setPaginationProps((prev) => {
      return {
        ...prev,
        totalData: result.totalData,
      };
    });
  }, [result]);

  useEffect(() => {
    console.log(result.urlPharmacy);
    var urlPharmacy = result.urlPharmacy ? result.urlPharmacy.url : null;
    setMedicalData({
      ...medicalData,
      pharmacyPhoto: urlPharmacy,
    })
  }, [result.urlPharmacy]);

  useEffect(() => {
    console.log(result.urlConsent);
    var urlConsent = result.urlConsent ? result.urlConsent.url : null;
    setMedicalData({
      ...medicalData,
      consentPhoto: urlConsent,
    })
  }, [result.urlConsent]);

  // Filter
  const dateFrom = useRef(null);
  const dateTo = useRef(null);
  const patientName = useRef(null);

  // Submit Query & Pagination
  const submitFilter = (page, limit) => {
    let values = {};
    if (page) {
      values.page = page;
    } else {
      // Reset if searched
      values.page = 1;
      setPaginationProps((prev) => {
        return {
          ...prev,
          page: 1,
        };
      });
    }

    if (limit) {
      values.limit = limit;
    }

    if (patientName.current.value !== "") {
      values.searchKey = patientName.current.value;
    }
    
    if (dateFrom.current.value !== "") {
      values.startDate = dateFrom.current.value;
    }

    if (dateTo.current.value !== "") {
      values.endDate = dateTo.current.value;
    }

    let query = queryString.stringify(values);
    setQuery(query);
  };

  // reset filter & pagination
  const handleReset = (page, limit) => {
    dateFrom.current.value = "";
    dateTo.current.value = "";
    patientName.current.value = "";

    let values = {};
    if (page) {
      values.page = page;
    } else {
      // Reset if searched
      values.page = 1;
      setPaginationProps((prev) => {
        return {
          ...prev,
          page: 1,
        };
      });
    }

    if (limit) {
      values.limit = limit;
    }

    if (patientName.current.value !== "") {
      values.searchKey = patientName.current.value;
    }
    
    if (dateFrom.current.value !== "") {
      values.startDate = dateFrom.current.value;
    }

    if (dateTo.current.value !== "") {
      values.endDate = dateTo.current.value;
    }

    let query = queryString.stringify(values);
    setQuery(query);
  };

  const handleAddPrescription = (val) => {
    setAddPrescriptionModal(true);
    setPrescriptionData({
      orderId: val.order_id,
      doctorName: val.doctor_firstname+" "+val.doctor_lastname,
      SIPNumber: val.SIPNumber ? val.SIPNumber : "",
      patientName: val.patient_name,
      age: val.age,
      prescription: "",
      gender: val.gender,
      prescriptionType: "",
    });
  };

  const handleCloseModal = () => {
    setPrescriptionData({
      orderId: "",
      doctorName: "",
      SIPNumber: "",
      patientName: "",
      age: "",
      prescription: "",
      gender: "",
      prescriptionType: "",
    });
    setAddPrescriptionModal(false);
  };

  const actionAddPrescription = () => {
    
    const form = new FormData();
      form.append("orderId", prescriptionData.orderId);
      form.append("doctorName", prescriptionData.doctorName);
      form.append("SIPNumber", prescriptionData.SIPNumber);
      form.append("patientName", prescriptionData.patientName);
      form.append("age", prescriptionData.age);
      form.append("prescription", prescriptionData.prescription);
      form.append("gender", prescriptionData.gender);
      form.append("prescriptionType", prescriptionData.prescriptionType);

      setLoading(true);

      dispatch(generatePrescription(form))
        .then(() => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Data Prescription created",
            showConfirmButton: false,
          });
          window.location = "/dokter-worklist"
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            Swal.fire({
              icon: "error",
              text: err.response.data.message,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: err.message,
            });
          }
        });

      setAddPrescriptionModal(false);
  };

  const handlePrescriptionType = (val) => {
    document.getElementById("prescriptionVal").readOnly = true;
    if(val == "A"){
      document.getElementById("prescriptionVal").readOnly = true;
      setPrescriptionData({
        ...prescriptionData,
        prescription: "R/ MULTIVITAMIN      10 TABLET \n   1x1",
        prescriptionType: "A",
      });
    }
    else if(val == "B"){
      document.getElementById("prescriptionVal").readOnly = true;
      var prescriptionValue = "R/ FAVIPIRAVIR 200 MG    40 TABLET \n"+
                              "   2X8 TABLET (HARI 1) \n"+
                              "   2X3 TABLET (HARI 2-5) \n\n"+
                              "R/ MULTIVITAMIN          10 TABLET \n"+
                              "   1x1 \n\n"+
                              "R/ PARACETAMOL 500 MG    10 TABLET \n"+
                              "   3x1 (BILA DEMAM)"
      setPrescriptionData({
        ...prescriptionData,
        prescription: prescriptionValue,
        prescriptionType: "B",
      });
    }
    else if(val == "C"){
      document.getElementById("prescriptionVal").readOnly = false;
      setPrescriptionData({
        ...prescriptionData,
        prescription: "",
        prescriptionType: "C",
      });
    }
  };

  const renderAddPrescription = () => {
    return (
      <Modal
        modalTitle="Add Prescription"
        show={addPrescriptionModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        onSubmit={actionAddPrescription}

      >
        <Row>
          <Col md>
            <Input
              type="text"
              label="Detail Patient" readOnly={true}
              value={prescriptionData.patientName + ", " + prescriptionData.age + " tahun, "+prescriptionData.gender}
            />
          </Col>
        </Row>
        <Row>
          <Col md>
            <Input
              type="text"
              label="Nama Dokter" readOnly={true}
              value={prescriptionData.doctorName + ", SIP : " + prescriptionData.SIPNumber}
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Chose Medicine</label>
              <div className="input-group">
                <select
                  className="form-control"
                  value={prescriptionData.prescriptionType}
                  onChange={(e) => handlePrescriptionType(e.target.value)}
                >
                  <option value="">- Choose Medicine -</option>
                  <option value="A">Paket A</option>
                  <option value="B">Paket B</option>
                  <option value="C">Paket C</option>
                </select>
              </div>
            </div>
          </Col>
        </Row>
        
        <Row>
          <Col md>
            <div className="form-group">
              <label>Detail Medicine</label>
              <textarea id="prescriptionVal"
                rows="3" readOnly={true}
                className="form-control"
                value={prescriptionData.prescription}
                onChange={(e) => setPrescriptionData({
                  ...prescriptionData,
                  prescription: e.target.value,
                })}
              />
            </div>
          </Col>
        </Row>
      </Modal>
    );
  };

  const sendToPatient = (orderId) => {
    setLoading(true);

    dispatch(sendEmailDocument(orderId))
      .then(() => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Prescription successfully sent to patient",
          showConfirmButton: false,
        });
        window.location = "/dokter-worklist"
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  }

  const remindPatient = (orderId) => {
    setLoading(true);

    var formOrder = new FormData();
    formOrder.append("orderId", orderId);
    dispatch(sendReminderToPatient(formOrder))
      .then(() => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Send Reminder Success.",
          showConfirmButton: true,
        });
        window.location = "/dokter-worklist"
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  }

  const handleAddMedical = (val) => {
    console.log(val.pharmacyPhoto);
    console.log(val.consentPhoto);
    setAddMedicalModal(true);
    setMedicalData({
      orderId: val.order_id,
      patientName: val.patient_name,
      patientPhone: val.patient_phone,
      patientDob: val.patient_dob,
      age: val.age,
      gender: val.gender,
      complaints: val.complaints,
      medicalRecordId: val.medicalRecordId,
      analysis: val.analysis,
      carePlan: val.carePlan,
      diagnosis: val.diagnosis,
      referToHospital: val.referToHospital,
      referToHospitalNote: val.referToHospitalNote,
      pharmacyPhoto: val.pharmacyPhoto,
      consentPhoto: val.consentPhoto,
      doctorName: val.doctor_firstname+" "+val.doctor_lastname,
      SIPNumber: val.SIPNumber ? val.SIPNumber : "",
      age: val.age,
      gender: val.gender,
      customResep: val.customResep,
    });
  };

  const handleCloseModalMedical = () => {
    setMedicalData({
      orderId: "",
      patientName: "",
      patientPhone: "",
      patientDob: "",
      age: "",
      gender: "",
      complaints: "",
      medicalRecordId: "",
      analysis: "",
      carePlan: "",
      diagnosis: "",
      referToHospital: "",
      referToHospitalNote: "",
      pharmacyPhoto: "",
      consentPhoto: "",
      customResep: "",
      doctorName: "",
      SIPNumber: "",
      age: "",
      gender: "",
    });
    setAddMedicalModal(false);
  };

  const actionAddMedical = () => {
    
    const form = new FormData();

      form.append("orderId", medicalData.orderId);
      form.append("complaints", medicalData.complaints);
      form.append("analysis", medicalData.analysis);
      form.append("carePlan", medicalData.carePlan);
      form.append("diagnosis", medicalData.diagnosis);
      form.append("referToHospital", medicalData.referToHospital);
      form.append("referToHospitalNote", medicalData.referToHospitalNote);
      form.append("patientName", medicalData.patientName);
      form.append("patientPhone", medicalData.patientPhone);
      form.append("doctorName", medicalData.doctorName);
      form.append("SIPNumber", medicalData.SIPNumber);
      form.append("age", medicalData.age);
      form.append("gender", medicalData.gender);

      if(medicalData.medicalRecordId){
        form.append("medicalRecordId", medicalData.medicalRecordId);
      }
      if(medicalData.pharmacyPhoto){
        form.append("pharmacyPhoto", medicalData.pharmacyPhoto);
      }
      if(medicalData.consentPhoto){
        form.append("consentPhoto", medicalData.consentPhoto);
      }

      if(medicalData.customResep){
        form.append("customResep", medicalData.customResep);
      }

      setLoading(true);

      dispatch(createMedicalRecordsV2(form))
        .then(() => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Medical Records created",
            showConfirmButton: false,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            Swal.fire({
              icon: "error",
              text: err.response.data.message,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: err.message,
            });
          }
        });

      setAddPrescriptionModal(false);
  };

  const renderAddMedical = () => {
    return (
      <Modal
        modalTitle="Add Medical Records"
        show={addMedicalModal}
        size="lg"
        handleClose={() => handleCloseModalMedical()}
        onSubmit={actionAddMedical}

      >
        <Row>
          <Col md>
            <Input
              type="text"
              label="Nama Pasien" readOnly={true}
              value={medicalData.patientName}
            />
          </Col>
          <Col md>
            <Input
              type="text"
              label="Jenis Kelamin" readOnly={true}
              value={medicalData.gender}
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="text"
              label="Tanggal Lahir" readOnly={true}
              value={formatDate(medicalData.patientDob)}
            />
          </Col>
          <Col md>
            <Input
              type="text"
              label="Usia" readOnly={true}
              value={medicalData.age +" tahun"}
            />
          </Col>
        </Row>
        
        <Row>
          <Col md>
            <div className="form-group">
              <label>Complaints</label>
              <textarea 
                rows="3" 
                className="form-control"
                value={medicalData.complaints}
                onChange={(e) => setMedicalData({
                  ...medicalData,
                  complaints: e.target.value,
                })}
              />
            </div>
          </Col>
          <Col md>
            <div className="form-group">
              <label>Pemeriksaan Fisik</label>
              <textarea 
                rows="3" 
                className="form-control"
                value={medicalData.analysis}
                onChange={(e) => setMedicalData({
                  ...medicalData,
                  analysis: e.target.value,
                })}
              />
            </div>
          </Col>
        </Row>

        <Row>
        <Col md>
            <div className="form-group">
              <label>Diagnosa</label>
              <textarea 
                rows="3" 
                className="form-control"
                value={medicalData.diagnosis}
                onChange={(e) => setMedicalData({
                  ...medicalData,
                  diagnosis: e.target.value,
                })}
              />
            </div>
          </Col>
          <Col md>
            <div className="form-group">
              <label>Rencana Perawatan</label>
              <textarea
                rows="3" 
                className="form-control"
                value={medicalData.carePlan}
                onChange={(e) => setMedicalData({
                  ...medicalData,
                  carePlan: e.target.value,
                })}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <input
                type="checkbox" style={{margin:"0px 20px 0px 10px"}}
                checked={medicalData.referToHospital} 
                onChange={handleCheck}
              />
              <label>Refer to Hospital</label>
              <textarea id="referToHospitalNote"
                rows="3"  readOnly={true}
                className="form-control"
                value={medicalData.referToHospitalNote}
                onChange={(e) => setMedicalData({
                  ...medicalData,
                  referToHospitalNote: e.target.value,
                })}
              />
            </div>
          </Col>
          <Col md>
            <div className="form-group">
              <label>Custom Resep</label>
              <textarea id="customResep"
                rows="3"
                className="form-control"
                value={medicalData.customResep}
                onChange={(e) => setMedicalData({
                  ...medicalData,
                  customResep: e.target.value,
                })}
              />
            </div>
          </Col>
        </Row>

      </Modal>
    );
  };

  const handleCheck = (e) => {
    let name = e.target.name,
      checked = e.target.checked;

      setMedicalData({ ...medicalData, referToHospital: checked })

      if(checked){
        document.getElementById("referToHospitalNote").readOnly = false;
      }
      else{
        setMedicalData({ ...medicalData, referToHospitalNote: '', referToHospital: checked });
        document.getElementById("referToHospitalNote").readOnly = true;
      }

  };

  const uploadDocumentConsent = () => {
    var uploadconsent = document.getElementById("uploadconsent").files;
    dispatch(uploadConsentFile(medicalData.orderId, uploadconsent[0]))
    .then(() => {
      document.getElementById('iconSuccessConsent').style.display = "inline-block";
      Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
      }).fire({
          icon: 'success',
          title: 'Upload Consent Success'
      })
    })
  };

  const uploadDocumentResep = () => {
    var uploadresep = document.getElementById("uploadresep").files;
    dispatch(uploadPharmacyFile(medicalData.orderId, uploadresep[0]))
    .then(() => {
      document.getElementById('iconSuccessResep').style.display = "inline-block";
      Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
      }).fire({
          icon: 'success',
          title: 'Upload Resep Success'
      })
    })
  };

  const handleAddSummary = (val) => {
    setAddSummary(true);
    setMedicalData({
      orderId: val.order_id,
      patientName: val.patient_name,
      patientDob: val.patient_dob,
      age: val.age,
      gender: val.gender,
      medicalRecordId: val.medicalRecordId,
      diagnosis: val.diagnosis,
      referToHospital: val.referToHospital,
      referToHospitalNote: val.referToHospitalNote,
    });
  };

  const handleCloseModalSummary = () => {
    setMedicalData({
      orderId: "",
      patientName: "",
      patientDob: "",
      age: "",
      gender: "",
      medicalRecordId: "",
      diagnosis: "",
      referToHospital: "",
      referToHospitalNote: "",
    });
    setAddSummary(false);
  };

  const actionAddSummary = () => {
    
    const form = new FormData();

      form.append("orderId", medicalData.orderId);
      form.append("diagnosis", medicalData.diagnosis);
     
      form.append("referToHospitalNote", medicalData.referToHospitalNote);
      if (medicalData.referToHospitalNote != ""){
        form.append("referToHospital", medicalData.referToHospital);
      }

      if(medicalData.medicalRecordId){
        form.append("medicalRecordId", medicalData.medicalRecordId);
      }

      setLoading(true);

      dispatch(addFreeTeleSummary(form))
        .then(() => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: medicalData.medicalRecordId ? "Medical Records Summary updated" : "Medical Records Summary created",
            showConfirmButton: false,
          });
          window.location = "/dokter-worklist"
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            Swal.fire({
              icon: "error",
              text: err.response.data.message,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: err.message,
            });
          }
        });

        setAddSummary(false);
  };

  const renderAddSummary = () => {
    return (
      <Modal
        modalTitle={medicalData.medicalRecordId ? "Medical Records Summary" : "Add Medical Records Summary"}
        show={addSummaryModal}
        size="lg"
        handleClose={() => handleCloseModalSummary()}
        onSubmit={actionAddSummary}

      >
        <Row>
          <Col md>
            <Input
              type="text"
              label="Nama Pasien" readOnly={true}
              value={medicalData.patientName}
            />
          </Col>
          <Col md>
            <Input
              type="text"
              label="Jenis Kelamin" readOnly={true}
              value={medicalData.gender}
            />
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="text"
              label="Tanggal Lahir" readOnly={true}
              value={formatDate(medicalData.patientDob)}
            />
          </Col>
          <Col md>
            <Input
              type="text"
              label="Usia" readOnly={true}
              value={medicalData.age +" tahun"}
            />
          </Col>
        </Row>
        
        <Row>
          <Col md>
            <div className="form-group">
              <label>Diagnosa</label>
              <textarea 
                rows="3" 
                className="form-control"
                value={medicalData.diagnosis}
                onChange={(e) => setMedicalData({
                  ...medicalData,
                  diagnosis: e.target.value,
                })}
              />
            </div>
          </Col>
          <Col md>
            <div className="form-group">
              <label>Recommendation</label>
              <textarea id="referToHospitalNote"
                rows="3"
                className="form-control"
                value={medicalData.referToHospitalNote}
                onChange={(e) => setMedicalData({
                  ...medicalData,
                  referToHospitalNote: e.target.value,
                })}
              />
            </div>
          </Col>
        </Row>

      </Modal>
    );
  };

  const filterProps = {
    dateFrom,
    dateTo,
    patientName,
    submitFilter,
    handleReset,
  };

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  return (
    <DashboardLayout>
      <Layout>        
        {/* Filter */}
        <Filter {...filterProps} />
        {/* Table */}
        {loading ? <Loading /> : (
          <div className="table-responsive">
            <table
              id="tableScheduler"
              className="table table-striped table-bordered"
            >
              <thead className="table-header">
                {/* Rows: 17 */}
                { 
                  localStorage.getItem("parentId") == 3295 ? (
                <>
                  <tr>
                    <th rowSpan={2} className="table-header-middle">
                      No
                    </th>
                    <th rowSpan={2} className="table-header-middle">
                      Schedule Date
                    </th>
                    <th colSpan={2} className="table-header-middle">
                      Time
                    </th>    
                    <th rowSpan={2} className="table-header-middle">
                      Order ID
                    </th>
                    <th rowSpan={2} className="table-header-middle">
                      Patient Name
                    </th>
                    <th rowSpan={2} className="table-header-middle">
                      Patient Phone
                    </th>         
                    <th rowSpan={2} className="table-header-middle">
                      Keluhan
                    </th>
                    <th rowSpan={2} className="table-header-middle">
                      Type
                    </th>
                    <th rowSpan={2} className="table-header-middle">
                      Doctor
                    </th>
                    <th rowSpan={2} className="table-header-middle">
                      Status
                    </th>
                    <th rowSpan={2} className="table-header-middle">
                      Action
                    </th>
                  </tr>
                  <tr>
                   <th>Start</th>
                   <th>End</th>
                 </tr>
                </>
              ) : <>
                <tr>
                  <th rowSpan={2} className="table-header-middle">
                    No
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    Order ID
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    Patient Name
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    DOB
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    Customer Phone
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    Patient Phone
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    Schedule Date
                  </th>
                  <th colSpan={2} className="table-header-middle">
                    Time
                  </th>             
                  <th rowSpan={2} className="table-header-middle">
                    Doctor
                  </th>
                  { 
                    localStorage.getItem("parentId") == 3295 ? (
                      <th rowSpan={2} className="table-header-middle">
                        Type
                      </th>) : <></> 
                  }
                  <th rowSpan={2} className="table-header-middle">
                    Keluhan
                  </th>
                  <th rowSpan={2} className="table-header-middle">
                    Action
                  </th>
                </tr>
                <tr>
                  <th>Start</th>
                  <th>End</th>
                </tr>
              </> 
              }
              </thead>
              <tbody>
                {DataTables(result, paginationProps, handleAddPrescription, sendToPatient, remindPatient, handleAddMedical, handleAddSummary)}
              </tbody>
            </table>
          </div>
        )}
        {/* Pagination */}
        <Pagination
          props={paginationProps}
          setProps={setPaginationProps}
          onChange={(page, limit) => {
            submitFilter(page, limit);
          }}
        />
      </Layout>
      {renderAddPrescription()}
      {renderAddMedical()}
      {renderAddSummary()}
    </DashboardLayout>
  );
}

const DataTables = (result, paginationProps, handleAddPrescription, sendToPatient, remindPatient, handleAddMedical, handleAddSummary) => {
  // Response Data
  const orders = result?.orders || [];
  
  return (
    <>
      {/* Empty */}
      {orders.length === 0 && (
        <tr>
          <td colSpan="18">Empty Data</td>
        </tr>
      )}

      {/* Has Data */}
      { 
        localStorage.getItem("parentId") == 3295 ?
        (orders.map((value, index) => {
          return (
            <>
              <tr key={index}>
                <td>{(paginationProps.page - 1) * 10 + index + 1}</td>
                <td width={"150px"}>{formatDate(value.scheduleDate)} 
                </td>
                <td>{moment(value.startTime, "HH:mm:ss").format("HH:mm")}</td>
                <td>{moment(value.endTime, "HH:mm:ss").format("HH:mm")}</td>
                <td>{value.order_id}</td>
                <td>{value.patient_name}</td>
                <td>{value.patient_phone}</td>
                <td>{value.complaints}</td>
                <td>{!value.isHealthScreening && !value.isPremium ? "Free Tele" : !value.eventId && value.isPremium ? "Tanya Dokter Premium" : value.isHealthScreening ? "Health Screening" : ""}</td>
                <td>{value.doctor_name} { value.doctor_firstname ? ` - ${value.doctor_firstname} ${value.doctor_lastname}` : ""}</td>        
                <td style={{ width: "160px", textAlign: "justify" }}>
                  <div>
                      <label className="mb-0" htmlFor="patientNotJoined" style={{ wordWrap: "break-word" }}>
                      <input
                        type="checkbox"
                        className="mb-1 mr-1"
                        name="patientNotJoined"
                        checked={value.patientNotJoined ? true : false}
                        disabled
                        style={{ verticalAlign: "middle", width: "12px", height: "12px" }}
                      />No Patient</label>
                  </div> 
                  <div>                  
                      <label className="mb-0" htmlFor="medicalRecordId" style={{ wordWrap: "break-word" }}>
                      <input
                        type="checkbox"
                        className="mb-1 mr-1"
                        name="medicalRecordId"
                        checked={value.medicalRecordId ? true : false}
                        disabled
                        style={{ verticalAlign: "middle", width: "12px", height: "12px" }}
                      />Medical Record</label>
                  </div>
                </td>        
                <td width={"200px"} style={{ verticalAlign: "middle"}}>
                  {(value.isTeleAido == 1 || value.isTeleAido == 3) && (!value.isHealthScreening) ? (
                  <>
                    <Link
                        to={{ pathname: "/dokter-worklist/detail", state: { orderId: value.order_id, q: paginationProps} }}
                      >
                      <div style={{justifyContent: "center"}}>
                        <button id="mr-button" type="button" className="btn btn-outline-primary"
                          >
                          Medical Record
                        </button>
                      </div>
                    </Link>
                  </>
                  ) : (<></>)}

                  {value.isTeleAido == 2 || value.isHealthScreening ? (
                    <>
                      <div style={{marginTop:"10px"}}>
                        <Link
                          to={{ pathname: "/dokter-worklist/detail", state: { orderId: value.order_id, q: paginationProps} }}
                          >
                          <button id="mr-button" type="button" className="btn btn-outline-info"
                            >
                            Medical Record
                          </button>
                        </Link>
                      </div>
                    </>)
                  : (<p></p>)}
                </td>
              </tr>
            </>
          )
         })) : 
        (orders.map((value, index) => {
          return (
          <tr key={index}>
            <td>{(paginationProps.page - 1) * 10 + index + 1}</td>
            <td
              // className={style.clickableTd}
              // onClick={() => {
              //   showOrderDetails(value.order_id);
              // }}
            >
              {value.order_id}
            </td>
            <td>{value.patient_name}</td>
            <td>{formatDate(value.patient_dob)}</td>
            <td>{value.patient_phone}</td>
            <td>{value.customer_phone}</td>
            <td>{formatDate(value.scheduleDate)}</td>
            <td>{moment(value.startTime, "HH:mm:ss").format("HH:mm")}</td>
            <td>{moment(value.endTime, "HH:mm:ss").format("HH:mm")}</td>
            <td>{value.doctor_name} { value.doctor_firstname ? ` - ${value.doctor_firstname} ${value.doctor_lastname}` : ""}</td>
            {
              localStorage.getItem("parentId") == 3295 ? (
                <td>{!value.isHealthScreening && !value.isPremium ? "Free Tele" : !value.eventId && value.isPremium ? "Tanya Dokter Premium" : value.isHealthScreening ? "Health Screening" : ""}</td>
              ) : <></>
            }
            <td>{value.complaints}</td>
            <td width={"250px"} style={{ verticalAlign: "middle"}}>
              {
                localStorage.getItem("parentId") != 3295 ? 
                (<div>
                  <a href={value.zoom_url} target="_blank">
                    <button type="button" className="btn btn-outline-info">
                      Join Meeting
                    </button>
                  </a>
                 </div>) : <></>
              }

              {(value.isTeleAido == 1 || value.isTeleAido == 3) && (!value.isHealthScreening) ? (
              <>
                <Link
                    to={{ pathname: "/dokter-worklist/detail", state: { orderId: value.order_id, q: paginationProps} }}
                  >
                  <div style={{justifyContent: "center"}}>
                    <button type="button" className="btn btn-outline-primary" 
                      // onClick={() => handleAddSummary(value)}
                      >
                      {/* {value.medicalRecordId ? "View Summary" : "Create Summary"} */}
                      Medical Record
                    </button>
                  </div>
                </Link>
                {/* <div style={{marginTop:"10px"}}>
                    <button type="button" className="btn btn-outline-info" onClick={() => handleAddPrescription(value)}>
                      Create Prescription
                    </button>
                </div> */}
                    {/* {value.docUrl ? (
                      <>
                      <div style={{marginTop: "10px"}}>
                        <a href={value.docUrl} target="_blank"
                            className="btn btn-outline-info" style={{marginRight: "10px"}}>Prescription
                        </a>
                      </div>
                      <div style={{marginTop: "10px"}}>
                        <button className="btn btn-outline-info" onClick={() => sendToPatient(value.order_id)}>
                        Send To Patient
                        </button> 
                      </div>
                      </>
                    ) : (<></>)} */}

              </>
              ) : (<></>)}

              {value.isTeleAido == 2 || value.isHealthScreening ? 
              
              (
              <>
              {/* <div>
                  <a href={value.zoom_url} target="_blank">
                    <button type="button" className="btn btn-outline-info">
                      Join Meeting
                    </button>
                  </a>
              </div>
              <div style={{marginTop:"10px"}}>
                <button type="button" className="btn btn-outline-primary" onClick={() => remindPatient(value.order_id)}>
                  Remind Patient
                </button>
              </div> */}
              <div style={{marginTop:"10px"}}>
                <Link
                  to={{ pathname: "/dokter-worklist/detail", state: { orderId: value.order_id, q: paginationProps} }}
                  >
                  <button type="button" className="btn btn-outline-info" 
                    // onClick={() => handleAddMedical(value)}
                    >
                    Medical Record
                  </button>
                </Link>
                {/* {value.pharmacyPhoto && (!value.isHealthScreening) ? (
                      <>
                      <div style={{marginTop: "10px"}}>
                        <button className="btn btn-outline-info" onClick={() => sendToPatient(value.order_id)}>
                        Send To Patient
                        </button> 
                      </div>
                      </>
                    ) : (<></>)
                } */}
                {/* <div style={{marginTop: "10px"}}>
                {value.pharmacyPhoto ? (
                    <a href={value.pharmacyPhoto} target="_blank"
                        className="btn btn-outline-info" style={{marginRight: "10px"}}>Prescription 
                    </a>
                ) : (<p></p>)}
                {value.labResult ? (
                    <a href={value.labResult} target="_blank"
                        className="btn btn-outline-info">Lab Result
                    </a>
                ) : (<p></p>)}
                  </div> */}
              </div>
              </>)
              : (<p></p>)}
            </td>
          </tr>
          )
        }))
      }
    </>
  );
};

const Layout = ({ children }) => {
  // Default Layout
  return (
    <>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>
        <div>Dokter Worklist</div>
        <div className="col">
          <hr />
        </div>
      </div>
      <div className="table-wrapper">       
        {children}
      </div>
    </>
  );
};

const Filter = (props) => {
  const { dateFrom, dateTo, patientName, submitFilter, handleReset } = props;
  return (
    <>
      <div className="row mb15">
        <div className="link-btn">
          <button
            type="button"
            className="btn btn-link no-padding"
            id="filterBy"
            data-toggle="collapse"
            data-target="#collapseDetailSchedule"
          >
            Filter By
            <i className="fa fa-angle-down" style={{ marginLeft: "10px" }} />
          </button>
        </div>
        <div className="col">
          <hr />
        </div>
      </div>
      <div className="collapse" id="collapseDetailSchedule">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group mb-md-0">
              <label>Date From</label>
              <input type="date" className="form-control" ref={dateFrom} />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group mb-md-0">
              <label>Date To</label>
              <input type="date" className="form-control" ref={dateTo} />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group mb-md-0">
              <label>Patient Name</label>
              <input type="text" className="form-control" ref={patientName} />
            </div>
          </div>
        </div>
        <div className="fm-btn-gr text-right">
          <button
            type="button"
            className="btn btn-secondary"
            style={{ marginRight: "10px" }}
            onClick={() => {
              handleReset();
            }}
          >
            Reset
          </button>
          <button
            onClick={() => {
              submitFilter();
            }}
            type="button"
            className="btn btn-primary"
          >
            Apply
          </button>
        </div>

        <div style={{ width: "100%" }}>
          <hr />
        </div>
      </div>
    </>
  );
};
