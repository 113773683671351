import axios from "../helpers/axios";
import { myOrderConstants } from "./constants";

export const getOrderList = (q) => {
  return async (dispatch) => {
    dispatch({ type: myOrderConstants.GET_ALL_ORDER_REQUEST });
    const res = await axios.get(`/Order?${q}`);
    if (res.status === 200) {
      const { orders, sendBird, parentId, doctorList, totalData } = res.data;
      dispatch({
        type: myOrderConstants.GET_ALL_ORDER_SUCCESS,
        payload: { orders, sendBird, parentId, doctorList, totalData },
      });
    } else {
      dispatch({
        type: myOrderConstants.GET_ALL_ORDER_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getOrderDetail = (id) => {
  return async (dispatch) => {
    dispatch({ type: myOrderConstants.GET_ORDER_DETAIL_REQUEST });
    const res = await axios.get(`/Order/OrderDetail/${id}`);
    if (res.status === 200) {
      const { orderDetail } = res.data;
      dispatch({
        type: myOrderConstants.GET_ORDER_DETAIL_SUCCESS,
        payload: { orderDetail },
      });
    } else {
      dispatch({
        type: myOrderConstants.GET_ORDER_DETAIL_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getDoctorNamesByProfession = (professionId) => {
  return async (dispatch) => {
    dispatch({ type: myOrderConstants.GET_DOCTOR_NAMES_BY_PROFESSION_REQUEST });
    const res = await axios.get(`/Order/partner/list/${professionId}`);
    if (res.status === 200) {
      dispatch({
        type: myOrderConstants.GET_DOCTOR_NAMES_BY_PROFESSION_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: myOrderConstants.GET_DOCTOR_NAMES_BY_PROFESSION_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const updateOrderDoctor = (data) => {
  return async (dispatch) => {
    dispatch({ type: myOrderConstants.UPDATE_ORDER_DOCTOR_NAME_REQUEST });
    const res = await axios.post(`/Order/Doctor/Update`, data);
    if (res.status === 200) {
      dispatch({
        type: myOrderConstants.UPDATE_ORDER_DOCTOR_NAME_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: myOrderConstants.UPDATE_ORDER_DOCTOR_NAME_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
