import React, { useEffect, useRef, useState } from "react";
import { Col, Row, ThemeProvider } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getListPatients,
  getPatientById,
  getPackage,
  findUserByPhoneEmail,
  createOrderHomecare,
} from "../../../actions";
import Modal from "../Components/Modal";
import GoogleMap from "../Components/GoogleMap";
import Pagination from "../../../components/Pagination";
import DashboardLayout from "../Layout";
import queryString from "query-string";
import moment from "moment";
import { createTheme, Step, StepLabel, Stepper } from "@material-ui/core";
import Input from "../Components/Input";
import Swal from "sweetalert2";
import "./index.scss";

const RegisterOrder = () => {
  const [patientModal, setPatientModal] = useState(false);
  const [selectedPatientModal, setSelectedPatientModal] = useState(false);
  const [mapModal, setMapModal] = useState(false);
  const [location, setLocation] = useState(null);
  const [search, setSearch] = useState(
    `parentId=${localStorage.getItem("parentId")}&name=&birthday=`
  );
  const [checkAll, setCheckAll] = useState(false);
  const [checkId, setCheckId] = useState({});
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const [activeStep, setActiveStep] = useState(0);
  const [newPatientData, setNewPatientData] = useState({
    patientName: "",
    patientDob: "",
    patientGender: "",
    patientEmail: "",
    patientNational: "",
    patientNationalOption: "",
    patientPhone: "",
  });
  const [homecareService, setHomecareService] = useState({
    visitData: "",
    idHomecare: "",
    nameHomecare: "",
    qtyHomecare: "",
    priceHomecare: "",
    latitude: "",
    longitude: "",
    postalCode: "",
    addressHomecare: "",
    detailAddressHomecare: "",
    noteHomecare: "",
    complaintsHomecare: "",
    sourceHomecare: "",
    referenceHomecare: "",
  });
  const [validation, setValidation] = useState({
    patientName: false,
    patientNamePattern: false,
    patientDob: false,
    patientPhone: false,
    patientPhonePattern: false,
    patientEmail: false,
    patientEmailPattern: false,
    patientNational: false,
    patientNationalOption: false,
    patientGender: false,
    patientForm: false,
    idHomecare: false,
    visitData: false,
    sourceHomecare: false,
    referenceHomecare: false,
    addressHomecare: false,
    homecareServiceForm: false,
  });
  const [addHomecareServiceModal, setAddHomecareServiceModal] = useState(false);
  const [filterHomecare, setFilterHomecare] = useState("");
  const parentId = localStorage.getItem("parentId");
  const name = useRef("");
  const birthday = useRef("");
  const auth = useSelector((state) => state.auth);
  const register = useSelector((state) => state.register);
  const dispatch = useDispatch();

  const initState = () => {
    setNewPatientData({
      patientName: "",
      patientDob: "",
      patientGender: "",
      patientEmail: "",
      patientNational: "",
      patientNationalOption: "",
      patientPhone: "",
    });
    setFilterHomecare("");
  };

  useEffect(() => {
    if (name.current.value || birthday.current.value) {
      dispatch(getListPatients(search));
    }
  }, [search]);

  useEffect(() => {
    let values = {
        partnerId: localStorage.getItem("parentId"),
        packageType: 2,
      },
      query = queryString.stringify(values);
    dispatch(getPackage(query));
  }, []);

  useEffect(() => {
    let allChecked = true;

    for (const name in checkId) {
      if (checkId[name] === false) {
        allChecked = false;
      }
    }
    if (allChecked) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [checkId]);

  useEffect(() => {
    let getData = {};

    register.data.forEach((item) => {
      getData[item.id] = false;
    });
    setCheckId(getData);
    setPagination({
      ...pagination,
      totalData: register.totalData,
    });
  }, [register.data, register.totalData]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    showCloseButton: true,
    timer: 3000,
  });

  const handleCloseModal = () => {
    setPatientModal(false);
    setSelectedPatientModal(false);
    setAddHomecareServiceModal(false);
  };

  const handleReset = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      parentId: parentId,
      page: pagination.page,
      limit: pagination.limit,
    };
    if (name.current.value !== "" || name.current.value === "") {
      values.name = name.current.value = "";
    }
    if (birthday.current.value !== "" || birthday.current.value === "") {
      values.birthday = birthday.current.value = "";
    }

    let query = queryString.stringify(values, {
      skipEmptyString: true,
    });

    setSearch(query);
    register.data = [];
  };

  const handleSearch = (page, limit) => {
    let values = {
      parentId: parentId,
      name: name.current.value,
      birthday: birthday.current.value,
    };
    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }
    if (limit) {
      values.limit = limit;
    }

    let query = queryString.stringify(values, {
      skipEmptyString: true,
    });

    setSearch(query);
  };

  const handleNext = () => {
    setActiveStep((step) => step + 1);
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const handleCheck = (e, data) => {
    let name = data.id,
      checked = e.target.checked;

    if (e.target.name === "checkAll") {
      var allDatainPage = [];

      setCheckAll(checked);

      let getData = {};

      register.data.forEach((item) => {
        getData[item.id] = checked;
        if (checked == true) {
          allDatainPage.push({
            customerId: item.customerId,
            patientId: item.id,
            patientName: item.patientName,
            patientDob: item.birthday,
            patientGender: item.gender,
            patientEmail: item.email,
            patientNational: item.national_id_no,
            patientNationalOption: item.national_id_type,
            patientPhone: item.phone,
          });
        }
      });
      setCheckId(getData);
      setSelectedPatient(allDatainPage);
    } else {
      setCheckId((prevState) => {
        const newState = { ...prevState };
        newState[name] = !prevState[name];
        return newState;
      });
      if (checked == true) {
        setSelectedPatient([
          ...selectedPatient,
          {
            customerId: data.customerId,
            patientId: data.id,
            patientName: data.patientName,
            patientDob: data.birthday,
            patientGender: data.gender,
            patientEmail: data.email,
            patientNational: data.national_id_no,
            patientNationalOption: data.national_id_type,
            patientPhone: data.phone,
          },
        ]);
      } else {
        setSelectedPatient(
          [...selectedPatient].filter(
            (item, index) => item.patientId !== data.id
          )
        );
      }
    }
  };

  const handleCancel = () => {
    setLocation(null);
    setHomecareService({
      visitData: "",
      idHomecare: "",
      nameHomecare: "",
      qtyHomecare: "",
      priceHomecare: "",
      latitude: "",
      longitude: "",
      postalCode: "",
      addressHomecare: "",
      detailAddressHomecare: "",
      noteHomecare: "",
      complaintsHomecare: "",
      sourceHomecare: "",
      referenceHomecare: "",
    });
    setCheckId({});
    setSelectedPatient([]);
    setActiveStep(0);
  };

  const handleAddPatient = () => {
    const patternName = /^[a-zA-Z ]*$/;
    const patternEmail =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const patternPhone =
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

    setValidation({
      patientName: newPatientData.patientName ? false : true,
      patientNamePattern: patternName.test(newPatientData.patientName)
        ? false
        : true,
      patientDob: newPatientData.patientDob ? false : true,
      patientGender: newPatientData.patientGender ? false : true,
      patientPhone: newPatientData.patientPhone ? false : true,
      patientPhonePattern: patternPhone.test(newPatientData.patientPhone)
        ? false
        : true,
      patientEmail: newPatientData.patientEmail ? false : true,
      patientEmailPattern: patternEmail.test(newPatientData.patientEmail)
        ? false
        : true,
      patientNationalOption: newPatientData.patientNationalOption
        ? false
        : true,
      patientNational: newPatientData.patientNational ? false : true,
    });

    const readyForSubmit = () => {
      if (
        newPatientData.patientName === "" ||
        newPatientData.patientDob === "" ||
        newPatientData.patientPhone === "" ||
        patternPhone.test(newPatientData.patientPhone) === false ||
        newPatientData.patientGender === "" ||
        newPatientData.patientEmail === "" ||
        patternPhone.test(newPatientData.patientPhone) === false ||
        newPatientData.patientNationalOption === "" ||
        newPatientData.patientNational === ""
      ) {
        return false;
      }
      return true;
    };

    if (readyForSubmit()) {
      dispatch(
        findUserByPhoneEmail(
          "phone=" +
            newPatientData.patientPhone +
            "&email=" +
            newPatientData.patientEmail
        )
      )
        .then(() => {
          setSelectedPatient([
            ...selectedPatient,
            {
              patientName: newPatientData.patientName,
              patientDob: newPatientData.patientDob,
              patientGender: newPatientData.patientGender,
              patientEmail: newPatientData.patientEmail,
              patientNational: newPatientData.patientNational,
              patientNationalOption: newPatientData.patientNationalOption,
              patientPhone: newPatientData.patientPhone,
            },
          ]);
          initState();
        })
        .catch((err) => {
          if (err.response) {
            return Swal.fire({
              icon: "error",
              text: err.response.data.message,
            });
          } else {
            return Swal.fire({
              icon: "error",
              text: err.message,
            });
          }
        });
    }
  };
  const handleData = (addressData) => {
    setLocation({
      latitude: addressData.latitude,
      longitude: addressData.longitude,
      fullAddress: addressData.fullAddress,
      postalCode: addressData.postalCode,
    });
  };

  const actionSetLocation = () => {
    if (location) {
      setHomecareService({
        ...homecareService,
        addressHomecare: location.fullAddress,
        postalCode: location.postalCode,
        latitude: location.latitude,
        longitude: location.longitude,
      });
    }
    setMapModal(false);
  };

  const handleAddHomecareService = () => {
    setAddHomecareServiceModal(true);
  };

  const handleCheckPackageData = (data) => {
    setValidation({
      ...validation,
      homecareServiceForm: data.packageId,
    });
    setHomecareService({
      ...homecareService,
      nameHomecare: data.serviceName,
      idHomecare: data.serviceId,
      qtyHomecare: data.qty,
    });
    Toast.fire({
      icon: "success",
      title: "Data Selected",
    });
    setAddHomecareServiceModal(false);
    initState();
  };

  const validateServicePackage = () => {
    const alphanumeric = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/;
    setValidation({
      idHomecare: homecareService.idHomecare ? false : true,
      visitData: homecareService.visitData ? false : true,
      sourceHomecare: homecareService.sourceHomecare
        ? alphanumeric.test(homecareService.sourceHomecare)
          ? false
          : true
        : false,
      referenceHomecare: homecareService.referenceHomecare
        ? alphanumeric.test(homecareService.referenceHomecare)
          ? false
          : true
        : false,
      addressHomecare: homecareService.addressHomecare ? false : true,
    });

    const readyForNext = () => {
      if (
        homecareService.idHomecare === "" ||
        homecareService.visitData === "" ||
        homecareService.addressHomecare === ""
      ) {
        return false;
      }
      return true;
    };

    if (readyForNext()) {
      handleNext();
    }
  };

  const handleUnselectPatient = (id) => {
    setSelectedPatient(
      [...selectedPatient].filter((item, index) => item.patientId !== id)
    );
    setCheckId((prevState) => {
      const newState = { ...prevState };
      newState[id] = false;
      return newState;
    });
  };

  const showSelected = () => {
    setSelectedPatientModal(true);
  };

  const handleDetailPatient = (dataId) => {
    dispatch(getPatientById(dataId)).then(setPatientModal(true));
  };

  const renderRegister = () => {
    return register.loading ? (
      <tr>
        <td colSpan="10">
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        {register.data.length > 0 ? (
          register.data.map((data, index) => (
            <tr key={index}>
              <td>
                <input
                  type="checkbox"
                  name={data.id}
                  checked={checkId[data.id]}
                  onChange={(e) => handleCheck(e, data)}
                />
              </td>
              <td>{(pagination.page - 1) * 10 + index + 1}</td>
              <td>{data.id ? data.id : "-"}</td>
              <td>{data.patientName ? data.patientName : "-"}</td>
              <td>
                {data.birthday
                  ? moment(data.birthday).format("DD MMMM YYYY")
                  : "-"}
              </td>
              <td>{data.phone ? data.phone : "-"}</td>
              <td>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <span
                    className="form-title"
                    style={{
                      cursor: "pointer",
                      width: "45px",
                      fontSize: "16px",
                    }}
                    onClick={() => handleDetailPatient(data.id)}
                  >
                    <i className="fa fa-eye" style={{ color: "#00acef" }}></i>
                  </span>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <>
            <tr>
              <td colSpan="10">Empty data</td>
            </tr>
            {/* <tr> */}
            <td colSpan={10}>
              <div className="row justify-content-center">
                {/* <Link to={{ pathname: "/register-order/add" }}> */}

                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => setActiveStep(1)}
                >
                  Create Order
                </button>
                {/* </Link> */}
              </div>
            </td>
            {/* </tr> */}
          </>
        )}
      </>
    );
  };

  const renderShowDetail = () => {
    if (!register.userData) {
      return null;
    }

    let patient = register.userData;

    return (
      <Modal
        modalTitle="Patient Data"
        show={patientModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        buttons={[]}
      >
        <Row>
          <Col md>
            <div className="form-group">
              <div style={{ display: "flex" }}>
                <span className="col-4">Full Name</span>
                <span className="col">{`: ${
                  patient.patientName ? patient.patientName : "-"
                }`}</span>
              </div>

              <div style={{ display: "flex" }}>
                <span className="col-4">Date of Birth</span>
                <span className="col">
                  {`: ${
                    patient.birthday
                      ? moment(patient.birthday).format("LL")
                      : "-"
                  }`}
                </span>
              </div>

              <div style={{ display: "flex" }}>
                <span className="col-4">Phone </span>
                <span className="col">{`: ${
                  patient.phone ? "+62" + patient.phone : "-"
                }`}</span>
              </div>

              <div style={{ display: "flex" }}>
                <span className="col-4">Gender</span>
                <span className="col">
                  {`: ${
                    patient.gender == "1"
                      ? "Male"
                      : patient.gender == "2"
                      ? "Female"
                      : "-"
                  }`}
                </span>
              </div>

              <div style={{ display: "flex" }}>
                <span className="col-4">Email</span>
                <span className="col">{`: ${
                  patient.email ? patient.email : "-"
                }`}</span>
              </div>

              <div style={{ display: "flex" }}>
                <span className="col-4">Identity Number</span>
                <span className="col">
                  {`: ${patient.national_id_no ? patient.national_id_no : "-"}`}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  };

  const renderSelectedPatient = () => {
    if (!register.userData) {
      return null;
    }

    return (
      <Modal
        modalTitle="Selected Patient"
        show={selectedPatientModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        buttons={[
          {
            label: activeStep === 0 ? "Create Order" : "Continue",
            color: "primary",
            onClick: () => {
              setActiveStep(2);
              setSelectedPatientModal(false);
            },
            disabled: selectedPatient.length > 0 ? false : true,
          },
        ]}
      >
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "50px" }}
                >
                  No
                </th>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "50px" }}
                >
                  Patient ID
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "200px" }}
                >
                  Patient Name
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "50px" }}
                >
                  Unselect
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedPatient.length > 0 ? (
                selectedPatient.map((data, index) => (
                  <tr key={data.id}>
                    <td>{index + 1}</td>
                    <td>{data.patientId ? data.patientId : "-"}</td>
                    <td>{data.patientName ? data.patientName : "-"}</td>
                    <td>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <span
                          className="form-title"
                          style={{ cursor: "pointer", fontSize: "16px" }}
                          onClick={() => handleUnselectPatient(data.patientId)}
                        >
                          <i className="fa fa-trash"></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">-</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Modal>
    );
  };

  const renderAddPatient = () => {
    return (
      <>
        <div className="wrapper-form-title">
          <span className="form-title">Patient Data</span>
        </div>

        <Row>
          <Col md>
            <Input
              type="text"
              label="Patient Name"
              placeholder="Input Patient Name"
              value={newPatientData.patientName}
              onChange={(e) =>
                setNewPatientData({
                  ...newPatientData,
                  patientName: e.target.value,
                })
              }
            />

            {validation.patientName ? (
              <div style={{ color: "red" }}>
                <label>Patient Name cannot be empty</label>
              </div>
            ) : validation.patientNamePattern ? (
              <div style={{ color: "red" }}>
                <label>Please provide a valid name</label>
              </div>
            ) : (
              <></>
            )}
          </Col>
          <Col md>
            <Input
              type="date"
              label="Date of Birth"
              value={newPatientData.patientDob}
              onChange={(e) =>
                setNewPatientData({
                  ...newPatientData,
                  patientDob: e.target.value,
                })
              }
            />
            {validation.patientDob && (
              <div style={{ color: "red" }}>
                <label>Date of Birth cannot be empty</label>
              </div>
            )}
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Phone Number</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">+62</div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Input Phone Number"
                  maxLength={14}
                  value={newPatientData.patientPhone}
                  onChange={(e) =>
                    setNewPatientData({
                      ...newPatientData,
                      patientPhone: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            {validation.patientPhone ? (
              <div style={{ color: "red" }}>
                <label>Phone cannot be empty</label>
              </div>
            ) : validation.patientPhonePattern ? (
              <div style={{ color: "red" }}>
                <label>Please provide a valid phone number</label>
              </div>
            ) : (
              <></>
            )}
          </Col>
          <Col md>
            <Input
              type="email"
              className="form-control"
              placeholder="Input Email"
              label="Email"
              value={newPatientData.patientEmail}
              onChange={(e) =>
                setNewPatientData({
                  ...newPatientData,
                  patientEmail: e.target.value,
                })
              }
            />
            {validation.patientEmail ? (
              <div style={{ color: "red" }}>
                <label>Email cannot be empty</label>
              </div>
            ) : validation.patientEmailPattern ? (
              <div style={{ color: "red" }}>
                <label>Please provide a valid email</label>
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>

        <Row>
          <Col md>
            <div className="form-group">
              <label>Identity Number</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <select
                    className="form-control"
                    value={newPatientData.patientNationalOption}
                    onChange={(e) =>
                      setNewPatientData({
                        ...newPatientData,
                        patientNationalOption: e.target.value,
                      })
                    }
                  >
                    <option value="">Select ID</option>
                    <option value="1">KTP</option>
                    <option value="2">SIM</option>
                    <option value="3">Passport</option>
                  </select>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Input Identity Number"
                  value={newPatientData.patientNational}
                  onChange={(e) =>
                    setNewPatientData({
                      ...newPatientData,
                      patientNational: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            {validation.patientNationalOption ? (
              <div style={{ color: "red" }}>
                <label>Please select Identity type first</label>
              </div>
            ) : validation.patientNational ? (
              <div style={{ color: "red" }}>
                <label>Identity Number cannot be empty</label>
              </div>
            ) : (
              <></>
            )}
          </Col>
          <Col md>
            <div className="form-group">
              <label>Gender</label>
              <select
                className="form-control"
                value={newPatientData.patientGender}
                onChange={(e) =>
                  setNewPatientData({
                    ...newPatientData,
                    patientGender: e.target.value,
                  })
                }
              >
                <option value="">Select Gender</option>
                <option value="1">Male</option>
                <option value="2">Female</option>
              </select>
            </div>
            {validation.patientGender && (
              <div style={{ color: "red" }}>
                <label>Please select Gender first</label>
              </div>
            )}
          </Col>
        </Row>

        <div className="row mt-4">
          <div className="col-md-12">
            {selectedPatient.length > 0 ? (
              <div
                className="row"
                style={{ justifyContent: "flex-end", padding: "10px" }}
              >
                <button
                  type="button"
                  className="btn btn-outline-primary button-footer"
                  onClick={() => setActiveStep(0)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary button-footer"
                  onClick={handleAddPatient}
                >
                  Add
                </button>
                <button
                  type="button"
                  className="btn btn-primary button-footer"
                  onClick={handleNext}
                >
                  Next
                </button>
              </div>
            ) : (
              <div
                className="row"
                style={{ justifyContent: "flex-end", padding: "10px" }}
              >
                <button
                  type="button"
                  className="btn btn-outline-primary button-footer"
                  onClick={() => setActiveStep(0)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-secondary button-footer"
                  disabled
                >
                  Add
                </button>
                <button
                  type="button"
                  className="btn btn-primary button-footer"
                  onClick={handleAddPatient}
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </div>

        {selectedPatient.length > 0 && (
          <div
            className="row mt-4"
            style={{ justifyContent: "flex-end", padding: "10px" }}
          >
            <button className="btn-link btn-no-border" onClick={showSelected}>
              {`${selectedPatient.length} Patient Added (click to view)`}
            </button>
          </div>
        )}
      </>
    );
  };

  const renderHomecareService = () => {
    return (
      <>
        <div className="wrapper-form-title">
          <span className="form-title">Homecare Service</span>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <div className="mb10">Homecare Service ID</div>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Select Homecare service ID"
                value={homecareService.nameHomecare}
                onClick={handleAddHomecareService}
              />
              <div className="input-group-append">
                <div className="input-group-text">Browse</div>
              </div>
            </div>
            {validation.idHomecare && (
              <div style={{ color: "red" }}>
                <label>Please select Package first</label>
              </div>
            )}
          </div>

          <div className="form-group col-md-6">
            <div className="mb10">Price</div>
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">IDR</div>
              </div>
              <input
                type="number"
                className="form-control"
                placeholder="Input Total Payment"
                min="0"
                style={{ height: "38px" }}
                value={homecareService.priceHomecare}
                onChange={(e) =>
                  setHomecareService({
                    ...homecareService,
                    priceHomecare: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <div className="mb10">Remarks</div>
            <textarea
              rows="1"
              className="form-control"
              placeholder="Input Remarks"
              value={homecareService.complaintsHomecare}
              onChange={(e) =>
                setHomecareService({
                  ...homecareService,
                  complaintsHomecare: e.target.value,
                })
              }
            />

            <div className="mb10 mt10">
              Visit Date
              {/* <span style={{ color: "#A1A4A8" }}>(Optional)</span> */}
            </div>
            <input
              type="date"
              className="form-control"
              value={homecareService.visitData}
              onChange={(e) => {
                setHomecareService({
                  ...homecareService,
                  visitData: e.target.value,
                });
              }}
            />
            {validation.visitData && (
              <div style={{ color: "red" }}>
                <label>Visit Date cannot be empty</label>
              </div>
            )}
          </div>

          <div className="form-group col-md-6">
            <div className="mb10">Medical Notes</div>
            <textarea
              // rows="4"
              className="form-control"
              placeholder="Input Notes"
              value={homecareService.noteHomecare}
              onChange={(e) =>
                setHomecareService({
                  ...homecareService,
                  noteHomecare: e.target.value,
                })
              }
              style={{ height: "120px" }}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <div className="mb10">
              Source <span style={{ color: "#A1A4A8" }}>(Optional)</span>
            </div>
            <textarea
              rows="1"
              className="form-control"
              placeholder="Input Source"
              value={homecareService.sourceHomecare}
              onChange={(e) =>
                setHomecareService({
                  ...homecareService,
                  sourceHomecare: e.target.value,
                })
              }
            />
            {validation.sourceHomecare && (
              <div style={{ color: "red" }}>
                <label>Source can only contain alphanumeric characters</label>
              </div>
            )}
          </div>

          <div className="form-group col-md-6">
            <div className="mb10">
              Reference <span style={{ color: "#A1A4A8" }}>(Optional)</span>
            </div>
            <textarea
              rows="1"
              className="form-control"
              placeholder="Input Reference"
              value={homecareService.referenceHomecare}
              onChange={(e) =>
                setHomecareService({
                  ...homecareService,
                  referenceHomecare: e.target.value,
                })
              }
            />
            {validation.referenceHomecare && (
              <div style={{ color: "red" }}>
                <label>
                  Reference can only contain alphanumeric characters
                </label>
              </div>
            )}
          </div>
        </div>

        <div className="wrapper-form-title">
          <span className="form-title">Visit Address</span>
        </div>

        <div className="row">
          <div className="col-md-6" style={{ height: "250px" }}>
            <div
              className="card-block-res"
              onClick={() => setMapModal(true)}
              style={{ cursor: "pointer" }}
            >
              <div className="content-form-res">
                <span>
                  <i
                    className="fas fa-map-marker-alt mr-2"
                    style={{ fontSize: "20px", color: "#38a8ff" }}
                  />{" "}
                  Location
                </span>
                <i
                  className="fa fa-chevron-right"
                  style={{ fontSize: "20px", color: "grey" }}
                />
              </div>

              <div className="content-form-res" style={{ marginTop: "-30px" }}>
                {homecareService.addressHomecare ? (
                  <span>{homecareService.addressHomecare}</span>
                ) : (
                  <div className="skeleton-info">
                    <p className="skeleton-location" style={{ width: "95%" }} />
                    <p className="skeleton-location" style={{ width: "80%" }} />
                    <p className="skeleton-location" style={{ width: "80%" }} />
                    <p className="skeleton-location" style={{ width: "70%" }} />
                  </div>
                )}
              </div>
            </div>

            {validation.addressHomecare && (
              <div style={{ color: "red" }}>
                <label>Please provide a valid address</label>
              </div>
            )}
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <div className="mb10">
                Detail Address{" "}
                <span style={{ color: "#A1A4A8" }}>(Optional)</span>
              </div>

              <textarea
                rows="5"
                className="form-control"
                placeholder="Input Detail Address"
                value={homecareService.detailAddressHomecare}
                onChange={(e) =>
                  setHomecareService({
                    ...homecareService,
                    detailAddressHomecare: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>

        <div
          className="row mt-4"
          style={{ justifyContent: "flex-end", padding: "10px" }}
        >
          <button
            type="button"
            className="btn btn-outline-primary button-footer"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary button-footer"
            onClick={() => setActiveStep(1)}
          >
            Back
          </button>
          <button
            type="button"
            className="btn btn-primary button-footer"
            onClick={validateServicePackage}
          >
            Next
          </button>
        </div>
      </>
    );
  };

  const renderAddHomecareService = () => {
    return (
      <Modal
        modalTitle="Select Homecare service ID"
        show={addHomecareServiceModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        buttons={[]}
        close
      >
        <Row>
          <Col md>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search ID or Name"
                value={filterHomecare}
                onChange={(e) => setFilterHomecare(e.target.value)}
              />
            </div>
          </Col>
          <Col md></Col>
        </Row>

        <Row>
          <Col md>
            <div className="table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="table-header">
                  <tr>
                    <th
                      rowSpan={2}
                      className="table-header-middle"
                      style={{ width: "45px" }}
                    >
                      +
                    </th>

                    <th
                      rowSpan={2}
                      className="table-header-middle"
                      style={{ width: "120px" }}
                    >
                      Service ID
                    </th>

                    <th rowSpan={2} className="table-header-middle">
                      Service Name
                    </th>

                    <th
                      rowSpan={2}
                      className="table-header-middle"
                      style={{ width: "120px" }}
                    >
                      Quantity
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {register.packageData.length > 0 ? (
                    register.packageData
                      .filter((data) => {
                        if (filterHomecare === "") return data;
                        else if (
                          data.serviceId.toString().includes(filterHomecare) ||
                          data.serviceName
                            .toLowerCase()
                            .includes(filterHomecare.toLowerCase())
                        )
                          return data;
                      })
                      .map((data) => (
                        <tr key={data.packageId}>
                          <td>
                            <input
                              type="radio"
                              name="packageData"
                              checked={
                                validation.homecareServiceForm ===
                                data.packageId
                              }
                              value={data.packageId}
                              onChange={() => handleCheckPackageData(data)}
                            />
                          </td>
                          <td>{data.serviceId ? data.serviceId : "-"}</td>
                          <td>{data.serviceName ? data.serviceName : "-"}</td>
                          <td>{data.qty ? data.qty : "-"}</td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan="4">Empty data</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  };

  const renderMap = () => {
    return (
      <Modal
        modalTitle="Cari Lokasi"
        show={mapModal}
        size="lg"
        handleClose={() => setMapModal(false)}
        buttons={[
          {
            label: "Apply",
            color: "primary",
            onClick: actionSetLocation,
          },
        ]}
      >
        <Row>
          <Col md style={{ height: "350px" }}>
            <GoogleMap addressData={(addressData) => handleData(addressData)} />
          </Col>
        </Row>

        <Row>
          <Col md>
            <span>{location ? location.fullAddress : ""}</span>
          </Col>
        </Row>
      </Modal>
    );
  };

  const actionSubmit = () => {
    const form = new FormData();
    form.append("patients", JSON.stringify(selectedPatient));
    form.append("visitData", homecareService.visitData);
    form.append("idHomecare", homecareService.idHomecare);
    form.append("qtyHomecare", homecareService.qtyHomecare);
    form.append("priceHomecare", homecareService.priceHomecare);
    form.append("sourceHomecare", homecareService.sourceHomecare);
    form.append("referenceHomecare", homecareService.referenceHomecare);
    form.append("addressHomecare", homecareService.addressHomecare);
    if (homecareService.postalCode) {
      form.append("postalCode", homecareService.postalCode);
    }
    if (homecareService.longitude) {
      form.append("longitude", homecareService.longitude);
    }
    if (homecareService.latitude) {
      form.append("latitude", homecareService.latitude);
    }
    if (homecareService.detailAddressHomecare) {
      form.append(
        "detailAddressHomecare",
        homecareService.detailAddressHomecare
      );
    }
    if (homecareService.noteHomecare) {
      form.append("noteHomecare", homecareService.noteHomecare);
    }
    if (homecareService.complaintsHomecare) {
      form.append("complaintsHomecare", homecareService.complaintsHomecare);
    }
    if (auth.user) {
      form.append("createdBy", auth.user.id);
    }

    dispatch(createOrderHomecare(form))
      .then(() => {
        setTimeout(
          () =>
            Swal.fire({
              icon: "success",
              title: "Order Created",
              showConfirmButton: false,
            }),
          1000
        );
        setTimeout(() => (window.location = "/register-order"), 2000);
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  };

  const renderPreviewData = () => {
    return (
      <>
        <div
          className="wrapper-form-title"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <span className="form-title">Patient Data</span>
          <span
            className="form-title"
            style={{ cursor: "pointer" }}
            onClick={() => setActiveStep(1)}
          >
            <i className="fa fa-pencil"></i>
          </span>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "250px" }}
                >
                  Name
                </th>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "250px" }}
                >
                  ID
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Gender
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Date of Birth
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Phone Number
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Identity Number
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Email
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedPatient.length > 0 ? (
                selectedPatient.map((data, index) => (
                  <tr key={index}>
                    <td>{data.patientName ? data.patientName : "-"}</td>
                    <td>{data.patientId ? data.patientId : "-"}</td>
                    <td>
                      {data.patientGender == "1"
                        ? "Male"
                        : data.patientGender == "2"
                        ? "Female"
                        : "-"}
                    </td>
                    <td>
                      {data.patientDob
                        ? moment(data.patientDob).format("LL")
                        : "-"}
                    </td>
                    <td>
                      {data.patientPhone ? "+62" + data.patientPhone : "-"}
                    </td>
                    <td>
                      {data.patientNational && data.patientNationalOption == "1"
                        ? `KTP ${data.patientNational}`
                        : data.patientNational &&
                          data.patientNationalOption == "2"
                        ? `SIM ${data.patientNational}`
                        : data.patientNational &&
                          data.patientNationalOption == "3"
                        ? `Passport ${data.patientNational}`
                        : "-"}
                    </td>
                    <td>{data.patientEmail ? data.patientEmail : "-"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">Empty data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div
          className="wrapper-form-title"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <span className="form-title">Homecare Service</span>
          <span
            className="form-title"
            style={{ cursor: "pointer" }}
            onClick={() => setActiveStep(2)}
          >
            <i className="fa fa-pencil"></i>
          </span>
        </div>

        <div className="form-group">
          <div style={{ display: "flex" }}>
            <span className="col-4">Homecare Service ID</span>
            <span className="col">{`: ${
              homecareService.idHomecare
                ? homecareService.idHomecare +
                  " - " +
                  homecareService.nameHomecare
                : "-"
            }`}</span>
          </div>

          <div style={{ display: "flex" }}>
            <span className="col-4">Price</span>
            <span className="col">{`: ${
              homecareService.priceHomecare
                ? formatter.format(homecareService.priceHomecare)
                : "-"
            }`}</span>
          </div>

          <div style={{ display: "flex" }}>
            <span className="col-4">{`Visit Date`}</span>
            <span className="col">{`: ${
              homecareService.visitData
                ? moment(homecareService.visitData).format("LL")
                : "-"
            }`}</span>
          </div>

          <div style={{ display: "flex" }}>
            <span className="col-4">Remarks</span>
            <span className="col">{`: ${
              homecareService.complaintsHomecare
                ? homecareService.complaintsHomecare
                : "-"
            }`}</span>
          </div>

          <div style={{ display: "flex" }}>
            <span className="col-4">Medical Notes</span>
            <span className="col">{`: ${
              homecareService.noteHomecare ? homecareService.noteHomecare : "-"
            }`}</span>
          </div>

          <div style={{ display: "flex" }}>
            <span className="col-4">Address</span>
            <span className="col">{`: ${
              homecareService.addressHomecare
                ? homecareService.addressHomecare
                : "-"
            }`}</span>
          </div>

          <div style={{ display: "flex", color: "gray" }}>
            <span className="col-4"></span>
            <span className="col">{`${
              homecareService.detailAddressHomecare
                ? `(${homecareService.detailAddressHomecare})`
                : ""
            }`}</span>
          </div>
        </div>

        <div
          className="row mt-4"
          style={{ justifyContent: "flex-end", padding: "10px" }}
        >
          <button
            type="button"
            className="btn btn-outline-primary button-footer"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary button-footer"
            onClick={() => setActiveStep(2)}
          >
            Back
          </button>
          <button
            type="button"
            className="btn btn-primary button-footer"
            onClick={actionSubmit}
          >
            Submit
          </button>
        </div>
      </>
    );
  };

  const style = createTheme({
    overrides: {
      MuiStepIcon: {
        root: {
          "&$active": {
            color: "#38a8ff",
          },
          "&$completed": {
            color: "#38a8ff",
          },
        },
      },
      MuiStepLabel: {
        label: {
          "&$active": {
            color: "#38a8ff",
          },
          "&$completed": {
            color: "#38a8ff",
          },
        },
      },
    },
  });

  const getLabel = () => {
    return ["Patient Data", "Service Package", "Summary"];
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Register Order</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        {activeStep === 0 && (
          <>
            <div className="row">
              <div className="form-group col-md-6">
                <div className="mb10">Patient Name</div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Patient Name"
                  ref={name}
                />
              </div>

              <div className="form-group col-md-6">
                <div className="mb10">Date of Birth</div>
                <input type="date" className="form-control" ref={birthday} />
              </div>
            </div>

            <div
              className="row pr-3 mb-4"
              style={{ justifyContent: "flex-end" }}
            >
              <div className="form-group text-left mt-auto">
                <button
                  type="button"
                  className="btn btn-circle btn-link"
                  style={{
                    marginRight: "5px",
                    alignItems: "center",
                    padding: "5px",
                  }}
                  onClick={() => handleReset()}
                >
                  <i
                    className="fa fa-rotate-left"
                    style={{ color: "#00acef", fontSize: "20px" }}
                  ></i>
                  {/* <span className="material-icons" style={{ fontSize: "32px",  marginTop: "5px"}}>
                    &#xE419;
                  </span> */}
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ width: "100px" }}
                  onClick={() => handleSearch()}
                >
                  Search
                </button>
              </div>
            </div>

            <div
              className="row pr-3"
              style={{ paddingBottom: "1em", justifyContent: "flex-end" }}
            >
              {selectedPatient.length > 0 && (
                <>
                  {selectedPatient.length > 0 && (
                    <button
                      className="btn-link btn-no-border"
                      onClick={showSelected}
                    >
                      {`${selectedPatient.length} Selected (click to view)`}
                    </button>
                  )}

                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => setActiveStep(2)}
                  >
                    Create Order
                  </button>
                </>
              )}
            </div>

            <div className="table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="table-header">
                  <tr>
                    <th
                      rowSpan={2}
                      className="table-header-middle"
                      style={{ width: "50px" }}
                    >
                      {register.data.length > 0 ? (
                        <input
                          type="checkbox"
                          name="checkAll"
                          checked={checkAll}
                          onChange={(e) => {
                            handleCheck(e, register.data);
                          }}
                        />
                      ) : (
                        <span>-</span>
                      )}
                    </th>
                    <th
                      rowSpan={2}
                      className="table-header-middle"
                      style={{ width: "50px" }}
                    >
                      No
                    </th>

                    <th
                      rowSpan={2}
                      className="table-header-middle"
                      style={{ width: "200px" }}
                    >
                      Patient ID
                    </th>

                    <th
                      rowSpan={2}
                      className="table-header-middle"
                      style={{ width: "200px" }}
                    >
                      Patient Name
                    </th>

                    <th
                      rowSpan={2}
                      className="table-header-middle"
                      style={{ width: "200px" }}
                    >
                      Date of Birth
                    </th>

                    <th
                      rowSpan={2}
                      className="table-header-middle"
                      style={{ width: "150px" }}
                    >
                      Phone
                    </th>

                    <th rowSpan={2} className="table-header-middle">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>{renderRegister()}</tbody>
              </table>
            </div>

            {register.totalData > 10 ? (
              <Pagination
                props={pagination}
                setProps={setPagination}
                onChange={(page, limit) => {
                  handleSearch(page, limit);
                }}
              />
            ) : null}
          </>
        )}

        {activeStep > 0 && (
          <>
            <ThemeProvider theme={style}>
              <Stepper activeStep={activeStep - 1} alternativeLabel>
                {getLabel().map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </ThemeProvider>

            {activeStep === 1 && renderAddPatient()}
            {activeStep === 2 && renderHomecareService()}
            {activeStep === 3 && renderPreviewData()}
          </>
        )}
      </div>

      {renderShowDetail()}
      {renderSelectedPatient()}
      {renderAddHomecareService()}
      {renderMap()}
    </DashboardLayout>
  );
};

export default RegisterOrder;
