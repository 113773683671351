import { paymentReportConstants } from "../actions/constants";

const initState = {
  orders: [],
  totalData: null,
  sendBird: "",
  parentId: null,
  loading: false,
  error: null,
  order: {},
  nextStatus: {},
};

export default (state = initState, action) => {
  switch (action.type) {
    case paymentReportConstants.GET_PAYMENT_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case paymentReportConstants.GET_PAYMENT_REPORT_SUCCESS:
      return {
        ...state,
        orders: action.payload.orders,
        totalData: action.payload.totalData,
        sendBird: action.payload.sendBird,
        parentId: action.payload.parentId,
        loading: false,
      };
    case paymentReportConstants.GET_PAYMENT_REPORT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case paymentReportConstants.PAYMENT_REPORT_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case paymentReportConstants.PAYMENT_REPORT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        order: action.payload.order,
        nextStatus: action.payload.nextStatus,
      }
    case paymentReportConstants.PAYMENT_REPORT_DETAIL_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    default: 
      return state;
  }
};
