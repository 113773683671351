import axios from "../helpers/axios";
import { scheduleVaccineConstants } from "./constants";

export const getAllSchedulesVaccine = (query) => {
  return async (dispatch) => {
    dispatch({ type: scheduleVaccineConstants.GET_ALL_SCHEDULE_VACCINE_REQUEST });
    const res = await axios.get(`/Schedules/Vaccine?${query}`);
    if (res.status === 200) {
      dispatch({
        type: scheduleVaccineConstants.GET_ALL_SCHEDULE_VACCINE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: scheduleVaccineConstants.GET_ALL_SCHEDULE_VACCINE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const importScheduleVaccine = (dataBody, query) => {
  return async (dispatch) => {
    dispatch({ type: scheduleVaccineConstants.IMPORT_SCHEDULE_VACCINE_REQUEST });
    const res = await axios.post("/Schedules/multiple", dataBody);
    if (res.status === 200) {
      dispatch({
        type: scheduleVaccineConstants.IMPORT_SCHEDULE_VACCINE_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllSchedulesVaccine(query));
    } else {
      dispatch({
        type: scheduleVaccineConstants.IMPORT_SCHEDULE_VACCINE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const clearImportSchedule = (query) => {
  return async (dispatch) => {
    dispatch({ 
      type: scheduleVaccineConstants.CLEAR_IMPORT_SCHEDULE_SUCCESS, 
      payload: {
        message: "Clear error data success",
        dataError: []
      }
    });
    dispatch(getAllSchedulesVaccine(query));
  };
};

export const deleteScheduleVaccine = (id, query) => {
  return async (dispatch) => {
    dispatch({ type: scheduleVaccineConstants.DELETE_SCHEDULE_VACCINE_REQUEST });
    const res = await axios.delete(`/Schedules/${id}`, {
      payload: {
        id,
      },
    });
    if (res.status === 200) {
      dispatch({ type: scheduleVaccineConstants.DELETE_SCHEDULE_VACCINE_SUCCESS });
      dispatch(getAllSchedulesVaccine(query));
    } else {
      dispatch({
        type: scheduleVaccineConstants.DELETE_SCHEDULE_VACCINE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const multipleDeleteScheduleVaccine = (dataBody, query) => {
  return async (dispatch) => {
    dispatch({ type: scheduleVaccineConstants.MULTIPLE_DELETE_SCHEDULE_VACCINE_REQUEST });
    const res = await axios.post(
      "/Schedules/Vaccine/delete",
      dataBody
    );
    if (res.status === 200) {
      dispatch({ type: scheduleVaccineConstants.MULTIPLE_DELETE_SCHEDULE_VACCINE_SUCCESS });
      dispatch(getAllSchedulesVaccine(query));
    } else {
      dispatch({
        type: scheduleVaccineConstants.MULTIPLE_DELETE_SCHEDULE_VACCINE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
