import axios from "../helpers/axios";
import { monthlyReportConstants } from "./constants";

export const getDownloadMonthlyReport = (query) => {
  return async (dispatch) => {
    dispatch({ type: monthlyReportConstants.GET_MONTHLY_REPORT_REQUEST });
    const res = await axios.get(
      `/Order/MonthlyRecap?${query}`
    );
    if (res.status === 200) {
      const { teleOrders, pharmacyOrders, homevisit} = res.data;
      dispatch({
        type: monthlyReportConstants.GET_MONTHLY_REPORT_SUCCESS,
        payload: { teleOrders, pharmacyOrders, homevisit },
      });
    } else {
      dispatch({
        type: monthlyReportConstants.GET_MONTHLY_REPORT_FAILURE,
        payload: { error: res.data.error },
      });
    } 
  };
};
