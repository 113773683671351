import axios from "../helpers/axios";
import { dailyReportConstants } from "./constants";

export const postDownloadReport = (form) => {
  return async (dispatch) => {
    dispatch({ type: dailyReportConstants.GET_DAILY_REPORT_REQUEST });
    const res = await axios.post(
      "/Reconciliation/Report/Daily", form
    );
    if (res.status === 200) {
      const { orders, orders2} = res.data;
      dispatch({
        type: dailyReportConstants.GET_DAILY_REPORT_SUCCESS,
        payload: { orders, orders2 },
      });
    } else {
      dispatch({
        type: dailyReportConstants.GET_DAILY_REPORT_FAILURE,
        payload: { error: res.data.error },
      });
    } 
  };
};
