import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import queryString from "query-string";
import CreatableSelect from 'react-select/creatable';
import DashboardLayout from "../../Layout";
import Modal from "../../Components/Modal";
import Pagination from "../../../../components/Pagination";
import Swal from "sweetalert2";
import { Link, useLocation } from "react-router-dom";
import { getAllMedicalHistory } from "../../../../actions";
import { Avatar } from "@mui/material";
import { Col, Form, Row } from "react-bootstrap";

import "./index.scss";

const Breadcrumb = (props) => {
  return (
      <nav key={props.label} aria-label="breadcrumb" id="breadcrumb-id">
          <ol className="breadcrumb pl-0">
            <li className="breadcrumb-item">
              <a>Dashboard</a>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <Link to={props.path}>{props.menu}</Link>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <Link to={props.childPath}>{props.childMenu}</Link>
            </li>
            {
              props.show ? (
              <>
                <li className="breadcrumb-item" aria-current="page">
                  <a><button onClick={props.childMenu2}>{props.label}</button></a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Detail
                </li>
              </>) : 
              <li className="breadcrumb-item active" aria-current="page">
                {props.label}
              </li>
            }
            
          </ol>
      </nav>
  )
}

const MedicalHistory = () => {
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const [patientData, setPatientData] = useState({
    patientName: "",
    patientDob: "",
    patientAge: "",
    patientGender: "",
    patientPhone: "",
    patientEmail: "",
    avatar: "",
  });
  const [medicalRecord, setMedicalRecord] = useState({
      orderId: "",
      scheduleDate: "",
      specialist: "",
      icdCode: "",
      diagnosis: "",
      partnerName: "",
      medicalRecordId: "",
      icdDiseases: "",
      icdProcedures: "",
      isReferToSpecialist: "",
      specialist_ref: "",
      isReferToLab: "",
      lab_reference: "",
      isReferToOtherPlan: "",
      recommendationNote: "",
    });
  const [show, setShow] = useState(false);
  const searchKey = useRef("");
  const specialistName = useRef("");
  const startDate = useRef("");
  const endDate = useRef("");
  const dokterWorklist = useSelector((state) => state.dokterWorklist);
  const { state } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (state.patientId == undefined || state.orderId == undefined || state.patientId == 0 || state.orderId == 0 || state.q == undefined) {
      Swal.fire({
        icon: "error",
        title: "Oops..",
        text: "Something went wrong. ID Not initialized",
      }).then(() => {
          window.location = "/dokter-worklist";
      });
    }
    dispatch(getAllMedicalHistory(state.patientId, search));
  }, [dispatch, search]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: dokterWorklist.totalData,
    });
  }, [dokterWorklist.totalData]);

  useEffect(() => {
    getPatientData(dokterWorklist.patient);
  }, [dokterWorklist.patient]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const getPatientData = () => {
    let data = dokterWorklist.patient;
    // console.log("data",data);
    setPatientData({
      patientName: data.patientName ? data.patientName : "",
      patientDob: data.patientDob ? moment(data.patientDob).format("ll") : "",
      patientAge: data.patientAgeStr ? data.patientAgeStr : (data.patientAge ? data.patientAge + " yo" : ""),
      patientGender: data.patientGender ? data.patientGender : "",
      patientPhone: data.patientPhone ? "0"+data.patientPhone : "",
      patientEmail: data.patientEmail ? data.patientEmail : "",
      avatar: data.avatar && data.avatar != "https://aido-app.s3-ap-southeast-1.amazonaws.com/assets/users/icons/anonymous.png" ? data.avatar : (data.patientGender == "Perempuan" ? "/img/icon/ic-female-profile.png" : "/img/icon/ic-male-profile.png"),
    });

  };
  
  const handleReset = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      page: pagination.page,
      limit: pagination.limit,
    };

    if (startDate.current.value !== "") {
      startDate.current.value = "";
    }
    
    if (endDate.current.value !== "") {
      endDate.current.value = "";
    }

    if (specialistName.current.value !== "") {
      specialistName.current.value = "";
    }

    if (searchKey.current.value !== "") {
      searchKey.current.value = "";
    }

    let query = queryString.stringify(values, {
      skipEmptyString: true
    });
    setSearch(query);
  };

  const handleSearch = (page, limit) => {
    let values = {};

    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }

    if (limit) {
      values.limit = limit;
    }

    if (startDate.current.value !== "") {
      values.startDate = startDate.current.value;
    }

    if (endDate.current.value !== "") {
      values.endDate = endDate.current.value;
    }

    if (specialistName.current.value !== "") {
      values.specialistName = specialistName.current.value;
    }

    if (searchKey.current.value !== "") {
        values.searchKey = searchKey.current.value;
      }

    let query = queryString.stringify(values, {
      skipEmptyString: true
    });
    setSearch(query);
  };

  const showDetail = (value) => {
    setShow(true);
    setMedicalRecord({
      orderId: value.order_id,
      scheduleDate: value.scheduleDate,
      specialist: value.specialist,
      diagnosis: value.diagnosis,
      partnerName: (value.doctor_firstname ? (value.doctor_lastname ? `${value.doctor_firstname} ${value.doctor_lastname} ${value.doctor_name ? " - "+value.doctor_name : ""}` : value.doctor_firstname) : value.doctor_name),
      medicalRecordId: value.medicalRecordId,
      icdDiseases: value.icdDiseases,
      icdProcedures: value.icdProcedures,
      isReferToSpecialist: value.isReferToSpecialist,
      specialist_ref: value.specialist_ref,
      isReferToLab: value.isReferToLab,
      lab_reference: value.lab_reference,
      isReferToOtherPlan: value.isReferToOtherPlan,
      recommendationNote: value.recommendationNote,
    });
  };

  const renderDetail = () => {
    return (
      <Modal
        modalTitle="Medical Record Detail"
        show={show}
        size="md"
        handleClose={() => setShow(false)}
        close={true}
        buttons={[
        ]}
      >
      <div className="mr-container">
        <div className="pl-2 pr-2">
          <Row style={{ padding: "10px 13.9px", paddingTop: "15px"}}>
                          
            <label>Consultation Date</label>
            <input 
              disabled
              type="text" 
              className="form-control mr-disabled" 
              value={medicalRecord.scheduleDate ? moment(medicalRecord.scheduleDate).format("ll") : "-"} 
            />
            
          </Row>
          <Row style={{ padding: "10px 13.9px", paddingTop: 0}}>
                          
            <label>Specialist</label>
            <input 
              disabled
              type="text" 
              className="form-control mr-disabled" 
              value={medicalRecord.specialist ? medicalRecord.specialist : "-"} 
            />
            
          </Row>
          <Row style={{ padding: "10px 13.9px", paddingTop: 0}}>
                          
            <label>Doctor</label>
            <input 
              disabled
              type="text" 
              className="form-control mr-disabled" 
              value={medicalRecord.partnerName ? medicalRecord.partnerName : "-"} 
            />
            
          </Row>
          <Row style={{ padding: "10px 13.9px", paddingTop: 0}}>
                          
            <label>Diagnosis</label>
            <input 
              disabled
              type="text" 
              className="form-control mr-disabled" 
              value={medicalRecord.diagnosis ? medicalRecord.diagnosis : "-"} 
            />
            
          </Row>
         
          <Row style={{ padding: "10px 13.9px", paddingTop: 0}}>
                          
            <label>ICD Diseases</label>
            <CreatableSelect
                id="mr-select-diseases"
                isMulti
                isDisabled
                placeholder={medicalRecord.icdDiseases ? false : "-"}
                getOptionValue={(option) => option.id}
                getOptionLabel={
                    option => `${option.code} - ${option.name}`
                }
                value={medicalRecord.icdDiseases ? medicalRecord.icdDiseases : []}
                maxMenuHeight={200}

            />
            
          </Row>

          <Row style={{ padding: "10px 13.9px", paddingTop: 0}}>                    
            <label>ICD Procedures</label>
            <CreatableSelect
                id="mr-select-procedures"
                isMulti
                isDisabled
                placeholder={medicalRecord.icdProcedures ? false : "-"}
                getOptionValue={(option) => option.id}
                getOptionLabel={
                    option => `${option.code} - ${option.name}`
                }
                value={medicalRecord.icdProcedures ? medicalRecord.icdProcedures : []}
                maxMenuHeight={200}

            /> 
          </Row>
          
          <label style={{ marginBottom: "4px", marginTop: "4px"}}><b>Plan</b></label>
          <Row style={{ padding: "10px 13.9px", paddingTop: 0}}>
                          
            <label>Specialist</label>
            <input 
              disabled
              type="text" 
              className="form-control mr-disabled" 
              placeholder={medicalRecord.specialist_ref ? false : "-"}
              value={medicalRecord.specialist_ref ? medicalRecord.specialist_ref : ""} 
            />
            
          </Row>
          <Row style={{ padding: "10px 13.9px", paddingTop: 0}}>
            
            <label>Laboratorium</label>
            <CreatableSelect
                id="mr-select-lab"
                isMulti
                isDisabled
                placeholder={medicalRecord.lab_reference ? false : "-"}
                getOptionValue={(option) => option.id}
                getOptionLabel={
                    option => `${option.test_name} ${option.description ? ("("+option.description+")") : ""}`
                }
                value={medicalRecord.lab_reference ? medicalRecord.lab_reference : []}
              />
            
          </Row>
          <Row style={{ padding: "10px 13.9px", paddingTop: 0}}>
            <label>Other</label>
            <input 
              type="text" 
              disabled
              className="form-control mr-disabled" 
              placeholder={medicalRecord.specialist_ref ? false : "-"}
              value={medicalRecord.recommendationNote ? medicalRecord.recommendationNote : ""} 
              />
          </Row>
        </div>
        </div>
      </Modal>
    );
  };

  const renderMedicalRecord = (medicalHistory, pagination, showDetail) => {
    const medicalRecords = medicalHistory?.medicalRecords || [];

    return (
      <>
        {/* Empty */}
        {medicalRecords.length === 0 && (
          <tr>
            <td colSpan="18">Empty Data</td>
          </tr>
        )}

        {/* Has Data */}
        {medicalRecords.map((item, index) => {
          return (
            <tr key={item.order_id}>
              <td>{(pagination.page - 1) * 10 + index + 1}</td>
              {/* <td>{item.order_id ? invoice.order_id : "-"}</td> */}
              <td>{item.scheduleDate ? moment(item.scheduleDate).format("ll") : "-"}</td>
              <td>{item.specialist ? item.specialist : "-"}</td>
              {/* <td>{item.icdDiseases ? (item.icdDiseases.length > 2 ? (item.icdDiseases.slice(0,2).map(icd => icd.code).join("; ")) : item.icdDiseases.map(icd => icd.code).join("; ")) : "-"}</td> */}
              <td>{item.icdCode ? (item.icdCode.length > 3 ? (item.icdCode.slice(0,2).map(icd => icd).join("; ")) : item.icdCode.map(icd => icd).join("; ")) : "-"}</td>
              <td>{item.diagnosis ? item.diagnosis : "-"}</td>
              <td>{(item.doctor_firstname ? (item.doctor_lastname ? `${item.doctor_firstname} ${item.doctor_lastname} ${item.doctor_name ? " - "+item.doctor_name : ""}` : item.doctor_firstname) : item.doctor_name)}</td>
              <td style={{ width: "120px", textAlign: "justify" }}>
                  <div 
                    >
                    
                      <label className="mb-0" htmlFor="isReferToSpecialist" style={{ wordWrap: "break-word" }}>
                      <input
                        type="checkbox"
                        className="mb-1 mr-1"
                        name="isReferToSpecialist"
                        checked={item.isReferToSpecialist}
                        disabled
                        style={{ verticalAlign: "middle", width: "12px", height: "12px" }}
                      /> Specialist</label>
                  </div>
                  <div>
                      <label className="mb-0" htmlFor="isReferToSpecialist" style={{ wordWrap: "break-word" }}>
                      <input
                        type="checkbox"
                        className="mb-1 mr-1"
                        name="isReferToLab"
                        checked={item.isReferToLab}
                        disabled
                        style={{ verticalAlign: "middle", width: "12px", height: "12px" }}
                      /> Lab</label>
                  </div>
                  <div>
                      <label className="mb-0" htmlFor="isReferToSpecialist" style={{ wordWrap: "break-word" }}>
                      <input
                        type="checkbox"
                        className="mb-1 mr-1"
                        name="isReferToOtherPlan"
                        checked={item.isReferToOtherPlan}
                        disabled
                        style={{ verticalAlign: "middle", width: "12px", height: "12px" }}
                      />Other</label>
                  </div>

              </td>
              <td style={{ verticalAlign: "middle"}}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  {( item.labResult || item.pharmacyPhoto || item.isReferToSpecialist == 1 || item.isReferToLab == 1 || item.isReferToOtherPlan == 1 || (item.icdCode && item.icdCode.length > 0)) ?
                    <Link 
                      to={{ pathname: "/dokter-worklist/medical-records/detail", state: { patientId: item.patientId, orderId: state.orderId, mr: item, patient: patientData, q: state.q } }}>
                      <button 
                        type="button"
                        className="btn btn-block btn-outline-primary"
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        Detail
                      </button>
                    </Link>
                    : "-"
                  }

                </div>
              </td>
            </tr>
          )
        })
        }
      </>
    );
  };

  const listMenu = document.getElementById("/dokter-worklist")
   
  if (listMenu) {
    listMenu.style.fontWeight = "bold";
    listMenu.style.backgroundColor = "blueviolet";
  } 

  return (
    <DashboardLayout>
      <Breadcrumb 
          path={{ pathname: "/dokter-worklist", state: { q: state.q }}} 
          menu={"Dokter Worklist"} 
          childPath={{ pathname: "/dokter-worklist/detail", state: { patientId: state.patientId, orderId: state.orderId, q: state.q } }} 
          childMenu={"Teleconsultation Patient Service"} 
          childMenu2={() => setShow(false)} 
          show={show}
          label={"Medical Record"}
          />
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Medical Record</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="mr-container mb-2" 
          >
          <div className="table-wrapper text-mr" style={{ boxShadow: "none", padding: "20px"}}>
            
            <div className="row w-100">
              <div className="col-md-2">
                <div className="row justify-content-center" style={{ marginTop: "15px" }}>
                  <Avatar
                    className="align-items-center"
                    sx={{ width: 100, height: 100}}
                    src={patientData.avatar}
                    />
                </div>
              </div>
              <div className="col-10">
                <div className="row mb-2">
                  <div className="col">
                    <Form.Group as={Row} 
                      className="mb-0" 
                      controlId="formPlaintextEmail">
                      <Form.Label column sm="5">
                        Patient Name
                      </Form.Label>
                      <Col sm="7" className="mr-field">
                        <Form.Control plaintext readOnly disabled value={patientData.patientName}/>
                      </Col>
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group as={Row} className="mb-0" controlId="formPlaintextEmail">
                      <Form.Label column sm="4">
                        Gender
                      </Form.Label>
                      <Col sm="8" className="mr-field">
                        <Form.Control plaintext readOnly disabled value={patientData.patientGender} />
                      </Col>
                    </Form.Group>
                  </div>  
                </div>

                <div className="row mb-2">
                  <div className="col">
                    <Form.Group as={Row} 
                      className="mb-0" 
                      controlId="formPlaintextEmail">
                      <Form.Label column sm="5">
                        Date of Birth
                      </Form.Label>
                      <Col sm="7" className="mr-field">
                        <Form.Control plaintext readOnly disabled value={patientData.patientDob}/>
                      </Col>
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group as={Row} className="mb-0" controlId="formPlaintextEmail">
                      <Form.Label column sm="4">
                        Age
                      </Form.Label>
                      <Col sm="8" className="mr-field">
                        <Form.Control plaintext readOnly disabled value={patientData.patientAge} />
                      </Col>
                    </Form.Group>
                  </div>  
                </div>

                <div className="row">
                  <div className="col">
                    <Form.Group as={Row} 
                      className="mb-0" 
                      controlId="formPlaintextEmail">
                      <Form.Label column sm="5">
                        Phone No
                      </Form.Label>
                      <Col sm="7" className="mr-field">
                        <Form.Control plaintext readOnly disabled value={patientData.patientPhone}/>
                      </Col>
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group as={Row} className="mb-0" controlId="formPlaintextEmail">
                      <Form.Label column sm="4">
                        Email
                      </Form.Label>
                      <Col sm="8" className="mr-field">
                        <Form.Control plaintext readOnly disabled value={patientData.patientEmail} />
                      </Col>
                    </Form.Group>
                  </div>  
                </div>           
              </div>
            </div>
          </div>
        </div>

        <div className="row mb15">
          <div className="link-btn">
            <button
              type="button"
              className="btn btn-link no-padding"
              style={{ textDecoration: "none", boxShadow: "none" }}
              data-toggle="collapse"
              data-target="#collapseMedicalHistory"
            >
              Filter By
              <i className="fa fa-angle-down" style={{ marginLeft: "10px" }} />
            </button>
          </div>

          <div className="col">
            <hr />
          </div>
        </div>

        <div className="collapse" id="collapseMedicalHistory">
          <div id="filterForm" className="row filter-by">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <div className="mb10">Date From</div>
                  <input 
                    type="date" 
                    className="form-control" 
                    ref={startDate} 
                    />
                </div>
                <div className="col-md-3">
                  <div className="mb10">Date To</div>
                  <input 
                    type="date" 
                    className="form-control" 
                    ref={endDate} 
                    />
                </div>
                <div className="col-md-3">
                  <div className="mb10">Specialist</div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Specialist"
                    ref={specialistName}
                    />
                </div>
                <div className="col-md-3">
                  <div className="mb10">Doctor Name</div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Doctor Name"
                    ref={searchKey}
                    />
                </div>
              </div>
            </div>
          </div>

          <div className="text-right" style={{ marginTop: "25px" }}>
            <button
              type="button"
              className="btn btn-secondary"
              style={{ width: "100px", marginRight: "5px" }}
              onClick={() => handleReset()}
            >
              Reset
            </button>

            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100px" }}
              onClick={() => handleSearch()}
            >
              Apply
            </button>
          </div>

          <div style={{ width: "100%" }}>
            <hr />
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "45px" }}
                >
                  No
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Date
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "150px" }}
                >
                  Specialist
                </th>

                <th rowSpan={2} className="table-header-middle">
                  ICD Code
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Diagnosis
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Doctor
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Plan
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Action
                </th>
              </tr>

            </thead>
            <tbody>{renderMedicalRecord(dokterWorklist, pagination, showDetail)}</tbody>
          </table>
        </div>

        {dokterWorklist.totalData > 10 ? (
          <Pagination
            props={pagination}
            setProps={setPagination}
            onChange={(page, limit) => {
              handleSearch(page, limit);
            }}
          />
        ) : null}
      </div>
    </DashboardLayout>
  );
};

export default MedicalHistory;
