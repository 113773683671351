import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../Layout";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { getOrderList } from "../../../actions";
import Pagination from "../../../components/Pagination";
import moment from "moment";

const MyOrder = () => {
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const searchBy = useRef("");
  const searchKey = useRef("");
  const status = useRef("");
  const sortBy = useRef("");
  const sortKey = useRef("");
  const date = useRef("");
  const myOrder = useSelector((state) => state.myOrder);
  const dispatch = useDispatch();
  const { loading, error, orders, totalData } = myOrder;

  useEffect(() => {
    dispatch(getOrderList(search));
  }, [search]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: totalData,
    });
  }, [totalData]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const handleReset = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      page: pagination.page,
      limit: pagination.limit,
    };

    if (searchBy.current.value !== "") {
      searchBy.current.value = "";
    }

    if (searchKey.current.value !== "") {
      searchKey.current.value = "";
    }

    if (sortBy.current.value !== "") {
      sortBy.current.value = "";
    }

    if (sortKey.current.value !== "") {
      sortKey.current.value = "";
    }

    if (date.current.value !== "") {
      date.current.value = "";
    }

    if (status.current.value !== "") {
      status.current.value = "";
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleSearch = (page, limit) => {
    let values = {};

    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }

    if (limit) {
      values.limit = limit;
    }

    if (searchBy.current.value !== "") {
      values.searchBy = searchBy.current.value;
    }

    if (searchKey.current.value !== "") {
      values.searchKey = searchKey.current.value;
    }

    if (sortBy.current.value !== "") {
      values.sortBy = sortBy.current.value;
    }

    if (sortKey.current.value !== "") {
      values.sortKey = sortKey.current.value;
    }

    if (date.current.value !== "") {
      values.date = date.current.value;
    }

    if (status.current.value !== "") {
      values.status = status.current.value;
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const renderMyOrders = () => {
    return loading ? (
      <tr>
        <td colSpan="11">
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        {orders.length > 0 ? (
          orders.map((data, index) => (
            <tr key={data.orderId}>
              <td>{(pagination.page - 1) * 10 + index + 1}</td>
              <td>{data.orderId ? data.orderId : "-"}</td>
              <td>{data.patientName ? data.patientName : "-"}</td>
              <td>{data.responsibleName ? data.responsibleName : "-"}</td>
              <td>{data.packageName ? data.packageName : "-"}</td>
              <td>{data.partnerName ? data.partnerName : "-"}</td>
              <td>{data.professionName ? data.professionName : "-"}</td>
              <td>
                {data.scheduleDate
                  ? moment(data.scheduleDate).format("ll")
                  : "-"}
              </td>
              <td>
                {data.startTime && data.endTime
                  ? `${moment(data.startTime, "HH:mm:ss").format(
                      "HH:mm"
                    )} - ${moment(data.endTime, "HH:mm:ss").format("HH:mm")}`
                  : "-"}
              </td>
              <td>{data.statusDesc ? data.statusDesc : "-"}</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <Link
                    to={{
                      pathname: "/my-order/detail",
                      state: { orderId: data.orderId },
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-block"
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      Detail
                    </button>
                  </Link>

                  <Link
                    to={{
                      pathname: "/my-order/reschedule",
                      state: {
                        orderId: data.orderId,
                        partnerId: data.partnerId,
                        scheduleDate: data.scheduleDate
                          ? moment(data.scheduleDate).format("YYYY-MM-DD")
                          : "-",
                        startTime: data.startTime,
                        endTime: data.endTime,
                      },
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-outline-success btn-block"
                    >
                      Reschedule
                    </button>
                  </Link>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="11">Empty data</td>
          </tr>
        )}
      </>
    );
  };

  return (
    <DashboardLayout>
      <>
        <div className="row revenue-title">
          <div className="col">
            <hr />
          </div>

          <div>My Order</div>

          <div className="col">
            <hr />
          </div>
        </div>

        <div className="table-wrapper">
          <div className="alert alert-info" style={{ textAlign: "center" }}>
            <p style={{ fontSize: "15px", marginBottom: "10px" }}>
              Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
              aido, silahkan hubungi aido support dengan click chat aido
              support.
              <br />
              <a
                href="https://wa.me/628118481436"
                style={{ color: "#51BED3" }}
                target="blank"
              >
                Chat Aido Support
              </a>
            </p>
          </div>

          <div className="row">
            <div className="col-md-6" id="btn-add">
              <span id="finance-title" className="mb10">
                Order
              </span>
            </div>
          </div>

          <div className="row mb15">
            <div className="link-btn">
              <button
                type="button"
                className="btn btn-link no-padding"
                style={{ textDecoration: "none", boxShadow: "none" }}
                data-toggle="collapse"
                data-target="#collapseFilterOrder"
              >
                Filter By
                <i className="fa fa-angle-down" style={{ marginLeft: "5px" }} />
              </button>
            </div>

            <div className="col">
              <hr />
            </div>
          </div>

          <div className="collapse" id="collapseFilterOrder">
            <div id="filterForm" className="row filter-by">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3">
                    <div className="mb10">Name</div>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <select className="form-control" ref={searchBy}>
                          <option value="">Select</option>
                          <option value="patientName">Patient</option>
                          <option value="customerName">Responsible</option>
                        </select>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Name"
                        ref={searchKey}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="mb10">Status</div>
                    <select className="form-control" ref={status}>
                      <option value="">Select</option>
                      <option value="10">Schedule Confirmed</option>
                      <option value="98">Waiting for Payment</option>
                      <option value="13">Care Partner On The Way</option>
                      <option value="14">Care Partner Arrived</option>
                      <option value="99">Order Completed</option>
                    </select>
                  </div>

                  <div className="col-md-3">
                    <div className="mb10">Visit Date</div>
                    <input type="date" className="form-control" ref={date} />
                  </div>

                  <div className="col-md-3">
                    <div className="mb10">Sort</div>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <select className="form-control" ref={sortBy}>
                          <option value="">Select</option>
                          <option value="scheduleDate">Visit Date</option>
                          <option value="startTime">Start Time</option>
                        </select>
                      </div>
                      <select className="form-control" ref={sortKey}>
                        <option value="">Select</option>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="fm-btn-gr text-right">
              <button
                type="button"
                className="btn btn-secondary btn-reset"
                style={{ width: "100px", marginRight: "5px" }}
                onClick={() => handleReset()}
              >
                Reset
              </button>

              <button
                type="button"
                className="btn btn-primary"
                style={{ width: "100px" }}
                onClick={() => handleSearch()}
              >
                Apply
              </button>
            </div>

            <div style={{ width: "100%" }}>
              <hr />
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-striped table-bordered">
              <thead className="table-header">
                <tr>
                  <th
                    rowSpan={2}
                    className="table-header-middle"
                    style={{ width: "45px" }}
                  >
                    No
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Order ID
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Patient Name
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Responsible Name
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Service Package
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Partner Assigned
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Profession
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Visit Date
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Visit Time
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Status
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Action
                  </th>
                </tr>
              </thead>

              <tbody>{renderMyOrders()}</tbody>
            </table>
          </div>

          {totalData > 10 ? (
            <Pagination
              props={pagination}
              setProps={setPagination}
              onChange={(page, limit) => {
                handleSearch(page, limit);
              }}
            />
          ) : null}
        </div>
      </>
    </DashboardLayout>
  );
};

export default MyOrder;
