import { orderHistoryConstants } from "../actions/constants";

const initState = {
  listDataAktif: [],
  listDataDone: [],
  listDataDetail: [],
  listRiwayat: [],
  detailRiwayat: [],
  additionalService: [],
  urlConsent: [],
  urlPharmacy: [],
  urlGallery: [],
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case orderHistoryConstants.GET_AKTIF_ORDERHISTORY_SUCCESS:
      state = {
        ...state,
        listDataAktif: action.payload,
        loading: false,
      };
      break;
    case orderHistoryConstants.GET_AKTIF_ORDERHISTORY_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderHistoryConstants.GET_AKTIF_ORDERHISTORY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case orderHistoryConstants.GET_DONE_ORDERHISTORY_SUCCESS:
      state = {
        ...state,
        listDataDone: action.payload,
        loading: false,
      };
      break;
    case orderHistoryConstants.GET_DONE_ORDERHISTORY_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderHistoryConstants.GET_DONE_ORDERHISTORY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case orderHistoryConstants.GET_DETAIL_ORDERHISTORY_SUCCESS:
      state = {
        ...state,
        listDataDetail: action.payload,
        loading: false,
      };
      break;
    case orderHistoryConstants.GET_DETAIL_ORDERHISTORY_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderHistoryConstants.GET_DETAIL_ORDERHISTORY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case orderHistoryConstants.GET_LIST_RIWAYAT_SUCCESS:
      state = {
        ...state,
        listRiwayat: action.payload,
        loading: false,
      };
      break;
    case orderHistoryConstants.GET_LIST_RIWAYAT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderHistoryConstants.GET_LIST_RIWAYAT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case orderHistoryConstants.GET_DETAIL_RIWAYAT_SUCCESS:
      state = {
        ...state,
        detailRiwayat: action.payload,
        loading: false,
      };
      break;
    case orderHistoryConstants.GET_DETAIL_RIWAYAT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderHistoryConstants.GET_DETAIL_RIWAYAT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case orderHistoryConstants.GET_ADDITIONAL_SERVICE_SUCCESS:
      state = {
        ...state,
        additionalService: action.payload,
        loading: false,
      };
      break;
    case orderHistoryConstants.GET_ADDITIONAL_SERVICE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderHistoryConstants.GET_ADDITIONAL_SERVICE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case orderHistoryConstants.CREATE_ORDER_PRODUCT_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case orderHistoryConstants.CREATE_ORDER_PRODUCT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderHistoryConstants.CREATE_ORDER_PRODUCT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case orderHistoryConstants.UPDATE_ORDER_PRODUCT_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case orderHistoryConstants.UPDATE_ORDER_PRODUCT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderHistoryConstants.UPDATE_ORDER_PRODUCT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case orderHistoryConstants.CREATE_MEDICAL_RECORD_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case orderHistoryConstants.CREATE_MEDICAL_RECORD_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderHistoryConstants.CREATE_MEDICAL_RECORD_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case orderHistoryConstants.UPDATE_STATUS_ORDER_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case orderHistoryConstants.UPDATE_STATUS_ORDER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderHistoryConstants.UPDATE_STATUS_ORDER_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case orderHistoryConstants.UPLOAD_CONSENT_SUCCESS:
      state = {
        ...state,
        urlConsent: action.payload,
        loading: false,
      };
      break;
    case orderHistoryConstants.UPLOAD_CONSENT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderHistoryConstants.UPLOAD_CONSENT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case orderHistoryConstants.UPLOAD_PHARMACY_SUCCESS:
      state = {
        ...state,
        urlPharmacy: action.payload,
        loading: false,
      };
      break;
    case orderHistoryConstants.UPLOAD_PHARMACY_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderHistoryConstants.UPLOAD_PHARMACY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case orderHistoryConstants.UPLOAD_GALLERY_SUCCESS:
      state = {
        ...state,
        urlGallery: action.payload,
        loading: false,
      };
      break;
    case orderHistoryConstants.UPLOAD_GALLERY_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderHistoryConstants.UPLOAD_GALLERY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
  }
  return state;
};
