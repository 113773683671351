import React, { useEffect, useState, useRef } from "react";
import Pagination from "../../../../components/Pagination";
import DashboardLayout from "../../Layout";
import Modal from "../../Components/Modal";
import Input from "../../Components/Input";
import { Col, Row } from "react-bootstrap";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { getPartners, updateOrderDoctor } from "../../../../actions";
import { useLocation } from "react-router";
import moment from "moment";
import Swal from "sweetalert2";

const MyOrderReschedule = () => {
  const { state } = useLocation();
  const [orderData, setOrderData] = useState({
    orderId: state.orderId,
    partnerId: state.partnerId,
    scheduleDate: state.scheduleDate,
    startTime: state.startTime,
    endTime: state.endTime,
  });
  const [search, setSearch] = useState(`visitDate=${state.scheduleDate}`);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const [visitDate, setVisitDate] = useState(state.scheduleDate);
  const profession = useRef("");
  const partnerId = useRef("");
  const [errors, setErrors] = useState({
    partnerId: false,
    scheduleDate: false,
    startTime: false,
    endTime: false,
    exceed: false,
  });
  const [reschedulePartnerModal, setReschedulePartnerModal] = useState(false);
  const orderRequest = useSelector((state) => state.orderRequest);
  const dispatch = useDispatch();
  const { loading, error, partners, totalPartner, allPartner } = orderRequest;

  const initState = () => {
    setOrderData({
      orderId: state.orderId,
      partnerId: state.partnerId,
      scheduleDate: state.scheduleDate,
      startTime: state.startTime,
      endTime: state.endTime,
    });
  };

  useEffect(() => {
    dispatch(getPartners(search));
  }, [search]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: totalPartner,
    });
  }, [totalPartner]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const minutesValidation = (minutes) => {
    if (minutes[1] === "00" || minutes[1] === "30") {
      return true;
    }
    return false;
  };

  const handleCloseModal = () => {
    initState();
    setReschedulePartnerModal(false);
  };

  const handleReset = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    setVisitDate("");

    let values = {
      page: pagination.page,
      limit: pagination.limit,
    };

    if (profession.current.value !== "") {
      profession.current.value = "";
    }

    if (partnerId.current.value !== "") {
      partnerId.current.value = "";
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleSearch = (page, limit) => {
    let values = {};

    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }

    if (limit) {
      values.limit = limit;
    }

    if (visitDate !== "") {
      values.visitDate = visitDate;
    }

    if (profession.current.value !== "") {
      values.profession = profession.current.value;
    }

    if (partnerId.current.value !== "") {
      values.partnerId = partnerId.current.value;
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleReschedulePartner = () => {
    setReschedulePartnerModal(true);
  };

  const handleSelectPartner = (data) => {
    setOrderData({
      partnerId: data.partnerId,
      scheduleDate: moment(data.scheduleDate).format("YYYY-MM-DD"),
      startTime: data.startTime,
      endTime: data.endTime,
    });
    setReschedulePartnerModal(true);
  };

  const actionSubmit = () => {
    const formData = new FormData();

    formData.append("id", orderData.orderId);
    formData.append("partner", orderData.partnerId);
    formData.append("scheduleDate", orderData.scheduleDate);
    formData.append("startTime", orderData.startTime);
    formData.append("endTime", orderData.endTime);

    dispatch(updateOrderDoctor(formData))
      .then(() => {
        initState();
        setReschedulePartnerModal(false);
        setTimeout(() => Swal.fire("Success!", "Data Updated", "success"), 500);
        setTimeout(() => (window.location = "/my-order"), 1000);
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  };

  const actionValidation = () => {
    const checkStartTime = minutesValidation(orderData.startTime.split(":"));
    const checkEndTime = minutesValidation(orderData.endTime.split(":"));

    setErrors({
      partnerId: orderData.partnerId ? false : true,
      scheduleDate: orderData.scheduleDate ? false : true,
      startTime: checkStartTime ? false : true,
      endTime: checkEndTime ? false : true,
      exceed: orderData.endTime <= orderData.startTime,
    });

    const readyForSubmit = () => {
      if (
        orderData.partnerId === "" ||
        orderData.scheduleDate === "" ||
        checkStartTime === false ||
        checkEndTime === false ||
        orderData.endTime <= orderData.startTime
      ) {
        return false;
      }
      return true;
    };

    if (readyForSubmit()) {
      actionSubmit();
    }
  };

  const renderReschedulePartner = () => {
    return (
      <Modal
        modalTitle="Reschedule Partner"
        show={reschedulePartnerModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        onSubmit={actionValidation}
      >
        <Row>
          <Col md>
            <div className="form-group">
              <label>Partner</label>
              <select
                className="form-control"
                value={orderData.partnerId}
                onChange={(e) =>
                  setOrderData({
                    ...orderData,
                    partnerId: e.target.value,
                  })
                }
              >
                <option value="">Select Partner</option>
                {allPartner.length > 0
                  ? allPartner.map((data) => (
                      <option key={data.partnerId} value={data.partnerId}>
                        {data.partnerName}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            {errors.partnerId && (
              <div style={{ color: "red" }}>
                <label>Please select Partner first</label>
              </div>
            )}
          </Col>
          <Col md>
            <Input
              type="date"
              label="Schedule Date"
              value={orderData.scheduleDate}
              onChange={(e) =>
                setOrderData({
                  ...orderData,
                  scheduleDate: e.target.value,
                })
              }
            />
            {errors.scheduleDate && (
              <div style={{ color: "red" }}>
                <label>Please select Schedule Date first</label>
              </div>
            )}
          </Col>
        </Row>

        <Row>
          <Col md>
            <Input
              type="time"
              label="Start Time"
              value={orderData.startTime}
              onChange={(e) =>
                setOrderData({
                  ...orderData,
                  startTime: e.target.value,
                })
              }
            />
            {errors.startTime && (
              <div style={{ color: "red" }}>
                <label>Minutes should be at 00 or 30</label>
              </div>
            )}
            {errors.exceed && (
              <div style={{ color: "red" }}>
                <label>Start Time cannot exceed End Time</label>
              </div>
            )}
          </Col>
          <Col md>
            <Input
              type="time"
              label="End Time"
              value={orderData.endTime}
              onChange={(e) =>
                setOrderData({
                  ...orderData,
                  endTime: e.target.value,
                })
              }
            />
            {errors.endTime && (
              <div style={{ color: "red" }}>
                <label>Minutes should be at 00 or 30</label>
              </div>
            )}
          </Col>
        </Row>
      </Modal>
    );
  };

  const renderPartners = () => {
    return loading ? (
      <tr>
        <td colSpan="9">
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        {partners.length > 0 ? (
          partners.map((data, index) => (
            <tr key={data.orderId}>
              <td>{(pagination.page - 1) * 10 + index + 1}</td>
              <td>{data.partnerName ? data.partnerName : "-"}</td>
              <td>{data.professionName ? data.professionName : "-"}</td>
              <td>{data.orderId ? data.orderId : "-"}</td>
              <td>{data.serviceName ? data.serviceName : "-"}</td>
              <td>{data.fullAddress ? data.fullAddress : "-"}</td>
              <td>
                {data.scheduleDate
                  ? moment(data.scheduleDate).format("ll")
                  : "-"}
              </td>
              <td>
                {data.startTime && data.endTime
                  ? `${moment(data.startTime, "HH:mm:ss").format(
                      "HH:mm"
                    )} - ${moment(data.endTime, "HH:mm:ss").format("HH:mm")}`
                  : "-"}
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-outline-success btn-block"
                  onClick={() => handleSelectPartner(data)}
                >
                  Select
                </button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="9">Empty data</td>
          </tr>
        )}
      </>
    );
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Reschedule Partner</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        <div className="row">
          <div className="col-md-6">
            <span id="finance-title" className="mb10">
              Partner List
            </span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3" style={{ paddingBottom: "1em" }}>
            <button
              type="button"
              className="btn btn-outline-primary"
              style={{ width: "100%" }}
              onClick={() => handleReschedulePartner()}
            >
              Reschedule Partner
            </button>
          </div>
        </div>

        <div className="row mb15">
          <div className="link-btn">
            <button
              type="button"
              className="btn btn-link no-padding"
              style={{ textDecoration: "none", boxShadow: "none" }}
              data-toggle="collapse"
              data-target="#collapseDetailSchedule"
            >
              Filter By
              <i className="fa fa-angle-down" style={{ marginLeft: "5px" }} />
            </button>
          </div>

          <div className="col">
            <hr />
          </div>
        </div>

        <div className="collapse" id="collapseDetailSchedule">
          <div id="filterForm" className="row filter-by">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <div className="mb10">Visit Date</div>
                  <input
                    type="date"
                    className="form-control"
                    value={visitDate}
                    onChange={(e) => setVisitDate(e.target.value)}
                  />
                </div>

                <div className="col-md-4">
                  <div className="mb10">Profession</div>
                  <select className="form-control" ref={profession}>
                    <option value="">Select Profession</option>
                    <option value="1">Doctor</option>
                    <option value="2">Nurse</option>
                    <option value="3">Medical Rehabilitation Therapist</option>
                    <option value="8">Lab Analyst</option>
                  </select>
                </div>

                <div className="col-md-4">
                  <div className="mb10">Partner Name</div>
                  <select className="form-control" ref={partnerId}>
                    <option value="">Select Partner</option>
                    {allPartner.length > 0
                      ? allPartner.map((data) => (
                          <option key={data.partnerId} value={data.partnerId}>
                            {data.partnerName}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="fm-btn-gr text-right">
            <button
              type="button"
              className="btn btn-secondary btn-reset"
              style={{ width: "100px", marginRight: "5px" }}
              onClick={() => handleReset()}
            >
              Reset
            </button>

            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100px" }}
              onClick={() => handleSearch()}
            >
              Apply
            </button>
          </div>

          <div style={{ width: "100%" }}>
            <hr />
          </div>
        </div>

        <div className="table-responsive">
          <table
            id="tableScheduler"
            className="table table-striped table-bordered"
          >
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "45px" }}
                >
                  No
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Partner Name
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Profession
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Assigned Order
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Service
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Visit Address
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Visit Date
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Visit Time
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Action
                </th>
              </tr>
            </thead>

            <tbody>{renderPartners()}</tbody>
          </table>
        </div>

        {totalPartner > 10 ? (
          <Pagination
            props={pagination}
            setProps={setPagination}
            onChange={(page, limit) => {
              handleSearch(page, limit);
            }}
          />
        ) : null}
      </div>
      {renderReschedulePartner()}
    </DashboardLayout>
  );
};

export default MyOrderReschedule;
