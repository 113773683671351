import React, { useEffect, useState, useRef } from "react";
import DashboardLayout from "../Layout";
// Query
import { useDispatch, useSelector } from "react-redux";
import {
  getAllEventCheckInHS,
} from "../../../actions";

export default function CheckInHS() {
    
  const result = useSelector((state) => state.healthScreening);
  const dispatch = useDispatch();
  const eventId = useRef(null);

  useEffect(() => {
    dispatch(getAllEventCheckInHS());
  }, []);

  const checkEvent = () => {
    var eventRelationId = eventId.current.value;

    if(eventRelationId){
      window.open(window.origin+"/CheckIn-HS/"+eventRelationId);
    }

  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>
        <div>Check In Health Screening</div>
        <div className="col">
          <hr />
        </div>
      </div>
      <div className="table-wrapper">       
          <div className="col-md-7 neutral-border">
            <div className="form-group mb-md-0">
              <label>Event Name</label>
              <select className="form-control" ref={eventId}>
                <option value="">Select Event</option>

                {result.eventLists ? result.eventLists.map((data) => (
                  <option key={data.eventId} value={data.eventId}> {data.eventNameDesc} </option>
                  )) : null}
              </select>
            </div>
            <div className="fm-btn-gr text-right">
              <div className="form-group mb-md-0">
                <button type="button" className="btn btn-primary" onClick={() => { checkEvent() }}> Check </button>
              </div>
            </div>
          </div>
        <div style={{ width: "100%" }}>
          <hr />
        </div>
      </div>
    </DashboardLayout>
  );
}
