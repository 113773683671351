import { myMedicalConstants } from "../actions/constants";

const initState = {
  medicalSuppliess: [],
  totalData: "",
  medicalData: {},
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case myMedicalConstants.GET_ALL_MEDICAL_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myMedicalConstants.GET_ALL_MEDICAL_SUCCESS:
      state = {
        ...state,
        medicalSuppliess: action.payload.medicalSuppliess,
        totalData: action.payload.count,
        loading: false,
      };
      break;
    case myMedicalConstants.GET_ALL_MEDICAL_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myMedicalConstants.GET_MEDICAL_BY_ID_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myMedicalConstants.GET_MEDICAL_BY_ID_SUCCESS:
      state = {
        ...state,
        medicalData: action.payload.medicalData,
        loading: false,
      };
      break;
    case myMedicalConstants.GET_MEDICAL_BY_ID_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myMedicalConstants.ADD_NEW_MEDICAL_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myMedicalConstants.ADD_NEW_MEDICAL_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case myMedicalConstants.ADD_NEW_MEDICAL_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myMedicalConstants.UPDATE_MEDICAL_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myMedicalConstants.UPDATE_MEDICAL_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case myMedicalConstants.UPDATE_MEDICAL_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case myMedicalConstants.DELETE_MEDICAL_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case myMedicalConstants.DELETE_MEDICAL_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case myMedicalConstants.DELETE_MEDICAL_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
  }
  return state;
};
