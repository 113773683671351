import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSchedulesVaccine,
  importScheduleVaccine,
  deleteScheduleVaccine,
  multipleDeleteScheduleVaccine,
  clearImportSchedule,
} from "../../../../actions";
import Modal from "../../Components/Modal";
import Pagination from "../../../../components/Pagination";
import DashboardLayout from "../../Layout";
import queryString from "query-string";
import moment from "moment";
import Swal from "sweetalert2";
import ExcelJS from "exceljs";
import saveAs from "file-saver";

const ScheduleVaccine = () => {
  const [id, setId] = useState("");
  const [files, setFiles] = useState({ name: "Upload Excel" });
  const [excel, setExcel] = useState([]);
  const [importSchedulesModal, setImportSchedulesModal] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkId, setCheckId] = useState({});
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const [importError, setImportError] = useState(false);
  const searchKey = useRef("");
  const sortBy = useRef("asc");
  const startDate = useRef("");
  const endDate = useRef("");
  const scheduleVaccine = useSelector((state) => state.scheduleVaccine);
  const dispatch = useDispatch();

  const initState = () => {
    setId("");
    setFiles({ name: "Upload Excel" });
    setExcel([]);
  };

  useEffect(() => {
    dispatch(getAllSchedulesVaccine(search));
  }, [search]);

  useEffect(() => {
    let allChecked = true;
    for (const name in checkId) {
      if (checkId[name] === false) {
        allChecked = false;
      }
    }
    if (allChecked) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [checkId]);

  useEffect(() => {
    const getData = {};

    scheduleVaccine.schedules.forEach((item) => {
      getData[item.id] = false;
    });
    setCheckId(getData);
    setPagination({
      ...pagination,
      totalData: scheduleVaccine.totalData,
    });
  }, [scheduleVaccine.schedules, scheduleVaccine.totalData]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const handleCloseModal = () => {
    initState();
    setImportSchedulesModal(false);
  };

  const handleImport = () => {
    setImportSchedulesModal(true);
  };

  const handleExcelFile = (e) => {
    e.preventDefault();
    const workbook = new ExcelJS.Workbook();
    let files = e.target.files[0];
    let dataSet = [];
    setFiles(files);

    workbook.xlsx.load(files).then(() => {
      const worksheet = workbook.getWorksheet(1);

      worksheet.eachRow((row) => {
        if (typeof row.getCell(2).value !== "string") {
          row.getCell(2).value = moment(row.getCell(2).value)
            .utc()
            .format("HH:mm:ss");
        }
        if (typeof row.getCell(3).value !== "string") {
          row.getCell(3).value = moment(row.getCell(3).value)
            .utc()
            .format("HH:mm:ss");
        }
        if (typeof row.getCell(4).value !== "string") {
          row.getCell(4).value = moment(row.getCell(4).value).utc().format();
        }

        const object = Object.assign(
          {},
          row.values.filter((e) => e !== null)
        );

        const renameKeys = (obj, newKeys) => {
          const keyValues = Object.keys(obj).map((key) => {
            const newKey = newKeys[key] || key;
            return { [newKey]: obj[key] };
          });
          return Object.assign({}, ...keyValues);
        };

        const newKeys = {
          0: "partnerId",
          1: "startTime",
          2: "endTime",
          3: "scheduleDate",
        };

        const renamedObj = renameKeys(object, newKeys);
        dataSet.push(renamedObj);
      });
    });
    setExcel(dataSet);
  };

  const handleDownload = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("template");
    let row;

    row = worksheet.addRow([
      "partnerId",
      "startTime",
      "endTime",
      "scheduleDate",
    ]);
    row.font = { bold: true };
    worksheet.addRow([1111, "10:00:00", "11:00:00", "2021-01-30"]);

    let date = new Date().getTime();
    workbook.xlsx.writeBuffer().then((buff) => {
      saveAs(new Blob([buff]), `template-${date}.xlsx`);
    });
  };

  const actionImportExcel = () => {
    let data = excel.slice(1, excel.length);
    if (excel.length > 1) {
      dispatch(importScheduleVaccine(data, search))
        .then((result) => {
          if (result) {
            dispatch(getAllSchedulesVaccine(search));
          }
        })
        .then(() => {
          initState();
          setImportSchedulesModal(false);
          console.log("close modal", scheduleVaccine.loading);
          if (scheduleVaccine.loading == false) {
            Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: false,
            }).fire({
              icon: "success",
              title: "Import Success",
            });
          }
        });
    } else if (excel.length === 1) {
      Swal.fire("Oops", "Seems like there's nothing in this file yet");
    } else {
      Swal.fire("Oops", "FIle Import Require");
    }
  };

  const handleClear = () => {
    dispatch(clearImportSchedule(search)).then(
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
      }).fire({
        icon: "success",
        title: "Clear error data success",
      })
    );
  };

  const formImportSchedules = () => {
    return (
      <Modal
        modalTitle="Import Schedule"
        show={importSchedulesModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        buttons={[
          {
            label: "Import",
            color: "primary",
            onClick: actionImportExcel,
          },
        ]}
      >
        <Row>
          <Col md>
            <div className="form-group">
              <label>Excel File (.xlsx)</label>
              <Form.File
                custom
                label={files.name}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                data-browse="Drag & Drop or Browse"
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    handleExcelFile(e);
                  } else {
                    setFiles({ name: "Upload Excel" });
                    setExcel([]);
                  }
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md>
            <div className="form-group">
              <span
                style={{ cursor: "pointer", color: "blue", marginTop: "10px" }}
                onClick={() => handleDownload()}
              >
                Download Template Schedule
              </span>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  };

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const datasheet = workbook.addWorksheet("Sheet1");
    let row;

    row = datasheet.addRow([
      "partnerId",
      "startTime",
      "endTime",
      "scheduleDate",
      "active",
      "error",
    ]);
    row.font = { bold: true };
    scheduleVaccine.dataError.map((data) => {
      datasheet.addRow([
        data.partnerId,
        data.startTime,
        data.endTime,
        moment(data.scheduleDate).format("YYYY-MM-DD"),
        data.active,
        data.error,
      ]);
    });

    workbook.xlsx.writeBuffer().then((buff) => {
      saveAs(new Blob([buff]), `errorImportSchedule.xlsx`);
    });
  };

  const handleSortBy = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      searchKey: searchKey.current.value,
      sortBy: sortBy.current.value,
      startDate: startDate.current.value,
      endDate: endDate.current.value,
      page: pagination.page,
      limit: pagination.limit,
    };

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleReset = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      searchKey: searchKey.current.value,
      sortBy: sortBy.current.value,
      startDate: (startDate.current.value = ""),
      endDate: (endDate.current.value = ""),
      page: pagination.page,
      limit: pagination.limit,
    };

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleSearch = (page, limit) => {
    let values = {
      searchKey: searchKey.current.value,
      sortBy: sortBy.current.value,
      startDate: startDate.current.value,
      endDate: endDate.current.value,
    };
    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }
    if (limit) {
      values.limit = limit;
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleDeleteSchedules = (scheduleId) => {
    Swal.fire({
      title: "Reconfirmartion",
      text: "Are you sure want to delete this schedule?",
      icon: "warning",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      cancelButtonColor: "grey",
      confirmButtonColor: "#7cb342",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteScheduleVaccine(scheduleId, search))
          .then((result) => {
            if (result) {
              dispatch(getAllSchedulesVaccine(search));
            }
          })
          .then(() => {
            initState();
            Swal.fire({
              title: "success",
              text: "Schedule berhasil di delete",
              icon: "success",
              showLoaderOnConfirm: true,
            });
          });
      }
    });
  };

  const handleCheck = (e) => {
    let name = e.target.name,
      checked = e.target.checked;

    if (name === "checkAll") {
      setCheckAll(checked);
      setCheckId((prevState) => {
        const newState = { ...prevState };
        for (const name in newState) {
          newState[name] = checked;
        }
        return newState;
      });
    } else {
      setCheckId((prevState) => {
        const newState = { ...prevState };
        newState[name] = !prevState[name];
        return newState;
      });
    }
  };

  const handleMultipleDeleteSchedules = () => {
    let getId = Object.keys(checkId)
      .filter((k) => checkId[k])
      .toString();

    const form = new FormData();
    form.append("teleScheduleIds", getId);

    if (form.get("teleScheduleIds") != "") {
      Swal.fire({
        title: "Reconfirmartion",
        text: "Are you sure want to delete this schedule?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "grey",
        confirmButtonColor: "#7cb342",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(multipleDeleteScheduleVaccine(form, search))
            .then((result) => {
              if (result) {
                dispatch(getAllSchedulesVaccine(search));
              }
            })
            .then(() => {
              Swal.fire({
                title: "success",
                text: "Schedule berhasil di delete",
                icon: "success",
                showLoaderOnConfirm: true,
              });
            });
        }
      });
    } else {
      Swal.fire({
        title: "Reconfirmartion",
        text: "Please select schedule first",
        icon: "warning",
        confirmButtonColor: "#7cb342",
        confirmButtonText: "OK",
      });
    }
  };

  const dataTables = () => {
    return scheduleVaccine.loading ? (
      <tr>
        <td colSpan="9">
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        {scheduleVaccine.schedules.length > 0 ? (
          scheduleVaccine.schedules.map((schedule, index) => (
            <tr key={schedule.id}>
              <td>
                <input
                  type="checkbox"
                  name={schedule.id}
                  checked={!!checkId[schedule.id]}
                  onChange={handleCheck}
                />
              </td>
              <td>{(pagination.page - 1) * 10 + index + 1}</td>
              <td>{schedule.partner ? schedule.partner : "-"}</td>
              <td>
                {schedule.assignedOrder === null ? (
                  <div className="rectangle-no">No</div>
                ) : (
                  <div class="rectangle-yes">Yes</div>
                )}
              </td>
              <td>
                {schedule.scheduleDate
                  ? moment(schedule.scheduleDate).format("LL")
                  : "-"}
              </td>
              <td>
                {schedule.startTime
                  ? moment(schedule.startTime, "HH:mm:ss").format("HH:mm")
                  : "-"}
              </td>
              <td>
                {schedule.endTime
                  ? moment(schedule.endTime, "HH:mm:ss").format("HH:mm")
                  : "-"}
              </td>
              <td>
                {schedule.assignedOrder === null ? (
                  <div>
                    <span
                      className="form-title"
                      onClick={() => handleDeleteSchedules(schedule.id)}
                      style={{ cursor: "pointer", width: "45px" }}
                    >
                      <i className="fa fa-trash"></i>
                    </span>
                  </div>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="9">Empty data</td>
          </tr>
        )}
      </>
    );
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Active Schedule</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        <div className="row">
          <div className="col-md-8">
            <span id="finance-title" className="mb10">
              Detail Schedule
            </span>
          </div>

          <div className="col-md-4">
            <select
              className="form-control table-search-field"
              style={{ marginBottom: "1em" }}
              onChange={handleSortBy}
              ref={sortBy}
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <button
              type="button"
              className="btn btn-outline-primary"
              style={{ marginRight: "5px", marginBottom: "1em" }}
              onClick={() => handleImport()}
            >
              Import Schedule
            </button>

            <button
              type="button"
              className="btn btn-outline-danger"
              style={{ marginBottom: "1em" }}
              onClick={() => handleMultipleDeleteSchedules()}
            >
              Delete Schedule
            </button>
          </div>

          <div style={{ marginBottom: "1em" }} className="col-md-4">
            <div className="input-group">
              <input
                type="text"
                className="form-control table-search-field"
                placeholder="Search Name"
                ref={searchKey}
              />
              <span className="input-group-btn">
                <button
                  type="submit"
                  className="btn btn-primary table-btn-search"
                  onClick={() => handleSearch()}
                >
                  <i className="fa fa-search"></i>
                </button>
              </span>
            </div>
          </div>
        </div>

        {!scheduleVaccine.loading && scheduleVaccine.dataError ? (
          scheduleVaccine.dataError.length > 0 ? (
            <div className="row">
              <div className="col-md-12">
                <div
                  className="well"
                  style={{
                    marginBottom: "10px",
                    padding: "20px",
                    background: "#e2abab6b",
                    borderRadius: "5px",
                  }}
                >
                  <p style={{ marginBottom: "1rem" }}>
                    <b>Error Import Data</b>
                  </p>
                  {scheduleVaccine.dataError.map((item, index) => (
                    <li
                      key={index + 1}
                      className="errorMsgStyle"
                      style={{ fontSize: "12px" }}
                    >
                      partnerId : {item.partnerId} - Error : {item.error}
                    </li>
                  ))}
                  <div className="" style={{ marginTop: "20px" }}>
                    <button
                      id="resetError"
                      className="btn btn-danger"
                      style={{ marginRight: "10px" }}
                      onClick={handleClear}
                    >
                      <span className="fa fa-trash text-white"></span> Clear
                    </button>

                    <button
                      className="btn btn-success"
                      target="_blank"
                      style={{ marginRight: "10px" }}
                      onClick={() => downloadExcel()}
                    >
                      <span className="fa fa-download text-white"></span>{" "}
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null
        ) : null}

        <div className="row mb15">
          <div className="link-btn">
            <button
              type="button"
              className="btn btn-link no-padding"
              style={{ textDecoration: "none", boxShadow: "none" }}
              data-toggle="collapse"
              data-target="#collapseDetailSchedule"
            >
              Filter By
              <i className="fa fa-angle-down" style={{ marginLeft: "5px" }} />
            </button>
          </div>

          <div className="col">
            <hr />
          </div>
        </div>

        <div id="collapseDetailSchedule" className="collapse">
          <div id="filterForm" className="row filter-by">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb10">Date From</div>
                  <input type="date" className="form-control" ref={startDate} />
                </div>

                <div className="col-md-6">
                  <div className="mb10">Date To</div>
                  <input type="date" className="form-control" ref={endDate} />
                </div>
              </div>
            </div>
          </div>

          <div className="text-right" style={{ marginTop: "25px" }}>
            <button
              type="button"
              className="btn btn-secondary"
              style={{ width: "100px", marginRight: "5px" }}
              onClick={() => handleReset()}
            >
              Reset
            </button>

            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100px" }}
              onClick={() => handleSearch()}
            >
              Apply
            </button>
          </div>

          <div style={{ width: "100%" }}>
            <hr />
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th rowSpan={2} className="table-header-middle">
                  {scheduleVaccine.schedules.length > 0 ? (
                    <input
                      type="checkbox"
                      name="checkAll"
                      checked={checkAll}
                      onChange={handleCheck}
                    />
                  ) : (
                    <span>-</span>
                  )}
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "45px" }}
                >
                  No
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "300px" }}
                >
                  Name
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "100px" }}
                >
                  Assigned to order
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Date
                </th>

                <th colSpan={2} className="table-header-middle">
                  Time
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "150px" }}
                >
                  Action
                </th>
              </tr>

              <tr>
                <th>Start</th>
                <th>End</th>
              </tr>
            </thead>
            <tbody>{dataTables()}</tbody>
          </table>
        </div>

        <div style={{ display: "flex" }}>
          <div
            className="hidden-total text-left"
            style={{
              fontWeight: "bold",
              marginTop: "10px",
              marginRight: "auto",
            }}
          >{`Total Records ${
            scheduleVaccine.totalData ? scheduleVaccine.totalData : "-"
          }`}</div>

          {scheduleVaccine.totalData > 10 ? (
            <Pagination
              props={pagination}
              setProps={setPagination}
              onChange={(page, limit) => {
                handleSearch(page, limit);
              }}
            />
          ) : null}
        </div>
      </div>
      {formImportSchedules()}
    </DashboardLayout>
  );
};

export default ScheduleVaccine;
