import React, { Component } from "react";
import AWS from "aws-sdk";
import { Amplify } from 'aws-amplify';

import maplibregl from "maplibre-gl";
import { createMap } from "maplibre-gl-js-amplify";
import "maplibre-gl/dist/maplibre-gl.css";

let zoom = 14;

const awsRegion = `${process.env.REACT_APP_AWS_REGION}`;
const awsIdentityPool = `${process.env.REACT_APP_AWS_IDENTITY_POOL}`;
const awsMap = `${process.env.REACT_APP_AWS_MAP}`;
const awsPlaceIndex = `${process.env.REACT_APP_AWS_PLACE_INDEX}`;


export class MapContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      address: this.props.existData ? this.props.existData.address : "",
      mapCenter: {
        lat: this.props.existData
          ? this.props.existData.lat
          : -6.175400791146999,
        lng: this.props.existData
          ? this.props.existData.lng
          : 106.82716145352265,
      },
      searchLoading: false,
      searchTimeout: 0,
      suggestions: null,
      map: null,
      marker: null,
      awsCredentials: null,
    };
  }

  async initializeMap() {
    AWS.config.region = awsRegion;
    AWS.config.credentials = this.state.awsCredentials;

    await this.state.awsCredentials.getPromise();

    Amplify.configure({
      Auth: {
        identityPoolId: awsIdentityPool, // REQUIRED - Amazon Cognito Identity Pool ID
        region: awsRegion, // REQUIRED - Amazon Cognito Region
      },
      geo: {
        AmazonLocationService: {
          maps: {
            items: {
              "ProdGrabMap": { // REQUIRED - Amazon Location Service Map resource name
                style: "VectorGrabMonoStreet", // REQUIRED - String representing the style of map resource
              },
            },
            default: awsMap, // REQUIRED - Amazon Location Service Map resource name to set as default
          },
          search_indices: {
            items: [awsPlaceIndex], // REQUIRED - Amazon Location Service Place Index name
            default: awsPlaceIndex, // REQUIRED - Amazon Location Service Place Index name to set as default
          },
          region: awsRegion, // REQUIRED - Amazon Location Service Region
        },
      },
    })

    this.state.map = await createMap({
        container: "map",
        center: [this.state.mapCenter.lng, this.state.mapCenter.lat],
        minZoom: 10,
        zoom: zoom,
        maxZoom: 18
    })
    this.state.map.setStyle(awsMap);
    this.state.map.addControl(new maplibregl.NavigationControl(), 'top-right');
  }

  initializeMarker(position) {
    var el = document.createElement('div');
    el.className = 'marker';
    el.style.backgroundImage = 'url(/img/icon/pin-point.png)';
    el.style.backgroundRepeat = 'no-repeat';
    el.style.backgroundSize = 'cover';
    el.style.width = '30px';
    el.style.height = '30px';

    this.state.marker = new maplibregl.Marker({element: el, draggable: true})
      .setLngLat([position.lng, position.lat])
      .addTo(this.state.map);

    this.state.marker.on('dragend', this.handleDragEnd);
  }

  async suggestPlace(term) {
    const location = new AWS.Location();
    const results = await location.searchPlaceIndexForSuggestions({
      IndexName: awsPlaceIndex,
      Text: term,
      FilterCountries: ['IDN'],
      MaxResults: 10
    }).promise();
    return results.Results;
  }

  async findByPosition(lngLat) {
    const location = new AWS.Location();
    const results = await location.searchPlaceIndexForPosition({
      IndexName: awsPlaceIndex,
      Position: [lngLat.lng, lngLat.lat],
      MaxResults: 1
    }).promise();
    return results.Results;
  }

  async findByAddress(placeId) {
    const location = new AWS.Location();
    const result = await location.getPlace({
      IndexName: awsPlaceIndex,
      PlaceId: placeId
    }).promise();
    return result;
  }

  handleDragEnd = async() => {
    let lngLat = this.state.marker.getLngLat();
    let { lat, lng } = lngLat;
    
    try {
      const results = await this.findByPosition(lngLat);

      if(results.length) {
        let fullAddress = results[0].Place.Label;
        let postalCode = results[0].Place.PostalCode;

        if(postalCode == undefined  && !this.props.isVersion2) {
          fullAddress = this.props.existData ? this.props.existData.address : "";
          lat = this.props.existData ? this.props.existData.lat : -6.175400791146999;
          lng = this.props.existData ? this.props.existData.lng : 106.82716145352265;
        }

        this.setState({
          address: fullAddress,
          mapCenter: {
            lat: lat ? lat : null,
            lng: lng ? lng : null,
          },
        });

        this.props.addressData({
          latitude: lat ? lat : null,
          longitude: lng ? lng : null,
          fullAddress: fullAddress ? fullAddress : "",
          postalCode: postalCode ? postalCode : "",
        });
        
        this.state.map.easeTo({ center: lngLat });
      } else {
        console.log("findByPosition: no data");
      }
    }
    catch (e) {
      console.log("handlerError", e);
    }
  }

  async componentDidMount() {
    this.state.awsCredentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: awsIdentityPool,
    });

    await this.initializeMap();
    await this.initializeMarker(this.state.mapCenter);
  }

  handleSelectPlace = async(placeId) => {
    this.handleChangeSuggestion(null);

    if(placeId.length) {
      try{
        const result = await this.findByAddress(placeId);
        if(result) {
          let latLng = result.Place.Geometry.Point;
          let lat = latLng[1];
          let lng = latLng[0];

          this.setState({ mapCenter: latLng });

          let fullAddress = result.Place.Label;
          let postalCode = result.Place.PostalCode;

          if(postalCode == undefined  && !this.props.isVersion2) {
            fullAddress = this.props.existData ? this.props.existData.address : "";
            lat = this.props.existData ? this.props.existData.lat : -6.175400791146999;
            lng = this.props.existData ? this.props.existData.lng : 106.82716145352265;
          }

          console.log("fullAddress", fullAddress);
          console.log("latitude", lat);
          console.log("longitude", lng);
          console.log("postalCode", postalCode);

          this.setState({
            address: fullAddress,
            mapCenter: {
              lat: lat ? lat : null,
              lng: lng ? lng : null,
            }
          });

          this.props.addressData({
            latitude: lat ? lat : null,
            longitude: lng ? lng : null,
            fullAddress: fullAddress ? fullAddress : "",
            postalCode: postalCode ? postalCode : "",
          });

          this.state.map.easeTo({ center: latLng });
          this.state.marker.setLngLat(latLng);
        } else {
          console.log("findByAddress: no data");
        }
      } catch(e) {
        console.log("handlerError", e);
      }
    }
  }

  searchTerm = async(query) => {
    const term = query.trim();

    if(term.length && term.length > 7) {
      this.handleChangeSuggestion(null);
      this.handleSearchLoader(true);

      try {
        const results = await this.suggestPlace(term);
        if(results.length) {
          this.handleChangeSuggestion(results);
        } else {
          console.log("suggestions not found");
        }
      } catch (e) {
        console.log("handlerError", e);
      } finally {
        this.handleSearchLoader(false);
      }
    } else {
      this.handleChangeSuggestion(null);
    }
  };

  handleSearchTerm = (e) => {
    let query = e.target.value;

    this.handleChangeAddress(query);

    if(this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    this.setState({
      searchTimeout: setTimeout(() => this.searchTerm(query), 250)
    })
  };

  handleChangeAddress = (address) => {
    this.setState({ address });
  };

  handleChangeSuggestion = (suggestions) => {
    this.setState({ suggestions });
  }

  handleSearchLoader = (searchLoading) => {
    this.setState({ searchLoading });
  }

  render() {
    return (
      <div>
        <div className="form-group">
          <div
            style={{
              height: "400px",
              position: "relative",
            }}
          >
            <div id="map" style={{ height: '100%', width: '100%' }}></div>
          </div>

          <div
            style={{
              zIndex: "99",
              position: "relative",
            }}
          >
            <span
              className="form-group"
              style={{
                color: "red",
                paddingTop: "10px"
              }}
            >
              <i>
                {this.props.notesLabel}{" "}
                <img className="icon-map" src={"/img/icon/pin-point.png"} />
                 {this.props.notesLabelDescription}{" "}
              </i>
            </span>
          </div>

          <div
            style={{
              zIndex: "99",
              position: "relative",
            }}
          >
            <label className="form-label-bold">
              {this.props.inputLabel}{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <input id="search-term"
              style={{ position: "relative", zIndex: "99", width: "100%" }}
              placeholder={ this.props.placeholder ? this.props.placeholder : "Search Places" }
              className="form-control location-search-input form-input-map"
              onChange={this.handleSearchTerm}
              value={this.state.address}
            />
            <div id="search-result"
              className="autocomplete-dropdown-container form-search-map"
              style={{ zIndex: "99", position: "absolute" }}
            >
              <ul id="autocompletedlist" className={ this.state.suggestions ? 'autocomplete-filled' : 'autocomplete-empty' }>
                {this.state.searchLoading && 
                  <li>Loading...</li>
                }
                {this.state.suggestions && this.state.suggestions.map((suggestion, index) => {
                  return (
                    <li key={"suggestion-" + index} value={suggestion.Text} onClick={() => this.handleSelectPlace(suggestion.PlaceId)}>{suggestion.Text}</li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MapContainer;
