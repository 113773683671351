import { dokterWorklistConstants } from "../actions/constants";

const initState = {
  orders: [],
  docUrl: [],
  medical: [],
  urlConsent: [],
  urlPharmacy: [],
  order: {},
  patient: {},
  icd: [],
  bmi: null,
  idealWeight: null,
  testLabList: [],
  medicines: [],
  medicalRecords: [],
  eventList: [],
  totalData: 0,
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case dokterWorklistConstants.GET_ALL_DOKTERWORKLIST_SUCCESS:
      state = {
        ...state,
        orders: action.payload.orders,
        totalData: action.payload.totalData,
        loading: false,
      };
      break;
    case dokterWorklistConstants.GET_ALL_DOKTERWORKLIST_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case dokterWorklistConstants.GET_ALL_DOKTERWORKLIST_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case dokterWorklistConstants.GENERATE_PRESCRIPTION_SUCCESS:
      state = {
        ...state,
        docUrl: action.payload,
        loading: false,
      };
      break;
    case dokterWorklistConstants.GENERATE_PRESCRIPTION_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case dokterWorklistConstants.GENERATE_PRESCRIPTION_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case dokterWorklistConstants.SEND_EMAIL_TELE_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dokterWorklistConstants.SEND_EMAIL_TELE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case dokterWorklistConstants.SEND_EMAIL_TELE_DOCUMENT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case dokterWorklistConstants.CREATE_MEDICAL_RECORD_SUCCESS:
      state = {
        ...state,
        medical: action.payload,
        loading: false,
      };
      break;
    case dokterWorklistConstants.CREATE_MEDICAL_RECORD_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case dokterWorklistConstants.CREATE_MEDICAL_RECORD_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case dokterWorklistConstants.UPLOAD_CONSENT_SUCCESS:
      state = {
        ...state,
        urlConsent: action.payload,
        loading: false,
      };
      break;
    case dokterWorklistConstants.UPLOAD_CONSENT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case dokterWorklistConstants.UPLOAD_CONSENT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case dokterWorklistConstants.UPLOAD_PHARMACY_SUCCESS:
      state = {
        ...state,
        urlPharmacy: action.payload,
        loading: false,
      };
      break;
    case dokterWorklistConstants.UPLOAD_PHARMACY_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case dokterWorklistConstants.UPLOAD_PHARMACY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case dokterWorklistConstants.FREETELE_SUMMARY_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case dokterWorklistConstants.FREETELE_SUMMARY_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case dokterWorklistConstants.FREETELE_SUMMARY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case dokterWorklistConstants.SEND_REMINDER_FREETELE_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case dokterWorklistConstants.SEND_REMINDER_FREETELE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case dokterWorklistConstants.SEND_REMINDER_FREETELE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case dokterWorklistConstants.GET_ICD10_SUCCESS:
      state = {
        ...state,
        loading: false,
        icd: action.payload.results,
        totalData: action.payload.totalData,
      };
      break;
    case dokterWorklistConstants.GET_ICD10_REQUEST:
        state = { ...state,
        loading: true,
      };
      break;
    case dokterWorklistConstants.GET_ICD10_FAILURE:
      state = { ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case dokterWorklistConstants.GET_TEST_LAB_SUCCESS:
      state = {
        ...state,
        loading: false,
        testLabList: action.payload.testLabList,
      };
      break;
    case dokterWorklistConstants.GET_TEST_LAB_REQUEST:
        state = { ...state,
        loading: true,
      };
      break;
    case dokterWorklistConstants.GET_TEST_LAB_FAILURE:
      state = { ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case dokterWorklistConstants.GET_MEDICINES_SUCCESS:
      state = {
        ...state,
        loading: false,
        medicines: action.payload.medicines,
      };
      break;
    case dokterWorklistConstants.GET_MEDICINES_REQUEST:
        state = { ...state,
        loading: true,
      };
      break;
    case dokterWorklistConstants.GET_MEDICINES_FAILURE:
      state = { ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case dokterWorklistConstants.GET_MEDICAL_DETAIL_SUCCESS:
      state = {
        ...state,
        loading: false,
        order: action.payload.order,
      };
      break;
    case dokterWorklistConstants.GET_MEDICAL_DETAIL_REQUEST:
        state = { ...state,
        loading: true,
      };
      break;
    case dokterWorklistConstants.GET_MEDICAL_DETAIL_FAILURE:
      state = { ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case dokterWorklistConstants.NOTIFY_EMERGENCY_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case dokterWorklistConstants.NOTIFY_EMERGENCY_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case dokterWorklistConstants.NOTIFY_EMERGENCY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case dokterWorklistConstants.GET_ALL_MEDICALHISTORY_SUCCESS:
      state = {
        ...state,
        medicalRecords: action.payload.medicalRecords,
        patient: action.payload.patient,
        totalData: action.payload.totalData,
        loading: false,
      };
      break;
    case dokterWorklistConstants.GET_ALL_MEDICALHISTORY_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case dokterWorklistConstants.GET_ALL_MEDICALHISTORY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case dokterWorklistConstants.REPORT_NOPATIENT_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case dokterWorklistConstants.REPORT_NOPATIENT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case dokterWorklistConstants.REPORT_NOPATIENT_FAILURE:
      state = {
        ...state,
        error: action.payload.message,
        loading: false,
      };
      break;
    case dokterWorklistConstants.GET_BMI_SUCCESS:
      state = {
        ...state,
        loading: false,
        bmi: action.payload.data.bmi,
        idealWeight: action.payload.data.idealWeight,
      };
      break;
    case dokterWorklistConstants.GET_BMI_REQUEST:
      state = {
        ...state,
        // loading: true,
      };
      break;
    case dokterWorklistConstants.GET_BMI_FAILURE:
      state = {
        ...state,
        error: action.payload.message,
        loading: false,
      };
      break;
    case dokterWorklistConstants.GET_EVENTHS_SUCCESS:
        state = {
          ...state,
          loading: false,
          eventList: action.payload.eventList,
        };
        break;
    case dokterWorklistConstants.GET_EVENTHS_REQUEST:
        state = {
          ...state,
          // loading: true,
        };
        break;
    case dokterWorklistConstants.GET_EVENTHS_FAILURE:
        state = {
          ...state,
          error: action.payload.message,
          loading: false,
        };
        break;
    
  }
  return state;
};
