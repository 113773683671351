import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import Layout from "../Layout";

const Success = () => {
  const { state } = useLocation();
  const [link, setLink] = useState("aidoapp://aido.id/deepLink/order");
  const history = useHistory();
  const testCovid = useSelector((state) => state.testCovid);
  const { loading, error, paymentData } = testCovid;

  if (!paymentData) {
    history.push({
      pathname: "/test-covid/order",
      state: state,
    });
  }

  useEffect(() => {
    const getToken = localStorage.getItem("accessToken");

    if (getToken) {
      setLink(`aidoapp://aido.id/deepLink/order?token=${getToken}`);
    }
  }, []);

  return (
    <Layout title="Test Covid - 19" link="/test-covid/list">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(90vh - 76px)",
        }}
      >
        <img
          src="/img/illustration/success.png"
          style={{
            height: "150px",
            width: "150px",
            marginBottom: "20px",
          }}
        />

        <span>Kamu telah sukses melakukan pemesanan</span>
        <span>Selesaikan pembayaran kamu di aplikasi Aido</span>

        <Link
          to={{
            pathname: link,
          }}
          target="_blank"
        >
          <button
            type="button"
            className="btn btn-primary"
            style={{ width: "100%", marginTop: "20px" }}
          >
            Kembali
          </button>
        </Link>
      </div>
    </Layout>
  );
};

export default Success;
