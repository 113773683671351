import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";

const Layout = (props) => {
  return (
    <div className="container-tc">
      <div className="header-tc">
        <Link
          to={{ pathname: props.link, state: props.state }}
          target={props.target ? "_blank" : ""}
        >
          <i
            className="fa fa-chevron-left"
            style={{ fontSize: "26px", color: "black" }}
          />
        </Link>
        <div id="finance-title" className="title-tc">
          {props.title}
        </div>
      </div>

      <div id="contentLoader" className="content-wrapper">
        {props.children}
      </div>
    </div>
  );
};

export default Layout;
