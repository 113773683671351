import React, { Fragment } from "react";
import { Modal, Button, Col, Row } from "react-bootstrap";

const Modals = (props) => {
  return (
    <Modal size={props.size} show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>{props.modalTitle}</Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        {props.buttons ? (
          props.buttons.map((btn, index) => (
            <Row key={index} style={{ width: "100%" }}>
              <Col>
                <Button
                  className="btn-close"
                  variant="secondary"
                  style={{ width: "100%" }}
                  onClick={props.handleClose}
                >
                  <span>Close</span>
                </Button>
              </Col>
              <Col>
                <Button
                  variant={btn.color}
                  style={{ width: "100%" }}
                  onClick={btn.onClick}
                  disabled={btn.disabled}
                >
                  {btn.spinner}
                  <span>{btn.label}</span>
                </Button>
              </Col>
            </Row>
          ))
        ) : (
          <Row style={{ width: "100%" }}>
            <Col>
              <Button
                className="btn-close"
                variant="secondary"
                style={{ width: "100%" }}
                onClick={props.handleClose}
              >
                <span>Close</span>
              </Button>
            </Col>
            <Col>
              <Button
                className="btn-save"
                variant="primary"
                style={{ width: "100%" }}
                onClick={props.onSubmit}
              >
                <span>Save</span>
              </Button>
            </Col>
          </Row>
        )}
        {props.close && (
          <Row style={{ width: "100%" }}>
            <Col>
              <Button
                className="btn-close"
                variant="secondary"
                style={{ width: "100%" }}
                onClick={props.handleClose}
              >
                <span>Close</span>
              </Button>
            </Col>
          </Row>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default Modals;
