export const authConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
};

export const myTeamConstants = {
  GET_ALL_TEAMS_REQUEST: "GET_ALL_TEAMS_REQUEST",
  GET_ALL_TEAMS_SUCCESS: "GET_ALL_TEAMS_SUCCESS",
  GET_ALL_TEAMS_FAILURE: "GET_ALL_TEAMS_FAILURE",
  GET_SPECIALIST_REQUEST: "GET_SPECIALIST_REQUEST",
  GET_SPECIALIST_SUCCESS: "GET_SPECIALIST_SUCCESS",
  GET_SPECIALIST_FAILURE: "GET_SPECIALIST_FAILURE",
  GET_TEAMS_BY_ID_REQUEST: "GET_TEAMS_BY_ID_REQUEST",
  GET_TEAMS_BY_ID_SUCCESS: "GET_TEAMS_BY_ID_SUCCESS",
  GET_TEAMS_BY_ID_FAILURE: "GET_TEAMS_BY_ID_FAILURE",
  ADD_NEW_TEAMS_REQUEST: "ADD_NEW_TEAMS_REQUEST",
  ADD_NEW_TEAMS_SUCCESS: "ADD_NEW_TEAMS_SUCCESS",
  ADD_NEW_TEAMS_FAILURE: "ADD_NEW_TEAMS_FAILURE",
  UPDATE_TEAMS_REQUEST: "UPDATE_TEAMS_REQUEST",
  UPDATE_TEAMS_SUCCESS: "UPDATE_TEAMS_SUCCESS",
  UPDATE_TEAMS_FAILURE: "UPDATE_TEAMS_FAILURE",
  DELETE_TEAMS_REQUEST: "DELETE_TEAMS_REQUEST",
  DELETE_TEAMS_SUCCESS: "DELETE_TEAMS_SUCCESS",
  DELETE_TEAMS_FAILURE: "DELETE_TEAMS_FAILURE",
  ADD_NEW_TEAMS_TELE_REQUEST: "ADD_NEW_TEAMS_TELE_REQUEST",
  ADD_NEW_TEAMS_TELE_SUCCESS: "ADD_NEW_TEAMS_TELE_SUCCESS",
  ADD_NEW_TEAMS_TELE_FAILURE: "ADD_NEW_TEAMS_TELE_FAILURE",
};

export const educationConstants = {
  ADD_NEW_EDUCATION_REQUEST: "ADD_NEW_EDUCATION_REQUEST",
  ADD_NEW_EDUCATION_SUCCESS: "ADD_NEW_EDUCATION_SUCCESS",
  ADD_NEW_EDUCATION_FAILURE: "ADD_NEW_EDUCATION_FAILURE",
  UPDATE_EDUCATION_REQUEST: "UPDATE_TEAMS_REQUEST",
  UPDATE_EDUCATION_SUCCESS: "UPDATE_TEAMS_SUCCESS",
  UPDATE_EDUCATION_FAILURE: "UPDATE_TEAMS_FAILURE",
  DELETE_EDUCATION_REQUEST: "DELETE_EDUCATION_REQUEST",
  DELETE_EDUCATION_SUCCESS: "DELETE_EDUCATION_SUCCESS",
  DELETE_EDUCATION_FAILURE: "DELETE_EDUCATION_FAILURE",
  GET_EDUCATION_REQUEST: "GET_EDUCATION_REQUEST",
  GET_EDUCATION_SUCCESS: "GET_EDUCATION_SUCCESS",
  GET_EDUCATION_FAILURE: "GET_EDUCATION_FAILURE",
  GET_UNIVERSITIES_REQUEST: "GET_UNIVERSITIES_REQUEST",
  GET_UNIVERSITIES_SUCCESS: "GET_UNIVERSITIES_SUCCESS",
  GET_UNIVERSITIES_FAILURE: "GET_UNIVERSITIES_FAILURE",
};

export const myPromoConstants = {
  GET_ALL_PROMO_REQUEST: "GET_ALL_PROMO_REQUEST",
  GET_ALL_PROMO_SUCCESS: "GET_ALL_PROMO_SUCCESS",
  GET_ALL_PROMO_FAILURE: "GET_ALL_PROMO_FAILURE",
  GET_PROMO_BY_ID_REQUEST: "GET_PROMO_BY_ID_REQUEST",
  GET_PROMO_BY_ID_SUCCESS: "GET_PROMO_BY_ID_SUCCESS",
  GET_PROMO_BY_ID_FAILURE: "GET_PROMO_BY_ID_FAILURE",
  GET_PROMO_CONSTANTS_REQUEST: "GET_PROMO_CONSTANTS_REQUEST",
  GET_PROMO_CONSTANTS_SUCCESS: "GET_PROMO_CONSTANTS_SUCCESS",
  GET_PROMO_CONSTANTS_FAILURE: "GET_PROMO_CONSTANTS_FAILURE",
  ADD_NEW_PROMO_REQUEST: "ADD_NEW_PROMO_REQUEST",
  ADD_NEW_PROMO_SUCCESS: "ADD_NEW_PROMO_SUCCESS",
  ADD_NEW_PROMO_FAILURE: "ADD_NEW_PROMO_FAILURE",
  UPDATE_PROMO_REQUEST: "UPDATE_PROMO_REQUEST",
  UPDATE_PROMO_SUCCESS: "UPDATE_PROMO_SUCCESS",
  UPDATE_PROMO_FAILURE: "UPDATE_PROMO_FAILURE",
  DELETE_PROMO_REQUEST: "DELETE_PROMO_REQUEST",
  DELETE_PROMO_SUCCESS: "DELETE_PROMO_SUCCESS",
  DELETE_PROMO_FAILURE: "DELETE_PROMO_FAILURE",
};

export const myScheduleConstants = {
  GET_ALL_SCHEDULES_REQUEST: "GET_ALL_SCHEDULES_REQUEST",
  GET_ALL_SCHEDULES_SUCCESS: "GET_ALL_SCHEDULES_SUCCESS",
  GET_ALL_SCHEDULES_FAILURE: "GET_ALL_SCHEDULES_FAILURE",
  ADD_NEW_SCHEDULES_REQUEST: "ADD_NEW_SCHEDULES_REQUEST",
  ADD_NEW_SCHEDULES_SUCCESS: "ADD_NEW_SCHEDULES_SUCCESS",
  ADD_NEW_SCHEDULES_FAILURE: "ADD_NEW_SCHEDULES_FAILURE",
  IMPORT_SCHEDULES_REQUEST: "IMPORT_SCHEDULES_REQUEST",
  IMPORT_SCHEDULES_SUCCESS: "IMPORT_SCHEDULES_SUCCESS",
  IMPORT_SCHEDULES_FAILURE: "IMPORT_SCHEDULES_FAILURE",
  CLEAR_IMPORT_SCHEDULE_REQUEST: "CLEAR_IMPORT_SCHEDULE_REQUEST",
  CLEAR_IMPORT_SCHEDULE_SUCCESS: "CLEAR_IMPORT_SCHEDULE_SUCCESS",
  CLEAR_IMPORT_SCHEDULE_FAILURE: "CLEAR_IMPORT_SCHEDULE_SUCCESS",
  UPDATE_SCHEDULES_REQUEST: "UPDATE_SCHEDULES_REQUEST",
  UPDATE_SCHEDULES_SUCCESS: "UPDATE_SCHEDULES_SUCCESS",
  UPDATE_SCHEDULES_FAILURE: "UPDATE_SCHEDULES_FAILURE",
  DELETE_SCHEDULES_REQUEST: "DELETE_SCHEDULES_REQUEST",
  DELETE_SCHEDULES_SUCCESS: "DELETE_SCHEDULES_SUCCESS",
  DELETE_SCHEDULES_FAILURE: "DELETE_SCHEDULES_FAILURE",
  MULTIPLE_DELETE_SCHEDULES_REQUEST: "MULTIPLE_DELETE_SCHEDULES_REQUEST",
  MULTIPLE_DELETE_SCHEDULES_SUCCESS: "MULTIPLE_DELETE_SCHEDULES_SUCCESS",
  MULTIPLE_DELETE_SCHEDULES_FAILURE: "MULTIPLE_DELETE_SCHEDULES_FAILURE",
};

export const waitingInvoiceConstants = {
  GET_ALL_WAITING_INVOICE_REQUEST: "GET_ALL_WAITING_INVOICE_REQUEST",
  GET_ALL_WAITING_INVOICE_SUCCESS: "GET_ALL_WAITING_INVOICE_SUCCESS",
  GET_ALL_WAITING_INVOICE_FAILURE: "GET_ALL_WAITING_INVOICE_FAILURE",
  GET_COMPLETED_DETAIL_REQUEST: "GET_COMPLETED_DETAIL_REQUEST",
  GET_COMPLETED_DETAIL_SUCCESS: "GET_COMPLETED_DETAIL_SUCCESS",
  GET_COMPLETED_DETAIL_FAILURE: "GET_COMPLETED_DETAIL_FAILURE",
  UPLOAD_INVOICE_BILLING_REQUEST: "UPLOAD_INVOICE_BILLING_REQUEST",
  UPLOAD_INVOICE_BILLING_SUCCESS: "UPLOAD_INVOICE_BILLING_SUCCESS",
  UPLOAD_INVOICE_BILLING_FAILURE: "UPLOAD_INVOICE_BILLING_FAILURE",
  ADD_EXCESS_PAYMENT_REQUEST: "ADD_EXCESS_PAYMENT_REQUEST",
  ADD_EXCESS_PAYMENT_SUCCESS: "ADD_EXCESS_PAYMENT_SUCCESS",
  ADD_EXCESS_PAYMENT_FAILURE: "ADD_EXCESS_PAYMENT_FAILURE",
};

export const registerConstants = {
  GET_REGISTER_LIST_REQUEST: "GET_REGISTER_LIST_REQUEST",
  GET_REGISTER_LIST_SUCCESS: "GET_REGISTER_LIST_SUCCESS",
  GET_REGISTER_LIST_FAILURE: "GET_REGISTER_LIST_FAILURE",
  GET_ALL_PATIENTS_SUCCESS: "GET_ALL_PATIENTS_SUCCESS",
  GET_ALL_PATIENTS_REQUEST: "GET_ALL_PATIENTS_REQUEST",
  GET_ALL_PATIENTS_FAILURE: "GET_ALL_PATIENTS_FAILURE",
  GET_REGISTER_BY_ID_REQUEST: "GET_REGISTER_BY_ID_REQUEST",
  GET_REGISTER_BY_ID_SUCCESS: "GET_REGISTER_BY_ID_SUCCESS",
  GET_REGISTER_BY_ID_FAILURE: "GET_REGISTER_BY_ID_FAILURE",
  GET_REGISTERPATIENT_LIST_REQUEST: "GET_REGISTERPATIENT_LIST_REQUEST",
  GET_REGISTERPATIENT_LIST_SUCCESS: "GET_REGISTERPATIENT_LIST_SUCCESS",
  GET_REGISTERPATIENT_LIST_FAILURE: "GET_REGISTERPATIENT_LIST_FAILURE",
  GET_RELATION_LIST_REQUEST: "GET_RELATION_LIST_REQUEST",
  GET_RELATION_LIST_SUCCESS: "GET_RELATION_LIST_SUCCESS",
  GET_RELATION_LIST_FAILURE: "GET_RELATION_LIST_FAILURE",
  FIND_PATIENT_DETAIL_REQUEST: "FIND_PATIENT_DETAIL_REQUEST",
  FIND_PATIENT_DETAIL_SUCCESS: "FIND_PATIENT_DETAIL_SUCCESS",
  FIND_PATIENT_DETAIL_FAILURE: "FIND_PATIENT_DETAIL_FAILURE",
  GET_ADDRESS_REQUEST: "GET_ADDRESS_REQUEST",
  GET_ADDRESS_SUCCESS: "GET_ADDRESS_SUCCESS",
  GET_ADDRESS_FAILURE: "GET_ADDRESS_FAILURE",
  GET_PACKAGE_REQUEST: "GET_PACKAGE_REQUEST",
  GET_PACKAGE_SUCCESS: "GET_PACKAGE_SUCCESS",
  GET_PACKAGE_FAILURE: "GET_PACKAGE_FAILURE",
  SUBMIT_SINGLE_REQUEST: "SUBMIT_SINGLE_REQUEST",
  SUBMIT_SINGLE_SUCCESS: "SUBMIT_SINGLE_SUCCESS",
  SUBMIT_SINGLE_FAILURE: "SUBMIT_SINGLE_FAILURE",
  SUBMIT_MULTIPLE_REQUEST: "SUBMIT_MULTIPLE_REQUEST",
  SUBMIT_MULTIPLE_SUCCESS: "SUBMIT_MULTIPLE_SUCCESS",
  SUBMIT_MULTIPLE_FAILURE: "SUBMIT_MULTIPLE_FAILURE",
  SUBMIT_PATIENT_REQUEST: "SUBMIT_PATIENT_REQUEST",
  SUBMIT_PATIENT_SUCCESS: "SUBMIT_PATIENT_SUCCESS",
  SUBMIT_PATIENT_FAILURE: "SUBMIT_PATIENT_FAILURE",
  CREATE_ORDER_HOMECARE_REQUEST: "CREATE_ORDER_HOMECARE_REQUEST",
  CREATE_ORDER_HOMECARE_SUCCESS: "CREATE_ORDER_HOMECARE_SUCCESS",
  CREATE_ORDER_HOMECARE_FAILURE: "CREATE_ORDER_HOMECARE_FAILURE",
  GET_DOCTOR_REQUEST: "GET_DOCTOR_REQUEST",
  GET_DOCTOR_SUCCESS: "GET_DOCTOR_SUCCESS",
  GET_DOCTOR_FAILURE: "GET_DOCTOR_FAILURE",
  GET_COUNTRY_REQUEST: "GET_COUNTRY_REQUEST",
  GET_COUNTRY_SUCCESS: "GET_COUNTRY_SUCCESS",
  GET_COUNTRY_FAILURE: "GET_COUNTRY_FAILURE",
  GET_STATE_REQUEST: "GET_STATE_REQUEST",
  GET_STATE_SUCCESS: "GET_STATE_SUCCESS",
  GET_STATE_FAILURE: "GET_STATE_FAILURE",
  GET_CITY_REQUEST: "GET_CITY_REQUEST",
  GET_CITY_SUCCESS: "GET_CITY_SUCCESS",
  GET_CITY_FAILURE: "GET_CITY_FAILURE",
  GET_DISTRICT_REQUEST: "GET_DISTRICT_REQUEST",
  GET_DISTRICT_SUCCESS: "GET_DISTRICT_SUCCESS",
  GET_DISTRICT_FAILURE: "GET_DISTRICT_FAILURE",
  GET_SUBDISTRICT_REQUEST: "GET_SUBDISTRICT_REQUEST",
  GET_SUBDISTRICT_SUCCESS: "GET_SUBDISTRICT_SUCCESS",
  GET_SUBDISTRICT_FAILURE: "GET_SUBDISTRICT_FAILURE",
  GET_POSTALCODE_REQUEST: "GET_POSTALCODE_REQUEST",
  GET_POSTALCODE_SUCCESS: "GET_POSTALCODE_SUCCESS",
  GET_POSTALCODE_FAILURE: "GET_POSTALCODE_FAILURE",
  GET_SCHEDULE_DATA_REQUEST: "GET_SCHEDULE_DATA_REQUEST",
  GET_SCHEDULE_DATA_SUCCESS: "GET_SCHEDULE_DATA_SUCCESS",
  GET_SCHEDULE_DATA_FAILURE: "GET_SCHEDULE_DATA_FAILURE",
  GET_SCHEDULE_NONSILOAM_REQUEST: "GET_SCHEDULE_NONSILOAM_REQUEST",
  GET_SCHEDULE_NONSILOAM_SUCCESS: "GET_SCHEDULE_NONSILOAM_SUCCESS",
  GET_SCHEDULE_NONSILOAM_FAILURE: "GET_SCHEDULE_NONSILOAM_FAILURE",
  GET_INSURANCE_REQUEST: "GET_INSURANCE_REQUEST",
  GET_INSURANCE_SUCCESS: "GET_INSURANCE_SUCCESS",
  GET_INSURANCE_FAILURE: "GET_INSURANCE_FAILURE",
  UPLOAD_INSURANCE_REQUEST: "UPLOAD_INSURANCE_REQUEST",
  UPLOAD_INSURANCE_SUCCESS: "UPLOAD_INSURANCE_SUCCESS",
  UPLOAD_INSURANCE_FAILURE: "UPLOAD_INSURANCE_FAILURE",
  UPLOAD_IDENTITY_REQUEST: "UPLOAD_IDENTITY_REQUEST",
  UPLOAD_IDENTITY_SUCCESS: "UPLOAD_IDENTITY_SUCCESS",
  UPLOAD_IDENTITY_FAILURE: "UPLOAD_IDENTITY_FAILURE",
  UPDATE_PATIENT_REQUEST: "UPDATE_PATIENT_REQUEST",
  UPDATE_PATIENT_SUCCESS: "UPDATE_PATIENT_SUCCESS",
  UPDATE_PATIENT_FAILURE: "UPDATE_PATIENT_FAILURE",
  FIND_USERPHONEEMAIL_REQUEST: "FIND_USERPHONEEMAIL_REQUEST",
  FIND_USERPHONEEMAIL_SUCCESS: "FIND_USERPHONEEMAIL_SUCCESS",
  FIND_USERPHONEEMAIL_FAILURE: "FIND_USERPHONEEMAIL_FAILURE",
  SUBMIT_PHARMACY_UNIT_REQUEST: "SUBMIT_PHARMACY_UNIT_REQUEST",
  SUBMIT_PHARMACY_UNIT_SUCCESS: "SUBMIT_PHARMACY_UNIT_SUCCESS",
  SUBMIT_PHARMACY_UNIT_FAILURE: "SUBMIT_PHARMACY_UNIT_FAILURE",
  GET_DELIVERY_METHOD_REQUEST: "GET_DELIVERY_METHOD_REQUEST",
  GET_DELIVERY_METHOD_SUCCESS: "GET_DELIVERY_METHOD_SUCCESS",
  GET_DELIVERY_METHOD_FAILURE: "GET_DELIVERY_METHOD_FAILURE",
  GET_ADDRESSBYORDER_REQUEST: "GET_ADDRESSBYORDER_REQUEST",
  GET_ADDRESSBYORDER_SUCCESS: "GET_ADDRESSBYORDER_SUCCESS",
  GET_ADDRESSBYORDER_FAILURE: "GET_ADDRESSBYORDER_FAILURE",
  GET_GOSENDPRICE_REQUEST: "GET_GOSENDPRICE_REQUEST",
  GET_GOSENDPRICE_SUCCESS: "GET_GOSENDPRICE_SUCCESS",
  GET_GOSENDPRICE_FAILURE: "GET_GOSENDPRICE_FAILURE",
  SUBMIT_ADDRESS_REQUEST: "SUBMIT_ADDRESS_REQUEST",
  SUBMIT_ADDRESS_SUCCESS: "SUBMIT_ADDRESS_SUCCESS",
  SUBMIT_ADDRESS_FAILURE: "SUBMIT_ADDRESS_FAILURE",
  UPDATE_ADDRESS_REQUEST: "UPDATE_ADDRESS_REQUEST",
  UPDATE_ADDRESS_SUCCESS: "UPDATE_ADDRESS_SUCCESS",
  UPDATE_ADDRESS_FAILURE: "UPDATE_ADDRESS_FAILURE",
  GET_DELIVERYPRICE_REQUEST: "GET_DELIVERYPRICE_REQUEST",
  GET_DELIVERYPRICE_SUCCESS: "GET_DELIVERYPRICE_SUCCESS",
  GET_DELIVERYPRICE_FAILURE: "GET_DELIVERYPRICE_FAILURE",
  GET_ONETIMEADDRESS_REQUEST: "GET_ONETIMEADDRESS_REQUEST",
  GET_ONETIMEADDRESS_SUCCESS: "GET_ONETIMEADDRESS_SUCCESS",
  GET_ONETIMEADDRESS_FAILURE: "GET_ONETIMEADDRESS_FAILURE",
  GET_PHARMACY_NAME_REQUEST: "GET_PHARMACY_NAME_REQUEST",
  GET_PHARMACY_NAME_SUCCESS: "GET_PHARMACY_NAME_SUCCESS",
  GET_PHARMACY_NAME_FAILURE: "GET_PHARMACY_NAME_FAILURE",
  GET_HOSPITAL_LIST_REQUEST: "GET_HOSPITAL_LIST_REQUEST",
  GET_HOSPITAL_LIST_SUCCESS: "GET_HOSPITAL_LIST_SUCCESS",
  GET_HOSPITAL_LIST_FAILURE: "GET_HOSPITAL_LIST_FAILURE",
  GET_DETAIL_CLINIC_REQUEST: "GET_DETAIL_CLINIC_REQUEST",
  GET_DETAIL_CLINIC_SUCCESS: "GET_DETAIL_CLINIC_SUCCESS",
  GET_DETAIL_CLINIC_FAILURE: "GET_DETAIL_CLINIC_FAILURE",
  GET_PROMO_VALIDATION_REQUEST: "GET_PROMO_VALIDATION_REQUEST",
  GET_PROMO_VALIDATION_SUCCESS: "GET_PROMO_VALIDATION_SUCCESS",
  GET_PROMO_VALIDATION_FAILURE: "GET_PROMO_VALIDATION_FAILURE",
};

export const orderDailyReportConstants = {
  FIND_ORDER_DAILY_REPORT_REQUEST: "FIND_ORDER_DAILY_REPORT_REQUEST",
  FIND_ORDER_DAILY_REPORT_SUCCESS: "FIND_ORDER_DAILY_REPORT_SUCCESS",
  FIND_ORDER_DAILY_REPORT_FAILURE: "FIND_ORDER_DAILY_REPORT_FAILURE",
};

export const orderByIdConstants = {
  GET_ORDER_BY_ID_REQUEST: "GET_ORDER_BY_ID_REQUEST",
  GET_ORDER_BY_ID_SUCCESS: "GET_ORDER_BY_ID_SUCCESS",
  GET_ORDER_BY_ID_FAILURE: "GET_ORDER_BY_ID_FAILURE",
  UPDATE_ORDER_STATUS_REQUEST: "UPDATE_ORDER_STATUS_REQUEST",
  UPDATE_ORDER_STATUS_SUCCESS: "UPDATE_ORDER_STATUS_SUCCESS",
  UPDATE_ORDER_STATUS_FAILURE: "UPDATE_ORDER_STATUS_FAILURE",
};
export const dailyReportConstants = {
  GET_DAILY_REPORT_REQUEST: "GET_DAILY_REPORT_REQUEST",
  GET_DAILY_REPORT_SUCCESS: "GET_DAILY_REPORT_SUCCESS",
  GET_DAILY_REPORT_FAILURE: "GET_DAILY_REPORT_FAILURE",
};

export const teleRefundConstants = {
  GET_ALL_TELEREFUND_REQUEST: "GET_ALL_TELEREFUND_REQUEST",
  GET_ALL_TELEREFUND_SUCCESS: "GET_ALL_TELEREFUND_SUCCESS",
  GET_ALL_TELEREFUND_FAILURE: "GET_ALL_TELEREFUND_FAILURE",
  DO_REFUND_REQUEST: "DO_REFUND_REQUEST",
  DO_REFUND_SUCCESS: "DO_REFUND_SUCCESS",
  DO_REFUND_FAILURE: "DO_REFUND_FAILURE",
};

export const teleRescheduleConstants = {
  GET_TELE_RESCHEDULE_REQUEST: "GET_TELE_RESCHEDULE_REQUEST",
  GET_TELE_RESCHEDULE_SUCCESS: "GET_TELE_RESCHEDULE_SUCCESS",
  GET_TELE_RESCHEDULE_FAILURE: "GET_TELE_RESCHEDULE_FAILURE",
  GET_TELE_DETAIL_REQUEST: "GET_TELE_DETAIL_REQUEST",
  GET_TELE_DETAIL_SUCCESS: "GET_TELE_DETAIL_SUCCESS",
  GET_TELE_DETAIL_FAILURE: "GET_TELE_DETAIL_FAILURE",
  GET_SCHEDULE_DATA_REQUEST: "GET_SCHEDULE_DATA_REQUEST",
  GET_SCHEDULE_DATA_SUCCESS: "GET_SCHEDULE_DATA_SUCCESS",
  GET_SCHEDULE_DATA_FAILURE: "GET_SCHEDULE_DATA_FAILURE",
  GET_SCHEDULE_NONSILOAM_REQUEST: "GET_SCHEDULE_NONSILOAM_REQUEST",
  GET_SCHEDULE_NONSILOAM_SUCCESS: "GET_SCHEDULE_NONSILOAM_SUCCESS",
  GET_SCHEDULE_NONSILOAM_FAILURE: "GET_SCHEDULE_NONSILOAM_FAILURE",
  RESCHEDULE_TELE_REQUEST: "RESCHEDULE_TELE_REQUEST",
  RESCHEDULE_TELE_SUCCESS: "RESCHEDULE_TELE_SUCCESS",
  RESCHEDULE_TELE_FAILURE: "RESCHEDULE_TELE_FAILURE",
  CANCEL_ORDER_TELE_REQUEST: "CANCEL_ORDER_TELE_REQUEST",
  CANCEL_ORDER_TELE_SUCCESS: "CANCEL_ORDER_TELE_SUCCESS",
  CANCEL_ORDER_TELE_FAILURE: "CANCEL_ORDER_TELE_FAILURE",
  APPROVECLAIM_TELE_REQUEST: "APPROVECLAIM_TELE_REQUEST",
  APPROVECLAIM_TELE_SUCCESS: "APPROVECLAIM_TELE_SUCCESS",
  APPROVECLAIM_TELE_FAILURE: "APPROVECLAIM_TELE_FAILURE",
  REJECTCLAIM_TELE_REQUEST: "REJECTCLAIM_TELE_REQUEST",
  REJECTCLAIM_TELE_SUCCESS: "REJECTCLAIM_TELE_SUCCESS",
  REJECTCLAIM_TELE_FAILURE: "REJECTCLAIM_TELE_FAILURE",
};

export const waitingForTeleConstants = {
  GET_COMPLETED_CALL_TELE_REQUEST: "GET_COMPLETED_CALL_TELE_REQUEST",
  GET_COMPLETED_CALL_TELE_SUCCESS: "GET_COMPLETED_CALL_TELE_SUCCESS",
  GET_COMPLETED_CALL_TELE_FAILURE: "GET_COMPLETED_CALL_TELE_FAILURE",
  COMPLETED_CALL_TELE_DETAIL_REQUEST: "COMPLETED_CALL_TELE_DETAIL_REQUEST",
  COMPLETED_CALL_TELE_DETAIL_SUCCESS: "COMPLETED_CALL_TELE_DETAIL_SUCCESS",
  COMPLETED_CALL_TELE_DETAIL_FAILURE: "COMPLETED_CALL_TELE_DETAIL_FAILURE",
  UPDATE_ORDER_TELE_STATUS_REQUEST: "UPDATE_ORDER_TELE_STATUS_REQUEST",
  UPDATE_ORDER_TELE_STATUS_SUCCESS: "UPDATE_ORDER_TELE_STATUS_SUCCESS",
  UPDATE_ORDER_TELE_STATUS_FAILURE: "UPDATE_ORDER_TELE_STATUS_FAILURE",
  UPLOAD_ORDER_TELE_DOCUMENT_REQUEST: "UPLOAD_ORDER_TELE_DOCUMENT_REQUEST",
  UPLOAD_ORDER_TELE_DOCUMENT_SUCCESS: "UPLOAD_ORDER_TELE_DOCUMENT_SUCCESS",
  UPLOAD_ORDER_TELE_DOCUMENT_FAILURE: "UPLOAD_ORDER_TELE_DOCUMENT_FAILURE",
  SEND_EMAIL_TELE_DOCUMENT_REQUEST: "SEND_EMAIL_TELE_DOCUMENT_REQUEST",
  SEND_EMAIL_TELE_DOCUMENT_SUCCESS: "SEND_EMAIL_TELE_DOCUMENT_SUCCESS",
  SEND_EMAIL_TELE_DOCUMENT_FAILURE: "SEND_EMAIL_TELE_DOCUMENT_FAILURE",
};

export const paymentReportConstants = {
  GET_PAYMENT_REPORT_REQUEST: "GET_PAYMENT_REPORT_REQUEST",
  GET_PAYMENT_REPORT_SUCCESS: "GET_PAYMENT_REPORT_SUCCESS",
  GET_PAYMENT_REPORT_FAILURE: "GET_PAYMENT_REPORT_FAILURE",
  PAYMENT_REPORT_DETAIL_REQUEST: "PAYMENT_REPORT_DETAIL_REQUEST",
  PAYMENT_REPORT_DETAIL_SUCCESS: "PAYMENT_REPORT_DETAIL_SUCCESS",
  PAYMENT_REPORT_DETAIL_FAILURE: "PAYMENT_REPORT_DETAIL_FAILURE",
};

export const monthlyReportConstants = {
  GET_MONTHLY_REPORT_REQUEST: "GET_MONTHLY_REPORT_REQUEST",
  GET_MONTHLY_REPORT_SUCCESS: "GET_MONTHLY_REPORT_SUCCESS",
  GET_MONTHLY_REPORT_FAILURE: "GET_MONTHLY_REPORT_FAILURE",
};

export const myOrderConstants = {
  GET_ALL_ORDER_REQUEST: "GET_ALL_ORDER_REQUEST",
  GET_ALL_ORDER_SUCCESS: "GET_ALL_ORDER_SUCCESS",
  GET_ALL_ORDER_FAILURE: "GET_ALL_ORDER_FAILURE",
  GET_ORDER_DETAIL_REQUEST: "GET_ORDER_DETAIL_REQUEST",
  GET_ORDER_DETAIL_SUCCESS: "GET_ORDER_DETAIL_SUCCESS",
  GET_ORDER_DETAIL_FAILURE: "GET_ORDER_DETAIL_FAILURE",
  GET_DOCTOR_NAMES_BY_PROFESSION_REQUEST:
    "GET_DOCTOR_NAMES_BY_PROFESSION_REQUEST",
  GET_DOCTOR_NAMES_BY_PROFESSION_SUCCESS:
    "GET_DOCTOR_NAMES_BY_PROFESSION_SUCCESS",
  GET_DOCTOR_NAMES_BY_PROFESSION_FAILURE:
    "GET_DOCTOR_NAMES_BY_PROFESSION_FAILURE",
  UPDATE_ORDER_DOCTOR_NAME_REQUEST: "UPDATE_ORDER_DOCTOR_NAME_REQUEST",
  UPDATE_ORDER_DOCTOR_NAME_SUCCESS: "UPDATE_ORDER_DOCTOR_NAME_SUCCESS",
  UPDATE_ORDER_DOCTOR_NAME_FAILURE: "UPDATE_ORDER_DOCTOR_NAME_FAILURE",
};

export const myTokenConstants = {
  GET_TOKEN_REQUEST: "GET_TOKEN_REQUEST",
  GET_TOKEN_SUCCESS: "GET_TOKEN_SUCCESS",
  GET_TOKEN_FAILURE: "GET_TOKEN_FAILURE",
  UPDATE_TOKEN_REQUEST: "UPDATE_TOKEN_REQUEST",
  UPDATE_TOKEN_SUCCESS: "UPDATE_TOKEN_SUCCESS",
  UPDATE_TOKEN_FAILURE: "UPDATE_TOKEN_FAILURE",
};

export const orderTeleConstants = {
  GET_ALL_ORDER_TELE_REQUEST: "GET_ALL_ORDER_TELE_REQUEST",
  GET_ALL_ORDER_TELE_SUCCESS: "GET_ALL_ORDER_TELE_SUCCESS",
  GET_ALL_ORDER_TELE_FAILURE: "GET_ALL_ORDER_TELE_FAILURE",
  ORDER_TELE_DETAIL_REQUEST: "ORDER_TELE_DETAIL_REQUEST",
  ORDER_TELE_DETAIL_SUCCESS: "ORDER_TELE_DETAIL_SUCCESS",
  ORDER_TELE_DETAIL_FAILURE: "ORDER_TELE_DETAIL_FAILURE",
  GET_TELE_STATUS_REQUEST: "GET_TELE_STATUS_REQUEST",
  GET_TELE_STATUS_SUCCESS: "GET_TELE_STATUS_SUCCESS",
  GET_TELE_STATUS_FAILURE: "GET_TELE_STATUS_FAILURE",
  CHANGE_ORDER_TELE_STATUS_REQUEST: "CHANGE_ORDER_TELE_STATUS_REQUEST",
  CHANGE_ORDER_TELE_STATUS_SUCCESS: "CHANGE_ORDER_TELE_STATUS_SUCCESS",
  CHANGE_ORDER_TELE_STATUS_FAILURE: "CHANGE_ORDER_TELE_STATUS_FAILURE",
};

export const orderRequestConstants = {
  GET_ORDER_REQUEST_REQUEST: "GET_ORDER_REQUEST_REQUEST",
  GET_ORDER_REQUEST_SUCCESS: "GET_ORDER_REQUEST_SUCCESS",
  GET_ORDER_REQUEST_FAILURE: "GET_ORDER_REQUEST_FAILURE",
  GET_PARTNERS_REQUEST: "GET_PARTNERS_REQUEST",
  GET_PARTNERS_SUCCESS: "GET_PARTNERS_SUCCESS",
  GET_PARTNERS_FAILURE: "GET_PARTNERS_FAILURE",
  ORDER_REQUEST_DETAIL_REQUEST: "ORDER_REQUEST_DETAIL_REQUEST",
  ORDER_REQUEST_DETAIL_SUCCESS: "ORDER_REQUEST_DETAIL_SUCCESS",
  ORDER_REQUEST_DETAIL_FAILURE: "ORDER_REQUEST_DETAIL_FAILURE",
  CREATE_ORDER_REQUEST_REQUEST: "CREATE_ORDER_REQUEST_REQUEST",
  CREATE_ORDER_REQUEST_SUCCESS: "CREATE_ORDER_REQUEST_SUCCESS",
  CREATE_ORDER_REQUEST_FAILURE: "CREATE_ORDER_REQUEST_FAILURE",
};

export const userConstants = {
  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
};

export const orderVaccineConstants = {
  GET_ALL_ORDER_VACCINE_REQUEST: "GET_ALL_ORDER_VACCINE_REQUEST",
  GET_ALL_ORDER_VACCINE_SUCCESS: "GET_ALL_ORDER_VACCINE_SUCCESS",
  GET_ALL_ORDER_VACCINE_FAILURE: "GET_ALL_ORDER_VACCINE_FAILURE",
  ORDER_VACCINE_DETAIL_REQUEST: "ORDER_VACCINE_DETAIL_REQUEST",
  ORDER_VACCINE_DETAIL_SUCCESS: "ORDER_VACCINE_DETAIL_SUCCESS",
  ORDER_VACCINE_DETAIL_FAILURE: "ORDER_VACCINE_DETAIL_FAILURE",
  GET_SKRINING_DETAIL_REQUEST: "GET_SKRINING_DETAIL_REQUEST",
  GET_SKRINING_DETAIL_SUCCESS: "GET_SKRINING_DETAIL_SUCCESS",
  GET_SKRINING_DETAIL_FAILURE: "GET_SKRINING_DETAIL_FAILURE",
  ORDER_VACCINE_RESULT_REQUEST: "ORDER_VACCINE_RESULT_REQUEST",
  ORDER_VACCINE_RESULT_SUCCESS: "ORDER_VACCINE_RESULT_SUCCESS",
  ORDER_VACCINE_RESULT_FAILURE: "ORDER_VACCINE_RESULT_FAILURE",
  UPDATE_ORDER_VACCINE_REQUEST: "UPDATE_ORDER_VACCINE_REQUEST",
  UPDATE_ORDER_VACCINE_SUCCESS: "UPDATE_ORDER_VACCINE_SUCCESS",
  UPDATE_ORDER_VACCINE_FAILURE: "UPDATE_ORDER_VACCINE_FAILURE",
  UPDATE_VACCINE_NOTE_REQUEST: "UPDATE_VACCINE_NOTE_REQUEST",
  UPDATE_VACCINE_NOTE_SUCCESS: "UPDATE_VACCINE_NOTE_SUCCESS",
  UPDATE_VACCINE_NOTE_FAILURE: "UPDATE_VACCINE_NOTE_FAILURE",
  GET_QUESTION_REQUEST: "GET_QUESTION_REQUEST",
  GET_QUESTION_SUCCESS: "GET_QUESTION_SUCCESS",
  GET_QUESTION_FAILURE: "GET_QUESTION_FAILURE",
  GET_ORDER_FORM_REQUEST: "GET_ORDER_FORM_REQUEST",
  GET_ORDER_FORM_SUCCESS: "GET_ORDER_FORM_SUCCESS",
  GET_ORDER_FORM_FAILURE: "GET_ORDER_FORM_FAILURE",
  UPDATE_SKRINING_REQUEST: "UPDATE_SKRINING_REQUEST",
  UPDATE_SKRINING_SUCCESS: "UPDATE_SKRINING_SUCCESS",
  UPDATE_SKRINING_FAILURE: "UPDATE_SKRINING_FAILURE",
  GET_VACCINE_DAILY_REPORT_REQUEST: "GET_VACCINE_DAILY_REPORT_REQUEST",
  GET_VACCINE_DAILY_REPORT_SUCCESS: "GET_VACCINE_DAILY_REPORT_SUCCESS",
  GET_VACCINE_DAILY_REPORT_FAILURE: "GET_VACCINE_DAILY_REPORT_FAILURE",
};

export const myVaccinationEventConstants = {
  GET_ALL_EVENTS_REQUEST: "GET_ALL_EVENTS_REQUEST",
  GET_ALL_EVENTS_SUCCESS: "GET_ALL_EVENTS_SUCCESS",
  GET_ALL_EVENTS_FAILURE: "GET_ALL_EVENTS_FAILURE",
  VACCINATION_EVENT_DETAIL_REQUEST: "VACCINATION_EVENT_DETAIL_REQUEST",
  VACCINATION_EVENT_DETAIL_SUCCESS: "VACCINATION_EVENT_DETAIL_SUCCESS",
  VACCINATION_EVENT_DETAIL_FAILURE: "VACCINATION_EVENT_DETAIL_FAILURE",
  CREATE_VACCINATION_EVENT_REQUEST: "CREATE_VACCINATION_EVENT_REQUEST",
  CREATE_VACCINATION_EVENT_SUCCESS: "CREATE_VACCINATION_EVENT_SUCCESS",
  CREATE_VACCINATION_EVENT_FAILURE: "CREATE_VACCINATION_EVENT_FAILURE",
  DELETE_VACCINATION_EVENT_REQUEST: "DELETE_VACCINATION_EVENT_REQUEST",
  DELETE_VACCINATION_EVENT_SUCCESS: "DELETE_VACCINATION_EVENT_SUCCESS",
  DELETE_VACCINATION_EVENT_FAILURE: "DELETE_VACCINATION_EVENT_FAILURE",
};

export const scheduleVaccineConstants = {
  GET_ALL_SCHEDULE_VACCINE_REQUEST: "GET_ALL_SCHEDULE_VACCINE_REQUEST",
  GET_ALL_SCHEDULE_VACCINE_SUCCESS: "GET_ALL_SCHEDULE_VACCINE_SUCCESS",
  GET_ALL_SCHEDULE_VACCINE_FAILURE: "GET_ALL_SCHEDULE_VACCINE_FAILURE",
  IMPORT_SCHEDULE_VACCINE_REQUEST: "IMPORT_SCHEDULE_VACCINE_REQUEST",
  IMPORT_SCHEDULE_VACCINE_SUCCESS: "IMPORT_SCHEDULE_VACCINE_SUCCESS",
  IMPORT_SCHEDULE_VACCINE_FAILURE: "IMPORT_SCHEDULE_VACCINE_FAILURE",
  CLEAR_IMPORT_SCHEDULE_REQUEST: "CLEAR_IMPORT_SCHEDULE_REQUEST",
  CLEAR_IMPORT_SCHEDULE_SUCCESS: "CLEAR_IMPORT_SCHEDULE_SUCCESS",
  CLEAR_IMPORT_SCHEDULE_FAILURE: "CLEAR_IMPORT_SCHEDULE_SUCCESS",
  DELETE_SCHEDULE_VACCINE_REQUEST: "DELETE_SCHEDULE_VACCINE_REQUEST",
  DELETE_SCHEDULE_VACCINE_SUCCESS: "DELETE_SCHEDULE_VACCINE_SUCCESS",
  DELETE_SCHEDULE_VACCINE_FAILURE: "DELETE_SCHEDULE_VACCINE_FAILURE",
  MULTIPLE_DELETE_SCHEDULE_VACCINE_REQUEST:
    "MULTIPLE_DELETE_SCHEDULE_VACCINE_REQUEST",
  MULTIPLE_DELETE_SCHEDULE_VACCINE_SUCCESS:
    "MULTIPLE_DELETE_SCHEDULE_VACCINE_SUCCESS",
  MULTIPLE_DELETE_SCHEDULE_VACCINE_FAILURE:
    "MULTIPLE_DELETE_SCHEDULE_VACCINE_FAILURE",
};

export const myMedicalConstants = {
  GET_ALL_MEDICAL_REQUEST: "GET_ALL_MEDICAL_REQUEST",
  GET_ALL_MEDICAL_SUCCESS: "GET_ALL_MEDICAL_SUCCESS",
  GET_ALL_MEDICAL_FAILURE: "GET_ALL_MEDICAL_FAILURE",
  GET_MEDICAL_BY_ID_REQUEST: "GET_MEDICAL_BY_ID_REQUEST",
  GET_MEDICAL_BY_ID_SUCCESS: "GET_MEDICAL_BY_ID_SUCCESS",
  GET_MEDICAL_BY_ID_FAILURE: "GET_MEDICAL_BY_ID_FAILURE",
  ADD_NEW_MEDICAL_REQUEST: "ADD_NEW_MEDICAL_REQUEST",
  ADD_NEW_MEDICAL_SUCCESS: "ADD_NEW_MEDICAL_SUCCESS",
  ADD_NEW_MEDICAL_FAILURE: "ADD_NEW_MEDICAL_FAILURE",
  UPDATE_MEDICAL_REQUEST: "UPDATE_MEDICAL_REQUEST",
  UPDATE_MEDICAL_SUCCESS: "UPDATE_MEDICAL_SUCCESS",
  UPDATE_MEDICAL_FAILURE: "UPDATE_MEDICAL_FAILURE",
  DELETE_MEDICAL_REQUEST: "DELETE_MEDICAL_REQUEST",
  DELETE_MEDICAL_SUCCESS: "DELETE_MEDICAL_SUCCESS",
  DELETE_MEDICAL_FAILURE: "DELETE_MEDICAL_FAILURE",
};

export const myProceduresConstants = {
  GET_ALL_PROCEDURES_REQUEST: "GET_ALL_PROCEDURES_REQUEST",
  GET_ALL_PROCEDURES_SUCCESS: "GET_ALL_PROCEDURES_SUCCESS",
  GET_ALL_PROCEDURES_FAILURE: "GET_ALL_PROCEDURES_FAILURE",
  GET_PROCEDURES_BY_ID_REQUEST: "GET_PROCEDURES_BY_ID_REQUEST",
  GET_PROCEDURES_BY_ID_SUCCESS: "GET_PROCEDURES_BY_ID_SUCCESS",
  GET_PROCEDURES_BY_ID_FAILURE: "GET_PROCEDURES_BY_ID_FAILURE",
  ADD_NEW_PROCEDURES_REQUEST: "ADD_NEW_PROCEDURES_REQUEST",
  ADD_NEW_PROCEDURES_SUCCESS: "ADD_NEW_PROCEDURES_SUCCESS",
  ADD_NEW_PROCEDURES_FAILURE: "ADD_NEW_PROCEDURES_FAILURE",
  UPDATE_PROCEDURES_REQUEST: "UPDATE_PROCEDURES_REQUEST",
  UPDATE_PROCEDURES_SUCCESS: "UPDATE_PROCEDURES_SUCCESS",
  UPDATE_PROCEDURES_FAILURE: "UPDATE_PROCEDURES_FAILURE",
  DELETE_PROCEDURES_REQUEST: "DELETE_PROCEDURES_REQUEST",
  DELETE_PROCEDURES_SUCCESS: "DELETE_PROCEDURES_SUCCESS",
  DELETE_PROCEDURES_FAILURE: "DELETE_PROCEDURES_FAILURE",
};

export const dokterWorklistConstants = {
  GET_ALL_DOKTERWORKLIST_REQUEST: "GET_ALL_DOKTERWORKLIST_REQUEST",
  GET_ALL_DOKTERWORKLIST_SUCCESS: "GET_ALL_DOKTERWORKLIST_SUCCESS",
  GET_ALL_DOKTERWORKLIST_FAILURE: "GET_ALL_DOKTERWORKLIST_FAILURE",
  GENERATE_PRESCRIPTION_REQUEST: "GENERATE_PRESCRIPTION_REQUEST",
  GENERATE_PRESCRIPTION_SUCCESS: "GENERATE_PRESCRIPTION_SUCCESS",
  GENERATE_PRESCRIPTION_FAILURE: "GENERATE_PRESCRIPTION_FAILURE",
  SEND_EMAIL_TELE_DOCUMENT_REQUEST: "SEND_EMAIL_TELE_DOCUMENT_REQUEST",
  SEND_EMAIL_TELE_DOCUMENT_SUCCESS: "SEND_EMAIL_TELE_DOCUMENT_SUCCESS",
  SEND_EMAIL_TELE_DOCUMENT_FAILURE: "SEND_EMAIL_TELE_DOCUMENT_FAILURE",
  CREATE_MEDICAL_RECORD_REQUEST: "CREATE_MEDICAL_RECORD_REQUEST",
  CREATE_MEDICAL_RECORD_SUCCESS: "CREATE_MEDICAL_RECORD_SUCCESS",
  CREATE_MEDICAL_RECORD_FAILURE: "CREATE_MEDICAL_RECORD_FAILURE",
  UPLOAD_CONSENT_REQUEST: "UPLOAD_CONSENT_REQUEST",
  UPLOAD_CONSENT_SUCCESS: "UPLOAD_CONSENT_SUCCESS",
  UPLOAD_CONSENT_FAILURE: "UPLOAD_CONSENT_FAILURE",
  UPLOAD_PHARMACY_REQUEST: "UPLOAD_PHARMACY_REQUEST",
  UPLOAD_PHARMACY_SUCCESS: "UPLOAD_PHARMACY_SUCCESS",
  UPLOAD_PHARMACY_FAILURE: "UPLOAD_PHARMACY_FAILURE",
  FREETELE_SUMMARY_REQUEST: "FREETELE_SUMMARY_REQUEST",
  FREETELE_SUMMARY_SUCCESS: "FREETELE_SUMMARY_SUCCESS",
  FREETELE_SUMMARY_FAILURE: "FREETELE_SUMMARY_FAILURE",
  SEND_REMINDER_FREETELE_REQUEST: "SEND_REMINDER_FREETELE_REQUEST",
  SEND_REMINDER_FREETELE_SUCCESS: "SEND_REMINDER_FREETELE_SUCCESS",
  SEND_REMINDER_FREETELE_FAILURE: "SEND_REMINDER_FREETELE_FAILURE",
  GET_ICD10_REQUEST: "GET_ICD10_REQUEST",
  GET_ICD10_SUCCESS: "GET_ICD10_SUCCESS",
  GET_ICD10_FAILURE: "GET_ICD10_FAILURE",
  GET_TEST_LAB_REQUEST: "GET_TEST_LAB_REQUEST",
  GET_TEST_LAB_SUCCESS: "GET_TEST_LAB_SUCCESS",
  GET_TEST_LAB_FAILURE: "GET_TEST_LAB_FAILURE",
  GET_MEDICAL_DETAIL_REQUEST: "GET_MEDICAL_DETAIL_REQUEST",
  GET_MEDICAL_DETAIL_SUCCESS: "GET_MEDICAL_DETAIL_SUCCESS",
  GET_MEDICAL_DETAIL_FAILURE: "GET_MEDICAL_DETAIL_FAILURE",
  NOTIFY_EMERGENCY_REQUEST: "NOTIFY_EMERGENCY_REQUEST",
  NOTIFY_EMERGENCY_SUCCESS: "NOTIFY_EMERGENCY_SUCCESS",
  NOTIFY_EMERGENCY_FAILURE: "NOTIFY_EMERGENCY_FAILURE",
  GET_ALL_MEDICALHISTORY_REQUEST: "GET_ALL_MEDICALHISTORY_REQUEST",
  GET_ALL_MEDICALHISTORY_SUCCESS: "GET_ALL_MEDICALHISTORY_SUCCESS",
  GET_ALL_MEDICALHISTORY_FAILURE: "GET_ALL_MEDICALHISTORY_FAILURE",
  REPORT_NOPATIENT_REQUEST: "REPORT_NOPATIENT_REQUEST",
  REPORT_NOPATIENT_SUCCESS: "REPORT_NOPATIENT_SUCCESS",
  REPORT_NOPATIENT_FAILURE: "REPORT_NOPATIENT_FAILURE",
  GET_MEDICINES_REQUEST: "GET_MEDICINES_REQUEST",
  GET_MEDICINES_SUCCESS: "GET_MEDICINES_SUCCESS",
  GET_MEDICINES_FAILURE: "GET_MEDICINES_FAILURE",
  GET_BMI_REQUEST: "GET_BMI_REQUEST",
  GET_BMI_SUCCESS: "GET_BMI_SUCCESS",
  GET_BMI_FAILURE: "GET_BMI_FAILURE",
  GET_EVENTHS_REQUEST: "GET_EVENTHS_REQUEST",
  GET_EVENTHS_SUCCESS: "GET_EVENTHS_SUCCESS",
  GET_EVENTHS_FAILURE: "GET_EVENTHS_FAILURE",
};

export const notifConstants = {
  GET_ALL_NEWORDER_REQUEST: "GET_ALL_NEWORDER_REQUEST",
  GET_ALL_NEWORDER_SUCCESS: "GET_ALL_NEWORDER_SUCCESS",
  GET_ALL_NEWORDER_FAILURE: "GET_ALL_NEWORDER_FAILURE",
  GET_ALL_INVOICEBILLING_REQUEST: "GET_ALL_INVOICEBILLING_REQUEST",
  GET_ALL_INVOICEBILLING_SUCCESS: "GET_ALL_INVOICEBILLING_SUCCESS",
  GET_ALL_INVOICEBILLING_FAILURE: "GET_ALL_INVOICEBILLING_FAILURE",
  GET_ALL_PHARMACYPAID_REQUEST: "GET_ALL_PHARMACYPAID_REQUEST",
  GET_ALL_PHARMACYPAID_SUCCESS: "GET_ALL_PHARMACYPAID_SUCCESS",
  GET_ALL_PHARMACYPAID_FAILURE: "GET_ALL_PHARMACYPAID_FAILURE",
  GET_ALL_TELEWAITINGPHARMACY_REQUEST: "GET_ALL_TELEWAITINGPHARMACY_REQUEST",
  GET_ALL_TELEWAITINGPHARMACY_SUCCESS: "GET_ALL_TELEWAITINGPHARMACY_SUCCESS",
  GET_ALL_TELEWAITINGPHARMACY_FAILURE: "GET_ALL_TELEWAITINGPHARMACY_FAILURE",
  GET_ALL_SUCCESSPAYMENT_REQUEST: "GET_ALL_SUCCESSPAYMENT_REQUEST",
  GET_ALL_SUCCESSPAYMENT_SUCCESS: "GET_ALL_SUCCESSPAYMENT_SUCCESS",
  GET_ALL_SUCCESSPAYMENT_FAILURE: "GET_ALL_SUCCESSPAYMENT_FAILURE",
};

export const orderHistoryConstants = {
  GET_AKTIF_ORDERHISTORY_REQUEST: "GET_AKTIF_ORDERHISTORY_REQUEST",
  GET_AKTIF_ORDERHISTORY_SUCCESS: "GET_AKTIF_ORDERHISTORY_SUCCESS",
  GET_AKTIF_ORDERHISTORY_FAILURE: "GET_AKTIF_ORDERHISTORY_FAILURE",
  GET_DONE_ORDERHISTORY_REQUEST: "GET_DONE_ORDERHISTORY_REQUEST",
  GET_DONE_ORDERHISTORY_SUCCESS: "GET_DONE_ORDERHISTORY_SUCCESS",
  GET_DONE_ORDERHISTORY_FAILURE: "GET_DONE_ORDERHISTORY_FAILURE",
  GET_DETAIL_ORDERHISTORY_REQUEST: "GET_DETAIL_ORDERHISTORY_REQUEST",
  GET_DETAIL_ORDERHISTORY_SUCCESS: "GET_DETAIL_ORDERHISTORY_SUCCESS",
  GET_DETAIL_ORDERHISTORY_FAILURE: "GET_DETAIL_ORDERHISTORY_FAILURE",
  GET_LIST_RIWAYAT_REQUEST: "GET_LIST_RIWAYAT_REQUEST",
  GET_LIST_RIWAYAT_SUCCESS: "GET_LIST_RIWAYAT_SUCCESS",
  GET_LIST_RIWAYAT_FAILURE: "GET_LIST_RIWAYAT_FAILURE",
  GET_DETAIL_RIWAYAT_REQUEST: "GET_DETAIL_RIWAYAT_REQUEST",
  GET_DETAIL_RIWAYAT_SUCCESS: "GET_DETAIL_RIWAYAT_SUCCESS",
  GET_DETAIL_RIWAYAT_FAILURE: "GET_DETAIL_RIWAYAT_FAILURE",
  GET_ADDITIONAL_SERVICE_REQUEST: "GET_ADDITIONAL_SERVICE_REQUEST",
  GET_ADDITIONAL_SERVICE_SUCCESS: "GET_ADDITIONAL_SERVICE_SUCCESS",
  GET_ADDITIONAL_SERVICE_FAILURE: "GET_ADDITIONAL_SERVICE_FAILURE",
  CREATE_ORDER_PRODUCT_REQUEST: "CREATE_ORDER_PRODUCT_REQUEST",
  CREATE_ORDER_PRODUCT_SUCCESS: "CREATE_ORDER_PRODUCT_SUCCESS",
  CREATE_ORDER_PRODUCT_FAILURE: "CREATE_ORDER_PRODUCT_FAILURE",
  UPDATE_ORDER_PRODUCT_REQUEST: "UPDATE_ORDER_PRODUCT_REQUEST",
  UPDATE_ORDER_PRODUCT_SUCCESS: "UPDATE_ORDER_PRODUCT_SUCCESS",
  UPDATE_ORDER_PRODUCT_FAILURE: "UPDATE_ORDER_PRODUCT_FAILURE",
  CREATE_MEDICAL_RECORD_REQUEST: "CREATE_MEDICAL_RECORD_REQUEST",
  CREATE_MEDICAL_RECORD_SUCCESS: "CREATE_MEDICAL_RECORD_SUCCESS",
  CREATE_MEDICAL_RECORD_FAILURE: "CREATE_MEDICAL_RECORD_FAILURE",
  UPDATE_STATUS_ORDER_REQUEST: "UPDATE_STATUS_ORDER_REQUEST",
  UPDATE_STATUS_ORDER_SUCCESS: "UPDATE_STATUS_ORDER_SUCCESS",
  UPDATE_STATUS_ORDER_FAILURE: "UPDATE_STATUS_ORDER_FAILURE",
  UPLOAD_CONSENT_REQUEST: "UPLOAD_CONSENT_REQUEST",
  UPLOAD_CONSENT_SUCCESS: "UPLOAD_CONSENT_SUCCESS",
  UPLOAD_CONSENT_FAILURE: "UPLOAD_CONSENT_FAILURE",
  UPLOAD_PHARMACY_REQUEST: "UPLOAD_PHARMACY_ORDER_REQUEST",
  UPLOAD_PHARMACY_SUCCESS: "UPLOAD_PHARMACY_ORDER_SUCCESS",
  UPLOAD_PHARMACY_FAILURE: "UPLOAD_PHARMACY_ORDER_FAILURE",
  UPLOAD_GALLERY_REQUEST: "UPLOAD_GALLERY_ORDER_REQUEST",
  UPLOAD_GALLERY_SUCCESS: "UPLOAD_GALLERY_ORDER_SUCCESS",
  UPLOAD_GALLERY_FAILURE: "UPLOAD_GALLERY_ORDER_FAILURE",
};

export const orderPharmacyConstants = {
  GET_ALL_ORDERPHARMACY_REQUEST: "GET_ALL_ORDERPHARMACY_REQUEST",
  GET_ALL_ORDERPHARMACY_SUCCESS: "GET_ALL_ORDERPHARMACY_SUCCESS",
  GET_ALL_ORDERPHARMACY_FAILURE: "GET_ALL_ORDERPHARMACY_FAILURE",
  GET_LIST_ORDERPHARMACY_REQUEST: "GET_LIST_ORDERPHARMACY_REQUEST",
  GET_LIST_ORDERPHARMACY_SUCCESS: "GET_LIST_ORDERPHARMACY_SUCCESS",
  GET_LIST_ORDERPHARMACY_FAILURE: "GET_LIST_ORDERPHARMACY_FAILURE",
  GET_ORDERPHARMACY_DETAIL_REQUEST: "GET_ORDERPHARMACY_DETAIL_REQUEST",
  GET_ORDERPHARMACY_DETAIL_SUCCESS: "GET_ORDERPHARMACY_DETAIL_SUCCESS",
  GET_ORDERPHARMACY_DETAIL_FAILURE: "GET_ORDERPHARMACY_DETAIL_FAILURE",
  UPDATE_ORDERPHARMACY_STATUS_REQUEST: "UPDATE_ORDERPHARMACY_STATUS_REQUEST",
  UPDATE_ORDERPHARMACY_STATUS_SUCCESS: "UPDATE_ORDERPHARMACY_STATUS_SUCCESS",
  UPDATE_ORDERPHARMACY_STATUS_FAILURE: "UPDATE_ORDERPHARMACY_STATUS_FAILURE",
  GET_COMPLETE_ORDERPHARMACY_REQUEST: "GET_COMPLETE_ORDERPHARMACY_REQUEST",
  GET_COMPLETE_ORDERPHARMACY_SUCCESS: "GET_COMPLETE_ORDERPHARMACY_SUCCESS",
  GET_COMPLETE_ORDERPHARMACY_FAILURE: "GET_COMPLETE_ORDERPHARMACY_FAILURE",
};

export const paymentOfflineConstants = {
  GET_REGISTERPATIENT_LIST_REQUEST: "GET_REGISTERPATIENT_LIST_REQUEST",
  GET_REGISTERPATIENT_LIST_SUCCESS: "GET_REGISTERPATIENT_LIST_SUCCESS",
  GET_REGISTERPATIENT_LIST_FAILURE: "GET_REGISTERPATIENT_LIST_FAILURE",
  FIND_PATIENT_DETAIL_REQUEST: "FIND_PATIENT_DETAIL_REQUEST",
  FIND_PATIENT_DETAIL_SUCCESS: "FIND_PATIENT_DETAIL_SUCCESS",
  FIND_PATIENT_DETAIL_FAILURE: "FIND_PATIENT_DETAIL_FAILURE",
  GET_ADDRESS_REQUEST: "GET_ADDRESS_REQUEST",
  GET_ADDRESS_SUCCESS: "GET_ADDRESS_SUCCESS",
  GET_ADDRESS_FAILURE: "GET_ADDRESS_FAILURE",
  SUBMIT_PAYMENT_REQUEST: "SUBMIT_PAYMENT_REQUEST",
  SUBMIT_PAYMENT_SUCCESS: "SUBMIT_PAYMENT_SUCCESS",
  SUBMIT_PAYMENT_FAILURE: "SUBMIT_PAYMENT_FAILURE",
  GET_COUNTRY_REQUEST: "GET_COUNTRY_REQUEST",
  GET_COUNTRY_SUCCESS: "GET_COUNTRY_SUCCESS",
  GET_COUNTRY_FAILURE: "GET_COUNTRY_FAILURE",
  GET_STATE_REQUEST: "GET_STATE_REQUEST",
  GET_STATE_SUCCESS: "GET_STATE_SUCCESS",
  GET_STATE_FAILURE: "GET_STATE_FAILURE",
  GET_CITY_REQUEST: "GET_CITY_REQUEST",
  GET_CITY_SUCCESS: "GET_CITY_SUCCESS",
  GET_CITY_FAILURE: "GET_CITY_FAILURE",
  GET_DISTRICT_REQUEST: "GET_DISTRICT_REQUEST",
  GET_DISTRICT_SUCCESS: "GET_DISTRICT_SUCCESS",
  GET_DISTRICT_FAILURE: "GET_DISTRICT_FAILURE",
  GET_SUBDISTRICT_REQUEST: "GET_SUBDISTRICT_REQUEST",
  GET_SUBDISTRICT_SUCCESS: "GET_SUBDISTRICT_SUCCESS",
  GET_SUBDISTRICT_FAILURE: "GET_SUBDISTRICT_FAILURE",
  GET_POSTALCODE_REQUEST: "GET_POSTALCODE_REQUEST",
  GET_POSTALCODE_SUCCESS: "GET_POSTALCODE_SUCCESS",
  GET_POSTALCODE_FAILURE: "GET_POSTALCODE_FAILURE",
};

export const paymentOfflineVoucherConstants = {
  GET_REGISTERPATIENT_LIST_REQUEST: "GET_REGISTERPATIENT_LIST_REQUEST",
  GET_REGISTERPATIENT_LIST_SUCCESS: "GET_REGISTERPATIENT_LIST_SUCCESS",
  GET_REGISTERPATIENT_LIST_FAILURE: "GET_REGISTERPATIENT_LIST_FAILURE",
  FIND_PATIENT_DETAIL_REQUEST: "FIND_PATIENT_DETAIL_REQUEST",
  FIND_PATIENT_DETAIL_SUCCESS: "FIND_PATIENT_DETAIL_SUCCESS",
  FIND_PATIENT_DETAIL_FAILURE: "FIND_PATIENT_DETAIL_FAILURE",
};

export const pharmacyDeliveryAllConstants = {
  GET_PHARMACY_ALL_REQUEST: "GET_PHARMACY_ALL_REQUEST",
  GET_PHARMACY_ALL_SUCCESS: "GET_PHARMACY_ALL_SUCCESS",
  GET_PHARMACY_ALL_FAILURE: "GET_PHARMACY_ALL_FAILURE",
};

export const pharmacyDeliveryAllCanceledConstants = {
  GET_PHARMACY_ALL_CANCELED_REQUEST: "GET_PHARMACY_ALL_CANCELED_REQUEST",
  GET_PHARMACY_ALL_CANCELED_SUCCESS: "GET_PHARMACY_ALL_CANCELED_SUCCESS",
  GET_PHARMACY_ALL_CANCELED_FAILURE: "GET_PHARMACY_ALL_CANCELED_FAILURE",
  UPDATE_PHARMACY_ALL_CANCELED_REQUEST: "UPDATE_PHARMACY_ALL_CANCELED_REQUEST",
  UPDATE_PHARMACY_ALL_CANCELED_SUCCESS: "UPDATE_PHARMACY_ALL_CANCELED_SUCCESS",
  UPDATE_PHARMACY_ALL_CANCELED_FAILURE: "UPDATE_PHARMACY_ALL_CANCELED_FAILURE",
};

export const pharmacyDeliveryConstants = {
  GET_ALL_REPORT_PHARMACY_OFFLINE_REQUEST:
    "GET_ALL_REPORT_PHARMACY_OFFLINE_REQUEST",
  GET_ALL_REPORT_PHARMACY_OFFLINE_SUCCESS:
    "GET_ALL_REPORT_PHARMACY_OFFLINE_SUCCESS",
  GET_ALL_REPORT_PHARMACY_OFFLINE_FAILURE:
    "GET_ALL_REPORT_PHARMACY_OFFLINE_FAILURE",
  GET_ALL_PHARMACY_DELIVERY_REQUEST: "GET_ALL_PHARMACY_DELIVERY_REQUEST",
  GET_ALL_PHARMACY_DELIVERY_SUCCESS: "GET_ALL_PHARMACY_DELIVERY_SUCCESS",
  GET_ALL_PHARMACY_DELIVERY_FAILURE: "GET_ALL_PHARMACY_DELIVERY_FAILURE",
  GET_PHARMACY_DETAIL_REQUEST: "GET_PHARMACY_DETAIL_REQUEST",
  GET_PHARMACY_DETAIL_SUCCESS: "GET_PHARMACY_DETAIL_SUCCESS",
  GET_PHARMACY_DETAIL_FAILURE: "GET_PHARMACY_DETAIL_FAILURE",
  PHARMACY_PICKUP_REQUEST: "PHARMACY_PICKUP_REQUEST",
  PHARMACY_PICKUP_SUCCESS: "PHARMACY_PICKUP_SUCCESS",
  PHARMACY_PICKUP_FAILURE: "PHARMACY_PICKUP_FAILURE",
  GET_PHARMACY_REQUEST: "GET_PHARMACY_REQUEST",
  GET_PHARMACY_SUCCESS: "GET_PHARMACY_SUCCESS",
  GET_PHARMACY_FAILURE: "GET_PHARMACY_FAILURE",
  SUBMIT_PHARMACY_NOTE_REQUEST: "SUBMIT_PHARMACY_NOTE_REQUEST",
  SUBMIT_PHARMACY_NOTE_SUCCESS: "SUBMIT_PHARMACY_NOTE_SUCCESS",
  SUBMIT_PHARMACY_NOTE_FAILURE: "SUBMIT_PHARMACY_NOTE_FAILURE",
  GET_PHARMACY_REPORT_LINK_REQUEST: "GET_PHARMACY_REPORT_LINK_REQUEST",
  GET_PHARMACY_REPORT_LINK_SUCCESS: "GET_PHARMACY_REPORT_LINK_SUCCESS",
  GET_PHARMACY_REPORT_LINK_FAILURE: "GET_PHARMACY_REPORT_LINK_FAILURE",
  SUBMIT_ADDRESS_NOTE_REQUEST: "SUBMIT_ADDRESS_NOTE_REQUEST",
  SUBMIT_ADDRESS_NOTE_SUCCESS: "SUBMIT_ADDRESS_NOTE_SUCCESS",
  SUBMIT_ADDRESS_NOTE_FAILURE: "SUBMIT_ADDRESS_NOTE_FAILURE",
  GET_PHARMACY_DELIVERY_AIDO_REQUEST: "GET_PHARMACY_DELIVERY_AIDO_REQUEST",
  GET_PHARMACY_DELIVERY_AIDO_SUCCESS: "GET_PHARMACY_DELIVERY_AIDO_SUCCESS",
  GET_PHARMACY_DELIVERY_AIDO_FAILURE: "GET_PHARMACY_DELIVERY_AIDO_FAILURE",
};

export const pharmacyWaitingDeliveryConstants = {
  GET_ALL_PHARMACY_WAITING_REQUEST: "GET_ALL_PHARMACY_WAITING_REQUEST",
  GET_ALL_PHARMACY_WAITING_SUCCESS: "GET_ALL_PHARMACY_WAITING_SUCCESS",
  GET_ALL_PHARMACY_WAITING_FAILURE: "GET_ALL_PHARMACY_WAITING_FAILURE",
  GET_PHARMACY_WAITING_DETAIL_REQUEST: "GET_PHARMACY_WAITING_DETAIL_REQUEST",
  GET_PHARMACY_WAITING_DETAIL_SUCCESS: "GET_PHARMACY_WAITING_DETAIL_SUCCESS",
  GET_PHARMACY_WAITING_DETAIL_FAILURE: "GET_PHARMACY_WAITING_DETAIL_FAILURE",
  SUBMIT_PHARMACY_NOTE_REQUEST: "SUBMIT_PHARMACY_NOTE_REQUEST",
  SUBMIT_PHARMACY_NOTE_SUCCESS: "SUBMIT_PHARMACY_NOTE_SUCCESS",
  SUBMIT_PHARMACY_NOTE_FAILURE: "SUBMIT_PHARMACY_NOTE_FAILURE",
};

export const pharmacyDeliveryDoneConstants = {
  GET_PHARMACY_DONE_REQUEST: "GET_PHARMACY_DONE_REQUEST",
  GET_PHARMACY_DONE_SUCCESS: "GET_PHARMACY_DONE_SUCCESS",
  GET_PHARMACY_DONE_FAILURE: "GET_PHARMACY_DONE_FAILURE",
  GET_DETAIL_PHARMACY_DONE_REQUEST: "GET_DETAIL_PHARMACY_DONE_REQUEST",
  GET_DETAIL_PHARMACY_DONE_SUCCESS: "GET_DETAIL_PHARMACY_DONE_SUCCESS",
  GET_DETAIL_PHARMACY_DONE_FAILURE: "GET_DETAIL_PHARMACY_DONE_FAILURE",
};

export const pharmacyDeliveryAllUnitConstants = {
  GET_PHARMACY_ALLUNIT_REQUEST: "GET_PHARMACY_ALLUNIT_REQUEST",
  GET_PHARMACY_ALLUNIT_SUCCESS: "GET_PHARMACY_ALLUNIT_SUCCESS",
  GET_PHARMACY_ALLUNIT_FAILURE: "GET_PHARMACY_ALLUNIT_FAILURE",
  GET_DETAIL_PHARMACY_ALLUNIT_REQUEST: "GET_DETAIL_PHARMACY_ALLUNIT_REQUEST",
  GET_DETAIL_PHARMACY_ALLUNIT_SUCCESS: "GET_DETAIL_PHARMACY_ALLUNIT_SUCCESS",
  GET_DETAIL_PHARMACY_ALLUNIT_FAILURE: "GET_DETAIL_PHARMACY_ALLUNIT_FAILURE",
};

export const testCovidConstants = {
  GET_LIST_REQUEST: "GET_LIST_REQUEST",
  GET_LIST_SUCCESS: "GET_LIST_SUCCESS",
  GET_LIST_FAILURE: "GET_LIST_FAILURE",
  GET_AREA_LIST_REQUEST: "GET_AREA_LIST_REQUEST",
  GET_AREA_LIST_SUCCESS: "GET_AREA_LIST_SUCCESS",
  GET_AREA_LIST_FAILURE: "GET_AREA_LIST_FAILURE",
  GET_DETAIL_REQUEST: "GET_DETAIL_REQUEST",
  GET_DETAIL_SUCCESS: "GET_DETAIL_SUCCESS",
  GET_DETAIL_FAILURE: "GET_DETAIL_FAILURE",
  CREATE_OTP_REQUEST: "CREATE_OTP_REQUEST",
  CREATE_OTP_FAILURE: "CREATE_OTP_FAILURE",
  CREATE_OTP_SUCCESS: "CREATE_OTP_SUCCESS",
  VERIFY_OTP_REQUEST: "VERIFY_OTP_REQUEST",
  VERIFY_OTP_FAILURE: "VERIFY_OTP_FAILURE",
  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  REGISTER_OTP_REQUEST: "REGISTER_OTP_REQUEST",
  REGISTER_OTP_SUCCESS: "REGISTER_OTP_SUCCESS",
  REGISTER_OTP_FAILURE: "REGISTER_OTP_FAILURE",
  INITIALIZE_CUSTOMER_REQUEST: "INITIALIZE_CUSTOMER_REQUEST",
  INITIALIZE_CUSTOMER_SUCCESS: "INITIALIZE_CUSTOMER_SUCCESS",
  INITIALIZE_CUSTOMER_FAILURE: "INITIALIZE_CUSTOMER_FAILURE",
  GET_CUSTOMER_REQUEST: "GET_CUSTOMER_REQUEST",
  GET_CUSTOMER_SUCCESS: "GET_CUSTOMER_SUCCESS",
  GET_CUSTOMER_FAILURE: "GET_CUSTOMER_FAILURE",
  CREATE_CUSTOMER_REQUEST: "CREATE_CUSTOMER_REQUEST",
  CREATE_CUSTOMER_SUCCESS: "CREATE_CUSTOMER_SUCCESS",
  CREATE_CUSTOMER_FAILURE: "CREATE_CUSTOMER_FAILURE",
  UPDATE_CUSTOMER_REQUEST: "UPDATE_CUSTOMER_REQUEST",
  UPDATE_CUSTOMER_SUCCESS: "UPDATE_CUSTOMER_SUCCESS",
  UPDATE_CUSTOMER_FAILURE: "UPDATE_CUSTOMER_FAILURE",
  CREATE_ADDRESS_REQUEST: "CREATE_ADDRESS_REQUEST",
  CREATE_ADDRESS_SUCCESS: "CREATE_ADDRESS_SUCCESS",
  CREATE_ADDRESS_FAILURE: "CREATE_ADDRESS_FAILURE",
  GET_PAYMENT_REQUEST: "GET_PAYMENT_REQUEST",
  GET_PAYMENT_SUCCESS: "GET_PAYMENT_SUCCESS",
  GET_PAYMENT_FAILURE: "GET_PAYMENT_FAILURE",
  CREATE_PAYMENT_REQUEST: "CREATE_PAYMENT_REQUEST",
  CREATE_PAYMENT_SUCCESS: "CREATE_PAYMENT_SUCCESS",
  CREATE_PAYMENT_FAILURE: "CREATE_PAYMENT_FAILURE",
};

export const gpOnsiteConstants = {
  GET_ALL_GPONSITE_REQUEST: "GET_ALL_GPONSITE_REQUEST",
  GET_ALL_GPONSITE_SUCCESS: "GET_ALL_GPONSITE_SUCCESS",
  GET_ALL_GPONSITE_FAILURE: "GET_ALL_GPONSITE_FAILURE",
  GET_DONE_GPONSITE_REQUEST: "GET_DONE_GPONSITE_REQUEST",
  GET_DONE_GPONSITE_SUCCESS: "GET_DONE_GPONSITE_SUCCESS",
  GET_DONE_GPONSITE_FAILURE: "GET_DONE_GPONSITE_FAILURE",
  UPDATE_GPONSITE_REQUEST: "UPDATE_GPONSITE_REQUEST",
  UPDATE_GPONSITE_SUCCESS: "UPDATE_GPONSITE_SUCCESS",
  UPDATE_GPONSITE_FAILURE: "UPDATE_GPONSITE_FAILURE",
};

export const orderMedicalRecordsConstant = {
  GET_ALL_MEDICALRECORDS_REQUEST: "GET_ALL_MEDICALRECORDS_REQUEST",
  GET_ALL_MEDICALRECORDS_SUCCESS: "GET_ALL_MEDICALRECORDS_SUCCESS",
  GET_ALL_MEDICALRECORDS_FAILURE: "GET_ALL_MEDICALRECORDS_FAILURE",
  GET_MEDICAL_REPORT_REQUEST: "GET_MEDICAL_REPORT_REQUEST",
  GET_MEDICAL_REPORT_SUCCESS: "GET_MEDICAL_REPORT_SUCCESS",
  GET_MEDICAL_REPORT_FAILURE: "GET_MEDICAL_REPORT_FAILURE",
  GET_WEEKLY_DASHBOARD_REQUEST: "GET_WEEKLY_DASHBOARD_REQUEST",
  GET_WEEKLY_DASHBOARD_SUCCESS: "GET_WEEKLY_DASHBOARD_SUCCESS",
  GET_WEEKLY_DASHBOARD_FAILURE: "GET_WEEKLY_DASHBOARD_FAILURE",
};

export const healthScreeningConstants = {
  GET_ALL_HS_REQUEST: "GET_ALL_HS_REQUEST",
  GET_ALL_HS_SUCCESS: "GET_ALL_HS_SUCCESS",
  GET_ALL_HS_FAILURE: "GET_ALL_HS_FAILURE",
  GET_ALL_EVENTHS_REQUEST: "GET_ALL_EVENTHS_REQUEST",
  GET_ALL_EVENTHS_SUCCESS: "GET_ALL_EVENTHS_SUCCESS",
  GET_ALL_EVENTHS_FAILURE: "GET_ALL_EVENTHS_FAILURE",
  CHECKIN_HS_REQUEST: "CHECKIN_HS_REQUEST",
  CHECKIN_HS_SUCCESS: "CHECKIN_HS_SUCCESS",
  CHECKIN_HS_FAILURE: "CHECKIN_HS_FAILURE",
};

export const addressConstants = {
  GET_SUBDISTRICT_BY_KEY_REQUEST: "GET_SUBDISTRICT_BY_KEY_REQUEST",
  GET_SUBDISTRICT_BY_KEY_SUCCESS: "GET_SUBDISTRICT_BY_KEY_SUCCESS",
  GET_SUBDISTRICT_BY_KEY_FAILURE: "GET_SUBDISTRICT_BY_KEY_FAILURE",
};

export const configConstants = {
  FIND_CONFIG_REQUEST: "FIND_CONFIG_REQUEST",
  FIND_CONFIG_SUCCESS: "FIND_CONFIG_SUCCESS",
  FIND_CONFIG_FAILURE: "FIND_CONFIG_SUCCESS"
}
