import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getPaymentMethod } from "../../../actions";
import { makeStyles } from "@material-ui/core/styles";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import queryString from "query-string";
import Swal from "sweetalert2";
import Layout from "../Layout";

const PaymentMethod = () => {
  const { state } = useLocation();
  const [show, setShow] = useState(false);
  const testCovid = useSelector((state) => state.testCovid);
  const dispatch = useDispatch();
  const { loading, error, paymentMethodData } = testCovid;

  useEffect(() => {
    if (state) {
      let values = {
        orderType: 6,
        price: state.price,
      };

      let query = queryString.stringify(values);

      dispatch(getPaymentMethod(query)).catch((err) => {
        if (err.response) {
          return Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          return Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
    }
  }, []);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));

  const classes = useStyles();

  const handleClick = () => {
    setShow(!show);
  };

  return (
    <Layout title="Metode Pembayaran" link="/test-covid/order" state={state}>
      {loading ? (
        <Loading />
      ) : (
        <>
          {paymentMethodData
            ? paymentMethodData.data
                .filter((data) => data.groupId == 2)
                .map((group) => (
                  <>
                    <ListItem button onClick={handleClick}>
                      <ListItemIcon>
                        <img
                          src={group.groupImage}
                          style={{
                            height: "auto",
                            width: "40px",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={group.groupName} />
                      {show ? (
                        <i
                          className="fa fa-chevron-up"
                          style={{ fontSize: "24px", color: "black" }}
                        />
                      ) : (
                        <i
                          className="fa fa-chevron-down"
                          style={{ fontSize: "24px", color: "black" }}
                        />
                      )}
                    </ListItem>

                    <Collapse in={show} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {group.groupDetails.map((detail) => (
                          <Link
                            to={{
                              pathname: "/test-covid/order",
                              state: {
                                ...state,
                                paymentName: detail.code,
                                paymentImg: detail.icon,
                                paymentType: detail.cardType,
                                xenditToken: detail.tokenId,
                              },
                            }}
                          >
                            <ListItem button className={classes.nested}>
                              <ListItemIcon>
                                <img
                                  src={detail.icon}
                                  style={{
                                    height: "auto",
                                    width: "40px",
                                  }}
                                />
                              </ListItemIcon>
                              <ListItemText primary={detail.code} />
                            </ListItem>
                          </Link>
                        ))}
                      </List>
                    </Collapse>
                  </>
                ))
            : null}
        </>
      )}
    </Layout>
  );
};

export default PaymentMethod;
