import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import DashboardLayout from "../../../Layout";

import { useDispatch, useSelector } from "react-redux";

import {
    getListRiwayatKesehatan,
} from "../../../../../actions";

// Style
import "../../orderHistory.css";

// Helpers
import { formatDate } from "../../../../../helpers/getFormattedDate";

export default function OrderHistoryDetailRiwayat() {
    const result = useSelector((state) => state.orderHistory);
    const { state } = useLocation();
    const dispatch = useDispatch();    

    const custId = state.detailData != undefined ?  
                        state.detailData.customer != undefined ? 
                            state.detailData.customer.id : 0 
                        : 0;
    const patientId = state.detailData != undefined ?  
                        state.detailData.patient != undefined ? 
                            state.detailData.patient.id : 0 
                        : 0;
    useEffect(() => {
        dispatch(getListRiwayatKesehatan(custId, patientId));
    }, []);

    const riwayatDataVal = result.listRiwayat != undefined ? result.listRiwayat : [];

    const prevPageUrl = "/order-history/detail";
    const nextPageRiwayatDetailUrl = "/order-history/detail/lihatriwayat";
    
    return (
        <DashboardLayout>        
            <div className="col-12" >
                <div className="card-detail" style={{background:"#38a8ff", margin:"30px 0px 20px 0px", borderRadius: "10px 10px 0px 0px"}}>
                    <Link to={{ pathname: prevPageUrl, state: {"id": state.id, "statusCode": state.statusCode, "detailData": state.detailData} }}>
                        <i className="fa fa-arrow-left" style={{fontSize: "18px", color: "white"}} aria-hidden="true" /> 
                    </Link>
                    <span style={{margin: "0px 30px 0px 30px", color: "white", fontSize: "16px"}}> 
                        Riwayat Kesehatan
                    </span>
                </div>
                {
                    riwayatDataVal.length > 0 ? (
                        riwayatDataVal.map((data, index) => (
                            <div className="card-detail" key={data.id}>
                                <Link to = {{ pathname: nextPageRiwayatDetailUrl, state: {"id": state.id, "statusCode": state.statusCode, "detailData": state.detailData, "orderId": data.id} }}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td style={{width: "30px"}}>
                                                    <i className="fa fa-heartbeat" style={{fontSize:"30px", margin: "0px 10px 0px 0px"}}/>
                                                </td>
                                                <td>
                                                    <div style={{fontSize:"14px", fontWeight:"bold"}}>
                                                        {data.partnerName}
                                                    </div>
                                                    <div>
                                                        <i className="fa fa-calendar" aria-hidden="true"  /> 
                                                        <span style={{margin: "5px 0px 0px 10px", fontSize:"13px"}}>
                                                            {formatDate(data.scheduleDate)}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <i className="fa fa-clock-o" aria-hidden="true"  /> 
                                                        <span style={{margin: "0px 0px 10px 10px", fontSize:"13px"}}>
                                                            {data.startTime} - {data.endTime}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td style={{width: "10px"}} align="right">
                                                    <i className="fa fa-angle-right" aria-hidden="true" style={{fontSize: "20px"}} /> 
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Link>
                            </div>
                        ))
                    )                     
                    : ( <p> </p>)
                }
                
            </div>
        </DashboardLayout>
    );
};