import React, { useEffect, useState } from "react";
import DashboardLayout from "../../Layout";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { postVaccineReport } from "../../../../actions";
import ExcelJS from "exceljs";
import saveAs from "file-saver";

const VaccineDailyReport = () => {
  const vaccineDailyReports = useSelector((state) => state.orderVaccine);
  const [orders, setOrders] = useState(null);
  const dispatch = useDispatch();
  const [date, setDate] = useState("");

  useEffect(() => {
    getData(vaccineDailyReports.orders);
  }, [vaccineDailyReports.orders]);

  const getData = (orders) => {
    setOrders(orders);
  };

  const handleChange = (e) => {
    setDate(e.target.value);
    const form = new FormData();
    const parentId = localStorage.getItem("parentId");
    form.append("parentId", parentId);
    form.append("reportDate", e.target.value);

    dispatch(postVaccineReport(form));
  };

  const exportToExcel = () => {
    let workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Order");
    let rowField;
    rowField = worksheet.addRow([
      "Order ID",
      "Nama Pasien",
      "Age",
      "DOB",
      "Gender",
      "No HP",
      "Email",
      "NIK",
      "Alamat",
      "Emergency Contact Name",
      "Emergency Contact Number",
      "Emergency Contact Address",
      "Created at",
      "Jadwal",
      "Start Time",
      "Status",
      "Url Screening",
    ]);

    orders.map((orders) => {
      var gender = "";
      if(orders.patient_gender == 1){
        gender = "Laki-laki";
      }else{
        gender = "Perempuan";
      }
      worksheet.addRow([
        orders.order_id,
        orders.patient_name,
        orders.patient_age,
        moment(orders.patient_dob).format("DD MMM YYYY"),
        gender,
        orders.patient_phone,
        orders.patient_email,
        orders.nik,
        orders.alamat,
        orders.emergency_contact_name,
        orders.emergency_contact_no,
        orders.emergency_contact_address,
        orders.created_at,
        moment(orders.jadwal).format("DD/MM/YYYY"),
        orders.startTime,
        orders.status,
        orders.skrining_url
      ]);
    });

    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateReplace = date.replace(/-/g, "");
    var timeReplace = time.replace(/:/g, "");
    workbook.xlsx.writeBuffer().then((buff) => {
      saveAs(
        new Blob([buff]),
        "VaccineDailyReport-" + dateReplace + timeReplace + ".xlsx"
      );
    });
  };

  return (
    <DashboardLayout>
      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>
        <div className="wrapper-form-title" id="btn-add">
          <span className="form-title">Filter Daily Report</span>
        </div>
        <div className="border-bottom-section">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="reportDate">Report Date</label>
                <input
                  id="datePickerFrom"
                  type="text"
                  className="form-control"
                  name="reportDate"
                  placeholder="Date Report"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  name="reportDate"
                  value={date}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-btn-group text-right">
          <button
            id="submitBtn"
            type="submit"
            className="btn btn-primary"
            onClick={() => exportToExcel()}
          >
            Download
          </button>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default VaccineDailyReport;
