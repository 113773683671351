import React, { useEffect, useState } from "react";
import DashboardLayout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { postDownloadReport } from "../../../actions";
import ExcelJS from "exceljs";
import saveAs from "file-saver";

const DailyReport = () => {
  const [date, setDate] = useState("");
  const [report, setReport] = useState({
    orders: [],
    orders2: [],
  });
  const dailyReports = useSelector((state) => state.dailyReport);
  const dispatch = useDispatch();

  useEffect(() => {
    if (date !== "") {
      const form = new FormData();
      form.append("reportDate", date);

      dispatch(postDownloadReport(form));
    }
  }, [date]);

  useEffect(() => {
    getData(dailyReports.orders, dailyReports.orders2);
  }, [dailyReports.orders, dailyReports.orders2]);

  const getData = (orders, orders2) => {
    setReport({
      orders: orders,
      orders2: orders2,
    });
  };

  const exportToExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("order");
    let row;

    if (localStorage.getItem("parentId") == 3295) {
      row = worksheet.addRow([
        "scheduleDate",
        "startTime",
        "endTime",
        "order_id",
        "customer_name",
        "customer_email",
        "patient_name",
        "patient_email",
        "patient_dob",
        "patient_phone",
        "doctor_name",
        "promoCode",
        "amount",
        "zoom_url",
        "meeting_id",
        "zoom_pin",
        "status",
        "isPremium",
        "isHealthScreening",
        "event_name",
        "corporate_name",
      ]);
    } else {
    
    row = worksheet.addRow([
      "scheduleDate",
      "startTime",
      "endTime",
      "order_id",
      "customer_name",
      "customer_email",
      "patient_name",
      "patient_email",
      "patient_dob",
      "patient_phone",
      "doctor_name",
      "promoCode",
      "amount",
      "zoom_url",
      "meeting_id",
      "zoom_pin",
      "status",
    ]);
      
    }
    row.font = { bold: true };

    let data = report.orders;
    if (data.length !== 0) {
      if (localStorage.getItem("parentId") == 3295) {
        data.map((orders) => {
          worksheet.addRow([
            moment(orders.scheduleDate).format("DD MMM YYYY"),
            orders.startTime,
            orders.endTime,
            orders.order_id,
            orders.customer_name,
            orders.user_email,
            orders.patient_name,
            orders.patient_email,
            orders.patient_dob,
            orders.patient_phone,
            orders.doctor_name,
            orders.promoCode,
            orders.totalFee,
            orders.zoom_url,
            orders.meeting_id,
            orders.zoom_pin,
            orders.status,
            orders.isPremium ? orders.isPremium : 0,
            orders.isHealthScreening,
            orders.event_name,
            orders.corporate_name,
          ]);
        });
      } else {
      data.map((orders) => {
        worksheet.addRow([
          moment(orders.scheduleDate).format("DD MMM YYYY"),
          orders.startTime,
          orders.endTime,
          orders.order_id,
          orders.customer_name,
          orders.user_email,
          orders.patient_name,
          orders.patient_email,
          orders.patient_dob,
          orders.patient_phone,
          orders.doctor_name,
          orders.promoCode,
          orders.totalFee,
          orders.zoom_url,
          orders.meeting_id,
          orders.zoom_pin,
          orders.status,
        ]);
      });
      }
     
    }

    const datasheet = workbook.addWorksheet("Slot Available");
    row = datasheet.addRow([
      "full_name",
      "parent_name",
      "scheduleDate",
      "startTime",
      "endTime",
      "zoom_url",
      "meeting_ID",
      "zoom_pin",
      "assignedOrder",
    ]);
    row.font = { bold: true };

    let data2 = report.orders2;
    if (data2.length !== 0) {
      data2.map((orders2) => {
        datasheet.addRow([
          orders2.full_name,
          orders2.parent_name,
          moment(orders2.scheduleDate).format("DD MMM YYYY"),
          orders2.startTime,
          orders2.endTime,
          orders2.zoom_url,
          orders2.meeting_ID,
          orders2.zoom_pin,
          orders2.assignedOrder,
        ]);
      });
    }

    let today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate(),
      time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds(),
      dateReplace = date.replace(/-/g, ""),
      timeReplace = time.replace(/:/g, "");

    workbook.xlsx.writeBuffer().then((buff) => {
      saveAs(
        new Blob([buff]),
        "DailyReport-" + dateReplace + timeReplace + ".xlsx"
      );
    });
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Daily Report</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        <div className="wrapper-form-title" id="btn-add">
          <span className="form-title">Filter Daily Report</span>
        </div>

        <div className="border-bottom-section">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label for="">Report Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form-btn-group text-right">
          {dailyReports.loading ? (
            <button type="submit" className="btn btn-primary" disabled>
              Loading...
            </button>
          ) : (
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => exportToExcel()}
            >
              Download
            </button>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default DailyReport;
