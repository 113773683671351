import { teleRefundConstants } from "../actions/constants";

const initState = {
  orders: [],
  loading: false,
  error: null,
  totalData: null,
  sendBird: "",
  parentId: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case teleRefundConstants.GET_ALL_TELEREFUND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case teleRefundConstants.GET_ALL_TELEREFUND_SUCCESS:
      return {
        ...state,
        orders: action.payload.orders,
        loading: false,
        totalData: action.payload.totalData,
        sendBird: action.payload.sendBird,
        parentId: action.payload.parentId,
      };
    case teleRefundConstants.GET_ALL_TELEREFUND_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case teleRefundConstants.DO_REFUND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case teleRefundConstants.DO_REFUND_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case teleRefundConstants.DO_REFUND_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    default: 
      return state;
  }
};
