import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import Geocode from "react-geocode";

let zoom = 14;

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.existData ? this.props.existData.address : "",
      mapCenter: {
        lat: this.props.existData
          ? this.props.existData.lat
          : -6.175400791146999,
        lng: this.props.existData
          ? this.props.existData.lng
          : 106.82716145352265,
      },
    };
  }

  onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    let lat = latLng.lat();
    let lng = latLng.lng();

    Geocode.setApiKey(`${process.env.REACT_APP_GOOGLE_API}`);
    Geocode.setLocationType("ROOFTOP");
    Geocode.fromLatLng(lat, lng)
      .then(
        (response) => {
          let fullAddress = response.results[0].formatted_address,
            postalCode;

          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "postal_code":
                  postalCode =
                    response.results[0].address_components[i].long_name;
                  break;
              }
            }
          }

          if(postalCode == undefined && !this.props.isVersion2) {
            fullAddress = this.props.existData ? this.props.existData.address : "";
            lat = this.props.existData ? this.props.existData.lat : -6.175400791146999;
            lng = this.props.existData ? this.props.existData.lng : 106.82716145352265;
          }

          this.setState({
            address: fullAddress,
            mapCenter: {
              lat: lat ? lat : null,
              lng: lng ? lng : null,
            },
          });

          this.props.addressData({
            latitude: lat ? lat : null,
            longitude: lng ? lng : null,
            fullAddress: fullAddress ? fullAddress : "",
            postalCode: postalCode ? postalCode : "",
          });
        },
        (error) => {
          console.error(error);
        }
      )
      .catch((error) => console.error("Error", error));
  };

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address, placeId) => {
    const tempStr = address.split(",");
    let tempArr = [];

    for (let i = 0; i < tempStr.length; i++) {
      const item = tempStr[i].toUpperCase();
      if (!item.includes("RT")) {
        if (!item.includes("RW")) {
          tempArr.push(tempStr[i]);
        }
      }
    }

    // geocodeByPlaceId(placeId)
    // .then(results => {
    //   getLatLng(results[0])
    //   .then(latLng => {
    //     let lat = latLng.lat;
    //     let lng = latLng.lng;

    //     this.setState({ mapCenter: latLng });

    //     let fullAddress = results[0].formatted_address,
    //           postalCode;

    //     for (
    //       let i = 0;
    //       i < results[0].address_components.length;
    //       i++
    //     ) {
    //       for (
    //         let j = 0;
    //         j < results[0].address_components[i].types.length;
    //         j++
    //       ) {
    //         switch (
    //           results[0].address_components[i].types[j]
    //         ) {
    //           case "postal_code":
    //             postalCode =
    //               results[0].address_components[i].long_name;
    //             break;
    //         }
    //       }
    //     }

    //     if(postalCode == undefined) {
    //       fullAddress = this.props.existData ? this.props.existData.address : "";
    //       lat = this.props.existData ? this.props.existData.lat : -6.175400791146999;
    //       lng = this.props.existData ? this.props.existData.lng : 106.82716145352265;
    //     }

    //     this.setState({
    //       address: fullAddress,
    //       mapCenter: {
    //         lat: lat ? lat : null,
    //         lng: lng ? lng : null,
    //       },
    //     });

    //     this.props.addressData({
    //       latitude: lat ? lat : null,
    //       longitude: lng ? lng : null,
    //       fullAddress: fullAddress ? fullAddress : "",
    //       postalCode: postalCode ? postalCode : "",
    //     });
    //   })
    //   .catch(error => console.error("getLatLng Error:", error))
    // })
    // .catch(error => console.error("geocodeByPlaceId Error:", error))

    geocodeByAddress(address)
      .then((results) => {
        getLatLng(results[0])
          .then((latLng) => {
            let lat = latLng.lat;
            let lng = latLng.lng;

            this.setState({ mapCenter: latLng });

            Geocode.setApiKey(`${process.env.REACT_APP_GOOGLE_API}`);
            Geocode.setLocationType("ROOFTOP");
            Geocode.fromLatLng(latLng.lat, latLng.lng).then(
              (response) => {
                let fullAddress = response.results[0].formatted_address,
                  postalCode;

                for (
                  let i = 0;
                  i < response.results[0].address_components.length;
                  i++
                ) {
                  for (
                    let j = 0;
                    j < response.results[0].address_components[i].types.length;
                    j++
                  ) {
                    switch (
                      response.results[0].address_components[i].types[j]
                    ) {
                      case "postal_code":
                        postalCode =
                          response.results[0].address_components[i].long_name;
                        break;
                    }
                  }
                }

                if(postalCode == undefined && !this.props.isVersion2) {
                  fullAddress = this.props.existData ? this.props.existData.address : "";
                  lat = this.props.existData ? this.props.existData.lat : -6.175400791146999;
                  lng = this.props.existData ? this.props.existData.lng : 106.82716145352265;
                }

                this.setState({
                  address: fullAddress,
                  mapCenter: {
                    lat: lat ? lat : null,
                    lng: lng ? lng : null,
                  },
                });

                this.props.addressData({
                  latitude: lat ? lat : null,
                  longitude: lng ? lng : null,
                  fullAddress: fullAddress ? fullAddress : "",
                  postalCode: postalCode ? postalCode : "",
                });
              },
              (error) => {
                console.error(error);
              }
            );
          })
          .catch((error) => console.error("getLatLng Error:", error));
      })
      .catch((error) => console.error("geocodeByAddress Error:", error));
  };

  render() {
    return (
      <div>
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          shouldFetchSuggestions={this.state.address.trim().split(" ").length > 2}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className="form-group">
              <div
                style={{
                  height: "400px",
                  position: "relative",
                }}
              >
                <Map
                  google={this.props.google}
                  initialCenter={{
                    lat: this.state.mapCenter.lat,
                    lng: this.state.mapCenter.lng,
                  }}
                  center={{
                    lat: this.state.mapCenter.lat,
                    lng: this.state.mapCenter.lng,
                  }}
                  zoom={zoom}
                  onZoomChanged={() => (zoom += 0.5)}
                  maxZoom={20}
                >
                  <Marker
                   icon={{
                    url: '/img/icon/pin-point.png',
                  }}
                    position={this.state.mapCenter}
                    draggable={true}
                    onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
                  />
                </Map>
              </div>

              <div
                style={{
                  zIndex: "99",
                  position: "relative",
                }}
              >
                <span
                  className="form-group"
                  style={{
                    color: "red",
                    paddingTop: "10px"
                  }}
                >
                  <i>
                    {this.props.notesLabel}{" "}
                    <img className="icon-map" src={"/img/icon/pin-point.png"} />
                     {this.props.notesLabelDescription}{" "}
                  </i>
                </span>
              </div>

              <div
                style={{
                  zIndex: "99",
                  position: "relative",
                }}
              >
                <label className="form-label-bold">
                  {this.props.inputLabel}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  style={{ position: "relative", zIndex: "99", width: "100%" }}
                  {...getInputProps({
                    placeholder: this.props.placeholder
                      ? this.props.placeholder
                      : "Search Places",
                    className: "form-control location-search-input form-input-map",
                  })}
                />
                <div
                  className="autocomplete-dropdown-container form-search-map"
                  style={{ zIndex: "99", position: "absolute" }}
                >
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";

                    const style = suggestion.active
                      ? {
                          backgroundColor: "#c2c2c2",
                          cursor: "pointer",
                          border: "1px solid #ced4da",
                          padding: "10px",
                        }
                      : {
                          backgroundColor: "#ffffff",
                          cursor: "pointer",
                          border: "1px solid #ced4da",
                          padding: "10px",
                        };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: `${process.env.REACT_APP_GOOGLE_API}`,
})(MapContainer);
