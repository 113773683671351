import { teleRescheduleConstants } from "../actions/constants";

const initState = {
  orders: [],
  loading: false,
  error: null,
  order: {},
  schedule_data: [],
  totalData: null,
  sendBird: "",
  parentId: null,
  res_data: {},
};

export default (state = initState, action) => {
  switch (action.type) {
    case teleRescheduleConstants.GET_TELE_RESCHEDULE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case teleRescheduleConstants.GET_TELE_RESCHEDULE_SUCCESS:
      return {
        ...state,
        orders: action.payload.orders,
        totalData: action.payload.totalData,
        sendBird: action.payload.sendBird,
        parentId: action.payload.parentId,
        loading: false,
      };
    case teleRescheduleConstants.GET_TELE_RESCHEDULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case teleRescheduleConstants.GET_TELE_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case teleRescheduleConstants.GET_TELE_DETAIL_SUCCESS:
      return {
        ...state,
        order: action.payload.order,
        loading: false,
      }
    case teleRescheduleConstants.GET_TELE_DETAIL_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case teleRescheduleConstants.GET_SCHEDULE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case teleRescheduleConstants.GET_SCHEDULE_DATA_SUCCESS:
      return {
        ...state,
        schedule_data: action.payload.schedule_data,
        loading: false,
      }
    case teleRescheduleConstants.GET_SCHEDULE_DATA_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case teleRescheduleConstants.GET_SCHEDULE_NONSILOAM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case teleRescheduleConstants.GET_SCHEDULE_NONSILOAM_SUCCESS:
      return {
        ...state,
        schedule_data: action.payload.schedule_data,
        loading: false,
      }
    case teleRescheduleConstants.GET_SCHEDULE_NONSILOAM_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case teleRescheduleConstants.RESCHEDULE_TELE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case teleRescheduleConstants.RESCHEDULE_TELE_SUCCESS:
      return {
        ...state,
        loading: false,
        res_data: action.payload.data
      };
    case teleRescheduleConstants.RESCHEDULE_TELE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case teleRescheduleConstants.CANCEL_ORDER_TELE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case teleRescheduleConstants.CANCEL_ORDER_TELE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case teleRescheduleConstants.CANCEL_ORDER_TELE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case teleRescheduleConstants.APPROVECLAIM_TELE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case teleRescheduleConstants.APPROVECLAIM_TELE_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload.data
      };
    case teleRescheduleConstants.APPROVECLAIM_TELE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case teleRescheduleConstants.REJECTCLAIM_TELE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case teleRescheduleConstants.REJECTCLAIM_TELE_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload.data
      };
    case teleRescheduleConstants.REJECTCLAIM_TELE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        };
    default: 
      return state;
  }
};
