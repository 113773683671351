import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  createEventNasional,
  getAllVaccinationEvent,
} from "../../../../../actions";
import DashboardLayout from "../../../Layout";
import Modal from "../../../Components/Modal";
import Loading from "../../../../../components/Loading";
import moment from "moment";
import Swal from "sweetalert2";

const AddNasional = () => {
  let history = useHistory();
  const [vaccinationData, setVaccinationData] = useState({
    fullName: "",
    vaccineType: "",
    fulladdress: "",
    companyName: "",
    startDate: "",
    endDate: "",
    logo: [],
    banner: [],
  });
  const [fileName, setFileName] = useState({
    logoName: "Upload Image",
    bannerName: "Upload Image",
  });
  const [errors, setErrors] = useState({
    fullName: false,
    vaccineType: false,
    fulladdress: false,
    companyName: false,
    startDate: false,
    endDate: false,
    logo: false,
    banner: false,
  });
  const [viewLogoModal, setViewLogoModal] = useState(false);
  const [viewBannerModal, setViewBannerModal] = useState(false);
  const [activeForm, setActiveForm] = useState(0);
  const vaccinationEvent = useSelector((state) => state.myVaccinationEvent);
  const dispatch = useDispatch();
  const { loading, error } = vaccinationEvent;

  const handleCloseModal = () => {
    setViewLogoModal(false);
    setViewBannerModal(false);
  };

  const handleNext = () => {
    setActiveForm((step) => step + 1);
  };

  const actionSubmitVaccination = () => {
    const form = new FormData();

    form.append("parentId", localStorage.getItem("parentId"));
    form.append("fullName", vaccinationData.fullName);
    form.append("fulladdress", vaccinationData.fulladdress);
    form.append("companyName", vaccinationData.companyName);
    form.append("vaccineType", vaccinationData.vaccineType);
    form.append("startDate", vaccinationData.startDate);
    form.append("endDate", vaccinationData.endDate);

    form.append("avatar", vaccinationData.banner);
    form.append("logo", vaccinationData.logo);

    dispatch(createEventNasional(form))
      .then((res) => {
        if (res) {
          dispatch(getAllVaccinationEvent());
        }

        Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: false,
        }).fire({
          icon: "success",
          title: "Data Created",
        });
        history.replace("/my-vaccination-event");
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  };

  const renderPreviewData = () => {
    return (
      <>
        <div className="wrapper-form-title">
          <span className="form-title">Vaccination Information</span>
        </div>

        <div className="form-group">
          <div style={{ display: "flex" }}>
            <span className="col-4">Event Name</span>
            <span className="col">{`: ${
              vaccinationData.fullName ? vaccinationData.fullName : "-"
            }`}</span>
          </div>

          <div style={{ display: "flex" }}>
            <span className="col-4">Vaccine Type</span>
            <span className="col">{`: ${
              vaccinationData.vaccineType ? vaccinationData.vaccineType : "-"
            }`}</span>
          </div>

          <div style={{ display: "flex" }}>
            <span className="col-4">Location</span>
            <span className="col">{`: ${
              vaccinationData.fulladdress ? vaccinationData.fulladdress : "-"
            }`}</span>
          </div>

          <div style={{ display: "flex" }}>
            <span className="col-4">Company Name</span>
            <span className="col">{`: ${
              vaccinationData.companyName ? vaccinationData.companyName : "-"
            }`}</span>
          </div>

          <div style={{ display: "flex" }}>
            <span className="col-4">Start Date</span>
            <span className="col">{`: ${
              vaccinationData.startDate
                ? moment(vaccinationData.startDate).format("LL")
                : "-"
            }`}</span>
          </div>

          <div style={{ display: "flex" }}>
            <span className="col-4">End Date</span>
            <span className="col">{`: ${
              vaccinationData.endDate
                ? moment(vaccinationData.endDate).format("LL")
                : "-"
            }`}</span>
          </div>
        </div>

        <div className="row justify-content-end mt-3">
          <div className="col-md-4 col-lg-3 col-xl-2">
            <button
              className="btn btn-block btn-primary"
              onClick={actionSubmitVaccination}
            >
              Submit
            </button>
          </div>
        </div>
      </>
    );
  };

  const actionVaccinationData = () => {
    setErrors({
      fullName: vaccinationData.fullName === "" ? true : false,
      vaccineType: vaccinationData.vaccineType === "" ? true : false,
      fulladdress: vaccinationData.fulladdress === "" ? true : false,
      companyName: vaccinationData.companyName === "" ? true : false,
      startDate: vaccinationData.startDate === "" ? true : false,
      endDate: vaccinationData.endDate === "" ? true : false,
      logo: vaccinationData.logo.length === 0 ? true : false,
      banner: vaccinationData.banner.length === 0 ? true : false,
    });

    const readyForSubmit = () => {
      if (vaccinationData.fullName === "") {
        return false;
      }
      if (vaccinationData.vaccineType === "") {
        return false;
      }
      if (vaccinationData.fulladdress === "") {
        return false;
      }
      if (vaccinationData.companyName === "") {
        return false;
      }
      if (vaccinationData.startDate === "") {
        return false;
      }
      if (vaccinationData.endDate === "") {
        return false;
      }
      if (vaccinationData.logo.length === 0) {
        return false;
      }
      if (vaccinationData.banner.length === 0) {
        return false;
      }
      return true;
    };

    if (readyForSubmit()) {
      handleNext();
    }
  };

  const handleView = (e) => {
    let type = e.target.name;

    if (type === "logo") {
      setViewLogoModal(true);
    } else if (type === "banner") {
      setViewBannerModal(true);
    }
  };

  const renderViewLogo = () => {
    return (
      <Modal
        modalTitle="Company Logo Image"
        show={viewLogoModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        buttons={[]}
        close
      >
        <Row>
          <Col md>
            <img
              className="text-center"
              src={
                vaccinationData.logo.length !== 0
                  ? URL.createObjectURL(vaccinationData.logo)
                  : "/img/icon/ic_camera.png"
              }
              style={{
                borderRadius: "10px",
                width: "100%",
              }}
            />
          </Col>
        </Row>
      </Modal>
    );
  };

  const renderViewBanner = () => {
    return (
      <Modal
        modalTitle="Company Banner Image"
        show={viewBannerModal}
        size="lg"
        handleClose={() => handleCloseModal()}
        buttons={[]}
        close
      >
        <Row>
          <Col md>
            <img
              className="text-center"
              src={
                vaccinationData.banner.length !== 0
                  ? URL.createObjectURL(vaccinationData.banner)
                  : "/img/icon/ic_camera.png"
              }
              style={{
                borderRadius: "10px",
                width: "100%",
              }}
            />
          </Col>
        </Row>
      </Modal>
    );
  };

  const renderVaccinationData = () => {
    return (
      <>
        <div className="row">
          <div className="form-group col-md-6">
            <div className="mb10">Event Name</div>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Input Event Name"
                value={vaccinationData.fullName}
                onChange={(e) =>
                  setVaccinationData({
                    ...vaccinationData,
                    fullName: e.target.value,
                  })
                }
              />
            </div>
            {errors.fullName && (
              <div className="error">
                <label className="is-invalid">
                  Please input event name first
                </label>
              </div>
            )}
          </div>

          <div className="form-group col-md-6">
            <div className="mb10">Vaccine Type</div>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Input Vaccine Type"
                value={vaccinationData.vaccineType}
                onChange={(e) =>
                  setVaccinationData({
                    ...vaccinationData,
                    vaccineType: e.target.value,
                  })
                }
              />
            </div>
            {errors.vaccineType && (
              <div className="error">
                <label className="is-invalid">
                  Please input vaccine type first
                </label>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <div className="mb10">Vaccination Location</div>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Input Venue Name"
                value={vaccinationData.fulladdress}
                onChange={(e) =>
                  setVaccinationData({
                    ...vaccinationData,
                    fulladdress: e.target.value,
                  })
                }
              />
            </div>
            {errors.fulladdress && (
              <div className="error">
                <label className="is-invalid">
                  Please input venue name first
                </label>
              </div>
            )}
          </div>

          <div className="form-group col-md-6">
            <div className="mb10">Company Name</div>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Input Company Name"
                value={vaccinationData.companyName}
                onChange={(e) =>
                  setVaccinationData({
                    ...vaccinationData,
                    companyName: e.target.value,
                  })
                }
              />
            </div>
            {errors.companyName && (
              <div className="error">
                <label className="is-invalid">
                  Please input company name first
                </label>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <div className="mb10">Start Date</div>
            <div className="input-group">
              <input
                type="text"
                className="form-control dateInput"
                placeholder="Select Date"
                min={moment().format("YYYY-MM-DD")}
                value={vaccinationData.startDate}
                onFocus={(e) => {
                  e.currentTarget.type = "date";
                  e.currentTarget.focus();
                }}
                onChange={(e) =>
                  setVaccinationData({
                    ...vaccinationData,
                    startDate: e.target.value,
                  })
                }
              />
            </div>
            {errors.startDate && (
              <div className="error">
                <label className="is-invalid">Please select date first</label>
              </div>
            )}
          </div>

          <div className="form-group col-md-6">
            <div className="mb10">End Date</div>
            <div className="input-group">
              <input
                type="text"
                className="form-control dateInput"
                placeholder="Select Date"
                min={moment().format("YYYY-MM-DD")}
                value={vaccinationData.endDate}
                onFocus={(e) => {
                  e.currentTarget.type = "date";
                  e.currentTarget.focus();
                }}
                onChange={(e) =>
                  setVaccinationData({
                    ...vaccinationData,
                    endDate: e.target.value,
                  })
                }
              />
            </div>
            {errors.endDate && (
              <div className="error">
                <label className="is-invalid">Please select date first</label>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <div className="mb10">Company Logo</div>
            <div className="input-group">
              <div className="input-group-prepend">
                <img
                  name="logo"
                  src={
                    vaccinationData.logo.length !== 0
                      ? URL.createObjectURL(vaccinationData.logo)
                      : "/img/icon/ic_camera.png"
                  }
                  style={{
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleView(e)}
                />
              </div>
              <Form.File
                custom
                label={fileName.logoName}
                accept="image/*"
                data-browse="Drag & Drop or Browse"
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    setFileName({
                      ...fileName,
                      logoName: e.target.files[0].name,
                    });
                    setVaccinationData({
                      ...vaccinationData,
                      logo: e.target.files[0],
                    });
                  } else {
                    setFileName({
                      ...fileName,
                      logoName: "Upload Image",
                    });
                    setVaccinationData({
                      ...vaccinationData,
                      logo: [],
                    });
                  }
                }}
              />
            </div>
            <div className="mt10">
              <label>
                Please upload 1080x1080px resolution photo (JPG or PNG) and less
                than 1MB
              </label>
            </div>
            {errors.logo && (
              <div className="error">
                <label className="is-invalid">Please choose image first</label>
              </div>
            )}
          </div>

          <div className="form-group col-md-6">
            <div className="mb10">Company Banner</div>
            <div className="input-group">
              <div className="input-group-prepend">
                <img
                  name="banner"
                  src={
                    vaccinationData.banner.length !== 0
                      ? URL.createObjectURL(vaccinationData.banner)
                      : "/img/icon/ic_camera.png"
                  }
                  style={{
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleView(e)}
                />
              </div>
              <Form.File
                custom
                label={fileName.bannerName}
                accept="image/*"
                data-browse="Drag & Drop or Browse"
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    setFileName({
                      ...fileName,
                      bannerName: e.target.files[0].name,
                    });
                    setVaccinationData({
                      ...vaccinationData,
                      banner: e.target.files[0],
                    });
                  } else {
                    setFileName({
                      ...fileName,
                      bannerName: "Upload Image",
                    });
                    setVaccinationData({
                      ...vaccinationData,
                      banner: [],
                    });
                  }
                }}
              />
            </div>
            <div className="mt10">
              <label>
                Please upload 1080x576px resolution photo (JPG or PNG) and less
                than 1MB
              </label>
            </div>
            {errors.banner && (
              <div className="error">
                <label className="is-invalid">Please choose image first</label>
              </div>
            )}
          </div>
        </div>

        <div className="row justify-content-end mt-5">
          <div className="col col-md-2">
            <Link to="/my-vaccination-event">
              <button
                type="button"
                className="btn btn-block btn-outline-primary"
              >
                Cancel
              </button>
            </Link>
          </div>
          <div className="col col-md-2">
            <button
              type="button"
              onClick={actionVaccinationData}
              className="btn btn-block btn-primary"
              style={{ width: "100%" }}
            >
              Next Step
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <DashboardLayout>
      {loading ? (
        <div className="table-wrapper">
          <Loading />
        </div>
      ) : error ? (
        error.message
      ) : (
        <>
          <div className="row revenue-title">
            <div className="col">
              <hr />
            </div>

            <div>Create Vaksinasi Nasional</div>

            <div className="col">
              <hr />
            </div>
          </div>

          <div className="table-wrapper">
            <div className="alert alert-info" style={{ textAlign: "center" }}>
              <p style={{ fontSize: "15px", marginBottom: "10px" }}>
                Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
                aido, silahkan hubungi aido support dengan click chat aido
                support.
                <br />
                <a
                  href="https://wa.me/628118481436"
                  style={{ color: "#51BED3" }}
                  target="blank"
                >
                  Chat Aido Support
                </a>
              </p>
            </div>

            {activeForm === 0 && renderVaccinationData()}
            {activeForm === 1 && renderPreviewData()}
          </div>
        </>
      )}
      {renderViewLogo()}
      {renderViewBanner()}
    </DashboardLayout>
  );
};

export default AddNasional;
