import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOrderVaccine,
  updateVaccineNote,
  updateOrderVaccine,
} from "../../../../actions";
import DashboardLayout from "../../Layout";
import Modal from "../../Components/Modal";
import Pagination from "../../../../components/Pagination";
import Swal from "sweetalert2";
import moment from "moment";
import queryString from "query-string";

const OrderVaccine = () => {
  const parentId = localStorage.getItem("parentId");
  const scheduleDate = moment(Date()).format("YYYY-MM-DD");
  const [dataTarget, setDataTarget] = useState("");
  const [search, setSearch] = useState(
    `parentId=${parentId}&scheduleDate=${scheduleDate}`
  );
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const [noteModal, setNoteModal] = useState(false);
  const [note, setNote] = useState(null);
  const searchKey = useRef("");
  const status = useRef("");
  const orderVaccine = useSelector((state) => state.orderVaccine);
  const dispatch = useDispatch();

  const { loading, error, orders, totalData } = orderVaccine;

  useEffect(() => {
    dispatch(getAllOrderVaccine(search));
  }, [search]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: totalData,
    });
  }, [totalData]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const handleReset = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      parentId: parentId,
      page: pagination.page,
      limit: pagination.limit,
      scheduleDate: scheduleDate,
    };

    if (searchKey.current.value !== "") {
      searchKey.current.value = "";
    }
    if (status.current.value !== "") {
      status.current.value = "";
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleSearch = (page, limit) => {
    let values = {
      parentId: parentId,
      scheduleDate: scheduleDate,
    };

    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }
    if (limit) {
      values.limit = limit;
    }
    if (searchKey.current.value !== "") {
      values.searchKey = searchKey.current.value;
    }
    if (status.current.value !== "") {
      values.status = status.current.value;
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleCloseModal = () => {
    setNoteModal(false);
  };

  const updateNote = () => {
    const formData = new FormData();

    formData.append("orderId", dataTarget);
    formData.append("note", note);
    if (
      formData.get("orderId") !== "" &&
      (formData.get("note") !== "" || formData.get("note") !== null)
    ) {
      Swal.fire({
        title: "Reconfirmation",
        text: "Apakah anda yakin ingin mengubah note?",
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#7cb342",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          dispatch(updateVaccineNote(dataTarget, formData, search)).then(
            (res) => {
              if (res) {
                dispatch(getAllOrderVaccine());
                console.log("m");
              }
              Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: false,
              }).fire({
                icon: "success",
                title: "Update Note Success",
              });
            }
          );
          setNoteModal(false);
          setDataTarget("");
          setNote(null);
        }
      });
    }
  };

  const changeStatus = (id, status) => {
    const formData = new FormData();
    formData.append("orderId", id);
    let message = "";
    switch (status) {
      case 102:
        formData.append("status", status);
        message = "Check In Success";
        break;
      case 99:
        formData.append("status", status);
        message = "Check Out Success";
        break;
      case 89:
        formData.append("status", status);
        message = "Cancel Order Vaccine Success";
        break;
    }

    if (
      formData.get("orderId") !== "" &&
      (formData.get("status") == 102 ||
        formData.get("status") == 99 ||
        formData.get("status") == 89)
    ) {
      Swal.fire({
        title: "Reconfirmation",
        text: `Apakah anda yakin ingin mengubah status order vaccine dengan id ${id}?`,
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#7cb342",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          dispatch(updateOrderVaccine(formData, search)).then((res) => {
            if (res) {
              dispatch(getAllOrderVaccine());
            }
            Swal.fire({
              icon: "success",
              text: message,
              timer: 3000,
            });
          });
        }
      });
    }
  };

  const dataTables = () => {
    return loading ? (
      <tr>
        <td colSpan="12">
          <Loading />
        </td>
      </tr>
    ) : error ? (
      error.message
    ) : (
      <>
        {orders.length > 0 ? (
          orders.map((item, index) => (
            <tr key={item.orderId}>
              <td>{(pagination.page - 1) * 10 + index + 1}</td>
              <td>{item.orderId ? item.orderId : "-"}</td>
              <td>{item.name ? item.name : "-"}</td>
              <td>{item.phone ? item.phone : "-"}</td>
              <td>
                {item.scheduleDate
                  ? moment(item.scheduleDate).format("LL")
                  : "-"}
              </td>
              <td>
                {item.startTime
                  ? moment(item.startTime, "HH:mm:ss").format("HH:mm")
                  : "-"}
              </td>
              <td>
                {item.skrining_url.split("?")[1] === undefined ? (
                  <Link
                    to={"/vaksin-form/" + item.orderId}
                    className="link"
                    style={{ color: "#007bff" }}
                    target="_blank"
                  >
                    Click here
                  </Link>
                ) : (
                  <Link
                    to={
                      "/vaksin-form/" +
                      item.orderId +
                      "?" +
                      item.skrining_url.split("?")[1]
                    }
                    className="link"
                    style={{ color: "#007bff" }}
                    target="_blank"
                  >
                    Click here
                  </Link>
                )}
              </td>
              <td>
                {item.birthday
                  ? `${moment(item.birthday).format("LL")} (${
                      new Date().getFullYear() -
                      new Date(item.birthday).getFullYear()
                    })`
                  : "-"}
              </td>
              <td>{item.email ? item.email : "-"}</td>
              <td>{item.description ? item.description : "-"}</td>
              <td>
                <div style={{ textAlign: "center" }}>
                  <div
                    className="btn-group btn-group-justified blocks"
                    role="group"
                    aria-label="Basic example"
                  >
                    {item.status === 100 ? (
                      <a href="#" title="print inactive">
                        <button
                          type="button"
                          className="btn btn-outline-dark btn-block"
                          disabled
                        >
                          Print
                        </button>
                      </a>
                    ) : item ? (
                      <Link
                        to={"/vaksin-print/" + item.orderId}
                        target="_blank"
                      >
                        <button
                          type="button"
                          className="btn btn-outline-success btn-block"
                        >
                          Print
                        </button>
                      </Link>
                    ) : (
                      <a href="#" title="print inactive">
                        <button
                          type="button"
                          className="btn btn-outline-dark btn-block"
                          disabled
                        >
                          Print
                        </button>
                      </a>
                    )}
                    <a data-toggle="modal" data-target="#editNoteModal">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-block"
                        onClick={() => {
                          setDataTarget(item.orderId);
                          setNoteModal(true);
                          setNote(item.note);
                        }}
                      >
                        Note
                      </button>
                    </a>
                    <a>
                      <button
                        type="button"
                        className="btn btn-outline-info btn-block"
                        onClick={() => {
                          changeStatus(item.orderId, 102);
                        }}
                      >
                        CheckIn
                      </button>
                      <input type="hidden" value="102" name="status" />
                    </a>
                  </div>
                  <div
                    className="btn-group btn-group-justified blocks"
                    role="group"
                    aria-label="Basic example"
                  >
                    <a>
                      <button
                        type="button"
                        className="btn btn-outline-warning btn-block"
                        onClick={() => {
                          changeStatus(item.orderId, 99);
                        }}
                      >
                        Checkout
                      </button>
                    </a>
                    <a>
                      <button
                        type="button"
                        className="btn btn-outline-danger btn-block"
                        onClick={() => {
                          changeStatus(item.orderId, 89);
                        }}
                      >
                        Cancel
                      </button>
                    </a>
                  </div>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="13">Empty data</td>
          </tr>
        )}
      </>
    );
  };

  return (
    <DashboardLayout>
      <Fragment>
        <div className="row revenue-title">
          <div className="col">
            <hr />
          </div>
          <div>Order Vaccine</div>
          <div className="col">
            <hr />
          </div>
        </div>

        <div className="table-wrapper">
          <div className="alert alert-info" style={{ textAlign: "center" }}>
            <p style={{ fontSize: "15px", marginBottom: "10px" }}>
              Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
              aido, silahkan hubungi aido support dengan click chat aido
              support.
              <br />
              <a
                href="https://wa.me/628118481436"
                style={{ color: "#51BED3" }}
                target="blank"
              >
                Chat Aido Support
              </a>
            </p>
          </div>

          <div className="row">
            <div className="col-md-8">
              <span id="finance-title" className="mb10">
                Order Vaccine
              </span>
            </div>
            <div className="col-md-4">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control table-search-field"
                  placeholder="Search Patient Name"
                  ref={searchKey}
                />
                <button
                  type="button"
                  className="btn btn-primary table-btn-search"
                  onClick={() => handleSearch()}
                >
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="row mb15">
            <div className="link-btn">
              <button
                type="button"
                className="btn btn-link no-padding"
                style={{ textDecoration: "none", boxShadow: "none" }}
                data-toggle="collapse"
                data-target="#collapseDetailSchedule"
              >
                Filter By
                <i
                  className="fa fa-angle-down"
                  style={{ marginLeft: "10px" }}
                />
              </button>
            </div>
            <div className="col">
              <hr />
            </div>
          </div>

          <div id="collapseDetailSchedule" className="collapse">
            <div id="filterForm" className="row filter-by">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4 mt-auto">
                    <div
                      className="mb10"
                      style={{ fontWeight: "bold" }}
                    >{`Schedule Date (Today) ${moment(Date()).format(
                      "LL"
                    )}`}</div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb10">Status</div>
                    <select className="form-control" ref={status}>
                      <option value="">All</option>
                      <option value="102">Checkin Successfully</option>
                      <option value="101">Skrining Ready To Print</option>
                      <option value="100">Promise Confirmed</option>
                      <option value="99">Checkout Successfully</option>
                      <option value="89">Canceled</option>
                    </select>
                  </div>

                  <div className="col-md-4 mt-auto">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      style={{ width: "100px", marginRight: "10px" }}
                      onClick={() => handleReset()}
                    >
                      Reset
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ width: "100px" }}
                      onClick={() => handleSearch()}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <hr />
            </div>
          </div>

          <div className="table-responsive">
            <table
              id="tableScheduler"
              className="table table-striped table-bordered"
            >
              <thead className="table-header">
                <tr>
                  <th
                    rowSpan={2}
                    className="table-header-middle"
                    style={{ width: "45px" }}
                  >
                    No
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Order ID
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Patient Name
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Phone
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Schedule Date
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Start Time
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Skrining Url
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Birthday (Age)
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Email
                  </th>

                  <th rowSpan={2} className="table-header-middle">
                    Status
                  </th>

                  <th rowSpan={2} className="header-action table-header-middle">
                    Action
                  </th>
                </tr>
              </thead>

              <tbody>{dataTables()}</tbody>
            </table>
          </div>

          <div style={{ display: "flex" }}>
            <div
              className="hidden-total text-left"
              style={{
                fontWeight: "bold",
                marginTop: "10px",
                marginRight: "auto",
              }}
            >{`Total Records ${totalData ? totalData : "-"}`}</div>

            {totalData > 10 ? (
              <Pagination
                props={pagination}
                setProps={setPagination}
                onChange={(page, limit) => {
                  handleSearch(page, limit);
                }}
              />
            ) : null}
          </div>

          <Modal
            modalTitle="Note"
            show={noteModal}
            size="md"
            handleClose={() => handleCloseModal()}
            onSubmit={updateNote}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="orderId">Order ID</label>
                  <input
                    className="form-control"
                    type="text"
                    id="orderIdNote"
                    name="orderId"
                    value={dataTarget}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="note">Note</label>
                  <textarea
                    className="form-control"
                    name="note"
                    id="orderDetailNote"
                    placeholder="Input Note"
                    rows="3"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </Fragment>
    </DashboardLayout>
  );
};

export default OrderVaccine;
