/* this function will help convert string data formatted data using js number format 
    @param data : string
*/

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });


export function formatCurrency(data){
    return `${formatter.format(data)}`
}