import { scheduleVaccineConstants } from "../actions/constants";

const initState = {
  loading: false,
  error: null,
  schedules: [],
  totalData: null,
  message: "",
  dataError: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case scheduleVaccineConstants.GET_ALL_SCHEDULE_VACCINE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case scheduleVaccineConstants.GET_ALL_SCHEDULE_VACCINE_SUCCESS:
      return {
        ...state,
        schedules: action.payload.schedules,
        totalData: action.payload.totalData,
        loading: false,
      };
    case scheduleVaccineConstants.GET_ALL_SCHEDULE_VACCINE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case scheduleVaccineConstants.IMPORT_SCHEDULE_VACCINE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case scheduleVaccineConstants.IMPORT_SCHEDULE_VACCINE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.msg,
        dataError: action.payload.dataError
      };
    case scheduleVaccineConstants.IMPORT_SCHEDULE_VACCINE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case scheduleVaccineConstants.CLEAR_IMPORT_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        dataError: action.payload.dataError,
      };
    case scheduleVaccineConstants.DELETE_SCHEDULE_VACCINE_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case scheduleVaccineConstants.DELETE_SCHEDULE_VACCINE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case scheduleVaccineConstants.DELETE_SCHEDULE_VACCINE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case scheduleVaccineConstants.MULTIPLE_DELETE_SCHEDULE_VACCINE_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case scheduleVaccineConstants.MULTIPLE_DELETE_SCHEDULE_VACCINE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case scheduleVaccineConstants.MULTIPLE_DELETE_SCHEDULE_VACCINE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    default: 
      return state;
  }
};
