import axios from "../helpers/axios";
import { pharmacyDeliveryDoneConstants } from "./constants";

export const getPharmacyDeliveryDone = (search) => {
  return async (dispatch) => {
    dispatch({
      type: pharmacyDeliveryDoneConstants.GET_PHARMACY_DONE_REQUEST,
    });
    const res = await axios.get(`/Order/getPharmacyDeliveryDone?${search}`);
    if (res.status === 200) {
      dispatch({
        type: pharmacyDeliveryDoneConstants.GET_PHARMACY_DONE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: pharmacyDeliveryDoneConstants.GET_PHARMACY_DONE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getDetailPharmacyDeliveryDone = (id, partnerId) => {
  return async (dispatch) => {
    dispatch({
      type: pharmacyDeliveryDoneConstants.GET_DETAIL_PHARMACY_DONE_REQUEST,
    });
    const res = await axios.get(
      `/Order/getDetailPharmacyDeliveryDone/${id}?${partnerId}`
    );
    if (res.status === 200) {
      dispatch({
        type: pharmacyDeliveryDoneConstants.GET_DETAIL_PHARMACY_DONE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: pharmacyDeliveryDoneConstants.GET_DETAIL_PHARMACY_DONE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
