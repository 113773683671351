import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import {
    updateOrderProducts,
    createOrderProducts,
    updateStatusOrderProducts,
    createMedicalRecords,
    uploadConsentFile,
    uploadPharmacyFile,
    uploadOrderGallery,
} from "../../../../actions";

// Style
import "../orderHistory.css";

import Swal from "sweetalert2";

export default function ViewDetailHistoryDone({state, detailDataVal, result}) {
    const dispatch = useDispatch(); 
    const [formdataVal, setFormdataVal] = useState({
        complaints: "",
        analysis: "",
        carePlan: "",
        referenceToHospitalNote: "",
        additionalFee: "",
        additionalFeeNote: "",
        pharmacyNote:"",
    });

    const partnerData = detailDataVal.partner != undefined ?  detailDataVal.partner : [];
    const partnerId = partnerData.length != 0 ? partnerData.id : "";
    const [orderGalleryUrl, setorderGalleryUrl] = useState([]);

    //Next Status
    const totalFee = detailDataVal.totalFee != undefined ?  detailDataVal.totalFee : null;
    const orderStatusData = detailDataVal.orderStatus != undefined ?  detailDataVal.orderStatus : [];
    const statusVal = orderStatusData.length != 0 ?  orderStatusData.code : null;
    const nextStatusData = orderStatusData.length != 0 ?  orderStatusData.nextStatus : [];
    const nextStatusDesc = orderStatusData.code == 14 ? 
                                totalFee > 0 ? nextStatusData.find(code => code.code == "98") : 
                                nextStatusData.find(code => code.code == "99") 
                            : null;
    const nextStatusVal = statusVal != null ? 
                            statusVal == 99 ? "Pesanan Selesai" : 
                                statusVal == 98 ? "Menunggu Pembayaran" :
                                statusVal == 14 ? nextStatusDesc.description : nextStatusData[0].description
                            : "";
    
    //Chat
    const custUserData = partnerData.length != 0 ? partnerData.user : [];
    const userId = custUserData.length != 0 ? custUserData.id : 0;
    const sendbirdToken = custUserData.length != 0 ? custUserData.sendbirdToken : 0;
    const channelURL = detailDataVal.channelUrl != undefined ? detailDataVal.channelUrl : " ";
    const urlChat = `${process.env.REACT_APP_CHAT_URL}chat.html?userid=${userId}&userToken=${sendbirdToken}&chatUrl=${channelURL}`

    //getData 
    const complaints = detailDataVal.complaints != undefined ? detailDataVal.complaints : "";
    const medRecordData = detailDataVal.medicalRecord != undefined ? detailDataVal.medicalRecord : [];
    const analysis = medRecordData.analysis != undefined ? medRecordData.analysis : "";
    const carePlan = medRecordData.carePlan != undefined ? medRecordData.carePlan : "";
    const referenceToHospitalNote = medRecordData.referenceToHospitalNote != undefined ?  medRecordData.referenceToHospitalNote : "";
    const additionalFee = medRecordData.additionalFee != undefined ? parseFloat(medRecordData.additionalFee) : "";
    const additionalFeeNote = medRecordData.additionalFeeNote != undefined ? medRecordData.additionalFeeNote : "";
    const pharmacyNote = medRecordData.pharmacyNote != undefined ? medRecordData.pharmacyNote : "";
    var consentPhotoVal = medRecordData.consentPhoto != undefined ? medRecordData.consentPhoto : null;
    const pharmacyPhotoVal = medRecordData.pharmacyPhoto != undefined ? medRecordData.pharmacyPhoto : null;
    const getOrderGallery = detailDataVal.orderGallery != undefined ? detailDataVal.orderGallery : [];

    const prevPageUrl = "/order-history";
    const nextPageRiwayatUrl = "/order-history/detail/riwayat";
    const nextPageLayananUrl = "/order-history/detail/layanan";
    const nextPageLampiranFotoUrl = "/order-history/detail/orderGallery";
    const stateVal = { "id": state.id, "statusCode": statusVal, "detailData": detailDataVal, 
                       "orderGallery" : statusVal != 98 && statusVal != 99 ? orderGalleryUrl : getOrderGallery};

    const selectedProcedureDataVal = JSON.parse(localStorage.getItem("selectedProcedureData"));
    const selectedMedicalDataVal = JSON.parse(localStorage.getItem("selectedMedicalData"));

    useEffect(() => {
        document.getElementById("btnSVGeneral").click();
        setFormdataVal({
            complaints: complaints,
            analysis: analysis,
            carePlan: carePlan,
            referenceToHospitalNote: referenceToHospitalNote,
            additionalFee: additionalFee,
            additionalFeeNote: additionalFeeNote,
            pharmacyNote: pharmacyNote
        });

        if(result.urlGallery.url != undefined && state.orderGallery == undefined){
            setorderGalleryUrl(orderGalleryUrl => [...orderGalleryUrl, {"imgUrl": result.urlGallery.url}]);
        }

        if(state.orderGallery != undefined){
            setorderGalleryUrl(orderGalleryUrl => state.orderGallery);
        }

    }, [result.urlGallery]);

    const uploadDocumentConsent = () => {
        var uploadconsent = document.getElementById("uploadconsent").files;
        dispatch(uploadConsentFile(state.id, uploadconsent[0]));           
    };

    const uploadDocumentResep = () => {
        var uploadresep = document.getElementById("uploadresep").files;
        dispatch(uploadPharmacyFile(state.id, uploadresep[0]));
    };

    const uploadDocumentGallery = () => {
        var ordergallery = document.getElementById("ordergallery").files;
        for(var i = 0; i < ordergallery.length; i++){
            dispatch(uploadOrderGallery(state.id, ordergallery[i]))
        }        
    };

    const openPage = (pageName) => {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent1");
        for (i = 0; i < tabcontent.length; i++) {
          tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablink1");
        for (i = 0; i < tablinks.length; i++) {
          tablinks[i].style.backgroundColor = "#38a8ff";
          tablinks[i].style.borderBottom = "4px solid #38a8ff";
        }
        document.getElementById(pageName).style.display = "block";
        var el = "btn"+pageName;
        document.getElementById(el).style.borderBottom = "4px solid rgba(255, 255, 255, 0.377)";
    };

    const onSubmitData = () => {
        if(statusVal == 99){
            return Swal.fire({
                title: "Pesanan Selesai!",
                icon: "success",
                confirmButtonText: "OK",
                confirmButtonColor: "#7cb342",
            }).then((result) => {
                if (result.value) {
                    // document.getElementById("redirectPage").click();
                    window.location.replace("/order-history");
                } 
            });
        }
        else if(statusVal == 98){
            return Swal.fire({
                title: "Menunggu Pembayaran!",
                icon: "success",
                confirmButtonText: "OK",
                confirmButtonColor: "#7cb342",
            }).then((result) => {
                if (result.value) {
                    // document.getElementById("redirectPage").click();
                    window.location.replace("/order-history");
                } 
            });
        }

        var tfKeluhan = document.getElementById("tfKeluhan").value;
        var tfPemeriksaanFisik = document.getElementById("tfPemeriksaanFisik").value;
        var tfDiagnosa = document.getElementById("tfDiagnosa").value;
        var tfRencanaPerawatan = document.getElementById("tfRencanaPerawatan").value;
        var nfFee = document.getElementById("nfFee").value;
        var tfadditionalFeeNote = document.getElementById("tfadditionalFeeNote").value;
        var tfPharmacyNote = document.getElementById("tfPharmacyNote").value;

        const consentPhoto = result.urlConsent != undefined ? result.urlConsent : [];
        const pharmacyPhoto = result.urlPharmacy != undefined ? result.urlPharmacy : [];

        var uploadconsent = consentPhoto.url != undefined ? consentPhoto.url : "";
        var uploadresep = pharmacyPhoto.url != undefined ? pharmacyPhoto.url : "";
        var orderGalleryData = [];

        for(var i = 0; i < orderGalleryUrl.length; i++){
            orderGalleryData.push(
                { "imgUrl": orderGalleryUrl[i].imgUrl,  "partner_id": partnerId, "order_id": state.id}
            )
        }

        if(tfKeluhan == "" || tfPemeriksaanFisik == "" || tfDiagnosa == "" || tfRencanaPerawatan == ""){
            return Swal.fire({
                icon: 'warning',
                text: 'Please fill required field!',
            })
        }

        const selectedProcedureData = selectedProcedureDataVal != undefined ? selectedProcedureDataVal : [];
        const selectedMedicalData = selectedMedicalDataVal != undefined ? selectedMedicalDataVal : [];

        var productData = [...selectedProcedureData, ...selectedMedicalData];

        if(selectedProcedureData.length != 0 || selectedMedicalData.length != 0){
            dispatch(updateOrderProducts(state.id, JSON.stringify(productData)));

            for(var i = 0; i < productData.length; i++){
                var productName = productData[i].productName;
                dispatch(createOrderProducts(productName));
            }
        }

        const formData = new FormData();
            formData.append("additionalFee", parseFloat(nfFee));
            formData.append("additionalFeeNote", tfadditionalFeeNote);
            formData.append("labReference", "");
            formData.append("complaints", tfKeluhan);
            formData.append("analysis", tfPemeriksaanFisik);
            formData.append("carePlan", tfDiagnosa);
            formData.append("referenceToHospitalNote", tfRencanaPerawatan);
            formData.append("isReferenceToHospital", false);
            formData.append("isPharmacyDelivery", false);
            formData.append("isLabAtHome", false);
            formData.append("orderGallery", JSON.stringify(orderGalleryData));
            formData.append("consentPhoto", uploadconsent);
            formData.append("pharmacyPhoto", uploadresep);
            formData.append("pharmacyNote", tfPharmacyNote);
            formData.append("status", statusVal);

        dispatch(createMedicalRecords(state.id, formData)).then(
            Swal.fire({
                title: nextStatusVal+"!",
                icon: "success",
                confirmButtonText: "OK",
                confirmButtonColor: "#7cb342",
            }).then((result) => {
                if (result.value) {
                    localStorage.removeItem("selectedProcedureData");
                    localStorage.removeItem("selectedMedicalData");
                    // document.getElementById("redirectPage").click();
                    window.location.replace("/order-history");
                } 
            })
        );
    };

    return (
            <div className="col-12" >
                <div className="card-detail" style={{background:"#38a8ff", margin:"30px 0px 0px 0px", borderRadius: "10px 10px 0px 0px"}}>
                    <a href={prevPageUrl}>
                        <i className="fa fa-arrow-left" style={{fontSize: "18px", color: "white"}} aria-hidden="true" /> 
                    </a>
                    <span style={{margin: "0px 30px 0px 30px", color: "white", fontSize: "16px"}}> 
                        Summary Visit
                    </span>
                    <label htmlFor="ordergallery">
                        <i className="fa fa-camera" aria-hidden="true" hidden = {statusVal != 98 && statusVal != 99 ? false : true}
                        style={{fontSize :"18px", fontWeight: 'bolder', color:'white', top: 16, right: 70, position: 'absolute', cursor: 'pointer'}}/>
                    </label>
                    <input id="ordergallery" type="file" onChange={() => uploadDocumentGallery()} 
                        accept="image/png, image/gif, image/jpeg" style={{display: "none"}}
                        multiple />
                    
                    <i className="material-icons" aria-hidden="true" onClick={() => window.open(urlChat)}
                        hidden = {statusVal != 98 && statusVal != 99 ? false : true}
                        style={{fontSize :"20px", fontWeight: 'bolder', color:'white', right: 33, position: 'absolute', cursor: 'pointer'}}> 
                    &#xe0b7;</i> 
                </div>

                <button className="tablink1" id="btnSVGeneral" onClick={() => {openPage('SVGeneral');}}>General</button>
                <button className="tablink1" id="btnSVResep" onClick={() => {openPage('SVResep');}}>Resep</button>

                <div id="SVGeneral" className="tabcontent1">
                    <div className="card-detail" hidden = {statusVal != 98 && statusVal != 99 ? false : true}>
                        <Link to = {{ pathname: nextPageRiwayatUrl, state: stateVal }}>
                                <i className="fa fa-file-text-o" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                                <span style={{margin: "0px 30px 0px 10px"}}> Lihat Riwayat Kesehatan</span>
                                <i className="fa fa-angle-right" aria-hidden="true" 
                                    style={{fontSize :"22px", fontWeight: 'bolder', right: 33, position: 'absolute'}} /> 
                        </Link>
                    </div>
                    <div className="card-detail">
                        <div>
                            <i className="fa fa-heart" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                            <span style={{margin: "0px 10px 0px 10px"}}> Keluhan <span style={{color:"red", fontSize:"16px"}}> *</span></span>
                        </div>
                        <div style={{fontSize: '16px', margin: '10px 10px 10px 10px'}}>
                            <textarea placeholder = {statusVal != 98 && statusVal != 99 ? "Masukkan keluhan" : ""}
                                id="tfKeluhan" 
                                onChange={(e) => 
                                    setFormdataVal({
                                    ...formdataVal,
                                    complaints: e.target.value,
                                })}
                                style={{padding: "10px", height: "100px", width: "100%", border: "1px solid lightgrey"}}
                                readOnly = {statusVal != 98 && statusVal != 99 ? false : true} 
                                value = {formdataVal.complaints} />
                        </div>
                    </div>

                    <div className="card-detail">
                        <div>
                            <i className="fa fa-heart" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                            <span style={{margin: "0px 10px 0px 10px"}}> Pemeriksaan Fisik <span style={{color:"red", fontSize:"16px"}}> *</span></span>
                        </div>
                        <div style={{fontSize: '16px', margin: '10px 10px 10px 10px'}}>
                            <textarea placeholder = {statusVal != 98 && statusVal != 99 ? "Kemampuan saat ini" : ""}
                                id="tfPemeriksaanFisik" 
                                onChange={(e) => 
                                    setFormdataVal({
                                    ...formdataVal,
                                    analysis: e.target.value,
                                  })}
                                style={{padding: "10px", height: "100px", width: "100%", border: "1px solid lightgrey"}}
                                readOnly = {statusVal != 98 && statusVal != 99 ? false : true} 
                                value = {formdataVal.analysis} />
                        </div>
                    </div>

                    <div className="card-detail">
                        <div>
                            <i className="fa fa-heart" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                            <span style={{margin: "0px 10px 0px 10px"}}> Diagnosa <span style={{color:"red", fontSize:"16px"}}>*</span></span>
                        </div>
                        <div style={{fontSize: '16px', margin: '10px 10px 10px 10px'}}>
                            <textarea placeholder = {statusVal != 98 && statusVal != 99 ? "Masukkan diagnosa" : ""}
                                id="tfDiagnosa" 
                                onChange={(e) => 
                                    setFormdataVal({
                                    ...formdataVal,
                                    carePlan: e.target.value,
                                  })}
                                style={{padding: "10px", height: "100px", width: "100%", border: "1px solid lightgrey"}}
                                readOnly = {statusVal != 98 && statusVal != 99 ? false : true} 
                                value = {formdataVal.carePlan} />
                        </div>
                    </div>

                    <div className="card-detail">
                        <div>
                            <i className="fa fa-heart" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                            <span style={{margin: "0px 10px 0px 10px"}}> Rencana Perawatan <span style={{color:"red", fontSize:"16px"}}> *</span></span>
                        </div>
                        <div style={{fontSize: '16px', margin: '10px 10px 10px 10px'}}>
                            <textarea placeholder = {statusVal != 98 && statusVal != 99 ? "Masukkan rencana perawatan" : ""}
                                id="tfRencanaPerawatan" 
                                onChange={(e) => 
                                    setFormdataVal({
                                    ...formdataVal,
                                    referenceToHospitalNote: e.target.value,
                                  })}
                                style={{padding: "10px", height: "100px", width: "100%", border: "1px solid lightgrey"}}
                                readOnly = {statusVal != 98 && statusVal != 99 ? false : true} 
                                value = {formdataVal.referenceToHospitalNote} />
                        </div>
                    </div>
                    
                    <div className="card-detail" hidden = {statusVal != 98 && statusVal != 99 ? false : true} >
                        <label htmlFor="uploadconsent" className="btnUploadConsent" style={{fontSize: "12px"}}>
                            <center> <i className="fa fa-camera" style={{color: "rgb(236, 134, 151)", margin: "15px 10px 0px 0px"}}/> 
                                Ambil Foto Consent Form (jika ada) </center>
                        </label>
                        <input id="uploadconsent" type="file" style={{display: "none"}}
                            accept="image/png, image/gif, image/jpeg" 
                            onChange={() => uploadDocumentConsent()} />
                    </div>

                    <div className="card-detail" id="cardConsentPhoto" hidden = {statusVal != 98 && statusVal != 99 ? true : false}>
                        <div>
                            <i className="fa fa-file-text-o" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                            <span style={{margin: "0px 10px 0px 10px"}}> 
                                Consent Photo                               
                            </span>
                        </div>
                        <div style={{margin: '10px 10px 15px 10px'}}>
                            <img style={{height: 250, width: 250, alignSelf: 'stretch'}} src={consentPhotoVal}/>
                        </div>
                    </div>

                    <div className="card-detail" hidden = {statusVal != 98 && statusVal != 99 ? false : true}>
                        <Link to = {{ pathname: nextPageLayananUrl, state: stateVal }} >
                            <i className="fa fa-file-text-o" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                            <span style={{margin: "0px 30px 0px 10px"}}> Layanan Tambahan</span>
                            <i className="fa fa-angle-right" aria-hidden="true" 
                                style={{fontSize :"22px", fontWeight: 'bolder', right: 33, position: 'absolute'}} /> 
                        </Link>
                    </div>
                    <Link to = {{ pathname: nextPageLampiranFotoUrl, state: stateVal }}>
                        <div className="card-detail">
                                <i className="fa fa-camera" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                                <span style={{margin: "0px 30px 0px 10px"}}> Lampiran Foto</span>
                                <i className="fa fa-angle-right" aria-hidden="true" 
                                    style={{fontSize :"22px", fontWeight: 'bolder', right: 33, position: 'absolute'}} /> 
                        </div>
                    </Link>

                    <div className="card-detail">
                        <div>
                            <i className="fa fa-suitcase" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                            <span style={{margin: "0px 10px 0px 10px"}}> Biaya Tambahan </span>
                        </div>
                        <div style={{fontSize: '16px', margin: '10px 10px 10px 28px'}}>
                            <input type="number" placeholder = {statusVal != 98 && statusVal != 99 ? "Masukkan biaya tambahan" : ""}
                                id="nfFee" value = {formdataVal.additionalFee}
                                onChange={(e) => 
                                    setFormdataVal({
                                    ...formdataVal,
                                    additionalFee: e.target.value,
                                  })} 
                                style={{fontSize: '14px', height: "40px", width: "100%", border: "none", borderBottom: "1px solid lightgrey"}}
                                readOnly = {statusVal != 98 && statusVal != 99 ? false : true} />
                        </div>
                        <div style={{fontSize: '16px', margin: '10px 10px 10px 10px'}}>
                            <textarea placeholder = {statusVal != 98 && statusVal != 99 ? "Notes..." : ""}
                                id="tfadditionalFeeNote" 
                                onChange={(e) => 
                                    setFormdataVal({
                                    ...formdataVal,
                                    additionalFeeNote: e.target.value,
                                  })}
                                style={{padding: "10px", height: "100px", width: "100%", border: "1px solid lightgrey"}}
                                readOnly = {statusVal != 98 && statusVal != 99 ? false : true} 
                                value = {formdataVal.additionalFeeNote} />
                        </div>
                    </div>
                </div>

                <div id="SVResep" className="tabcontent1">
                    <div className="card-detail" hidden = {statusVal != 98 && statusVal != 99 ? false : true}>
                        <label htmlFor="uploadresep" className="btnUploadConsent">
                           <center> <i className="fa fa-camera" style={{color: "rgb(236, 134, 151)", margin: "15px 10px 0px 0px"}}/> 
                                Upload Resep </center>
                        </label>
                        <input id="uploadresep" onChange={() => uploadDocumentResep()}
                        type="file" accept="image/png, image/gif, image/jpeg" style={{display: "none"}} />
                    </div>
                    <div className="card-detail" hidden = {statusVal != 98 && statusVal != 99 ? true : false}>
                        <div>
                            <i className="fa fa-file-text-o" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                            <span style={{margin: "0px 10px 0px 10px"}}> 
                                Pharmacy Photo                               
                            </span>
                        </div>
                        <div style={{margin: '10px 10px 15px 10px'}}>
                            <img style={{height: 250, width: 250, alignSelf: 'stretch'}} src={pharmacyPhotoVal}/>
                        </div>
                    </div>
                    <div className="card-detail">
                        <div>
                            <i className="fa fa-file-text-o" style={{ fontSize: '18px'}} aria-hidden="true" /> 
                            <span style={{margin: "0px 10px 0px 10px"}}> Catatan </span>
                        </div>
                        <div style={{fontSize: '16px', margin: '10px 10px 10px 10px'}}>
                            <textarea placeholder = {statusVal != 98 && statusVal != 99 ? "Catatan..." : ""}
                                id="tfPharmacyNote" 
                                onChange={(e) => 
                                    setFormdataVal({
                                    ...formdataVal,
                                    pharmacyNote: e.target.value,
                                })} 
                                style={{padding: "10px", height: "100px", width: "100%", border: "1px solid lightgrey"}}
                                readOnly = {statusVal != 98 && statusVal != 99 ? false : true} 
                                value = {formdataVal.pharmacyNote} />
                        </div>
                    </div>
                </div>
                
                <button className="btnNextAction" 
                    onClick={() => onSubmitData()}
                    hidden = {statusVal != 98 && statusVal != 99 ? false : true}> 
                        {nextStatusVal} 
                </button>
                {/* <Link to={{ pathname: "/order-history/detail", state: {"id": state.id} }} id="redirectPage" /> */}
            </div>
    );
};