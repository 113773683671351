import React, { useState } from "react";
import Header from "./Header";
import Breadcrumb from "./Breadcrumb";
import "./layout.scss";
import SideMenu from "./SideMenu";
import Sidebar from "./Sidebar";

const Layout = (props) => {
  const [menuType, setMenuType] = useState(
    localStorage.getItem("menuType") || "0"
  );

  const role = JSON.parse(localStorage.getItem("user"));
  const data = [
    // Teleconsultation
    {
      role: "team_member_management_tele",
      label: "My Team Teleconsultation",
      path: "/my-team-tele",
      icon: "/img/icon/icon_menu/ic_doctor_disabled.png",
      childLabel: "Add Education",
      childPath: "/my-team-tele/education",
    },
    {
      role: "promo_teleconsultation",
      label: "My Promo Teleconsultation",
      path: "/my-promo-tele",
      icon: "/img/icon/icon_menu/ic_doctor_disabled.png",
    },
    {
      role: "schedule_teleconsultation",
      label: "My Schedule Teleconsultation",
      path: "/my-schedule-tele",
      icon: "/img/icon/icon_menu/ic_calendar_disabled.png",
    },
    {
      role: "completed_call_teleconsultation",
      label: "List Waiting for Teleconsultation",
      path: "/completed-call-teleconsultation",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
    },
    {
      role: "payment_report",
      label: "Payment Report",
      path: "/payment-report",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
    },
    {
      role: "order",
      label: "Reschedule Teleconsultation",
      path: "/re-schedule",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
    },
    {
      role: "waiting_invoice_teleconsultation",
      label: "Waiting for Cashier",
      path: "/waiting-invoice-teleconsultation",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
      childLabel: "Detail Waiting for Cashier",
      childPath: "/waiting-invoice-teleconsultation/detail",
    },
    {
      role: "pharmacy_offline",
      label: "Report Pharmacy Offline",
      path: "/report-pharmacy-offline",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
    },
    {
      role: "pharmacy_delivery_teleconsultation",
      label: "Pharmacy Delivery",
      path: "/pharmacy-delivery",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
      childLabel: "Detail Pharmacy Delivery",
      childPath: "/pharmacy-delivery/detail",
    },
    {
      role: "pharmacy_delivery_teleconsultation",
      label: "Pharmacy Waiting for Delivery",
      path: "/pharmacy-waiting-delivery",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
      childLabel: "Detail Pharmacy Waiting for Delivery",
      childPath: "/pharmacy-waiting-delivery/detail",
    },
    {
      role: "pharmacy_delivery_teleconsultation",
      label: "Pharmacy Delivery Done",
      path: "/pharmacy-delivery-done",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
      childLabel: "Detail Pharmacy Delivery Done",
      childPath: "/pharmacy-delivery-done/detail",
    },
    {
      role: "pharmacy_delivery_teleconsultation",
      label: "All Pharmacy Delivery Offline (Today)",
      path: "/pharmacy-delivery-all",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
    },
    {
      role: "pharmacy_delivery_teleconsultation",
      label: "Pharmacy Delivery Offline Canceled",
      path: "/pharmacy-delivery-all-canceled",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
    },

    {
      role: "pharmacy_delivery_teleconsultation",
      label: "Pharmacy Delivery Reporting",
      path: "/pharmacy-delivery/reporting",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
    },
    {
      role: "order_refund",
      label: "List Teleconsultation Refund",
      path: "/tele-refund",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
    },
    {
      role: "order_pharmacy",
      label: "List Order (Pharmacy)",
      path: "/order/tele/pharmacy",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
    },
    {
      role: "daily_report",
      label: "Daily Report",
      path: "/daily-report",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
    },
    {
      role: "order_by_id",
      label: "Search Order By ID",
      path: "/order-by-id",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
    },
    {
      role: "monthly_recap",
      label: "Monthly Report",
      path: "/monthly-report",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
    },
    {
      role: "order_tele_data",
      label: "List Teleconsultation",
      path: "/teleconsultation-all-data",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
    },
    {
      role: "order_pharmacy_delivery",
      label: "List Order Pharmacy",
      path: "/order/tele/all-pharmacy",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
      childLabel: "Detail - Pharmacy Order",
      childPath: "/order/tele/all-pharmacy/detail",
    },
    {
      role: "report_gds",
      label: "Pharmacy Delivery Reporting",
      path: "/pharmacy-delivery/reporting",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
    },

    // Homecare
    {
      role: "team_member_management",
      label: "My Team Homecare",
      path: "/my-team-homecare",
      icon: "/img/icon/icon_menu/ic_doctor_disabled.png",
      childLabel: "Add Education",
      childPath: "/my-team-homecare/education",
    },
    {
      role: "medical_supplies_management",
      label: "My Medical Supplies",
      path: "/medical-supplies",
      icon: "/img/icon/icon_menu/ic_menu_hospital_disabled.png",
    },
    {
      role: "procedure_management",
      label: "My Procedures",
      path: "/procedures",
      icon: "/img/icon/icon_menu/ic_menu_surgery_disabled.png",
    },
    {
      role: "register_single_management",
      label: "Register Order",
      path: "/register-order",
      icon: "/img/icon/icon_menu/ic_homecare_disable.png",
    },
    {
      role: "order_request",
      label: "Order Request",
      path: "/order-request",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
      childLabel: "Order Request Detail",
      childPath: "/order-request/detail",
      childLabel2: "Assign Partner",
      childPath2: "/order-request/assign",
    },
    {
      role: "order_list",
      label: "My Order",
      path: "/my-order",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
      childLabel: "My Order Detail",
      childPath: "/my-order/detail",
      childLabel2: "Reschedule Partner",
      childPath2: "/my-order/reschedule",
    },
    {
      role: "register_teleconsultation",
      label: "Register Teleconsultation",
      path: "/register-teleconsultation",
      icon: "/img/icon/icon_menu/ic_homecare_disable.png",
      childLabel: "Register New Data",
      childPath: "/register-teleconsultation/add",
    },
    {
      role: "order_list",
      label: "Order Daily Report",
      path: "/order-daily-report",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
    },

    // Dokter Worklist
    {
      role: "order_partner",
      label: "Dokter Worklist",
      path: "/dokter-worklist",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
    },

    // Vaccine
    {
      role: "vaccine_management",
      label: "My Vaccination Event",
      path: "/my-vaccination-event",
      icon: "/img/icon/icon_menu/ic_menu_syringe.svg",
      childLabel: "Vaccination Information",
      childPath: "/my-vaccination-event/detail",
    },
    {
      role: "vaccine_management",
      label: "Vaksinasi Nasional",
      path: "/my-vaccination-event/nasional",
      icon: "/img/icon/icon_menu/ic_menu_dot.png",
    },
    {
      role: "vaccine_management",
      label: "Vaksinasi Gotong Royong",
      path: "/my-vaccination-event/gotong-royong",
      icon: "/img/icon/icon_menu/ic_menu_dot.png",
    },
    {
      role: "schedule_vaccine",
      label: "Schedule Vaccine",
      path: "/schedule-vaccine",
      icon: "/img/icon/icon_menu/ic_calendar_disabled.png",
    },
    {
      role: "order_vaccine",
      label: "List Order (Vaccine)",
      path: "/vaksin",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
    },
    {
      role: "vaccine_daily_report",
      label: "Daily Report (Vaccine)",
      path: "/order-vaccine/daily-report",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
    },
    {
      role: "partner_management",
      label: "Order History",
      path: "/order-history",
      icon: "/img/icon/icon_menu/archive_disabled.png",
      childLabel: "Order Detail",
      childPath: "/order-history/detail",
    },
    {
      role: "register_teleconsultation",
      label: "Payment Offline",
      path: "/payment-offline",
      icon: "/img/icon/icon_menu/ic_homecare_disable.png",
      childLabel: "Register New Data",
      childPath: "/payment-offline/add",
    },
    {
      role: "register_teleconsultation",
      label: "Payment Offline Voucher",
      path: "/payment-offlinevoucher",
      icon: "/img/icon/icon_menu/ic_homecare_disable.png",
    },

    // GP Onsite
    {
      role: "gp_onsite",
      label: "Order GP Onsite",
      path: "/gp-onsite",
      icon: "/img/icon/icon_menu/ic_menu_dot.png",
    },
    {
      role: "gp_onsite",
      label: "Order GP Onsite - DONE",
      path: "/gp-onsite/done",
      icon: "/img/icon/icon_menu/archive_disabled.png",
    },
    
    // Health Screening
    {
      role: "health_screening",
      label: "Check-In Health Screening",
      path: "/CheckIn-HS",
      icon: "/img/icon/icon_menu/ic_menu_dot.png",
    },
    // Phd Monitoring
    {
      role: "pharmacy_delivery_monitoring",
      label: "Pharmacy Delivery",
      path: "/pharmacy-delivery",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
      childLabel: "Detail Pharmacy Delivery",
      childPath: "/pharmacy-delivery/detail",
    },
    {
      role: "pharmacy_delivery_monitoring",
      label: "Pharmacy Waiting for Delivery",
      path: "/pharmacy-waiting-delivery",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
      childLabel: "Detail Pharmacy Waiting for Delivery",
      childPath: "/pharmacy-waiting-delivery/detail",
    },
    {
      role: "pharmacy_delivery_monitoring",
      label: "Pharmacy Delivery Done",
      path: "/pharmacy-delivery-done",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
      childLabel: "Detail Pharmacy Delivery Done",
      childPath: "/pharmacy-delivery-done/detail",
    },
    {
      role: "pharmacy_delivery_monitoring",
      label: "All Pharmacy Delivery (Today)",
      path: "/pharmacy-delivery-all",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
    },
    {
      role: "pharmacy_delivery_monitoring",
      label: "Pharmacy Delivery Offline Canceled",
      path: "/pharmacy-delivery-all-canceled",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
    },
    {
      role: "pharmacy_delivery_monitoring",
      label: "Pharmacy Delivery All Unit",
      path: "/pharmacy-delivery-all-unit",
      icon: "/img/icon/icon_menu/graph_line_disabled.png",
      childLabel: "Detail Pharmacy All Unit",
      childPath: "/pharmacy-delivery-all-unit/detail",
    },
  ];

  const listMenu = data.filter((x) =>
    Object.keys(JSON.parse(role.user_role_management_id.route_group)).includes(
      x.role
    )
  );

  if (
    localStorage.getItem("parentId") == 3295 &&
    role.user_role_management_id.id == 27
  ) {
    listMenu.push({
      role: "medical_records", // Tele from aido only
      label: "Medical Records",
      path: "/medical-records",
      icon: "/img/icon/icon_menu/archive_disabled.png",
    });
    listMenu.unshift({
      role: "medical_records", // Tele from aido only
      label: "Medical Record Dashboard",
      path: "/dashboard",
      icon: "/img/icon/icon_menu/ic-dashboard-mr.png",
    });
  }

  return (
    <div className="wrapper">
      <p id="demo"></p>
      {menuType == "0" && (
        <nav id="sidebar" style={{ overflow: "auto" }}>
          <SideMenu listMenu={listMenu} />
        </nav>
      )}
      {menuType == "1" && (
        <nav id="sidemenu" style={{ overflow: "auto" }}>
          <Sidebar listMenu={listMenu} />
        </nav>
      )}
      <div id="content">
        <Header
          listMenu={listMenu}
          menuType={menuType}
          setMenuType={setMenuType}
        />
        <Breadcrumb listMenu={listMenu} />
        <div id="contentLoader" className="content-wrapper">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
