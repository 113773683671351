import axios from "../helpers/axios";
import { waitingInvoiceConstants } from "./constants";

export const getAllWaitingInvoice = (search) => {
  return async (dispatch) => {
    dispatch({ type: waitingInvoiceConstants.GET_ALL_WAITING_INVOICE_REQUEST });
    const res = await axios.get(
      `/Order/waitingInvoiceTeleconsultation?${search}`
    );
    if (res.status === 200) {
      dispatch({
        type: waitingInvoiceConstants.GET_ALL_WAITING_INVOICE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: waitingInvoiceConstants.GET_ALL_WAITING_INVOICE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getCompletedDetail = (id) => {
  return async (dispatch) => {
    dispatch({ type: waitingInvoiceConstants.GET_COMPLETED_DETAIL_REQUEST });
    const res = await axios.get(`/Order/completedCallTeleconsultation/${id}`);
    if (res.status === 200) {
      dispatch({
        type: waitingInvoiceConstants.GET_COMPLETED_DETAIL_SUCCESS,
        payload: { details: res.data },
      });
    } else {
      dispatch({
        type: waitingInvoiceConstants.GET_COMPLETED_DETAIL_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const uploadInvoiceBilling = (id, form) => {
  return async (dispatch) => {
    dispatch({ type: waitingInvoiceConstants.UPLOAD_INVOICE_BILLING_REQUEST });
    const res = await axios.put(
      `/Order/waitingInvoiceTeleconsultation/uploadBilling/${id}`,
      form
    );
    if (res.status === 200) {
      dispatch({
        type: waitingInvoiceConstants.UPLOAD_INVOICE_BILLING_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: waitingInvoiceConstants.UPLOAD_INVOICE_BILLING_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const addExcessPayment = (id, form) => {
  return async (dispatch) => {
    dispatch({ type: waitingInvoiceConstants.ADD_EXCESS_PAYMENT_REQUEST });
    const res = await axios.put(`/Update/PendingPayment/${id}`, form);
    if (res.status === 200) {
      dispatch({
        type: waitingInvoiceConstants.ADD_EXCESS_PAYMENT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: waitingInvoiceConstants.ADD_EXCESS_PAYMENT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
