import axios from "../helpers/axios";
import { myScheduleConstants } from "./constants";

export const getAllSchedules = (search) => {
  return async (dispatch) => {
    dispatch({ type: myScheduleConstants.GET_ALL_SCHEDULES_REQUEST });
    const res = await axios.get(`/Schedules/Teleconsultation?${search}`);
    if (res.status === 200) {
      dispatch({
        type: myScheduleConstants.GET_ALL_SCHEDULES_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: myScheduleConstants.GET_ALL_SCHEDULES_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const addSchedules = (form, search) => {
  return async (dispatch) => {
    dispatch({ type: myScheduleConstants.ADD_NEW_SCHEDULES_REQUEST });
    const res = await axios.post("/Schedules", form);
    if (res.status === 200) {
      dispatch({
        type: myScheduleConstants.ADD_NEW_SCHEDULES_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllSchedules(search));
    } else {
      dispatch({
        type: myScheduleConstants.ADD_NEW_SCHEDULES_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const importSchedules = (multiple, search) => {
  return async (dispatch) => {
    dispatch({ type: myScheduleConstants.IMPORT_SCHEDULES_REQUEST });
    const res = await axios.post("/Schedules/multiple", multiple);
    if (res.status === 200) {
      dispatch({
        type: myScheduleConstants.IMPORT_SCHEDULES_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllSchedules(search));
    } else {
      dispatch({
        type: myScheduleConstants.IMPORT_SCHEDULES_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const clearImportSchedule = (query) => {
  return async (dispatch) => {
    dispatch({ 
      type: myScheduleConstants.CLEAR_IMPORT_SCHEDULE_SUCCESS, 
      payload: {
        message: "Clear error data success",
        dataError: []
      }
    });
    dispatch(getAllSchedules(query));
  };
};

export const updateSchedules = (id, form, search) => {
  return async (dispatch) => {
    dispatch({ type: myScheduleConstants.UPDATE_SCHEDULES_REQUEST });
    const res = await axios.put(`/Schedules/${id}`, form);
    if (res.status === 200) {
      dispatch({
        type: myScheduleConstants.UPDATE_SCHEDULES_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllSchedules(search));
    } else {
      dispatch({
        type: myScheduleConstants.UPDATE_SCHEDULES_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const deleteSchedules = (id, search) => {
  return async (dispatch) => {
    dispatch({ type: myScheduleConstants.DELETE_SCHEDULES_REQUEST });
    const res = await axios.delete(`/Schedules/${id}`, {
      payload: {
        id,
      },
    });
    if (res.status === 200) {
      dispatch({ type: myScheduleConstants.DELETE_SCHEDULES_SUCCESS });
      dispatch(getAllSchedules(search));
    } else {
      dispatch({
        type: myScheduleConstants.DELETE_SCHEDULES_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const multipleDeleteSchedules = (multiple, search) => {
  return async (dispatch) => {
    dispatch({ type: myScheduleConstants.MULTIPLE_DELETE_SCHEDULES_REQUEST });
    const res = await axios.post(
      "/Schedules/Teleconsultation/delete",
      multiple
    );
    if (res.status === 200) {
      dispatch({ type: myScheduleConstants.MULTIPLE_DELETE_SCHEDULES_SUCCESS });
      dispatch(getAllSchedules(search));
    } else {
      dispatch({
        type: myScheduleConstants.MULTIPLE_DELETE_SCHEDULES_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
