import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllReportPharmacyOffline } from "../../../actions";
import Pagination from "../../../components/Pagination";
import DashboardLayout from "../Layout";
import queryString from "query-string";

const ReportPharmacyOffline = () => {
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const searchKey = useRef("");
  const startDate = useRef("");
  const endDate = useRef("");
  const pharmacyDelivery = useSelector((state) => state.pharmacyDelivery);
  const sortBy = useRef("orderId");
  const sortType = useRef("DESC");
  const paidStatus = useRef("all");
  const dispatch = useDispatch();

  useEffect(() => {
    let values = {
        partnerId: localStorage.getItem("parentId"),
      },
      query = queryString.stringify(values);
    dispatch(getAllReportPharmacyOffline(query, search));
  }, [search]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: pharmacyDelivery.totalData,
    });
  }, [pharmacyDelivery.totalData]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const handleAutoComplete = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      searchKey: searchKey.current.value,
      page: pagination.page,
      limit: pagination.limit,
    };
    if (startDate.current.value !== "") {
      values.startDate = startDate.current.value;
    }
    if (endDate.current.value !== "") {
      values.endDate = endDate.current.value;
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleReset = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      searchKey: searchKey.current.value,
      page: pagination.page,
      limit: pagination.limit,
    };
    if (startDate.current.value !== "") {
      startDate.current.value = "";
    }
    if (endDate.current.value !== "") {
      endDate.current.value = "";
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleSearch = (page, limit) => {
    let values = {
      searchKey: searchKey.current.value,
    };
    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }
    if (limit) {
      values.limit = limit;
    }
    if (startDate.current.value !== "") {
      values.startDate = startDate.current.value;
    }
    if (endDate.current.value !== "") {
      values.endDate = endDate.current.value;
    }
    values.sortBy = sortBy.current.value;
    values.sortType = sortType.current.value;
    values.paidStatus = paidStatus.current.value;

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const renderPharmacy = () => {
    return pharmacyDelivery.loading ? (
      <tr>
        <td colSpan="9">
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        {pharmacyDelivery.orders.length > 0 ? (
          pharmacyDelivery.orders.map((pharmacy, index) => (
            <tr key={pharmacy.orderId}>
              <td>{(pagination.page - 1) * 10 + index + 1}</td>
              <td>{pharmacy.orderId}</td>
              <td>{pharmacy.createdDate}</td>
              <td>{pharmacy.patientName}</td>
              <td>{pharmacy.phoneNumber}</td>
              <td>{pharmacy.address}</td>
              <td>{pharmacy.status}</td>
              <td>
                {(pharmacy.paymentLink && pharmacy.status === "Belum Bayar") ||
                (pharmacy.paymentLink &&
                  pharmacy.status === "Pending Bayar") ? (
                  <a
                    href={pharmacy.paymentLink}
                    target="_blank"
                    style={{ color: "#007bff" }}
                  >
                    Link
                  </a>
                ) : (
                  <span>-</span>
                )}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="9">Empty data</td>
          </tr>
        )}
      </>
    );
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Report Pharmacy Offline</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        <div className="row">
          <div className="col-md-7">
            <span id="finance-title" className="mb10">
              Report Pharmacy Offline
            </span>
          </div>

          <div style={{ marginBottom: "1em" }} className="col-md-3">
            <select
              className="form-control table-search-field"
              style={{ marginBottom: "1em" }}
              onChange={() => handleSearch()}
              defaultValue={"orderId"}
              ref={sortBy}
            >
              <option value="orderId">Order Id</option>
              <option value="patientName">Patient Name</option>
              <option value="orderStatus">Order Status</option>
            </select>
          </div>
          <div style={{ marginBottom: "1em" }} className="col-md-2">
            <select
              className="form-control table-search-field"
              style={{ marginBottom: "1em" }}
              onChange={() => handleSearch()}
              defaultValue={"DESC"}
              ref={sortType}
            >
              <option value="ASC">Ascending</option>
              <option value="DESC">Descending</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div style={{ marginBottom: "1em" }} className="col-md-4 offset-md-8">
            <div className="input-group">
              <input
                type="text"
                className="form-control table-search-field"
                placeholder="Search by Name, Order Id, Phone Number"
                onChange={handleAutoComplete}
                ref={searchKey}
              />
            </div>
          </div>
        </div>

        <div className="row mb15">
          <div className="link-btn">
            <button
              type="button"
              className="btn btn-link no-padding"
              style={{ textDecoration: "none", boxShadow: "none" }}
              data-toggle="collapse"
              data-target="#collapseDetailSchedule"
            >
              Filter By
              <i className="fa fa-angle-down" style={{ marginLeft: "10px" }} />
            </button>
          </div>

          <div className="col">
            <hr />
          </div>
        </div>

        <div className="collapse" id="collapseDetailSchedule">
          <div id="filterForm" className="row filter-by">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <div className="mb10">Date From</div>
                  <input type="date" className="form-control" ref={startDate} />
                </div>

                <div className="col-md-4">
                  <div className="mb10">Date To</div>
                  <input type="date" className="form-control" ref={endDate} />
                </div>

                <div className="col-md-2">
                  <div className="mb10">Order Status</div>
                  <select
                    className="form-control table-search-field"
                    style={{ marginBottom: "1em" }}
                    ref={paidStatus}
                  >
                    <option value="all">Semua Status</option>
                    <option value="notPaid">Belum Bayar</option>
                    <option value="pending">Pending Bayar</option>
                    <option value="paidRs">Bayar ke RS</option>
                    <option value="paid">Sudah Dibayar</option>
                    <option value="processDelivery">Proses Delivery</option>
                    <option value="done">Selesai</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="text-right" style={{ marginTop: "25px" }}>
            <button
              type="button"
              className="btn btn-secondary"
              style={{ width: "100px", marginRight: "5px" }}
              onClick={() => handleReset()}
            >
              Reset
            </button>

            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100px" }}
              onClick={() => handleSearch()}
            >
              Apply
            </button>
          </div>

          <div style={{ width: "100%" }}>
            <hr />
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "45px" }}
                >
                  No
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Order Id Pharmacy
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Order Date
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Patient Name
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Phone Number
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Address
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Order Status
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Payment Link
                </th>
              </tr>
            </thead>
            <tbody>{renderPharmacy()}</tbody>
          </table>
        </div>

        {pharmacyDelivery.totalData > 10 ? (
          <Pagination
            props={pagination}
            setProps={setPagination}
            onChange={(page, limit) => {
              handleSearch(page, limit);
            }}
          />
        ) : null}
      </div>
    </DashboardLayout>
  );
};

export default ReportPharmacyOffline;
