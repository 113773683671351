import { gpOnsiteConstants } from "../actions/constants";

const initState = {
  orders: [],
  totalData: 0,
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case gpOnsiteConstants.GET_ALL_GPONSITE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case gpOnsiteConstants.GET_ALL_GPONSITE_SUCCESS:
      state = {
        ...state,
        orders: action.payload.orders,
        totalData: action.payload.totalData,
        loading: false,
      };
      break;
    case gpOnsiteConstants.GET_ALL_GPONSITE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case gpOnsiteConstants.GET_DONE_GPONSITE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case gpOnsiteConstants.GET_DONE_GPONSITE_SUCCESS:
      state = {
        ...state,
        orders: action.payload.orders,
        totalData: action.payload.totalData,
        loading: false,
      };
      break;
    case gpOnsiteConstants.GET_DONE_GPONSITE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case gpOnsiteConstants.UPDATE_GPONSITE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case gpOnsiteConstants.UPDATE_GPONSITE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case gpOnsiteConstants.UPDATE_GPONSITE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
  }
  return state;
};
