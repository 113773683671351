import axios from "../helpers/axios";
import { orderMedicalRecordsConstant } from "./constants";

export const getAllMedicalRecords = (q) => {
  return async (dispatch) => {
    dispatch({ type: orderMedicalRecordsConstant.GET_ALL_MEDICALRECORDS_REQUEST });
    
    const res = await axios.get(`/Order/medicalHistory?${q}`);
    if (res.status === 200) {
      const { medicalRecords, totalData } = res.data.data;
      dispatch({
        type: orderMedicalRecordsConstant.GET_ALL_MEDICALRECORDS_SUCCESS,
        payload: { medicalRecords, totalData },
      });
    } else {
      dispatch({
        type: orderMedicalRecordsConstant.GET_ALL_MEDICALRECORDS_FAILURE,
        payload: { error: res.data },
      });
    }
  };
};

export const postDownloadMR = (form) => {
  return async (dispatch) => {
    dispatch({ type: orderMedicalRecordsConstant.GET_MEDICAL_REPORT_REQUEST });
    const res = await axios.post(
      "/Reconciliation/Report/Medical", form
    );
    if (res.status === 200) {
      const { orders } = res.data;
      dispatch({
        type: orderMedicalRecordsConstant.GET_MEDICAL_REPORT_SUCCESS,
        payload: { orders },
      });
    } else {
      dispatch({
        type: orderMedicalRecordsConstant.GET_MEDICAL_REPORT_FAILURE,
        payload: { error: res.data.error },
      });
    } 
  };
};

export const getWeeklyDashboard = (query) => {
  return async (dispatch) => {
    dispatch({ type: orderMedicalRecordsConstant.GET_WEEKLY_DASHBOARD_REQUEST });
    const res = await axios.get(
      `/Reconciliation/Dashboard?${query}`
    );
    if (res.status === 200) {
      const { partnerOrders, totalData, totalMrFilled, totalNoPatient, totalNoDoctor, totalDoctorLate, totalPatientCovid } = res.data.weeklyReport;
      dispatch({
        type: orderMedicalRecordsConstant.GET_WEEKLY_DASHBOARD_SUCCESS,
        payload: { partnerOrders, totalData, totalMrFilled, totalNoPatient, totalNoDoctor, totalDoctorLate, totalPatientCovid },
      });
    } else {
      dispatch({
        type: orderMedicalRecordsConstant.GET_WEEKLY_DASHBOARD_FAILURE,
        payload: { error: res.data.message },
      });
    } 
  };
};